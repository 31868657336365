import {createContext, ReactNode, useContext} from "react";

type trivialContextResult<T> = readonly [() => T, (props: { children: ReactNode } & T) => JSX.Element]

export function createTrivialContext<T>(contextName: string): trivialContextResult<T> {

    const TrivialContext = createContext<T | undefined>(undefined)

    const useTrivialContext = () => {
        const context = useContext(TrivialContext);
        if (!context) {
            throw new Error(`Context ${contextName}: Hook must be used within the corresponding Provider`)
        }
        return context
    }

    function TrivialProvider({children, ...rest}: { children: ReactNode } & T): JSX.Element {
        return <TrivialContext.Provider value={{...rest} as T}>{children}</TrivialContext.Provider>
    }

    return [useTrivialContext, TrivialProvider]
}
