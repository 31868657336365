import {useLineItemsStoreMetadata, usePersistenceStoreQueryWithContextUpdate} from "../../../lineitems-store/LineItemsStore.hook";
import {DEFAULT_SOURCE_GRID, LineItemsStore, pQuery, QueryResult, StoreQuery} from "../../../ps-models/lineitems-store";
import {Grid, Header, Icon, Segment} from "semantic-ui-react";
import React from "react";
import {
  buildDailyTimeDef,
  buildTimedCalculatedLineItem,
  field, TimedCalculatedLineItem
} from "../../../ps-models/line-items";
import {Metric} from "../../../statistics/Metric";
import {useDateRange} from "../../../lineitems-store/DateRange";
import {LineItemsTable, LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {lineItemAggregatorMap, singleAggregator} from "../../../ps-models";
import {addAlertLineItems, setLabelAndFormatForAggregates} from "./storeFormulas";
import {ClosableSection} from "../../../ClosableSection";
import {useLineItemFilter} from "../../../lineitems-store/MetadataFilters";
import MapComponent from "../../../lineitems-store/MapComponent";
import {AlertsLineItemTable, SimpleCalculatedLineItemEditor} from "../../../lineitems-store/Alerts";
import {useUpdateContext, WaitForIt} from "../../../UpdateContext";
import {GridOfComponents} from "../../../ui/GridOfComponents";
import {DemoTimeSeriesChart} from "./DemoTimeSeriesChart";
import {LoadingBlock} from "../../../ui/Loading";
import {authStorage} from "../../../auth";
import {getAmProjectConfig} from "../../../ps-models";


const AGGREGATOR_MAP =  lineItemAggregatorMap({
  'Investment Returns IRR': "avg",
  "Original_Asset_Value__c": "first",
  "Current Value": "first",
  "Battery_kWh__c": "first",
  "kWp_Solar_System__c": "first",
  "Billing_Type__c": "first",
  "Current_Energy_Retailer__c": "first",
}, "sum");



export function PortfolioPerformance({goToSiteDetailTab}: {goToSiteDetailTab: (id: string) => void}) {
  const company = authStorage.getCompany();
  let { collection: COLLECTION } = getAmProjectConfig(company);

    let lineItems = [
      'Current Value',
      'original_asset_value__c',
      'Net Cash Flow',
      'Investment Returns IRR',
      'Site Available',
      'Battery Available',
      'Solar Available',
      'pv_generation_kwh',
      'wamo',
      'kWp_Solar_System__c',
      'Current_Energy_Retailer__c',
      'Billing_Type__c',
      'Battery_kWh__c',
      'Original_Asset_Value__c',
      'Net Revenue',
      'Net Cash Flow',
      'Post_Code__c'
    ];

    let parameters = [
      'Current_Energy_Retailer__c', 'Network__c', 'State__c', 'Billing_Type__c', 'Residential_Business__c'
    ]

    let store = usePersistenceStoreQueryWithContextUpdate(COLLECTION,
      pQuery()
        .selectSourceParams(parameters)
        .import(['portfolio-formulas'])
        .withLineItems(lineItems)
      , (store) => {
          store.getDataSet().addTimedGroupingLineItemsByField("store_sourceLineItemName", {
            defaultGroupingOperation : "sum",
            groupOperationMap: {
              "Solar Available": "avg",
              "Battery Available": "avg",
              "Site Available": "avg",
              "Investment Returns IRR": "avg",
            }
          });

          store.getDataSet().addLineItem(
            buildTimedCalculatedLineItem('AEO/Wamo',
              buildDailyTimeDef(),
              //language=JavaScript
              ` "pv_generation_kwh" / "wamo"  `
            )
          )
          store.getDataSet().addLineItem(
            buildTimedCalculatedLineItem('Availability',
                buildDailyTimeDef(),
              //language=JavaScript
              ` "Site Available"  `
            )
          );

        store.getDataSet().addLineItem(
          buildTimedCalculatedLineItem('Number of Sites',
              buildDailyTimeDef(),
            //language=JavaScript
            `f('store_sourceLineItemName', 'Post_Code__c').length`
            //Todo: This is a trick to get the number of loaded sites, we should think on a simple way to count stores, even when filtered
          )
        )
          addAlertLineItems(store);
          setLabelAndFormatForAggregates(store);
      }
    );

    let storesMetadata = useLineItemsStoreMetadata(COLLECTION, pQuery().metadataOnly([
      'Location_latitude',
      'Location_longitude',
      ...parameters
    ]));

    let [filterQuery, filtersComponent] = useLineItemFilter({
      'source_Current_Energy_Retailer__c': 'Retailer',
      'source_Network__c': 'Network',
      'source_State__c': 'State',
      'source_Billing_Type__c': 'Billing Type',
      'source_Residential_Business__c': 'Residential/Business'
    }, store);

    let [dateRangeComponent, dateRange] = useDateRange(store);

    let metrics: QueryResult | undefined;
    let wamo: QueryResult | undefined;
    let availability: QueryResult | undefined;
    let metricsTable: QueryResult | undefined;

    let queryWithFiltersAndGropingElements = StoreQuery.withField('store_groupingName').or(filterQuery);

    let metricsMainQuery = queryWithFiltersAndGropingElements.aggregateOverTimeRange(dateRange.from, dateRange.to, AGGREGATOR_MAP);

    let siteList = storesMetadata && storesMetadata.query(queryWithFiltersAndGropingElements);

    if (store) {
      metrics = store.materializeTimed(metricsMainQuery).query(StoreQuery.all());

      metricsTable = store.view(queryWithFiltersAndGropingElements).query(metricsMainQuery, {grid: DEFAULT_SOURCE_GRID });


      wamo = store.view(StoreQuery.byNames(['AEO/Wamo']).or(queryWithFiltersAndGropingElements)).query(StoreQuery.all().inTimeRange(dateRange.from, dateRange.to));
      availability = store.view(StoreQuery.byNames(['Availability']).or(queryWithFiltersAndGropingElements))
        .query(StoreQuery.all().inTimeRange(dateRange.from, dateRange.to).monthly(singleAggregator('avg')));
    }

    if (!metrics || !wamo) {
      return <LoadingBlock />
    }


    let netCashflowHigh = metrics.firstTimeSlotValueOf('Net Cash Flow - alert') ?
      (store?.getDataSet().getLineItem('Net Cash Flow - alert') as TimedCalculatedLineItem).fn.toString() : ""

    let netCashflowMedium = metrics
      .firstTimeSlotValueOf('Net Cash Flow - children-alert') ? "Some sites are non compliant" : ""

    let irrHigh = metrics.firstTimeSlotValueOf('Investment Returns IRR - alert') ?
      (store?.getDataSet().getLineItem('Investment Returns IRR - alert') as TimedCalculatedLineItem).fn.toString() : ""
    let irrMed = metrics.firstTimeSlotValueOf('Investment Returns IRR - children-alert') ?
      "Some sites are non compliant" : "";


    return <WaitForIt><div className="InvestorDashboard">
      <Header as="h2" color="purple">Portfolio Performance</Header>

      {dateRangeComponent}

      <Segment className="aggregate-filters">
        {filtersComponent}
      </Segment>

      {metrics && <Grid solid>
          <Grid.Row columns={2}>
              <Grid.Column>
                {siteList && <MapComponent
                    onClickLocation={(site) => goToSiteDetailTab && goToSiteDetailTab(site)}
                    result={siteList}/>}
              </Grid.Column>
              <Grid.Column>


                {metrics && <GridOfComponents height={400}>
                    <Metric size="small" label={"Number of Sites"}
                            value={metrics.firstTimeSlotTextOf("Number of Sites")}
                    />
                    <Metric size="small" label={"Solar KWP Installed"}
                            value={metrics.firstTimeSlotTextOf("kWp_Solar_System__c")}
                    />

                    <Metric size="small" label={"Actual generation"}
                            value={metrics.firstTimeSlotTextOf('pv_generation_kwh')}
                    />

                    <Metric size="small" label="Original Value of Assets Deployed"
                            value={metrics.firstTimeSlotTextOf('original_asset_value__c')}
                    />

                    <Metric label={"Net Cash Return"}
                            size="small"
                            value={metrics.firstTimeSlotTextOf('Net Cash Flow')}
                            alert={netCashflowHigh}
                            mediumAlert={netCashflowMedium}
                    />

                    <Metric size="small" label={"MOIC"}
                            value={metrics.firstTimeSlotTextOf('MOIC')}
                    />
                    <Metric size="small" label={"DSCR"}
                            value={metrics.firstTimeSlotTextOf('DSCR')}
                    />

                    <Metric label={"Portfolio IRR"}
                            size="small"
                            value={metrics.firstTimeSlotTextOf('Investment Returns IRR')}
                            alert={irrHigh}
                            mediumAlert={irrMed}
                    />


                </GridOfComponents>}

              </Grid.Column>
          </Grid.Row>
      </Grid>}


      <Grid>
        <Grid.Row>
          <Grid.Column>
            {store && <AlertsSection store={store} storeQuery={metricsMainQuery}  mediumAlert={netCashflowMedium} highAlert={netCashflowHigh}/>}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={2}>
          <Grid.Column>
            {wamo &&
                <DemoTimeSeriesChart title={"AEO/Wamo"} result={wamo}
                                 options={{includeLineItems: ['Wamo', "pv_generation_kwh"]}}/>
            }
          </Grid.Column>
          <Grid.Column>
            {availability &&
                <DemoTimeSeriesChart title={"Availability"} result={availability}
                                 options={{includeLineItems: ['Solar Available', 'Site Available', 'Battery Available']}}/>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {metricsTable && <LineItemsTable queryResult={metricsTable}
      excludeColumns={['Post_Code__c']}
      />}

      <ClosableSection title={"Debug"} level={"small"} opened={false}>
        {store && <LineItemsTableWithFormulas store={store} withGroups
                                              queryResult={metrics}
      />}
      </ClosableSection>
</div>
</WaitForIt>
}

function AlertsSection({store, storeQuery, mediumAlert, highAlert}:
                         {
                           store:LineItemsStore,
                           storeQuery: StoreQuery,
                           highAlert: string, mediumAlert: string}) {

  useUpdateContext();



  let aggregatedStore =  store.materializeTimed(storeQuery);

  let alertsCol1 = aggregatedStore.query(
    StoreQuery.byNames(['Investment Returns IRR'], true)
      .or(StoreQuery.withField('alertOf'))
  );

  let alertsCol2 = aggregatedStore.query(
    StoreQuery.byNames(['Net Cash Flow'], true)
      .or(StoreQuery.withField('alertOf'))
  );

  return <ClosableSection
    opened={false}
    title={<><strong style={{fontSize: "16px"}}>
      {highAlert && <Icon name="warning circle" color="red" />}
      {mediumAlert && <Icon name="warning circle" color="orange" />}
      Alerts
    </strong>
    </>}
    level="title-bar"
  >
     <SimpleCalculatedLineItemEditor store={store}
        dispatcher={(onRowSelected) =>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>

                <AlertsLineItemTable
                  onRowSelected={onRowSelected}
                  results={alertsCol1!} store={store!}/>


              </Grid.Column>
              <Grid.Column>
                <AlertsLineItemTable
                  onRowSelected={onRowSelected}
                  results={alertsCol2!}
                  store={store!}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>}/>

  </ClosableSection>
}


