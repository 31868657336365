import {Button, Divider, Form, Grid, Header, Label, Table} from "semantic-ui-react";
import React, {useState} from "react";
import {
    DAILY_GLOBAL_SOLAR_EXPOSURE_NAME,
    BomData,
    getP50P90Stats,
    addP50P90Parameters
} from "./list-of-sites-data/bomStore";
import {useForm} from "../../../ui/UseForm";
import {LineItemsStore, QueryResult, StoreQuery} from "../../../ps-models/lineitems-store";
import {buildDailyTimeIndex, valueAsNumber} from "../../../ps-models";
import {LineItemsTable, LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {LineItemsStoreDebug} from "./LineItemsStoreDebug";
import {buildTimedCalculatedLineItem} from "../../../ps-models/line-items";
import {ClosableSection} from "../../../ClosableSection";
import {DemoTimeSeriesChart} from "./DemoTimeSeriesChart";


export function SiteMetadataSetup({bomData}: { bomData: BomData | null }) {
    return <>
        {bomData === null ? <SiteMetadataSetupNoContent/> : <SiteMetadataSetupContent data={bomData} />}
    </>
}

interface P50P90Data {
    store: LineItemsStore
}

function SiteMetadataSetupContent({data}: { data: BomData }) {
    const { formData, handleChange } = useForm({
        initialState: {
            expectedProduction: `"Solar Exposure (kWh/m2)" * 0.79`,
            systemSize: 100,
            ppaRate: 0.24,
        }
    })
    const [p50p90data, setP50p90data] = useState<{yearlyStore: LineItemsStore, statsStore: LineItemsStore} | null>(null)
    const [error, setError] = useState<string | null>(null)

    const expectedProductionOk = formData.expectedProduction.trim().length > 0
    const systemSizeOk = formData.systemSize > 0
    const ppaRateOk = formData.ppaRate > 0
    const enoughData = expectedProductionOk && systemSizeOk && ppaRateOk;

    function calculateP50P90() {
        setError(null)
        const {store} = data
        addP50P90Parameters(store, formData.expectedProduction, formData.systemSize, formData.ppaRate)
        setP50p90data( getP50P90Stats(store))
        store.clearExecutionCache();
    }

    return <>
        <Header as="h2" color="purple">Add site MetaData or Import Site</Header>
        <Grid container>
            <Grid.Row columns={2}>
                <Grid.Column width={3} verticalAlign="middle"><span>Expected Production (per kW)</span></Grid.Column>
                <Grid.Column>
                    <Form.Input
                        style={{width: "300px"}}
                        type="string"
                        name="expectedProduction"
                        value={formData.expectedProduction}
                        onChange={handleChange}
                        floated={"left"}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={3} verticalAlign="middle"><span>System Size (kW)</span></Grid.Column>
                <Grid.Column>
                    <Form.Input
                        type="number"
                        name="systemSize"
                        value={formData.systemSize}
                        onChange={handleChange}
                        floated={"left"}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={3} verticalAlign="middle"><span>PPA Rate (per kWh)</span></Grid.Column>
                <Grid.Column>
                    <Form.Input
                        type="number"
                        name="ppaRate"
                        value={formData.ppaRate}
                        onChange={handleChange}
                        floated={"left"}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button color="purple" onClick={() => calculateP50P90()} disabled={!enoughData}>Calculate p50/p60</Button>

                    {p50p90data && <ShowP50P90Data results={p50p90data.statsStore.query(StoreQuery.byNames(['p50', 'p90', 'p50 Revenue', 'p90 Revenue']))} />}

                  {/*<h3>Yearly Store</h3>*/}
                  {/*{p50p90data && <LineItemsStoreDebug store={p50p90data.yearlyStore} />}*/}

                  {/*<h3>Stats Store</h3>*/}
                  {/*{p50p90data && <LineItemsStoreDebug store={p50p90data.statsStore} />}*/}

                  {p50p90data &&<DemoTimeSeriesChart title={"Historical Production"} result={data.store
                      .query(StoreQuery.byNames(
                      ['Daily Expected Production (kwh)']
                    ).withTimeIndex(data.store.timeIndex.withGranularity("months"))
                      )} />}

                  {p50p90data && <DemoTimeSeriesChart
                      title={"Historical Revenue"}
                      result={data.store.query(StoreQuery.byNames(
                        ['Daily Expected Revenue']
                      ).withTimeIndex(data.store.timeIndex.withGranularity("months")))}
                    />}
                    {/*<ClosableSection title={"debug"} level="small">*/}

                    {/*  <LineItemsStoreDebug store={data.store} />*/}
                    {/*</ClosableSection>*/}

                </Grid.Column>
            </Grid.Row>
        </Grid>
        {error && <>
            <Divider/>
            <Label color="red">{error}</Label>
        </>}

    </>
}

function ShowP50P90Data({results}: { results: QueryResult }) {


    return <div style={{paddingTop: "20px"}}><LineItemsTable hideHeaders hideToolbar hideFooter  queryResult={results} /></div>
}

function SiteMetadataSetupNoContent() {
    return <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column></Grid.Column>
                <Grid.Column><Label color="red">Please connect weather data first in the other tab.</Label></Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
        </Grid>
}
