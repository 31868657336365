import {AggregatorMethod, LineItemAggregations} from "../../ps-models";
import {TimeSeriesChartOptions} from "../../lineitems-store/TimeSeriesChart";
import {INPUT_FIELD_MAX_CHARACTER_LIMITS} from "../../../utils/globalInputConstants";
import {InputFieldDef, InputFieldDefinitionBuilder} from "../../../components/PreparedForm/PreparedFormInputCommons";
import {QueryResult} from "../../ps-models/lineitems-store";
import {DateRangeType} from "../../ps-types";

export type ConfigurableItemKind = 'Metric' | 'Chart' | 'NonTimeSeriesTable';

export interface MetricConfig {
    id: string;
    kind: 'Metric',
    title: string;
    lineItemName: string;
    aggregationTechnique: AggregatorMethod;
}

export interface ChartConfig extends ConfigItemLineItemAndAggregationMaps {
    id: string;
    kind: 'Chart';
    title: string;
    type: TimeSeriesChartOptions["type"];
}

export interface NonTimeSeriesTableConfig extends ConfigItemLineItemAndAggregationMaps {
    id: string;
    kind: 'NonTimeSeriesTable',
}

export interface ConfigItemLineItemAndAggregationMaps {
    lineItemNames: string[];
    timeAggregatorMap: Record<string, AggregatorMethod>;
    groupAggregatorMap: Record<string, LineItemAggregations>;
}

export const ALL_POSSIBLE_CHART_TYPES: ChartConfig["type"][] = [
    'line', 'area', 'bar', 'histogram', 'pie', 'donut',
    'radialBar', 'scatter', 'bubble', 'heatmap', 'candlestick', 'boxPlot', 'radar',
    'polarArea', 'rangeBar', 'treemap'
]
export const POSSIBLE_CHART_TYPES_FOR_DEMO: ChartConfig["type"][] = [
    'line', 'area', 'bar'
]

export type ReportConfigItem = MetricConfig | ChartConfig | NonTimeSeriesTableConfig;
export type ReportConfigItems = ReportConfigItem[];
export interface ReportConfig {
        id: string;
        dateRange: DateRangeType
        filterQuery: Record<string, string>
        items: ReportConfigItems,
        reportMeta: Record<ReportMeta, string | null>
        createdAt: Date;
        updatedAt: Date;
        emailConfig?: ReportDeliveryEmailConfig
}

export interface ReportDeliveryEmailConfig {
    emailTo: string;
    frequency: "Monthly" | "Quarterly" | "Yearly"
}

// @TODO we also need to store the dateRange and filterQuery...

export type ReportMeta = 'title' | 'description';

export const reportMetaInputLabels:  Record<ReportMeta, string> = {
    title: "Report Title",
    description: "Report Description",
}

const fieldDefinitionBuilder = new InputFieldDefinitionBuilder();

export const reportMetaInputsDefinition: Record<ReportMeta, InputFieldDef> = {
    title: fieldDefinitionBuilder.buildTextInput({
        customFieldType: 'text',
        label: reportMetaInputLabels["title"],
        placeholder: reportMetaInputLabels["title"],
        required: true,
        maxLength: INPUT_FIELD_MAX_CHARACTER_LIMITS.companyName,
        customFieldValidator: (value)=> {
            if(!value){
                return 'Please enter title for this report'
            }
            return null;
        }
    }),
    description: fieldDefinitionBuilder.buildTextAreaInput({
        label: reportMetaInputLabels["description"],
        placeholder: reportMetaInputLabels["description"],
        required: true,
        maxLength: 200,
        rows: 2,
        customFieldValidator: (value)=> {
            if (!value) {
                return 'Please enter description for this report';
            }
            return null;}
    }),
};

//
interface Dimension {
    width: number; height: number
}
export interface DimensionWithItemDef extends Dimension {
    itemDef: ReportConfigItem | ReportStaticItem;
    queryResult: QueryResult | undefined;
}
type ReportStaticItem = SiteMap | InputItemType | ReportMetaInputsContainerType;
type StaticItemKind = "Map" | "Input" | "ReportMetaInputsContainer"

export interface SiteMap {
    kind: "Map"
}

export interface InputItemType {
    kind: "Input",
    fieldName: ReportMeta
}

export interface ReportMetaInputsContainerType {
    kind: "ReportMetaInputsContainer"
}

export type Row<T extends Dimension> = T[]
export type Page<T extends Dimension> = Row<T>[]

// @TODO, Make this dynamic.
export const HEIGHT_OF_CONTENT_CONTAINER = (2198-96);
export const WIDTH_OF_CONTENT_CONTAINER = (1510-(96*2));

export function arrangeLayout<T extends Dimension>(inputElements : T[]): Page<T>[] {
    const pages: Page<T>[] = [];
    let currentRow: Row<T> = [];
    let currentPage: Page<T> = [];
    for(let el of inputElements){
        let rowWidth = calculateRowWidth(currentRow);
        if(rowWidth+el.width > WIDTH_OF_CONTENT_CONTAINER){
            currentPage.push(currentRow);
            currentRow = [el];
        } else {
            currentRow.push(el);
        }

        let pageHeight = calculatePageHeight(currentPage);
        if(pageHeight+calculateRowHeight(currentRow) > HEIGHT_OF_CONTENT_CONTAINER){
            pages.push(currentPage)
            currentPage = [];
        }
    }
    if(currentRow.length>0){
        currentPage.push(currentRow)
    }
    if(currentPage.length>0){
        pages.push(currentPage)
    }
    return pages;
}

function calculateRowWidth(row: Row<Dimension>){
    return row.map((r)=> r.width).reduce((a,b)=> a+b, 0)
}

function calculateRowHeight(row: Row<Dimension>){
    return Math.max(...row.map((r)=> r.height))
}

function calculatePageHeight(page: Page<Dimension>){
    return page.map((r)=> calculateRowHeight(r)).reduce((a,b)=> a+b, 0)
}

export const REPORT_BUILDER_ITEM_DIMENSIONS: Record<ConfigurableItemKind | StaticItemKind, Dimension> = {
    'Metric': {width:WIDTH_OF_CONTENT_CONTAINER / 3, height: 100},
    'Chart': {width:  WIDTH_OF_CONTENT_CONTAINER, height: 400},
    'NonTimeSeriesTable': {width:WIDTH_OF_CONTENT_CONTAINER, height: 450},
    'Map': {width:WIDTH_OF_CONTENT_CONTAINER, height: 450},
    'Input': {width:WIDTH_OF_CONTENT_CONTAINER/2, height: 50},
    'ReportMetaInputsContainer': {width:WIDTH_OF_CONTENT_CONTAINER, height: 150}
}


export const addReportConfigToLocalStorage = (configToStore: ReportConfig) => {
    const rawExistingReportConfigs = window.localStorage.getItem("reportConfigs") ?? "[]";
    try {
        const existingReportConfigs = JSON.parse(rawExistingReportConfigs);
        // @TODO: Not fullproof. Just the Happy flow
        if(Array.isArray(existingReportConfigs)){
            const configItemIndex = existingReportConfigs.findIndex((c)=>c.id && c.id === configToStore.id);
            if(configItemIndex!==-1){
                existingReportConfigs[configItemIndex] = configToStore;
            } else {
                existingReportConfigs.push(configToStore);
            }
            window.localStorage.setItem("reportConfigs", JSON.stringify(existingReportConfigs));
        } else {
            // The localStorage was tampered
        }
    } catch(err){
        console.info("are we in the err", err);
        window.localStorage.setItem("reportConfigs", JSON.stringify([configToStore]));
    }
    return;
}


export const deleterReportConfigFromLocalStorage = (reportConfigId: string) => {
    const rawExistingReportConfigs = window.localStorage.getItem("reportConfigs") ?? "[]";
    try {
        const existingReportConfigs = JSON.parse(rawExistingReportConfigs);
        // @TODO: Not fullproof. Just the Happy flow
        if(Array.isArray(existingReportConfigs)){
            const existingNumberOfReportConfigs = existingReportConfigs.length;
            const updatedReportConfigs = existingReportConfigs.filter((c)=>c.id && c.id !== reportConfigId);
            if(updatedReportConfigs.length!==existingNumberOfReportConfigs){ // only update if the item was indeed found
                window.localStorage.setItem("reportConfigs", JSON.stringify(updatedReportConfigs));
            }
        } else {
            // The localStorage was tampered
        }
    } catch(err){
        console.error("Error parsing the ls", err);
    }
    return;
}