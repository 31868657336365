let startNs: bigint
let clocks = 0
let messagelessClock: boolean

export const durationMs = (startNs: bigint): number => {
    // @ts-ignore
    const oneMillion = 1_000_000n
    return Number((process.hrtime.bigint() - startNs) / oneMillion) // ns to ms
}

export function bench(entry: string, ...args: any[]) {
    if (messagelessClock) {
        messagelessClock = false
        process.stdout.write('\nBench: new clock \n')
    }
    process.stdout.write(`Bench: ${entry} ⏱ ${durationMs(startNs).toLocaleString()}ms /${sourceFile(1) ?? ''}`)
    if (args.length > 0) process.stdout.write(` [ ${args.join(', ')} ]`)
    process.stdout.write('\n')
}

function sourceFile(origin: number = 0): string | undefined {
    return (new Error()).stack?.split("\n")?.[origin + 2].split('/')?.slice(-1)?.[0]?.replace(')', '')
}

bench.on = false
bench.beginClock = (time: bigint) => {
    if (clocks === 0) {
        startNs = time
        messagelessClock = true
    }
    bench.on = true
    clocks++
}

bench.keepClock = () => {
    clocks++
}

bench.endClock = () => {
    clocks--
    if (clocks === 0 && !messagelessClock) {
        process.stdout.write(`Bench: clock ended ⏱  ${durationMs(startNs).toLocaleString()}ms\n\n`)
    }
}
