import {Icon, Segment, SemanticCOLORS, SemanticICONS} from "semantic-ui-react";
import {ClosableSection} from "../ClosableSection";
import React from "react";

export const ProcessLogs = ({ maxHeight, logs}: {maxHeight?: string, logs: {warning: string[], info: string[]} }) => {
        return <ClosableSection
            opened={true}
            title={<><strong style={{fontSize: "16px"}}>
                Process Logs
            </strong>
            </>}
            level="title-bar"
            customStyles={{margin: '1em 0'}}
        ><Segment style={{...(maxHeight ? {maxHeight, overflowY: 'auto'}: {})}}>
            {renderLogsContainer(logs.warning, 'warning')}
            {renderLogsContainer(logs.info, 'info')}
        </Segment> </ClosableSection>

};

export const renderLogsContainer = (messages: string[], type: 'warning' | 'info' | 'error', maxHeightInPx?: string) => {
    let iconName: SemanticICONS = 'warning sign';
    let iconColor: SemanticCOLORS = 'yellow';
    switch(type){
        case "warning":
            iconName = 'warning sign';
            iconColor = 'yellow';
            break;
        case "info":
            iconName = 'info circle';
            iconColor = 'blue';
            break;
        case "error":
            iconName = 'exclamation'
            iconColor = 'red';
            break;
    }
    const containerStyle = {
    margin: '10px 0',
        // border: '1px solid gray',
        border: 'none',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        backgroundColor: '#fff',
        ...(maxHeightInPx ? {maxHeight:maxHeightInPx, overflowY: 'auto'}: {}) }
    const titleSegmentStyle = {
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        padding: '10px',
        fontSize: '1.1rem',
        color: 'rgba(0, 0, 0, 0.85)',
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottom: '1px solid rgba(0,0,0,0.1)'
    }
    const messageSegmentStyle = {
        justifyContent: 'center',
        padding: '8px 16px',
        borderBottom: 'none',
        '&:last-child': {
            borderBottom: 'none'
        }
    };
    return <Segment.Group style={containerStyle}>
        <Segment
            style={titleSegmentStyle}
        ><Icon name={iconName} color={iconColor} /><strong>{type.toUpperCase()}</strong></Segment>
        <Segment.Group style={{margin: 0}}>
            {messages.map((message, index) => (
                <Segment key={index} style={{messageSegmentStyle}}>
                    {message}
                </Segment>
            ))}
        </Segment.Group>
    </Segment.Group>
};