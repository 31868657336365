import React, {useEffect} from "react";
import {Button, TextArea} from "semantic-ui-react";

export function RowOrderingTextArea({items, onSelected}: { items: string[], onSelected: (liNames: string[]) => void }) {
  const [textValue, setTextValue] = React.useState<string>("");

  let dataStr = items.join("\n");

  useEffect(() => {
    setTextValue(dataStr);
  }, [dataStr])

  const handleApply = () => {
    let individuallySelectedItems = textValue.split("\n")
      .map((it) => it.trim())
      .filter((it) => it.length > 0);

    console.info("Items selected", individuallySelectedItems)
    onSelected(individuallySelectedItems);
  }

  return <div>

    <TextArea value={textValue}
              style={{width: "300px", height: "400px"}}
              onChange={(e) =>
                setTextValue(e.target.value)}/>
    <Button
      size="mini"
      primary onClick={() => handleApply()}>Done</Button>
  </div>
}