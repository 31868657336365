import { groupBy } from "ramda";
import { functions } from "../firebase/firebase.core";
import { CircuitBreaker } from "../platform/ps-models/CircuitBreaker";
import { Queue } from "../platform/ps-models/Queue";
import { getBrowserName } from "./getBrowserName.utils";

type MetricsItemType = {
  id?: string,
  name: string,
  metricCategory: string,
  value: number,
};

type MetricsStaticMetaDataType = {
  applicationVersion: string,
  browser: string
};

const setupTimerToProcessBatch = (queue: Queue<MetricsItemType, MetricsStaticMetaDataType>) => {
  let batchProcessingTimer: number | NodeJS.Timeout;
  // @ts-ignore
  if (window.requestIdleCallback) {
    batchProcessingTimer = requestIdleCallback(() => queue.processBatch());
  } else {
    // Every 15 seconds
    batchProcessingTimer = setInterval(() => {
      queue.processBatch();
    }, 15 * 1000);
  }
  return batchProcessingTimer;
};

const clearTimer = (timerId: number | NodeJS.Timeout): null => {
  // @ts-ignore
  if (window.requestIdleCallback) {
    cancelIdleCallback(timerId as number);
  } else {
    clearInterval(timerId);
  }
  return null;
};

export const metricsQueue = new Queue<MetricsItemType, MetricsStaticMetaDataType>('frontend-metrics-collector', {
  processorCallback: (items) => {
    const metricsGroupedByName = groupBy((queueItem) => queueItem.name, items);
    const body = metricsGroupedByName;
    functions.httpsCallable("publishCustomMetrics")(body)
  }, batchSize: 5, circuitBreaker: new CircuitBreaker('frontend-metrics-processor')
},
  {
    applicationVersion: process.env.APPLICATION_VERSION || "0.0.0",
    browser: getBrowserName()
  });

if (window) {
  let timerId: number | NodeJS.Timeout | null = setupTimerToProcessBatch(metricsQueue);
  // Report all available metrics whenever the page is backgrounded or unloaded.
  window.addEventListener("visibilitychange", () => {
    switch (document.visibilityState) {
      case 'hidden':
        metricsQueue.flush();
        if (timerId !== null) {
          timerId = clearTimer(timerId);
        }
        break;
      case 'visible':
        if (!timerId) {
          timerId = setupTimerToProcessBatch(metricsQueue);
        }
        break;
    }
  });

  // NOTE: Safari does not reliably fire the `visibilitychange` event when the
  // page is being unloaded. If Safari support is needed, you should also flush
  // the queue in the `pagehide` event.
  window.addEventListener("pagehide", () => {
    metricsQueue.flush();
    if (timerId !== null) {
      timerId = clearTimer(timerId);
    }
  });

  window.addEventListener("pageshow", () => {
    if (!timerId) {
      timerId = setupTimerToProcessBatch(metricsQueue);
    }
  });
}