import React, { useState } from 'react';
import { Button, Modal, Icon, Dropdown } from 'semantic-ui-react'
import { useDispatch } from 'react-redux';

import { assignUser } from '../../../redux/admin/admin.actions';

function UserAssign(props) {
  const { user, companies, text } = props; 
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const company = selectedCompanyId ? companies.find(company => company.id === selectedCompanyId) : null;
  const userId = user.id;
  let displayName = `${user.id} (${user.email})`;
  if (!!user.displayName) { displayName = `${user.displayName} (${user.email})`; }
  else if (!!user.firstName && user.firstName !== undefined) { displayName = `${user.firstName} ${user.lastName} (${user.email})`; }

  const resetModal = () => {
    setOpen(false);
    setSelectedCompanyId("");
  }

  const handleDropdownSelect = (event, data) => {
    const { value } = data;
    if (value) setSelectedCompanyId(value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log("ATTEMPTIG USER ASSIGNMENT");
      setLoading(true);
      await assignUser(dispatch, userId, selectedCompanyId);
      setLoading(false);
      resetModal();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button size="tiny" compact content={text || "Assign"} color={text === "Reassign" ? "orange" : "purple"} />
      }
    >
      <Modal.Header>{`Assign User ${displayName} to ${company ? (company.name || company.id) : "Company"}`}</Modal.Header>
      <Modal.Content> 
        <Modal.Description>
        <Dropdown 
          fluid
          text={company ? company.name : "Select Company"}
          loading={loading}
          disabled={loading}
          onChange={(event, data) => handleDropdownSelect(event, data)} 
          defaultValue={selectedCompanyId}
          placeholder='Assign User' search  
          options={(companies || []).map(company => ({ key: company.id, text: company.name || company.id, value: company.id }))} 
        />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          labelPosition='left'
          icon="check"
          primary
          onClick={handleSubmit}
          content="Confirm User Assignment"
          disabled={!selectedCompanyId || loading}
          loading={loading}
        />
        <Button negative onClick={resetModal} icon labelPosition="left" type="button">
          <Icon name="cancel" />
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default UserAssign;