import React, {useEffect, useState} from 'react';
import {Button, Container, Divider, Dropdown, Form, Grid, Header, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {LoginLayout} from "./LoginLayout";
import {useForm} from "../ui/UseForm";
import {useTimer} from "../ui/UseTimer";
import firebase from "firebase/app";
import {initiatePhoneNumberRegistration, completePhoneNumberRegistration, PhoneData} from "./auth.client";
import {countryOptions} from "./phoneCountries";
import {OtpFormInput, PhoneFormInput} from "./PhoneFormInputs";
import {LoadingBlock} from "../ui/Loading";
import {useAuthCtx} from "./AuthContextProvider";


let recaptchaVerifier: firebase.auth.RecaptchaVerifier

type Step = 'phone' | 'otp' | 'old_logging' | 'verified'

interface TwoFactorAuthSetupProps {
    step: Step,
    onPhone: (phone: PhoneData) => Promise<void>,
    onOtp: (otp: string) => Promise<void>,
    error: Error | null,
    reset: () => void
}

function useTwoFactorAuthSetup(): TwoFactorAuthSetupProps {
  const [verificationId, setVerificationId] = useState('');
  const [step, setStep] = useState<Step>('phone')
  const [error, setError] = useState<Error | null>(null);

  const onError = async (e: any) => {
    if (e.code === 'auth/requires-recent-login') {
      return setStep('old_logging')
    }
    setError(e instanceof Error ? e : new Error(e))
  }

  const onPhone = async (phone: PhoneData) => {
    try {
      const newVerificationId = await initiatePhoneNumberRegistration(phone, recaptchaVerifier)
      setVerificationId(newVerificationId)
      setStep('otp')
    } catch (e: any) {
      await onError(e)
    }
  };

  const onOtp = async (otp: string) => {
    try {
      await completePhoneNumberRegistration(verificationId, otp)
    } catch (e: any) {
      await onError(e)
    }
    setStep('verified')
  }

  const reset = () => {
    setError(null)
    setVerificationId('')
    setStep('phone')
  }

  return { step, onPhone, onOtp, error, reset }
}

export function TwoFactorAuthSetupPage() {
  const { step, onPhone, onOtp, reset, error } = useTwoFactorAuthSetup()

  switch (step) {
    case 'phone': return <PhoneStep onSendOtp={onPhone} error={error} />
    case 'otp': return <OtpStep onVerify={onOtp} onResend={async () => reset() } error={error} />
    case 'verified': return <VerifiedStep />
    case 'old_logging': return <OldLoggingStep />
  }
}

function ThisLayout({ children, error, subheader, avoidLogout }: { children: any, error: Error | null, subheader: string, avoidLogout?: boolean }) {
  const {logout} = useAuthCtx()
  return <LoginLayout error={error}>
    <Header as="h1" className="hero-title">Two-Factor Authentication Setup</Header>
    <p>{subheader}</p>

    <Divider hidden />
    {children}
    <Divider hidden />

    {!avoidLogout && <p>Want to use another account? <Link to="#" onClick={logout}>Click here to Logout</Link></p>}
  </LoginLayout>
}

function PhoneStep(props: { error: Error | null, onSendOtp: (phone: PhoneData) => Promise<void> }) {
  const { formData, handleChange, handleSubmit, submitting, error } = useForm<PhoneData>({
    initialState: {
      phoneNumber: '',
      countryCode: 'US'
    },
    onSubmit: async (data) => {
        await props.onSendOtp(data)
    }
  });

  useEffect(() => {
    recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {'size': 'invisible'})
  }, [])

  return (
  <ThisLayout error={props.error ?? error} subheader={'Enter your phone number to set up Two-Factor Authentication.'}>
    <Form onSubmit={handleSubmit} className="outlineForm">
      <Form.Group widths="equal">
        <Form.Field style={{width: '40%'}}>
          <Dropdown
            fluid
            search
            selection
            upward={false}
            options={countryOptions}
            value={formData.countryCode}
            onChange={(e, {value}) => {
              handleChange({target: {name: 'countryCode', value}} as React.ChangeEvent<HTMLInputElement>)
            }}
          />
        </Form.Field>
        <Form.Field style={{width: '60%'}}>
          <PhoneFormInput
            fluid
            placeholder='Phone Number'
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Group>
      <Container textAlign='center'>
        <Button id="sign-in-button" primary type="submit" className="raised" size='huge' loading={submitting} disabled={submitting}>
          Send OTP Code to my phone
          <Icon name='arrow right' />
        </Button>
      </Container>
    </Form>
  </ThisLayout>
  );
}


function OtpStep(props: { error: Error | null, onVerify: (otp: string) => Promise<void>, onResend: () => Promise<void> }) {
  const [timerExpired, resetTimer] = useTimer(5000)
  const { formData, handleChange, handleSubmit, submitting, error } = useForm({
    initialState: {
      otp: '',
    },
    onSubmit: async () => {
      await props.onVerify(formData.otp);
    }
  });

  const handleResend = async () => {
    resetTimer(7000)
    await props.onResend()
  }

  return (
    <ThisLayout error={props.error ?? error} subheader={'Enter the OTP Code sent to your phone.'}>
      <Form onSubmit={handleSubmit} className="outlineForm">
        <OtpFormInput
          fluid
          placeholder='OTP Code'
          name="otp"
          value={formData.otp}
          onChange={handleChange}
        />
        <Grid centered>
          <Grid.Row centered columns={2}>
            <Grid.Column textAlign='center'>
              <Button primary type="submit" className="raised" size='huge' loading={submitting} disabled={submitting}>
                Verify
                <Icon name='arrow right' />
              </Button>
            </Grid.Column>
            <Grid.Column textAlign='center'>
              <Button primary type="button" className="raised" size='huge' onClick={handleResend} disabled={!timerExpired} loading={!timerExpired}>
                Resend Code
                <Icon name='repeat' style={{ marginLeft: '8px' }} />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </ThisLayout>
  );
}

function VerifiedStep() {
  const {reloadAuth} = useAuthCtx()
  useEffect(reloadAuth);
  return <LoadingBlock message={'Verified'}/>
}

function OldLoggingStep() {
  const {logout} = useAuthCtx()
  return <ThisLayout error={null} subheader={''} avoidLogout={true}>
    <p>You've been logged in for a while.</p>
    <p>Please, login again to make this process safer.</p>
    <Button primary type="submit" className="raised" size='huge' onClick={logout}>Go back to Login</Button>
  </ThisLayout>
}
