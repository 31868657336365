import React from "react";
import { Switch, Route } from "react-router-dom";
import { Grid } from 'semantic-ui-react';


import DashboardWrapper from "../DashboardWrapper/DashboardWrapper.component";
import AdminSidebar from "./AdminSidebar";
import CompaniesList from './Companies/CompaniesList.component';
import CompanyView from './Companies/CompanyView.component';
import UsersList from "./Users/UsersList";
import {authStorage} from "../../platform/auth";

class AdminDashboard extends React.Component {

  checkAdminBeforeRender = () => {
    // Check if loading

    // Verify admin status?
    // TBD - make this secure
    const isAdmin = authStorage.getUser().isAdmin();
    if (!isAdmin) return <DashboardWrapper>
      <h1>Access denied. Please contact team@perlstreet.com</h1>
    </DashboardWrapper>

    const path = '/admin';

    // Now render the default dashboard and project list
    return (
      <DashboardWrapper fullscreen>
        <div style={{ backgroundColor: "#fbfbfb", minHeight: "100vh" }}>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={3}>
                <AdminSidebar />
              </Grid.Column>
              <Grid.Column width={13}>
                <Switch>
                  <Route exact path={`${path}/`} component={CompaniesList} />
                  <Route exact path={`${path}/companies`} component={CompaniesList} />
                  <Route exact path={`${path}/companies/:id`} component={CompanyView} />
                  <Route exact path={`${path}/users`} component={UsersList} />
                </Switch>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </DashboardWrapper>
    );
  }

  render() {
    return(
      <React.Fragment>
        {this.checkAdminBeforeRender()}
      </React.Fragment>
    );
  }
}

export default AdminDashboard;
