import firebase from "firebase";
import {PsUser} from "../auth";

let instance: Analytics | null = null;

export function initialiseAnalytics() {
    firebase.analytics.isSupported().then((suppported)=> {
        if(suppported){
            instance = new Analytics(firebase.analytics());
        } else {
            console.error('Analytics is not supported in this environment not initialising');
            instance = null;
        }
    })
}

export function analytics(): Analytics | null {
    if (instance === null) {
        console.error('Analytics not initialised (use initialiseAnalytics)');
    }
    return instance;
}

// Note: Not export anything below this line

class Analytics {
    constructor(private readonly fb: firebase.analytics.Analytics) {}

    reportScreenView(screenName: string){
        // @ts-ignore
        this.fb.logEvent('screen_view', {
            screen_name:  screenName
        });
    }

    reportSignUp(customer: any) {
        this.fb.logEvent('sign_up', {method: 'App', ...customerData(customer)});
    }

    reportIntakeFormInteractionStageReached(customer: any) {
        this.fb.logEvent('intake_form_interaction_stage_reached', customerData(customer));
    }

    reportFirstIntakeFormSubmission(customer: any) {
        this.fb.logEvent('first_intake_form_submitted', customerData(customer));
    }

    reportSecondIntakeFormSubmission(customer: PsUser) {
        this.fb.logEvent('second_intake_form_submitted', customerData(customer));
    }

    reportHatVersionCreated(currentUser: PsUser, version: any) {
        this.fb.logEvent('hat_version_created', {...customerData(currentUser), ...versionData(version)});
    }

}


function customerData(customer: PsUser) {
    return {
        customerId: customer?.id ?? '',
        customerMail: customer?.email ?? '',
        companyId: customer?.data?.userCompany?.id ?? ''
    }
}

function versionData(version: any) {
    return {
        versionId: version?.id ?? '',
        projectId: version?.entityId ?? '',
        companyId: version?.companyId ?? ''
    }
}