export class DomainError extends Error {
    constructor(message?: string, public entity?: any, stack?: string) {
        super(message)
        this.name = this.constructor.name
        if (stack) {
            this.stack = stack
        }
    }
}

export class NotImplementedException extends DomainError {}
export class UnreachableException extends DomainError {}
export class NotInitializedException extends DomainError {}
export class LogicError extends DomainError {}
export class EmptyResults extends DomainError {}
export class ObjectNotFound extends DomainError {}
export class UserDataNotFound extends ObjectNotFound {}
export class ObjectAlreadyExists extends DomainError {}
export class UserAlreadyExists extends ObjectAlreadyExists {}
export class InvalidArgument extends DomainError {
    constructor(message?: string | [string, unknown], entity?: any, stack?: string) {
        super(Array.isArray(message) && message.length === 2 ? `${message[0]}: ${message[1]}` : message, entity, stack)
    }
}
export class NullArgument extends InvalidArgument {
    constructor(message?: string, entity?: any, stack?: string) { super(message, entity, stack) }
}
export class UnsupportedOperation extends DomainError {}
export class LoginRequired extends DomainError {}
export class ExpiredAuthToken extends DomainError {}
export class PermissionDenied extends DomainError {}
export class PreconditionFailed extends DomainError {}
export class UnprocessableEntity extends DomainError {}

export const skip = (message: string | number): undefined => recover(message, undefined)
export function recover<T>(message: string | number, value: T): T {
    const error = new LogicError(message.toString())
    if (process.env.NODE_ENV === 'test') {
        throw error
    } else {
        console.error(error)
    }
    return value
}

export const EXPIRED_AUTH_TOKEN_CODE = 'expired-token'
