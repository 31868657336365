import React from 'react';
import { Segment, Container, Divider, Header, Button, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import BrandColors from '../../utils/brandColors';

const FooterCTA = () => (
  <Segment vertical className="white" style={{ paddingBottom: 0 }}>

    <Container text textAlign="center">

      <Divider hidden />

      <Header as='h2' className="hero-header">
        Scale with <span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>Perl Street</span> 
      </Header>
      <p className="cta-subtitle">
        Mobilize capital by building bankable projects
      </p>
      <Button 
        as={Link} to="/signup" 
        color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='large' icon="arrow right" 
        labelPosition="right" content="Let's Get Started" 
      />

      <p className="cta-footerText">
        Reach out to <a href="mailto:team@perlstreet.com">team@perlstreet.com</a> to learn more
      </p>

      <Divider hidden />
      <Divider hidden />
      <Divider hidden />

    </Container>

    <Divider hidden />

    <Segment basic textAlign='center' style={{ paddingBottom: "0" }}>
      <a href="https://www.ycombinator.com/companies/perl-street" rel='noopener noreferrer' target="_blank">
        <Message
          style={{ textAlign: 'center' }}
          compact
          floating
          attached="top"
          color='purple'
          header='We are hiring right now! Join our growing team!'
          content="Check out our open positions or inquire at team@perlstreet.com"
        />
      </a>
    </Segment>

  </Segment>
);

export default FooterCTA;