import {normalizeString} from "./line-item-utils/coding.utils";

export interface LineItemStoreLogsDto {
    information: string[];
    err: string[];
    warning: string[];
}
export class LineItemStoreLogs {
    private information: Set<string>= new Set<string>();
    private warning: Set<string>= new Set<string>();
    private err: Set<string>= new Set<string>();
    constructor() {}

    clear(){
        this.information = new Set<string>();
        this.warning = new Set<string>();
        this.err = new Set<string>();
    }

    serialize(): LineItemStoreLogsDto{
        return {information: Array.from(this.information), warning: Array.from(this.warning), err: Array.from(this.err)}
    }

    static deserialize(logsSerialized: LineItemStoreLogsDto): LineItemStoreLogs{
        const logsInstance = new LineItemStoreLogs();
        logsInstance.information = new Set([...(logsSerialized?.information ?? [])]);
        logsInstance.warning = new Set([...(logsSerialized?.warning ?? [])]);
        logsInstance.err = new Set([...(logsSerialized?.err ?? [])]);
        return logsInstance;
    }

    static merge(...instances: LineItemStoreLogs[]){
        const mergedInstance = new LineItemStoreLogs();
        for (const instance of instances) {
            for(const infoLog of instance.information){
                mergedInstance.info(infoLog)
            }
            for(const warningLog of instance.warning){
                mergedInstance.warn(warningLog)
            }
            for(const errorLog of instance.err){
                mergedInstance.error(errorLog)
            }
        }
        return mergedInstance;
    }

    info(msg: string){
        const normalizedMsg = normalizeString(msg);
        if(!this.information.has(normalizedMsg)) this.information.add(msg);
    }

    warn(msg: string){
        const normalizedMsg = normalizeString(msg);
        if(!this.warning.has(normalizedMsg)) this.warning.add(msg);
    }

    error(msg:string){
        const normalizedMsg = normalizeString(msg);
        if(!this.err.has(normalizedMsg)) this.err.add(msg);
    }
}