import React from 'react';
import { Button, Modal, Icon, Form, Divider, List } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { startAddCompany } from '../../../redux/companies/companies.actions';
import { selectCompaniesStatus } from '../../../redux/companies/companies.selectors';
import {authStorage} from "../../../platform/auth";

class CompanyCreate extends React.Component {
  state = {
    open: false,
    errors: [],

    apiStatus: "idle",

    companyName: "",
    // logoUrl: "",
    memberEmailToAdd: "",
    memberRoleToAdd: "",

    newCompanyMembers: []
  }

  resetModal = () => {
    this.setState({
      open: false,
      errors: [],
      companyName: "",
      // logoUrl: "",
      memberEmailToAdd: "",
      memberRoleToAdd: "",
      newCompanyMembers: []
    });
  }

  getUserArrayWithAdmin = () => {
    const { newCompanyMembers } = this.state;
    const currentUser = authStorage.getUser();

    // Add current user as an admin in array
    const membersArray = [...newCompanyMembers, { email: currentUser.email, role: "admin" }];
    return membersArray;
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { startAddCompany } = this.props;
    const currentUser = authStorage.getUser();

    const { companyName, 
      // logoUrl 
    } = this.state;

    // TBD handle input errors and check for blanks

    // Build company document
    const allInvites = this.getUserArrayWithAdmin();
    // const memberEmails = allMembers.map(member => member.email);
    // const adminEmails = allMembers.filter(member => member.role === "admin").map(member => member.email);
    const currentUserDisplayName = currentUser.getProfile();
    const companyData = {
      name: companyName,
      // logoUrl,
      allInvites,
      invitedBy: currentUserDisplayName ? `${currentUserDisplayName} (${currentUser.email})` : currentUser.email
    }
    
    try {
      // Dispatch company create action
      this.setState({ apiStatus: "loading" })
      await startAddCompany(companyData);
    } catch (error) {
      console.error(error)
      this.setState({ apiStatus: "failed", error });
    } finally {
      this.setState({ 
        apiStatus: "success", 
        companyName: "",
        // logoUrl: "",
        memberEmailToAdd: "",
        memberRoleToAdd: "",
        newCompanyMembers: [] 
      });
      this.setOpen(false);
    }
    
  }

  addMemberToList = () => {
    let { newCompanyMembers, memberEmailToAdd, memberRoleToAdd } = this.state;
    newCompanyMembers.push({ email: memberEmailToAdd, role: memberRoleToAdd || "member" });
    this.setState({ newCompanyMembers, memberEmailToAdd: "", memberRoleToAdd: "" });
  }

  removeMemberFromList = (email) => {
    let { newCompanyMembers } = this.state;
    newCompanyMembers = newCompanyMembers.filter((member) => member.email !== email);
    this.setState({ newCompanyMembers });
  }

  handleChange = (event, data) => {
    const { value, name } = data;
    this.setState({ [name]: value });
  }

  setOpen = (newState) => {
    this.setState({ open: newState });
  } 

  renderNewMembersList = () => {
    const currentUser = authStorage.getUser();
    const membersArray = this.getUserArrayWithAdmin();

    return (
      <List relaxed animated>
        {membersArray.map((member, member_i) => {
          return (
            <List.Item key={member.id || member_i}>
              { member.email !== currentUser.email ? (
                <List.Content floated='right'>
                  <Link to="#" onClick={() => this.removeMemberFromList(member.email)}><Button size="tiny" compact>Remove</Button></Link>
                </List.Content>
              ) : null}
              <List.Icon name="user" />
              {/* { company.logoUrl ? <Image src={company.logoUrl} avatar /> : <List.Icon name="building" /> } */}
              <List.Content>
                <List.Header>
                  <Link to={"#"}>{member.email}</Link>
                </List.Header>
                <List.Description>
                  {member.role}
                </List.Description>
              </List.Content>
            </List.Item>
          )
        })}
      </List>
    );
  }

  render() {
    const { 
      open, companyName,
      // memberEmailToAdd, memberRoleToAdd, logoUrl, apiStatus,  
    } = this.state;
    const { size, className, title, prompt, icon, labelPosition, companiesStatus } = this.props;
    const currentUser = authStorage.getUser();
    return (
      <Modal
        onClose={() => this.setOpen(false)}
        onOpen={() => this.setOpen(true)}
        open={open}
        trigger={
        <Button primary size={size || "medium"} labelPosition={labelPosition || "left"} icon className={className} disabled={!currentUser || companiesStatus === "loading"} loading={!currentUser || companiesStatus === "loading"} >
          {prompt || "Add New Company"}
          <Icon name={icon || 'plus'} />
        </Button>
      }
      >
        <Modal.Header>{title || "Create a New Company"}</Modal.Header>
        <Modal.Content> 
          <Modal.Description>

            <Form size='large' onSubmit={this.handleSubmit} className="outlineForm">
              <Form.Input 
                fluid
                placeholder='Company Name' 
                name="companyName" 
                value={companyName}
                onChange={this.handleChange} 
              />
              {/* <Form.Input 
                fluid
                placeholder='Logo URL (optional)' 
                name="logoUrl" 
                value={logoUrl}
                onChange={this.handleChange} 
                disabled
              /> */}
              <Divider hidden />
              {/* <Header size="tiny">Add Company Members Below</Header>
              <Divider /> */}
              {/* <Form.Group widths='equal'>
                <Form.Input 
                  fluid
                  icon='mail' 
                  placeholder='Email Address' 
                  name="memberEmailToAdd" 
                  value={memberEmailToAdd}
                  onChange={this.handleChange} 
                  disabled
                />
                <Form.Field>
                  <Dropdown 
                    disabled
                    name="memberRoleToAdd" placeholder='Select Member Status' className='link item' fluid selection options={newMemberOptions} value={memberRoleToAdd || "member"} onChange={this.handleChange} 
                  />
                </Form.Field>
                <Button type="button" content="Add" onClick={this.addMemberToList} disabled={!memberEmailToAdd || companiesStatus === "loading"} role='button'/>
              </Form.Group> */}
              {/* {this.renderNewMembersList()} */}
            </ Form>

          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            // labelPosition='left'
            // icon
            primary
            onClick={this.handleSubmit}
            disabled={companiesStatus === "loading" || !companyName}
            loading={companiesStatus === "loading"}
          >
            Create Company
          </Button>
          <Button negative onClick={this.resetModal} icon labelPosition="left" type="button">
            <Icon name="cancel" />
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  companiesStatus: selectCompaniesStatus
});

const mapDispatchToProps = dispatch => ({
  startAddCompany: (companyData) => dispatch(startAddCompany(companyData))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreate);