import React from "react";
import {QueryResult} from "../../../ps-models/lineitems-store";
import {NRNTimeSeriesChart} from "./NRNTimeSeriesChart";

export function RevenueVsLoanCommitments({queryResult}: { queryResult: QueryResult }) {
    return <NRNTimeSeriesChart title={"Revenue vs Loan Commitments"} result={queryResult}
                            options={{
                                stackingConfig: {},
                                includeLineItems: [{name: 'Net Revenue', type: 'line'}, {name: 'Principal Repayment', type: 'bar'}, {name: 'Interest Payment', type: 'bar'}]
                            }}/>
}