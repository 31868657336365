import {LineItemsStore, StoreQuery} from "../../../../ps-models/lineitems-store";
import {formatRows, fromNestedList} from "../../../../lineitems-store/TableFormat";
import {fromPairs} from "ramda";
import {LineItemsTable} from "../../../../lineitems-store/LineItemsTableView";
import {
    buildMonthlyTimeDef,
    buildTimedCalculatedLineItem
} from "../../../../ps-models/line-items";
import {Grid, Icon, Segment} from "semantic-ui-react";
import {Metric} from "../../../../statistics/Metric";
import React from "react";
import {storeValueTypeField} from "../../../../ps-models";
import {AlertsLineItemTable, SimpleCalculatedLineItemEditor} from "../../../../lineitems-store/Alerts";
import {useUpdateContext} from "../../../../UpdateContext";
import {ClosableSection} from "../../../../ClosableSection";

export const loanMonitoringReportTableItems = [
    'Debt Balance',
    'Cash Balance',
    'CFADS',
    'Interest Payment',
    'Debt Payment',
    'Facility Size',
    'Borrowing Base',
    'Facility Check',
    'Drawdown Available',
    'Current Value',
];

export function LoanMonitoringReport({store}: {store: LineItemsStore}) {
    let result = store.query(StoreQuery.byNames(loanMonitoringReportTableItems).monthly());
    let formattedReportTableResult = formatRows(fromNestedList({
        ...fromPairs(loanMonitoringReportTableItems.map((item) => [item, []]))
    }), result);


    let metrics = store.query(StoreQuery.byNames(loanMonitoringReportTableItems).or(StoreQuery.withField('alertOf')).monthly());

    return <>
        <Segment>
            <Grid
                className={"InvestorDashboard"}
                style={{marginLeft: 0, marginRight: 0, marginTop: '0.2rem', border: '1px solid rgba(34, 36, 38, 0.15)'}}
            >
                <Grid.Row divided style={{justifyContent: 'center'}} textAlign={'center'}><h5>Values for the last month</h5></Grid.Row>
                <Grid.Row>
                    {loanMonitoringReportTableItems.map((lineItemName, colIndex) => (
                        <Grid.Column key={colIndex}
                                     computer={5}
                                     tablet={8}
                                     mobile={16}
                        >
                            <Metric label={lineItemName}
                                    value={metrics.lastTimeSlotTextOf(lineItemName)}
                            />
                        </Grid.Column>
                    ))}
                </Grid.Row>
            </Grid>
                {store && <LoanAlertsSection store={store} />}
            <LineItemsTable queryResult={formattedReportTableResult} />
        </Segment></>
}


export const addLoanMonitoringCalculatedLineItems = (store: LineItemsStore) => {
    store.getDataSet().addLineItems([
        buildTimedCalculatedLineItem('CFADS',
            buildMonthlyTimeDef(),
            //language=JavaScript
            `"Net Cash Flow" - "Net Opex"`,
            storeValueTypeField('dollars')
        ),
        buildTimedCalculatedLineItem('Interest Payment',
            buildMonthlyTimeDef(),
            //language=JavaScript
            `"Debt Balance" * ("Annual Interest Rate" / 12)`,
            storeValueTypeField('dollars')
        ),
        buildTimedCalculatedLineItem('Debt Payment',
            buildMonthlyTimeDef(),
            //language=JavaScript
            `"Interest Payment" + "Principal Repayment"`,
            storeValueTypeField('dollars')
        ),
        buildTimedCalculatedLineItem('Net Asset Cost Basis',
            buildMonthlyTimeDef(),
            //language=JavaScript
            `"Original_Asset_Value__c"`,
            storeValueTypeField('dollars')
        ),
        buildTimedCalculatedLineItem('Borrowing Base',
            buildMonthlyTimeDef(),
            //language=JavaScript
            `"Net Asset Cost Basis" - "Advance Ratio"`,
            storeValueTypeField('dollars')
        ),
        buildTimedCalculatedLineItem('Facility Check',
            buildMonthlyTimeDef(),
            //language=JavaScript
            `"Facility Size" - "Borrowing Base"`,
            storeValueTypeField('dollars')
        ),
        buildTimedCalculatedLineItem('Drawdown Available',
            buildMonthlyTimeDef(),
            //language=JavaScript
            `"Borrowing Base" - "Debt Balance"`,
            storeValueTypeField('dollars')
        )
        ]
    )
    addLoanMonitoringAlertLineItems(store)
}

const addLoanMonitoringAlertLineItems = (store: LineItemsStore) => {
    store.getDataSet().addLineItems([
    buildTimedCalculatedLineItem(
        "Minimum DSCR - alert",
        buildMonthlyTimeDef(),
        //language=JavaScript
        `"CFADS"/"Debt Payment" < "Minimum DSCR"`,
        {
            "alertOf": "Minimum DSCR"
        }
    ),
    buildTimedCalculatedLineItem(
        "Minimum Cash Balance - alert",
        buildMonthlyTimeDef(),
        //language=JavaScript
        `"Cash Balance" - "Debt Payment" < "Minimum Cash Balance"`,
        {
            "alertOf": "Minimum Cash Balance"
        }
    ),
    ])
}

function LoanAlertsSection({store}: {
                               store:LineItemsStore
}) {

    useUpdateContext();

    const aggregatedViewQuery = StoreQuery.all()
        .aggregateOverTimeRange(store.timeIndex.startDate, store.timeIndex.endDate)

    let aggregatedStore = store.materializeTimed(aggregatedViewQuery);

    let dscrAndRelatedAlerts = aggregatedStore.query(
        StoreQuery.byNames(['Minimum DSCR', 'Minimum DSCR - alert'], true)
    );
    let cashBalanceAndRelatedAlerts = aggregatedStore.query(
        StoreQuery.byNames(['Minimum Cash Balance', 'Minimum Cash Balance - alert'], true)
    );

    let highAlert = dscrAndRelatedAlerts.rows.length > 1 && dscrAndRelatedAlerts.firstTimeSlotValueOf('Minimum DSCR - alert') ?
        "DSCR Check Violated" : "";

    let mediumAlert = cashBalanceAndRelatedAlerts.rows.length > 1 && cashBalanceAndRelatedAlerts
        .firstTimeSlotValueOf('Minimum Cash Balance - alert') ?
        "Cash Reserve Check Violated" : "";

    return <ClosableSection
        customStyles={{margin: '2em 0'}}
        opened={false}
        title={<><strong style={{fontSize: "16px"}}>
            {(highAlert || mediumAlert) && <Icon name="warning circle" color="red" />}
            Alerts
        </strong>
        </>}
        level="title-bar"
    >
        <SimpleCalculatedLineItemEditor store={store}
                                        dispatcher={(onRowSelected) =>
                                            <Grid>
                                                <Grid.Row columns={2}>
                                                    <Grid.Column>
                                                        {dscrAndRelatedAlerts.rows.length === 1 && <Segment textAlign={'center'}>Provide a value for Minimum DSCR in the Loan Definition Parameters Section to see related alerts</Segment>}
                                                        {dscrAndRelatedAlerts.rows.length > 1 && <AlertsLineItemTable
                                                            onRowSelected={onRowSelected}
                                                            results={dscrAndRelatedAlerts!} store={store!}
                                                        />}
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        {cashBalanceAndRelatedAlerts.rows.length === 1 && <Segment textAlign={'center'}>Provide a value for Minimum Cash Balance in the Loan Definition Parameters Section to see related alerts</Segment>}
                                                        {cashBalanceAndRelatedAlerts.rows.length > 1 && <AlertsLineItemTable
                                                            onRowSelected={onRowSelected}
                                                            results={cashBalanceAndRelatedAlerts!}
                                                            store={store!}
                                                        />}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>}/>

    </ClosableSection>
}