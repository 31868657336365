import {Projects} from "../../projects/Projects.page";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getLoans, Loan} from "./loans.client";
import {useCompanyId} from "../../../core";
import {getAmProjects} from "../../projects/amProject.client";
import {SmallLoading} from "../../../ui/Loading";


export function LoanProjects() {

  const loanId = useParams<{loanId: string}>().loanId;
  const companyId =  useCompanyId();

  let [loan, setLoan] = useState<Loan>();

  useEffect(() => {
    async function load() {
      let loans = await getLoans(companyId);
      let loan = loans.find((loan) => loan.id === loanId);
      console.info('loan', loan);
      setLoan(loan);
    }
    load();
  }, [loanId]);

  const getProjects = async () => {
    let projects = await getAmProjects(companyId);

    console.info('projects', projects);
    return projects.filter((project) => loan?.projectIds.includes(project.name!));
  }

  if(!loan) {
    return <SmallLoading />
  }



  return (
    <div>
      <Projects
        title={`Projects | ${loan.name}`}
        getProjects={getProjects}
      />
    </div>
  );
}