import ActionTypes from './admin.types';
import ScenarioActionTypes from '../scenario/scenario.types';

const reassignIdInArrayProperty = (arr, property, objectId, propertyId) => {
  const newArray = arr.map(c => {
    if (c.id === objectId) {
      return {
        ...c,
        [property]: c[property] ? [ ...new Set([...c[property], propertyId])] : [propertyId]
      }
    } else {
      return {
        ...c,
        [property]: c[property] ? c[property].filter(sid => sid !== propertyId) : null
      }
    }
  });
  return newArray;
}

const INITIAL_STATE = {
  status: 'idle',

  companies: {
    data: [],
    status: 'idle', // enum: 'idle' | 'loading' | 'success' | 'failed',
  },

  users: {
    data: [],
    status: 'idle',
  }
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_COMPANIES_FAILURE:
      return {
        ...state,
        companies: {
          ...state.companies,
          status: 'failed'
        }
      }
    case ActionTypes.GET_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          status: 'failed'
        }
      }
    case ActionTypes.GET_USERS_START:
      return {
        ...state,
        users: {
          ...state.users,
          status: 'loading'
        }
      }
    case ActionTypes.GET_COMPANIES_START:
      return {
        ...state,
        companies: {
          ...state.companies,
          status: 'loading'
        }
      }
    case ActionTypes.ASSIGN_USER_SUCCESS:
      return {
        ...state,
        companies: {
          ...state.companies,
          data: reassignIdInArrayProperty(state.companies.data, "members", action.payload.companyId, action.payload.userId)
        }
      }
    case ScenarioActionTypes.ASSIGN_SCENARIO_SUCCESS:
      return {
        ...state,
        companies: {
          ...state.companies,
          data: reassignIdInArrayProperty(state.companies.data, "scenarios", action.payload.companyId, action.payload.scenarioId)
        }
      }
    case ActionTypes.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: {
          ...state.companies,
          data: action.payload,
          status: 'idle'
        }
      };
    case ActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          data: action.payload,
          status: 'idle'
        }
      };
    default:
      return state;
  }
}

export default reducer;