const ActionTypes = {

  // Create sharable project token
  CREATE_SHARETOKEN_STARTED: 'createProjectSharetoken/started',
  CREATE_SHARETOKEN_SUCCESS: 'createProjectSharetoken/success',
  CREATE_SHARETOKEN_FAILED: 'createProjectSharetoken/failed',

  // Calculate Project Data
  CALC_PROJECT_STARTED: 'calcProject/started',
  CALC_PROJECT_SUCCESS: 'calcProject/success',
  CALC_PROJECT_FAILED: 'calcProject/failed',

  // Load Project
  LOAD_PROJECT_STARTED: 'loadProject/started',
  LOAD_PROJECT_SUCCESS: 'loadProject/success',
  LOAD_PROJECT_FAILED: 'loadProject/failed',

};

export default ActionTypes;