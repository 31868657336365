import React, {useRef} from "react";
import {
    PDFPreviewerWithVisibilityController
} from "../../../../components/PDFPreviewer/PdfPreviewerWithVisibilityController";
import {Grid, Table, TableCell, TableHeaderCell, TableRow} from "semantic-ui-react";
import {CompanyLogo} from "./CompanyLogo";

interface InvoiceData {
    number: string,
    period: string,
    date: string,
    dueAt: string,
    offTakerName: string,
    offTakerAddress: string,
    totalPVGeneration: string;
    PPARate: string;
    PPARevenue: string;
    discountToPPARate: string;
    ppaDiscount: string;
    netRevenue: string;
}
export function MonthlyInvoicePreview({invoiceData, onDocumentDownload}: {
    invoiceData: InvoiceData, onDocumentDownload: ()=>void
}) {
    const pdfContentPage1Ref = useRef<HTMLDivElement | null>(null);
    const pageRefArray = [pdfContentPage1Ref]

    const preparedPageContent = pageContents(invoiceData)

    return (<PDFPreviewerWithVisibilityController
                        pageContents={preparedPageContent}
                        pagesRefArray={pageRefArray}
                        previewCTA={'Generate Invoice'}
                        downloadedFileBaseName={`${invoiceData.period ? `${invoiceData.period}_`: ''}Invoice`}
                        appendGenerationDateToFileName={true}
                        onDownloadComplete={onDocumentDownload}
                        customizationsForPreviewCTABtn={{
                            icon:'file pdf outline'
                        }}
                    />
    );
}

const pageContents = (invoiceData: InvoiceData) => {
    const page1Content =  <>
        <Grid>
            <Grid.Row>
                <CompanyLogo/>
            </Grid.Row>
            <Grid.Row columns={1} style={{paddingTop:0}}>
                <Grid.Column width={16} textAlign={'right'}>
                    <strong>PPA INVOICE</strong>
                    <Grid.Row>
                        Invoice #: {invoiceData.number}
                    </Grid.Row>
                    <Grid.Row>
                        Invoice Period: {invoiceData.period}
                    </Grid.Row>
                    <Grid.Row>
                        Invoice Date: {invoiceData.date}
                    </Grid.Row>
                    <Grid.Row>
                        Invoice Due: {invoiceData.dueAt}
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <strong>Bill To:</strong>
                    <Grid.Row>
                        {invoiceData.offTakerName}
                    </Grid.Row>
                    <Grid.Row>
                        {invoiceData.offTakerAddress}
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Table celled>
                    <Table.Header>
                        <TableHeaderCell textAlign={'center'}>
                            Description
                        </TableHeaderCell>
                        <TableHeaderCell textAlign={'center'}>
                            Qty
                        </TableHeaderCell>
                        <TableHeaderCell textAlign={'center'}>
                            Unit Price
                        </TableHeaderCell>
                        <TableHeaderCell textAlign={'center'}>
                            Total
                        </TableHeaderCell>
                    </Table.Header>
                    <Table.Body>
                        <TableRow>
                            <TableCell>
                                Energy Consumption
                            </TableCell>
                            <TableCell>
                                {invoiceData.totalPVGeneration} kWh
                            </TableCell>
                            <TableCell>
                                ${invoiceData.PPARate}/kWh
                            </TableCell>
                            <TableCell>
                                {invoiceData.PPARevenue}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={'3'}>
                                Discount @ {invoiceData.discountToPPARate}%
                            </TableCell>
                            <TableCell>
                                {invoiceData.ppaDiscount}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={'3'}>
                                <strong>TOTAL Amount Due</strong>
                            </TableCell>
                            <TableCell>
                                {invoiceData.netRevenue}
                            </TableCell>
                        </TableRow>
                    </Table.Body>
                </Table>
            </Grid.Row>
        </Grid>
    </>

    return [
        page1Content,
    ];
}