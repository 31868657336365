import {LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import React from "react";
import {StoreQuery} from "../../../ps-models/lineitems-store";
import {useSiteStoreContext} from "../../siteStoreLoader";
import {Header, Icon, Segment} from "semantic-ui-react";
import {TimeSeriesChart} from "../../../lineitems-store/TimeSeriesChart";
import {buildStoreWithPxxRevenue} from "./storeFormulas";
import {DemoTimeSeriesChart} from "./DemoTimeSeriesChart";



export function CashFlowVariance() {
  const store = useSiteStoreContext();

  let revenueLineItems = [
    'Net Revenue - Projected',
    'Net Revenue',
    'Variance'
  ]

  let mat = store
    .materializeTimed(StoreQuery.all().withTimeIndex(store.timeIndex.withGranularity('months')))
   console.info("mat", mat)
   let cashflowVariance =  mat.query(StoreQuery.byNames([...revenueLineItems]));

  return <Segment basic>

      <Header as="h2" color="purple">Cash Flow Variance</Header>
    <PXXRevenues />
  </Segment>
}

export function PXXRevenues() {

  let {yearActualsWithStats} = buildStoreWithPxxRevenue(useSiteStoreContext());

  let yearActualsResult = yearActualsWithStats.query(StoreQuery.byNames(
    [
      "p50 Revenue",
      "p90 Revenue",
      "Actual Revenue",
      "Variance from p50 Revenue",
      "Variance from p90 Revenue"
    ]
  ));

  return <Segment basic>
    <LineItemsTableWithFormulas hideToolbar queryResult={yearActualsResult} store={yearActualsWithStats}/>
    <DemoTimeSeriesChart title={"Pxx Revenues"} result={yearActualsResult}
                     options={{includeLineItems: [ "p50 Revenue", "p90 Revenue", "Actual Revenue"], type: 'bar'}}
    />
  </Segment>
}