import {MutableRefObject, useState} from "react";
import {Button, ButtonProps} from "semantic-ui-react";
import {PDFPreviewer} from "./PdfPreviewer";

export function PDFPreviewerWithVisibilityController({
                                 pageContents,
                                 pagesRefArray,
                                 PageHeader,
                                 PageFooter,
                                 previewCTA,
                                 downloadedFileBaseName,
                                 onDownloadComplete,
                                 appendGenerationDateToFileName=false,
                                 downloadDisabled=false,
                                 onInputsSave=undefined,
                                 customizationsForPreviewCTABtn
                             }: {
    pageContents: JSX.Element[],
    pagesRefArray: MutableRefObject<HTMLDivElement | null>[],
    PageHeader?: ()=>JSX.Element,
    PageFooter?: ({pageNum, generationDate}: {pageNum: number, generationDate: string})=> JSX.Element,
    previewCTA: string,
    downloadedFileBaseName: string,
    onDownloadComplete: () => void,
    appendGenerationDateToFileName?: boolean,
    downloadDisabled?: boolean,
    onInputsSave?: ()=>void,
    customizationsForPreviewCTABtn?: Omit<ButtonProps,'content' | 'onClick'>
}) {
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    return (
        <>
            <Button color={'purple'} icon={'download'} labelPosition="left"
                    content={previewCTA} onClick={
                () => setIsPreviewOpen(true)}
               {...customizationsForPreviewCTABtn}
            />
            {isPreviewOpen && <PDFPreviewer pageContents={pageContents} pagesRefArray={pagesRefArray} PageHeader={PageHeader} PageFooter={PageFooter}
                          downloadedFileBaseName={downloadedFileBaseName} onDownloadComplete={onDownloadComplete}
                          appendGenerationDateToFileName={appendGenerationDateToFileName}
                          setIsPreviewOpen={setIsPreviewOpen}
                          isPreviewOpen={isPreviewOpen}
                          downloadDisabled={downloadDisabled}
                          onInputsSave={onInputsSave}
            />}
        </>
    );
}