import {getWidgetType} from "./WidgetRegistry";
import {WidgetConfig} from "./WidgetConfig";
import {Input, Segment} from "semantic-ui-react";

export function renderWidgetConfig(config: WidgetConfig, context: any, setConfig: (config: any) => void) {
  return <Segment>

    {config.id !== 'root' && <>
    Machine Name: <Input value={config.machineName}
                         onChange={(e, {value}) =>
                           setConfig({...config, machineName: value})} />
    </>}



    {getWidgetType(config.type).renderConfig(config.config, {
    ...context,
    id: config.id,
    type: config.type,
    parent: config.parent,
    machineName: config.machineName
  }, setConfig)}
    </Segment>
}
