import Chart from "react-apexcharts";
import * as React from "react";
import {ApexOptions} from "apexcharts";
import {QueryResult, TimeColumn} from "../ps-models/lineitems-store";
import {Segment} from "semantic-ui-react";
import {compareNormalized, FinancialValueType, normalizeString, valueAsNumber} from "../ps-models";
import { formatValueWithValueType } from "../ps-models/formatting";

import {ValueType} from "../ps-models/line-items";

export type TimeSeriesChartOptions = {
  type?: ApexChart['type'];
  includeLineItems?: string[] | {name: string, type: ApexChart['type']}[];
  dateFormat?: string;
  stackingConfig?: {
    relativeToLi?: string;
  },
  projectionsStartDate?: Date;
  customValueFormatter?: (value: ValueType, valueType: FinancialValueType, decimalPlacesInValue?: number) => string;
  sharedTooltip?: boolean
}

export interface TimeSeriesChartProps {title: string, result: QueryResult, options?: TimeSeriesChartOptions}

export function TimeSeriesChart({title, result, options}:TimeSeriesChartProps) {


  let series: ApexAxisChartSeries = [];

  let timeLine: TimeColumn[] = result.columns.filter(col => col.type === 'time').map(col => col as TimeColumn);
  let annotations: ApexOptions["annotations"] | undefined;

  if(!options) {
    options = {};
  }

  if(timeLine.length>1 && options.projectionsStartDate){
    const projectionsStartTime = options.projectionsStartDate.getTime();
    const lastDateInTimeLineInMs = timeLine[timeLine.length-1].time
    if(projectionsStartTime < lastDateInTimeLineInMs){
      // @TODO: This is a hack to avoid cases, where the cutoffTime is very near for e.g. at a day's gap, in such cases we still need to cover the remaining width of the graph.
      const anYearBuffer = (1000*60*60*24*400);
      annotations = {
        xaxis:[
          {
            x: projectionsStartTime,
            x2: lastDateInTimeLineInMs + anYearBuffer,
            borderColor: "#FEB019",
            strokeDashArray: 0,
            fillColor: '#bcd85f',
            label: {
              borderColor: "#FEB019",
              style: {
                color: "#fff",
                background: "#FEB019",
              },
              text: 'Projected Data',
              orientation: "horizontal",
            }
          }
        ]
      };
    }}

  let valueType = "number";
  const includeLineItems = (r: any) => options!.includeLineItems? options!.includeLineItems.map((i)=>{
    if(typeof i === "string"){
      return normalizeString(i)
    } else {
      return normalizeString(i.name)
    }
  }).includes(normalizeString(r.name.value as string)) : true;

  //TODO: We need to exclude the relativeToLi from the chart for the y-axis to be correct, however this breakes the percent tooltip, we should try to calculate the percent from result.rows instead
  const excludeRelativeLi = (r: any)=> !options?.stackingConfig?.relativeToLi
    || !compareNormalized(options.stackingConfig.relativeToLi, r.name.value as string)

  const valueTypeMap: Record<string, FinancialValueType> = {}

  //lineItems to process, Limit rows to max 8
  let lineItemsToProcess = result.rows
    .filter(includeLineItems).filter(excludeRelativeLi).slice(0, 8);


  lineItemsToProcess
    .map(row => {
    let data: {x: number, y: number | string}[] = [];
    const name = row.name.text.toString();
    timeLine.forEach((col, index) => {
      valueType = result.rowColumnValue(row, "store_valueType")?.toString() || "";

      valueTypeMap[name] = valueType ? (valueType as FinancialValueType) : "number";

      let value = valueAsNumber(row[col.columnId].value);
      if(isFinite(value)) {
        data.push({x: col.time, y: value })
      }
    });
    const seriesData: any = {name, data};
    if(Array.isArray(options!.includeLineItems) && options!.includeLineItems.some((i)=>typeof i !=="string")){
      const seriesIndex = options!.includeLineItems.findIndex((item)=>(normalizeString((item as {name: string, type: ApexChart["type"]}).name) === normalizeString(name)))
      seriesData.type = (options?.includeLineItems[seriesIndex] as any).type;
    }
    series.push(seriesData);
  });

  const valueFormatter = options?.customValueFormatter || formatValueWithValueType;

  function getFormattedValue(value: number, opts: any): string {
    const itemName = opts?.w?.globals?.seriesNames[opts.seriesIndex];
    const valueTypeForItem = itemName ? (valueTypeMap[itemName] ?? valueType): valueType;
    return valueFormatter(value,valueTypeForItem as FinancialValueType);
  }

  const lineItemsHaveSeparatelySpecifiedType = options?.includeLineItems?.some((i)=>typeof i !=="string") ?? false;

  let defaultOptions: ApexOptions =  {
    chart: {
      stacked: options?.stackingConfig === undefined ? false : true,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
    ...(options?.stackingConfig?.relativeToLi !==undefined ? {
      formatter: function(val, opts) {
        return getRelativePercentageText(options?.stackingConfig?.relativeToLi ?? "", +val, opts);
      }
    }:
        {enabled: false})
    },
    markers: {
      size: 0,
    },
    title: {
      text: title,
      align: 'left'
    },
    yaxis: {
      labels: {
        formatter: function (val:number, opts: any) {
          return getFormattedValue(val, opts);
        },
      },
      // title: {
      //   text: 'Price'
      // },
    },
    xaxis: {
      type: 'datetime',
      labels: {
      ...(options.dateFormat ? {format: options.dateFormat} : {})
      }
    },
    tooltip: {
      shared: lineItemsHaveSeparatelySpecifiedType || options?.sharedTooltip,
      y: {
        formatter: function (val: number, opts) {
          let tooltip = getFormattedValue(val, opts);

          const relativeLiName = options?.stackingConfig?.relativeToLi

          if(relativeLiName!==undefined){
            const relativePercentage = getRelativePercentageText(relativeLiName, val, opts);
            if(relativePercentage !==""){
                tooltip = `Value: ${tooltip} | (% of ${relativeLiName}): ${relativePercentage}`
            }
          }
          return tooltip;
        }
      }
    },
  ...(annotations ? {annotations}: {})
  };


  return <Segment ><Chart
    options={defaultOptions}
    series={series}
    type={(options.type ?? 'line') as any} // @TODO (Jose): Remove as any
    width="100%"
    height="300"
  /></Segment>
}

function getRelativePercentageText(relativeLiName: string, val: number, opts: any): string{
  const relatedLiSeriesIndex = opts.w.globals.seriesNames.findIndex((s:string)=>s === relativeLiName);
  if(opts.seriesIndex === relatedLiSeriesIndex || relatedLiSeriesIndex === -1){
    return "";
  }
  const relatedDataPoint = opts.w.globals.series[relatedLiSeriesIndex][opts.dataPointIndex];
  if(relatedDataPoint === 0){
    return "- %";
  }
  return (val / relatedDataPoint * 100).toFixed(2) + '%';
}
