import {useCompany, useCompanyId} from "../core";
import {ReactElement} from "react";
import {AMModuleNames} from "../ps-types";
import {MODULE_DASHBOARD_REGISTRY} from "./index";
import {getAmProjectConfig} from "../ps-models";


export interface AMModule {
  MainContent(): ReactElement
  MainMenu(): ReactElement
  CompanyLogo(): ReactElement
  Name: AMModuleNames
}

export function useAMCompanyHome(): string {
  let companyId = useCompanyId();
  let company = useCompany();
  const { namespace } = getAmProjectConfig(company);
  return getCompanyHome(companyId, namespace);
}

export function getCompanyHome(companyId: string, amNamespace: string) {
  let manualSetupCompanies = Object.keys(MODULE_DASHBOARD_REGISTRY);

  console.info("Manual Setup Companies", manualSetupCompanies);

  if(manualSetupCompanies.includes(amNamespace)) {
    return `/ps/${companyId}/assets-management/b/`
  }
  return `/ps/${companyId}/am/dashboard`;
}