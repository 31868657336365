import {useParams} from "react-router-dom";
import {ProjectOwner} from "./ps-models";
import {authStorage, PsUser} from "./auth";
import {useSelector} from "react-redux";
import {selectCompaniesData} from "../redux/admin/admin.selectors";
import {Company} from "./ps-types";

const MAIN_LANDING_SITE_URL: Record<string, string> = {
  local: "http://localhost:3000",
  dev: "https://dev.perlstreet.com",
  stg: "https://stg.perlstreet.com",
  prod: "https://perlstreet.com"
}

const reactAppEnv = process.env.REACT_APP_ENV?.trim()?.toLowerCase() ?? "";
export const SITE_URL = MAIN_LANDING_SITE_URL[reactAppEnv] ? MAIN_LANDING_SITE_URL[reactAppEnv]: "https://perlstreet.com"    

export const PS_URLS = (companyId: string) => ({
  projects: (subPage: string = '') => `/ps/${companyId}/project/${subPage}`,
  newProjects: () => `/ps-new/${companyId}/project/`,
  taskWorkflow: (projectId: string) => `/ps-new/${companyId}/task-workflow/${projectId}`,
  facilities: () => `/ps/${companyId}/facilities/`,
  facility: (name: string) => `/ps/${companyId}/facilities/${name}`,
  dashboard: () => `/ps/${companyId}/dashboard/`,
  keyManagement: () => `/ps/${companyId}/key-management/`,
  fileUpload: (projectId: string) => `/ps/${companyId}/project/${projectId}/file-upload`,
  fileValidation: (projectId: string) => `/ps/${companyId}/project/${projectId}/file-validation`,
  fileValidationWithOwner: (owner: ProjectOwner) => `/ps/${companyId}/project/${owner.entityId}/file-validation/${owner.versionId}`,
  glossary: (owner: ProjectOwner) => `/ps/${companyId}/project/${owner.entityId}/glossary/${owner.versionId}`,

  assetsManagement: () => `/ps/${companyId}/assets-management/b`,
  amProjectNewVersion: (projectId: string) => `/ps/${companyId}/am/project/${projectId}/new-version`,
  amMultipleProjectNewVersion: () => `/ps/${companyId}/am/new-projects`,
  amProjectDetails: (projectId: string) => `/ps/${companyId}/am/project/${projectId}`,
  projectExcelMapping: () => `/ps/${companyId}/am/projects/excel-mapping`,
  amCompanyNewModel: () => `/ps/${companyId}/am/company/model/new-model`,

  amDashboardBuilder: () => `/ps/${companyId}/am/board-builder`,
  amDashboardEditor: (dashboardId: string) => `/ps/${companyId}/am/board-builder/${dashboardId}`,

  amProjects: (subPage: string) => `/ps/${companyId}/am/projects/${subPage}`,
  amReports: () => `/ps/${companyId}/am/reports`,
  amReportDetails: (reportId: string) => `/ps/${companyId}/am/report/${reportId}`,
  amInvoiceDetails: (invoiceId: string) => `/ps/${companyId}/am/invoice/${invoiceId}`,
  whatIfScenario(scenarioId: string) {
    return `/ps/${companyId}/am/what-if-scenario/${scenarioId}`;
  }
})

export function useCompanyId(): string {
  const { companyId } = useParams<Record<string, string>>();
  return companyId;
}

export function useCompany(): Company {
  return authStorage.getCompany();
}

export function useCurrentUser(): PsUser {
  return authStorage.getUser();
}

export function usePlatformUrls(): ReturnType<typeof PS_URLS> {
  const companyId = useCompanyId()
  return PS_URLS(companyId)
}