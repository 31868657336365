import {getCompanies} from "../../platform/company/company.client";

export const fsGetLinkedUserData = async () => {
    const companies = await getCompanies();

    let companyData = [];
    let projectIds = [];
    if (companies.length > 0) {
        companyData = companies.map((comp) => {
            const {id, ...data} = comp;
            // projects
            const projects = data.projects;
            if (projects) projectIds = [...projectIds, ...projects];

            // Return company data
            return {
                id,
                ...data,
            };
        });
    }

    // Get linked project data
    let projectsData = [];
    if (projectIds.length) {
        // TODO: Instead make a single endpoint that does return multiple documents in one go.
        //projectsData = await Promise.all(
            //projectIds.map(async (projectId) => getLegacyProjectById(projectId))
        //);
    }

    // Return all linked data
    return {
        companies: companyData,
        projects: projectsData,
    };
};