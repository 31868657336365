import {useCallback, useEffect, useState} from "react";

export function useTimer(timeoutMs: number): [boolean, (newTimeoutMs?: number) => void] {
    const [time, setTime] = useState({value: timeoutMs})
    const [timedOut, setTimedOut] = useState(timeoutMs <= 0)
    useEffect(() => {
        if (time.value <= 0) return
        const id = setTimeout(() => setTimedOut(true), time.value)
        return () => clearTimeout(id)
    }, [time])
    const reset = useCallback((newTimeoutMs?: number) => {
        const newTime = newTimeoutMs || timeoutMs
        setTime({value: newTime})
        setTimedOut(newTime <= 0)
    }, [timeoutMs])
    return [timedOut, reset]
}