import {AdminConfigurableUserData, SignUpUserData} from "../ps-models";
import {
    RegistrationError,
    SignUpPayload,
} from "../auth";
import psPlatformClient from "../psPlatformClient";
import {Company, CompanyCloneDto, CompanyCreateDto, CompanyUpdateDto, MessagePayloadV2} from "../ps-types";

export async function createUser({email, password, signUpUserData}: SignUpPayload, welcomeEmailConfig: Omit<MessagePayloadV2, 'attachments'> & { attachments?:  FileList }): Promise<AdminConfigurableUserData> {
    try {
        const formData = new FormData();
        formData.append('password', password);
        formData.append('signUpUserData', JSON.stringify(signUpUserData));
        formData.append('welcomeEmailConfig', JSON.stringify(welcomeEmailConfig));
        if(welcomeEmailConfig?.attachments){
            for (let i = 0; i < welcomeEmailConfig?.attachments?.length; i++) {
                formData.append('files', welcomeEmailConfig?.attachments[i]);
            }
        }
        const result = await psPlatformClient<
            FormData
        , AdminConfigurableUserData>(
            {
                method: 'post',
                url: `/admin/user`,
                data: formData
            });
        return result.data;
    } catch (err) {
        throw new RegistrationError(`For ${email}`)
    }
}


export async function updateUser(userId: string, userData: Omit<SignUpUserData, 'isNewUser' | 'providerId'>, password?: string): Promise<AdminConfigurableUserData> {
    try {
        const result = await psPlatformClient<
            {userId: string, password?: string, userData: Omit<SignUpUserData, 'isNewUser' | 'providerId'>}
            , AdminConfigurableUserData>(
            {
                method: 'put',
                url: `/admin/user`,
                data: {
                    userId,
                    userData,
                ...(password ? {password}: {})
                }
            });
        return result.data;
    } catch (err) {
        throw new RegistrationError(`For ${userData.profile.email}`)
    }
}

export async function createCompany(companyData: CompanyCreateDto): Promise<Company> {
    const result = await psPlatformClient<
        {companyData: CompanyCreateDto}
        , Company>(
        {
            method: 'post',
            url: `/admin/company`,
            data: {
                companyData,
            }
        });
        return result.data;
}

export async function updateCompany(companyData: CompanyUpdateDto): Promise<Company> {
    const result = await psPlatformClient<
        {companyData: CompanyUpdateDto}
        , Company>(
        {
            method: 'put',
            url: `/admin/company`,
            data: {
                companyData
            }
        });
    return result.data;
}

export async function cloneCompany(companyId: string, companyData: CompanyCloneDto): Promise<Company> {
    const result = await psPlatformClient<
        {companyData: CompanyCloneDto}
        , Company>(
        {
            method: 'post',
            url: `/admin/company/${companyId}/clone`,
            data: {companyData}
        });
    return result.data;
}