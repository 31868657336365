import React, {useEffect, useState} from 'react';
import {LineItemsFieldSet} from "../../ps-models/line-items";
import {indexBy, values} from "ramda";
import {Button, Icon, Input, Segment, Table} from "semantic-ui-react";
import {LineItemField} from "../../ps-types";


export interface EditorParam {
  name: string;
  value: string;
}

interface Props {
  params: Record<string, EditorParam>;
  onUpdate: (updatedData: Record<string, EditorParam>, deleted: string[]) => void;
}

export const MultiParameterEditor: React.FC<Props> = ({ params, onUpdate }) => {
  const [deleted, setDeleted] = useState<string[]>([]);
  const [messages, setMessages] = useState<Record<string, any>>({});

  let fields = values(params);

  const handleUpdate = (key: number, field: keyof LineItemField, value: string) => {
    setMessages(prev => ({...prev, [key]: {...prev[key], [field]: undefined} }));
    const updatedData = fields.map((item, index) => {
      if (index === key) {
        return { ...item, [field]: value };
      }
      return item;
    });

    onUpdate(indexBy(f=> f.name, updatedData), deleted);
  };

  const handleAdd = () => {
    onUpdate(indexBy(f=> f.name,
      fields.concat([{name: '', value: ''}])
    ), deleted);
  };

  const handleDelete = (keyToDelete: number) => {
    setMessages(prev => ({...prev, [keyToDelete]: undefined}));
    setDeleted(prev => prev.concat(fields[keyToDelete].name));
    // setFields(
    //   fields.filter((_, key) => key !== keyToDelete)
    // );
    let updatedData = fields.filter((_, key) => key !== keyToDelete);
    onUpdate(
      indexBy(f=> f.name, updatedData)
    , deleted)
  };

  return (
    <Segment>

      <h5>Project Parameters</h5>

      <Table basic='very' celled collapsing style={{width: "100%"}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
            <Table.HeaderCell>
              <Button icon size="mini" onClick={handleAdd}>
                <Icon name='plus' />
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fields.map((fieldData, key) => (

            <Table.Row key={key}>
              <Table.Cell>
                <Input
                  style={{width: "100%"}}
                  error={!!(messages[key]?.name)}
                  required
                  size="mini"
                  onChange={(e) => handleUpdate(key, "name", e.target.value)}
                  value={fieldData.name} />
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{width: "100%"}}
                  error={!!(messages[key]?.value)}
                  required
                  size="mini"  onChange={(e) => handleUpdate(key, "value", e.target.value)} value={fieldData.value} />
              </Table.Cell>
              <Table.Cell>
                <Button size="mini" icon  onClick={() => handleDelete(key)}>
                  <Icon  name='trash' />
                </Button>
              </Table.Cell>
            </Table.Row>

          ))}
        </Table.Body>
      </Table>

    </Segment>
  );
};

