// Define firebase config based on build level
const BUILD_LEVEL = process.env.REACT_APP_FIREBASE_CONFIG_ENV || "dev";
let firebaseConfig = null;
let cloudFunctionUrl = "https://us-central1-atomscale-dev.cloudfunctions.net/";

console.info("build", BUILD_LEVEL)

if (BUILD_LEVEL === "dev") {
  firebaseConfig = {
    apiKey: "AIzaSyBcnIGL6qdM86wXYs5n3kZOZYfWn_uIeug",
    authDomain: "atomscale-dev.firebaseapp.com",
    databaseURL: "https://atomscale-dev.firebaseio.com",
    projectId: "atomscale-dev",
    storageBucket: "atomscale-dev.appspot.com",
    messagingSenderId: "165081882721",
    appId: "1:165081882721:web:b30f0c60a5667a35168a20",
    measurementId: "G-PXP838E5QS",
  };
  cloudFunctionUrl = "https://us-central1-atomscale-dev.cloudfunctions.net/";
} else if (BUILD_LEVEL === "stg") {
  firebaseConfig = {
    apiKey: "AIzaSyAOjB9-72tYcpAQa8XAJKobGahF7nMtLx8",
    authDomain: "atomscale-stg.firebaseapp.com",
    projectId: "atomscale-stg",
    storageBucket: "atomscale-stg.appspot.com",
    messagingSenderId: "652609390004",
    appId: "1:652609390004:web:45ec2b4f2c158ea26b5ee1",
    measurementId: "G-TTPEZ2SX7M"
  }
  cloudFunctionUrl = "https://us-central1-atomscale-stg.cloudfunctions.net/";
} else if (BUILD_LEVEL === "prod") {
  firebaseConfig = {
    apiKey: "AIzaSyAQeAGM0nx-gK20Zi9-6SQWDOdEK4tO754",
    authDomain: "atomscale-prod.firebaseapp.com",
    databaseURL: "https://atomscale-prod.firebaseio.com",
    projectId: "atomscale-prod",
    storageBucket: "atomscale-prod.appspot.com",
    messagingSenderId: "168455690717",
    appId: "1:168455690717:web:5850777cfd6603e5b47e34",
    measurementId: "G-6K1VLN4RQ9",
  };
  cloudFunctionUrl = "https://us-central1-atomscale-prod.cloudfunctions.net/";
} else {
  console.error(
    `ATTENTION: No firebase build config exists for ${BUILD_LEVEL}`
  );
}

// Export firebase config
export { BUILD_LEVEL, firebaseConfig, cloudFunctionUrl };
