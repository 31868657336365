import React, { useEffect, useState } from "react";
import { Menu, Segment, Icon } from 'semantic-ui-react';
import { useHistory, useLocation } from "react-router-dom";

function AdminSidebar() {
  const baseRoute = 'admin';
  const [activeItem, setActiveItem] = useState("companies");
  const history = useHistory();
  const location = useLocation();

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    history.push(`/${baseRoute}/${name}`);
  };

  useEffect(() => {
    const pathname = location.pathname;
    const adminSubroute = pathname.split('/')[2] || baseRoute;
    setActiveItem(adminSubroute);
  }, [location]);

  return (
    <Segment basic textAlign="left">
      <Menu vertical color="purple" style={{ margin: "auto", width: "100%" }}>

        <Menu.Item>
          <Menu.Header style={{ 
            color: "#9b51e0",
            fontSize: "18px",
            fontWeight: 900,
            margin: 0
          }}>PS Admin Console</Menu.Header>
        </Menu.Item>

        <Menu.Item
          name='companies'
          active={activeItem === 'companies' || activeItem === baseRoute}
          onClick={handleItemClick}
        >
          <Icon className='city' />
          Companies
        </Menu.Item>

        <Menu.Item
          name='users'
          active={activeItem === 'users'}
          onClick={handleItemClick}
        >
          <Icon className='users' />
          Users
        </Menu.Item>

        <Menu.Item
          name='scenarios'
          active={activeItem === 'scenarios'}
          onClick={handleItemClick}
        >
          <Icon className='code branch' />
          Scenarios
        </Menu.Item>

      </Menu>
    </Segment>
  );
}

export default AdminSidebar;