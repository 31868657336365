import React, {Profiler, useEffect, useMemo} from "react";
import {getAmDashboard} from "./Dashboard.client";
import {useCompanyId, useCurrentUser, usePlatformUrls} from "../../core";
import {UpdateProvider, WaitForIt, WaitForItV2} from "../../UpdateContext";
import {
  buildConfigService,
  buildConfigServiceFromDashboard,
  DashboardConfigService,
} from "./DashboardConfigService";
import {WidgetWrapper} from "./WidgetWrapper";
import {DashboardPageStoreCtxLoader} from "./DashboardPageStoreCtxLoader";
import {AmDashboard, DateRangeType} from "../../ps-types";
import {Button} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {Render} from "@measured/puck";
import "@measured/puck/dist/index.css";
import {DASHBOARD_COMPONENTS_CONFIG, extractPuckDataFromDashboardConfig} from "./widgets/puck-widgets/commons";

export function DashboardBoardView({dashboardId, onServiceReady, widgetParams, dashboardData, constrainedViewDateRange, constrainedStoreIds}:{
  dashboardId: string,
  dashboardData?: AmDashboard,
  widgetParams?: Record<string, any>,
  onServiceReady?: (service: DashboardConfigService) => void,
  constrainedViewDateRange?: DateRangeType
  constrainedStoreIds?: string[]
}) {
  let companyId = useCompanyId();
  let isAdmin = useCurrentUser().isAdmin();
  let {amDashboardEditor} = usePlatformUrls()

  return <UpdateProvider loadService={async ()=> {
    let service;
    if(dashboardData) {
      service = await buildConfigService(dashboardData, true, {viewDateRange: constrainedViewDateRange, storeIds: constrainedStoreIds});
    } else {
      service = await buildConfigServiceFromDashboard(companyId, dashboardId, true, {viewDateRange: constrainedViewDateRange, storeIds: constrainedStoreIds});
    }
     onServiceReady?.(service);
    
     return service;
  }} >
    <WaitForItV2>

      {isAdmin && <Link
        style={{position: 'absolute', right: '10px', top: '-35px'}}
        to={amDashboardEditor(dashboardId)}><Button>Edit</Button></Link>}

        <DashboardPageStoreCtxLoader
            render={( service)=> {
              if(service.amDashboardDto.v2){
                  // @TODO: Need to inject widgetParams here...
                return <Render config={DASHBOARD_COMPONENTS_CONFIG}
                               data={extractPuckDataFromDashboardConfig(service, {widgetParams: widgetParams})}
                    />
              } else {
                return <WidgetWrapper widgetId={'root'} context={{
                  appContext: service,
                  widgetParams: widgetParams
                }} />
              }
            }
        }
        />
    </WaitForItV2>
  </UpdateProvider>
}