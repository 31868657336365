import {
  Dropdown,
  Icon,
  Button,
} from 'semantic-ui-react'
import {Link} from 'react-router-dom';
import React from "react";
import {PsUser} from "../../platform/auth";
import {CompanySelectorForAdmins} from "../../platform/CompanySelectorForAdmins";
import {useAuthCtx} from "../../platform/auth/AuthContextProvider";
// Create sidebar options
export const UserDropdownActions = ({ currentUser, renderCompanySelectorForAdmin= false }: {currentUser: PsUser, renderCompanySelectorForAdmin: boolean}) => {
    const {logout} = useAuthCtx();
    return (<>
        {(renderCompanySelectorForAdmin && currentUser && currentUser.isAdmin()) && (
            <CompanySelectorForAdmins />
        )}
            <Dropdown item text={currentUser.email}>
                <Dropdown.Menu>
                    <Dropdown.Header>Currently Logged In</Dropdown.Header>
                    <Dropdown.Item onClick={() => logout()}><Icon name="sign-out"/> Sign Out</Dropdown.Item>
                    {currentUser.isAdmin() && (
                        <>
                            <Dropdown.Header icon='hide' content='FOR PERL STREET ONLY' />
                            <Dropdown.Item ><Button as={Link} to="/admin" primary size='tiny' fluid>
                                Admin Console
                            </Button></Dropdown.Item>
                            <Dropdown.Item ><Button as={Link} to="/projects/deal-mgmt" primary size='tiny' fluid>
                                Deal Management
                            </Button></Dropdown.Item>
                            <Dropdown.Item >
                                <Button as={Link} to={{ pathname: "https://ps-ops-dashboard.web.app/dashboard/projects" }} target="_blank" primary size='tiny' fluid>
                                    Ops Dashboard
                                </Button>
                            </Dropdown.Item>
                            <Dropdown.Item ><Button as={Link} to="/projects/spv-mgmt" primary size='tiny' fluid>
                                SPV Management
                            </Button></Dropdown.Item>
                        </>
                    )}
                </Dropdown.Menu>
            </Dropdown>
    </>
    );
}