export interface AuthError {
    code: string;
    message: string;
}

export function isAuthError(error: unknown): error is AuthError {
    return typeof error === 'object' && error !== null &&
        'code' in error && typeof error.code === 'string' &&
        'message' in error && typeof error.message === 'string'
}

export const GLOBAL_REGION = ''
export const AUSTRALIA_REGION = 'australia'
export const SUPPORTED_REGIONS = [GLOBAL_REGION, AUSTRALIA_REGION]