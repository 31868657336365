import {normalizeString} from "./coding.utils";

let reported: Record<string, boolean> = {}

export function reportLineItemError(msg: string, e?: any) {
  let msgKey = normalizeString(msg);
  if(!reported[msgKey]) {
    reported[msgKey] = true
    console.warn(msg, e || "")
  }
}
