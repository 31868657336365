import { CompanyKey } from "../../ps-types/CompanyKeys";
import psPlatformClient from "../../psPlatformClient";
import { usePromise } from "../../generic.hooks";

export const useCompanyKeys = (searchId: unknown) => {
    return usePromise<CompanyKey[]>(() => psPlatformClient<null, CompanyKey[]>({method: 'post', url: '/company-keys-search'}).then(result => result.data), [searchId])[0] || []
}

export const useDeleteCompanyKeys = () => {
    return (keyId: string) => psPlatformClient<{keyId: string}, null>({method: 'delete', url: '/company-keys', data: {keyId}})
}

export const useCreateCompanyKey = () => {
    return (keyId: string, publicKey: string) => psPlatformClient<{keyId: string, publicKey: string}, null>({method: 'put', url: '/company-keys', data: {keyId, publicKey}})
}
