import React, {useEffect, useState} from 'react';
import {LineItemsFieldSet} from "../../ps-models/line-items";
import {values} from "ramda";
import {Button, Icon, Input, Segment, Table} from "semantic-ui-react";
import {LineItemField} from "../../ps-types";
import {LineItemsStore} from "../../ps-models/lineitems-store";


type ListOfFields =  (Omit<LineItemField, "key">)[]

interface Props {
  initialData: LineItemsFieldSet;
  onUpdate: (updatedData: LineItemsFieldSet, deleted: string[]) => void;
  lineItemName: string,
  store: LineItemsStore
}

export const FieldsEditor: React.FC<Props> = ({ initialData, onUpdate,  lineItemName, store }) => {
  const [deleted, setDeleted] = useState<string[]>([]);
  const [messages, setMessages] = useState<Record<string, any>>({});

  let fields = values(initialData.getFieldMap());

  const handleUpdate = (key: number, field: keyof LineItemField, value: string) => {
    setMessages(prev => ({...prev, [key]: {...prev[key], [field]: undefined} }));
    const updatedData = fields.map((item, index) => {
      if (index === key) {
        return { ...item, [field]: value };
      }
      return item;
    });

    onUpdate(LineItemsFieldSet.fromList(updatedData), deleted);
  };

  const handleAdd = () => {
    onUpdate(LineItemsFieldSet.fromList(([{name: '', value: ''}] as ListOfFields).concat(fields)), deleted);
  };

  const handleDelete = (keyToDelete: number) => {
    setMessages(prev => ({...prev, [keyToDelete]: undefined}));
    setDeleted(prev => prev.concat(fields[keyToDelete].name));
    // setFields(
    //   fields.filter((_, key) => key !== keyToDelete)
    // );
    onUpdate(LineItemsFieldSet.fromList(
      fields.filter((_, key) => key !== keyToDelete)
    ), deleted)
  };

  return (
    <Segment>

        <h5>Fields</h5>

        <Table basic='very' celled collapsing style={{width: "100%"}}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
              <Table.HeaderCell>
                <Button icon size="mini" onClick={handleAdd}>
                  <Icon name='plus' />
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
        {fields.map((fieldData, key) => (

                <Table.Row>
                  <Table.Cell>
                    <Input
                      style={{width: "100%"}}
                      error={!!(messages[key]?.name)}
                      required
                      size="mini"
                      onChange={(e) => handleUpdate(key, "name", e.target.value)}
                           value={`${fieldData.name}${fieldData.isDynamic ? "*" : ""}`} />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      style={{width: "100%"}}
                      error={!!(messages[key]?.value)}
                      required
                      size="mini"  onChange={(e) => handleUpdate(key, "value", e.target.value)}
                      readOnly={fieldData.isDynamic}
                      value={
                        fieldData.isDynamic ? store.getExecution().getField(lineItemName, fieldData.name)?.value : fieldData.value
                      } />
                  </Table.Cell>
                  <Table.Cell>
                    <Button size="mini" icon  onClick={() => handleDelete(key)}>
                     <Icon  name='trash' />
                    </Button>
                  </Table.Cell>
                </Table.Row>

        ))}
          </Table.Body>
        </Table>

    </Segment>
  );
};

