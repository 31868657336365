import {StoreQuery} from "../../../ps-models/lineitems-store";
import React from "react";
import {useSiteStoreContext} from "../../siteStoreLoader";
import {Grid, Header, Segment} from "semantic-ui-react";
import {GridExportTimeSeries} from "./GridExportTimeSeries";
import {WSTimeSeriesChart} from "./WSTimeSeriesChart";

export function AEOvsWAMO() {

  let store = useSiteStoreContext();

  let result = store.query(StoreQuery.all())

  return <Segment basic>
    <Header as="h2" color="purple">AEO vs WAMO</Header>
    <GridExportTimeSeries />
    <WSTimeSeriesChart title={"AEO vs WAMO"} result={result}    options={{includeLineItems: ['AEO60', "WAMO60"]}} />
    <WSTimeSeriesChart title={"AEO60 vs WAMO60"} result={result}  options={{includeLineItems: ['pv_generation_kwh', 'wamo']}}/>

    <WSTimeSeriesChart title={"Grid Export vs BTM Utilization"} result={result}
                     options={{includeLineItems: ['BTM Utilization', "Grid Export"]}}/>


    <WSTimeSeriesChart title={"Revenue"} result={result}
                     options={{includeLineItems:
                         ['Net Export Revenue', 'BTM PPA Revenue']}}/>


    <WSTimeSeriesChart title={"Energy Storage"} result={result}
                     options={{includeLineItems:
                         ['Charged (Predicted)', 'Charged (Actual)', 'Dispatched (Predicted)', 'Dispatched (Actual)']}}/>

    <WSTimeSeriesChart title={"Energy Storage"} result={result}
                     options={{includeLineItems:
                         ['Grid Services Revenue']}}/>

  </Segment>
}