import {LineItemsStore, StoreQuery} from "../../../ps-models/lineitems-store";
import {
    buildDailyTimeDef, buildMonthlyTimeDef,
    buildParameterLineItem,
    buildTimedCalculatedLineItem, buildYearlyTimeDef,
    field, LineItem, LineItemsFieldSet, TimedCalculatedLineItem, TimedRawLineItem
} from "../../../ps-models/line-items";
import {
    buildAnnualTimeIndex,
    buildDailyTimeIndex, entries,
    lineItemAggregatorMapLegacy,
    normalizeMapKeys,
    normalizeString,
    storeValueTypeField, VALUE_TYPE_KEY
} from "../../../ps-models";


export function addStoreFormulas(store: LineItemsStore) {

  let dataSet = store.getDataSet();



  //Simplify the label of the Asset Category line items
  dataSet.getLineItemsWithField('assetCategoryId').forEach(lineItem => {
    let simplerLabel = dataSet.getLineItem(lineItem.name).fields.getFieldStr('label');
    simplerLabel = simplerLabel.replace(/__c/gi, '').replace(/_/gi, ' ')
    dataSet.addFieldToLineItem(lineItem.name, field("label", simplerLabel) )
  });

  let labelsMap = {
    'grid_import_kwh': 'Grid Import',
    'generation_export_kwh': 'Generation Export',
    'all_consumption_kwh': 'Total Consumption',
    'generation_import_kwh': 'Generation Import',
    'pv_generation_kwh': 'PV Generation',
    'grid_export_kwh': 'Grid Export',
    'siteunavailable': 'Site Unavailable',
    'batteryunavailable': 'Battery Unavailable',
    'solarunavailable': 'Solar Unavailable',
    'total_purchase_price__c': 'Total Purchase Price',
    'discounted_purchase_price__c': 'Discounted Purchase Price',
    'current_value_post_dep__c': 'Current Value Post Depreciation',
    'original_asset_value__c': 'Original Asset Value',
    'months_active__c': 'Months Active',
    'monthly_depreciated_value__c': 'Monthly Depreciated Value',
    'Billing_type__c': 'Billing Type'
  }

  for (let [key, value] of Object.entries(labelsMap)) {
    dataSet.addFieldToLineItem(
      key,
      field('label', value)
    )
  }

}

export function setSourceForDemo(store: LineItemsStore) {
  let sourceMap: Record<string, string> = normalizeMapKeys({
    'Debt Balance': 'Sage Intacct',
    'Cash Balance': 'Sage Intacct',
    'Net Cash Flow': 'Financial Analytics Module',
    'Net Asset Cost Basis': 'Financial Analytics Module',
    'Current Value': 'Financial Analytics Module'
  });

  let dataSet = store.getDataSet();

  for(let [key, value] of Object.entries(sourceMap)) {
    dataSet.getByField('store_sourceLineItemName', key).forEach(li => {
      dataSet.addFieldToLineItem(li.name, field('data_source', value))
    });
    dataSet.addFieldToLineItem(
        key,
        field('data_source', value)
    )
  }
}

export function setLabelAndFormatForAggregates(store: LineItemsStore) {

  let labelsMap: Record<string, string> = {
    'grid_import_kwh': 'Grid Import',
    'generation_export_kwh': 'Generation Export',
    'all_consumption_kwh': 'Total Consumption',
    'generation_import_kwh': 'Generation Import',
    'pv_generation_kwh': 'PV Generation',
    'grid_export_kwh': 'Grid Export',
    'siteunavailable': 'Site Unavailable',
    'batteryunavailable': 'Battery Unavailable',
    'solarunavailable': 'Solar Unavailable',
    'total_purchase_price__c': 'Total Purchase Price',
    'discounted_purchase_price__c': 'Discounted Purchase Price',
    'current_value_post_dep__c': 'Current Value Post Depreciation',
    'original_asset_value__c': 'Original Asset Value',
    'months_active__c': 'Months Active',
    'monthly_depreciated_value__c': 'Monthly Depreciated Value',
    'billing_type__c': 'Billing Type',
    'kWp_Solar_System__c': 'kWp Solar System',
    'Battery_kWh__c': 'Battery kWh',
    'Current_Energy_Retailer__c': 'Current Energy Retailer',
    'Original_Asset_Value__c': 'Original Asset Value',
    'State__c': 'State',
    'Post_Code__c': 'Postcode',
    'City__c': 'City',
    'Investment Returns IRR': 'IRR'
  }




  let formatMap: Record<string, string> = normalizeMapKeys({
    'Original_Asset_Value__c': 'dollars',
    'Current_Value_Post_Dep__c': 'dollars',
  });

  for (let [key, value] of Object.entries(formatMap)) {

    store.getDataSet().getByField('store_sourceLineItemName', key).forEach(li => {
      store.getDataSet().addFieldToLineItem(li.name, field('store_valuetype', value))
    });

    store.getDataSet().addFieldToLineItem(
      key,
      field('store_valuetype', value)
    )
  }


  for(let [key, value] of Object.entries(labelsMap)) {
    store.getDataSet().getByField('store_sourceLineItemName', key).forEach(li => {
      let site = store.getDataSet().getLineItem(li.name).fields.getFieldStr('store_sourceName');
      store.getDataSet().addFieldToLineItem(li.name, field('store_label', `${site} - ${value}`))
    });
    store.getDataSet().addFieldToLineItem(
      key,
      field('store_label', value)
    )
  }

  store.getDataSet().addFieldToLineItem(
    "Investment Returns IRR",
    field("store_label", "Portfolio IRR")
  )

}

export function setDemoLenderGroupings(store: LineItemsStore) {
    if (store.getParam('dsType') !== 'spv') {
        return
    }
    const liNames = [
        'Withholding Tax',
        'Interest Payment After Tax',
        'Principal Repayment',
        'Interest Payment',
        'Drawn Balance',
        'Annual Interest Rate',
        'Funds Under Management',
        'Total Depreciation',
    ]

    function timeDef(name: string) {
        return buildDailyTimeDef(name === 'Drawn Balance' ? 'max' : 'sum')
    }

    function firstToUpperCase(str: string) {
        if (str.length <= 1) {
            return str.toUpperCase();
        }
        return str.slice(0, 1).toUpperCase() + str.slice(1);
    }

    // Adding Calculated Line Items
    for (const name of liNames) {
        const li = store.getDataSet().getLineItem(name)
        if (!li || !li.fields.hasField('store_groupingname')) {
            store.getDataSet().addLineItem(
                buildTimedCalculatedLineItem(
                    name,
                    timeDef(name),
                    /* language=JavaScript */ `sum(f('cname', '${name}'))`,
                    {
                      'store_groupingname': 'cname',
                      'store_valueType': 'number',
                    }
                )
            )
        }
    }

    // Fixing existing Raw Line Items
    const liByEnding: Record<string, TimedRawLineItem[]> = {}
    for (const [normalizedName, li] of Object.entries(store.getDataSet().getLineItems())) {
        if (!(li instanceof TimedRawLineItem)) {
            continue
        }
        const parts = normalizedName.split('-')
        if (parts.length < 2) {
            continue
        }

        const ending = parts[parts.length - 1]
        liByEnding[ending] = liByEnding[ending] || []
        liByEnding[ending].push(li)
    }

    for (const name of liNames) {
        const ending = normalizeString(name)
        if (!liByEnding[ending]) {
            continue
        }

        for (const li of liByEnding[ending]) {
            if (li.fields.hasField('cname') && li.fields.hasField('store_parent') && li.fields.hasField('lender')) {
                continue
            }
            const fields = new LineItemsFieldSet()
            fields.addField('cname', name)
            fields.addField('store_parent', name)
            const parts = li.canonicalName.split('-')
            fields.addField('lender', parts.length > 0 ? firstToUpperCase(parts[0]) : 'Unknown')
            for(const field of fields.getFields()){
                store.getDataSet().addFieldToLineItem(li.name, field)
            }
            store.getDataSet().addLineItem(
                li.withDefinition(timeDef(name))
            )
        }
    }
}

export function addAlertLineItems(store: LineItemsStore) {

  store.getDataSet().addLineItems([
    buildTimedCalculatedLineItem(
      "Investment Returns IRR - alert",
      buildDailyTimeDef(),
      //language=JavaScript
      `"Investment Returns IRR" < 13`,
      {
        "alertOf": "Investment Returns IRR"
      }
    ),
    buildTimedCalculatedLineItem(
      "Net Cash Flow - alert",
        buildDailyTimeDef(),
      //language=JavaScript
      `"Net Cash Flow" < 500000`,
      {
        "alertOf": "Net Cash Flow",
      }
    ),
    buildTimedCalculatedLineItem(
      "Net Cash Flow - children-alert",
        buildDailyTimeDef(),
      //language=JavaScript
      ` sumOver(f('rootAlert', 'NetCashflow - alert')) > 1 `,
      {
        "alertOf": "Net Cash Flow",
      }
    ),
    buildTimedCalculatedLineItem(
      "Investment Returns IRR - children-alert",
        buildDailyTimeDef(),
      //language=JavaScript
      ` sumOver(f('rootAlert', 'Investment Returns IRR - alert')) > 1 `,
      {
        "alertOf": "Investment Returns IRR",
      })
  ]);


  //Adds alerts per site
  let sites = store.getDataSet().getAggregatedStoreIds()
  for(let site of sites){
    store.getDataSet().addLineItems([
      buildTimedCalculatedLineItem(
      `${site} - Investment Returns IRR - alert`,
      buildDailyTimeDef(),
        //language=JavaScript
        `"${site}-Investment Returns IRR" < 13`,
          {
              "rootAlert": 'Investment Returns IRR - alert',
              "alertOf": `${site}-Investment Returns IRR`,
          }),
          buildTimedCalculatedLineItem(
              `${site} - NetCashflow - alert`,
              buildDailyTimeDef(),
              //language=JavaScript
              `"${site}-Net Cashflow" < 50000`,
              {
                  "rootAlert": 'Net Cash Flow - alert',
                  "alertOf": `${site}-Net Cash Flow`,
              }
          )
    ]);
  }
}

export function addSageIntacctDemoData(store: LineItemsStore){
  // @TODO Remove:: just for demoing
    store.getDataSet().addLineItems([buildTimedCalculatedLineItem('Debt Balance', buildMonthlyTimeDef(),`
        let baseValue = 240000;
        if(dt === 0){
            return baseValue;
        } else if(dt ===1){
            return baseValue - (5500)
        } else if(dt === 2){
            return baseValue - (5500) - (6500)
        } else {
            return baseValue - (5500) - (6500) - (5050)
        }
        ` ,LineItemsFieldSet.fromMap(storeValueTypeField('dollars')))
        // .serialize(store.getExecution())
        ,
        buildTimedCalculatedLineItem('Cash Balance', buildMonthlyTimeDef(),`
        let baseValue = 240000;
        if(dt === 0){
            return baseValue*2.5;
        } else if(dt ===1){
            return (baseValue + (9000))*2.5;
        } else if(dt === 2){
            return (baseValue + (2*8000))*2.5;
        } else {
            return (baseValue + (3*5000))*2.5;
        }
    ` ,LineItemsFieldSet.fromMap(storeValueTypeField('dollars')))
            // .serialize(store.getExecution())
    ])
}




export function buildStoreWithPxxStatistics(store: LineItemsStore) {

  let store2 = store.serialize();

  store2.timeIndex = buildAnnualTimeIndex(
    new Date(Date.UTC(2019, 1, 1)),
    new Date(Date.UTC(2039, 1, 1)),
  ).serialize();


  let multiYearStore = LineItemsStore.deserialize(store2);

  multiYearStore.getDataSet()
    .addLineItem(
      buildTimedCalculatedLineItem(
        'Actual Production',
        buildYearlyTimeDef(),
        ' "Total PV Generation"/"Solar_kWp__c"  ',
        {"store_label": "Actual Production per KW"}
      )
    )

  let yearActuals = multiYearStore.materializeTimed(StoreQuery
    .byNames(['Total PV Generation', 'Actual Production', 'Solar_kWp__c', 'PPA Rate']));


    let actualProductionAvg = yearActuals.query(StoreQuery.byName("Actual Production").aggregateOverTimeRange(
        new Date(Date.UTC(2019, 1, 1)),
        new Date(Date.UTC(2039, 1, 1)),
        lineItemAggregatorMapLegacy({
            'Actual Production': 'avgNZ'
        })
    ))

    let actualProductionSTD = yearActuals.query(StoreQuery.byName("Actual Production").aggregateOverTimeRange(
        new Date(Date.UTC(2019, 1, 1)),
        new Date(Date.UTC(2039, 1, 1)),
        lineItemAggregatorMapLegacy({
            'Actual Production': 'stDevNZ'
        })
    ))
    yearActuals.getDataSet().addLineItems(
    [
        buildParameterLineItem("STD", actualProductionSTD.firstTimeSlotValueOf('Actual Production') || 0),
        buildParameterLineItem("Average", actualProductionAvg.firstTimeSlotValueOf('Actual Production') || 0),
        buildTimedCalculatedLineItem("p50 Yearly", buildYearlyTimeDef(), 'norminv(0.5, "Average", "STD")'),
        buildTimedCalculatedLineItem("p90 Yearly", buildYearlyTimeDef(), 'norminv(1 - 0.9, "Average", "STD")'),
    ])
    let materializedYearActuals = yearActuals.materializeTimed(StoreQuery.all())
  let statsResult = materializedYearActuals.query(StoreQuery.all());

  const p50FirstYearValue = (materializedYearActuals.getDataSet().getLineItem("p50 Yearly")  as TimedCalculatedLineItem).getValue(new Date(Date.UTC(2019, 1, 1)).getTime(), materializedYearActuals.getExecution()).value;
  const p90FirstYearValue = (materializedYearActuals.getDataSet().getLineItem("p90 Yearly") as TimedCalculatedLineItem).getValue(new Date(Date.UTC(2019, 1, 1)).getTime(), materializedYearActuals.getExecution()).value;

    yearActuals.getDataSet().addLineItems(
    [
      buildParameterLineItem("p50", p50FirstYearValue || 0),
      buildParameterLineItem("p90", p90FirstYearValue || 0),
      buildTimedCalculatedLineItem("Variance from p50", buildYearlyTimeDef(), '   ifnz("Actual Production", ("Actual Production" - "p50") /"p50" ) * 100 ', {"store_valueType": "percentage"}),
      buildTimedCalculatedLineItem("Variance from p90", buildYearlyTimeDef(),'  ifnz("Actual Production", ("Actual Production" - "p90") / "p90")  * 100', {"store_valueType": "percentage"}),
    ]
  );
  let yearActualsWithStats = yearActuals.materializeTimed(StoreQuery.all());

  return {statsResult, yearActualsWithStats};
}

export function buildStoreWithPxxRevenue(store: LineItemsStore) {
  let {statsResult, yearActualsWithStats} = buildStoreWithPxxStatistics(store);

  yearActualsWithStats.getDataSet().addLineItems(
    [
      buildTimedCalculatedLineItem("p50 Revenue", buildYearlyTimeDef(), '  "p50" * "Solar_kWp__c" * "PPA Rate" ', {"store_valueType": "dollars"}),
      buildTimedCalculatedLineItem("p90 Revenue", buildYearlyTimeDef(), '  "p90" * "Solar_kWp__c" * "PPA Rate" ', {"store_valueType": "dollars"}),
      buildTimedCalculatedLineItem("Actual Revenue", buildYearlyTimeDef(), '  ifnz("Actual Production", "Total PV Generation" * "PPA Rate") ', {"store_valueType": "dollars"}),
      buildTimedCalculatedLineItem("Variance from p50 Revenue", buildYearlyTimeDef(), '  ifnz("Actual Revenue", ("Actual Revenue" - "p50 Revenue")/"p50 Revenue"*100) ', {"store_valueType": "percentage"}),
      buildTimedCalculatedLineItem("Variance from p90 Revenue", buildYearlyTimeDef(), '  ifnz("Actual Revenue", ("Actual Revenue" - "p90 Revenue")/"p90 Revenue"*100) ', {"store_valueType": "percentage"}),
    ]);

  return {statsResult, yearActualsWithStats};
}
