import React, {useEffect, useMemo, useState} from "react";
import {Segment} from "semantic-ui-react";
import {StoresMetadataSelect} from "../lineitems-store/StoresMetadataSelect";
import {useUpdateContext, WaitForIt} from "../UpdateContext";
import {LineItemsStore, PersistenceQuery} from "../ps-models/lineitems-store";
import {loadSiteStore} from "./siteStoreLoader";
import {authStorage} from "../auth";
import {getAmProjectConfig} from "../ps-models";

export function SiteSelectWrapper({children, setSelectedSite, selectedSite, query, storeSetup}: {
  query?: PersistenceQuery,
  children: JSX.Element | JSX.Element[], selectedSite?: string, setSelectedSite: (site: string)=>void, storeSetup: (store: LineItemsStore)=>void }) {
  const company = authStorage.getCompany();
  const {collection} = getAmProjectConfig(company);
  const ctx = useUpdateContext<LineItemsStore>()

  useEffect(() => {

    if(!selectedSite) {
      console.info("ENtering here", ctx.empty)
      ctx.empty()
    }
    else if(selectedSite) {
      console.info("ENtering here")
      if(ctx.tryGetService()?.getId() !== selectedSite) {
        ctx.loading();
        loadSiteStore(selectedSite, collection, storeSetup).then((store) => {
          ctx.setService(store);
          ctx.reload();
        })
      }
      window.localStorage.setItem("selectedStore", selectedSite);

    }

  }, [selectedSite, ctx.actionChanged])

  const handleSelectedStore = (id: string) => {
    setSelectedSite(id);
  }

  return <div>
    <Segment >
      <StoresMetadataSelect
        value={selectedSite}
        onSelect={(id) => handleSelectedStore(id)}
        collection={collection}
        query={query}
      /></Segment>
    <WaitForIt emptyMessage="Select a Site">{children}</WaitForIt>
  </div>
}