import {Button, Divider, Form, Header, Icon} from "semantic-ui-react";
import React from "react";
import {Link} from "react-router-dom";
import {useForm} from "../ui/UseForm";
import {LoginLayout} from "./LoginLayout";
import {SignUpData, signUp, SignUpPayload} from "./auth.client";
import {useAuthCtx} from "./AuthContextProvider";

export function NewSignUpPage() {
    const {reloadAuth} = useAuthCtx()
    const { formData, handleChange, handleSubmit, submitting, error } = useForm<SignUpData>({
        initialState: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordAgain: '',
            status: "enabled"
        },
        onSubmit: async (data) => {
            await signUp(new SignUpPayload(data))
            reloadAuth()
        }
    })

    return <LoginLayout error={error}>
      <Header as="h1" className="hero-title">
        Let's get started
      </Header>
      <p>
        Create your Perlstreet account. Already registered?{" "}
        <Link to="/login">Login here</Link>
      </p>
      <Form
        size="large"
        onSubmit={handleSubmit}
        className="outlineForm"
      >
        <Form.Group widths="equal">
          <Form.Input
            fluid
            placeholder="First name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
          <Form.Input
            fluid
            placeholder="Last name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Input
          fluid
          placeholder="Business email address"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <Form.Input
          fluid
          placeholder="Create a password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
        <Form.Input
          fluid
          placeholder="Enter your password again"
          type="password"
          name="passwordAgain"
          value={formData.passwordAgain}
          onChange={handleChange}
        />

        <Divider hidden />
        <Divider hidden />

        <Button
          primary
          type="submit"
          className="raised"
          size="huge"
          loading={submitting}
          disabled={submitting}
        >
          Continue
          <Icon name="arrow right" />
        </Button>
      </Form>
      <Divider hidden />
  </LoginLayout>
}
