import {ConfigEntry, ConfigType, WhatIfScenarioConfig} from "../ps-types";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useCompanyId} from "../core";
import {useMessages} from "../ui/MessagesProvider";
import {useEffect, useState} from "react";
import {getAmCompanyConfig, saveAmCompanyConfig} from "./company.client";
import {Container, Select} from "semantic-ui-react";
import Form from "@rjsf/semantic-ui";
import validator from "@rjsf/validator-ajv8";

export function Configurator<T extends ConfigEntry>({configType, schema, children}: {
  configType: ConfigType,
  schema: RJSFSchema,
   children?: React.ReactNode
}) {

  const uiSchema: UiSchema = {
    'ui:classNames': 'custom-css-class',
  };

  const companyId = useCompanyId();
  const {error, info} = useMessages();
  const [configs, setConfigs] = useState<Record<string, T>>();
  const [selectedConfig, setSelectedConfig] = useState<string>('');
  const [formData, setFormData] = useState<any>();

  function load() {
    getAmCompanyConfig<T>(companyId, configType)
      .then((config) => {
        setConfigs(config.configs);
        const key = Object.keys(config.configs)[0];
        setFormData(config.configs[key])
      })
  }

  useEffect(() => {
    load();
  }, []);

  if (!configs) return <div>Loading...</div>

  let options = Object.keys(configs).map((key) => ({key, value: key, text: key}));
  options.push({key: 'new', value: 'new', text: 'New'});

  const handleSelectedConfigChange = (configName: string) => {
    setSelectedConfig(configName);

    if (configName === 'new') {
      setFormData({
        configName: '',
        configKey: '',
        locatorMap: {}
      });
      return;
    }

    setFormData(configs[configName]);
  }


  const handleSave = async () => {
    console.info("Saving", formData)
    if (!formData) return;
    // save config

    console.info("Saving", formData)
    if (!formData) return;
    // save config
    await saveAmCompanyConfig(companyId, configType, {
      companyId,
      configType,
      configs: {
        ...configs,
        [formData.configKey]: formData,
      }
    }).then(() => {
      info("Saved");
    }).catch((e) => {
      error(e.message);
    });

    load();
  }



  return <>
    Select Config:
    <Select
      options={options}
      value={selectedConfig}
      onChange={(e, {value}) => handleSelectedConfigChange(value as string)}
    />
    <Form
      schema={schema}
      uiSchema={uiSchema}
      validator={validator}
      formData={formData}
      onChange={({formData}) => setFormData(formData)}
      onSubmit={handleSave}
    />
  </>
}