import React, {useEffect, useMemo} from "react";
import {AggregationBuilder} from "./AggregationBuilder";
import {Button, Grid, Header, Input, Loader, Segment, Sidebar} from "semantic-ui-react";
import {getWidgetTypes} from "./WidgetRegistry";
import {UpdateProvider, WaitForIt, WaitForItV2} from "../../UpdateContext";
import {
  buildConfigServiceFromDashboard,
  DashboardConfigService,
  useDashboardService
} from "./DashboardConfigService";
import {ClosableSection} from "../../ClosableSection";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DragableAddWidget} from "./DragableAddWidget";
import {WidgetWrapper} from "./WidgetWrapper";
import {useCompanyId} from "../../core";
import {useMessages} from "../../ui/MessagesProvider";
import {LineItemsStore} from "../../ps-models/lineitems-store";
import {DashboardPageStoreCtxLoader} from "./DashboardPageStoreCtxLoader";
import {useInstantUpdater} from "../../generic.hooks";
import {Puck} from "@measured/puck";
import "@measured/puck/dist/index.css";
import {DASHBOARD_COMPONENTS_CONFIG, extractPuckDataFromDashboardConfig} from "./widgets/puck-widgets/commons";
import {DashboardConfigBase} from "./DashboardConfig";
import {WidgetConfigBase} from "./WidgetConfig";

export function DashboardBoardBuilderPage({dashboardId}:{dashboardId: string}) {
  let companyId = useCompanyId();

  return <UpdateProvider loadService={async ()=> {
    return buildConfigServiceFromDashboard(companyId, dashboardId, false);
  }} >
      <WaitForItV2>
        <DashboardPageStoreCtxLoader
            render={(service)=> {
              if(service.amDashboardDto.v2){
                return <BoardBuilderViewV2 />
              } else {
                return <BoardBuilderViewWrapper />
              }
            }}
        />
      </WaitForItV2>
    </UpdateProvider>
}



export function BoardBuilderViewWrapper() {
  return <DndProvider backend={HTML5Backend}>
      <BoardBuilderView />
  </DndProvider>
}

function BoardBuilderView() {

  let {getService} = useDashboardService();
  let dashboardService = getService();
  let {info, error, clear} = useMessages();
  let companyId = useCompanyId();

  const handleSave = () => {
    clear();
    dashboardService.save(companyId).then(() => {
      info("Dashboard Saved");
    }).catch(e => {
      error("Error Saving Dashboard " + e.message);
    });
  }

  let rootContainer = useMemo(() => {
    return <WidgetWrapper widgetId={'root'} context={{
      appContext: dashboardService,
      store: dashboardService.loadStore({}),
    }}/>
  }, [])



  return <Sidebar.Pushable as={Segment}>
    <Sidebar visible  style={{backgroundColor: "#fff"}}    width='very thin'>
      <Segment>
        {getWidgetTypes().map(widget =>
          <DragableAddWidget key={widget.typeId}
                             widget={widget} />
        )}
      </Segment>
    </Sidebar>

    <ConfigSideBar />

    <Sidebar.Pusher>
      <Segment basic style={{ width: '95%', minHeight: "500px"}}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={15}>
            </Grid.Column>
            <Grid.Column width={1}>
              <Button size="mini" primary onClick={handleSave} >Save</Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
              <div style={{border: "1px solid #ccc", padding: "5px", marginBottom: "10px", background:"#eee" , width: '100%'}}>
                <ClosableSection opened={false}  title="Aggregation Setup" level="medium"   >
                  <AggregationBuilder  />
                </ClosableSection>
              </div>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              {rootContainer}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Sidebar.Pusher>

  </Sidebar.Pushable>
}

function ConfigSideBar() {
  let {getService} = useDashboardService();
  let [,update] = useInstantUpdater();
  let dashboardService = getService();

  const handleUnselectConfig = () => {
    dashboardService.unselectWidget();
  }

  useEffect(() => {
    dashboardService.onWidgetSelected(update)
  }, []);

  return  <Sidebar
    visible={dashboardService.hasSelectedWidget()}
    onHide={handleUnselectConfig}
    animation='overlay'
    vertical
    direction={'right'}
    style={{backgroundColor: "#fff", width: "800px"}}
    width='very wide'
  >
    <Segment>
      <Header> Properties </Header>
      {dashboardService.renderConfig()}
    </Segment>
  </Sidebar>
}

function BoardBuilderViewV2(){
  let {getService} = useDashboardService();
  let dashboardService = getService();
  let {info, error, clear} = useMessages();
  let companyId = useCompanyId();

  const handleSave = () => {
    clear();
    dashboardService.save(companyId).then(() => {
      info("Dashboard Saved");
    }).catch(e => {
      error("Error Saving Dashboard " + e.message);
    });
  }

  return <Segment basic style={{ minHeight: "500px"}}>
    <Grid>
      <Grid.Row>
        <Grid.Column width={15}>
        </Grid.Column>
        <Grid.Column width={1}>
          <Button size="mini" primary onClick={handleSave} >Save</Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <div style={{border: "1px solid #ccc", padding: "5px", marginBottom: "10px", background:"#eee" , width: '100%'}}>
          <ClosableSection opened={false}  title="Aggregation Setup" level="medium"   >
            <AggregationBuilder  />
          </ClosableSection>
        </div>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column width={16}>
          <Editor />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
}

function Editor(){
  let {getService} = useDashboardService();
  let dashboardService = getService();
  return <Puck config={DASHBOARD_COMPONENTS_CONFIG}
               data={extractPuckDataFromDashboardConfig(dashboardService)}
               renderHeaderActions={(props)=>{
                 return <></>
               }}
               onChange={(data)=>{
                 const {content, ...layout} = data;
                 // const currentOutputs = dashboardService.getAllWidgets().map((w)=>w.getOutputs());
                 (dashboardService.getConfig() as DashboardConfigBase<WidgetConfigBase>).setLayout(layout);
                 (dashboardService.getConfig() as DashboardConfigBase<WidgetConfigBase>).setWidgets(content);
               }}
               iframe={{enabled: false}}
               permissions={{
                 duplicate: false
               }}
  />;
}