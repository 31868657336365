import {LineItemsStore, ResultOptions, StoreQuery} from "../../../ps-models/lineitems-store";
import {LoadingBlock} from "../../../ui/Loading";
import {Button, Checkbox, Container, DropdownProps, Input, Segment, SegmentInline, Select} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {BuilderContext, registerWidgetType} from "../WidgetRegistry";
import {TimeSeriesChart} from "../../../lineitems-store/TimeSeriesChart";
import {QueryEditor} from "../StoreQueryEditor";
import {TimeIndexEditorStandalone} from "../../line-items-editor/TimeIndexEditor";
import {StoreQueryFlat, StoreQueryFlatDto} from "../../../ps-models/lineitems-store/StoreQueryFlat";
import {
  getGlobalContext,
  getStore,
  useOnStoreReady,
  useUpdateOnGlobalContextChange,
} from "./commons";
import {SideBySideView} from "../../SideBySideView";
import {DateRangeType} from "../../../ps-types";

interface ConfigProps {
  title: string,
  chartType: string,
  storeQueryFlat?: StoreQueryFlatDto,
  // table?: ResultOptions['table']
}

// @TODO: Make this cleaner, better to have some other type extending ConfigProps and over-riding storeQueryFlat
interface DeSerializedConfigProps {
  title: string,
  chartType: string,
  storeQueryFlat?: StoreQueryFlat,
}

const DEFAULT_STORE_QUERY_FLAT = new StoreQueryFlat();
registerWidgetType({
    typeId: 'TimeSeries Chart',
    metadata: {
      name: 'Bar or Line Chart',
      description: '',
      icon: 'chart line',
    },
    defaultConfig: {
      chartType: 'bar',
      storeQueryFlat: DEFAULT_STORE_QUERY_FLAT.serialize(),
    },
  renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
    return <WidgetConfig
      config={config} context={context} onConfigChange={setConfig}/>
  },
    render: (config: any, context: any) => {
        return <WidgetCompare config={config.config} context={context}/>
    }
  }
)

const deserializeConfig = (config: ConfigProps):DeSerializedConfigProps  =>{
  return {...config, ...(config.storeQueryFlat ? {storeQueryFlat: StoreQueryFlat.deserialize(config?.storeQueryFlat)} :{})} as DeSerializedConfigProps
}

function WidgetConfig({config, context, onConfigChange}:
                                      { config: ConfigProps, context: BuilderContext, onConfigChange: (config: any) => void }) {
  let store = context.store as LineItemsStore;
  let [localConfig, setLocalConfig] = React.useState<DeSerializedConfigProps>(deserializeConfig(config));

  let [customizeWidgetTimeIndex, setCustomizeWidgetTimeIndex] = useState<boolean>(!!localConfig.storeQueryFlat?.timeIndex);
  // let [customizeTableRowDimension, setCustomizeTableRowDimension] = useState<boolean>(!!localConfig.table);

  useEffect(() => {
    setLocalConfig(deserializeConfig(config));
  }, [config]);

  if (!store) {
    return <LoadingBlock/>
  }
  const handleApply = () => {
    const serializedConfig = {...localConfig, ...(localConfig.storeQueryFlat ? {storeQueryFlat: localConfig.storeQueryFlat.serialize()} : {})}
    onConfigChange(serializedConfig);
  }

  const handleDiscard = () => {
    setLocalConfig(deserializeConfig(config));
  }

  return <>
    Type:
    <Select
      value={localConfig.chartType}
      onChange={(e, data) => {
        setLocalConfig({...localConfig, chartType: data.value as string})
      }}
      options={[
        {key: 'bar', text: 'Bar', value: 'bar'},
        {key: 'line', text: 'Line', value: 'line'},
      ]}
    />
    <Segment>
      <SegmentInline>
        Title: <Input value={localConfig.title} onChange={(e, data) => {
          setLocalConfig({...localConfig, title: data.value})
        }} />
        <QueryEditor store={store} capabilities={["names"]} query={localConfig.storeQueryFlat ?? DEFAULT_STORE_QUERY_FLAT} onQueryUpdate={(updatedQuery)=>{
          let query = updatedQuery;
          if(localConfig.storeQueryFlat && localConfig.storeQueryFlat.timeIndex){
            query = updatedQuery.withTimeIndex(localConfig.storeQueryFlat.timeIndex)
          }
          setLocalConfig({...localConfig, storeQueryFlat: query })
        }} />
        <Checkbox toggle checked={customizeWidgetTimeIndex}
                  onChange={(e, data) => {
                    if(!!data.checked === false){
                      setLocalConfig((prev) => {
                        const serializedExistingStoreQueryFlat = (prev.storeQueryFlat ?? DEFAULT_STORE_QUERY_FLAT)?.serialize();
                        if(serializedExistingStoreQueryFlat){
                          const {timeIndex, ...storeQueryFlatSerializedWithStrippedTimeIndex} = serializedExistingStoreQueryFlat;
                          let storeQueryFlatToSet = StoreQueryFlat.deserialize(storeQueryFlatSerializedWithStrippedTimeIndex)
                          return {...prev, storeQueryFlat: storeQueryFlatToSet }
                        }
                        return {...prev};
                      })
                    }
                    setCustomizeWidgetTimeIndex(!!data.checked)
                  }}
                  label={"Customize Widget Time Index"} />
        {customizeWidgetTimeIndex && <TimeIndexEditorStandalone initiallySelectedDates={{startDate: localConfig.storeQueryFlat?.timeIndex ? localConfig.storeQueryFlat?.timeIndex.startDate : store.timeIndex.startDate, endDate: localConfig.storeQueryFlat?.timeIndex ? localConfig.storeQueryFlat?.timeIndex.endDate : store.timeIndex.endDate}}
                                                                initialGranularity={localConfig.storeQueryFlat?.timeIndex ? localConfig.storeQueryFlat?.timeIndex.getUnit() : store.timeIndex.getUnit()}
                                                                onUpdate={(updatedTimeIndex)=>{
                                                                  if(JSON.stringify(store.timeIndex.serialize()) !== JSON.stringify(updatedTimeIndex.serialize())){
                                                                    setLocalConfig((prev)=>(
                                                                        {...prev, storeQueryFlat: (prev.storeQueryFlat ?? DEFAULT_STORE_QUERY_FLAT).withTimeIndex(updatedTimeIndex) }))
                                                                  }
                                                                }}
                                                                layout={"vertical"}
        />}
        {/*<Segment>*/}
        {/*    <h5>Table Configurations</h5>*/}
        {/*    <Checkbox toggle checked={customizeTableRowDimension}*/}
        {/*              onChange={(e, data) => {*/}
        {/*                if(!!data.checked === false){*/}
        {/*                  setLocalConfig((prev)=>{*/}
        {/*                    const {table, ...restConfig} = prev;*/}
        {/*                    return restConfig;*/}
        {/*                  })*/}
        {/*                }*/}
        {/*                setCustomizeTableRowDimension(!!data.checked);*/}
        {/*              }}*/}
        {/*              label={"Customize Table Row Dimension"} />*/}
        {/*    {customizeTableRowDimension && <div style={{display: 'flex', alignItems: 'center', gap: '2px', margin: '10px 0px'}}>Row Dimension: <StoreFieldSelector store={store}*/}
        {/*                                                                                                                                                           value={localConfig?.table?.rowDimensionFields}*/}
        {/*                                                                                                                                                           multiple*/}
        {/*                                                                                                                                                           onChange={(e:any, data:DropdownProps) =>*/}
        {/*                                                                                                                                                               setLocalConfig({...localConfig, table: {rowDimensionFields: data.value as string[]}}) }*/}
        {/*    /></div>}*/}
        {/*</Segment>*/}
      </SegmentInline>
    </Segment>
    <Button primary onClick={handleApply}>Apply</Button>
    <Button color={"grey"} onClick={handleDiscard}>Discard</Button>
  </>

}

function WidgetCompare({config, context}: { config: ConfigProps,context: BuilderContext }) {
  useOnStoreReady(context);
  useUpdateOnGlobalContextChange(context);

  const storeToCompareWith = context.appContext.getLastStoreToCompareWith();
  const mainStore = context.appContext.getStore();
  if(storeToCompareWith){
    return <SideBySideView selectedStores={mainStore}
                           compareWithStores={storeToCompareWith}
                           highlightComparedWithStore={true}
                           render={(store) =>
                             <Widget config={config} context={{...context, store}} />}
    />
  }
  return <Widget config={config} context={{...context, store: mainStore}}/>
}

function Widget({config, context}: { config: ConfigProps, context: BuilderContext }) {

  let store = getStore(context);
  let globalContext = getGlobalContext(context)

  let baseQueryForGettingGroupingFields = StoreQuery.withField('store_groupingName');
  let query = baseQueryForGettingGroupingFields;
  let timeIndex = store.timeIndex;
  if(config.storeQueryFlat && JSON.stringify(config.storeQueryFlat) !== JSON.stringify(DEFAULT_STORE_QUERY_FLAT.serialize())){
    let deserializedSQF = StoreQueryFlat.deserialize(config.storeQueryFlat);
    query = StoreQueryFlat.deserialize(config.storeQueryFlat).toStoreQuery(false).and(baseQueryForGettingGroupingFields);
    if(deserializedSQF.timeIndex){
      timeIndex = deserializedSQF.timeIndex;
    }
  }

  if(globalContext.utcDateRange) {
    const dateRange: DateRangeType = globalContext.utcDateRange;
    const from = dateRange.from || timeIndex.startDate;
    const to = dateRange.to || timeIndex.endDate;
    timeIndex = timeIndex.withDates(from, to);
    query = query.withTimeIndex(timeIndex);
  }

  if(globalContext.granularity) {
    timeIndex = timeIndex.withGranularity(globalContext.granularity);
    query = query.withTimeIndex(timeIndex);
  }
  let results = store.query(query);

  return <>
    <TimeSeriesChart title={config.title}
                     options={{type: config.chartType as any}}
                     result={results}
   />
  </>
}