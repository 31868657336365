import ActionTypes from './companies.types';

const INITIAL_STATE = {
  status: 'idle', // enum: 'idle' | 'loading' | 'success' | 'failed'
  error: null,
  
  companies: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.ADD_COMPANY_FAILED:
    case ActionTypes.EDIT_COMPANY_FAILED:
      return {
        ...state,
        status: 'failed',
        error: action.payload
      }
    case ActionTypes.ADD_COMPANY_STARTED:
    case ActionTypes.EDIT_COMPANY_STARTED:
      return {
        ...state,
        status: 'loading'
      }
    case ActionTypes.EDIT_COMPANY_SUCCESS:
      const newCompanyData = [ ...state.companies.filter((company) => company.id !== action.payload.id), action.payload ];
      return {
        ...state,
        status: 'success',
        companies: newCompanyData
      }
    case ActionTypes.ADD_COMPANY_SUCCESS:
      return {
        ...state,
        status: 'success',
        companies: [
          ...state.companies,
          action.payload
        ]
      }
    case ActionTypes.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        status: 'success',
        companies: [
          ...state.companies.filter(c => c.id !== action.payload)
        ]
      }
    case ActionTypes.UPLOAD_COMPANY_FILE_SUCCESS:
      let updatedCompany = state.companies.filter((company) => company.id === action.payload.companyId)[0];
      let newLinkedFiles = updatedCompany.linkedFiles ? [...updatedCompany.linkedFiles, action.payload] : [action.payload];
      updatedCompany.linkedFiles = newLinkedFiles;
      let newCompaniesArray = [ ...state.companies.filter((company) => company.id !== action.payload.companyId), updatedCompany];
      return {
        ...state,
        status: 'success',
        companies: newCompaniesArray
      }
    case ActionTypes.ADD_COMPANY_MODEL_UPDATE_SUCCESS:
      const updateIndex = state.companies.findIndex(x => x.id === action.payload.companyId);
      let newCompany = { ...state.companies[updateIndex] };
      newCompany.linkedEvents = newCompany.linkedEvents ? [...newCompany.linkedEvents, action.payload] : [action.payload];
      let newCompanies = [ ...state.companies ];
      newCompanies[updateIndex] = newCompany;
      return {
        ...state,
        status: 'success',
        companies: newCompanies
      }
    default:
      return state;
  }
}

export default reducer;