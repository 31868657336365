import {LineItemsStore, StoreQuery} from "../../../ps-models/lineitems-store";
import {field} from "../../../ps-models/line-items";
import {buildDailyTimeIndex, normalizeMapKeys, storeValueTypeField} from "../../../ps-models";


export function addStoreFormulas(store: LineItemsStore) {

  let dataSet = store.getDataSet();



  //Simplify the label of the Asset Category line items
  dataSet.getLineItemsWithField('assetCategoryId').forEach(lineItem => {
    let simplerLabel = dataSet.getLineItem(lineItem.name).fields.getFieldStr('label');
    simplerLabel = simplerLabel.replace(/__c/gi, '').replace(/_/gi, ' ')
    dataSet.addFieldToLineItem(lineItem.name, field("label", simplerLabel) )
  });

  let labelsMap = {
    'grid_import_kwh': 'Grid Import',
    'generation_export_kwh': 'Generation Export',
    'all_consumption_kwh': 'Total Consumption',
    'generation_import_kwh': 'Generation Import',
    'pv_generation_kwh': 'PV Generation',
    'grid_export_kwh': 'Grid Export',
    'siteunavailable': 'Site Unavailable',
    'batteryunavailable': 'Battery Unavailable',
    'solarunavailable': 'Solar Unavailable',
    'total_purchase_price__c': 'Total Purchase Price',
    'discounted_purchase_price__c': 'Discounted Purchase Price',
    'current_value_post_dep__c': 'Current Value Post Depreciation',
    'original_asset_value__c': 'Original Asset Value',
    'months_active__c': 'Months Active',
    'monthly_depreciated_value__c': 'Monthly Depreciated Value',
    'Billing_type__c': 'Billing Type'
  }

  for (let [key, value] of Object.entries(labelsMap)) {
    dataSet.addFieldToLineItem(
      key,
      field('label', value)
    )
  }

}

export function setLabelAndFormatForAggregates(store: LineItemsStore) {

  let labelsMap: Record<string, string> = {
    'Total': 'Total Debt Service'
  }


  let formatMap: Record<string, string> = normalizeMapKeys({
    'Original_Asset_Value__c': 'dollars',
    'Current_Value_Post_Dep__c': 'dollars',
  });

  for (let [key, value] of Object.entries(formatMap)) {

    store.getDataSet().getByField('store_sourceLineItemName', key).forEach(li => {
      store.getDataSet().addFieldToLineItem(li.name, field('store_valuetype', value))
    });

    store.getDataSet().addFieldToLineItem(
      key,
      field('store_valuetype', value)
    )
  }


  for(let [key, value] of Object.entries(labelsMap)) {
    store.getDataSet().getByField('store_sourceLineItemName', key).forEach(li => {
      let site = store.getDataSet().getLineItem(li.name).fields.getFieldStr('store_sourceName');
      store.getDataSet().addFieldToLineItem(li.name, field('store_label', `${site} - ${value}`))
    });
    store.getDataSet().addFieldToLineItem(
      key,
      field('store_label', value)
    )
  }

  store.getDataSet().addFieldToLineItem(
    "Investment Returns IRR",
    field("store_label", "Portfolio IRR")
  )

}