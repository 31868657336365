import {LineItemsStore} from "../LineItemsStore";
import {StoreQuery} from "../StoreQuery";
import {LineItemsStoreExecution} from "../LineItemsStoreExecution.model";
import {ResultOptions} from "./QueryResultOptions";
import {TimeIndex, TimeLine} from "../../Time.model";
import {LineItem, ValueType} from "../../line-items";
import {LineItemColumn, LineItemRow, ResultCell} from "../QueryResult";
import {FinancialValueType} from "../../fin-math/FinancialValueTypes";
import {formatValueWithValueType} from "../../formatting";
import {GROUPING_LINE_ITEM_KEY} from "../../LineItemDataSet";
import {entries, normalizeString, valueAsNumber} from "../../line-item-utils/coding.utils";
import {values} from "ramda";

export abstract class TableBuilder {
  constructor(protected store: LineItemsStore,
              protected query: StoreQuery,
              protected execution: LineItemsStoreExecution,
              protected options: ResultOptions,
              protected timeIndex: TimeIndex,
              protected visibleTimeLine: TimeLine,
              protected visibleLineItems: LineItem[]) {}

  getFormattingFunction(li: LineItem) {
    let valueType = this.getValueType(li);
    let decimalPlaces = this.getDecimalPlaces(li);
    return (value: ValueType) => formatValueWithValueType(value, valueType, typeof decimalPlaces === "number" ? decimalPlaces : undefined);
  }

  buildNameCell(li: LineItem, options: ResultOptions): ResultCell {
    let execution = this.execution;
    let text = li.label;
    if (options?.table?.rowDimensionFields && !li.fields.hasField(GROUPING_LINE_ITEM_KEY)) {
      text = options.table?.rowDimensionFields.map((it) =>
        execution.getFieldStr(li.name, it)
      ).join(' - ');
    }
    return {
      columnId: 'name',
      text,
      type: 'header',
      value: li.name
    }
  }

  metadataCells(lineItem: LineItem) {
    let cells: Record<string, ResultCell> = {}

    entries(lineItem.fields.getFieldMap()).forEach(([key, field]) => {
      //Add metadata. prefix for user defined metadata, otherwise don't use it
      let mKey = normalizeString(key).startsWith("store_") ? normalizeString(key) : `fields.${normalizeString(key)}`
      cells[mKey] = {
        type: 'metadata',
        columnId: mKey,
        text: field.value?.toString() || "",
        value: field.value
      }
    });

    return cells;
  }

  buildMetadataColumns(fieldNames: string[]): LineItemColumn[] {
    const columns = [];
    let specificFieldsToSelect = (Array.isArray(fieldNames) && fieldNames.length > 0) ? fieldNames.map(normalizeString) : null;
    let metadataColumnsByKey: Record<string, LineItemColumn> = {}

    for (let li of values(this.store.getDataSet().getLineItems())) {
      for (let key of li.fields.getAllKeys()) {
        if (specificFieldsToSelect && !specificFieldsToSelect.includes(normalizeString(key))) {
          continue;
        }
        let mKey = normalizeString(key).startsWith("store_") ? normalizeString(key) : `fields.${normalizeString(key)}`
        let column = metadataColumnsByKey[key]
        if (!column) {
          column = {
            columnId: mKey,
            text: takeLastPartOfPath(li.fields.getFieldName(key) || "") || "",
            type: "metadata-header"
          }
          metadataColumnsByKey[key] = column
          columns.push(column)
        }
      }
    }
    return columns;
  }


  getFieldStr(liName: string, fieldName: string) {
    return this.execution.getFieldStr(liName, fieldName);
  }

  getValueType(li: LineItem){
    return (li.fields.getFieldStr("store_valueType") as FinancialValueType) || "number";
  }

  getDecimalPlaces(li: LineItem){
    let decimalPlaces = li.fields.getField("store_value_decimals")?.value || undefined;
    if(typeof decimalPlaces === "string") {
      let numericRepresentation = parseFloat(decimalPlaces);
      if(!Number.isNaN(numericRepresentation)){
        decimalPlaces = numericRepresentation;
      }
    }
    return decimalPlaces;
  }

  public abstract buildTable(): { columns: LineItemColumn[]; rows: LineItemRow[] };
}

function takeLastPartOfPath(path: string) {
  return path.split(".").pop();
}