import {LineItem} from "./LineItem.model";
import { LineItemValue, ValueType} from "./LineItemValue.model";
import {LineItemsFieldSet} from "./LineItemsFieldSet";
import {buildDailyTimeDef, TimeDefinition} from "./TimeDefinition";

export function isTemplateLineItem(li: LineItem): boolean {
  return li instanceof TemplateLineItem
}

export const TEMPLATE_LINE_ITEM_TYPE = 'TemplateLineItem'

export class TemplateLineItem extends LineItem {
  constructor(name: string,
              public timeDefinition: TimeDefinition,
              fields: LineItemsFieldSet,
  ) {
    super(name, fields)
  }
  getValue(): LineItemValue {
    return new LineItemValue("")
  }

  getTotal(): ValueType {
    return 0;
  }

  get type(): string {
    return TEMPLATE_LINE_ITEM_TYPE
  }

  serialize() {
    return {
      type: this.type,
      name: this.name,
      fields: this.fields.serialize(),
      timeDefinition: this.timeDefinition.serialize()
    }
  }
  getTimeDefinition(): TimeDefinition {
    return this.timeDefinition;
  }

  withDefinition(timeDefinition: TimeDefinition) {
    return new TemplateLineItem(this.name, timeDefinition, this.fields.clone())
  }

  clone() {
    return buildTemplateLineItem(this.name, this.timeDefinition, this.fields)
  }

  static deserialize(li: any) {
    // @TODO: Remove this, once all template line items are migrated and have a timeDefinition
    if(!li?.timeDefinition) {
      li.timeDefinition =  buildDailyTimeDef();
    }
    return buildTemplateLineItem(li.name, TimeDefinition.deserialize(li.timeDefinition),  LineItemsFieldSet.deserialize(li.fields))
  }
}

export function buildTemplateLineItem(name: string, timeDefinition: TimeDefinition, fields: Record<string, ValueType> | LineItemsFieldSet = {},) {
  if(fields instanceof LineItemsFieldSet) {
    return new TemplateLineItem(name, timeDefinition, fields);
  }
  return new TemplateLineItem(name, timeDefinition, LineItemsFieldSet.fromMap(fields));
}