import {LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import React from "react";
import {StoreQuery} from "../../../ps-models/lineitems-store";
import {useSiteStoreContext} from "../../siteStoreLoader";
import {fromPairs} from "ramda";
import {formatRows, fromNestedList} from "../../../lineitems-store/TableFormat";
import {Header, Icon, Segment} from "semantic-ui-react";
import {Revenues} from "./Revenues";
import {TimeSeriesChart} from "../../../lineitems-store/TimeSeriesChart";
import {LineItemsStoreDebug} from "./LineItemsStoreDebug";
import {WSTimeSeriesChart} from "./WSTimeSeriesChart";



export function CashFlowVariance() {
  const store = useSiteStoreContext();

  let revenueLineItems = [
    'Net Revenue - Projected',
    'Net Revenue',
    'Variance'
  ]

  let mat = store
    .materializeTimed(StoreQuery.all().withTimeIndex(store.timeIndex.withGranularity('months')))
   console.info("mat", mat)
   let cashflowVariance =  mat.query(StoreQuery.byNames([...revenueLineItems]));

  return <Segment basic>

      <Header as="h2" color="purple">Cash Flow Variance</Header>
      <LineItemsTableWithFormulas
        withGroups
        queryResult={cashflowVariance}
        store={store}
      />

     <WSTimeSeriesChart title={"Projected vs Actual Net Revenue"}
                      options={{includeLineItems: ["Net Revenue - Projected", "Net Revenue"]}}
                      result={cashflowVariance}
     />
  </Segment>
}