import ButtonWithImage from "../../../../components/ButtonWithImage/ButtonWithImage.component";
import React from "react";
import {Button, Image} from "semantic-ui-react";

export function SendToTurnKeyButton({text}: {text: string}) {

  return <Button floated="right" style={{position: "relative", top: -10, backgroundColor: "white", border: "1px solid #ccc"}} >
    <div style={{float: "left", marginTop: 12}}>{text}</div>
    <Image floated="right" src="/logos/demo/turnkey-lender.png" style={{ width:'90px', borderRadius: '2px'}} />
  </Button>
}