import React, {ReactElement} from "react";
import {Grid} from "semantic-ui-react";
import {LineItemsStore} from "../ps-models/lineitems-store";

export function SideBySideView<T extends string[] | LineItemsStore>({selectedStores, compareWithStores, render, highlightComparedWithStore = false}: {
  selectedStores: T, compareWithStores: T,
  render: (storeReferences: T) => ReactElement,
  highlightComparedWithStore?:boolean
}) {

  return <>
    {(!(compareWithStores instanceof LineItemsStore) || (Array.isArray(compareWithStores) && compareWithStores.length == 0)) && render(selectedStores)}
    {(compareWithStores instanceof LineItemsStore || (Array.isArray(compareWithStores) && compareWithStores.length > 0)) &&
        <Grid style={(highlightComparedWithStore ? {width: '100%'} : {})}>
          <Grid.Row columns={2}>
            <Grid.Column>
              {render(selectedStores)}
            </Grid.Column>
            <Grid.Column style={(highlightComparedWithStore ? {border: '2px dotted #FF9800'}: {})}>
              {render(compareWithStores)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
    }
  </>

}