import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
  Icon,
  Card
} from 'semantic-ui-react';

import './AboutPage.styles.css';
import AppContainer from '../../components/AppContainer/AppContainer.component';
import Footer from '../../components/Footer/Footer.component';
import Contact from '../../components/Contact/Contact.component';

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const WhyDebtHeading = ({ mobile }) => (
  <div className="aboutPageOverlay">
    <Segment style={{ padding: '0em 0' }} vertical>
      <Container text 
        textAlign="center"
        style={{
          paddingTop: '2em',
          paddingBottom: '1em'
        }}
      >
        {/* <Header
          as='h2'
          content={
          <React.Fragment>
            <span>Who We Are<span style={{color: BrandColors.HEX_COLOR_PRIMARY }}>.</span></span>
          </React.Fragment>}
          style={{
            fontSize: '3em',
            fontWeight: '700',
            marginTop: '0.5em',
            marginBottom: '0em'
          }}
        /> */}
        {/* <Header
          as='h2'
          content={
          <React.Fragment>
            Unlocking Capital for Next-Generation<br/>Distributed Infrastructure Assets
          </React.Fragment>}
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: '1em',
            marginBottom: '0em'
          }}
        /> */}

        {/* <Button as={Link} to="/signup" color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='huge' className="round" 
          style={{
            marginTop: '1em'
          }}
        >
          Learn More About Debt
          <Icon name='right chevron' />
        </Button> */}
      </Container>
    </Segment>
  </div>
)

WhyDebtHeading.propTypes = {
  mobile: PropTypes.bool,
}

const AboutPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <AppContainer {...props}>

      <WhyDebtHeading {...props} />
      
      <Segment style={{ padding: '0em 0em 0em 0em', borderBottom: 'none' }} vertical className="white">

        <Grid container stackable verticalAlign='middle' style={{ paddingTop: '1em' }}>

          <Divider hidden />

          <Grid.Row reversed="computer">
            <Grid.Column width={8} textAlign="center">
              <Image src={'https://upload.wikimedia.org/wikipedia/commons/5/58/PearlStreetStation.jpg'} centered size="medium" />
              <p style={{ marginTop: '6px' }}><i>We believe in investing in the future of cities, hardware, and infrastructure for climate resiliency. But in many ways it all started with <a href="https://en.wikipedia.org/wiki/Pearl_Street_Station" target="_blank" rel="noopener noreferrer">Pearl Street Station</a>, the first commercial plant in the United States. Powered by coal (of course) and built by Thomas Edison's "Edison Illuminating Company" in 1929. It was located on 255-257 Pearl Street in the Financial District of Manhattan, NYC.</i></p>
              <i><p>As of the time of this writing, there are currently still over <a href="https://en.wikipedia.org/wiki/List_of_coal-fired_power_stations_in_the_United_States#:~:text=In%202019%20there%20were%20241,capacity%20was%20about%20236%20GW." target="_blank" rel="noopener noreferrer">240 coal power plants operating in the United States</a> alone.</p></i>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as='h3' style={{ fontSize: '3em' }}>
                About Perl Street
              </Header>
              <p style={{ fontSize: '1.75em' }}>
                Perl Street transforms under-banked hardware companies into bankable deployments with structures formerly reserved for billion-dollar project finance transactions.
              </p>
              <p className="hugeText">
                The age of the traditional power plant is over. The future of infrastructure is small and distributed assets. Perl Street's mission is to help hardware companies deploy and finance their projects earlier than ever before in order to upgrade the world's infrastructure for climate resiliency.
              </p>
              <p className="hugeText">
                With Perl Street, even early-stage hardware companies can build financeable packages of assets and manage them at scale. Perl Street helps hardware companies to bundle their assets to expand their debt financing options with advanced SPV structures, generate recurring revenue through hardware-as-a-service business models, increase their valuation and leverage with equity investors, and to multiply their assets over time with less dilution.
              </p>
            </Grid.Column>
          </Grid.Row>

          <Divider hidden />

          <Grid.Row>
            <Grid.Column>
              <Contact />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Divider
                as='h4'
                className='header'
                horizontal
                style={{ margin: '1em 0em 4em 0', textTransform: 'uppercase' }}
              >
                <p style={{ color: "#9B51E0" }}>MEET THE TEAM BEHIND PERL STREET</p>
              </Divider>
            </Grid.Column>
          </Grid.Row>

          <Card.Group stackable centered itemsPerRow={3} style={{ paddingBottom: '1.2em' }}>
            <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Tooraj-circle.jpg' />
                <Card.Header>Tooraj Arvajeh</Card.Header>
                <Card.Meta>
                  CEO & Co-Founder
                </Card.Meta>
                <Card.Description>
                  Tooraj Arvajeh has 20+ years of experience in developing, financing, and operating $1B+ new infrastructure and climate-tech projects across the world. 
                  Tooraj has worked in senior engineering, project origination, and research roles at Arup, Hatch, BlocPower, and the Building Performance Lab. Tooraj holds degrees in mechanical engineering and business from the University of Waterloo, Columbia Business School, London Business School, and the University of Hong Kong.
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a href="https://www.linkedin.com/in/toorajarvajeh/" rel="noopener noreferrer" target="_blank">
                  <Icon name='linkedin' />
                </a>
                <a href="mailto:tooraj@perlstreet.com">
                  <Icon name='mail' />
                </a>
              </Card.Content>
            </Card>
            {/* <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Dakota-circle.jpg' />
                <Card.Header>Dakota Wixom</Card.Header>
                <Card.Meta>
                CTO & Co-Founder
                </Card.Meta>
                <Card.Description>
                  Dakota Wixom is a quantitative analyst and developer with a background in data science and financial risk management. 
                  Dakota built trading signals and network-based risk analytics for top hedge funds in his former role, launching emerging technology indexes with $100M+ in AUM. Dakota holds degrees in quantitative finance and financial analytics from the Stevens Institute of Technology, and also teaches finance and data science courses to students around the world.
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a href="https://www.linkedin.com/in/dakotawixom/" rel="noopener noreferrer" target="_blank">
                  <Icon name='linkedin' />
                </a>
                <a href="mailto:dakota@perlstreet.com">
                  <Icon name='mail' />
                </a>
              </Card.Content>
            </Card> */}
            <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Arvind-circle.jpg' />
                <Card.Header>Arvind Vermani</Card.Header>
                <Card.Meta>
                CPO & Co-Founder
                </Card.Meta>
                <Card.Description>
                  Arvind Vermani has worked in credit and finance for over two decades in every major international market. He brings a deep understanding of the underwriting and transaction management processes in structured finance, direct lending and situational credit. Arvind holds degrees in Mechanical Engineering and Business Administration from Delhi College of Engineering and Indian Institute of Management Bangalore. 
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a href="https://www.linkedin.com/in/arvindvermani/" rel="noopener noreferrer" target="_blank">
                  <Icon name='linkedin' />
                </a>
                <a href="mailto:arvind@perlstreet.com">
                  <Icon name='mail' />
                </a>
              </Card.Content>
            </Card>
          </Card.Group>

          <Card.Group stackable centered itemsPerRow={3} style={{ paddingBottom: '1.2em' }}>
            <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Ricardo.jpg' />
                <Card.Header>Ricardo Pacheco</Card.Header>
                <Card.Meta>
                  Principal Fullstack Software Engineer
                </Card.Meta>
                <Card.Description>
                Ricardo Pacheco is a Software Engineer with 15+ years in delivering software for multiple industries and technologies. He has extensive experience in building highly available distributed systems, developing frontend/backend/mobile applications, architecting cloud computing infrastructure, leading teams and promoting core Agile practices such as pair programming, TDD and continuous delivery. Ricardo's previous roles include: Senior Engineer at Amazon, Lead Consultant at ThoughtWorks and Solutions Architect at Adidas.
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a href="https://www.linkedin.com/in/ricardo-pacheco-29778127/" rel="noopener noreferrer" target="_blank">
                  <Icon name='linkedin' />
                </a>
                <a href="mailto:ricardo@perlstreet.com">
                  <Icon name='mail' />
                </a>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/jose.jpeg' />
                <Card.Header>Jose Barroso</Card.Header>
                <Card.Meta>
                  Staff Software Engineer
                </Card.Meta>
                <Card.Description>
                  Jose is a software engineer with over 10+ years of experience in software development including work on distributed systems, software-as-a-service products, payment processing, and frontend and backend system design. Jose has experience designing highly scalable architecture, as well as leading technology development using Agile practices, test driven development, continuous delivery, as well as delivering workshops and presentations and building culture and community within the organizations he is a part of. Jose hold a degree in Computer Science and Computer Systems Engineering from the Universidad de Sevilla.
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a href="https://www.linkedin.com/in/theypsilon/" rel="noopener noreferrer" target="_blank">
                  <Icon name='linkedin' />
                </a>
                <a href="mailto:jose@perlstreet.com">
                  <Icon name='mail' />
                </a>
              </Card.Content>
            </Card>
            
            {/* <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Richard.jpg' />
                <Card.Header>Richard Kerrigan</Card.Header>
                <Card.Meta>
                  Transaction and Structuring Advisor (Smart Cities & Infrastructure)
                </Card.Meta>
                <Card.Description>
                  Richard Kerrigan is a financial advisor with 21 years of industry experience in planning, commercializing, structuring, procurement, and financing with over $20 billion in infrastructure, real estate, and energy projects. Richard moved to the U.S. in 2009 to help develop the national transaction advisory business for Arup. In 2014, as the second founding U.S. team member, Richard helped build a seven-person national project finance advisory business. Richard is Corporate Finance professional and licensed professional engineer in the U.K.
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a href="https://www.linkedin.com/in/richardkerrigan/" rel="noopener noreferrer" target="_blank">
                  <Icon name='linkedin' />
                </a>
                <a href="mailto:richard@perlstreet.com">
                  <Icon name='mail' />
                </a>
              </Card.Content>
            </Card> */}
            {/* <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Roberto.jpg' />
                <Card.Header>Roberto Sierra</Card.Header>
                <Card.Meta>
                  Transaction and Structuring Advisor (Smart Cities & Infrastructure)
                </Card.Meta>
                <Card.Description>
                  Roberto Sierra is a project finance professional with over 15 years of experience in financial advisory for
                  infrastructure delivered through innovative delivery, including public-private partnerships. Roberto has
                  advised on projects with a cumulative capital investment value over $10 billion and has developed a
                  comprehensive understanding of political, legal, and technical considerations for complex transit and
                  transportation infrastructure projects. Roberto holds a degree in economics from Zaragoza University.
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a href="https://www.linkedin.com/in/roberto-sierra-71b86812/" rel="noopener noreferrer" target="_blank">
                  <Icon name='linkedin' />
                </a>
                <a href="mailto:roberto@perlstreet.com">
                  <Icon name='mail' />
                </a>
              </Card.Content>
            </Card> */}
          </Card.Group>

          <Card.Group stackable centered itemsPerRow={3} style={{ paddingBottom: '1.2em' }}>
            <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Mark-circle.jpg' />
                <Card.Header>Mark Paris</Card.Header>
                <Card.Meta>
                  Chairman
                </Card.Meta>
                <Card.Description>
                  30+ years in cities, technology and finance. Partner at Urban Us, Managing Director of URBAN-X, an urban tech accelerator program.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Shaun-circle.jpg' />
                <Card.Header>Shaun Abrahamson</Card.Header>
                <Card.Meta>
                  Advisor
                </Card.Meta>
                <Card.Description>
                  Managing Partner at Urban Us, Angel & LP (5 exits) 3 time operator (1 exit). The Economist. University Cape Town, MIT CADLab.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Peter.jpg' />
                <Card.Header>Peter Versteeg, PhD</Card.Header>
                <Card.Meta>
                  Advisor
                </Card.Meta>
                <Card.Description>
                  Peter holds a PhD from Carnegie Mellon specializing in CCS technologies. Commissioning engineer of the carbon capture plant at Boundary Dam Unit 3, past member at the International CCS Knowledge Center.
                </Card.Description>
              </Card.Content>
            </Card>
          </Card.Group>

          {/* <Card.Group stackable centered itemsPerRow={2} style={{ paddingBottom: '1.2em' }}>
            <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Chris_sq.jpg' />
                <Card.Header>Chris Preuss</Card.Header>
                <Card.Meta>
                  Quantitative Structuring
                </Card.Meta>
                <Card.Description>
                  Chris Preuss is a structurer and quantitative developer with a background in data science, investment management and credit risk. Chris built bond pricing models, portfolio optimisation and monitoring tools, and off-balance sheet financing structures for European-based investment firms in his former role. He also worked on agent-based models, network-based collateral and credit exposure techniques to quantify systemic risk. Chris holds degrees in finance with a specialisation in investments from IE Business School.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Abijit.jpeg' />
                <Card.Header>Abijit Aji</Card.Header>
                <Card.Meta>
                  Transaction Advisory
                </Card.Meta>
                <Card.Description>
                  Abijit has 12+ years of experience in power generation, research organizations, investment banking advisory, venture capital, and clean-tech startups. 
                  Specializes in business model innovation, financial structuring, technology due-diligence, international business development, transaction advisory, deal origination, infrastructure finance, renewable energy, and advanced financial modelling.
                  Abijit holds a degree in energy finance and policy from Columbia University as well as degrees in chemical engineering and business management. 
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Lilian.jpeg' />
                <Card.Header>Lilian Chen</Card.Header>
                <Card.Meta>
                  Underwriting & Project Management
                </Card.Meta>
                <Card.Description>
                  Financial analyst with a background in project management and real estate. Underwriting $100M+ commercial real estate transactions, acquisition and asset management at Carvel Consulting. B.A. in economics and city planning from UC Berkeley. Global traveler, speaks 4 languages!
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Image avatar floated="left" size="massive" src='/media/portraits/Diego.jpg' />
                <Card.Header>Diego Ramirez</Card.Header>
                <Card.Meta>
                  Lead Financial Analyst
                </Card.Meta>
                <Card.Description>
                  6+ years of experience in investment banking, corporate finance, and financial planning with a background in origination, fundraising and M&A of $300M+ in deals in LATAM and in the United States. Diego is also a Masters in Finance candidate at London Business School and CFA level 1.
                </Card.Description>
              </Card.Content>
            </Card>
          </Card.Group> */}

        </Grid>
      </Segment>

      <Footer />
    </AppContainer>
  );
}
export default AboutPage;