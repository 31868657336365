import {Button} from "semantic-ui-react";
import React from "react";


export function GoBackButton() {
  return <Button
    basic
    labelPosition="left"
    icon="left chevron"
    size={"mini"}
    onClick={() => window.history.back()}
    content="Back"
  />
}