import React, {useMemo, useState} from "react";
import {Grid, Icon, Menu, MenuItemProps, Segment} from "semantic-ui-react";
import {CompanyManagement} from "./entity-managers/CompanyManagement";
import {UserManagement} from "./entity-managers/UserManagement";

type ADMIN_CAPABILITIES = "companies" | "users"
export function AdminPage() {
  const [activeItem, setActiveItem] = useState<ADMIN_CAPABILITIES>("users");

  const controllerComponent = useMemo(()=> {
    switch (activeItem){
      case "users":
        return <UserManagement />
      case "companies":
        return <CompanyManagement />
    }
  }, [activeItem])

  return <div>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={3}>
          <Sidebar activeItem={activeItem} setActiveItem={setActiveItem} />
        </Grid.Column>
        <Grid.Column width={13}>
          {controllerComponent}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
}


function Sidebar({activeItem, setActiveItem}: {activeItem: ADMIN_CAPABILITIES, setActiveItem: (item:ADMIN_CAPABILITIES)=>void}) {
  const handleItemClick = (e: any, { name }: MenuItemProps) => {
    setActiveItem(name as ADMIN_CAPABILITIES)
  };

  return (
      <Segment basic textAlign="left">
        <Menu vertical color="purple" style={{ margin: "auto", width: "100%" }}>

          <Menu.Item>
            <Menu.Header style={{
              color: "#9b51e0",
              fontSize: "18px",
              fontWeight: 900,
              margin: 0
            }}>PS Admin Console</Menu.Header>
          </Menu.Item>

          <Menu.Item
              name='users'
              active={activeItem === 'users'}
              onClick={handleItemClick}
          >
            <Icon className='users' />
            Users
          </Menu.Item>

          <Menu.Item
              name='companies'
              active={activeItem === 'companies'}
              onClick={handleItemClick}
          >
            <Icon className='city' />
            Companies
          </Menu.Item>
        </Menu>
      </Segment>
  );
}