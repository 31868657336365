import React, {useEffect} from "react";
import {StoreQuery, QueryResult} from "../../../ps-models/lineitems-store";
import {DateRange, useDateRange} from "../../../lineitems-store/DateRange";
import {Metric} from "../../../statistics/Metric";
import {TimeSeriesChart} from "../../../lineitems-store/TimeSeriesChart";
import {useSiteStoreContext} from "../../siteStoreLoader";
import {Grid, Header, Segment} from "semantic-ui-react";
import {WSTimeSeriesChart} from "./WSTimeSeriesChart";
import {DateRangeType} from "../../../ps-types";

export function SitePerformance() {
  let store = useSiteStoreContext();
  let [dateRange, setDateRange] = React.useState<DateRangeType>({
    from: store.timeIndex.startDate,
    to: store.timeIndex.endDate
  });

  console.info("store", store.timeIndex.startDate, store.timeIndex.endDate);

  console.info("SitePerformance", dateRange);

  let result: QueryResult = store.query(StoreQuery.byNames([
    'generation_export_kwh',
    'all_consumption_kwh',
    'generation_import_kwh',
    'pv_generation_kwh',
    'grid_export_kwh',
    'grid_import_kwh',
    'siteunavailable',
    "Battery Unavailable Units",
    "Solar Unavailable Units",

    "Solar Storage",
    "Grid Storage"

  ]).aggregateOverTimeRange(dateRange.from, dateRange.to));



  return <Segment basic>
    <Header as="h2" color="purple">Site Performance</Header>

    <DateRange
      initialStartDate={dateRange.from}
      initialEndDate={dateRange.to}
      onSelect={(from, to) => setDateRange({from, to})}/>,

    {result &&<div >
        <Segment>
            <Grid solid>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Metric label={ "Total Consumption"}
                                value={ result.firstTimeSlotTextOf('all_consumption_kwh') }
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <Metric label={ "Total PV Generation"}
                                value={ result.firstTimeSlotTextOf('pv_generation_kwh') }
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <Metric label={ "Total Generation Export"}
                                value={ result.firstTimeSlotTextOf('generation_export_kwh') }
                        />
                    </Grid.Column>



                </Grid.Row>

                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Metric label={ "Total Generation Import"}
                                value={ result.firstTimeSlotTextOf('generation_import_kwh') }
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Metric label={ "Total Grid Export"}
                                value={ result.firstTimeSlotTextOf('grid_export_kwh') }
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <Metric label={ "Total Grid Import"}
                                value={ result.firstTimeSlotTextOf('grid_import_kwh') }
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Metric label={ "Solar Storage"}
                                value={ result.firstTimeSlotTextOf('Solar Storage') }
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Metric label={ "Grid Storage"}
                                value={ result.firstTimeSlotTextOf('Grid Storage') }
                        />
                    </Grid.Column>


                </Grid.Row>
            </Grid>
        </Segment>
        <Segment>
            <Grid solid>
                <Grid.Row columns={2}>
                    <Grid.Column width={4} divided>
                        <Grid.Row>
                            <Metric label={ "Solar Unavailable Units"}
                                    value={ result.firstTimeSlotTextOf('Solar Unavailable Units') }
                            /><br/><br/>
                        </Grid.Row>
                        <Grid.Row>
                            <Metric label={ "Battery Unavailable Units"}
                                    value={ result.firstTimeSlotTextOf('Battery Unavailable Units') }
                            /><br/><br/>
                        </Grid.Row>
                        <Grid.Row centered>
                            <Metric label={ "Site Unavailable"}
                                    value={ result.firstTimeSlotTextOf('siteunavailable') }
                            />
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column  width={12}>
                        <LineItemsChart />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </Segment>
    </div>

    }

  </Segment>
}

function LineItemsChart({title}: {title?: string}) {
  let store = useSiteStoreContext();

  if (!store) return null;

  let result = store.query(StoreQuery
    .byNames(['siteunavailable', 'batteryunavailable', 'solarunavailable']));

  return <WSTimeSeriesChart
    title={title || ""}
    result={result}
  />
}