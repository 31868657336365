import {Select, SelectProps} from "semantic-ui-react";
import {keys} from "ramda";
import {LineItemsStore} from "../ps-models/lineitems-store";
import {ValueType} from "../ps-models/line-items";

const buildSelectOption = (value: ValueType)=> {
    return {
        value: value,
        text: value,
        key: value.toString()
    }
}

interface StoreFieldSelectorProps {
    store: LineItemsStore;
    fieldNamesToFilterOut?: string[];
}
export function StoreFieldSelector(props: StoreFieldSelectorProps & Omit<SelectProps, 'options'>){
    const {store, fieldNamesToFilterOut, ...selectComponentProps} = props;
    let completeFieldIndex = store.getDataSet().getFieldsIndex();
    let allFieldNames = keys(completeFieldIndex);
    let selectableFieldNameOptions = allFieldNames.filter((fieldName)=>!(fieldNamesToFilterOut ?? []).includes(fieldName)).map(fieldName => (buildSelectOption(fieldName)));
    return  <Select
        search selection
        options={selectableFieldNameOptions}
        {...selectComponentProps}
    />
}