import {PersistenceQuery} from "../../ps-models/lineitems-store";
import React, {useState} from "react";
import {Button, Checkbox, Dropdown, Grid, Header, Input, Radio, Segment, Tab, Table, Form, DropdownProps, CheckboxProps, Select} from "semantic-ui-react";

import {DashboardConfigService, useDashboardService} from "./DashboardConfigService";
import {QueryEditor} from "./StoreQueryEditor";
import {TimeIndexEditorStandalone} from "../line-items-editor/TimeIndexEditor";
import {ExtraLineItems} from "./ExtraLineItems";
import {QueryEditorNew} from "./FilterStores";

import {ClosableSection} from "../../ClosableSection";
import {renderLogsContainer} from "../../ui/ProcessLogs";
import {ExcelReportInjectionConfig, LineItemStoreLogs, TimeUnits} from "../../ps-models";
import {NavigationConfig, InjectionTemplateConfig} from "../../ps-types";
import {FilterEditor} from "./FilterEditor";
import {JSONCodeEditor} from "../../ui/CodeEditor";
import {LineItemsExtensionsEditor} from "./LineItemsExtensionsEditor";
import {LineItemsStoreDebug} from "../LineItemsStoreDebug";

export function AggregationBuilder() {
  let {getService, ready} = useDashboardService();

  let store = getService().getStore();
  let boardService = getService();
  let boardConfig = boardService.getConfig();
  let boardQuery = boardConfig.getQuery();
  let [intermediatePQuery, setIntermediatePQuery] = useState<PersistenceQuery>(boardQuery);

  const panes = [
    { menuItem: 'Query Stores', render: () => <Tab.Pane> <>
            <QueryEditorNew
                query={boardQuery} onQueryUpdate={(updatedQuery)=>{
                boardConfig.setQuery(updatedQuery)
            }}
            />
        </>
      </Tab.Pane> },
    { menuItem: 'Select Line Items', render: () => <Tab.Pane>
        <QueryEditor query={boardQuery} onQueryUpdate={(updatedQuery)=>{
          setIntermediatePQuery(updatedQuery);
        }} />
        <Button primary onClick={()=>{
          if(intermediatePQuery){
            boardConfig.setQuery(intermediatePQuery)
          }
        }}>Apply</Button>
        <ClosableSection opened={false}  title="Resultant Line Items" level="medium"  >
          <Table celled compact size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Line Item</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(Object.keys(store.getDataSet().getLineItems())).map((liName) => (
                  <Table.Row key={liName}>
                    <Table.Cell>{liName}</Table.Cell>
                  </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ClosableSection>

        <ClosableSection opened={false}  title="All Line Items" level="medium"  >
            <LineItemsStoreDebug
              store={store}
              />
        </ClosableSection>

      </Tab.Pane> },
    { menuItem: 'Add Calculated', render: () => <Tab.Pane>
        <ExtraLineItems store={store} type={'Calculated'} />
      </Tab.Pane> },
    { menuItem: 'Add Parameter', render: () => <Tab.Pane>
      <ExtraLineItems store={store} type={'Parameter'} />
    </Tab.Pane> },
    { menuItem: 'Add Template Line Items', render: () => <Tab.Pane>
      <ExtraLineItems store={boardService.getTemplateStore()} type={'Template'} />
      </Tab.Pane> },
    { menuItem: 'Time Config', render: () => <Tab.Pane>
        <>
          <TimeIndexEditorStandalone initiallySelectedDates={{startDate: store.timeIndex.startDate, endDate: store.timeIndex.endDate}}
                                     initialGranularity={store.timeIndex.getUnit()}
         onUpdate={(updatedTimeIndex)=>{
          setIntermediatePQuery((prev)=>prev.withTimeIndex(updatedTimeIndex))
        }} />
          <Button primary onClick={()=>{
            if(intermediatePQuery){
              boardConfig.setQuery(intermediatePQuery)
            }
        }}>Apply</Button></>
      </Tab.Pane> },
    { menuItem: 'Logs', render: () => <Tab.Pane>
      <LogsTab storeLogs={store.getAggregatedLogs()}/>
      </Tab.Pane> },
    {menuItem: 'Global Components', render: () => <Tab.Pane>
            <Checkbox
                label={"Expose Granularity Filter"}
                checked={boardConfig.isGranularityFilterExposed()}
                onChange={(e, {checked}) => {
                    boardConfig.setExposeGranularityFilter(!!checked);
                    ready();
                }}
            />
            <Checkbox
                label={"Expose Project Version Selector"}
                checked={boardConfig.isProjectVersionSelectorExposed()}
                onChange={(e, {checked}) => {
                    boardConfig.setExposeProjectVersionSelector(!!checked);
                    if(!!checked === false){
                        // Remove filters on storeIds, which were applied upon default version selection, when the component was exposed.
                        boardConfig.setQuery(boardConfig.getQuery().withStoreIds([]))
                    } 
                    ready();
                }}
           />
            <Checkbox
                label={"Expose Scenario Comparator"}
                checked={boardConfig.isScenarioComparatorExposed()}
                onChange={(e, {checked}) => {
                    boardConfig.setExposeScenarioComparator(!!checked);
                    ready();
                }}
            />
            <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column', border: '1px dotted gray', width: '35%', gap: '2px'}}>
                <strong>Global Range Selector</strong>
                <GlobalRangeSelectorConfigurator />
            </div>
            <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column', border: '1px dotted gray', width: '35%', gap: '2px'}}>
                <strong>Date Range Selectors</strong>
                <Radio
                    label='None'
                    name='utcDateRange'
                    checked={boardConfig.getUTCDateSelector() === undefined}
                    onChange={(e, {checked}) => {
                        if(!!checked === true){
                            boardConfig.setUTCDateSelector(undefined);
                            boardService.updateGlobalContext({utcDateRange: undefined})
                            ready();
                        }
                    }}
                />
                <Radio
                    label='Date Range'
                    name='utcDateRange'
                    checked={boardConfig.getUTCDateSelector()?.rangeSelection === true}
                    onChange={(e, {checked}) => {
                        if(!!checked === true){
                            boardConfig.setUTCDateSelector({...boardConfig.getUTCDateSelector(),rangeSelection: !!checked})
                        } else {
                            boardConfig.setUTCDateSelector(undefined)
                        }
                        ready();
                    }}
                />
                <Radio
                    label='Single Point Date'
                    name='utcDateRange'
                    checked={boardConfig.getUTCDateSelector()?.rangeSelection === false}
                    onChange={(e, {checked}) => {
                        if(!!checked === true){
                            boardConfig.setUTCDateSelector({...boardConfig.getUTCDateSelector(),rangeSelection: !checked})
                        } else {
                            boardConfig.setUTCDateSelector(undefined)
                        }
                        ready();
                    }}
                />
                {boardConfig.getUTCDateSelector() !==undefined && <Checkbox
                    label={"With Granularity Selector"}
                    checked={boardConfig.getUTCDateSelector()?.withGranularitySelector === true}
                    onChange={(e, {checked}) => {
                        boardConfig.setUTCDateSelector({...boardConfig.getUTCDateSelector(), withGranularitySelector: !!checked})
                        ready();
                    }}
                />}
            </div>
            <FilterEditor />
        </Tab.Pane>},
    {
      menuItem: 'Navigation/Report Config',
      render: () => <Tab.Pane>
        <NavigationConfigEditor service={getService()} />
        <InjectionTemplateConfigEditor service={getService()} />
      </Tab.Pane>
    },
    {
      menuItem: 'Extension Line Items',
      render: () => <Tab.Pane>
    <LineItemsExtensionsEditor service={getService()} />
      </Tab.Pane>
    }
  ]

  return (
    <Segment>
      <Tab panes={panes} />
    </Segment>
  );
}


const LogsTab = ({storeLogs}: {storeLogs: LineItemStoreLogs})=>{
    const messages = storeLogs.serialize();
    return <Grid>
        <Grid.Row columns={1}>
            <Grid.Column width={16}>
                {renderLogsContainer(messages.err, 'error', '150px')}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
            <Grid.Column width={8}>
                {renderLogsContainer(messages.warning, 'warning', '200px')}
            </Grid.Column>
            <Grid.Column width={8}>
                {renderLogsContainer(messages.information, 'info', '200px')}
            </Grid.Column>
        </Grid.Row>
    </Grid>
}


export function NavigationConfigEditor({service}: {service: DashboardConfigService}) {

  const [updated, setUpdated] = useState(0);
  const navigationConfig = service.getNavigationConfig();

  const handleValueChange = (key: any, value: string | number) => {
    service.setNavigationConfig({...navigationConfig, [key]: value} as NavigationConfig);
    setUpdated(updated + 1);
  }

  return <div>
    <Header>Navigation Config</Header>
    Title:
    <Input value={navigationConfig?.menuName}
      onChange={(e) => handleValueChange('menuName', e.target.value)}
    />
    Key:
    <Input value={navigationConfig?.key}
      onChange={(e) => handleValueChange('key', e.target.value)}
    />
    Parent:
    <Input value={navigationConfig?.parentId}
      onChange={(e) => handleValueChange('parentId', e.target.value)}
    />
    Order:
    <Input value={navigationConfig?.order}
      onChange={(e) => handleValueChange('order', parseInt(e.target.value))}
           />
  </div>

}

export function InjectionTemplateConfigEditor({service}: {service: DashboardConfigService}) {

    const [updated, setUpdated] = useState(0);
    const injectionTemplateConfig = service.getInjectionTemplateConfig();

    const handleExposeAsInjectionTemplateChange = (value: boolean) => {
        service.setExposeAsInjectionTemplate(value);
        setUpdated(updated+1);
    }

    const handleValueChange = (key: any, value: string | number | ExcelReportInjectionConfig) => {
        service.setInjectionTemplateConfig({...injectionTemplateConfig, [key]: value} as InjectionTemplateConfig);
        setUpdated(updated + 1);
    }

    const handleTemplateFileChange = (file: File)=>{
        service.setReportTemplateExcelFile(file);
        setUpdated(updated+1);
    }


    return <div>
        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column', border: '1px dotted gray', width: '35%', marginTop: '20px', padding: '5px', gap: '2px'}}>
            <Checkbox
                label={"Expose As Injection Template"}
                checked={service?.getExposedAsInjectionTemplate()}
                onChange={(e, {checked}) => {
                    handleExposeAsInjectionTemplateChange(!!checked);
                }}
            />
        </div>
        {service.getExposedAsInjectionTemplate() === true && <><Header>Injection Template Config</Header>
        Title:
        <Input value={injectionTemplateConfig?.name}
               onChange={(e) => handleValueChange('name', e.target.value)}
        />
        Type: <Select
                selection
                value={injectionTemplateConfig?.type}
                options={
                [
                    {value: "Report", label: "Report"},
                    {value: "Invoice", label: "Invoice"}]
            }  onChange={(e, data) => handleValueChange('type', data.value as InjectionTemplateConfig['type'])} placeholder={"Select type"} />

            Excel Injection Config:
        <JSONCodeEditor initialCode={JSON.stringify(injectionTemplateConfig?.injectionConfig ?? "", null, 2)}
                        onValidated={(parsedCode)=> {
            handleValueChange('injectionConfig', parsedCode as ExcelReportInjectionConfig)
        }} />

        Template Excel File:
        <Input type={"file"}
               accept={".xls, .xlsx, .xlsm"}
               onChange={(e) => {
                   let file = e.target.files?.[0];
                   if(file) handleTemplateFileChange(file)
               }}
        />
        {/*{reportTemplateConfig?.templateFileUri && <Button*/}
        {/*    href={reportTemplateConfig.templateFileUri}>Download Last Template</Button>}*/}
           </>}
        {/*Static Report Granularity Level:*/}
        {/*<Input*/}
        {/*       value={reportTemplateConfig?.staticGranularityLevel}*/}
        {/*       onChange={(e) => handleValueChange('staticGranularityLevel', e.target.value)}*/}
        {/*/>*/}
    </div>
}

function buildGranularitySelectOption(value:string){
    return {
        value: value,
        text: value.charAt(0).toUpperCase() + value.slice(1, -1),
        key:  value,
    }
}

const GlobalRangeSelectorConfigurator = () => {
    let {getService, ready} = useDashboardService();

    let boardService = getService();
    let boardConfig = boardService.getConfig();

    const [selectedOptions, setSelectedOptions] = useState(boardConfig.getGlobalRangeSelectorConfig().availableGranularities);
    const [defaultOption, setDefaultOption] = useState<TimeUnits | "">(boardConfig.getGlobalRangeSelectorConfig().defaultGranularity);
    const [error, setError] = useState(false);

    // Handle dropdown change
    const handleDropdownChange = (e: any, { value }: DropdownProps) => {
        let castedValue = value as TimeUnits[]
        setSelectedOptions(castedValue);
        if (castedValue.length === 0 || (defaultOption !== '' && !castedValue.includes(defaultOption))) {
            setError(true);
            setDefaultOption(''); // Reset default if it's no longer in selected
        } else {
            setError(false);
        }
    };

    // Handle radio button change for default option
    const handleDefaultChange = (e: any, { value }: CheckboxProps) => {
        setDefaultOption(value as TimeUnits | "");
        setError(false); // Clear error if a valid default is selected
    };

    // Handle form submission
    const handleSubmit = () => {
        if (selectedOptions.length === 0 || defaultOption === '') {
            setError(true);
            return;
        }

        boardConfig.setGlobalRangeSelectorConfig({defaultGranularity: defaultOption, availableGranularities: selectedOptions});
        boardService.updateGlobalContext({granularity: defaultOption})
        ready();
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Field>
                <label>Select Granularities</label>
                <Dropdown
                    placeholder='Select Granularities'
                    fluid
                    multiple
                    selection
                    options={["months", "quarters", "years"].map((value)=>buildGranularitySelectOption(value))}
                    value={selectedOptions}
                    onChange={handleDropdownChange}
                    error={error && selectedOptions.length === 0}
                />
                {error && selectedOptions.length === 0 && (
                    <div style={{ color: 'red', marginTop: '5px' }}>
                        Please select at least one granularity.
                    </div>
                )}
            </Form.Field>

            {selectedOptions.length > 0 && (
                <Form.Field>
                    <label>Select Default Granularity</label>
                    {selectedOptions.map((option) => (
                        <Form.Field key={option}>
                            <Radio
                                label={option}
                                name='defaultGranularity'
                                value={option}
                                checked={defaultOption === option}
                                onChange={handleDefaultChange}
                            />
                        </Form.Field>
                    ))}
                    {error && defaultOption === '' && (
                        <div style={{ color: 'red', marginTop: '5px' }}>
                            Please select a default granularity.
                        </div>
                    )}
                </Form.Field>
            )}

            <Button type='submit'>Submit</Button>
        </Form>
    );
};