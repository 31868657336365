import React from 'react';

import FooterMenu from '../FooterMenu/FooterMenu.component';
import FooterCTA from '../FooterCTA/FooterCTA.component';

const Footer = () => (
  <React.Fragment>
    <FooterCTA />
    <FooterMenu />
  </React.Fragment>
);

export default Footer;