import {LocatorMap, LocatorParams} from "../ps-models/exa";

export enum ConfigType {
  Salesforce = "salesforce",
  ProjectTemplate = "projectTemplate",
  WhatIfScenario = "whatIfScenario"
}

export interface CompanyConfig<T extends ConfigEntry> {
  companyId: string,
  configType: ConfigType
  configs: Record<string, T>
}

export interface ConfigEntry {
  configName: string,
  configKey: string
}

export interface SalesforceConfig extends ConfigEntry {
  salesforceApi: 'report' | 'soql',
  salesforceReportId?: string,
  salesforceSoqlQuery?: string,
  projectIdColumn: string, //This is the column with the project ID in the Salesforce report
  versionDataProjectId?: string, //This is the versionData field in the PS project that contains the project ID

  mappingConfig: Record<string, SalesforceReportCellMapping>
}

export interface SalesforceReportCellMapping  {
  valueKey: string, //to cross reference the locator
  valueColumn: string | number,
  useValueText?: boolean,
}

export interface ProjectTemplateConfig extends ConfigEntry {
  config: any //JSON string
}

export interface WhatIfScenarioMappingConfig {
  valueKey: string,
  locator: LocatorParams,
  label: string
}

export interface WhatIfScenarioConfig extends ConfigEntry {
  mappingConfig: Record<string, WhatIfScenarioMappingConfig>;
}




