import React, { useEffect } from "react";
import { Header, Segment, List, Button, Container, Image, Label, Divider, Icon } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";

import { getCompanies } from "../../../redux/admin/admin.actions";
import { selectCompaniesData, selectCompaniesStatus } from "../../../redux/admin/admin.selectors";

import {lastModifiedCompare, extractSecondsFromDate} from "../../../utils/utilfunctions";

import CompanyCreate from "./CompanyCreate.component";
import CompaniesHeader from "./CompaniesHeader";

function CompaniesList() {
  const dispatch = useDispatch();
  const companies = useSelector(selectCompaniesData);
  const companiesStatus = useSelector(selectCompaniesStatus);

  const loading = companiesStatus === "loading";
  const sortedCompanies = (companies || []).sort(lastModifiedCompare);

  const defaultTag = "qlead";
  const sortTags = sortedCompanies.map(c => c.sortTag || defaultTag);
  let sortTags_uniq = sortTags ? [ ...new Set(sortTags) ] : [];

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  const renderSublist = (companies) => {

    return <>{
      companies.map((company, company_i) => {
        return (
          <List.Item key={company.id || company_i}>
            <List.Content floated='right'>
              <Link to={company.id ? `/admin/companies/${company.id}` : "#"}><Button size="tiny" compact>Manage</Button></Link>
            </List.Content>
            { company.logoUrl ? <Image src={company.logoUrl} avatar className="square" /> : <List.Icon name="building" /> }
            
            <List.Content>
              <List.Header>
                <Header size="small"><Link to={company.id ? `/admin/companies/${company.id}` : "#"}>{company.name || `UNDEFINED: ${company.id}`}</Link></Header>
              </List.Header>
              { company.lastModified && (
                <List.Description>
                  Updated on { company.lastModified && moment(extractSecondsFromDate(company.lastModified) * 1000).format('MMMM Do YYYY, h:mm a') }
                </List.Description>
              ) }
              <List.Description>
                {(company.members && company.members.length) ? <Label size="mini" color="purple" icon="users" content={`${company.members.length} Members`} /> : null}
                {(company.scenarios && company.scenarios.length) ? <Label size="mini" color="purple" icon="code branch" content={`${company.scenarios.length} Scenarios`} /> : null}
              </List.Description>
            </List.Content>
          </List.Item>
        )
      })
    }</>
  }

  return(
    <Segment basic>
      <Container>
        <CompaniesHeader />
        <Divider hidden />
        <Divider hidden />
        <Segment.Group raised>
          <Segment attached>
            <Header size="tiny" className="tightHeader" icon={<Icon className="city" />} content={`All Companies`} />
          </Segment>
          <Segment secondary>
            <CompanyCreate size="small" />
          </Segment>
          <Segment attached color="purple" loading={loading}>
            
            {(!loading && !sortedCompanies.length) ? (<p>No currently assigned companies. Please contact team@perlstreet.com</p>) : null}
            {loading && <p>Loading ...</p>}
            <List relaxed animated divided>
              {sortTags_uniq.map(tag => {
                const sublist = sortedCompanies.filter(c => tag !== defaultTag ? 
                    c.sortTag === tag : 
                    c.sortTag === tag || c.sortTag === undefined
                  );
                let tagTitle = tag;
                if (tagTitle === "lead") tagTitle = "Unqualified Leads";
                if (tagTitle === "customer") tagTitle = "Customers";
                if (tagTitle === defaultTag) tagTitle = "Qualified Leads";
                return <React.Fragment key={tagTitle}>
                  <Header size="tiny" content={tagTitle} />
                  {renderSublist(sublist)}
                </React.Fragment>
              })}
            </List>
          </Segment>
        </Segment.Group>
      </Container>
    </Segment>
  );
}

export default CompaniesList;