const UserActionTypes = {

  CREATE_INITIAL_USERPROFILE_START: "CREATE_INITIAL_USERPROFILE_START",
  CREATE_INITIAL_USERPROFILE_SUCCESS: "CREATE_INITIAL_USERPROFILE_SUCCESS",
  CREATE_INITIAL_USERPROFILE_FAILURE: "CREATE_INITIAL_USERPROFILE_FAILURE",

  CREATE_INITIAL_COMPANY_START: "CREATE_INITIAL_COMPANY_START",
  CREATE_INITIAL_COMPANY_SUCCESS: "CREATE_INITIAL_COMPANY_SUCCESS",
  CREATE_INITIAL_COMPANY_FAILURE: "CREATE_INITIAL_COMPANY_FAILURE",

  COMPLETE_ONBOARDING_STEP_START: "COMPLETE_ONBOARDING_STEP_START",
  COMPLETE_ONBOARDING_STEP_SUCCESS: "COMPLETE_ONBOARDING_STEP_SUCCESS",
  COMPLETE_ONBOARDING_STEP_FAILURE: "COMPLETE_ONBOARDING_STEP_FAILURE",

  INTAKE_FORM_START: "INTAKE_FORM_START",
  INTAKE_FORM_SUCCESS: "INTAKE_FORM_SUCCESS",


  COMPLETE_USER_INTAKE: "COMPLETE_USER_INTAKE",
  UPDATE_USER_INTAKE: "UPDATE_USER_INTAKE",
};

export default UserActionTypes;
