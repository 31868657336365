import {LineItemsStore, StoreQuery} from "../../ps-models/lineitems-store";
import React, {useState} from "react";
import {indexBy, values} from "ramda";
import {Dropdown} from "semantic-ui-react";
import {OrderedArrayInput} from "../../ui/OrderedArrayInput";

export function LineItemNamesSelector({store, selectedLineItems, onSelected}: {
  store?: LineItemsStore,
  onSelected: (liNames: string[]) => void,
  selectedLineItems: string[]
}) {
  let [localStateOfSelectLis, setLocalStateOfSelectLis] = useState<string[]>(selectedLineItems);
  let lineItems = store?.getLineItems(StoreQuery.all()) || {};
  let lineItemNames = indexBy(lineItem =>
      lineItem.fields.getFieldStr('store_sourceLineItemName')
    , values(lineItems));

  let options = values(lineItemNames)
    .filter((l) => !localStateOfSelectLis.includes(l.fields.getFieldStr('store_sourceLineItemName')))
    .map(lineItem => ({
      value: lineItem.fields.getFieldStr('store_sourceLineItemName'),
      text: lineItem.fields.getFieldStr('store_sourceLineItemName'),
      key: lineItem.fields.getFieldStr('store_sourceLineItemName')
    }));

  const handleSelect = (selectedLiName: string) => {
    setLocalStateOfSelectLis([...selectedLineItems, selectedLiName]);
  }

  const handleSelectionUpdate = (selectedLis: string[]) => {
      if(JSON.stringify(localStateOfSelectLis) !== JSON.stringify(selectedLis)){
          setLocalStateOfSelectLis(selectedLis)
      }
      onSelected(selectedLis)
  }

  return <>
    <Dropdown
      style={{width: "100%"}}
      search
      selection
      options={options}
      placeholder={'No value selected'}
      selectOnBlur={false}
      fluid
      onChange={(e, data) => {
        let val = data.value;
        handleSelect(val as string);
      }}
    />
    <OrderedArrayInput items={localStateOfSelectLis} onSelected={handleSelectionUpdate} />
  </>
}