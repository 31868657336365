import moment from "moment";
import {extractSecondsFromDate} from "../utils/utilfunctions";
import firebase from "firebase";
import {updateProjectConfig} from "../platform/project-config/project-config.client";
import {auth, firestore, storage} from "./firebase.core";
import {v4 as uuidv4} from "uuid";
import {authStorage} from "../platform/auth";

export async function updateUserSubscriptionManually(uid, type) {
  if (!uid) return;
  console.log("Handling case ",type," user: ",uid);
  switch (type) {
    case "upgrade":
      await firestore.collection('users').doc(uid).set({ stripeOverride: "active" }, { merge: true });
      return window.location.reload(); // TBD - Update store instead
    case "downgrade":
      await firestore.collection('users').doc(uid).set({ stripeOverride: "inactive" }, { merge: true });
      return window.location.reload(); // TBD - Update store instead
    default:
      return;
  }
}

export async function getUserCompanySubscriptions(user, adminAdjust=true) {
  const uid = user.id;
  const stripeOverride = user.stripeOverride;
  if (!uid) return;

  // TBD - move to server function?

  // First check to see if user has any active subscriptions
  const companySubscriptionsRef = await firestore.collection('users')
    .doc(uid)
    .collection('subscriptions')
    .where('status', 'in', ['trialing', 'active'])
    .get();
    // .onSnapshot(async (snapshot) => {
    //   // In this implementation we only expect one active or trialing subscription to exist.
    //   const doc = snapshot.docs[0];
    //   console.log(doc.id, ' => ', doc.data());
    // });
  let companySubscriptions = companySubscriptionsRef.docs.map(d => ({ id: d.id, ...(d.data()) }));

  // TBD - Handle Perl Street admin users as well
  // TBD - Do this securely - server side perhaps?
  const currentUser = authStorage.getMaybeUser();

  // Handle override from input param or admin case
  if ((adminAdjust && currentUser && currentUser.isAdmin()) || stripeOverride === "active") {
    companySubscriptions = [...companySubscriptions, { id: "ps-admin", metadata: { accessLevel: "1" }}]
  }
  if (stripeOverride === "inactive") {
    companySubscriptions = [];
  }

  // TBD - Check other company users for subscriptions

  return companySubscriptions;
}

export const createProjectSharetoken = async (projectId) => {
  try {

    // Check params before proceeding
    // // // if(!userAuth.currentUser) new Error("No user ID defined");
    if (!projectId) throw new Error("No project id defined");

    // Create sharetoken (and shorten it for usability)
    let shareTokenKey = uuidv4(); // e.g. '55af1e37-0734-46d8-b070-a1e42e4fc392'
    shareTokenKey = shareTokenKey.split('-')[0].toUpperCase(); // e.g. '55AF1E37'
    const shareToken = {
      key: shareTokenKey,
      createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
    }

    // Add a shareToken to the project shareTokens array (or create one if it does not already exist)
    const projectConfigsRef = firestore.collection('project-configs').doc(projectId);
    projectConfigsRef.set({
      shareTokens: firebase.firestore.FieldValue.arrayUnion(shareToken)
    }, { merge: true });

    // Return data to client
    const returnObj = shareToken
    return (returnObj);

  } catch (error) {
    const eMsg = 'Error creating project sharetoken: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

// export const loadProjectDocument = async (projectId) => {
//   try {
//     // Copy and extract params
//     // const projectData = { ...projectDataRaw };
//     // const { companyId } = projectData;

//     // Check params before proceeding
//     // if(!userAuth.currentUser) new Error("No user ID defined");
//     if (!projectId) throw new Error("No project id received");

//     // Get project data object
//     const projectRef = await firestore.collection("project-data").doc(projectId).get();
//     let projectData = projectRef.data();
//     if (!projectData) throw new Error("No scenario found");

//     // Handle startDate casting
//     projectData.config.startDate = moment(projectData.config.startDate.seconds*1000);

//     // Return data to client
//     const returnObj = {
//       ...projectData
//     };
//     return (returnObj);

//   } catch (error) {
//     const eMsg = 'Error loading project: ' + error.message;
//     console.error(eMsg);
//     throw new Error(eMsg);
//   }
// };

export const saveProjectDocumentForCurrentUser = async (method, projectDataRaw, id, currentUser) => {
  try {
    // Copy and extract params
    const projectData = { ...projectDataRaw };

    // Get user company and assign this project to that company
    const userCompany = currentUser.company;
    if (!userCompany) throw new Error("No userCompany defined");
    const companyId = userCompany.id;

    // Check params before proceeding
    if (!companyId) throw new Error("No company id defined");
    if (!projectData) throw new Error("No project data received");
    if (method === 'create' && !id) throw new Error("No project id defined for an update");

    // Cast the date for Firestore
    let sDateRaw = projectData.config.startDate;
    if (sDateRaw instanceof moment) {
      sDateRaw = sDateRaw.toDate();
    }
    if (extractSecondsFromDate(sDateRaw) !== undefined) {
      sDateRaw = moment(extractSecondsFromDate(sDateRaw)).toDate();
    }
    const startDate = firebase.firestore.Timestamp.fromDate(sDateRaw);
    projectData.config.startDate = startDate;

    // Ensure a project name
    projectData.config.name = projectData.config.name || "New Scenario";
    projectData.name = projectData.config.name;

    // Add last modified datetime
    projectData.lastModified = firebase.firestore.Timestamp.fromDate(new Date());

    // Cleanup unused params
    delete projectData.status;

    // Split the project configs and data outputs
    let projConfig = { ...projectData };
    delete projConfig.data_annual; delete projConfig.data_monthly;

    // Add the document to config store and data store
    let projectId = id;
    if (method === "create") {
      const projectRef = await firestore.collection('project-configs').add(projConfig);
      delete projConfig.shareTokens; // Must not copy sharetokens from existing to new scenarios
      projectId = projectRef.id;
    } else if (method === "update") {
      await updateProjectConfig(projectId, projConfig)
    } else {
      throw new Error("Undefined save method ",method);
    }

    // Edit company document and proceed
    await firestore.collection("companies").doc(companyId).set({
      scenarios: firebase.firestore.FieldValue.arrayUnion(projectId)
    }, {merge: true});

    // Return data to client
    const returnObj = {
      ...projectData,
      id: projectId
    };
    return (returnObj);

  } catch (error) {
    const eMsg = 'Error saving project: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

export const addCompanyEvent = async (companyId, eventData) => {
  try {

    // Check params before proceeding
    // // if(!userAuth.currentUser) new Error("No user ID defined");
    if (!eventData) throw new Error("No event data received");
    if (!companyId) throw new Error("No company id defined");

    // Build company event update object
    const updateObj = {
      companyId,
      ...eventData
    }

    // Add event document
    const eventRef = await firestore.collection("company-events").add(updateObj);
    const eventId = eventRef.id;

    // Edit company document and proceed
    await firestore.collection("companies").doc(companyId).set({
      companyEvents: firebase.firestore.FieldValue.arrayUnion(eventId)
    }, { merge: true });

    // Return data to client
    const returnObj = {
      ...updateObj,
      id: eventId,
    };
    return (returnObj);
  } catch (error) {
    const eMsg = 'Error adding company event: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

async function uploadFileAsyncPromise(storageRef, file, metadata) {
  return new Promise(function(resolve, reject) {
    let uploadTask = storageRef.put(file, metadata);
    uploadTask.on('state_changed', snap => {
      const progress = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
      console.log('Upload is ' + progress + '% done');
    },
    err => {
      console.error(err);
      reject(err.toString());
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
        resolve(downloadUrl);
      });
    });
  });
}

export const uploadCompanyFile = async (companyId, fileData) => {
  try {

    // Extract file attributes
    const { fileCategory, uploadedBy, uploadedByName, uploadedOn, fileName, file } = fileData;

    // Check params before proceeding
    // if(!userAuth.currentUser) new Error("No user ID defined");
    if (!fileData) throw new Error("No file data received");
    if (!companyId) throw new Error("No company id defined");
    if (!fileCategory || !uploadedBy || !uploadedByName || !uploadedOn || !fileName || !file) throw new Error("Missing file attributes");

    // Define path based on user ID if none is given
    const fpath = `uploads/_companies/${companyId}/${fileName}`;

    // Set storage object
    const storageRef = storage.ref(fpath);

    // Create the file metadata
    const metadata = {
      contentType: file.type
    };

    // Upload file and metadata to the designated file path
    const downloadUrl = await uploadFileAsyncPromise(storageRef, file, metadata);

    // Construct file data to store in db
    let fileDataStored  = {
      companyId,
      ...fileData,
      downloadUrl,
      fileType: file.type
    }
    delete fileDataStored.file;

    // Add file data document
    const fileRef = await firestore.collection("file-uploads").add(fileDataStored);
    const fileId = fileRef.id;

    // Edit company document and proceed
    await firestore.collection("companies").doc(companyId).set({
      companyFiles: firebase.firestore.FieldValue.arrayUnion(fileId)
    }, { merge: true });

    // Return data to client
    const returnObj = {
      ...fileDataStored,
      fileId
    };
    return (returnObj);
  } catch (error) {
    const eMsg = 'Error uploading company file: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

export const getCompanyLogoFile = async (companyId) => {
  try {
    if (!companyId) throw new Error("No company id defined");
    const logoFileRefs = await firestore.collection("file-uploads")
        .where("companyId", "==" , companyId)
        .where("fileCategory", "==", 'companyLogo')
        .get();
    if(logoFileRefs.empty){
      return null;
    }
    return logoFileRefs.docs[0].data().downloadUrl;
  } catch (error) {
    console.error('The complete error is', error)
    const eMsg = 'Error getting company logo file: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

export const checkUserExists = async (email) => {
  try {

    // Check params before proceeding
    if(!email) new Error("No email defined");

    // Check to see if user exists
    const userArray = await firestore.collection("users").where("email", "==", email).get();
    if (userArray.empty) {
      return ({
        exists: false
      }) ;
    } else {
      return ({
        exists: true,
        id: userArray.docs[0].id
      }) ;
    }
  } catch (error) {
    const eMsg = 'Error checking if user exists: ' + error.message;
    console.error(eMsg);
  }
};

export const editCompanyDocument = async (companyId, companyData) => {
  try {

    // Check params before proceeding
    // if(!userAuth.currentUser) new Error("No user ID defined");
    if (!companyData) new Error("No company data or id defined");
    if (!companyId) new Error("No company id defined");

    // Add last updated value
    const newVal = { ...companyData, lastModified: firebase.firestore.Timestamp.fromDate(new Date()) };

    // Edit company document and get updated doc back
    await firestore.collection("companies").doc(companyId).set(newVal, { merge: true });
    const companyDoc = await firestore.collection("companies").doc(companyId).get();
    const updatedDoc = companyDoc.data();

    // Return data to client
    return ({ access: true, data: updatedDoc });
  } catch (error) {
    const eMsg = 'Error editing company document: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

export const addCompanyDocument = async (companyData) => {
  try {

    // Check params before proceeding
    // if(!userAuth.currentUser) new Error("No user ID defined");
    // if(!fileData) new Error("No file data defined");

    // For each user and admin, get the uids if they exist, else create users and get uids
    let { allInvites } = companyData;
    let admins = [];
    let members = [];
    let invites = [];

    // Check if user data exists, if so, add immediately to company doc, otherwise create invite doc
    await Promise.all(allInvites.map(async invite => {
      // First check for existing user data
      const userData = await checkUserExists(invite.email);
      if (userData.exists) {
        if (invite.role === "admin") admins.push(userData.id);
        if (invite.role === "admin" || "member") members.push(userData.id);
      } else {

        // User not found, keep track of pending invites
        invites.push({ ...invite });
      }
      return userData;
    }));

    // Create conforming company document
    let newCompanyData = {
      ...companyData,
      lastModified: firebase.firestore.Timestamp.fromDate(new Date()),
      name: companyData.name,
      members,
      admins,
      invites
    }

    // Add company document and get corresponding ID
    const companyDoc = await firestore.collection("companies").add(newCompanyData);
    const newCompanyId = companyDoc.id;

    // Create invite docs and add company IDs
    // await Promise.all(invites.map(async invite => {
    //   // Create user invite
    //   const inviteDoc = await firestore.collection("signup-invites").add({
    //     email: invite.email,
    //     companyId: newCompanyId,
    //     companyName: companyData.name,
    //     invitedBy: companyData.invitedBy,
    //     inviteDate: firebase.firestore.Timestamp.fromDate(new Date())
    //   });

    //   // TBD send invite email to users?
    //   return inviteDoc;
    // }));

    // Return data to client
    return ({ access: true, data: { ...newCompanyData, id: newCompanyId } });
  } catch (error) {
    const eMsg = 'Error creating company document: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

export const deleteCompanyDocument = async (companyId) => {
  try {

    // Check params before proceeding
    // if(!userAuth.currentUser) new Error("No user ID defined");
    // if(!fileData) new Error("No file data defined");

    // Add company document and get corresponding ID
    await firestore.collection("companies").doc(companyId).delete();

    // Return data to client
    return (companyId);
  } catch (error) {
    const eMsg = 'Error deleting company document: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if(!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        ...additionalData,
        displayName,
        email,
        createdAt,
      })
    } catch (error) {
      console.error('error creating user', error.message);
    }
  }

  return userRef;
};

export const updateUserAttributes = async (currentUser, newAttributes) => {
  if(!currentUser) return;
  const userId = currentUser.id || currentUser.uid;
  if(!userId) throw new Error("No defined user ID");
  const userRef = firestore.collection('users').doc(userId);
  const snapShot = await userRef.get();
  if (snapShot.exists) {
    try {
      await userRef.update({
        ...newAttributes,
        lastModified: new Date()
      })
    } catch (error) {
      console.error('Error updating user attributes', error.message);
      return error;
    }
  } else {
    throw new Error("User does not exist");
  }
  return userRef;
};

// export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
//   const collectionRef = firestore.collection(collectionKey);
//   console.log(collectionRef);

//   console.log("objectsToAdd: ",objectsToAdd);

//   const batch = firestore.batch();
//   objectsToAdd.forEach(obj => {
//     const newDocRef = collectionRef.doc(); // generates a new ID randomly
//     batch.set(newDocRef, obj)
//   });

//   return await batch.commit();
// };

// export const convertCollectionsSnapshotToMap = (collections) => {
//   const transformedCollection = collections.docs.map(doc => {
//     const { title, items } = doc.data();

//     return {
//       routeName: encodeURI(title.toLowerCase()),
//       id: doc.id,
//       title,
//       items
//     }
//   });

//   return transformedCollection.reduce((accumulator, collection) => {
//     accumulator[collection.title.toLowerCase()] = collection;
//     return accumulator;
//   }, {});
// };

