import {LineItemsStore} from "../../../ps-models/lineitems-store";
import {field} from "../../../ps-models/line-items";
import {lineItemAggregatorMap, normalizeMapKeys, normalizeString, STORE_VALUE_DECIMALS_KEY} from "../../../ps-models";


export const DEFAULT_TIME_AGGREGATIONS =  lineItemAggregatorMap({
  'Investment Returns IRR': "avg",
  // "Original Asset Value": "last",
  "original_asset_value__c": "last",
  // "Current Value": "last",
  "current_value_post_dep__c": "last",
  "Projected Current Value": "last",
  "Battery_kWh__c": "first",
  "kWp_Solar_System__c": "first",
  'Post_Code__c': 'first',
  'Billing_Type__c': 'first',
  'Current_Energy_Retailer__c': 'first',
  'Monthly_Depreciated_Value__c': 'first',
  'Total_Purchase_Price__c': 'first',
  // 'discounted_purchase_price__c': 'last',
  'Customer Buy out Price': 'last',
  'Drawn Balance': 'last',
  'Number of Sites': 'first',
}, "sum");


export const itemsNotToBeRoundedOff: string[] = [
  'Daily Fee',
  'Standard Daily Fee',
  'Solar Fee',
  'Solar Fee With Discount',
  'Standard Solar Fee',
  'Battery Fee',
  'Battery Fee With Discount',
  'Standard Battery Fee',
  'Solar_Bill_Rate_per_kWp__c',
  'Battery_Bill_Rate_per_kWh__c',
  'Optimisation_Control_Charge_Day__c',
  'Metering_and_Optimiser_Bill_Rate_per_Day__c',
  // 'Solar_kWp__c',
  'Solar_Bill_Rate_per_kWp__c',
  'Discount_Solar_Capacity_Charge_kWp__c',
  // 'Battery_kWh__c',
  'Battery_Bill_Rate_per_kWh__c',
  'Discount_Battery_Capacity_Charge_kWh__c',
  'Metering_Data_Charge_Day__c'
].map(normalizeString);

export function setLabelAndFormatForAggregates(store: LineItemsStore) {

  let labelsMap: Record<string, string> = {
    'grid_import_kwh': 'Grid Import',
    'generation_export_kwh': 'Generation Export',
    'all_consumption_kwh': 'Total Consumption',
    'generation_import_kwh': 'Generation Import',
    'grid_export_kwh': 'Grid Export',
    'siteunavailable': 'Site Unavailable',
    'batteryunavailable': 'Battery Unavailable',
    'solarunavailable': 'Solar Unavailable',
    'total_purchase_price__c': 'Original Purchase Price',
    'current_value_post_dep__c': 'Current Value',
    'original_asset_value__c': 'Original Asset Value',
    'months_active__c': 'Months Active',
    'monthly_depreciated_value__c': 'Monthly Depreciation',
    'billing_type__c': 'Billing Type',
    'kWp_Solar_System__c': 'kWp Solar System',
    'Battery_kWh__c': 'Battery kWh',
    'Current_Energy_Retailer__c': 'Current Energy Retailer',
    'State__c': 'State',
    'Post_Code__c': 'Postcode',
    'City__c': 'City',
    'Investment Returns IRR': 'IRR',
    'Customer Buy out Price (PLATFORM)': 'Customer Buy out Price *',
    'Net Cash Flow': 'Net Cash Flow *',
    'DSCR': 'DSCR *',
    'Drawn Balance': 'Funds Under Management',
    'Total_Early_Ownership_Discount__c': 'Total Early Ownership Discount',
    // 'discounted_purchase_price__c': 'Customer Buy Out Price',
    'Projected Current Value': 'Current Asset Value'
  }



  let formatMap: Record<string, string> = normalizeMapKeys({
    'Original_Asset_Value__c': 'dollars',
    'Original Asset Value': 'dollars',
    'Current_Value_Post_Dep__c': 'dollars',
  });



  for (let [key, value] of Object.entries(formatMap)) {

    store.getDataSet().getByField('store_sourceLineItemName', key).forEach(li => {
      store.getDataSet().addFieldToLineItem(li.name, field('store_valuetype', value))
    });

    store.getDataSet().addFieldToLineItem(
      key,
      field('store_valuetype', value)
    )
  }

  for (let key of itemsNotToBeRoundedOff) {
    store.getDataSet().getByField('store_sourceLineItemName', key).forEach(li => {
      store.getDataSet().addFieldToLineItem(li.name, field(STORE_VALUE_DECIMALS_KEY, "-1"))
    });

    store.getDataSet().addFieldToLineItem(
        key,
        field(STORE_VALUE_DECIMALS_KEY, "-1")
    )
  }

  for(let [key, value] of Object.entries(labelsMap)) {

    //Simplify salesforce field names
    value = value.replace(/__c/gi, '').replace(/_/gi, ' ');

    store.getDataSet().getByField('store_sourceLineItemName', key).forEach(li => {
      let site = store.getDataSet().getLineItem(li.name).fields.getFieldStr('store_sourceName');
      store.getDataSet().addFieldToLineItem(li.name, field('store_label', `${site} - ${value}`))

      store.getDataSet().addFieldToLineItem(
        li.name,
        field('store_sourceLabel', value)
      )
    });

    store.getDataSet().addFieldToLineItem(
      key,
      field('store_sourceLabel', value)
    )

    store.getDataSet().addFieldToLineItem(
      key,
      field('store_label', value)
    )
  }
}

export function addCustomLabelToItemsInStore(store: LineItemsStore, sourceName: string, targetName: string, parentFieldToAppend: string = 'store_sourceName'){
  const key = sourceName;
  let value = targetName;

  const lineItems = store.getDataSet().getByField('store_parent', key);

  for(let li of lineItems){
    let lender = store.getDataSet().getLineItem(li.name).fields.getFieldStr(parentFieldToAppend);

    store.getDataSet().addFieldToLineItem(li.name, field('store_label', `${lender} - ${value}`))

    store.getDataSet().addFieldToLineItem(
        li.name,
        field('store_sourceLabel', value)
    )
  }

  store.getDataSet().addFieldToLineItem(
      key,
      field('store_sourceLabel', value)
  )

  store.getDataSet().addFieldToLineItem(
      key,
      field('store_label', value)
  )
}
