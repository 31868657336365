import {useUpdateContext} from "../UpdateContext";
import {LineItemsStore} from "../ps-models/lineitems-store";
import {buildLineItemsStorePersistence} from "../lineitems-store/RestLineItemsStore.persistence";
import {AxiosError} from "axios";
import {StoreSetupType} from "./index";


let lineItemsPersistence = buildLineItemsStorePersistence();

export function useSiteStoreContext(){
  let store = useUpdateContext<LineItemsStore>().getService();
  return store;
}

export function loadSiteStore(storeId: string, collection: string, storeSetup: StoreSetupType, createNonExistentStore: boolean = true) {
  return lineItemsPersistence.loadLineItemsStore(collection, storeId, createNonExistentStore).then(
    (store) => {
      storeSetup(store);
      return store;
    }
  ).catch((err)=> {
      if(!createNonExistentStore && err instanceof AxiosError && err.response && err.response.status === 404 && err?.response?.data?.storeId === storeId){
          return null;
      } else {
          throw err;
      }
  })
}

export function saveSiteStore(collection: string, store: LineItemsStore) {
  return lineItemsPersistence.persistLineItemsStore(collection, store);
}