import React, {useState} from "react";
import {useCompanyId} from "../../../core";
import {PortfolioPerformance} from "./PortfolioPerformance";
import {SiteSelectWrapper} from "../../SiteSelectWrapper";
import {SiteDetail} from "./SiteDetail";
import {SitePerformance} from "./SitePerformance";
import {AEOvsWAMO} from "./AEOvsWAMO";
import {CashFlow} from "./CashFlow";
import {CAPexAndROI} from "./CAPexAndROI";
import {CustomerSaving} from "./CustomerSaving";
import {AggregateFinancials} from "./AggregateFinancials";
import {Dropdown, DropdownProps, Menu, Segment} from "semantic-ui-react";
import {Route, useHistory} from "react-router-dom";
import { CompanyLogo } from "./CompanyLogo";
import {CashFlowVariance} from "./CashFlowVariance";
import {LoanManagement} from "./LoanManagement";
import {MonthlyInvoicing} from "./MonthlyInvoicing";
import {MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING} from "../../index";

type Section = { title: string, key: string, path: string, render: () => JSX.Element, getPath: () => string, isHome?: boolean }

function useSections(): Record<string, Section> {
  const [selectedSite, setSelectedSite] = useState<string | undefined>(window.localStorage.getItem("selectedStore") || undefined);
  const companyId = useCompanyId();
  const handleSelectedSite = (site: string) => {
    setSelectedSite(site)
  }

  const BASE_PATH = `/ps/:companyId/assets-management/b`;

  const buildSection = (data: Omit<Section, "getPath">): Section => {
    return {...data, getPath: () => data.path.replace(":companyId", companyId)}
  }

  let sections = {
    "portfolio-performance": {
      ...buildSection({
        title: "Portfolio Performance",
        key: "portfolio-performance",
        path: BASE_PATH,
        render: () => <>
          <PortfolioPerformance goToSiteDetailTab={site => handleSelectedSite(site)}/>
        </>
      })
    },
    "site-detail": {
      ...buildSection({
        title: "Site Detail",
        key: "site-detail",
        path: `${BASE_PATH}/site-detail`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["WATTSYNC"]}>
            <SiteDetail/>
          </SiteSelectWrapper>
        </>
      })
    },
    "site-performance": {
      ...buildSection({
        title: "Site Performance",
        key: "site-performance",
        path: `${BASE_PATH}/site-performance`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["WATTSYNC"]}>
            <SitePerformance/>
          </SiteSelectWrapper>
        </>
      })
    },
    "aeo-vs-wamo": {
      ...buildSection({
        title: "AEO vs WAMO",
        key: "aeo-vs-wamo",
        path: `${BASE_PATH}/aeo-vs-wamo`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["WATTSYNC"]}>
            <AEOvsWAMO/>
          </SiteSelectWrapper>
        </>
      })
    },
    "cashflow-statement": {
      ...buildSection({
        title: "Cash Flow Statement",
        key: "cashflow-statement",
        path: `${BASE_PATH}/cashflow-statement`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["WATTSYNC"]}>
            <CashFlow/>
          </SiteSelectWrapper>
        </>
      })
    },
    "cashflow-variance": {
      ...buildSection({
        title: "Cash Flow Variance",
        key: "cashflow-variance",
        path: `${BASE_PATH}/cashflow-variance`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["WATTSYNC"]}>
            <CashFlowVariance />
          </SiteSelectWrapper>
        </>
      })
    },
    "monthly-invoicing": {
      ...buildSection({
        title: "Cash Flow Variance",
        key: "monthly-invoicing",
        path: `${BASE_PATH}/monthly-invoicing`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["WATTSYNC"]}>
            <MonthlyInvoicing />
          </SiteSelectWrapper>
        </>
      })
    },
    "site-capex-roi": {
      ...buildSection({
        title: "Site CAPEX & ROI",
        key: "site-capex-roi",
        path: `${BASE_PATH}/site-capex-roi`,
        render: () => <>
          
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["WATTSYNC"]}>
            <CAPexAndROI/>
          </SiteSelectWrapper>
        </>
      })
    },
    "customer-savings": {
      ...buildSection({
        title: "Customer Savings",
        key: "customer-savings",
        path: `${BASE_PATH}/customer-savings`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["WATTSYNC"]}>
            <CustomerSaving/>
          </SiteSelectWrapper>
        </>
      })
    },
    "aggregate-financials": {
      ...buildSection({
        title: "Aggregate Financials",
        key: "aggregate-financials",
        path: `${BASE_PATH}/aggregate-financials`,
        render: () => <>
          <AggregateFinancials/>
        </>
      })
    },
    "loan-management": {
      ...buildSection({
        title: "Loan Management",
        key: "loan-management",
        path: `${BASE_PATH}/loan-management`,
        render: () => <>
          <LoanManagement />
        </>
      })
    },
  };

  return sections;
}

function MainContent() {
  const sections = useSections();

  return <Segment>
    {Object.values(sections).map(section =>
      <Route exact  path={section.path}  key={section.key} render={section.render} />
    )}
  </Segment>
}

function MainMenu() {
  const sections = useSections();

  const goToSection = (section: string) => () => history.push(sections[section].getPath())

  const history = useHistory();
  return <Menu compact>
        <Menu.Item className={'nav-header-item'} onClick={goToSection('portfolio-performance')}>Portfolio Performance</Menu.Item>

        <Dropdown text={'Site Operations'} item simple>
          <Dropdown.Menu>
            <Dropdown.Item className={'nav-header-item'} onClick={goToSection("site-detail")}>Site Detail</Dropdown.Item>
            <Dropdown.Item className={'nav-header-item'} onClick={goToSection("site-performance")}>Site Performance</Dropdown.Item>
            <Dropdown.Item className={'nav-header-item'} onClick={goToSection("aeo-vs-wamo")}>AEO vs WAMO</Dropdown.Item>
            <Dropdown.Item className={'nav-header-item'} onClick={goToSection("customer-savings")}>Customer Savings</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown text='Site Financials' item simple>
          <Dropdown.Menu>
            <Dropdown.Item className={'nav-header-item'} onClick={goToSection("site-capex-roi")}>Site CAPEX & ROI</Dropdown.Item>
            <Dropdown.Item className={'nav-header-item'} onClick={goToSection("cashflow-statement")}>Cash Flow Statement</Dropdown.Item>
            <Dropdown.Item className={'nav-header-item'} onClick={goToSection("cashflow-variance")}>Cash Flow Variance</Dropdown.Item>
            <Dropdown.Item className={'nav-header-item'} onClick={goToSection("monthly-invoicing")}>Monthly Invoicing</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item className={'nav-header-item'} onClick={goToSection("aggregate-financials")}>Aggregate Financials</Menu.Item>
        <Menu.Item className={'nav-header-item'} onClick={goToSection("loan-management")}>Loan Management</Menu.Item>
      </Menu>
}
const Name = "WATTSYNC" as const;
export default  {
  MainContent, MainMenu, CompanyLogo, Name
}