import {useEffect, useRef} from "react";
import * as echarts from 'echarts/core';

// Import bar charts, all suffixed with Chart
import { BarChart, LineChart } from 'echarts/charts';

// Import the title, tooltip, rectangular coordinate system, dataset and transform components
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent, ToolboxComponent, LegendComponent, DataZoomComponent
} from 'echarts/components';

// Features like Universal Transition and Label Layout
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import type {
    BarSeriesOption,
    LineSeriesOption,
} from 'echarts/charts';
import type {
    // The component option types are defined with the ComponentOption suffix
    TitleComponentOption,
    TooltipComponentOption,
    GridComponentOption,
    DatasetComponentOption,
    ToolboxComponentOption,
    LegendComponentOption
} from 'echarts/components';
import type {
    ComposeOption,
} from 'echarts/core';
import * as React from "react";


export type EChartConfigurableOptions = ComposeOption<
    | BarSeriesOption
    | LineSeriesOption
    | TitleComponentOption
    | TooltipComponentOption
    | GridComponentOption
    | DatasetComponentOption
    | LegendComponentOption
>;

// Register the required components
echarts.use([
    BarChart,
    LineChart,
    TitleComponent,
    LegendComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    ToolboxComponent,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    DataZoomComponent
]);

export const useECharts = (options: EChartConfigurableOptions) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);
        const chartingOptionsWithToolbox: EChartConfigurableOptions & ComposeOption<ToolboxComponentOption> = {...options,
            useUTC: true,
            toolbox: {
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    restore: {},
                    saveAsImage: {
                        title: 'Download PNG',
                    },
                    mySVG: {
                        show: true,
                        title: 'Download JPG',
                        icon: 'path://M1024 320v640H0V320h1024zm-64 576V384H64v512h896zM832 512H192v64h640v-64zm-64 128H192v64h576v-64zM192 768h448v64H192v-64z',
                        onclick: () => downloadJPG(chartInstance),
                    },
                }
            }}
        chartInstance.setOption(chartingOptionsWithToolbox);

        const handleResize = () => {
            chartInstance.resize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            chartInstance.dispose();
        };
    }, [options]);

    return chartRef;
};

const downloadJPG = (chartInstance: echarts.ECharts) => {
    const img = chartInstance.getDataURL({
        type: 'jpeg',
        pixelRatio: 2,
        backgroundColor: '#fff',
        excludeComponents: ['toolbox']
    });
    const link = document.createElement('a');
    link.href = img;
    link.download = 'chart.jpeg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}