// NOTE: Adapted from atomscale/frontend/src/utils/utilfuntions.js
// Did the typescript conversion super fast, you'll want to check these
// Should this be made into a shared node module?

// Convert interest rates from one period to another (e.g. annual to monthly)
export function convertRate(r: number, toType: string, fromType: string) {
  if (isNaN(r)) {
    return r
  }
  const toFrom = `${fromType}-${toType}`;
  const daysPerYear = 360;
  const daysPerMonth = 30;
  switch (toFrom) {
    case "year-month":
      return Math.pow(1 + r, daysPerMonth / daysPerYear) - 1;
    case "month-year":
      return Math.pow(1 + r, daysPerYear / daysPerMonth) - 1;
    default:
      return new Error(`Unknown conversion formula: ${toFrom}`);
  }
}

export function PMT(ir: number, np: number, pv: number, fv: number, type: number) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
}

export function IRR(cashFlows: number[], guess: number = 0.01, maxIterations: number = 1000, tolerance: number = 1e-6): number {
  cashFlows = cashFlows.filter(v => !Number.isNaN(v))
  let currentGuess: number = guess;
  let iteration: number = 0;

  while (iteration < maxIterations) {
    const currentNpv: number = NPV(currentGuess, cashFlows);
    const npvDerivative: number = cashFlows.reduce((acc: number, cashFlow: number, time: number) =>
        acc - (time * cashFlow) / Math.pow(1 + currentGuess, time + 1)
    , 0);

    if (Math.abs(npvDerivative) < 1e-9) {
      return NaN;
    }

    const newGuess: number = currentGuess - currentNpv / npvDerivative;
    const currentError: number = Math.abs(newGuess - currentGuess);

    if (currentError < tolerance) {
      return newGuess;
    }

    currentGuess = newGuess;
    iteration++;
  }

  return NaN;
}

export function IRR2(cashFlows: number[]) {
  try {
    let min = 0.0;
    let max = 1.0;
    let guest = 0.0;
    let npv = 0
    do {
      guest = (min + max) / 2;
      npv = 0;
      for (var j=0; j<cashFlows.length; j++) {
            npv += cashFlows[j]/Math.pow((1+guest),j);
      }
      if (npv > 0) {
        min = guest;
      }
      else {
        max = guest;
      }
    } while(Math.abs(npv) > 0.000001);
    return guest * 100;
  } catch (e) {
    console.error(e);
  }
}

// Modified from here: https://gist.github.com/ghalimi/4597900
export function NPV(rate: number, values: number[], discount0 = true) {
  values = values.filter(v => !Number.isNaN(v))
  // Initialize net present value
  var value = 0;

  // If not discount0, skip first period from discounting
  let startIndex = 0;
  if (!discount0) startIndex = 1;
  if (values.length === 1 && !discount0) return values[0]; // Catch single var case

  // Loop on all values
  for (var j = startIndex; j < values.length; j++) {
    value += values[j] / Math.pow(1 + rate, j + 1);
  }

  // Add back 0 period if needed
  if (!discount0) value += values[0];

  // Return net present value
  return value;
}

// Sort array of object based on lastModified property
export const lastModifiedCompare = (a: any, b: any) => {
  if (!a.lastModified) return 1;
  if (!b.lastModified) return -1;
  const a_lastModified = a.lastModified._seconds || a.lastModified.seconds; // TBD - Temporary bug workaround for date issue on update
  const b_lastModified = b.lastModified._seconds || b.lastModified.seconds; // TBD - Temporary bug workaround for date issue on update
  if (a_lastModified > b_lastModified) {
    return -1;
  }
  if (a_lastModified < b_lastModified) {
    return 1;
  }
  return 0;
};

export function nDol(num: any, decimals = 0, dolSign = true, showZero = false) {
  if (num === 0 && !showZero) return "-";
  let original_sign = Math.sign(num);
  if (original_sign === 0) original_sign = 1;
  let ret = Math.abs(Math.round(num * 10 ** decimals) / 10 ** decimals);
  let suffix = "";
  if ((ret >= 999999) && (ret < 1000000)) {
    ret = ret / 1000;
    suffix = "K";
  } else if ((ret >= 1000000) && (ret < 1000000000)) {
    ret = ret / 1000000;
    suffix = "M";
  } else if ((ret >= 1000000000) && (ret < 1000000000000)) {
    ret = ret / 1000000000;
    suffix = "B";
  } else if (ret >= 1000000000000) {
    ret = ret / 1000000000000;
    suffix = "T";
  }
  // if(ret % 1 !== 0) Number(ret.toFixed(Math.max(decimals) || 0));
  let retS = ret.toLocaleString("en-US");
  retS = dolSign ? "$" + retS : retS;
  retS = retS + suffix;
  if (original_sign === -1) retS = "-" + retS;
  if (!isFinite(num))
    return `${original_sign === -1 ? "-" : ""}${dolSign ? "$" : ""}∞`;
  return retS;
}

export function percChange(n1: number, n2: number) {
  return Math.round((100 * (n2 - n1)) / n1);
}

export function accum(n: number[]) {
  return n.reduce(add, 0);
}

export function add(accumulator: any, a: any): number {
  // EXAMPLE :  const sum = [1, 2, 3].reduce(add, 0); // with initial value to avoid when the array is empty
  return accumulator + a;
}

export function average(nums: number[]) {
  return nums.reduce((a: any, b: any) => a + b) / nums.length;
}

export function PV(rate: number, nper: number, pmt: number) { // Present value calculation
  return pmt / rate * (1 - Math.pow(1 + rate, -nper));
}

export function erfInv(x: number): number {
  const a1 =  0.254829592;
  const a2 = -0.284496736;
  const a3 =  1.421413741;
  const a4 = -1.453152027;
  const a5 =  1.061405429;
  const p  =  0.3275911;

  const sign = (x < 0) ? -1 : 1;
  x = Math.abs(x);
  const t = 1.0 / (1.0 + p * x);
  const y = (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t;

  return sign * Math.sqrt(-Math.log(1-x*x)) * y;
}


export function norminv(probability: number, mean: number, standardDeviation: number): number {
  if (probability <= 0 || probability >= 1) {
    throw new Error("The probability must be between 0 and 1 (exclusive).");
  }

  return mean + standardDeviation * Math.sqrt(2) * erfInv(2*probability - 1);
}