import React, {useEffect, useState} from "react";
import {buildLineItemsStorePersistence} from "./RestLineItemsStore.persistence";
import {LineItemsStore, PersistenceQuery, pQuery} from "../ps-models/lineitems-store";
import {INIT_ACTION, REFETCH_ACTION, useUpdateContext} from "../UpdateContext";

let lineItemsPersistence = buildLineItemsStorePersistence();

export function useLineItemsStore(storeId: string, collection: string): LineItemsStore | undefined {

  let [store, setStore] = React.useState<LineItemsStore | undefined>();

  useEffect(() => {
    lineItemsPersistence.loadLineItemsStore(collection, storeId).then(
      (store) => {
        setStore(store);
      }
    )
  }, [storeId, collection]);

  return store;
}

export function usePSQuery(
  collection: string, query: PersistenceQuery,
  onLoad: (store: LineItemsStore)=> void | LineItemsStore  = ()=>{}
): LineItemsStore | undefined {
  let persistence = buildLineItemsStorePersistence();
  let [aggregatedStore, setAggregatedStore] = useState<LineItemsStore | undefined>();
  let strQuery = JSON.stringify(query);

  //Call Cash Flow Store
  useEffect(() => {

      persistence.query(
        collection, query
      ).then((store) => {
        let res = onLoad(store);
        if (res) {
          store = res;
        }
        setAggregatedStore(store);

      });

  }, [strQuery]);

  return aggregatedStore;
}


/**
 * @Deprecated use usePSQuery instead to avoid unexpected context updates
 */
export function usePersistenceStoreQueryWithContextUpdate(
  collection: string, query: PersistenceQuery,
  onLoad: (store: LineItemsStore)=> void | LineItemsStore  = ()=>{}
): LineItemsStore | undefined {
  let persistence = buildLineItemsStorePersistence();
  let [aggregatedStore, setAggregatedStore] = useState<LineItemsStore | undefined>();
  let ctx = useUpdateContext();
  let strQuery = JSON.stringify(query);

  //Call Cash Flow Store
  useEffect(() => {

    console.info("Updating context");
    if(ctx.action.type === INIT_ACTION || ctx.action.type === REFETCH_ACTION) {
      ctx.loading();
      persistence.query(
        collection, query
      ).then((store) => {
        let res = onLoad(store);
        if (res) {
          store = res;
        }
        setAggregatedStore(store);
        ctx.setService(store);
        ctx.ready();
      });

    }
  }, [ctx.actionChanged, strQuery]);

  return aggregatedStore;
}



export function useLineItemsStoreMetadata(collection: string, query: PersistenceQuery = pQuery().metadataOnly()
  .selectSourceParams(['name'])
  .withQueryName("Metadata Default Query")){

  const [result, setResult] = React.useState<LineItemsStore | undefined>();
  const strQuery = JSON.stringify(query);
  //Call Cash Flow Store
  useEffect(() => {
    buildLineItemsStorePersistence()
      .query(collection, query)
      .then((store) => setResult(store));
  }, [collection, strQuery]);

  return result;
}