import {Container} from "semantic-ui-react";
import {Configurator} from "./Configurator";
import {ConfigType} from "../ps-types";
import {RJSFSchema} from "@rjsf/utils";

export function SalesforceConfig() {

  return <Container>
    <h1>Salesforce Config</h1>
    <Configurator configType={ConfigType.Salesforce} schema={salesforceSchema}/>
  </Container>
}

const salesforceSchema: RJSFSchema = {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "properties": {
    "configKey": {
      "type": "string"
    },
    "configName": {
      "type": "string"
    },
    "salesforceApi": {
      "type": "string"
    },
    "salesforceReportId": {
      "type": "string",
    },
    "projectIdColumn": {
      "type": "string",
      title: "Project ID Column",
      description: "This is the column with the project ID in the Salesforce report"
    },
    "versionDataProjectId": {
      title: "Version Data Project ID",
      description: "This is the versionData field in the PS project that contains the project ID",
      "type": "string"
    },
    "mappingConfig": {
      "type": "object",
      "additionalProperties": {
        "type": "object",
        "properties": {
          "valueKey": {
            "type": "string"
          },
          "valueColumn": {
            "type": "string"
          },
          "useValueText": {
            "type": "boolean"
          }
        },
        "required": ["valueKey", "valueColumn"]
      }
    }
  },
  "required": ["configKey", "configName", "salesforceApi", "mappingConfig", "projectIdColumn"]
};