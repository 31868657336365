import {LineItemsStorePersistence} from "./LineItemsStore.persistence";
import {TimeIndex} from "../Time.model";
import {LineItemsStore, LineItemsStoreOptions} from "./LineItemsStore";
import {PersistenceQuery} from "./PersistenceQuery";
import {ValueType} from "../line-items";


export class StoreAccessDeniedError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export function buildFieldAccessPersistence(persistence: LineItemsStorePersistence, matchingFields: string[], accessObject: FieldAccessDefinition) {
  return new FieldAccessPersistenceDecorator(persistence, matchingFields, accessObject);
}

export type FieldAccessDefinition = Record<string, ValueType[]>

export class FieldAccessPersistenceDecorator implements LineItemsStorePersistence {
  constructor(private persistence: LineItemsStorePersistence, private readonly matchingFields: string[], private accessObject: FieldAccessDefinition) {}

  createLineItemsStore(timeIndex: TimeIndex, options: LineItemsStoreOptions) {
    return this.persistence.createLineItemsStore(timeIndex, options);
  }

  async loadLineItemsStore(collection: string, id: string, createNonExistentStore = true) {
    let store = await this.persistence.loadLineItemsStore(collection, id, createNonExistentStore);

    this.accessCheck(store);

    return store;
  }

  private accessCheck(store: LineItemsStore) {

    for(let field of this.matchingFields) {
      let value = store.getParam(field);
      if(value) {

        if(this.accessObject[field].includes("*")) {
          continue;
        }

        if(!this.accessObject[field].includes(value)) {
          throw new StoreAccessDeniedError(`Access denied to store ${store.getId()}  because ${field}=${value} is not in ${JSON.stringify(this.accessObject[field])}`);
        }
      }
    }
  }

  persistLineItemsStore(collection: string, store: LineItemsStore) {
    this.accessCheck(store);
    return this.persistence.persistLineItemsStore(collection, store);
  }

  query(collection: string, query: PersistenceQuery, defaultTimeIndex?: TimeIndex) {
    let fieldsMap: Record<string, ValueType | ValueType[]> = {};
    for(let field of this.matchingFields) {
      if(this.accessObject[field].includes("*")) {
        continue;
      }
      fieldsMap[field] = this.accessObject[field];
    }
    query._havingParamValues = {...query._havingParamValues,...fieldsMap};
    return this.persistence.query(collection, query, defaultTimeIndex);
  }

  async deleteStore(collection: string, id: string): Promise<void> {
    let store = this.loadLineItemsStore(collection, id);
    await this.persistence.deleteStore(collection, id)
  }

}