import {RJSFSchema} from "@rjsf/utils";
import {ConfigType} from "../ps-types";
import {Container} from "semantic-ui-react";
import {Configurator} from "./Configurator";


export function WhatIfScenariosConfig() {
  return <Container>
    <h1>What If Scenarios Config</h1>
    <Configurator configType={ConfigType.WhatIfScenario}
                  schema={WhatIfScenarioJSONSchema}
    />
  </Container>
}


export const WhatIfScenarioJSONSchema: RJSFSchema = {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "definitions": {
    "MappingConfigEntry": {
      "type": "object",
      title: "",
      "properties": {
        "valueKey": {
          title: "Value Key",
          type: "string"
        },
        "label": {
          title: "Label",
          type: "string"
        },
        "locator": {
          type: "object",
          title: "Excel Locator",
          "properties": {
            "label": {
              title: "Excel Label",
              "type": "string"
            },
            "type": {
              title: "Type",
              "type": "string"
            },
            "direction": {
              title: "Direction (left, right, down)",
              "type": "string"
            },
            "skipNValues": {
              title: "Skip N Values",
              "type": "number"
            },
            "workSheet": {
              title: "WorkSheet",
              "type": "string"
            }
          },
          "required": ["type", "label", "workSheet"]
        },
      },
      required: ["valueKey", "locator", "label"]
    },
  },

  "properties": {
    "configName": {
      "type": "string"
    },
    "configKey": {
      "type": "string"
    },
    "mappingConfig": {
      "type": "object",
      title: "Mapping Config",
      "additionalProperties": {
        title: "Config",
        "$ref": "#/definitions/MappingConfigEntry"
      }
    }
  },
  "required": ["configName", "configKey", "locatorMap"]
}