import {useDashboardService} from "../DashboardConfigService";
import React from "react";
import {BuilderContext} from "../WidgetRegistry";
import {useOnStoreReady} from "../widgets/commons";

export function WidgetContextProvider(
    {render}: {render:((context: BuilderContext)=>React.ReactNode)}
) {
    let {getService} = useDashboardService();
    let dashboardService = getService();
    let context = {appContext: dashboardService};
    return <>{render(dashboardService.buildDashboardContext(context))}</>
}

export function WidgetConfigContextProvider(
    {render}: {render:((context: BuilderContext)=>React.ReactNode)}
) {

    return <WidgetContextProvider render={(context) => {
        return <WidgetConfigRenderer render={render} context={context} />
    }
    }/>
}

function WidgetConfigRenderer({context, render}: {context: BuilderContext, render:((context: BuilderContext)=>React.ReactNode)}){
    useOnStoreReady(context);
    return <>{render({...context, store: context.appContext.getStore(), query: context.appContext.getConfig().getQuery()})}</>
}
