import React, {createContext, useState} from "react";
import {assoc, dissoc} from "ramda";
import { Message } from "semantic-ui-react";
import {entries} from "../ps-models";


interface Message {
    level: "error" | "warning" | "info",
    text: string
}

interface MessageContextProps {
    messages: Record<string, Message>,
    info: (message: string, name?:string) => void,
    error: (message: string, name?: string) => void,
    warning: (message: string, name?: string) => void,
    clear: (name?: string) => void
}

const MessageContext = createContext<MessageContextProps>({
    messages: {},
    info: (message: string) => {},
    error: (message: string) => {},
    warning: (message: string) => {},
    clear: () => {}
})

export function MessageProvider({children}: {children: React.ReactNode | React.ReactNode[] | string}) {
    const [messages, setMessages] = useState<Record<string, Message>>({});

    const addMessage = ({level, text}: Message, name?: string) => {
        let messageKey = name || `message-${Math.random().toString(36).substring(7)}`;
        setMessages({...messages, [messageKey]:{level, text}});
    }
    const info = (message: string, name?: string) => {
        addMessage({level: "info", text: message}, name)
    }

    const error = (message: string, name?: string) => {
       addMessage({level: "error", text: message}, name)
    }

    const warning = (message: string, name?: string) => {
       addMessage({level: "warning", text: message}, name)
    }

    const clear = (name?: string) => {
        if (name) {
            setMessages(dissoc(name, messages))
        } else {
            setMessages({})
        }
    }



    return <MessageContext.Provider value={{messages, info, error, warning, clear}}>
        {children}
    </MessageContext.Provider>
}

export function useMessages() {
    return React.useContext(MessageContext);
}

export function MessageBlock() {
    const {messages, clear} = useMessages();

    const handleDismiss = (name: string) => {
        clear(name);
    }

    return <div style={{marginBottom: "15px", marginTop: "15px"}}>
        {entries(messages).map(([k, msg]) => <Message
          warning={msg.level === 'warning'}
          error={msg.level === 'error'}
          info={msg.level === 'info'}
          onDismiss={()=>handleDismiss(k)}
        >{msg.text}</Message>)}
    </div>
}