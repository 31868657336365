import React, {useEffect, useMemo, useRef, useState} from "react"
import {Button, Checkbox, Container, Dropdown, Grid, Header, Input, Popup} from "semantic-ui-react"
import './MultiSelect.css';
export type MultiSelectOption = {
    key: string | number
    text: string
    value: string | number
    isHeader?: boolean
    group?: string
}


export interface MultiSelectProps {
    options: MultiSelectOption[]
    onChanged?: (selectedOptions: MultiSelectOption[]) => void
    placeholderText?: string
    initialSelection?: (MultiSelectOption)[],
    hideSelectAll?: boolean,
    showSelectAllBtn?:boolean,
    showUnSelectAllBtn?:boolean,
    hideSelectByGroup?:boolean,
    onApply?: (selectedOptions: MultiSelectOption[]) => void;
    disableApplyOnNoSelection?: boolean;
    searchable?:boolean,
    direction?: "left" | "right"
}

//TODO: the Initial selection should be values, not the entire Options object (??)
export function MultiSelect({options, onApply, onChanged, placeholderText, initialSelection, hideSelectAll, hideSelectByGroup, disableApplyOnNoSelection
                                ,showSelectAllBtn,
                                showUnSelectAllBtn,
                                searchable,
  direction

}: MultiSelectProps) {
    const [selectedOptions, setSelectedOptions] = useState<MultiSelectOption[]>(initialSelection ?? [])
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const dropdownRef = useRef<any>(null)
    const sortedOptions = options;
    //const sortedOptions = options.sort((a, b) => a.text.localeCompare(b.text))
    const groupValuesMap = options.reduce<{[group: string]: Array<string | number>}>((acc, item) => {
        if (item.group) {
            if (!acc[item.group]) {
                acc[item.group] = [];
            }
            acc[item.group].push(item.value);
        }
        return acc;
    }, {});
    const allOptionValues = options.map(opt => opt.value)
    const selectedValues = selectedOptions.map(opt => opt.value)
    const filteredOptions = sortedOptions.filter(option =>
        option.text.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        if(initialSelection)
            setSelectedOptions(initialSelection)
    }, [initialSelection]);

    if(!placeholderText) {
        placeholderText = multiSitesSelectText(selectedValues.length, options.filter(o => !o.isHeader).length)
    }

    function selectValues(values: MultiSelectOption[]) {
        setSelectedOptions(values)
        onChanged?.(values)
    }

    const isAllSelected = () => allOptionValues.every(optVal => selectedValues.includes(optVal))
    const isGroupSelected = (groupName: string) => groupValuesMap?.[groupName].every((val)=> selectedValues.includes(val))
    const handleSelectAll = () => selectValues(isAllSelected() ? [] : options)
    const handleUnSelectAll = () => selectValues([]);
    const handleSelectAllBtnClck = () => selectValues(options)
    const handleGroupSelection = (groupName: string) => selectValues(isGroupSelected(groupName) ? selectedOptions.filter((opt)=>opt?.group !== groupName) : [...selectedOptions, ...options.filter((opt)=>opt?.group === groupName)]);

    const isApplyButtonDisabled = useMemo(() => {
        return disableApplyOnNoSelection &&  selectedOptions.length ===0
    }, [disableApplyOnNoSelection, selectedOptions])

    function handleItemClick(option: MultiSelectOption) {
        if (selectedValues.includes(option.value)) {
            selectValues(selectedOptions.filter(opt => opt.value !== option.value))
        } else {
            selectValues([...selectedOptions, option])
        }
    }
    function closeDropdownMenu(){
        setSearchQuery('')
        setDropdownOpen(false)
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.ref.current.contains(event.target as Node)) {
                closeDropdownMenu()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [dropdownRef])

    return <Dropdown
        ref={dropdownRef}
        className={'multi-select'}
        text={placeholderText ?? 'Select Options'}
        multiple
        open={dropdownOpen}
        onOpen={() => setDropdownOpen(true)}

        style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '2px',
            marginRight: '5px',
            marginLeft: '5px',
            cursor: 'pointer',
        }}
    >
        <Dropdown.Menu direction={direction}  >
            {searchable && <Input
                icon='search'
                placeholder='Search...'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ margin: '5px' }}
            />}
            <div style={{maxHeight: '400px', width:  "300px", overflowY: 'auto'}}>
            {!hideSelectAll && <Dropdown.Item onClick={handleSelectAll}>
                <Checkbox label="Select All" checked={isAllSelected()}/>
            </Dropdown.Item>}
            {!hideSelectByGroup && Object.keys(groupValuesMap).map((groupName)=><Dropdown.Item onClick={()=>handleGroupSelection(groupName)}>
                <Checkbox label={`Select ${groupName}`} checked={isGroupSelected(groupName)}/>
            </Dropdown.Item>)}
            <Dropdown.Divider/>
                <div style={{maxWidth: "600px", padding: "5px"}}>
                    <Grid stackable columns={3}>
                {filteredOptions.map(option => {

                    if(option.isHeader) return (<Grid.Column width={12}>
                        <Header  >{option.text}</Header>
                    </Grid.Column>)

                  return <Grid.Column><Dropdown.Item key={option.key} onClick={() => handleItemClick(option)} >
                      <span     className="multi-select-checkbox">
                      <Checkbox

                        label={option.text} checked={selectedValues.includes(option.value)}/>
                          </span >
                  </Dropdown.Item></Grid.Column>
                })}
                    </Grid>

                </div>
</div>
            {showSelectAllBtn && <div >
                <Button color="instagram" floated="left" style={{margin: "5px"}} size="tiny" disabled={isAllSelected()} onClick={handleSelectAllBtnClck}
                >Select All</Button>
            </div>}
            {showUnSelectAllBtn && <div >
                <Button color="instagram" floated="left" style={{margin: "5px"}} size="tiny" disabled={selectedValues.length===0} onClick={handleUnSelectAll}
                >UnSelect All</Button>
            </div>}
            {onApply && <div >
                <Popup
                    content={"Please make at least one selection"}
                    disabled={!isApplyButtonDisabled}
                    trigger={<div style={{ display: 'inline-block', float:"right", ...(isApplyButtonDisabled ? {cursor: 'not-allowed'}:{}) }}>
                        {/* @ts-ignore */}
                        <Button color="black" floated="right" style={{margin: "5px"}} size="tiny" onClick={() => {
                            onApply(selectedOptions)
                            closeDropdownMenu();
                        }}
                        {...(isApplyButtonDisabled ? {disabled: isApplyButtonDisabled, color: "grey"}: {})}
                        >Apply</Button>
                    </div>}
                />
            </div>}
        </Dropdown.Menu>
    </Dropdown>
}

function multiSitesSelectText(selectedValuesLength: number, sitesLength: number): string {
    return `${selectedValuesLength} selected`
}