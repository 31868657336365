import {
  EntityTable,
  buildEntityTableDef,
  ColumnDef,
  TableDef
} from "./EntityTable.component";
import * as React from "react";
import {PlatformLayout, PlatformLayoutParams} from "../../PlatformLayout";
import {map, mapObjIndexed} from "ramda";
import {useState} from "react";
import {Header} from "semantic-ui-react";
import {buildForm} from "./common/Field";
import {EntityDef, FieldDef} from "./types";
import {CreateEntity} from "./CreateEntity.component";

export interface EntityPropDef {
  key: string,
  title: string,
  create?: Partial<FieldDef>,
  update?: Partial<FieldDef>,
  table?: Partial<ColumnDef>
}

export function EntityCrud({entityDef, layout}: {
  title?: string,
  entityDef: EntityDef
  layout?: PlatformLayoutParams}) {

  let formDef = entityDef.createEntity && toFormDef(entityDef);
  let tableDef =  toTableDef(entityDef);

  let [newest, setNewest] = useState<any | undefined>();

  const onEntityCreated = (entity: any) => {
    setNewest(entity);
  }

  return <div>
    <Header as="h2" floated="left">{entityDef.title}</Header>
    <div style={{float: "right"}}>
      {formDef &&
    <CreateEntity {...formDef}
                  onEntityCreated={onEntityCreated}
    />}
    </div>
    <div style={{clear:'both'}}>
    <EntityTable
      tableDef={tableDef}
      newest={newest}
      entityDef={entityDef}
    />
    </div>
  </div>



}



export function EntityCrudWithLayout({entityDef, layout}: {
  entityDef: EntityDef
  layout?: Partial<PlatformLayoutParams>}) {

  let formDef = toFormDef(entityDef);
  let tableDef =  toTableDef(entityDef);

  let [newest, setNewest] = useState<any | undefined>();

  const onEntityCreated = (entity: any) => {
    console.info("Created", entity)
    setNewest(entity);
  }

  let layoutParams:Omit<PlatformLayoutParams, "children"> = {
    header: entityDef.title,
    ...(layout || {})}

  return <PlatformLayout
    {...layoutParams}
    actions={
      <CreateEntity {...formDef}
        onEntityCreated={onEntityCreated}
      />
    }
  >
     <EntityTable
       tableDef={tableDef}
       newest={newest}
       entityDef={entityDef}
     />

   </PlatformLayout>
}


export function buildEntityDef(def: Omit<EntityDef, "props">, props: Record<string, Partial<EntityPropDef>>): EntityDef {

  let propsCompleted: { [key: string]: EntityPropDef }  = mapObjIndexed((v, k) => ({
    key: k,
    title: v.title || k,
    ...v
  }), props);

  return {
    ...def,
    props: propsCompleted
  }

}

function toFormDef(def: EntityDef) {
  return buildForm(
    // @ts-ignore
    { ...def, ...def.create},
    map((p) => ({
      key: p.key,
      label: p.title,
      type: "text",
      ...p.create
    } as FieldDef), def.props)
  );
}

function toTableDef(entityDef: EntityDef) {
  return buildEntityTableDef(
    { ...entityDef, ...entityDef.table }
    , map((p) => ({
      key: p.key,
      title: p.title,
      type: "text",
      ...p.table
    } as ColumnDef), entityDef.props)
  );
}