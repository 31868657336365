import React from "react";
import {LoadingBlock} from "../ui/Loading";
import {LineItemsStore, QueryResult, ResultOptions, StoreQuery} from "../ps-models/lineitems-store";
import {LineItemsTable} from "../lineitems-store/LineItemsTableView";
import {
  buildParameterLineItem, field,
  LineItem,
  LineItemsFieldSet,
  PARAMETER_LINE_ITEM_TYPE,
  ParameterLineItem
} from "../ps-models/line-items";
import {FileUploadButton} from "../ui/FileUploadButton";
import {useMessages} from "../ui/MessagesProvider";
import {extractLineItemsFromExcel} from "./boards/demo/assetsManagement.client";
import {DateRangeType} from "../ps-types";


export function UploadLineItems({store, lineItemNames, onLineItemsUploaded, resultantTableOptions, onQueryResultComputed, aggregationRange}: {
  store: LineItemsStore,
  lineItemNames: string[],
  onLineItemsUploaded: (uploadedLineItems: LineItem[])=>void,
  resultantTableOptions?: ResultOptions,
  onQueryResultComputed?: (query: StoreQuery, result: QueryResult)=> void,
  aggregationRange?: DateRangeType,
}) {
  let {clear, error} = useMessages();
  if (!store) {
    return <LoadingBlock/>
  }
  let from = aggregationRange?.from ?? store.timeIndex.startDate;
  let to = aggregationRange?.to ?? store.timeIndex.endDate;
  let query = StoreQuery.byNames(lineItemNames, true).aggregateOverTimeRange(from, to); //filter by line items
  let result = store.query(query, resultantTableOptions);

  onQueryResultComputed?.(query, result);
  const handleLineItemsExcelUpload = async (file: File) => {
    // @TODO
    // useCancellable promise...
    clear();
    try {
      let uploadedLineItems: LineItem[] = [];
      if(lineItemNames.length>0){
        let {extractedSiteWiseLineItems, errors} = await extractLineItemsFromExcel(file, lineItemNames.map((liName)=>({name: liName, type: "parameter"})));
        for(let entry of extractedSiteWiseLineItems){
          let siteId = Object.keys(entry)[0];
          let lineItemsSerialized = entry[siteId];
          lineItemsSerialized.forEach((li)=>{
            if(li.type === PARAMETER_LINE_ITEM_TYPE){
              uploadedLineItems.push(buildParameterLineItem(`${siteId} - ${li.name}`, li.value ?? 0, LineItemsFieldSet.fromFieldMap(li.fields)
                  .addField('store_label', `${siteId} - ${li.fields['store_label']?.value || li.name}`)
                  .addField('store_sourceLineItemName', li.name)
                  .addField('store_sourceName', siteId)
                  .addField('store_sourceId', siteId)
                  .addField('store_sourceLabel', li.name)
                  .addField('store_parent', li.name)
                  .addField('cname', li.name)
                  .addField('$type', 'ParameterLineItem')
              ));
            }
          })
        }
        onLineItemsUploaded(uploadedLineItems);
      }
    } catch(err: any){
      error(err?.message);
    }
  }

  return <div
      style={{display: 'flex', flexDirection: 'column', gap: '8px',
        justifyContent: 'space-around'
  }}
  >
    <div
        style={{
          display: "flex",
          alignItems: 'center',
          justifyContent: 'space-between'
    }}
    >
      <div><strong>Uploadable line items via excel are: {lineItemNames.join(", ")}</strong></div>
      <FileUploadButton onFileSelect={(file)=>{
        handleLineItemsExcelUpload(file);
      }} />
    </div>

    <LineItemsTable
        queryResult={result}
    />
  </div>

}