import {useLineItemsStoreMetadata, usePersistenceStoreQueryWithContextUpdate} from "../../../lineitems-store/LineItemsStore.hook";
import {
  CANONICAL_NAME_FIELD,
  DEFAULT_SOURCE_GRID,
  LineItemsStore,
  pQuery,
  QueryResult,
  StoreQuery
} from "../../../ps-models/lineitems-store";
import {Container, Grid, Header, Icon, Segment} from "semantic-ui-react";
import React from "react";

import {setLabelAndFormatForAggregates} from "./storeFormulas";

import {LoadingBlock} from "../../../ui/Loading";
import {LineItemsTable, LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {utcDate} from "../../../ps-models";
import {TimeSeriesChart} from "../../../lineitems-store/TimeSeriesChart";
import {LineItemsStoreDebug} from "./LineItemsStoreDebug";
import {buildTimedCalculatedLineItem, buildYearlyTimeDef} from "../../../ps-models/line-items";
import {authStorage} from "../../../auth";
import {getAmProjectConfig} from "../../../ps-models";

export function AssetCoCashflowRollup({projectStores}: {projectStores: string[]}) {

  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);

  let sourceMetadata = [
   'versionId', 'location', 'property'
  ]
  const store = usePersistenceStoreQueryWithContextUpdate(collection,
    pQuery()
      .selectSourceParams(sourceMetadata)
      .withLineItems(['CFADS', 'DSCR', 'CFAE'])
      // .havingParameterValues({"dsType": "site"})
      .selectLineItemsHavingFieldValues({
        'group': ['Revenues', 'Expenses', 'Debt Service'],
      })
      .withGranularity('years')
      .withStoreIds(projectStores)
    ,
    (store) => {
      setLabelAndFormatForAggregates(store)

      store.getDataSet().addTimedGroupingLineItemsByField(CANONICAL_NAME_FIELD);

      store.getDataSet().addLineItem(
        buildTimedCalculatedLineItem(
          "DSCR",
          buildYearlyTimeDef(),
          `-"CFADS"/"Total"`
        )
      );



    });

  let isLoading = !store;

  return <>
    {store && <CashflowRollUpView store={store} />}
    {isLoading && <LoadingBlock  />}
  </>
}

export function CashflowRollUpView({store}: { store: LineItemsStore }) {

  let table = QueryResult.emptyTable();

  /*
  This is a workaround because we don't have a good component to show Parameter Line Items
   */
  let timeIndex = store.timeIndex
    .withGranularity('years');

  table.addSection(
    store.query(StoreQuery
      .byField("group", "Revenues")
    .withTimeIndex(timeIndex)),"Revenues"
  )
  //
  table.addSection(
    store.query(StoreQuery
      .byField("group", "Expenses")
      .withTimeIndex(timeIndex)),"Expenses"
  )


  table.addSection(
    store.query(StoreQuery
      .byField("group", "Debt Service")
      .withTimeIndex(timeIndex)),"Debt Service"
  )

  table.addSection(
    store.query(StoreQuery.byNames(['CFADS', 'DSCR', 'CFAE'], true)
      .withTimeIndex(timeIndex)
    )
  );

  table.setFormatting("CFADS", "header")
  table.setFormatting("DSCR", "header")
  table.setFormatting("CFAE", "header")

  return <>
    {<AggregatedView
        sectionHeading={"AssetCo Cashflow Rollup"}
        store={store}
        queryResult={table} />}
  </>
}

function AggregatedView({sectionHeading, store, queryResult}: { sectionHeading: string, store: LineItemsStore, queryResult: QueryResult}) {
  return <Container>
      <Header as="h2" color="purple">{sectionHeading}</Header>
        <LineItemsTableWithFormulas
          hideToolbar
          queryResult={queryResult} store={store}
                                    withGroups  />

           <TimeSeriesChart title={""}
                            options={{type: 'bar'}} result={store.query(
             StoreQuery
               .byNames(['CFADS', 'CFAE', 'Total'])
           )} />


    </Container>
}



