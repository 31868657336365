import React, {ReactElement, useState} from "react";
import {useCompanyId} from "../../../core";
import {Dropdown, DropdownProps, Grid, Menu, Segment} from "semantic-ui-react";
import {Route, useHistory} from "react-router-dom";
import { CompanyLogo } from "./CompanyLogo";
import {AssetCoCashflowRollup} from "./AssetCoCashflowRollup";
import {FundingRollup} from "./FundingRollup";
import {CostsRollUp} from "./CostsRollup";
import {useScenarioSelect} from "../../scenarios/scnearioSelect";
import {CapexRollUp} from "./CapexRollup";
import {KelvinSystemsCashflowRollup} from "./KelvinSystemsCashflowRollup";

type Section = { title: string, key: string, path: string,
  render: (selectedStores: string[], compareWithStores: string[]) => JSX.Element, getPath: () => string, isHome?: boolean }


function SideBySideView({selectedStores, compareWithStores, render}: {
  selectedStores: string[], compareWithStores: string[],
  render: (storeIds: string[]) => ReactElement,
}) {

  let component1 = render(selectedStores);
  let component2 = compareWithStores && render(compareWithStores);

  return <>
    {compareWithStores.length == 0 && render(selectedStores)}
    {compareWithStores.length > 0 &&
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column>
                  {render(selectedStores)}
                </Grid.Column>
                <Grid.Column>
                  {render(compareWithStores)}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    }
  </>

}

function useSections(): Record<string, Section> {
  const companyId = useCompanyId();
  const BASE_PATH = `/ps/:companyId/assets-management/b`;

  const buildSection = (data: Omit<Section, "getPath">): Section => {
    return {...data, getPath: () => data.path.replace(":companyId", companyId)}
  }

  let sections = {
    "capex-rollup": {
      ...buildSection({
        title: "Project CAPEX Rollup",
        key: "capex-rollup",
        path: BASE_PATH,
        render: (selectedStores: string[], compareWithStores: string[]) =>
         <SideBySideView selectedStores={selectedStores}
                         compareWithStores={compareWithStores}
                         render={(storeIds) =>
                           <CapexRollUp projectStores={storeIds} />}
         />
      })
    },
    "assetco-cashflow": {
      ...buildSection({
        title: "AssetCo Cashflow Rollup",
        key: "assetco-cashflow",
        path: `${BASE_PATH}/assetco-cashflow`,
        render: (selectedStores: string[], compareWithStores: string[]) =>
          <SideBySideView
            selectedStores={selectedStores}
            compareWithStores={compareWithStores}
            render={(storeIds) =>
              <AssetCoCashflowRollup projectStores={storeIds} />}
          />

      })
    },
    "kelvin-systems-cashflow": {
      ...buildSection({
        title: "Kelvin Systems Cashflow Rollup",
        key: "kelvin-systems-cashflow",
        path: `${BASE_PATH}/kelvin-systems-cashflow`,
        render: (selectedStores: string[], compareWithStores: string[]) =>
          <SideBySideView
            selectedStores={selectedStores}
            compareWithStores={compareWithStores}
            render={(storeIds) =>
              <KelvinSystemsCashflowRollup projectStores={storeIds} />}
          />
      })
    }
  };

  return sections;
}

function MainContent() {
  const sections = useSections();

  let {component, selectedStores, compareWithStores} = useScenarioSelect();


  return <Segment>
    {component}
    {Object.values(sections).map(section =>
      <Route exact  path={section.path}  key={section.key}
             render={()=>section.render(selectedStores, compareWithStores)} />
    )}
  </Segment>
}

function MainMenu() {
  const sections = useSections();
  return <Menu compact>


    <SectionItem className={'nav-header-item'}
                 section={sections["capex-rollup"]} as={Menu.Item} />
    <SectionItem className={'nav-header-item'}
                 section={sections["assetco-cashflow"]} as={Menu.Item} />
    <SectionItem className={'nav-header-item'}
                 section={sections["kelvin-systems-cashflow"]} as={Menu.Item} />

  </Menu>
}

function SectionItem({section, as, ...rest}: {section: Section, as: React.ElementType, [_: string]: any}) {
  const history = useHistory();
  const Item = as;
  return <Item {...rest} onClick={() => history.push(section.getPath())}>{section.title}</Item>
}

const Name = "Kelvin" as const;
export default  {
  MainContent, MainMenu, CompanyLogo, Name
}