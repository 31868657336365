import {loadCompanyModule, useCompanyModule} from "./builder/DashboardConfigServiceRegistry";
import {useCompany} from "../core";
import {clone} from "ramda";

const UI_STRINGS: Record<string, string> = {}

let UI_STRINGS_CURRENT: Record<string, string> = {};

export function uiStringsMerge(overrides: Record<string, string>) {
    Object.assign(UI_STRINGS, overrides);
}

export function st(key: string) {
   return UI_STRINGS_CURRENT[key] ?? key;
}

let overridedCompany: string | null = null;

export function useStrings() {

    let company = useCompany().id;
    if(company !== overridedCompany) {
        UI_STRINGS_CURRENT = clone(UI_STRINGS);
        overridedCompany = company;
        Object.assign(UI_STRINGS_CURRENT, loadCompanyModule().overrideUIStrings());
    }

   return st;
}