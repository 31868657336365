import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios"
import { fromPairs } from "ramda";
import makeRequestViaAxios, {RequestRetryConfig} from "./makeRequestViaAxios";
import {EXPIRED_AUTH_TOKEN_CODE, ExpiredAuthToken, NormalizedString, normalizeString} from "./ps-models";
import {Company} from "./ps-types";
import {authStorage, getRefreshedUserToken} from "./auth";
require('dotenv').config();

let token_shown = false;

export const REGION_URLS = buildRegionUrls()
export const REGIONS: Readonly<NormalizedString[]> = Object.keys(REGION_URLS)

function buildRegionUrls(): Readonly<Record<NormalizedString, string>> {
  const str = process.env.REACT_APP_PLATFORM_REGION_URL_DICT
  if (!str) {
    return {}
  }
  try {
    console.info(`Parsing REACT_APP_PLATFORM_REGION_URL_DICT: ${str}`)
    const dict = fromPairs(
        Object.entries(JSON.parse(str))
            .map(([k, v]: readonly [string, unknown]) => [normalizeString(k), typeof v === 'string' ? v : ''] as const)
            .filter(([k, v]: readonly [string, string]) => k.length > 0 && v.length > 0)
    )
    console.info(`Platform URLs by region:`, dict)
    return dict
  } catch (e) {
    console.error(`Error parsing REACT_APP_PLATFORM_REGION_URL_DICT.`, e)
    return {}
  }
}

export function apiUrl(region: string | null) {
  const url = region ? urlByRegion(region) : process.env.REACT_APP_PLATFORM_URL
  return url ?? 'http://localhost:4000'
}

function urlByRegion(region: string) {
  const url = REGION_URLS[normalizeString(region)]
  if (!url) {
    console.error(`Platform URL for region '${region}' not found in environment variables REACT_APP_PLATFORM_REGION_URL_DICT`)
  }
  return url
}

const authorizationHeader = (token: string, company: Company | null) => {

  if(!token_shown && process.env.NODE_ENV === "development") {
    console.info("***** TOKEN ***********")
    console.info("PS-Platform: ", apiUrl(company?.region ?? null));
    console.info(`Bearer ${token}`);
    console.info("***********************");
    token_shown = true;
  }

  return token.length > 0 ? `Bearer ${token}` : ""
};

if (process.env.NODE_ENV === "development") {
  console.info("Global PS-Platform: ", apiUrl(null));
}

let refreshPromise: Promise<Token | null> | null = null;

async function getConcurrentlySafeRefreshedUserToken(): Promise<Token | null> {
  if (!refreshPromise) {
    refreshPromise = getRefreshedUserToken();
  }

  try {
    return await refreshPromise;
  } catch (e) {
    return null;
  } finally {
    refreshPromise = null;
  }
}

type Token = string

interface PlatformClientConfig<Req = Record<string, unknown>> extends AxiosRequestConfig<Req> {
  company?: Company,
  token?: Token,
  retryConfig?: RequestRetryConfig
}

export default async function psPlatformClient<Req = Record<string, unknown>, Res = Record<string, unknown>>(config: PlatformClientConfig<Req>): Promise<AxiosResponse<Res>> {
  const user = authStorage.getMaybeUser();
  const token = config.token ?? user?.token ?? null;
  const company = config.company ?? authStorage.getMaybeCompany() ?? null
  const [newToken, response] = await implPsPlatformClientWithTokenRetries<Req, Res>(config, token, company, config.retryConfig)
  if (user && newToken) {
    user.refreshToken(newToken)
  }
  return response
}

async function implPsPlatformClientWithTokenRetries<Req = Record<string, unknown>, Res = Record<string, unknown>>(
  config: AxiosRequestConfig<Req>,
  token: Token | null,
  company: Company | null,
  retryConfig?: RequestRetryConfig
): Promise<[Token | null, AxiosResponse<Res>]> {
  for (let tokenRetries = 0; tokenRetries < 5; tokenRetries++) {
    if (tokenRetries > 0) {
      token = await getConcurrentlySafeRefreshedUserToken()
    }
    addContextToAxiosConfig(token, company, config)
    try {
      return [tokenRetries === 0 ? null : token, await makeRequestViaAxios<Req, Res>(config, retryConfig)]
    } catch (e) {
      if (!isTokenExpiredError(e)) {
        throw e
      }
    }
  }

  throw new ExpiredAuthToken("Max token refresh attempts reached.")
}

function isTokenExpiredError(e: unknown): boolean {
  if (!(e instanceof AxiosError)) {
    return false
  }
  if (!e.response || !e.response.data) {
    return false
  }
  if (e.status && (e.status < 400 || e.status >= 500)) {
    return false
  }
  if (e.response.status && (e.response.status < 400 || e.response.status >= 500)) {
    return false
  }
  return e.response.data?.error === EXPIRED_AUTH_TOKEN_CODE
}

function addContextToAxiosConfig<Req>(token: Token | null, company: Company | null, config: AxiosRequestConfig<Req>) {
  config.headers = {...config?.headers, authorization: authorizationHeader( token ?? '', company)}
  if (company !== null) {
    config.baseURL = apiUrl(company.region ?? null)
    config.headers['company-id'] = company.id
    const configData = config.data as any
    if (configData && configData.companyId === undefined) {
      configData.companyId = company.id
    }
  } else {
    config.baseURL = apiUrl(null)
  }
}
