import {Button, Divider, Form, Header, Icon, Modal} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {useForm} from "../ui/UseForm";
import {LoginLayout} from "./LoginLayout";
import {resetPassword, ResetPasswordPayload} from "./auth.client";
import {useState} from "react";

export function NewResetPasswordPage() {
    const [ checkEmail, setCheckEmail ] = useState(false)
    return <>
        {checkEmail ? <CheckEmailPage setCheckEmail={setCheckEmail} /> : <ResetPasswordFormPage setCheckEmail={setCheckEmail}/>}
    </>
}

function CheckEmailPage({setCheckEmail}: {setCheckEmail: (checkEmail: boolean) => void}) {
    const [open, setOpen] = useState(false);
    const handleResetPassword = () => {
        setOpen(false); // Close the modal
        setCheckEmail(false); // Trigger your reset password logic
    };
    return <LoginLayout error={null}>
        <Header as="h1" className="hero-title">Check your email</Header>
        <p>We've sent you an email with a link to reset your password.</p>
        <p>The email will arrive in a few minutes. If you don't see it, check your spam folder.</p>
        <Button primary type="submit" className="raised" size='huge' onClick={(e) => setOpen(true)}>
            Reset password again
            <Icon name='arrow right' />
        </Button>
        <Modal
            size="mini"
            open={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Content>
                <Icon name='close' style={{ cursor: 'pointer', float: 'right' }} onClick={() => setOpen(false)} />
                <p style={{ textAlign: 'center', fontSize: '1.1rem' }}>Didn't receive the email?</p>
            </Modal.Content>
            <Modal.Actions style={{ justifyContent: 'center', padding: '1em' }}>
                <Button primary type="submit" className="raised" size='huge' onClick={handleResetPassword}>
                    Try again
                    <Icon name='arrow right' />
                </Button>
            </Modal.Actions>
        </Modal>
        <Divider hidden />
        <p>Still need help? <Link to="/about" className="slightLeftMargin">Contact us</Link></p>
    </LoginLayout>
}

function ResetPasswordFormPage({setCheckEmail}: {setCheckEmail: (checkEmail: boolean) => void}) {
    const { formData, handleChange, handleSubmit, submitting, error } = useForm<{email: string}>({
        initialState: {
            email: '',
        },
        onSubmit: async (data) => {
            await resetPassword(new ResetPasswordPayload(data))
            setCheckEmail(true)
        }
    })

    return <LoginLayout error={error}>
      <Header as="h1" className="hero-title">Reset your password</Header>
      <p>Don't have an account? <Link to="/signup">Click here</Link></p>
      <Form size='large' onSubmit={handleSubmit} className="outlineForm">
        <Form.Input
          fluid
          // icon='mail'
          // iconPosition='left'
          placeholder='Email address'
          name="email"
          value={formData.email}
          onChange={handleChange}
        />

        <Divider hidden />
        <Divider hidden />

        <Button primary type="submit" className="raised" size='huge' loading={submitting} disabled={submitting} >
          Recover Password
          <Icon name='arrow right' />
        </Button>

      </Form>
      <Divider hidden />
      <p>Still need help? <Link to="/about" className="slightLeftMargin">Contact us</Link></p>
  </LoginLayout>
}

