import {LineItem, } from "./LineItem.model";
import { LineItemValue, NO_DATA, ValueType} from "./LineItemValue.model";
import {
    TimeStream
} from "../Time.model";
import {AggregatorMethod, runAggregatorMethod} from "../line-item-utils/aggregators";
import {LineItemsFieldSet} from "./LineItemsFieldSet";
import {TimedLineItem} from "./TimedLineItem";
import {runSpreadMethod, TimeDefinition} from "./TimeDefinition";
import {
    addTime,
    buildTimeRangeFromGranularity,
    isLowerGranularity,
    truncateTime,
    truncateTimeStamp
} from "../TimeGranularity";
import {TimeRange, TimeUnit, TimeUnits} from "../Time.types";
import {entries} from "../line-item-utils/coding.utils";
import {PartialExecution} from "../lineitems-store/PartialExecution";
import {mapObjIndexed, filter} from "ramda";


export function buildTimedRawLineItem(name: string,
                                  timeDefinition: TimeDefinition,
                                  fields: LineItemsFieldSet = new LineItemsFieldSet(),
                                      lowerFrequencyAggregator: AggregatorMethod = "last",
): TimedRawLineItem {
    return new TimedRawLineItem(name, timeDefinition, fields, lowerFrequencyAggregator)
}

export const TIMED_RAW_LINE_ITEM_TYPE = "TimedRawLineItem"

//Todo: Can we extend from RawLineItem?
export class TimedRawLineItem extends TimedLineItem {

    _discriminator: "timed" = "timed";
    public timeStream = new TimeStream<LineItemValue>();
    constructor(public name: string,
                public timeDefinition: TimeDefinition,
                public fields: LineItemsFieldSet,
                public lowerFrequencyAggregator: AggregatorMethod = "last"
    ) {
        super(name, fields);
    }

    getTimeDefinition(): TimeDefinition {
        return this.timeDefinition;
    }

    getValue(timeQuery: TimeUnit, report: PartialExecution, targetGranularity?: TimeUnits): LineItemValue {

        if(!targetGranularity) {
            targetGranularity = this.timeDefinition.granularity;
        }

        if(targetGranularity === this.timeDefinition.granularity) {
            let existingValue = this.timeStream.get(timeQuery);

            if(existingValue) {
                return existingValue;
            } else {
                return NO_DATA;
            }
        }

        if(isLowerGranularity(targetGranularity, this.timeDefinition.granularity)) {
            //TODO: Using "repeat" spread strategy, support other spreaders
            targetGranularity = this.timeDefinition.granularity;
        }


        let range = buildTimeRangeFromGranularity(timeQuery, this.timeDefinition.granularity, targetGranularity);
        let values = this.timeStream.getRange(range, this.timeDefinition.granularity)
          .map(v => v.value);

        if(values.length === 0) {
            return NO_DATA;
        }

        return new LineItemValue(runAggregatorMethod(this.timeDefinition.aggregator, values));
    }


    add(timeStamp: number | Date, value: ValueType, lowerFrequencyAggregator?: AggregatorMethod): TimedRawLineItem {

        if(value === null || value === undefined) {
            return this;
        }

        if(timeStamp instanceof Date) {
            timeStamp = timeStamp.getTime();
        }

        let truncatedTimeStamp = truncateTimeStamp(this.timeDefinition.granularity, timeStamp);

        let existingValue = this.timeStream.get(truncatedTimeStamp);
        if(existingValue) {
            value = runAggregatorMethod(lowerFrequencyAggregator || this.lowerFrequencyAggregator, [existingValue.value, value]);
        }

        this.timeStream.add(truncatedTimeStamp, new LineItemValue(value));

        return this;
    }

    clone(): LineItem {
        let newLi = new TimedRawLineItem(this.name, this.timeDefinition, this.fields.clone(), this.lowerFrequencyAggregator);
        // @ts-ignore
        newLi.timeStream = this.timeStream.clone();
        return newLi;
    }

    get type(): string {
        return TIMED_RAW_LINE_ITEM_TYPE
    }

    serialize() {

        let serializedTimeStream = this.timeStream.serialize();

        //Filter out the values that are zero from serialized time stream
        let filteredTimeStream = filter((v) => {
            return !!v.value;
        }, serializedTimeStream);

        filteredTimeStream = mapObjIndexed((v, k) => {
            return {value: v.value}
        }, filteredTimeStream)

        return {
            type: this.type,
            name: this.name,
            fields: this.fields.serialize(),
            timeStream: filteredTimeStream,
            timeDefinition: this.timeDefinition.serialize(),
            lowerFrequencyAggregator: this.lowerFrequencyAggregator
        }
    }

    withDefinition(timeDefinition: TimeDefinition) {
        let timedRaw =  new TimedRawLineItem(this.name, timeDefinition, this.fields.clone(), this.lowerFrequencyAggregator);

        for(let [timeStamp, value] of entries(this.timeStream.timeStream)) {
            timedRaw.add(parseInt(timeStamp), value.value);
        }

        return timedRaw;
    }

    shiftTime(timeUnit: TimeUnits, amount: number) {
        let newLineItem = new TimedRawLineItem(this.name, this.timeDefinition, this.fields.clone(), this.lowerFrequencyAggregator);

        for(let [timeStamp, value] of entries(this.timeStream.timeStream)) {
            newLineItem.add(addTime(new Date(parseInt(timeStamp)), amount, timeUnit), value.value);
        }

        this.timeStream = newLineItem.timeStream;
    }

    getValues() {
        return this.timeStream.getValues().map(v => v.value);
    }

    getTimes() {
        return this.timeStream.getTimes();
    }

    getTotal({start, end}: TimeRange, report: PartialExecution, aggregator?: AggregatorMethod): ValueType {
        let values = this.timeStream.getRange({start, end}, this.timeDefinition.granularity)
          .map(v => v.value);

        if(values.length === 0) {
            return 0;
        }

        return runAggregatorMethod(aggregator || this.timeDefinition.aggregator, values);
    }


    static deserialize(li: any) {
        const raw = new TimedRawLineItem(li.name, TimeDefinition.deserialize(li.timeDefinition ), LineItemsFieldSet.deserialize(li.fields), li.lowerFrequencyAggregator)
        raw.timeStream = TimeStream.deserialize(li.timeStream)
        return raw
    }
}

