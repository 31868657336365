import {BuilderContext, registerWidgetType} from "../../builder/WidgetRegistry";
import React from "react";
import {DEFAULT_SOURCE_GRID, StoreQuery} from "../../../ps-models/lineitems-store";
import {StoreQueryFlat} from "../../../ps-models/lineitems-store/StoreQueryFlat";
import {useOnStoreReady, useUpdateOnGlobalContextChange} from "../../builder/widgets/commons";
import {SideBySideView} from "../../SideBySideView";
import {GridWidget, GridWidgetConfig, GridWidgetConfigProps} from "../../builder/widgets/LineItemsGridWidget";
import {Message} from "semantic-ui-react";

const DEFAULT_STORE_QUERY_FLAT = new StoreQueryFlat();
// non default v1 AND default non v1.
const BASE_VS_DEFAULT_VERSION_QUERY = (
        (StoreQuery.byField('source_defaultVersion', 'true').not())
        .and(StoreQuery.byField('source_versionTitle', 'v1'))
    ).or(
        StoreQuery.byField('source_defaultVersion', 'true')
        .and(StoreQuery.byField('source_versionTitle', 'v1').not())
    );

registerWidgetType({
    typeId: 'Ampyr-base-vs-default-versions',
    metadata: {
      name: 'Ampyr Base vs Default Versions',
      description: '',
      icon: 'EnvelopeIcon',
    },
    defaultConfig: {
        title: '',
        grid: DEFAULT_SOURCE_GRID,
        storeQueryFlat: DEFAULT_STORE_QUERY_FLAT.serialize()
    },
    renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
        return <GridWidgetConfig
            config={config} context={context} onConfigChange={setConfig}/>
    },
    render: (config: any, context: BuilderContext, setOutput) => {
      return <WidgetWithCompare config={config.config} context={context} setOutput={setOutput}/>
    }
  }
)

export function WidgetWithCompare({config, context, setOutput}: {config: GridWidgetConfigProps, context: BuilderContext, setOutput:(key: string, value: any) => void }) {
    useOnStoreReady(context);
    useUpdateOnGlobalContextChange(context);

    const storeToCompareWith = context.appContext.getLastStoreToCompareWith();
    const mainStore = context.appContext.getStore();
    if(mainStore.getDataSet().getFieldValues('source_defaultVersion').length ===0 || mainStore.getDataSet().getFieldValues('source_versionTitle').length === 0 ){
        if(context.readOnly) {
            return <></>
        }
        return <>
            <Message warning>
                Please make sure you select defaultVersion and versionTitle params from "Query Stores" section to be able to use this widget.
            </Message>
        </>
    }

    if(storeToCompareWith){
        return <SideBySideView selectedStores={mainStore}
                               compareWithStores={storeToCompareWith}
                               highlightComparedWithStore={true}
                               render={(store) =>
                                   <GridWidget
                                       config={config}
                                       context={{...context, store}}
                                       setOutput={setOutput}
                                       externalCustomQuery={BASE_VS_DEFAULT_VERSION_QUERY}
                                   />}
        />
    }
    return <GridWidget
        config={config}
        context={{...context, store: mainStore}} setOutput={setOutput}
        externalCustomQuery={BASE_VS_DEFAULT_VERSION_QUERY}
    />
}