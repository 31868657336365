import {matchPath, useLocation} from "react-router-dom";
import { useEffect } from "react";
import {analytics} from "../platform/analytics/AnalyticsFacade";

const FirebaseAnalytics = () => {
    let location = useLocation();
    useEffect(() => {
        const pagePathName = location.pathname
        let documentTitle = 'Perl Street'
        if(matchPath(pagePathName, {path:'/app', exact: false, strict: true})){
            documentTitle = 'Dashboard | Perl Street'
        } else if(pagePathName === '/'){
            documentTitle = 'Home | Perl Street'
        }
        document.title = documentTitle;
        analytics()?.reportScreenView(documentTitle);
    }, [location]);
    return null;
}

  
export default FirebaseAnalytics;