import {useLineItemsStoreMetadata} from "./LineItemsStore.hook";
import {Select} from "semantic-ui-react";
import React, {useEffect} from "react";
import {PersistenceQuery, StoreQuery} from "../ps-models/lineitems-store";
import {LineItem, ValueType} from "../ps-models/line-items";
import {indexBy} from "ramda";



export function StoresMetadataSelect({onSelect, collection, value, query}:
                                       {value?: ValueType,
                                         query?: PersistenceQuery,
                                         onSelect: (id: string, text: string, li: LineItem) => void, collection: string}) {

  const storeMetadata = useLineItemsStoreMetadata(collection, query);

  const result = storeMetadata?.query(StoreQuery.all());

  useEffect(
    () => {
      if(!result) {
        return;
      }

      if(!value && result.rows.length === 1) {
        let liName = result.rows[0].name.value as string;
        let liLabel = result.rows[0].name.text;
        handleSelect(liName, liLabel);
      }
    }, [result]
  );

  const handleSelect = (liName: string, liLabel: string) => {
    let lineItem = storeMetadata?.getDataSet().getLineItem(liName);
    if(lineItem) {
      onSelect( liName, liLabel, lineItem);
    }
  }

  if(!result || !storeMetadata) {
    return <div>Loading...</div>
  }

  let options = storeMetadata.query(StoreQuery.all(), {withMetadata: []}).rows.map((store, i) => ({
      key: result.column(i, 'name')?.value,
      value: result.column(i, 'name')?.value,
      text: result.column(i, 'name')?.text
    }));

  let optionsByValue = indexBy((o) => o.value as string, options);

  return <Select
    value={value}
    autocomplete="off"
    search={true}
    options={options || []}
    onChange={(e, data) => handleSelect(data.value as string, optionsByValue[data.value as string].text)}
  />
}