import {Radio} from "semantic-ui-react";
import React from "react";
import {AggregateOver} from "../../../ps-models/lineitems-store";

export const DefaultAggregateOverConfigurator = ({defaultAggregateOverConfig, onDefaultAggregateOverConfigChange}:{defaultAggregateOverConfig: AggregateOver, onDefaultAggregateOverConfigChange: (defaultAggregateOverConfig: AggregateOver)=>void})=> {
    return <div
        style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column', border: '1px dotted gray', width: '35%', gap: '2px'}}
    >
        <strong>Default Aggregate Over</strong>
        <Radio
            label='Time Index'
            name='defaultAggregateOver'
            checked={defaultAggregateOverConfig === AggregateOver.TIME_INDEX}
            onChange={(e, {checked}) => {
                if(!!checked === true){
                    onDefaultAggregateOverConfigChange(AggregateOver.TIME_INDEX)
                }
            }}
        />
        <Radio
            label='Current Range'
            name='defaultAggregateOver'
            checked={defaultAggregateOverConfig === AggregateOver.CURRENT_RANGE}
            onChange={(e, {checked}) => {
                if(!!checked === true){
                    onDefaultAggregateOverConfigChange(AggregateOver.CURRENT_RANGE);
                }
            }}
        />
    </div>
}