import {DependencyList, useCallback, useEffect, useState} from "react"
import {CancellablePromise} from "./ps-models";

type UsePromiseResult<T> = [T | undefined, Error | undefined, boolean]

export const usePromise = <T>(promiseFactory: () => Promise<T>, deps: DependencyList): UsePromiseResult<T> => {
    const [result, setResult] = useState<UsePromiseResult<T>>([undefined, undefined, false])
    useEffect(() => {
        const promise = CancellablePromise.makeCancellable(promiseFactory())
        promise
          .then(result => setResult([result, undefined, true]))
          .catch(e => setResult([undefined, e, true]))
        return () => promise.cancel()
    }, deps)
    return result
}

export const useInstantUpdater = (): [number, () => void] => {
    const [id, setId] = useState(0)
    const update = useCallback(() => setId(prev => prev + 1), [])
    return [id, update]
}

export const useDeferredUpdater = (): [number | 'loading', () => void, () => void] => {
    const [id, setId] = useState<number | 'loading'>(0)
    const update = () => setId(id === 'loading' ? 0 : id + 1)
    const loading = () => setId('loading')
    return [id, update, loading]
}
