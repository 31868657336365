import {compareNormalized, normalizeString} from "../line-item-utils/coding.utils";
import {values} from "ramda";
import {ValueType} from "./LineItemValue.model";
import {LineItemField} from "../../ps-types";


export interface FieldParam {
  name: string,
  value: ValueType,
  label?: string
}

export function compareField(left: LineItemField, right: LineItemField): number {
    return left.key.localeCompare(right.key)
}

export class LineItemsFieldSet {

  private fields: Record<string, LineItemField> = {}

  static fromMap(fields: Record<string, ValueType>) {
    const fieldSet = new LineItemsFieldSet()
    Object.entries(fields).forEach(([key, value]) => {
       fieldSet.addField(key, value);
    });
    return fieldSet
  }

  static fromFieldMap(fields: Record<string, Omit<LineItemField, "key">>) {
    const fieldSet = new LineItemsFieldSet()
    Object.entries(fields).forEach(([key, value]) => {
      fieldSet.addField(value.name, value.value, value.label);
    });
    return fieldSet
  }

  static fromList(fields: FieldParam[]) {
    const fieldSet = new LineItemsFieldSet()
    fields.forEach(({name, value, label}) => {
      fieldSet.addField(name, value, label || name);
    });
    return fieldSet
  }


  addField(name: string, value: ValueType, label = name) {
    if(!name || value === null || value === undefined) {
      console.warn(`Invalid field name or value: ${name} - ${value}`);
    }
    let key = normalizeString(name);
    this.fields[key] = {name, key, value, label}
    return this
  }

  hasFieldValue(fieldName: string, value: string) {
    let field = this.fields[normalizeString(fieldName)];
    return field && compareNormalized(this.getFieldStr(fieldName), value);
  }

  hasFieldWithValue(value: string) {
    return values(this.fields).some(field => compareNormalized(field.value.toString(), value))
  }

  hasField(field: string): boolean {
    return !!this.fields[normalizeString(field)];
  }

  getField(field: string): LineItemField | undefined {
    if (!this.hasField(field)) {
      return undefined;
    }
    return this.fields[normalizeString(field)];
  }

  getFieldStr(field: string) {
    return this.fields[normalizeString(field)]?.value.toString() ||
      this.fields[normalizeString(`source_${field}`)]?.value.toString() || ""
  }

  getFieldName(field: string) {
    return this.fields[normalizeString(field)]?.name
  }

  getValue(field: string) {
    return this.fields[normalizeString(field)]?.value
  }

  getFieldMap(): Record<string, LineItemField> {
    return Object.values(this.fields).reduce((acc: Record<string, LineItemField>, field) => {
      acc[field.name] = field;
      return acc;
    }, {});
  }

  getFields(): LineItemField[] {
    return Object.values(this.fields)
  }

  clone() {
    const fieldSet = new LineItemsFieldSet();


    Object.values(this.fields).forEach(field => {
      fieldSet.addField(field.name, field.value.toString(), field.label);
    });

    return fieldSet
  }

  getAllKeys() {
    return Object.keys(this.fields)
  }

  getFieldWithValue(value: string) {
    return values(this.fields).find(field => compareNormalized(field.value.toString(), value))?.name
  }

  serialize() {
    return this.fields;
  }

  static deserialize(fields: any) {
    let fieldSet = new LineItemsFieldSet();

    //#Migration: We are changing fields to be stored as map rather than array. Need to handle both until we run the migrations
    if(Array.isArray(fields)) {
      Object.values(fields).forEach((value: any) => {
        fieldSet.addField(value.name, value.value, value.label || value.name);
      });
    } else {
      fieldSet.fields = fields;
    }

    return fieldSet;
  }

  removeField(fieldName: string) {
    delete this.fields[normalizeString(fieldName)];
  }

  addDynamicField(name: string, code: string) {
    if(!name || code === null) {
      console.warn(`Invalid dynamic field name or code: ${name} - ${code}`);
    }
    let key = normalizeString(name);
    this.fields[key] = {name, key, code, isDynamic: true, value: 0}
    return this
  }
}
//@TODO: We need to use this in MongoPersistence's query method too.
export const STORE_IDENTIFYING_FIELDS_SHARED_BY_SITE_LINE_ITEMS = ["store_sourceId", "store_sourceName", "store_sourceLineItemName", "phase"];