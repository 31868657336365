import React, {useEffect} from "react";
import {DataGridPro, GridColDef} from "@mui/x-data-grid-pro";
import {Button, Icon} from "semantic-ui-react";

function updateRowPosition(
    initialIndex: number,
    newIndex: number,
    rows: ReOrderingGridRow[] | undefined,
): any {
    if(!rows) return;
    const rowsClone = [...rows];
    const row = rowsClone.splice(initialIndex, 1)[0];
    rowsClone.splice(newIndex, 0, row);
    return buildRows(rowsClone.map(({id})=>id));
}

const buildRows = (data: string[]) => {
    return data.map((d, index)=>({id: d, index, col1: d}))
}

interface ReOrderingGridRow {id: string, index: number, col1: string}

export function RowOrderingGrid({data, onSelected}: {data: string[], onSelected: (liNames: string[]) => void}) {
    const [rows, setRows] = React.useState<ReOrderingGridRow[]>();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setRows(buildRows(data));
    }, [data]);



    useEffect(()=> {
        if(!rows) return;
        const updatedSelection = rows.map(({id})=>id);
        onSelected(updatedSelection);
    }, [rows])

    const handleMoveUp = (e: any, rowParams: any) => {
        handleRowOrderChange(rowParams.id, rowParams.index, rowParams.index -1)
    }

    const handleMoveDown = (e: any, rowParams: any) => {
        handleRowOrderChange(rowParams.id, rowParams.index, rowParams.index +1)
    }

    const handleRemoval = (e: any, rowParams: any) => {
        setLoading(true);
        if(!rows) return;
        const rowsClone = [...rows];
        const row = rowsClone.splice(rowParams.index, 1)[0];
        const newRows = buildRows(rowsClone.map(({id})=>id));
        setRows(newRows);
        setLoading(false);
    }

    const columns: GridColDef[] = [
        { field: 'reOrder', headerName: 'Re Order', width: 150 , renderCell: (params) => {
                return (
                    <>
                        <Button
                            size="small"
                            icon onClick={(e) => handleMoveUp(e, params.row)}>
                            <Icon name='arrow up' />
                        </Button>
                        <Button
                            size="small"
                            icon onClick={(e) => handleMoveDown(e, params.row)}>
                            <Icon name='arrow down' />
                        </Button>
                    </>
                );
            } },
        { field: 'col1', headerName: 'Name', width: 150 },
        { field: 'remove', headerName: 'Remove', width: 150 , renderCell: (params) => {
                return (
                    <>
                        <Button
                            size="small"
                            icon onClick={(e) => handleRemoval(e, params.row)}>
                            <Icon name='delete' />
                        </Button>
                    </>
                );
            } },
    ];

    const handleRowOrderChange = async (rowId: string, oldIndex: number, targetIndex: number) => {
        setLoading(true);
        const newRows = updateRowPosition(
            oldIndex,
            targetIndex,
            rows,
        );

        setRows(newRows);
        setLoading(false);
    };



    return (rows ? <div style={{ height: 400, width: '100%' }}>
        <DataGridPro
            experimentalFeatures={{ariaV7: true}}
            loading={loading}
            columns={columns}
            rows={rows}
        />
    </div>: <></>);
}