import {auth, AZURE_ENTRA_SAML_AUTH_PROVIDER_ID} from "../../firebase/firebase.core";
import {authStorage, SKIPPING_AUTH} from "./AuthStorage";
import {metricsQueue} from "../../utils/metricsCollector";
import firebase, {User} from "firebase";
import {analytics} from "../analytics/AnalyticsFacade";
import {CountryCode, getCountryCallingCode, isValidPhoneNumber} from "libphonenumber-js";
import {LoginError, OtpError, PhoneError, RegistrationError, ResetPasswordError} from "./errors";
import {
    accessPsUser,
    clearPsUserCache, createUserDocumentIfNotExists,
    setUserProfile, updateUserIntakeData,
    updateUserOnboardingData
} from "./user.client";
import {notifyNewCompany, notifyNewSignUp} from "../mail";
import {SignUpUserData, UserCompany, UserStatus} from "../ps-models/user";
import {addCompanyDocument} from "./company.repository";
import userflow from "userflow.js";
import {isAuthError} from "../ps-types/Auth";
import {LoginStorage} from "./LoginStorage";
import {AUTHENTICATION_PATHS} from "../../constants";
import {CompanyCreateDto, OnboardingData, PrimaryIntakeData} from "../ps-types";
import {assignUserToCompanyByDomain, assignUserToDemoCompany} from "../company/company.client";

export type LoginData = Omit<LoginPayload, "_tag">
export class LoginPayload {
    private _tag: "LoginPayload" = "LoginPayload"
    readonly email: string
    readonly password: string

    constructor({email, password}: LoginData) {
        this.email = email.trim()
        this.password = password.trim()
        if (!this.email || !this.password) {
            throw new LoginError("Please fill out all fields")
        }
    }
}

export async function login(payload: LoginPayload): Promise<void> {
    try {
        await doLogin(payload)
    } catch (error: any) {
        handleLoginError(error, payload.email)
    }
}

export class TwoFactorLoginPayload {
    private _tag: "TwoFactorLoginPayload" = "TwoFactorLoginPayload"

    constructor(readonly verificationId: string, readonly otp: string, readonly resolver: firebase.auth.MultiFactorResolver) {
        if (!this.verificationId.trim()) {
            throw new LoginError("Please try again.")
        }
        if (!this.otp.trim()) {
            throw new OtpError("Code is empty, please fill it up.")
        }
        if (this.otp.length !== 6 || isNaN(parseInt(this.otp))) {
            throw new OtpError("Code is not correct, it should be 6 numbers.")
        }
    }
}

export async function twoFactorAuthLogin(payload: TwoFactorLoginPayload, email: string) {
    try {
        await doTwoFactorAuthLogin(payload)
        addSignInMetrics(email);
    } catch(e) {
        handleLoginError(e, email)
    }
}

export class SSOLoginPayload {
    constructor(readonly providerId: string) {
        if (!this.providerId.trim()) {
            throw new LoginError("Please try again.")
        }
    }
}

export async function doLogin({email, password}: LoginPayload): Promise<void> {
    const result = await auth.signInWithEmailAndPassword(email, password)
    if (!result.user) {
        throw new LoginError("Please try again.")
    }
    addSignInMetrics(email);
}

function addSignInMetrics(email: string){
    console.info(`Sign In Event :: User email ${email}`);
    metricsQueue.add({name: "SignIn", value: 1, metricCategory: "auth"});
}

async function doTwoFactorAuthLogin({verificationId, otp, resolver}: TwoFactorLoginPayload) {
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
    const assertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    const result = await resolver.resolveSignIn(assertion)
    if (!result.user) {
        throw new LoginError("Please try again.")
    }
}

export async function ssoAuthLogin(payload: SSOLoginPayload) {
    const provider = new firebase.auth.SAMLAuthProvider(payload.providerId);
    try {
        const result = await auth.signInWithPopup(provider);
        if (!result.user) {
            throw new LoginError("Please try again.")
        }
        let email = result.user.email;
        if(!email){
            throw new LoginError("There is something wrong with your SSO integration.")
            // @TODO; we need to raise an exception to use the metadata file that we provided.
        }
        if(result.additionalUserInfo?.isNewUser) {
            analytics()?.reportSignUp(result.user)
            metricsQueue.add({name: "SignUp", value: 1, metricCategory: "auth"});
            const displayNameParts = result.user.displayName?.split(" ");
            await notifyNewSignUp(email, {...result.additionalUserInfo, profile: {
                    email: email ?? "",
                    firstName: displayNameParts?.[0] ?? "",
                    lastName: displayNameParts?.[1] ?? "",
                    displayName: result.user.displayName ?? ""
                }, status: "enabled"});
        } else {
            addSignInMetrics(email);
        }
    } catch(error: any){
        const errorCode = error?.code;
        const errorMessage = error?.message;
        // The email of the user's account used.
        const email = error?.customData?.email;
        // The AuthCredential type that was used.
        console.error({errorCode, errorMessage, email})
        // Handle / display error.
        handleLoginError(error, email)
        // ...
    }
}

function handleLoginError(error: any, email?: string) {
        if (error?.code === 'auth/multi-factor-auth-required' && error?.resolver) {
            throw error
        } else if (error?.code === "auth/wrong-password") {
            metricsQueue.add({
                name: "SignInAttemptWithInvalidPassword",
                value: 1,
                metricCategory: "auth",
            });
            console.warn(`Sign In Exception :: Occurred for ${email}`, error);
        } else {
            console.error(
                `Sign In Exception :: Occurred for ${email}. They could not sign in.`,
                error
            );
            metricsQueue.add({
                name: "SignInException",
                value: 1,
                metricCategory: "auth",
            });
        }
        throw error
}

async function loginExtra(fbUser: firebase.User): Promise<void> {
    const userToken = await fbUser.getIdToken(true)
    const user = await accessPsUser(fbUser, userToken)


    authStorage.newLogin(user)
}


export async function signUp(payload: SignUpPayload): Promise<void> {
    try {
        await doSignUp(payload)
    } catch (error: any) {
        if (error?.code === "auth/email-already-in-use") {
            console.warn(`Sign Up Exception :: Occurred for ${payload.email}`, error);
        } else {
            console.error(
                `Sign Up Exception :: Occurred for ${payload.email}. They could not sign up.`,
                error
            );
            metricsQueue.add({
                name: "SignUpException",
                value: 1,
                metricCategory: "auth",
            });
        }
        throw error
    }
}

export async function doSignUp({email, password, signUpUserData}: SignUpPayload): Promise<void> {
    const {user} = await auth.createUserWithEmailAndPassword(email, password);
    if (!user) {
        throw new RegistrationError("Could not sign up.")
    }

    console.info(`Sign Up Event :: User email ${email}`);
    analytics()?.reportSignUp(user)
    metricsQueue.add({name: "SignUp", value: 1, metricCategory: "auth"});

    await setUserProfile(user, signUpUserData);
    await notifyNewSignUp(email, signUpUserData);
    await sendVerificationEmail();
}

export interface SignUpData extends SignUpDataBase {
    password: string;
    passwordAgain: string;
}

export interface SignUpDataBase {
    firstName: string;
    lastName: string;
    email: string;
    status: UserStatus
}

export class SignUpPayloadBase {
    private _tag: "SignUpPayloadBase" = "SignUpPayloadBase"
    readonly email: string
    readonly firstName: string
    readonly lastName: string
    readonly displayName: string
    readonly status: UserStatus;

    constructor({email, firstName, lastName, status}: SignUpDataBase) {
        this.email = email.trim()
        this.firstName = firstName.trim()
        this.lastName = lastName.trim()
        this.displayName = `${this.firstName} ${this.lastName}`
        this.status = status;

        if (!this.email || !this.firstName || !this.lastName) {
            throw new RegistrationError("Fill all the fields.", "custom/failed-signup-inputs")
        }
    }

    get signUpUserData(): SignUpUserData {
        return {
            isNewUser: true,
            providerId: "password",
            profile: {
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                displayName: this.displayName,
            },
            status: this.status,
        }
    }
}

export class SignUpPayload extends SignUpPayloadBase {
    readonly password: string

    constructor(signUpPayload: SignUpData) {
        super(signUpPayload);
        const {password, passwordAgain} = signUpPayload;
        this.password = password.trim()

        if (this.password !== passwordAgain.trim()) {
            throw new RegistrationError("Password does not match.", "custom/failed-password-verification")
        }
    }
}

export class ResetPasswordPayload {
    private _tag: "ResetPasswordPayload" = "ResetPasswordPayload"
    readonly email: string

    constructor({email}: { email: string }) {
        this.email = email.trim()
        if (!this.email) {
            throw new ResetPasswordError("Please enter a valid email")
        }
    }
}

export async function resetPassword(payload: ResetPasswordPayload) {
    try {
        await doResetPassword(payload)
    } catch (error: any) {
        if (error?.code === "auth/user-not-found") {
            console.warn(`Reset Password Exception :: Occurred for ${payload.email}`, error);
        } else {
            console.error(
                `Reset Password Exception :: Occurred for ${payload.email}. They could not reset their password.`,
                error
            );
            metricsQueue.add({
                name: "ResetPasswordException",
                value: 1,
                metricCategory: "auth",
            });
        }
        throw error
    }
}

export async function doResetPassword({email}: ResetPasswordPayload) {
    await auth.sendPasswordResetEmail(email);
    console.info(`Reset Password Event :: User email ${email}`);
    metricsQueue.add({
        name: "ResetPassword",
        value: 1,
        metricCategory: "auth",
    });
}

export async function logout() {
    LoginStorage.setRedirectionURL(AUTHENTICATION_PATHS.logout);
    await auth.signOut()
}

export function isMultiFactorError(error: unknown): error is firebase.auth.MultiFactorError {
    return isAuthError(error) && 'resolver' in error && !!error.resolver && error.code === 'auth/multi-factor-auth-required'
}

export function observeLoginStatus(setAuthState: (status: {loading: boolean, isAuthenticated: boolean}) => void): () => void {
    if (SKIPPING_AUTH) {
      setAuthState({ isAuthenticated: true, loading: false });
      return () => {}
    }

    const unsubscribe = firebase.auth().onAuthStateChanged(async (fbUser) => {
        let authStateChangePropagated = false;
        try {
                if(!fbUser){
                    setAuthState({ isAuthenticated: false, loading: true });
                    markUserAsSignedOutInAuthStorage();
                    setAuthState({ isAuthenticated: false, loading: false });
                    authStateChangePropagated = true;
                } else {
                    authStateChangePropagated =  await (fbUser.providerData[0]?.providerId === AZURE_ENTRA_SAML_AUTH_PROVIDER_ID ? onSignInTriggerViaSSO(fbUser, setAuthState) :  onSignInTrigger(fbUser, setAuthState));
                }
        } catch(err){
            console.error(err)
        } finally {
            if(!authStateChangePropagated){
                setAuthState({ isAuthenticated: !!fbUser, loading: false });
            }
        }
        });

    return () => unsubscribe()
}

async function onSignInTrigger(fbUser: User, setAuthState: (status: {loading: boolean, isAuthenticated: boolean}) => void): Promise<boolean> {
    let authStateChangePropagated = false;
    let authStateChangeTriggerReason: "sign-in-from-user-with-mail-verification-pending" | "sign-in-from-user-fully-registered";
    setAuthState({ isAuthenticated: true, loading: true });
    authStateChangeTriggerReason = !fbUser.emailVerified ? "sign-in-from-user-with-mail-verification-pending": "sign-in-from-user-fully-registered";
    switch(authStateChangeTriggerReason){
        case "sign-in-from-user-fully-registered":
            // @ts-ignore
            await loadSignedInUserToAuthStorage(fbUser);
            setAuthState({ isAuthenticated: true, loading: false });
            authStateChangePropagated = true;
            break;
        case "sign-in-from-user-with-mail-verification-pending":
            // @ts-ignore
            await createUserDocumentAndloadSignedInUserToAuthStorage(fbUser);
            setAuthState({ isAuthenticated: true, loading: false });
            authStateChangePropagated = true;
            break;
    }
    return authStateChangePropagated;
}

async function onSignInTriggerViaSSO(fbUser: User, setAuthState: (status: {loading: boolean, isAuthenticated: boolean}) => void): Promise<boolean>{
    let authStateChangePropagated = false;

    setAuthState({ isAuthenticated: true, loading: true });
    if(fbUser.metadata.lastSignInTime === fbUser.metadata.creationTime){ // This will run at the first and second login only...
        const userToken = await fbUser.getIdToken();
        const emailParts = fbUser?.email?.split("@");
        const domain = emailParts?.[1];
        if(domain){
            try {
                await createUserDocumentIfNotExists(fbUser);
                await assignUserToCompanyByDomain(domain, fbUser.uid, userToken);
            } catch(err: any) {
                if(err.message === "No company with specified domain exists"){
                    console.warn('Continuing as usual for domain based login');
                } else {
                    throw err;
                }
            }
        }
    }
    // @ts-ignore
    await createUserDocumentAndloadSignedInUserToAuthStorage(fbUser);
    setAuthState({ isAuthenticated: true, loading: false });
    authStateChangePropagated = true;

    return authStateChangePropagated;
}

async function createUserDocumentAndloadSignedInUserToAuthStorage(fbUser: firebase.User) {
    if(!authStorage.getMaybeUser()) {
        await createUserDocumentIfNotExists(fbUser);
        await loginExtra(fbUser);
    }
}

async function loadSignedInUserToAuthStorage(fbUser: firebase.User) {
    if(!authStorage.getMaybeUser()) {
        await loginExtra(fbUser);
    }
}

function markUserAsSignedOutInAuthStorage() {
    if(authStorage.getMaybeUser()){
        clearPsUserCache()
        authStorage.logout()
    }
    if(process.env.REACT_APP_USERFLOW_TOKEN) {
        userflow.reset()
    }
}

export async function getRefreshedUserToken(): Promise<string | null> {
    try {
        return await firebase.auth().currentUser?.getIdToken(true) ?? null
    } catch (error) {
        console.error(
            `Refresh Token Exception :: Occurred.`,
            error
        );
        metricsQueue.add({
            name: "Refresh Token Exception",
            value: 1,
            metricCategory: "auth",
        });
        throw error
    }
}

const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
export async function verifyPhoneNumber(resolver: firebase.auth.MultiFactorResolver, recaptchaVerifier: firebase.auth.RecaptchaVerifier): Promise<string> {
    return await phoneAuthProvider.verifyPhoneNumber({
        multiFactorHint: resolver.hints[0],
        session: resolver.session
    }, recaptchaVerifier)
}

export interface PhoneData {
  phoneNumber: string,
  countryCode: CountryCode
}

export async function initiatePhoneNumberRegistration(phone: PhoneData, recaptchaVerifier: firebase.auth.RecaptchaVerifier): Promise<string> {
    if (!phone.phoneNumber || !phone.phoneNumber.trim()) {
      throw new PhoneError(`The field is empty.`)
    }
    const phoneNumber = `+${getCountryCallingCode(phone.countryCode)}${phone.phoneNumber.trim()}`
    if (!isValidPhoneNumber(phoneNumber, phone.countryCode)) {
      throw new PhoneError(`The number "${phoneNumber}" is not a valid phone number.`)
    }
    const user = authStorage.getUser()
    const newToken = await getRefreshedUserToken()
    if (newToken) {
        user.refreshToken(newToken)
    }
    return await phoneAuthProvider.verifyPhoneNumber({
        phoneNumber: phoneNumber,
        session: await user.multiFactor.getSession(),
    }, recaptchaVerifier);
}

export async function completePhoneNumberRegistration(verificationId: string, otp: string) {
      const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      await authStorage.getUser().multiFactor.enroll(multiFactorAssertion, 'My personal phone number');
}

export async function sendVerificationEmail() {
    if (!auth.currentUser) {
        throw new LoginError('Try again later')
    }
    await auth.currentUser.sendEmailVerification();
}

export async function checkIsUserEmailVerified(): Promise<boolean> {
    if (!auth.currentUser) {
        throw new LoginError('Try again later')
    }
    await auth.currentUser.reload();
    if (auth.currentUser.emailVerified) {
      console.info("User email verified.");
      await onboard()
      analytics()?.reportIntakeFormInteractionStageReached(auth.currentUser);
      return true
    } else {
      console.info("User email not verified yet, reloading user.");
      // await auth.applyActionCode(oobCode) // TBD - Is there a need for this?
      await auth.currentUser.getIdToken(true);
      await auth.currentUser.reload();
      return false
    }
}

export class OnboardingPayload {
    private _tag: "OnboardingPayload" = "OnboardingPayload"
    constructor(readonly data: OnboardingData) {
        if (data.companyName.trim() === '') {
            throw new LoginError('Please fill the company name')
        }
        data.companyName = data.companyName.trim()
        data.companyDescr = data.companyDescr.trim()
        data.companyHardware = data.companyHardware.trim()
        data.companyWebsite = data.companyWebsite.trim()
        data.customerTypes = data.customerTypes.trim()
        data.refSource = data.refSource.trim()
    }
}

export async function onboard() {
    const user = authStorage.getUser()
    const refreshedToken = await getRefreshedUserToken()
    if (!refreshedToken) {
        throw new LoginError('Try again later')
    }
    user.refreshToken(refreshedToken)
    try {
        const company = await assignUserToDemoCompany(user.id, user.token)
        user.setUserCompany({ ...company, subscriptions: [] });
        authStorage.setDefaultCompany()
    } catch (error) {
        console.error(`Exception :: Occurred for ${user.email}. They could not create a company.`, error);
    }
}
