import {useSiteStoreContext} from "../../siteStoreLoader";
import {StoreQuery} from "../../../ps-models/lineitems-store";
import React, {useEffect, useState} from "react";
import { LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import { Header, Segment} from "semantic-ui-react";
import {formatRows, fromNestedList} from "../../../lineitems-store/TableFormat";
import {fromPairs, values} from "ramda";
import {GROUPING_LINE_ITEM_KEY, lineItemAggregatorMap, normalizeString} from "../../../ps-models";

export function CAPexAndROI() {
  const store = useSiteStoreContext();

  let mainRows = [
    'total_purchase_price__c',
    'discounted_purchase_price__c',
    'Current Value',
    'Days in Month',
    'current_value_post_dep__c',
    'original_asset_value__c',
    'months_active__c',
    'monthly_depreciated_value__c',
    'Investment Returns IRR',
    'Investment Returns MOIC',
    'Investment Returns Cash Return',
    'Investment Returns Rolling ARR',
  ].map(normalizeString);

  const capex = store.query(StoreQuery.byNames(mainRows)

      .or(StoreQuery.byField('salesforce.fieldName', 'Actual_Cost__c'))
      .or(StoreQuery.byField('salesforce.fieldName', 'Monthly_Depreciated_Value__c'))
      .or(StoreQuery.byField('salesforce.fieldName', 'Number_of_Dep_Months__c'))
      .or(StoreQuery.withField(GROUPING_LINE_ITEM_KEY))
      //We are asumming that everything in this table is monthly
      .monthly(lineItemAggregatorMap({"Days in Month": "sum"}, "last"))
  );

  let assetRows = fromPairs(values(capex.rows).filter((row:any) => !mainRows.includes(normalizeString(row.name.value)))
    .map((row: any) => [row.name.value, []]))

  let formattedRows = formatRows(
    fromNestedList(
      {
         "Assets": {},
        ...assetRows,
        ...fromPairs(mainRows.map(item => [item, []])),
      }
    ), capex, { rowsWithTotals: ['total_purchase_price__c'] });


  return <Segment basic>
    <Header as="h2" color="purple">CAPEX and ROI</Header>

    <LineItemsTableWithFormulas
      withGroups
      queryResult={formattedRows} store={store} />

  </Segment>
}
