import React from "react";
import {PlatformLayout} from "../PlatformLayout";
import {AMLogo} from "./index";
import {AssetsManagementMenu} from "./AssetsManagementMenu";
import {PopupProvider} from "../ui/popup/Popup";

export function Layout({children, contentWide}: { children: React.ReactNode, contentWide?: boolean }) {
  return <PlatformLayout topId={"AssetsManagement"} header={<AMLogo/>}
                         contentWide={contentWide}
                         leftFloatedActions={<AssetsManagementMenu/>}>
    <PopupProvider>
      <div className="AssetsManagement">
        {children}
      </div>
    </PopupProvider>
  </PlatformLayout>
}