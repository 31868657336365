import {LineItemColumn, LineItemRow, QueryResult, QueryResultData, ResultCell} from "../ps-models/lineitems-store";
import {normalizeString} from "../ps-models";
import {indexBy} from "ramda";

interface TableFormatDefinition {
  name: string,
  formatter?: (input: ResultCell) => string,
  items?: TableFormatDefinition[]
  rowFormatterType?: "header" | "lineItem" | "subtotal" | "total"
}

interface NestedListFormatDefinition {
  [key: string]: string[] | NestedListFormatDefinition
}

export function fromNestedList(nestedList: NestedListFormatDefinition): TableFormatDefinition[] {

  return Object.keys(nestedList).map((key) => {
    let value = nestedList[key];
    let formatterType;


    if (Array.isArray(value)) {
      return {
        name: key, formatterType, items: value.map((item) => {
          return {name: item}
        })
      }
    } else {
      return {name: key, formatterType, items: fromNestedList(value)}
    }
  })

}

interface FormatRowsOptions {
  rowsWithSubTotals: string[] //Itmes with the total line-item style
  rowsWithTotals: string[] //Itmes with the total line-item style
}

export function formatRows(formatDefinition: TableFormatDefinition[], results: QueryResult, options?: Partial<FormatRowsOptions>) {

  const newQueryResults: QueryResultData = {columns: results.columns, rows: []}

  const rowsIndexed = indexBy((row) => normalizeString(row.name.value.toString()), results.rows);

  const outputOptions: FormatRowsOptions = options === undefined ?
    {rowsWithSubTotals: [], rowsWithTotals: []} : {
        rowsWithSubTotals: options.rowsWithSubTotals?.map(normalizeString) ?? [],
        rowsWithTotals: options.rowsWithTotals?.map(normalizeString) ?? []
    }

  for (let rowDef of formatDefinition) {
    formatRowsRec(rowDef, rowsIndexed, new QueryResult(newQueryResults),  outputOptions)
  }

  return new QueryResult(newQueryResults);
}

function formatRowsRec(rowDef: TableFormatDefinition, rowsIndexed: Record<string, LineItemRow>, newQueryResults: QueryResult, options: FormatRowsOptions) {

  let defName = normalizeString(rowDef.name);

  if (!rowDef.formatter) {
    rowDef.formatter = (input) => input.text?.toString() || '';
  }
  if (!rowDef.items) {
    rowDef.items = [];
  }
  let notALineItem = !rowsIndexed[defName];

  let rowFormatterType = rowDef.rowFormatterType;
  if (options.rowsWithTotals.includes(defName)) {
    rowFormatterType = "total";
  } else if (options.rowsWithSubTotals.includes(defName)) {
    rowFormatterType = "subtotal";
  } else if ((!rowFormatterType && notALineItem) || (rowDef.items.length > 0)) {
    rowFormatterType = "header";
  } else {
    rowFormatterType = "lineItem";
  }

  const newRow: LineItemRow = {
    ...(rowsIndexed[defName] ?? {name: {value: rowDef.name, text: rowDef.name, type: "header"}})
  };
  newRow.path = rowsIndexed[defName]?.path || [defName];
  newRow.rowFormatterType = rowFormatterType;
  newQueryResults.rows.push(newRow)

  for (let def of rowDef.items) {
    formatRowsRec(def, rowsIndexed, newQueryResults, options)
  }

}