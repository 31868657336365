import React, {useState} from "react";
import {useCompanyId} from "../../../core";
import {PortfolioPerformance} from "./PortfolioPerformance";
import {SiteSelectWrapper} from "../../SiteSelectWrapper";
import {SiteDetail} from "./SiteDetail";
import {SitePerformance} from "./SitePerformance";
import {AEOvsWAMO} from "./AEOvsWAMO";
import {CashFlow} from "./CashFlow";
import {CAPexAndROI} from "./CAPexAndROI";
import {CustomerSaving} from "./CustomerSaving";
import {AggregateFinancials} from "./AggregateFinancials";
import {Dropdown, DropdownProps, Menu, Segment} from "semantic-ui-react";
import {Route, useHistory} from "react-router-dom";
import { CompanyLogo } from "./CompanyLogo";
import {CashFlowVariance} from "./CashFlowVariance";
import {LoanManagement} from "./LoanManagement";
import {MonthlyInvoicing} from "./MonthlyInvoicing";
import {SiteP50P90Reports} from "./SiteP50P90Reports";
import {SpvStructure} from "./SpvStructure";
import {MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING} from "../../index";

type Section = { title: string, key: string, path: string, render: () => JSX.Element, getPath: () => string, isHome?: boolean }

function useSections(): Record<string, Section> {
  const [selectedSite, setSelectedSite] = useState<string | undefined>(window.localStorage.getItem("selectedStore") || undefined);
  const companyId = useCompanyId();
  const handleSelectedSite = (site: string) => {
    setSelectedSite(site)
  }

  const BASE_PATH = `/ps/:companyId/assets-management/b`;

  const buildSection = (data: Omit<Section, "getPath">): Section => {
    return {...data, getPath: () => data.path.replace(":companyId", companyId)}
  }

  let sections = {
    "portfolio-performance": {
      ...buildSection({
        title: "Portfolio Performance",
        key: "portfolio-performance",
        path: BASE_PATH,
        render: () => <>
          <PortfolioPerformance goToSiteDetailTab={site => handleSelectedSite(site)}/>
        </>
      })
    },
    "site-detail": {
      ...buildSection({
        title: "Site Detail",
        key: "site-detail",
        path: `${BASE_PATH}/site-detail`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"]}>
            <SiteDetail/>
          </SiteSelectWrapper>
        </>
      })
    },
    "site-performance": {
      ...buildSection({
        title: "Site Performance",
        key: "site-performance",
        path: `${BASE_PATH}/site-performance`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"]}>
            <SitePerformance/>
          </SiteSelectWrapper>
        </>
      })
    },
    "site-p50-p90-reports": {
      ...buildSection({
        title: "Site P50/P90 Reports",
        key: "site-p50-p90-reports",
        path: `${BASE_PATH}/site-p50-p90-reports`,
        render: () => <>
            <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"]}>
                <SiteP50P90Reports/>
            </SiteSelectWrapper>
        </>
      })
    },
    "aeo-vs-wamo": {
      ...buildSection({
        title: "AEO vs WAMO",
        key: "aeo-vs-wamo",
        path: `${BASE_PATH}/aeo-vs-wamo`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"]}>
            <AEOvsWAMO/>
          </SiteSelectWrapper>
        </>
      })
    },
    "cashflow-statement": {
      ...buildSection({
        title: "Cash Flow Statement",
        key: "cashflow-statement",
        path: `${BASE_PATH}/cashflow-statement`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"]}>
            <CashFlow/>
          </SiteSelectWrapper>
        </>
      })
    },
    "cashflow-variance": {
      ...buildSection({
        title: "Cash Flow Variance",
        key: "cashflow-variance",
        path: `${BASE_PATH}/cashflow-variance`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"]}>
            <CashFlowVariance />
          </SiteSelectWrapper>
        </>
      })
    },
    "monthly-invoicing": {
      ...buildSection({
        title: "Monthly Invoicing",
        key: "monthly-invoicing",
        path: `${BASE_PATH}/monthly-invoicing`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"]}>
            <MonthlyInvoicing />
          </SiteSelectWrapper>
        </>
      })
    },
    "site-capex-roi": {
      ...buildSection({
        title: "Site CAPEX & ROI",
        key: "site-capex-roi",
        path: `${BASE_PATH}/site-capex-roi`,
        render: () => <>
          
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"]}>
            <CAPexAndROI/>
          </SiteSelectWrapper>
        </>
      })
    },
    "customer-savings": {
      ...buildSection({
        title: "Customer Savings",
        key: "customer-savings",
        path: `${BASE_PATH}/customer-savings`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"]}>
            <CustomerSaving/>
          </SiteSelectWrapper>
        </>
      })
    },
    "aggregate-financials": {
      ...buildSection({
        title: "Aggregate Financials",
        key: "aggregate-financials",
        path: `${BASE_PATH}/aggregate-financials`,
        render: () => <>
          <AggregateFinancials/>
        </>
      })
    },
    "loan-management": {
      ...buildSection({
        title: "Loan Management",
        key: "loan-management",
        path: `${BASE_PATH}/loan-management`,
        render: () => <>
          <LoanManagement />
        </>
      })
    },
    "spv-structure": buildSection({
      title: "SPV Structure",
      key: "spv-structure",
      path: `${BASE_PATH}/spv-structure`,
      render: () => <><SpvStructure/></>
    })
  };

  return sections;
}

function MainContent() {
  const sections = useSections();

  return <Segment>
    {Object.values(sections).map(section =>
      <Route exact  path={section.path}  key={section.key} render={section.render} />
    )}
  </Segment>
}

function MainMenu() {
  const sections = useSections();
  return <Menu compact>

        <Dropdown text={'Portfolio Performance'} item simple>
          <Dropdown.Menu>
            {["portfolio-performance", 'spv-structure',"aggregate-financials", "loan-management"]
              .map(section => <SectionItem className={'nav-header-item'} section={sections[section]} as={Dropdown.Item} />)}
          </Dropdown.Menu>
        </Dropdown>



    <Dropdown text={'Site Operations'} item simple>
          <Dropdown.Menu>
            {["site-detail", "site-performance", "aeo-vs-wamo", "customer-savings"]
                .map(section => <SectionItem className={'nav-header-item'} section={sections[section]} as={Dropdown.Item} />)}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown text='Site Financials' item simple>
          <Dropdown.Menu>
            {["site-capex-roi", "cashflow-statement", "cashflow-variance", "monthly-invoicing"]
                .map(section => <SectionItem className={'nav-header-item'} section={sections[section]} as={Dropdown.Item} />)}
          </Dropdown.Menu>
        </Dropdown>
        {/*{["aggregate-financials", "loan-management"]*/}
        {/*    .map(section => <SectionItem className={'nav-header-item'} section={sections[section]} as={Menu.Item} />)}*/}
      </Menu>
}

function SectionItem({section, as, ...rest}: {section: Section, as: React.ElementType, [_: string]: any}) {
  const history = useHistory();
  const Item = as;
  return <Item {...rest} onClick={() => history.push(section.getPath())}>{section.title}</Item>
}
const Name = "DEMO" as const;
export default  {
  MainContent, MainMenu, CompanyLogo, Name
}