import {Accordion, Icon} from "semantic-ui-react";
import React, {useState} from "react";

export function PublicKeyHelpComponent() {
  let [active, setActive] = useState(true);

  return <Accordion fluid styled>
    <Accordion.Title
      active={active} onClick={()=> setActive(!active)}>
      <Icon name='help' /> How to create your API public/private key
    </Accordion.Title>
    <Accordion.Content  active={active}>
      <p>Follow the following instructions to create the public/private keys to communicate with the device-data
        API:</p>
      <h5>Option 1: Easy way:</h5>
      <p>
        Go to: <a href="https://cryptotools.net/rsagen "> https://cryptotools.net/rsagen </a> and store the two
        generated files.
      </p>
      <h5>Option 2: With Linux/Mac Terminal</h5>
      <p>
        Generates private and public key
        <pre className="code-block">
                ssh-keygen -t rsa -b 1024 -m PEM -f /some/dir/yourPrivateKey
            </pre>

        Converts the public key to the PEM format:
        <pre className="code-block">
            openssl rsa -in /some/dir/yourPrivateKey -pubout -outform PEM -out /some/dir/yourPublicKey.pem
              </pre>
      </p>
      <hr/>
      For more detailed instructions on how to communicate with the API go to: <a
      href="https://bit.ly/3xvfAKK">https://bit.ly/3xvfAKK </a>

    </Accordion.Content>
  </Accordion>;
}