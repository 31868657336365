import {Button, Modal} from "semantic-ui-react";
import React from "react";

export function ConfirmDiscardChangesPopup({onClose, onDiscard}: {onClose: () => void, onDiscard: () => void | Promise<void>}) {
    return <Modal
      open={true}
      onClose={onClose}
      size="tiny"
    >
        <Modal.Header>Confirm Close & Discard Changes</Modal.Header>
        <Modal.Content>
        <p>Do you want to discard the current changes?</p>
        </Modal.Content>
        <Modal.Actions>
            <Button color="green" onClick={async () => {
              onClose()
              await onDiscard()
            }}>
              Discard
            </Button>
            <Button color="blue" onClick={() => onClose()}>
              Cancel
            </Button>
        </Modal.Actions>
    </Modal>
}