import {BuilderContext, getWidgetType} from "./WidgetRegistry";
import {renderWidgetConfig} from "./WidgetConfigWrapper";

export class WidgetConfigBase {
  constructor(
    public readonly id: string, public readonly type: string,
    public config: any = {},
    public machineName: string = id
  ) {
  }

  private outputs: Record<string, any> = {};

  getOutputs() {
    return this.outputs;
  }

  setOutput(key: string, value: any) {
    this.outputs[key] = value;
  }

  preRender(context: BuilderContext) {
    const entry = getWidgetType(this.type);
    if(entry?.preRender) {
      entry.preRender(this.buildWidgetContext(context));
    }
  }


  render(config: any, context: BuilderContext) {
    return getWidgetType(this.type).render(config, this.buildWidgetContext(context), (key: string, value: any) => {
      this.setOutput(key, value);
    });
  }

  buildWidgetContext(context: BuilderContext) {
    return {
      ...context,
      id: this.id,
      type: this.type,
      machineName: this.machineName,
      params: context.params || {},
      allParams: context.params || {}
    }
  }

  setConfig(config: any) {
    this.config = config;
  }

  serialize() {
    return {
      id: this.id,
      type: this.type,
      config: this.config,
      machineName: this.machineName
    }
  }

  public static deserialize(data: any) {
    return new WidgetConfigBase(
        data.id,
        data.type,
        data.config,
        data.machineName || data.id
    );
  }
}

export class WidgetConfig extends WidgetConfigBase {
  constructor(
    public readonly id: string, public readonly type: string,
    public config: any = {},
    public parent: string,
    public machineName: string = id
  ) {
    super(id, type, config, machineName);
  }

  renderConfig(context: BuilderContext,
               ready: (config: any) => void) {
    return renderWidgetConfig(this, context, ready);
  }

  mergeConfig(config: any) {
    this.config = {...this.config, ...config};
    console.info('merged Config', this.config);
  }

  setParentId(widgetId: string) {
    this.parent = widgetId;
  }

  serialize() {
    return {
      id: this.id,
      type: this.type,
      config: this.config,
      parent: this.parent,
      machineName: this.machineName
    }
  }

  public static deserialize(data: any) {
    return new WidgetConfig(
      data.id,
      data.type,
      data.config,
      data.parent,
      data.machineName || data.id
    );
  }

}