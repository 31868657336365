import {ReactNode, useEffect, useRef} from 'react';

function GenericNestedInputWrapper<T>({
                                          children,
                                          onBlur,
                                      }: {
    children: ReactNode,
    onBlur: () => void;
}) {
    const divRef = useRef<HTMLDivElement | null>(null);

    const handleDocumentClick = (event: MouseEvent) => {
        if (divRef.current && event.target && !divRef.current.contains(event.target as Node)) {
            onBlur();
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick, true);

        return () => {
            document.removeEventListener('click', handleDocumentClick, true);
        };
    }, []);


    return (
        <div
            ref={divRef}
            style={{display: 'inline-block'}}
        >
            {children}
        </div>
    );
}

export default GenericNestedInputWrapper;
