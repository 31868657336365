import React from 'react';
import {Header} from 'semantic-ui-react';

const INITIAL_STATE = {
  email: '',
  firstName: '',
  lastName: '',
  message: ''
}

class Contact extends React.Component { 
  state = INITIAL_STATE;

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <>
        <Header as="h1" className="hero-title">Contact Us</Header>
        <p>You can always reach out to us directly at <a href="mailto:team@perlstreet.com">team@perlstreet.com</a></p>
      </>
    );
  }
}

export default Contact;
