import { createSelector } from 'reselect';

const selectCompaniesState = state => state.companies;

export const selectCurrentCompanies = createSelector(
  [selectCompaniesState],
  (companies) => companies.companies
);

export const selectCompaniesStatus = createSelector(
  [selectCompaniesState],
  (companies) => companies.status
);

export const selectCompaniesError = createSelector(
  [selectCompaniesState],
  (companies) => companies.error
);

export const selectCompanyById = (companies, companyId) => {
  const companyData = companies.filter(company => company.id === companyId);
  if (companyData.length === 0) return null;
  return companyData[0];
}