import {Table} from "semantic-ui-react";
import React from "react";

export type PairTableRow = { left: string | JSX.Element, right: string | JSX.Element }
export type PairTableProps = {
  pairs: Record<string, PairTableRow>;
  description?: string | JSX.Element
  header?: PairTableRow;
};

export function PairTable({ pairs, description, header }: PairTableProps) {
  return (
    <div>
      {description}
      {description && <br />}
      <Table basic='very' celled collapsing style={{ width: "100%" }}>
          {header && <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{header.left}</Table.HeaderCell>
            <Table.HeaderCell>{header.right}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>}
        <Table.Body>
          {Object.entries(pairs).map(function([key, { left, right }]) {
            return (
              <Table.Row key={key}>
                <Table.Cell>{left}</Table.Cell>
                <Table.Cell>{right}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
