import {getUsers} from "../../user/user.client";
import {MessagePayloadV2} from "../../ps-types";
import {RegistrationError, SignUpPayload} from "../../auth";
import {createUser, updateUser} from "../admin.client";
import {assignUserToCompany} from "../../company/company.client";
import {AdminConfigurableUserData, SignUpUserData, UserStatuses} from "../../ps-models";
import {buildEntityDef, EntityCrud, EntityPropDef} from "../../ui/crud/EntityCrud.component";
import React from "react";
import {formatDistance} from "date-fns";
import {UpdateEntity} from "../../ui/crud/UpdateEntity.component";
import {buildForm} from "../../ui/crud/common/Field";
import {map} from "ramda";
import {EntityDef, FieldDef} from "../../ui/crud/types";

function timeAgo(value: string) {
  return value ? formatDistance(new Date(value), new Date(), {addSuffix: true}) : "";
}

export function UserManagement(){
  const getEntities = () => getUsers();
  const createEntity =  async (data: any) => {
    if(!data.email || !data.password){
      throw Error('Not all data was provided')
    }
    const {welcomeEmailConfig, ...signUpData} = data;
    const emailConfig = welcomeEmailConfig as Omit<MessagePayloadV2, 'attachments'> & {attachments?: FileList};
    // @TODO: All of this code needs to be done nicely and error handling needs to be added to the create entity component itself...
    let signUpPayload = new SignUpPayload(signUpData as any);
    let susbstitutionsOOB = { firstName : signUpPayload.firstName, lastName: signUpPayload.lastName, email: signUpPayload.email, password: signUpPayload.password }
    emailConfig.substitutions = {...susbstitutionsOOB, ...JSON.parse(welcomeEmailConfig.substitutions ?? "{}")}
    emailConfig.message = {
      text: stripHtmlTags(welcomeEmailConfig.message ?? ""),
      html: welcomeEmailConfig.message
    }
    emailConfig.toEmail = data.email;

    const user = await createUser(signUpPayload, emailConfig);
    if(data.companyId){
      const company = await assignUserToCompany(data.companyId, user.id);
      user.companies?.push(company);
    }
    return user;
  }

  const updateEntity = async (entity: any) => {
    if(!entity.email || !entity.id || !entity.firstName || !entity.lastName || !entity.lastName){
      throw Error('Not all data was provided')
    }
    if (entity.password && (entity.password !== entity.passwordAgain.trim())) {
      throw new RegistrationError("Password does not match.", "custom/failed-password-verification")
    }
    let userUpdatePayload: Omit<SignUpUserData, 'isNewUser' | 'providerId'> = {
      profile: {
        email: entity.email.trim(),
        firstName: entity.firstName.trim(),
        lastName: entity.lastName.trim(),
        displayName: `${entity.firstName.trim()} ${entity.lastName.trim()}`,
      },
      status: entity.status,
    }
    console.info("WHAT DO WE GET TO UPDATE HERE???", entity.id);
    const user = await updateUser(entity.id, userUpdatePayload, entity.password);
    if(entity.companyId){
      const company = await assignUserToCompany(entity.companyId, entity.id);
      user.companies?.push(company);
    }
    return user;
  }

  function UpdateBtn({entityName, entity, handleUpdate, entityDef}: {entityName: string, entity: any, handleUpdate: (entity: any) => void, entityDef: EntityDef}) {
    const formDef = buildForm(
        // @ts-ignore
        { ...entityDef, ...(entityDef?.create ?? {}), ...(entityDef?.update ?? {})},
        map((p) => ({
          key: p.key,
          label: p.title,
          type: "text",
          ...p.create,
          ...p.update
        } as FieldDef), entityDef.props)
    );
    const relevantEntity = {
      ...entity,
      password: null,
      passwordAgain: null,
      companyId: null,
    }
    return <UpdateEntity
        {...formDef}
        entityName={entityName}
        entity={relevantEntity}
        updateEntity={updateEntity}
        onEntityUpdated={()=>handleUpdate(relevantEntity)}
    />
  }

  const entityDefProps:Record<{password: string, passwordAgain: string} & keyof AdminConfigurableUserData
      , Partial<EntityPropDef>> = {
    firstName: {
      table: {
        type: 'hidden',
      },
      create: {
        label: "First Name",
        type: 'text',
      },
    },
    lastName: {
      table: {
        type: 'hidden',
      },
      create: {
        label: "Last Name",
        type: 'text',
      },
    },
    email: {
      table: {},
      create: {
        label: "Email",
        type: 'email',
      },
      update: {
        type: 'email',
        readOnly: true
      }
    },
    password: {
      table: {
        type: 'hidden',
      },
      create: {
        label: "Password",
        type: 'password',
      },
    },
    passwordAgain: {
      table: {
        type: 'hidden',
      },
      create: {
        label: "Confirm Password",
        type: 'text',
        placeholder: 'Confirm password'
      },
    },
    "welcomeEmailConfig.fromEmail": {
      table: {
        type: 'hidden',
      },
      create: {
        label: "From Email",
        type: 'email',
        placeHolder: "Configure sender email"
      },
      update: { type: "hidden" }
    },
    "welcomeEmailConfig.replyTo": {
      table: {
        type: 'hidden',
      },
      create: {
        label: "Reply To",
        type: 'text',
        placeHolder: 'Configure email to reply back at a.k.a. replyTo'
      },
      update: { type: "hidden" }
    },
    "welcomeEmailConfig.emailSubject": {
      table: {
        type: 'hidden',
      },
      create: {
        label: "Email Subject",
        type: 'text',
        placeHolder: 'Configure email subject'
      },
      update: { type: "hidden" }
    },
    "welcomeEmailConfig.message": {
      table: {
        type: 'hidden',
      },
      create: {
        label: "Email Message",
        type: 'html',
        defaultValue: '<p>Hi<strong> {{firstName}} {{lastName}}</strong>,</p><p><br></p><p>Greetings! Welcome to PerlStreet. Please find your password below:</p><p>{{password}}</p><p><br></p><p>Explore endless possibilities at <a href=\"perlstreet.com\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"color: rgb(220, 161, 13);\">perlstreet.com</a></p><p><br></p><p>Regards,</p><p>Nishant.</p>',
        placeHolder: 'Configure email message'
      },
      update: { type: "hidden" }
    },
    "welcomeEmailConfig.attachments": {
      table: {
        type: 'hidden',
      },
      create: {
        label: "Email Attachments",
        type: 'multiple-files',
        placeHolder: 'Configure email attachments',
      },
      update: { type: "hidden" }
    },
    "welcomeEmailConfig.substitutions": {
      table: {
        type: "hidden",
      },
      create: {
        label: "Substitutions",
        type: 'text',
        placeHolder: 'Place a stringified JSON with actual values for {{placeholders}} in email. These are extraneous.'
      },
      update: { type: "hidden" },
    },
    companyId: {
      table: {
        type: 'hidden',
      },
      create: {
        label: "Company Id",
        type: 'text',
      },
    },
    status: {
      table: {
        type: 'hidden',
      },
      create: {type: 'hidden'},
      update: {
        label: "Status",
        type: 'select',
        options: UserStatuses.map((status)=>({key: status, value: status, text: status}))
      }
    },
    createdAt: {
      title: "CREATED",
      create: { type: "hidden" },
      update: { type: "hidden" },
      table: { format: timeAgo }
    }
  }

  let def = buildEntityDef(
      {
        entityName: "User",
        title: "Platform Users",
        createEntity,
        getEntities,
        updateEntity,
        table: {
          updateButton: (props )=> (<UpdateBtn {...props}/>)
        }
      },
      entityDefProps
  );

  return <div>
    <EntityCrud entityDef={def} />
  </div>
}

const stripHtmlTags = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};