import {useLineItemsStoreMetadata, usePersistenceStoreQueryWithContextUpdate} from "../../../lineitems-store/LineItemsStore.hook";
import {
  CANONICAL_NAME_FIELD,
  DEFAULT_SOURCE_GRID,
  LineItemsStore,
  pQuery,
  QueryResult,
  StoreQuery
} from "../../../ps-models/lineitems-store";
import {Container, Grid, Header, Icon, Segment} from "semantic-ui-react";
import React from "react";

import {setLabelAndFormatForAggregates} from "./storeFormulas";

import {LoadingBlock} from "../../../ui/Loading";
import {LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {utcDate} from "../../../ps-models";
import {CategoryChart} from "../../../lineitems-store/CategoryChart";
import {authStorage} from "../../../auth";
import {getAmProjectConfig} from "../../../ps-models";

export function KelvinSystemsCashflowRollup({projectStores}: {projectStores: string[]}) {

  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);

  let sourceMetadata = [
   'versionId', 'location', 'property'
  ]
  const store = usePersistenceStoreQueryWithContextUpdate(collection,
    pQuery()
      .selectSourceParams(sourceMetadata)
      .withLineItems(['Total Cash Return'])
      // .havingParameterValues({"dsType": "site"})
      .selectLineItemsHavingFieldValues({
        'group': ['Cash Flow'],
        'secondaryGroup': ['Cash Flow']
      })
      .withGranularity('years')
      .withStoreIds(projectStores)
    ,
    (store) => {
      store.getDataSet().addTimedGroupingLineItemsByField(CANONICAL_NAME_FIELD);
     // setLabelAndFormatForAggregates(store)
    });

  let isLoading = !store;

  return <>
    {store && <CashflowRollUpView store={store} />}
    {isLoading && <LoadingBlock  />}
  </>
}

export function CashflowRollUpView({store}: { store: LineItemsStore }) {

  let table = QueryResult.emptyTable();

  /*
  This is a workaround because we don't have a good component to show Parameter Line Items
   */
  let timeIndex = store.timeIndex
    .withGranularity('years');

  table.addSection(
    store.query(StoreQuery
      .byField("group", "Cash Flow")
      .or(StoreQuery.byField("secondaryGroup", "Cash Flow"))
    .withTimeIndex(timeIndex)),"Cash Flow"
  );

  table.setFormatting('Total', 'header')

  let cashReturn = store.query(
    StoreQuery.byNames(['Total Cash Return'], true)
      .withTimeIndex(
        timeIndex.withDates(timeIndex.startDate, timeIndex.startDate)
      ));

  cashReturn.setFormatting('Total Cash Return', 'header');

  return <>
    <Container>
      <Header as="h2" color="purple">{"Kelvin Systems Cashflow Rollup"}</Header>
      <LineItemsTableWithFormulas
        hideToolbar
        queryResult={table} store={store}
        hideFooter
        withGroups  />

      <p></p>
      <LineItemsTableWithFormulas
        hideToolbar
        hideHeaders
        queryResult={cashReturn}
        store={store}
        hideFooter
        withGroups  />

      <CategoryChart
        title=""
        fieldName={"store_sourcename"}
        result={store.query(
          StoreQuery.byNames(['Total Cash Return'], true)
        )}
      />

    </Container>

  </>
}


