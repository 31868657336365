import {NavLink} from "react-router-dom";
import {DeleteButton} from "../../../ui/crud/DeleteButton.component";
import {buildEntityDef, EntityCrud} from "../../../ui/crud/EntityCrud.component";
import React from "react";
import {Container, Segment} from "semantic-ui-react";
import {useCompanyId} from "../../../core";
import {getLoans} from "./loans.client";
import {formatValueWithValueType} from "../../../ps-models";
import {ValueType} from "../../../ps-models/line-items";
import {SendToTurnKeyButton} from "./SendToTurnKeyButton";

export function LoanLink({loan}: {loan: any}) {
  const companyId = useCompanyId();

  return <NavLink to={() => `/ps/${companyId}/ext/projects/${loan.id}` }>{loan.name}</NavLink>
}

export function Loans() {

  const companyId = useCompanyId();

  const getEntities = () =>  getLoans(companyId);

  const createEntity =  async () => {
  }


  const deleteEntity = async (scenario: any) => {}

  let def = buildEntityDef(
    {
      entityName: "Loans",
      title: "My Loans",
      getEntities,
      deleteEntity,
      table: {
        tableComponent: 'dataGrid',
      }
    },
    {
      name: {
        title: "Loan Name",
        table: {
          render: (_value: string, loan) => <LoanLink loan={loan}  />
        }
      },
      projectCount: {},
        amount: {},
        interestRate: {
          table: {
              format: (value) => formatValueWithValueType(value as ValueType, 'percentX100')
          },
        },
      borrower: {},
    }
  );



  return <Segment>
    <SendToTurnKeyButton text="Add Loans" />
    <EntityCrud entityDef={def} />
  </Segment>
}