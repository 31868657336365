import React, {useEffect, useMemo, useState} from "react";
import {DIFFERENCES_CONSTANT, SiteLineItem} from "./lineItemBatchOperations";
import {Popup, Segment, Table} from "semantic-ui-react";
import {CodeEditorWithTooltip} from "../../ui/InputWithTooltip";
import {PairTable, PairTableRow} from "../../ui/PairTable";

export interface FormulaEditorState {
    formula: string;
    canSave: boolean;
    modified: boolean;
}

type GroupId = string

export type FormulaDifferences = Record<GroupId, PairTableRow>

interface Props {
    initialFormula: string;
    differences: FormulaDifferences;
    onStateChanged: (state: FormulaEditorState) => void;
    lineItemName: string;
    siteCalculatedLineItems: SiteLineItem[];
}

export function makeInitialFormulaEditorState(): FormulaEditorState {
    return { canSave: false, modified: false, formula: "" }
}

export function BatchFormulaEditor(
    {
        initialFormula, differences,
        onStateChanged,
        lineItemName, siteCalculatedLineItems
    }: Props
) {
    const [formula, setFormula] = useState(() => initialFormula);

    const messages = useMemo(() => {
        if(!formula){
            return {"formula": "Required"}
        }
        return {};
    }, [formula])

    const modified = useMemo(() => initialFormula !== formula, [formula, initialFormula])

    const canSave = useMemo(() => {
        const emptyFormula = formula.length === 0
        const isFormulaValid = Object.keys(messages).length === 0
        return !emptyFormula && modified && isFormulaValid;
    }, [formula, messages, modified])

    useEffect(() => {
        onStateChanged({formula, canSave, modified})
    }, [onStateChanged, formula, canSave, modified])

    function handleChange(value: string) {
        if (value.startsWith(DIFFERENCES_CONSTANT) && value.length > DIFFERENCES_CONSTANT.length) {
            value = value.slice(DIFFERENCES_CONSTANT.length)
        }
        setFormula(value);
    }

    return <div>
        <Segment>
        <FormulaEditorHeaderSection
            lineItemName={lineItemName}
            siteCalculatedLineItems={siteCalculatedLineItems}
        />
        <Table basic='very' celled collapsing style={{width: "100%"}}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell><h5>Formula</h5></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <CodeEditorWithTooltip
                            tooltipCondition={differences && Object.keys(differences).length > 0}
                            tooltipContent={()=> <PairTable pairs={differences} description={"This item has different formulas in different sites"} header={{left: "Site", right: "Value"}} />}
                            style={{width: "100%"}}
                            size="mini"
                            valueChanged={formula !== undefined && formula !== initialFormula}
                            onChange={(e: any) => handleChange(e.target.value)}
                            onRestore={() => handleChange(initialFormula || "")}
                            value={formula}
                            error={!!(messages["formula"])}
                        ></CodeEditorWithTooltip>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </Segment></div>
}

function FormulaEditorHeaderSection({ lineItemName, siteCalculatedLineItems }: {    lineItemName: string;
    siteCalculatedLineItems: SiteLineItem[];}): JSX.Element {
    return (
        <Segment>
            <strong>Name</strong> "{lineItemName}" is a calculated item <strong>in</strong> {siteCalculatedLineItems.length} sites
            <Popup trigger={<u>(*)</u>}>
                {siteCalculatedLineItems.map(({id, label}) => <div key={id}>{label} ({id})</div>)}
            </Popup>
        </Segment>
    );
}