import {filter, values} from "ramda";
import {StoreQuery} from "../StoreQuery";
import {TimeUnitView} from "../../Time.model";
import {LineItemsStore} from "../LineItemsStore";
import {normalizeString} from "../../line-item-utils/coding.utils";
import {QueryResult} from "../QueryResult";
import {GROUPING_PARENT_REFERENCE_KEY} from "../../LineItemDataSet";
import {LineItemsStoreExecution} from "../LineItemsStoreExecution.model";
import {LineItem} from "../../line-items";
import {StoreQueryFlat} from "../StoreQueryFlat";
import {ResultOptions} from "./QueryResultOptions";
import {TimeSeriesTableBuilder} from "./TimeSeriesTable.builder";
import {GridTableBuilder} from "./GridTable.builder";
import {AggregateTableBuilder} from "./AggregateTable.builder";
import {StreamTimeSeriesTableBuilder} from "./StreamTimeSeriesTable.builder";


export class QueryRunner {
  constructor(private store: LineItemsStore, private dataSet = store.getDataSet()) {
  }

  filteredLineItems: LineItem[] = [];

  execution?: LineItemsStoreExecution;
  query?: StoreQuery;

  columnIndex: Record<string, number> = {};


  getLineItems(query: StoreQuery, options: ResultOptions = {}) {
    let allLineItems = this.dataSet.getLineItems();
    let lineItems = filter(li => query.filter(li)(this.dataSet), allLineItems);

    
    if(options.forceParentInclude) {
      let parentsOfLineItems = new Set<string>();

      for (let li of values(lineItems)) {
        let parent =  this.execution!.getFieldStr(li.name, GROUPING_PARENT_REFERENCE_KEY);
        if (parent) {
          parentsOfLineItems.add(normalizeString(parent));
        }
      }

      //Include parents of line items
      for (let lif of parentsOfLineItems) {
        let li = this.dataSet.getLineItem(lif);
        if (li) {
          lineItems[lif] = li;
        }
      }
    }

    return lineItems;
  }

  sortedLineItems(lineItems: Record<string, LineItem>, options: ResultOptions = {}) {
  
    let liIndex: Record<string, number> = {};

    let sortingNames = options.sortedNames || options.sourceQuery?.lineItems || [];

    for(let i = 0; i < sortingNames.length; i++) {
      let liName = normalizeString(sortingNames[i]);
      liIndex[liName] = i;
    }


    let sorted = values(lineItems).sort((a, b) => {

      //A name is the name or the cname
      let aIndex = liIndex[normalizeString(a.canonicalName)];
      let bIndex = liIndex[normalizeString(b.canonicalName)];

      if(aIndex !== undefined && bIndex !== undefined) {
        return aIndex - bIndex;
      }

      return 0;
    });

    return sorted;

  }

  getTimeIndex() {
    return this.query?.timeIndex || this.store.timeIndex;
  }

  timeLine: TimeUnitView[] = [];

  execute(query: StoreQuery | StoreQueryFlat, options: ResultOptions = {}): QueryResult {
    options = {...options};
    if(options.forceParentInclude === undefined) {
      options.forceParentInclude = true;
    }

    let store = this.store;
    if(query instanceof StoreQueryFlat){
      query = query.toStoreQuery();
    }
    this.query = query;
    this.execution = store.getExecution();

    this.columnIndex = {};
    this.filteredLineItems = this.sortedLineItems(this.getLineItems(query, options), options);

    let {from, to} = query.timeRange ? query.timeRange : {from: this.getTimeIndex().startDate, to: this.getTimeIndex().endDate};

    this.timeLine = this.getTimeIndex().timeLine.filter(time => time.date.getTime() >= from.getTime() && time.date.getTime() <= to.getTime());

    if(options.grid) {
      let gridBuilder = new GridTableBuilder(store, query, this.execution, options, this.getTimeIndex(), this.timeLine, this.filteredLineItems);
      return new QueryResult(gridBuilder.buildTable());
    } else if (options.aggregatedTable) {
      let aggregatedTableBuilder = new AggregateTableBuilder(store, query, this.execution, options, this.getTimeIndex(), this.timeLine, this.filteredLineItems);
      return new QueryResult(aggregatedTableBuilder.buildTable());
    }
    else if (options.streamTable) {
      let tableBuilder = new StreamTimeSeriesTableBuilder(store, query, this.execution, options, this.getTimeIndex(), this.timeLine, this.filteredLineItems);
      return new QueryResult(tableBuilder.buildTable());

    } else {
      let tableBuilder = new TimeSeriesTableBuilder(store, query, this.execution, options, this.getTimeIndex(), this.timeLine, this.filteredLineItems);
      return new QueryResult(tableBuilder.buildTable());
    }
  }


}


