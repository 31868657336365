import { takeLatest, put, all, call } from "redux-saga/effects";

import UserActionTypes from "./user.types";

import {
  createInitialUserProfileSuccess,
  createInitialUserProfileFailure,
  completeOnboardingStepSuccess,
  completeOnboardingStepFailure,
} from "./user.actions";

import { auth } from "../../firebase/firebase.core";
import {
  updateUserAttributes,
} from "../../firebase/firebase.utils";
import { startAddCompany } from "../companies/companies.actions";
import {analytics} from "../../platform/analytics/AnalyticsFacade";


export function* createInitialUserProfile({
  payload: { currentUser, firstName, lastName, phoneNumber },
}) {
  try {
    yield updateUserAttributes(currentUser, {
      firstName,
      lastName,
      phoneNumber,
    });
    yield put(
      createInitialUserProfileSuccess({ firstName, lastName, phoneNumber })
    );
  } catch (error) {
    console.error("Failed to create initial user profile", error);
    yield put(createInitialUserProfileFailure(error));
  }
}

export function* onCreateInitialUserProfileStart() {
  yield takeLatest(
    UserActionTypes.CREATE_INITIAL_USERPROFILE_START,
    createInitialUserProfile
  );
}

export function* completeOnboardingStepStart({
  payload: { stepId, stepValues },
}) {
  try {
    const updateObject = { ...stepValues, [stepId]: true };
    yield updateUserAttributes(auth.currentUser, updateObject);
    yield put(completeOnboardingStepSuccess(updateObject));
  } catch (error) {
    console.error(
      `Failed to complete the onboarding step with stepId ${stepId}`,
      error
    );
    yield put(completeOnboardingStepFailure(error));
  }
}

export function* onCompleteOnboardingStepStart() {
  yield takeLatest(
    UserActionTypes.COMPLETE_ONBOARDING_STEP_START,
    completeOnboardingStepStart
  );
}

export function* completeUserIntake({ payload }) {
  const { currentUser, intakeData } = payload;
  try {
    // Build company document and prefill some onboarding data
    const companyData = {
      name: intakeData.companyName,
      sortTag: "lead",
      intake: intakeData,
      allInvites: [{ email: auth.currentUser.email, role: "admin" }],
    };

    yield put(startAddCompany(companyData));
    analytics()?.reportFirstIntakeFormSubmission(currentUser);
  } catch (error) {
    console.error("Failed to update the user", error);
  }
}

export function* onCompleteUserIntake() {
  yield takeLatest(UserActionTypes.COMPLETE_USER_INTAKE, completeUserIntake);
}

export function* userSagas() {
  yield all([
    call(onCreateInitialUserProfileStart),
    call(onCompleteOnboardingStepStart),
    call(onCompleteUserIntake),
  ]);
}
