import {useSiteStoreContext} from "../siteStoreLoader";
import {usePersistenceStoreQueryWithContextUpdate} from "../../lineitems-store/LineItemsStore.hook";
import {pQuery, StoreQuery} from "../../ps-models/lineitems-store";
import {Button, Segment} from "semantic-ui-react";
import React from "react";
import {ClosableSection} from "../../ClosableSection";
import {StoreInfoEditor} from "./StoreInfoEditor";

export function StoreInfo({}) {
  const store = useSiteStoreContext();

  return <div>
    <h5>Dataset Info</h5>
    <div><strong>Name: </strong>{store.getName()}</div>
    <div><strong>Id: </strong> {store.getId()}</div>
    <div><strong>Type: </strong> {store.isTemplate ? "Template" : "Document"}</div>
    {!store.isTemplate &&
        <div><strong>Imports: </strong>{store.getImports().map(i => <div><strong></strong>{i}</div>)}</div>}
  </div>
}