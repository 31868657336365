import UserActionTypes from "./user.types";


export const createInitialUserProfileStart = ({
  currentUser,
  firstName,
  lastName,
  phoneNumber,
}) => ({
  type: UserActionTypes.CREATE_INITIAL_USERPROFILE_START,
  payload: {
    currentUser,
    firstName,
    lastName,
    phoneNumber,
  },
});

export const createInitialUserProfileSuccess = ({
  firstName,
  lastName,
  phoneNumber,
}) => ({
  type: UserActionTypes.CREATE_INITIAL_USERPROFILE_SUCCESS,
  payload: {
    firstName,
    lastName,
    phoneNumber,
  },
});

export const createInitialUserProfileFailure = (error) => ({
  type: UserActionTypes.CREATE_INITIAL_USERPROFILE_FAILURE,
  payload: error,
});

export const completeOnboardingStepStart = ({ stepId, stepValues }) => ({
  type: UserActionTypes.COMPLETE_ONBOARDING_STEP_START,
  payload: { stepId, stepValues },
});

export const completeOnboardingStepSuccess = (updateObject) => ({
  type: UserActionTypes.COMPLETE_ONBOARDING_STEP_SUCCESS,
  payload: updateObject,
});

export const completeOnboardingStepFailure = (error) => ({
  type: UserActionTypes.COMPLETE_ONBOARDING_STEP_FAILURE,
  payload: error,
});


export const intakeFormStart = (currentUser, onboardingData) => ({
  type: UserActionTypes.INTAKE_FORM_START,
  payload: {
    currentUser,
    onboardingData,
  },
});

export const updateUserIntake = (currentUser, intakeData) => ({
  type: UserActionTypes.UPDATE_USER_INTAKE,
  payload: {
    currentUser,
    intakeData,
  },
});

export const completeUserIntake = (currentUser, intakeData) => ({
  type: UserActionTypes.COMPLETE_USER_INTAKE,
  payload: {
    currentUser,
    intakeData,
  },
});

