import {TimeDefinition} from "./TimeDefinition";
import {LineItem} from "./LineItem.model";
import {LineItemValue, ValueType} from "./LineItemValue.model";
import {TimeRange, TimeUnit, TimeUnits} from "../Time.types";
import {PartialExecution} from "../lineitems-store/PartialExecution";
import {truncateTime} from "../TimeGranularity";
import {AggregatorMethod} from "../line-item-utils/aggregators";


export abstract class TimedLineItem  extends LineItem {
  abstract getTimeDefinition(): TimeDefinition;
  abstract withDefinition(timeDefinition: TimeDefinition) : TimedLineItem;

  getByDate(dateQuery: Date | number, report: PartialExecution, granularity?: TimeUnits): LineItemValue {
    let date = typeof dateQuery === "number" ? new Date(dateQuery) : dateQuery;
    return this.getValue(truncateTime(this.getTimeDefinition().granularity, date).getTime(), report, granularity)
  }

}

export function isTimedLineItem(lineItem: any): lineItem is TimedLineItem {
  return lineItem?._discriminator === "timed"
}

