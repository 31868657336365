// Convert interest rates from one period to another (e.g. annual to monthly)
export function convertRate(r, toType, fromType) {
  const toFrom = `${fromType}-${toType}`;
  const daysPerYear = 360;
  const daysPerMonth = 30;
  switch (toFrom) {
    case "year-month":
      return Math.pow(1 + r, daysPerMonth / daysPerYear) - 1;
    case "month-year":
      return Math.pow(1 + r, 1 / 12) - 1;
    default:
      return new Error("Unknown conversion formula: ", toFrom);
  }
}

export function PMT(ir, np, pv, fv, type) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
}

// Modified from here: https://gist.github.com/ghalimi/4597900
export function NPV(rate, values, discount0 = true) {
  // Initialize net present value
  var value = 0;

  // If not discount0, skip first period from discounting
  let startIndex = 0;
  if (!discount0) startIndex = 1;
  if (values.length === 1 && !discount0) return values[0]; // Catch single var case

  // Loop on all values
  for (var j = startIndex; j < values.length; j++) {
    value += values[j] / Math.pow(1 + rate, j + 1);
  }

  // Add back 0 period if needed
  if (!discount0) return (value += values[0]);

  // Return net present value
  return value;
}

// Sort array of object based on lastModified property
export const lastModifiedCompare = (a, b) => {
  if (!a.lastModified) return 1;
  if (!b.lastModified) return -1;
  const a_lastModified = extractSecondsFromDate(a.lastModified)
  const b_lastModified = extractSecondsFromDate(b.lastModified)
  if (a_lastModified > b_lastModified) {
    return -1;
  }
  if (a_lastModified < b_lastModified) {
    return 1;
  }
  return 0;
};

// TBD - Temporary bug workaround for date issue on update
export function extractSecondsFromDate(date) {
  if (date.seconds !== undefined) {
    return date.seconds
  }
  else if (date._seconds !== undefined) {
    return date._seconds
  }
  return undefined
}

// Simulate an async / sleep action or promise for an example API call
export const sleep = async (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function nDol(num, decimals = 0, dolSign = true, showZero = false) {
  if (num === 0 && !showZero) return "-";
  let original_sign = Math.sign(num);
  if (original_sign === 0) original_sign = 1;
  let ret = Math.abs(Math.round(num * 10 ** decimals) / 10 ** decimals);
  let suffix = "";
  if ((ret >= 999999) & (ret < 1000000)) {
    ret = ret / 1000;
    suffix = "K";
  } else if ((ret >= 1000000) & (ret < 1000000000)) {
    ret = ret / 1000000;
    suffix = "M";
  } else if ((ret >= 1000000000) & (ret < 1000000000000)) {
    ret = ret / 1000000000;
    suffix = "B";
  } else if (ret >= 1000000000000) {
    ret = ret / 1000000000000;
    suffix = "T";
  }
  // if(ret % 1 !== 0) Number(ret.toFixed(Math.max(decimals) || 0));
  ret = ret.toLocaleString("en-US");
  ret = dolSign ? "$" + ret : ret;
  ret = ret + suffix;
  if (original_sign === -1) ret = "-" + ret;
  if (!isFinite(num))
    return `${original_sign === -1 ? "-" : ""}${dolSign ? "$" : ""}∞`;
  return ret;
}

export function percChange(n1, n2) {
  return Math.round((100 * (n2 - n1)) / n1);
}

export function accum(n) {
  return n.reduce(add, 0);
}

export function add(accumulator, a) {
  // EXAMPLE :  const sum = [1, 2, 3].reduce(add, 0); // with initial value to avoid when the array is empty
  return accumulator + a;
}

export function average(nums) {
  return nums.reduce((a, b) => a + b) / nums.length;
}

export function pluralStringArrayLength(
  arr,
  suffix,
  blank_case = "",
  nospace = false
) {
  if (!arr || !arr.length) return blank_case;
  return pluralString(arr.length, suffix, blank_case, nospace);
}

export function pluralString(number, suffix, blank_case = "", nospace = false) {
  if (!number || number === 0) return blank_case;
  let isPlural = false;
  if (number > 1) isPlural = true;
  return `${number}${nospace ? "" : " "}${suffix}${isPlural ? "s" : ""}`;
}

export function getRangeData(data, freq) {
  return data?.[`data_${freq}`];
}
