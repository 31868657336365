import {Button, Modal} from "semantic-ui-react";
import React from "react";

export function ErrorPopup({onClose, e, header}: {onClose: () => void, e: unknown, header?: string}): JSX.Element {
    return <Modal
        open={true}
        onClose={onClose}
        size="tiny"
    >
        <Modal.Header>{header ?? 'Error'}</Modal.Header>
        <Modal.Content>
            <p>{e instanceof Error ? e.message : 'Unknown error'}</p>
        </Modal.Content>
        <Modal.Actions>
            <Button color="blue" onClick={() => onClose()}>
                Close
            </Button>
        </Modal.Actions>
    </Modal>
}
