import { useHistory } from "react-router-dom";
import { Breadcrumb } from 'semantic-ui-react';
import React from "react";

function HomeButton(props) {
  let history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <>
      {props.backArrow && <Breadcrumb.Divider icon='left chevron' />}
      <Breadcrumb.Section link onClick={handleClick}>{props.text || "Back"}</Breadcrumb.Section>
    </>
  );
}

export default HomeButton;