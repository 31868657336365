import {Button, Dimmer, Dropdown, Grid, Header, Icon, Input, Loader, Segment} from "semantic-ui-react";
import React, {ReactNode, useEffect, useMemo, useState} from "react";
import {useLineItemsStoreMetadata, usePersistenceStoreQueryWithContextUpdate} from "../../lineitems-store/LineItemsStore.hook";
import {DEFAULT_SOURCE_GRID, LineItemsStore, pQuery, QueryResult, StoreQuery} from "../../ps-models/lineitems-store";
import {setLabelAndFormatForAggregates} from "../boards/demo/storeFormulas";
import {useLineItemFilter} from "../../lineitems-store/MetadataFilters";
import {useDateRange} from "../../lineitems-store/DateRange";
import { v4 as uuidv4 } from 'uuid';
import {
    addReportConfigToLocalStorage,
    arrangeLayout, ChartConfig,
    DimensionWithItemDef, InputItemType, MetricConfig, NonTimeSeriesTableConfig, Page,
    REPORT_BUILDER_ITEM_DIMENSIONS,
    ReportConfig, ReportConfigItem, ReportDeliveryEmailConfig,
    ReportMeta, ReportMetaInputsContainerType,
    reportMetaInputsDefinition, SiteMap,
} from "./constants";
import {ReportConfigBuilder} from "./ReportConfigBuilder";
import {ReportPreview} from "./ReportPreview";
import {lineItemAggregatorMap, getAmProjectConfig} from "../../ps-models";
import {Metric} from "../../statistics/Metric";
import {TimeSeriesChartProps} from "../../lineitems-store/TimeSeriesChart";
import {LineItemsTable} from "../../lineitems-store/LineItemsTableView";
import PDFInputField from "../../../components/PDFPreviewer/PDFInputField";
import {DateRangeType, FormInputTypes} from "../../ps-types";
import MapComponent from "../../lineitems-store/MapComponent";
import {ClosableSection} from "../../ClosableSection";
import {ReportConfigCrud} from "./ReportConfigCrud";
import {useInstantUpdater} from "../../generic.hooks";
import {useAssetManagementModule} from "../index";
import {NRNTimeSeriesChart} from "../boards/nrn/NRNTimeSeriesChart";
import {DemoTimeSeriesChart} from "../boards/demo/DemoTimeSeriesChart";
import {KelvinTimeSeriesChart} from "../boards/Kelvin/KelvinTimeSeriesChart";
import {WSTimeSeriesChart} from "../boards/wattsync/WSTimeSeriesChart";
import {authStorage} from "../../auth";


const defaultValuesForInputValuesToBeCaptured: Record<ReportMeta, FormInputTypes> = {
    title: null,
    description: null
}

const formFieldsDefinition = reportMetaInputsDefinition;

function useReportBackendHooks() {
    let company = authStorage.getCompany();
    const {collection: COLLECTION} = getAmProjectConfig(company);
    const storesMetadata = useLineItemsStoreMetadata(COLLECTION);
    const store = usePersistenceStoreQueryWithContextUpdate(COLLECTION,
        pQuery()
            .selectSourceParams([
                'Current_Energy_Retailer__c', 'Network__c', 'State__c', 'Billing_Type__c', 'Residential_Business__c'
            ])
        , (store) => {
        // @TODO: Test this when we re-enable report builder
            store.getDataSet().addTimedGroupingLineItemsByField("store_sourceLineItemName",
                {
                    defaultGroupingOperation: "sumOver",
                    groupOperationMap: {
                        "Solar Available": "avg",
                        "Battery Available": "avg",
                        "Site Available": "avg",
                        "Investment Returns IRR": "avg"
                    }
                }
            );
            setLabelAndFormatForAggregates(store);
        }
    );
    return {store, storesMetadata}
}


export function ReportBuilder() {
    const {store, storesMetadata} = useReportBackendHooks();
    const isLoading = !store || !storesMetadata;
    const module = useAssetManagementModule();
    let TimeSeriesChartComponentForModule;

    switch (module.Name){
        case "NRN":
            TimeSeriesChartComponentForModule = NRNTimeSeriesChart;
            break;
        case "DEMO":
            TimeSeriesChartComponentForModule = DemoTimeSeriesChart;
            break;
        case "Kelvin":
            TimeSeriesChartComponentForModule = KelvinTimeSeriesChart;
            break;
        case "WATTSYNC":
            TimeSeriesChartComponentForModule = WSTimeSeriesChart;
            break;
        default:
            TimeSeriesChartComponentForModule = DemoTimeSeriesChart;
            break;
    }

    return isLoading ? <Loading /> : <ReportBuilderFrontend store={store} storesMetadata={storesMetadata} TimeSeriesChartComponentForModule={TimeSeriesChartComponentForModule} />
}

function ReportBuilderFrontend({store, storesMetadata, TimeSeriesChartComponentForModule}: {store: LineItemsStore, storesMetadata: LineItemsStore, TimeSeriesChartComponentForModule: (props: TimeSeriesChartProps) => ReactNode}) {
    const [builderConfig, setBuilderConfig] = useState<ReportConfig>(() => NewReportConfig());
    const [filterQuery, filtersComponent] = useLineItemFilter({
        'source_Current_Energy_Retailer__c': 'Retailer',
        'source_Network__c': 'Network',
        'source_State__c': 'State',
        'source_Billing_Type__c': 'Billing Type',
        'source_Residential_Business__c': 'Residential/Business'
    }, store);
    const [dateRangeComponent, dateRange] = useDateRange(store);
    const {layout, lineItemNames} = useStores(store, storesMetadata, dateRange, filterQuery, builderConfig);
    const [crudId, updateCrudId] = useInstantUpdater();

    const preparedPDFPageContent = layout.map((p, pageIdx) =>
        <Grid style={{width: '100%'}}>
            {pageIdx === 0 &&
                (<Grid.Row key={-1} style={{padding: 0}} columns={1}>
                    <ReportMetaInputsContainer dateRange={dateRange} builderConfig={builderConfig} />
                </Grid.Row>)
            }
            {p.map((r, rowIdx) =>
                <Grid.Row key={rowIdx} style={{padding: 0}} columns={r.length as any}>
                    {r.map(({itemDef, queryResult}, idx) =>
                        <ReportItem key={idx} itemDef={itemDef} queryResult={queryResult} TimeSeriesChartComponentForModule={TimeSeriesChartComponentForModule} />
                    )}
                </Grid.Row>
            )}
        </Grid>
    )

    return <TopLayout>
        <ClosableSection title={"Reports History"} level={"title-bar"}
                         customStyles={{margin: '1em 0'}}
                         opened={false}>
            <ReportConfigCrud key={crudId} selectConfigToLoad={(reportConfigToLoad)=>{
                if (reportConfigToLoad) {
                    console.info('the selectedConfig is', reportConfigToLoad)
                    setBuilderConfig(reportConfigToLoad)
                } else {
                    setBuilderConfig(NewReportConfig())
                }
            }}/>
        </ClosableSection>
        <Segment>
            <div style={{display: 'flex', justifyContent: "space-between", width: "100%",
                height: "100px",
                alignItems: "center"
            }}>
                <div style={{width: "30%", justifySelf: "center"}}>
                    {dateRangeComponent}
                </div>
                <ReportMailerConfigurator builderConfig={builderConfig}  />
            </div>
            <Segment className="aggregate-filters">
                {filtersComponent}
            </Segment>
            <Grid solid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <ReportConfigBuilder
                            onConfigItemsUpdate={setBuilderConfig}
                            // @TODO: This could be the cause of our problems.
                            // @TODO This is dangerous, sanitize the config coming from localStorage
                            reportConfig={builderConfig}
                            lineItemNames={lineItemNames}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <ClearButton onClear={() =>{
                                const set = NewReportConfig()
                                console.info('the config after clearing is', set)
                                setBuilderConfig(set)
                            } } />
                            <SaveButton onSave={() => {
                                SaveReportConfig(builderConfig, dateRange, filterQuery)
                                updateCrudId()
                            }} />
                            <ReportPreview preparedPageContent={preparedPDFPageContent} onDocumentDownload={() => {}} />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
        <div style={{margin: 20}}>{preparedPDFPageContent}</div>
    </TopLayout>

}

function useStores(store: LineItemsStore, storesMetadata: LineItemsStore, dateRange: DateRangeType, filterQuery: StoreQuery, builderConfig: ReportConfig) {
    const [state, setState] = useState<{layout: Page<DimensionWithItemDef>[], lineItemNames: string[]}>({
        layout: [],
        lineItemNames: []
    });

    useEffect(() => {
        console.time("StoreQueries");
        const queryWithFiltersAndGroupingElements = StoreQuery.withField('store_groupingName').or(filterQuery);
        // @TODO: Test this when we re-enable report builder
        const aggregatedStoreView = store.materializeTimed(queryWithFiltersAndGroupingElements.aggregateOverTimeRange(dateRange.from, dateRange.to));
        const lineItemNames = Object.values(aggregatedStoreView.getDataSet().getByField('store_groupingName', 'store_sourceLineItemName') ?? {}).map((li) => li.name)

        function queryByReportConfigItem(itemDef: ReportConfigItem): QueryResult | undefined {
            switch (itemDef.kind) {
                case "Metric":
                    // @TODO: Test this when we re-enable report builder
                    return store.materializeTimed(
                    queryWithFiltersAndGroupingElements.aggregateOverTimeRange(
                        dateRange.from,
                        dateRange.to,
                        lineItemAggregatorMap({[itemDef.lineItemName]: itemDef.aggregationTechnique})
                    )
                ).query(
                    StoreQuery.byNames([itemDef.lineItemName])
                )
                case "Chart": return store.view(
                    StoreQuery.byNames(itemDef.lineItemNames).or(queryWithFiltersAndGroupingElements)
                ).query(
                    StoreQuery.all().inTimeRange(dateRange.from, dateRange.to).monthly(lineItemAggregatorMap(itemDef.timeAggregatorMap))
                )
                case "NonTimeSeriesTable":
                    // @TODO: Test this when we re-enable report builder
                    return store.materializeTimed(
                    queryWithFiltersAndGroupingElements.aggregateOverTimeRange(dateRange.from, dateRange.to, lineItemAggregatorMap(itemDef.timeAggregatorMap))
                ).query(
                    StoreQuery.byNames(itemDef.lineItemNames, true),
                    {grid: DEFAULT_SOURCE_GRID}
                )
                default: return undefined
            }
        }

        const dimensionsWithItemDefForConfiguredItems: DimensionWithItemDef[] = (builderConfig?.items ?? [])
            .map((itemDef) => ({...REPORT_BUILDER_ITEM_DIMENSIONS[itemDef.kind], itemDef, queryResult: queryByReportConfigItem(itemDef)}))

        // @TODO: Prepare configurable part of elementsArray i.e. from the builderConfig and the queryResultsMap in one iteration
        const elementsArray: DimensionWithItemDef[] = [
            {...REPORT_BUILDER_ITEM_DIMENSIONS["ReportMetaInputsContainer"], itemDef: {kind: "ReportMetaInputsContainer"}, queryResult: undefined},
            {...REPORT_BUILDER_ITEM_DIMENSIONS["Map"], itemDef: {kind: 'Map'}, queryResult: storesMetadata.query(queryWithFiltersAndGroupingElements)},
            ...dimensionsWithItemDefForConfiguredItems
        ];
        console.timeEnd("StoreQueries");

        console.time("arrangeLayout")
        const layout = arrangeLayout(elementsArray);
        layout[0].shift() // Remove the very first row as it should contain ReportMetaInputsContainer as it'll be rendered in another way
        console.timeEnd("arrangeLayout")

        setState({layout, lineItemNames})
    }, [store, storesMetadata, dateRange.from, dateRange.to, filterQuery, builderConfig])

    return state
}

function ReportItem({itemDef, queryResult, TimeSeriesChartComponentForModule}: {
    itemDef: MetricConfig | ChartConfig | NonTimeSeriesTableConfig | SiteMap | InputItemType | ReportMetaInputsContainerType,
    queryResult: QueryResult | undefined,
    TimeSeriesChartComponentForModule: (props: TimeSeriesChartProps) => ReactNode,
}): JSX.Element {
    switch (itemDef.kind) {
        case "Map": return <ReportMap queryResult={queryResult!} itemDef={itemDef} />
        case "Metric": return <ReportMetric queryResult={queryResult!} itemDef={itemDef} />
        case "Chart": return <ReportChart queryResult={queryResult!} itemDef={itemDef} TimeSeriesChartComponentForModule={TimeSeriesChartComponentForModule} />
        case "NonTimeSeriesTable": return <ReportNonTimeSeriesTable queryResult={queryResult!} itemDef={itemDef} />
        default: return <></>
    }
}

function ReportNonTimeSeriesTable({itemDef, queryResult}: {itemDef: NonTimeSeriesTableConfig, queryResult: QueryResult}) {
    return <Grid.Column
        style={{
            height: REPORT_BUILDER_ITEM_DIMENSIONS[itemDef.kind].height,
        }}
    >
        {queryResult ? <LineItemsTable key={itemDef.id} queryResult={queryResult}/> : <div>Table has no data</div>}
    </Grid.Column>
}

function ReportChart({itemDef, queryResult, TimeSeriesChartComponentForModule}: {itemDef: ChartConfig, queryResult: QueryResult, TimeSeriesChartComponentForModule: (props: TimeSeriesChartProps)=>ReactNode}) {
    return <Grid.Column
        style={{
            height: REPORT_BUILDER_ITEM_DIMENSIONS[itemDef.kind].height,
        }}
    >
        {queryResult ? <TimeSeriesChartComponentForModule
                key={itemDef.id} title={itemDef.title} result={queryResult}
                options={{
                    type: itemDef.type,
                    includeLineItems: itemDef.lineItemNames
                }}
            />: <div>Chart has no data</div>}
    </Grid.Column>
}

function ReportMetric({itemDef, queryResult}: {itemDef: MetricConfig, queryResult: QueryResult}) {
    return <Grid.Column style={{
        height: REPORT_BUILDER_ITEM_DIMENSIONS[itemDef.kind].height,
    }}><Metric key={itemDef.id} label={itemDef.title || itemDef.lineItemName}
               value={queryResult?.firstTimeSlotTextOf(itemDef.lineItemName) || 'N/A'}
    /></Grid.Column>
}

function ReportMap({itemDef, queryResult}: {itemDef: SiteMap, queryResult: QueryResult}) {
    return <Grid.Column style={{
        ...REPORT_BUILDER_ITEM_DIMENSIONS[itemDef.kind]
    }}>
        <MapComponent
            onClickLocation={(site) => {}}
            result={queryResult}/>
    </Grid.Column>
}

function ReportMetaInputsContainer({dateRange, builderConfig}: {dateRange: DateRangeType, builderConfig: ReportConfig}) {
    const [inputValuesCaptured, setInputValuesCaptured] = useState<Record<ReportMeta, FormInputTypes>>(
        {
            title: builderConfig.reportMeta.title,
            description: builderConfig.reportMeta.description,
        }
    );
    const [errorsOnInputs, setErrorsOnInputs] = useState<Record<ReportMeta, any | null>>({
        ...defaultValuesForInputValuesToBeCaptured
    })
    useEffect(() => {
        builderConfig.reportMeta.title = inputValuesCaptured.title as string;
        builderConfig.reportMeta.description = inputValuesCaptured.description as string;
    }, [builderConfig, inputValuesCaptured]);
    const getInputFieldContent = (fieldName: ReportMeta) => {
        return <PDFInputField
            fieldDef={formFieldsDefinition[fieldName]}
            error={errorsOnInputs[fieldName]}
            setErrors={setErrorsOnInputs}
            setInputValues={setInputValuesCaptured}
            name={fieldName}
            value={inputValuesCaptured[fieldName]}
            onFieldFocus={() => {
            }}
        />
    };
    return <Grid.Column style={{
            ...REPORT_BUILDER_ITEM_DIMENSIONS["ReportMetaInputsContainer"]
        }}>
        <div>
            <div style={{display: "flex", justifyContent: 'space-between'}}>
                <h1 style={{marginLeft: -9}}>{getInputFieldContent("title")}</h1>
                <div style={{justifySelf: 'center', alignSelf: 'center', fontWeight: 400}}>
                    {`${dateRange.from.toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                    })} - ${dateRange.to.toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                    })}`}
                </div>
            </div>
            {getInputFieldContent("description")}
        </div>
        <></>
    </Grid.Column>
}

function Loading() {
    return <TopLayout><Dimmer page={false} active={true} inverted><Loader active={true} content={`Loading, Please wait...`}/></Dimmer></TopLayout>
}

const DUMMY_ID = "NOT PERSISTED";

function NewReportConfig(): ReportConfig {
    return {
        id: DUMMY_ID,
        reportMeta: {
            title: null,
            description: null
        },
        dateRange: {
            from: new Date(),
            to: new Date()
        },
        filterQuery: {},
        items: [],
        createdAt: new Date(),
        updatedAt: new Date()
    }
}

function SaveButton({onSave}: {onSave: () => (Promise<void> | void)}) {
  const [buttonState, setButtonState] = useState({
    content: 'Save Report',
    disabled: false
  });

  const handleClick = () => {

    setButtonState({
      content: 'Saving...',
      disabled: true
    });

    setTimeout(async () => {
      await onSave();
      setButtonState({
        content: 'Save Report',
        disabled: false
      });
    }, 1000);
  };

  return (
    <Button
      icon="save"
      labelPosition="left"
      color="blue"
      type="button"
      width={8}
      content={buttonState.content}
      disabled={buttonState.disabled}
      onClick={handleClick}
    />
  );
}

function ReportMailerConfigurator({builderConfig}: {builderConfig: ReportConfig}) {
    const [mailConfiguratorinputValuesCaptured, setMailConfiguratorinputValuesCaptured] = useState<ReportDeliveryEmailConfig>(() =>{
        return {
           emailTo : builderConfig?.emailConfig?.emailTo ?? '',
           frequency : builderConfig?.emailConfig?.frequency ?? 'Monthly',
        }
    });

    // @TODO: Check if this way of mutating the state is safe.
    useEffect(()=> {
        setMailConfiguratorinputValuesCaptured({emailTo: builderConfig?.emailConfig?.emailTo ?? '',
            frequency : builderConfig?.emailConfig?.frequency ?? 'Monthly'})
    }, [builderConfig])

    useEffect(() => {
        if(!mailConfiguratorinputValuesCaptured.emailTo || !mailConfiguratorinputValuesCaptured.frequency){
            return;
        }
        if(!builderConfig["emailConfig"]){
            builderConfig.emailConfig = {
                emailTo: mailConfiguratorinputValuesCaptured.emailTo as string,
                frequency: mailConfiguratorinputValuesCaptured.frequency as ReportDeliveryEmailConfig["frequency"]
            }
        } else {
            builderConfig.emailConfig.emailTo = mailConfiguratorinputValuesCaptured.emailTo;
            builderConfig.emailConfig.frequency = mailConfiguratorinputValuesCaptured.frequency;
        }
    }, [builderConfig, mailConfiguratorinputValuesCaptured]);

    useEffect(() => {
        const deliverEmailTo = mailConfiguratorinputValuesCaptured.emailTo;
        const emailDeliveryFrequency = mailConfiguratorinputValuesCaptured.frequency
        if(deliverEmailTo && emailDeliveryFrequency) {
            builderConfig['emailConfig'] = {
                emailTo: deliverEmailTo,
                frequency: emailDeliveryFrequency
                }
        }
    }, [builderConfig, mailConfiguratorinputValuesCaptured]);

    return <Segment placeholder
                                    style={{padding: "2px",minHeight:'110px', height: '110px'}}
    >
        <Header icon style={{fontSize: "0.9em",
            margin: "1px"
        }}>
            <Icon name='paper plane' />
            Configure report delivery to your mailbox.
        </Header>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Input
                // style={{width: "40%"}}
                placeholder='Email'
                icon={'mail'}
                value={mailConfiguratorinputValuesCaptured['emailTo']}
                onChange={(e) => {
                    setMailConfiguratorinputValuesCaptured((prev)=> ({...prev, emailTo: e.target.value ?? ''}))
                }}
            />
            <Dropdown
                // style={{width: "40%"}}
                options={(["Monthly", "Quarterly", "Yearly"] as ReportDeliveryEmailConfig["frequency"][])?.map((name) => ({key: name, text: name, value: name}))}
                search
                selection
                placeholder={'Delivery Frequency'}
                multiple={false}
                onChange={(e, data) => {
                    setMailConfiguratorinputValuesCaptured((prev)=> ({...prev, frequency: data.value as ReportDeliveryEmailConfig["frequency"] ?? 'Monthly'}))
                }}
            />
        </div>
    </Segment>
}

function ClearButton({onClear}: {onClear: () => (Promise<void> | void)}) {
    const [buttonState, setButtonState] = useState({
        content: 'Reset Choices',
        disabled: false
    });

    const handleClick = () => {
        onClear();
    };

    return (
        <Button
            icon="window restore outline"
            labelPosition="left"
            color="grey"
            type="button"
            width={8}
            content={buttonState.content}
            disabled={buttonState.disabled}
            onClick={handleClick}
        />
    );
}

function SaveReportConfig(builderConfig: ReportConfig, dateRange: DateRangeType, filterQuery: Record<string, any>) {
    addReportConfigToLocalStorage({...builderConfig,
        createdAt: builderConfig.id === DUMMY_ID ? new Date() : builderConfig.createdAt,
        updatedAt: new Date(),
        id: builderConfig.id === DUMMY_ID ? uuidv4() : builderConfig.id,
        dateRange, filterQuery,
    })
}

const TopLayout = ({children}: { children: React.ReactNode | React.ReactNode[] }) => {
    return <div className="InvestorDashboard"><Segment>
        <Header as="h2" color="purple">Report Builder</Header>
        {children}
    </Segment></div>
}