import {createRef, useMemo } from "react";
import {
    PDFPreviewerWithVisibilityController
} from "../../../components/PDFPreviewer/PdfPreviewerWithVisibilityController";

export function ReportPreview({preparedPageContent, onDocumentDownload}: {
    preparedPageContent: JSX.Element[], onDocumentDownload: () => void,
}) {

    const pageRefArray = useMemo(() => {
        return preparedPageContent.map(() => createRef<HTMLDivElement | null>());
    }, [preparedPageContent]);

    return (<PDFPreviewerWithVisibilityController
                        pageContents={preparedPageContent}
                        // PageHeader={() => <LOIAndMoUPreviewPageHeader />}
                        // PageFooter={(props) => <ScenarioPreviewPageFooter customerName={customerName}
                        //                                                   versionName={versionName} {...props}/>}
                        pagesRefArray={pageRefArray}
                        downloadedFileBaseName={`ReportView`}
                        appendGenerationDateToFileName={true}
                        onDownloadComplete={onDocumentDownload}
                        previewCTA={'Export As PDF'}
                        downloadDisabled={false}
                        // @TODO: Add inputs
                        // onInputsSave={onInputsSave}
                    />
    );
}



