import {Header} from "semantic-ui-react";
import React, {useState} from "react";
import {LineItemsStore, QueryResult} from "../ps-models/lineitems-store";
import {LineItemsTable, LineItemsTableProps} from "./LineItemsTableView";
import "../assets-management/AssetsManagement.css";
import {useUpdateContext} from "../UpdateContext";
import {LineItemCodeEditor, LineItemCodeEditorStandalone} from "./lineItemEditor/LineItemCodeEditor";

function addClassName(existingClass: string | undefined, newClass: string) {
  existingClass = (existingClass || "").toLowerCase();
  newClass = (newClass || "").toLowerCase();

  if(existingClass && existingClass.indexOf(newClass) === -1) {
    return existingClass + " " + newClass;
  }
  return newClass;
}

function resetClassName(existingClass: string | undefined, names: string[]) {
  existingClass = (existingClass || "").toLowerCase();

  names.forEach(name => {
    existingClass = existingClass!.replace(name, "");
  });

  return existingClass;
}

function processAlerts(result: QueryResult, store: LineItemsStore) {

  //Add Alert class
  result.rows
    .filter((row) => result.rowColumnValue(row, 'fields.alertOf'))
    .forEach((row) => {
      let alertLineItemName = result.rowLineItemName(row);

      let alertLineItem = store.getDataSet().getLineItem(alertLineItemName);
      let alertRow = result.row(alertLineItemName)!;
      alertRow.hidden = true;


      let alertOf = alertLineItem.fields.getFieldStr("alertOf");
      let value = result.nthSlotOf(alertLineItemName)?.value;
      let alertingRow = result.row(alertOf)!;


      if(!alertingRow) {
        return;
      }

      resetClassName(alertingRow.extraClasses, ["row-alert-success", "row-alert-failed", "row-alert-warning"])

      if(value) {
        let level = alertLineItem.fields.getFieldStr("alertLevel") || "failed";
        alertingRow.extraClasses = addClassName(alertingRow.extraClasses, "row-alert-" + level);
      } else {
        alertingRow.extraClasses = addClassName(alertingRow.extraClasses, "row-alert-success");
      }

  });

}


export function SimpleCalculatedLineItemEditor({store, dispatcher}:
                               { store :LineItemsStore,
                                 dispatcher: (onRowSelected: (row: string) => void) => React.ReactNode
                               }) {

  let [selectedLineItem, setSelectedLineItem] = useState<string | undefined>(undefined);

  useUpdateContext();

  const handleRowSelected = (row: string) => {
    if(store.getDataSet().getLineItem(row)) {
      console.info(store.getDataSet().getLineItem(row))
      setSelectedLineItem(row + " - alert");
    }
  }

  return <>
    {dispatcher(handleRowSelected)}
    {selectedLineItem &&<Header as="h3" color="purple">Alert When:</Header>}
    {selectedLineItem && <LineItemCodeEditorStandalone
        store={store}
        lineItemName={selectedLineItem}
    />}
  </>
}

export function AlertsLineItemTable({results, store, onRowSelected}: {
  results: QueryResult, store: LineItemsStore,
  onRowSelected?: (row: string) => void
}) {


  processAlerts(results, store);

  return <><LineItemsTable
      hideToolbar
      onRowSelected={onRowSelected}
      withGroups
      hideFooter
      hideHeaders
      groupExpansionDepth={1}
      queryResult={results}
  /></>
}