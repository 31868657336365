export type CircuitBreakerState = 'open' | 'closed' | 'half-open';
export type TimeoutEventToBeTreatedAs = 'success' | 'error' | 'ignored';

const publishErrorLogsToConsole = process.env.NODE_ENV === 'development';

/**
 * @param {CircuitBreakerState} state - Defines the current state of the circuitbreaker or the protected resource.
 * @param {string} name - Name of the protected resource.
 * @param {number} errorTimeout - The time in milliseconds to wait after opening the circuit, to query the resource again. This is also used to define a timespan to determine if errorThreshold has been met. 
 * @param {number} errorThreshold - The number of errors that should occur within errorTimeout to open the circuit.
 * @param {number} successThreshold - The number of consecutive successfull calls to the protected resource, for the circuit to be closed again, when it's in the half-open state.
 * @param {number} requestsInQuickSuccessionThreshold - The number of consecutive calls to the protected resource in quick succession(i.e. during time specified by errorTimeout), for the circuit to be open. So as to not overload the remote resource and to protect it from malicious calls.
 * @param {number} successCountWhenInHalfOpenState - Used to accumulate the count of successfull calls to the protected resource during the half-open state which in turn helps to check if successThreshold has been met and whether the circuit can be closed again.
 * @param {number} requestCount - Used to accumulate the count of calls made to the protected resource, which in turn helps to check if requestsInQuickSuccessionThreshold has been met, when in closed state. 
 * @param {number} errorCount - Used to accumulate the count of errorneous calls to the protected resource, which in turn helps to check if errorThreshold has been met, when in closed state.
 */


export class CircuitBreaker {
    private state: CircuitBreakerState = 'closed';
    private readonly name: string;
    private errorThreshold: number = 3;
    private errorTimeout: number = 30 * 1000; // 30 seconds
    private successThreshold: number = 2;
    private requestsInQuickSuccessionThreshold: number = 10;
    private successCountWhenInHalfOpenState: number = 0;
    private requestCount: number = 0;
    private errorCount: number = 0;

    private errorCountCollectorInterval: NodeJS.Timeout | null = null;
    private requestCountCollectorInterval: NodeJS.Timeout | null = null;
    private timeoutEventToBeTreatedAs: TimeoutEventToBeTreatedAs = 'ignored';
    private isProtectedCallSynchronous: boolean = false; // the request is async

    constructor(name: string, errorTimeout?: number, errorThreshold?: number, requestsInQuickSuccessionThreshold?: number, isProtectedCallSynchronous?: boolean, timeoutEventToBeTreatedAs?: TimeoutEventToBeTreatedAs) {
        this.name = name;
        if (errorTimeout) { // This should not be zero, hence the condition.
            this.errorTimeout = errorTimeout;
        }
        if (errorThreshold) { // This should not be zero, hence the condition.
            this.errorThreshold = errorThreshold;
        }
        if (requestsInQuickSuccessionThreshold) { // This should not be zero, hence the condition.
            this.requestsInQuickSuccessionThreshold = requestsInQuickSuccessionThreshold;
        }
        if (timeoutEventToBeTreatedAs) {
            this.timeoutEventToBeTreatedAs = timeoutEventToBeTreatedAs;
        }
        if (this.requestsInQuickSuccessionThreshold < this.successThreshold) {
            throw new Error('requestsInQuickSuccessionThreshold should be greater than successThreshold, otherwise the circuit cannot move from half-open state to closed state.');
        }
        if (isProtectedCallSynchronous !== undefined) {
            this.isProtectedCallSynchronous = isProtectedCallSynchronous;
        }
    }

    private changeState(updateStateTo: CircuitBreakerState) {
        /*
        Allowed state updates are:
        // Closed -> half-open
        // half-open -> open
        // half-open -> close
        */
        if (this.state !== updateStateTo) {
            this.resetErrorCount();
            this.resetSuccessCountWhenInHalfOpenState();
            if (updateStateTo === 'open') {
                setTimeout(() => {
                    this.state = 'half-open';
                }, this.errorTimeout);
            }
            this.state = updateStateTo;
        }
    }

    private resetSuccessCountWhenInHalfOpenState() {
        this.successCountWhenInHalfOpenState = 0;
    }

    private resetErrorCount() {
        if (this.errorCountCollectorInterval) {
            clearInterval(this.errorCountCollectorInterval);
        }
        this.errorCount = 0;
    }

    private resetRequestCount() {
        if (this.requestCountCollectorInterval) {
            clearInterval(this.requestCountCollectorInterval);
        }
        this.requestCount = 0;
    }

    getState() {
        return this.state;
    }

    successEvent() {
        const currentState = this.getState();
        switch (currentState) {
            case 'half-open':
                this.successCountWhenInHalfOpenState++;
                if (this.successCountWhenInHalfOpenState === this.successThreshold) {
                    this.changeState('closed');
                }
                break;
            case 'closed':
                // Nothing to be done here
                break;
            case 'open':
                if (this.isProtectedCallSynchronous) {
                    this.logAnError(`This should not happen, got a successfull event for ${this.name} when the circuit was open`);
                }
                break;
        }
    }

    errorEvent() {
        if (this.errorCount === 0) {
            this.errorCountCollectorInterval = setTimeout(() => {
                this.resetErrorCount();
            }, this.errorTimeout);
        }
        this.errorCount++;
        const currentState = this.getState();
        switch (currentState) {
            case 'half-open':
                this.changeState('open');
                break;
            case 'closed':
                if (this.errorCount === this.errorThreshold) {
                    this.changeState('open');
                }
                break;
            case 'open':
                if (this.isProtectedCallSynchronous) {
                    this.logAnError(`This should not happen, got a error event for ${this.name} when the circuit was open`);
                }
                break;
        }
    }

    requestEvent() {
        if (this.requestCount === 0) {
            this.requestCountCollectorInterval = setTimeout(() => {
                this.resetRequestCount();
            }, this.errorTimeout);
        }
        this.requestCount++;
        const currentState = this.getState();
        switch (currentState) {
            case 'half-open':
                if (this.requestCount === this.requestsInQuickSuccessionThreshold) {
                    this.changeState('open');
                }
                break;
            case 'closed':
                if (this.requestCount === this.requestsInQuickSuccessionThreshold) {
                    this.changeState('open');
                }
                break;
            case 'open':
                this.logAnError(`This should not happen, got a request event for ${this.name} when the circuit was open`);
                break;
        }
    }

    timeoutEvent() {
        switch (this.timeoutEventToBeTreatedAs) {
            case 'success':
                this.successEvent();
                break;
            case 'error':
                this.errorEvent();
                break;
            case 'ignored':
                break;
        }
    }

/**
    This is a helper method (for debugging) which will be removed after we have sufficient tests for the circuitBreaker.
 */
    private logAnError(...args: (object | string)[]) {
        if (publishErrorLogsToConsole) {
            console.error(...args);
        }
    }
}