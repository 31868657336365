import {LineItemsTable, LineItemsTableWithFormulas} from "../lineitems-store/LineItemsTableView";
import {LineItemsStore, QueryResult, StoreQuery} from "../ps-models/lineitems-store";
import React, {useEffect, useState} from "react";
import {useUpdateContext} from "../UpdateContext";
import {Header, Segment, Select} from "semantic-ui-react";
import {TimeUnits} from "../ps-models";



export function LineItemsStoreDebug({store}:{store?: LineItemsStore}) {
   let storeFromContext = useUpdateContext<LineItemsStore>().tryGetService();

   store = (store || storeFromContext)!;

  let [granularity, setGranularity] = useState<TimeUnits>("years");


  let result = store.query(StoreQuery.all()
    .withTimeIndex(store.timeIndex.withGranularity(granularity)));

  return <Segment basic>
    <Select options={
      [
        {value: "days", label: "Days"},
        {value: "months", label: "Months"},
        {value: "years", label: "Years"},
      ]
    } value={granularity} onChange={(e, data) => setGranularity(data.value as TimeUnits)} />
    <Header as="h2" color="purple">Line Items Store Debug</Header>
    <LineItemsTableWithFormulas store={store}
    queryResult={result}
  />
  </Segment>
}

