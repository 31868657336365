import {ResultOptions} from "./QueryResultOptions";
import {
  isTemplateLineItem,
  isTimedLineItem,
  LineItem,
  TemplateLineItem,
  TimedLineItem, ValueType
} from "../../line-items";
import {LineItemColumn, LineItemRow, QueryResultData, ResultCell, TimeColumn} from "../QueryResult";
import {normalizeString} from "../../line-item-utils/coding.utils";
import {values} from "ramda";
import {CELL_TEXT_FOR_ITEM_EXCLUDED_FROM_ROW_AGGREGATE, ROW_AGGREGATE_COLUMN_ID} from "./queryRunnerCommons";
import {AggregatorMethod, lineItemAggregatorMap} from "../../line-item-utils/aggregators";
import {TableBuilder} from "./TableBuilder";
import {buildTimeIndex, TimeIndex, TimeLine, TimeUnitView} from "../../Time.model";
import {QueryRunner} from "./QueryRunner";

export class StreamTimeSeriesTableBuilder extends TableBuilder {


  result: QueryResultData = {
    rows: [],
    columns: []
  }

  public buildTable(): QueryResultData {

    this.buildColumns()
    this.buildLoadingRows();

    setTimeout(() => {
      this.startProcessing();
    }, 300);

    return this.result;
  }

  getTimeLineTimeStamps() {
    return this.visibleTimeLine.map(it => it.date.getTime().toString());
  }


  startProcessing() {

    let timeLine = this.visibleTimeLine;

    //create Chunks
    let chunkSize = 20;
    let chunks: TimeUnitView[][] = [];

    for(let i = 0; i < timeLine.length; i += chunkSize) {
      chunks.push(timeLine.slice(i, i + chunkSize));
    }

    let timeIndexes = chunks.map((chunk) =>
      buildTimeIndex(chunk[0].date, chunk[chunk.length - 1].date, this.timeIndex.getUnit())
    );


    //Run in batches and sleep
    let interval = setInterval(() => {
      console.time("Processing time segment")
      console.info("time segments", timeIndexes.length)
      if(timeIndexes.length === 0) {
        console.timeEnd("Finished Processing")
        clearInterval(interval);
        return;
      }
      let timeIndexSegment = timeIndexes.shift()!;

      console.info("Current time segment", timeIndexSegment.startDate, timeIndexSegment.endDate)

      this.updateLineItems(timeIndexSegment);

      this.options.streamTable?.onData?.();

      console.timeEnd("Processing time segment");
    }, 1000);


  }

  private updateLineItems(timeIndex: TimeIndex) {
    let options = this.options;
    let lineItems = this.visibleLineItems;

    for (let i = 0; i < lineItems.length; i++) {
      let row = this.result.rows[i];
      let lineItem = lineItems[i];
      let timeValuesCell = this.timeValuesCells(lineItem,  timeIndex.timeLine, options);

      this.result.rows[i] = Object.assign(row, timeValuesCell);
    }
  }


  buildLoadingRows() {
    let options = this.options;
    let lineItems = this.visibleLineItems;

    let rows: LineItemRow[] = [];

    for (let i = 0; i < lineItems.length; i++) {
      let lineItem = lineItems[i];
      let nameCell: ResultCell = this.buildNameCell(lineItem, options);


      let row: any = {
        name: nameCell
      };

      if (options.showGranularity) {
        row.granularity = this.granularityCell(lineItem);
      }

      Object.assign(row, this.metadataCells(lineItem));

      if (options.withLineItemNameColumn) {
        row.lineItemName = this.buildLineItemNameColumn(lineItem);
      }

      rows.push(row);
    }


    //Add aggregates
    if (options.table?.aggregateColumnConfig) {
      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        row[ROW_AGGREGATE_COLUMN_ID] = {
          columnId: ROW_AGGREGATE_COLUMN_ID,
          text:  "..",
          type: 'metadata',
          value: 0
        }
      }
    }

    this.result.rows = rows;
  }

  private buildColumns() {
    let options = this.options;
    let columns: LineItemColumn[] = [];

    columns.push({
      type: 'header',
      columnId: 'name',
      text: 'Line Item'
    });

    if (options.withLineItemNameColumn) {
      columns.push({
        type: 'header',
        columnId: 'lineItemName',
        text: 'Line Item Name'
      });
    }

    if (options.withMetadata) {
      columns.push(...this.buildMetadataColumns(options.withMetadata));
    }

    if (options.showGranularity) {
      columns.push({
        type: 'header',
        columnId: 'granularity',
        text: 'Granularity'
      });
    }

    if (options.table?.aggregateColumnConfig) {
      columns.push({
        type: 'header',
        columnId: ROW_AGGREGATE_COLUMN_ID,
        text: 'Aggregate'
      })
    }


    this.visibleTimeLine.forEach((time) => {

      const timeColumn: TimeColumn & LineItemColumn = {
        type: 'time',
        // @ts-ignore
        columnId: time.date.getTime(),
        text: time.fDate,
        time: time.date.getTime(),
        timeIndex: time.time
      }
      columns.push(timeColumn);
    });


     this.result.columns = columns;
  }


  private buildLineItemNameColumn(li: LineItem): ResultCell {
    return {
      columnId: 'lineItemName',
      text: li.name,
      type: 'header',
      value: li.name
    }
  }


  private granularityCell(lineItem: LineItem | (LineItem & TimedLineItem)): ResultCell {
    if (isTimedLineItem(lineItem) || isTemplateLineItem(lineItem)) {
      return {
        columnId: 'granularity',
        text: (lineItem as (TemplateLineItem | TimedLineItem)).getTimeDefinition().granularity,
        type: 'metadata',
        value: (lineItem as (TemplateLineItem | TimedLineItem)).getTimeDefinition().granularity
      }
    } else {
      return {
        columnId: 'granularity',
        text: '',
        type: 'metadata',
        value: ''
      }
    }
  }

  getFieldStr(liName: string, fieldName: string) {
    return this.execution!.getFieldStr(liName, fieldName);
  }


  private rowAggregateCell(lineItem: LineItem, timeValuesCell: Record<string, ResultCell>, options: ResultOptions): ResultCell {
    if(options.table?.aggregateColumnConfig?.excludedItems?.map(normalizeString)?.includes(normalizeString(lineItem.name))){
      return {
        columnId: ROW_AGGREGATE_COLUMN_ID,
        text: CELL_TEXT_FOR_ITEM_EXCLUDED_FROM_ROW_AGGREGATE,
        type: 'metadata',
        value: 0
      }
    }
    let aggregatorMethod: AggregatorMethod = "sum";
    if (isTimedLineItem(lineItem)) {
      aggregatorMethod = lineItem.getTimeDefinition().aggregator;
    }

    let value = lineItem.getTotal(this.store.timeIndex.getRange(), this.execution!, lineItemAggregatorMap({}, aggregatorMethod)(lineItem));

    let formatterFunction = this.getFormattingFunction(lineItem);

    return {
      columnId: ROW_AGGREGATE_COLUMN_ID,
      text: formatterFunction(value),
      type: 'metadata',
      value: value
    }
  }

  private timeValuesCells(lineItem: LineItem, timeLine: TimeLine,  options: ResultOptions): Record<string, ResultCell> {

    let formatterFunction = this.getFormattingFunction(lineItem);

    let cells: Record<string, ResultCell> = {};

    timeLine.forEach((time) => {
      let value = this.execution!.getLineItemValue(
        lineItem,
        time.date.getTime(),
        this.timeIndex.getUnit()
      ).value;

      cells[time.date.getTime().toString()] = {
        type: 'lineItemValue',
        columnId: time.date.getTime().toString(),
        text: formatterFunction(value),
        value: value,
      }
    });

    return cells;
  }

}


