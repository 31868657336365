import {LineItemRow, LineItemsStore, QueryResult, StoreQuery} from "../../../ps-models/lineitems-store";
import React, {useEffect, useMemo} from "react";
import {loadSiteStore, useSiteStoreContext} from "../../siteStoreLoader";
import {Grid, Header, Icon, Segment, SemanticWIDTHS} from "semantic-ui-react";
import {formatSingleCellRow} from "./formatting";
import {NRNTimeSeriesChart} from "./NRNTimeSeriesChart";
import {buildTimeIndex, getFirstDateForCurrentMonthInUTC, utcDate} from "../../../ps-models";
import {ParameterLineItem} from "../../../ps-models/line-items";


export function AssetRegister() {
  const store = useSiteStoreContext();

  console.info(store)

  const result = useMemo(() => store.query(StoreQuery.byNames([
      'Site_ID__c',
      'Analytics_Serial_Number__c',
      'Name',
      'SPV_Fund__c',
      'State__c',
      'Post_Code__c',
      'Billing_Type__c',
      'Current_Energy_Retailer__c',
      'Solar_Bill_Rate_per_kWp__c',
      'Battery_Bill_Rate_per_kWh__c',
      'Optimisation_Control_Charge_Day__c',
      'Metering_and_Optimiser_Bill_Rate_per_Day__c',
      'kWp_Solar_System__c',
      'Battery_kWh__c',
      'Monthly Lease Rate',
      'original_asset_value__c',
      'current_value_post_dep__c',
      'monthly_depreciated_value__c',
      'Customer Buy Out Price'
    ]).withTimeIndex(buildTimeIndex(getFirstDateForCurrentMonthInUTC(), store.timeIndex.endDate,store.timeIndex.getUnit(), store.timeIndex._useRawDatesToBuildIndex))), [store]);
    let installedDateLi = (store.getDataSet().getLineItem("Installed_Date__c") as ParameterLineItem);
    let metricsChartEndDate = utcDate(2044, 1, 1);
    if(installedDateLi){
        let installedDateForSite =  installedDateLi.getValue().value;
        let installedDate = new Date(installedDateForSite.toString());
        metricsChartEndDate = utcDate(installedDate.getUTCFullYear()+20, installedDate.getUTCMonth(), installedDate.getUTCDate());
    }

    let metricsChart: QueryResult | undefined;
    metricsChart = store.query(StoreQuery.byNames([
        'Projected Current Value',
        'Customer Buy Out Price'
    ], true).withTimeIndex(buildTimeIndex(utcDate(2024, 1, 1),
        metricsChartEndDate,
        store.timeIndex.getUnit(),
        store.timeIndex._useRawDatesToBuildIndex))
    );

  return <Segment basic>
    <Header as="h2" color="purple"><Icon className="building outline" size="small" />Site Location</Header>
    <Segment>
      <ValueGrid metrics={[
          {lineItemRow: result.row("Site_ID__c"), label: "Site ID"},
          {lineItemRow: result.row("Analytics_Serial_Number__c"), label: "Analytics Serial Number"},
      ]} />
    </Segment>
      <Segment>
          <ValueGrid metrics={[
              {lineItemRow: result.row("SPV_Fund__c"), label: "SPV"},
              {lineItemRow: result.row("State__c"), label: "State"},
          ]} />
      </Segment>
    <Segment>
      <ValueGrid metrics={[
          {lineItemRow: result.row("Post_Code__c"), label: "Postal Code"},

      ]} />
    </Segment>
    <Header as="h2" color="purple"><Icon className="dollar" size="small" />Billing Rates and System Size</Header>
    <Segment>
      <ValueGrid metrics={[
          {lineItemRow: result.row("Billing_Type__c"), label: "Billing Type"},
          {lineItemRow: result.row("Current_Energy_Retailer__c"), label: "Retailer"},
      ]} />
    </Segment>

    <Segment>
      <ValueGrid metrics={[
          {lineItemRow: result.row("Solar_Bill_Rate_per_kWp__c"), label: "Daily Solar Fee"},
          {lineItemRow: result.row("Battery_Bill_Rate_per_kWh__c"), label: "Daily Battery Fee"},
      ]} />
    </Segment>
    <Segment>
      <ValueGrid metrics={[
        {lineItemRow: result.row("Optimisation_Control_Charge_Day__c"), label: "Daily Optimisation Control Fee"},
        {lineItemRow: result.row("Metering_and_Optimiser_Bill_Rate_per_Day__c"), label: "Daily Metering Data Fee"},
      ]} />
    </Segment>
    <Segment>
      <ValueGrid metrics={[
          {lineItemRow: result.row("kWp_Solar_System__c"), label: "Solar System kWp"},
          {lineItemRow: result.row("Battery_kWh__c"), label: "Battery kWh"},
      ]} />
    </Segment>

      <Header as="h2" color="purple"><Icon className="dollar" size="small" />Asset Values and Depreciation</Header>
      <Segment>
          <ValueGrid metrics={[
              {lineItemRow: result.row("original_asset_value__c"), label: "Original Asset Value"},
              {lineItemRow: result.row("current_value_post_dep__c"), label: "Current Asset Value"},
          ]} />
      </Segment>

      <Segment>
          <ValueGrid metrics={[
              {lineItemRow: result.row("monthly_depreciated_value__c"), label: "Monthly Depreciation"},
              {lineItemRow: result.row("Customer Buy Out Price"), label: "Customer Buy Out Price"},
          ]} />
      </Segment>
      <NRNTimeSeriesChart title={"Asset Values"} result={metricsChart}
                          options={{
                              type: "line",
                              sharedTooltip: true,
                              includeLineItems:[
                                  "Projected Current Value",
                                  "Customer Buy Out Price"
                              ]
                          }}/>
</Segment>
}

function ValueGrid({metrics}: { metrics: {lineItemRow: LineItemRow | undefined, label: string, decimals?: number}[] }) {
  return <Grid solid>
    <Grid.Row columns={metrics.length as SemanticWIDTHS}>
      {metrics.map(m => <Grid.Column><strong>{m.label}</strong></Grid.Column>)}
    </Grid.Row>
    <Grid.Row columns={metrics.length as SemanticWIDTHS}>
      {metrics.map(m => <Grid.Column>{formatSingleCellRow(m.lineItemRow)}</Grid.Column>)}
    </Grid.Row>
  </Grid>
}
