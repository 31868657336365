import {Redirect, Route} from "react-router-dom";
import React, {useEffect} from "react";
import '@silevis/reactgrid/styles.css';
import {PS_URLS} from "./core";
import getAssetsManagementRoutes from "./assets-management/router";
import getAdminRoutes from "./admin/router"
import {LineItemsEditor} from "./assets-management/line-items-editor";
import {TaskWorkflowsPage} from "./task-workflow/TaskWorkflows.page";
import "./IddleTimeRefresh";
import {
    NewAuthWrapper,
    authStorage
} from "./auth";

export default function Platform() {
    return [
            <Route exact path='/ps-new/:companyId/task-workflow/:projectId'
                   render={ (props) =>  <NewAuthWrapper><TaskWorkflowsPage projectId={props.match.params.projectId ?? ''}/></NewAuthWrapper> } />
        ,
          <Route exact path='/ps/'
               render={ () =>  <NewAuthWrapper render={() => <Redirect to={PS_URLS(authStorage.getCompany().id).assetsManagement()} />} /> } />
        ,
      <Route exact path='/ps/:companyId/builder'
             render={ () => <NewAuthWrapper><LineItemsEditor /></NewAuthWrapper> } />,
      ...getAdminRoutes(),
      ...getAssetsManagementRoutes()
    ].map((route, index) => React.cloneElement(route, {key: index}))
}
