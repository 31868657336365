import {REFETCH_ACTION, useUpdateContext} from "../../UpdateContext";
import {loadSiteStore, saveSiteStore, useSiteStoreContext} from "../siteStoreLoader";
import {Button, ButtonGroup} from "semantic-ui-react";
import React from "react";
import {StoreSetupType} from "../index";
import {authStorage} from "../../auth";
import {getAmProjectConfig} from "../../ps-models";

export function StoreActions({setMessage, storeSetup}: { setMessage: (message: string) => void, storeSetup: StoreSetupType }) {
  const ctx = useUpdateContext();
  const store = useSiteStoreContext();
  const company = authStorage.getCompany();
  const { collection } = getAmProjectConfig(company);

  function saveStore() {
    setMessage("...saving");
    saveSiteStore(collection, store).then(() => {
      setMessage("Store Saved");
    }).catch((err) => {
      setMessage("Error Saving Store " + JSON.stringify(err))
    });
  }
  function handleRefresh() {
    loadSiteStore(store.getId()!, collection, storeSetup).then(store => {
      ctx.setService(store);
      ctx.reload();
      ctx.fireAction(REFETCH_ACTION);
    });
  }


  return <>
    <ButtonGroup>
      <Button icon={"refresh"} onClick={handleRefresh}/>
      <Button floated="right" onClick={saveStore}> Save Data Set</Button>
    </ButtonGroup>
  </>
}