import {useHistory} from "react-router-dom";
import {useCompanyId, usePlatformUrls} from "./core";
import {Button} from "semantic-ui-react";
import * as React from "react";
import {getCompanyHome} from "./assets-management/config";
import {authStorage} from "./auth";

export function MainMenu() {
  const history = useHistory();
  let companyId = useCompanyId();
  let {assetsManagement} = usePlatformUrls();
  let redirectionUrl = '';
  if(!companyId){
    const company = authStorage.getCompany();
    const amCompanyHome = getCompanyHome(company.id,
        company.amProjectId ?? 'DEMO')
    redirectionUrl = amCompanyHome;
  }

  //Todo: HAcky code, need to fix this
  return <div>
    <Button onClick={() => history.push(redirectionUrl || assetsManagement())}>Asset Management</Button>
  </div>
}