// +validate

export type TimeUnits = "seconds" | "minutes" | "hours" | "days" | "weeks" | "months" | "quarters" | "years"

export type TimeUnit = number;

// +validate
export interface TimeIncrement {
  readonly unit: TimeUnits,
  readonly value: TimeUnit
}

export function utcDate(year: number, month: number, day: number, hour: number = 0, minute: number = 0, second: number = 0, ms: number = 0) {
  return new Date(Date.UTC(year, month, day, hour, minute, second, ms));
}

export function buildUTCDate(date: Date){
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();
  let ms = date.getMilliseconds();
  return utcDate(year, month, day, hour, minute, second, ms);
}

export interface TimeRange {
  start: number
  end: number
}