import {LineItemsStore, PersistenceQuery, pQuery} from "../../ps-models/lineitems-store";
import {buildWidgetConfig, buildWidgetConfigBase, getWidgetType} from "./WidgetRegistry";
import {
  LineItem,
  TIMED_CALCULATED_LINE_ITEM_TYPE,
  PARAMETER_LINE_ITEM_TYPE,
  TEMPLATE_LINE_ITEM_TYPE,
} from "../../ps-models/line-items";
import {LineItemDataSet, normalizeString, PartialBy, TimeUnits} from "../../ps-models";
import {mapObjIndexed, values} from "ramda";
import {WidgetConfig, WidgetConfigBase} from "./WidgetConfig";
import {EventHandler, EVENTS} from "./EventHandler";
import {Data} from "@measured/puck";


export interface DashboardFilterConfig {
  fiterLabel: string;
  fieldName: string;
  getFromSourceStore: boolean;
  formattedOptions?: boolean;
  getFromPersistanceStore?: boolean;
  required?: boolean;
  singleSelect?: boolean;
}

export interface DashboardLineItemExtensionsConfig {
    code: string,
    lineItemNames: string[],
    excludeGroupingLineItems: boolean,
}

type ExtraLineItemKind = 'Parameter' | 'Calculated' | 'Template';
type GlobalRangeSelectorConfiguration = {defaultGranularity: TimeUnits, availableGranularities: TimeUnits[]}

const DEFAULT_GLOBAL_RANGE_SELECTOR_CONFIG: GlobalRangeSelectorConfiguration = {defaultGranularity: 'months', availableGranularities: ["months", "quarters", "years"]}

export class DashboardConfigBase<T extends WidgetConfigBase> {
  protected query: PersistenceQuery = pQuery().withLineItems(["None"]);
  protected extraLineItems: Record<string, LineItem> = {};
  protected filters: DashboardFilterConfig[] = [];
  protected exposeGranularityFilter: boolean = false;
  protected exposeProjectVersionSelector: boolean = false;
  protected utcDateSelector: {rangeSelection?: boolean, withGranularitySelector?: boolean} | undefined = undefined;
  protected globalRangeSelectorConfig: GlobalRangeSelectorConfiguration = DEFAULT_GLOBAL_RANGE_SELECTOR_CONFIG;
  protected exposeScenarioComparator: boolean = false;
  protected handler?: EventHandler;

  protected lineItemExtensions?: DashboardLineItemExtensionsConfig[];

  layout: Omit<Data, 'content'> = {root:{}, zones: {}};

  widgets: Record<string, T> = {}

  addExtraLineItem(lineItem: LineItem) {
    this.extraLineItems[normalizeString(lineItem.name)] = lineItem;
  }


  setFilters(filters: DashboardFilterConfig[]) {
    this.filters = filters;
  }

  setExposeGranularityFilter(expose: boolean) {
    this.exposeGranularityFilter = expose;
  }

  setExposeProjectVersionSelector(expose: boolean) {
    this.exposeProjectVersionSelector = expose;
    this.handler?.fireEvent(EVENTS.GLOBAL_CONTEXT_CHANGED, this)
  }

  setExposeScenarioComparator(expose: boolean) {
    this.exposeScenarioComparator = expose;
  }

  setUTCDateSelector(valToExpose: {rangeSelection?: boolean, withGranularitySelector?: boolean} | undefined) {
    this.utcDateSelector = valToExpose;
  }

  setGlobalRangeSelectorConfig(value: GlobalRangeSelectorConfiguration) {
    this.globalRangeSelectorConfig = value;
  }

  isGranularityFilterExposed() {
    return this.exposeGranularityFilter;
  }

  isProjectVersionSelectorExposed(){
    return this.exposeProjectVersionSelector;
  }

  isScenarioComparatorExposed(){
    return this.exposeScenarioComparator;
  }

  getUTCDateSelector() {
    return this.utcDateSelector;
  }

  getGlobalRangeSelectorConfig(){
    return this.globalRangeSelectorConfig ?? DEFAULT_GLOBAL_RANGE_SELECTOR_CONFIG;
  }

  getFilters() {
    return this.filters;
  }

  getWidgets() {
    return this.widgets;
  }
  getLayout(){
    return this.layout;
  }
  getByID(id: string) {
    return this.widgets[id];
  }

  getByMachineName(machineName: string) {
    return values(this.widgets).find(w => w.machineName === machineName);
  }

  getLineItemExtensions() {
    return this.lineItemExtensions || [];
  }

  setLineItemExtensions(extensions: DashboardLineItemExtensionsConfig[]) {
    this.lineItemExtensions = extensions;
  }

  setQuery(query: PersistenceQuery) {
    this.query = query;
    this.handler?.fireEvent(EVENTS.QUERY_CHANGED, query);
  }

  getMenuConfig() {
    return this
  }

  getQuery(): PersistenceQuery {
    this.query.selectSourceParams([...this.query._selectParams, ...this.filters
      .filter(f => f.getFromSourceStore && !f.required)
      .map(f => f.fieldName)]);
    return this.query;
  }

  getExtraLineItems({getByTypes, excludeTypes}:{getByTypes?: ExtraLineItemKind[], excludeTypes?: ExtraLineItemKind[]}) {
    let lineItemsToReturn = values(this.extraLineItems);
    if(getByTypes){
      let typesToFilter: string[] = [];
      if(getByTypes.includes('Parameter')){
        typesToFilter.push(PARAMETER_LINE_ITEM_TYPE);
      }
      if(getByTypes.includes('Calculated')){
        typesToFilter.push(TIMED_CALCULATED_LINE_ITEM_TYPE);
      } else if(getByTypes.includes('Template')){
        typesToFilter.push(TEMPLATE_LINE_ITEM_TYPE);
      }
      return lineItemsToReturn.filter((li)=>typesToFilter.includes(li.type))
    }

    if(excludeTypes){
      let typesToExclude: string[] = [];
      if(excludeTypes.includes('Parameter')){
        typesToExclude.push(PARAMETER_LINE_ITEM_TYPE);
      }
      if(excludeTypes.includes('Calculated')){
        typesToExclude.push(TIMED_CALCULATED_LINE_ITEM_TYPE);
      } else if(excludeTypes.includes('Template')){
        typesToExclude.push(TEMPLATE_LINE_ITEM_TYPE);
      }
      return lineItemsToReturn.filter((li)=>!typesToExclude.includes(li.type))
    }
    return lineItemsToReturn;
  }

  removeExtraLineItem(liName: string) {
    delete this.extraLineItems[normalizeString(liName)];
  }
  //@TODO: This method should not be called from DashboardConfig service.
  setWidgets(widgets: Data['content']){
    this.widgets = [...widgets, ...Object.values(this.layout?.zones ?? {}).flat()].map((w)=>{
      const {id, config, machineName} = w.props;
      return {[id]: buildWidgetConfigBase(id, w.type, config, machineName)} as Record<string, T>;
    }).reduce((a,b)=>({...a,...b}), {});
  }

  //@TODO: This method should not be called from DashboardConfig service.
  setLayout(layout: DashboardConfigBase<WidgetConfigBase>['layout']){
    this.layout = layout;
  }

  serialize() {
    return {
      query: this.query.serialize(),
      extraLineItems: values(this.extraLineItems).map(li => li.serialize()),
      widgets: mapObjIndexed(w => w.serialize(), this.widgets),
      layout: this.layout,
      filters: this.filters,
      exposeGranularityFilter: this.exposeGranularityFilter,
      exposeProjectVersionSelector: this.exposeProjectVersionSelector,
      exposeScenarioComparator: this.exposeScenarioComparator,
      utcDateSelector: this.utcDateSelector,
      lineItemExtensions: this.lineItemExtensions,
      globalRangeSelectorConfig: this.globalRangeSelectorConfig,
    }
  }

  static deserialize(data: any, handler: EventHandler) {
    let ds = new DashboardConfigBase();
    ds.query = PersistenceQuery.deserialize(data.query);
    ds.extraLineItems = data.extraLineItems.map((li: any) =>(
            {[normalizeString(li.name)]:LineItemsStore.deserializeLineItem(li)}
    )).reduce((prev: any, curr: any)=>({...prev,...curr}), {});
    ds.widgets = mapObjIndexed((w: any) => WidgetConfigBase.deserialize(w), data.widgets);
    ds.layout = data.layout;
    ds.filters = (!data.filters || !Array.isArray(data.filters)) ? [] : data.filters;
    ds.exposeGranularityFilter = !!data.exposeGranularityFilter;
    ds.exposeProjectVersionSelector = !!data.exposeProjectVersionSelector;
    ds.exposeScenarioComparator = !!data.exposeScenarioComparator;
    ds.utcDateSelector=  data.utcDateSelector;
    ds.handler = handler;
    ds.lineItemExtensions = data.lineItemExtensions;
    ds.globalRangeSelectorConfig = data.globalRangeSelectorConfig;
    return ds;
  }
}

export class DashboardConfig extends DashboardConfigBase<WidgetConfig> {
  widgets: Record<string, WidgetConfig> = {};

  addWidget(widget: WidgetConfig) {
    let entry = getWidgetType(widget.type);
    this.widgets[widget.id] = widget;
    this.widgets[widget.id].setConfig({
      ...entry.defaultConfig,
      ...widget.config
    });
    let data = this.widgets[widget.id];
    this.handler?.fireEvent(EVENTS.WIDGET_ADDED, data);
  }

  getByParentId(parent: string) {
    return values(this.widgets)
      .filter(widget => widget.parent === parent && widget.id !== parent);
  }

  setWidgetConfig(id: string, config: any) {
    this.widgets[id].setConfig(config);
    this.handler?.fireEvent(EVENTS.WIDGET_CONFIG_CHANGED, {widgetId: id});
  }

  removeWidget(widget: WidgetConfig) {
    delete this.widgets[widget.id];
    this.handler?.fireEvent(EVENTS.WIDGET_DELETED, {widgetId: widget.id});
  }

  deleteWidget(id: string) {
    let data = this.widgets[id];
    delete this.widgets[id];
    this.handler?.fireEvent(EVENTS.WIDGET_DELETED, data);
  }

  static deserialize(data: any, handler: EventHandler) {
    let ds = new DashboardConfig();
    ds.query = PersistenceQuery.deserialize(data.query);
    ds.extraLineItems = data.extraLineItems.map((li: any) =>(
        {[normalizeString(li.name)]:LineItemsStore.deserializeLineItem(li)}
    )).reduce((prev: any, curr: any)=>({...prev,...curr}), {});
    ds.widgets = mapObjIndexed((w: any) => WidgetConfig.deserialize(w), data.widgets);
    ds.filters = (!data.filters || !Array.isArray(data.filters)) ? [] : data.filters;
    ds.exposeGranularityFilter = !!data.exposeGranularityFilter;
    ds.exposeProjectVersionSelector = !!data.exposeProjectVersionSelector;
    ds.exposeScenarioComparator = !!data.exposeScenarioComparator;
    ds.utcDateSelector=  data.utcDateSelector;
    ds.handler = handler;
    ds.lineItemExtensions = data.lineItemExtensions;
    ds.globalRangeSelectorConfig = data.globalRangeSelectorConfig;
    return ds;
  }
}

export function createNewDashboardConfig(v2?: boolean) {
  if(v2){
    return new DashboardConfigBase<WidgetConfigBase>();
  }
  let newDashboard = new DashboardConfig();

  newDashboard.addWidget(
    buildWidgetConfig(
      'root',
      'Container',
      {},
      'root'
    )
  );

  return newDashboard;
}
