
import {
  canBeNumber,
  FinancialValueType,
  round,
  STORE_VALUE_DECIMALS_KEY,
  VALUE_TYPE_KEY,
  valueAsNumber
} from "../../../ps-models";
import {LineItemRow} from "../../../ps-models/lineitems-store";
import {ValueType} from "../../../ps-models/line-items";

export function formatSingleCellRow(row: LineItemRow | undefined): string {
  if (row?.["0"]?.value === undefined) {
    return 'N/A'
  }
  if (row[VALUE_TYPE_KEY] === undefined) {
    return row["0"].value.toString()
  } else {
    return formatValueWithValueType(row["0"].value, row[VALUE_TYPE_KEY].value as FinancialValueType,
        row[STORE_VALUE_DECIMALS_KEY]?.value !==undefined ? parseInt(row[STORE_VALUE_DECIMALS_KEY]?.value as string) : undefined)
  }
}

export function formatValueWithValueType(value: ValueType, valueType: FinancialValueType, decimalPlacesInValue?: number): string {

  //Set default value type
  if(!valueType) {
    valueType = canBeNumber(value) ? "number" : "string";
    if(canBeNumber(value)) {
      value = valueAsNumber(value);
    }
  }


  if(valueType === "string") {
    return value?.toString() || "";
  }

  //@ts-ignore
  value = parseFloat( value || 0)

  switch (valueType.toLowerCase()) {
    case "zip":
      return value.toString()
    case "dollars":
      let sign = ""
      if (value < 0) {
        value = -value
        sign = "-"
      }
      return `${sign}$${formatNumber(value, decimalPlacesInValue)}`
    case "percentx100":
      return `${formatNumber(value * 100, decimalPlacesInValue)}%`
    case "percentage": case "percent":
      return `${formatNumber(value, decimalPlacesInValue)}%`
    case "multipleof":
      return `${formatNumber(value, decimalPlacesInValue)} x`
    case "kwh":
    case "kw":
    case "kwp":
    case "number":
      return formatNumber(value, decimalPlacesInValue).toString()
    default:
      return formatNumber(value, decimalPlacesInValue).toString()
  }
}

function formatNumber(value: any, decimalPlaces?:number) {
  if(decimalPlaces === undefined){
    return addComaToThousands(round(value as number, decimals(value)));
  } else {
    if(decimalPlaces === -1) {
      return addComaToThousands(value as number);
    } else {
      return addComaToThousands(round(value as number, decimalPlaces));
    }
  }
}

function addComaToThousands(num: number) {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

function decimals(value: number): number {
  return Math.abs(value) < 0 ? 3 : 2
}
