import {TimeIndex, TimeIndexDto} from "../Time.model";
import {StoreQuery} from "./StoreQuery";
import {ValueType} from "../line-items";
import {DateRangeType, LineItemFieldFilter} from "../../ps-types";

export interface StoreQueryFlatDto {
  // storeIds: string[],
  lineItems: string[],
  timeRange?: DateRangeType,
  timeIndex?: TimeIndexDto,
  _selectLineItemsHavingFieldValues?: Record<string, ValueType | ValueType[]>,
  _selectLineItemsHavingFields?: LineItemFieldFilter[],
  _calculatedLineItems?: string[],
}

export class StoreQueryFlat {
  constructor(
      // public storeIds: string[] = [],
      public lineItems: string[] = [],
      public timeRange?: DateRangeType,
      public timeIndex?: TimeIndex,
      public _selectLineItemsHavingFieldValues?: Record<string, ValueType | ValueType[]>,
      public _selectLineItemsHavingFields?: LineItemFieldFilter[],
      public _calculatedLineItems?: string[]
  ) {}

  serialize(): StoreQueryFlatDto{
    return {
      // storeIds: this.storeIds,
      lineItems: this.lineItems,
      timeIndex: this?.timeIndex?.serialize(),
      timeRange: this?.timeRange,
      _selectLineItemsHavingFieldValues: this?._selectLineItemsHavingFieldValues,
      _selectLineItemsHavingFields: this?._selectLineItemsHavingFields,
      _calculatedLineItems: this?._calculatedLineItems
    }
  }

  static deserialize(data: StoreQueryFlatDto): StoreQueryFlat{
    let storeQueryFlatInstance = Object.assign(new StoreQueryFlat(), {...data, ...(data.timeIndex ? {timeIndex: TimeIndex.deserialize(data.timeIndex)}: {})});
    return storeQueryFlatInstance;
  }

  withLineItems(lineItems: string[]) {
    this.lineItems = lineItems;
    return this;
  }

  withTimeIndex(timeIndex: TimeIndex) {
    this.timeIndex = timeIndex;
    return this;
  }

  selectLineItemsHavingFieldValues(fieldMap: Record<string, ValueType | ValueType[]>) {
    this._selectLineItemsHavingFieldValues = fieldMap;
    return this;
  }

  withCalculatedLineItems(calculatedLineItems: string[]) {
    this._calculatedLineItems = calculatedLineItems;
    return this;
  }

  // withStoreIds(storeIds: string[]) {
  //   this.storeIds = storeIds;
  //   return this;
  // }

  selectLineItemsHavingFields(fieldNames: LineItemFieldFilter[]) {
    this._selectLineItemsHavingFields = fieldNames;
    return this;
  }

  // @TODO: Complete this implementation...
  toStoreQuery(useCanonicalNames: boolean = true): StoreQuery{
    let baseQuery = StoreQuery.all();
    if(this.lineItems.length>0){
      baseQuery = StoreQuery.byNames(this.lineItems, useCanonicalNames)
    }

    if(this.timeIndex){
      baseQuery = baseQuery.withTimeIndex(this.timeIndex);
    }
    if(this.timeRange){
      baseQuery = baseQuery.inTimeRange(this.timeRange.from, this.timeRange.to);
    }
    if(this._selectLineItemsHavingFields){
      for(let fieldFilter of this._selectLineItemsHavingFields){
        let filterInclusionQuery;
        if(fieldFilter.value !==null && fieldFilter.value !==undefined){
          filterInclusionQuery = StoreQuery.byField(fieldFilter.name, fieldFilter.value.toString());
        } else {
          filterInclusionQuery = StoreQuery.withField(fieldFilter.name);
        }
        if(fieldFilter.applicableAsAnd){
          baseQuery = baseQuery.and(filterInclusionQuery);
        } else {
          baseQuery = baseQuery.or(filterInclusionQuery);
        }
      }
    }

    return baseQuery;
  }
}

