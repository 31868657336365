import firebase from "firebase";
import {LoginError} from "./errors";
import {
    UserCheckoutSessionData,
    UserCompany,
    UserData,
    UserGroup,
    UserProfile
} from "../ps-models/user";

export class PsUser {
    private readonly _tag: "PsUser" = "PsUser";

    constructor(
        private fbUser: firebase.User,
        private _token: string,
        readonly data: UserData
    ) {
        if (!_token || !_token.trim() || !data) {
            throw new LoginError("Please try again later.");
        }
    }

    get token(): string {
        return this._token
    }

    get email(): string {
        return this.fbUser.email as string
    }

    refreshToken(token: string) {
        this._token = token
    }

    get company(): UserCompany | null {
        return this.data.userCompany ?? null
    }

    get multiFactor(): firebase.User.MultiFactorUser {
        return this.fbUser.multiFactor
    }

    get requiresMultiFactor(): boolean {
        return this.data.require2FA ?? this.company?.require2FA ?? false
    }

    get emailVerified(): boolean {
        return this.fbUser.emailVerified
    }

    get id(): string {
        return this.fbUser.uid
    }

    get userGroups(): UserGroup[] {
        return this.data.userGroups ?? []
    }

    getProfile(): UserProfile {
        return this.data.profile ?? {
            email: this.data.email,
            firstName: this.data.firstName ?? "User",
            lastName: this.data.lastName ?? "",
            displayName: this.data.displayName ?? this.fbUser.displayName ?? this.email,
        }
    }

    get twoFactorAuthenticated(): boolean {
        return this.multiFactor && this.multiFactor.enrolledFactors.length > 0
    }

    setLinkedUserData(data: Record<string, any>) {
        this.data.linkedUserData = data
    }

    setUserCompany(company: UserCompany) {
        if (!this.data.userCompany) {
            this.data.userCompany = company
        }
    }

    addCheckoutSession(checkoutSession: UserCheckoutSessionData) {
        if(Array.isArray(this.data.checkout_sessions)){
            this.data.checkout_sessions.push(checkoutSession);
        } else {
            this.data.checkout_sessions = [checkoutSession];
        }
    }

    isPremium(adminAdjust:boolean= true): boolean{
        let currentUserCompanySubscriptions = this.data.userCompany?.subscriptions;
        const stripeOverride = this.data?.stripeOverride;

        if ((adminAdjust && this.isAdmin()) || stripeOverride === "active") {
            currentUserCompanySubscriptions = [...(currentUserCompanySubscriptions ?? []), { id: "ps-admin", metadata: { accessLevel: "1" }}]
        }
        if (stripeOverride === "inactive") {
            currentUserCompanySubscriptions = [];
        }

        const isPremium = (Array.isArray(currentUserCompanySubscriptions) && currentUserCompanySubscriptions.some(s=>checkPremiumSubscription(s))) ?? false;
        return isPremium;
    }

    isAdmin(): boolean {
        return this.data.email.endsWith("@perlstreet.com");
    }
}


export const checkPremiumSubscription = (subscription: any) => {
    let hasAcccess = false;
    if (subscription.metadata && subscription.metadata.accessLevel) {
        if (subscription.metadata.accessLevel === "1" || "level-1") hasAcccess = true;
    }
    return hasAcccess;
}
