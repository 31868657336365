import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import FirebaseAnalytics from "../src/firebase/firebase.analytics";
import "./index.css";
import App from "./App";
import { initialiseLogger } from "./utils/LoggerWrapper";
import reportWebVitals from "./reportWebVitals";
import { metricsQueue } from "./utils/metricsCollector";


import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('388edcb8eaeb0b2b1bc4c61d34093895Tz03OTExMCxFPTE3MzIxNDY2MjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


// initialise logger.
initialiseLogger();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
        <FirebaseAnalytics/>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((data) => {
  const { id, name, value } = data;
  metricsQueue.add({ id, name, value, metricCategory: "webvitals" });
});
