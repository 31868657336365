import React, {useState} from 'react';
import {Button, Form, FormInputProps} from "semantic-ui-react";
import {ListItemsInputFieldDef} from "./PreparedFormInputCommons";

function ListInput(pr: ListItemsInputFieldDef & {onValueChange: (value: string[]) => void, initialValue: string[] , onBlur: () => void }) {
    const {onValueChange, label, error,initialValue, onBlur, ...fieldProps} = pr
    const [items, setItems] = useState<string[]>(initialValue);

    const handleInputChange = (index: number, value: string) => {
        const newItems = [...items];
        newItems[index] = value;
        setItems(newItems);
        onValueChange(newItems.filter((it)=>it.length!==0));
    };

    const handleAddItem = () => {
        const newItems = [...items, '']
        setItems(newItems);
        onValueChange(newItems.filter((it)=>it.length!==0));
    };

    const {maxLengthPerItem, maxItems,...fieldPropsToPass}= fieldProps;

    return (<>
            <Form.Input label={label} required={true} input={<span>Specify items in your list
                <br/>
                <span style={{fontSize: '12px'}}>Each item can contain upto {maxLengthPerItem} characters.</span>
                <br/>
                <span style={{fontSize: '12px'}}>You can specify upto {maxItems} items.</span>
            </span>
            }/>
            <div className={"field"}
                 style={(error!==null ? {border: '1px solid #E0B4B4', color: '#9F3A38'}: {})}
            >
                {items.map((item, index) => (
                    <div key={index}>
                        <Form.Input
                            style={{marginBottom: '12px'}}
                            value={item || ''}
                            onChange={(e)=>handleInputChange(index, e.target.value)}
                            maxLength={maxLengthPerItem}
                            {...(fieldPropsToPass as FormInputProps)}
                        />
                    </div>
                ))}
                {error && <div className="ui pointing above prompt label" role={'alert'} aria-atomic={true}>
                    {error}
                </div>}
            </div>
            {items.length < maxItems && <Button type={'button'} color={'pink'} size={'mini'} icon={'plus square'} content={'Add Next'} onClick={handleAddItem}/>}
        </>
    );
}

export default ListInput;
