import ActionTypes from './projects.types';
// import UserActionTypes from '../user/user.types';

const INITIAL_STATE = {
  status: 'idle', // enum: 'idle' | 'loading' | 'success' | 'failed'
  error: null,
  currentProject: null,
  availableProjects: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.CALC_PROJECT_STARTED:
    case ActionTypes.LOAD_PROJECT_STARTED:
      return {
        ...state,
        currentProject: {
          status: 'loading',
          error: null,
          data: null
        }
      }
    case ActionTypes.CREATE_SHARETOKEN_SUCCESS:
      let newShareTokens = [ action.payload ];
      if (state.currentProject.data.shareTokens) newShareTokens.push(...state.currentProject.data.shareTokens)
      return {
        ...state,
        currentProject: {
          status: 'success',
          error: null,
          data: {
            ...state.currentProject.data,
            shareTokens: newShareTokens
          }
        }
      }
    case ActionTypes.CALC_PROJECT_SUCCESS:
      return {
        ...state,
        currentProject: {
          status: 'success',
          error: null,
          data: action.payload
        }
      }
    case ActionTypes.LOAD_PROJECT_SUCCESS:
      return {
        ...state,
        currentProject: {
          status: 'success',
          error: null,
          data: action.payload
        }
      }
    default:
      return state;
  }
}

export default reducer;