import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
    Menu,
    Container,
    Image,
    Button, Grid, Icon, Segment
} from 'semantic-ui-react'
import {useHistory} from 'react-router-dom';

import { UserDropdownActions } from '../UserDropdownActions/UserDropdownActions';
import { useLocation } from 'react-router-dom'
import {startEditCompany} from "../../redux/companies/companies.actions";
import {WaitForPromise} from "../../platform/generic.components";
import {useObservable} from "../../platform/reactive";
import {SyncResourceProvider} from "../../platform/SyncResourceContext";
import {authStorage} from "../../platform/auth";
import {SITE_URL} from "../../platform/core";
import {PageLoading} from "../../platform/ui/Loading";
import { AUTHENTICATION_PATHS } from '../../constants';

export const DashboardWrapper = ({ fullscreen, children, customContextResourceLoader, hatContextVariables }) => {
  const currentUserCompany = authStorage.getUser().data?.userCompany;
  const companyId = useMemo(()=> {
      return hatContextVariables?.companyId || currentUserCompany?.id;}, [
      hatContextVariables?.companyId, currentUserCompany?.id
  ])

  return <DashboardWrapperContextBridge companyId={companyId} fullscreen={fullscreen} children={children} customContextResourceLoader={customContextResourceLoader} />

}

const DashboardWrapperContextBridge = ({ companyId, fullscreen, children, customContextResourceLoader }) => {

  return <DashboardWrapperDependencyBridge fullscreen={fullscreen} children={children} />
}

const DashboardWrapperDependencyBridge = ({ fullscreen, children }) =>{
    const history = useHistory()
    const location = useLocation()
    const currentUser = authStorage.getUser();
    const currentUserCompany = currentUser.company;
    const dispatch = useDispatch();
    const editCompanyStartFn = useCallback((userCompanyId, companyAttributes)=> {
        dispatch(startEditCompany(userCompanyId, companyAttributes ,true))
    }, [dispatch]);

    return <DashboardWrapperImpl currentUser={currentUser} currentUserCompany={currentUserCompany} editCompanyStart={editCompanyStartFn} fullscreen={fullscreen} children={children} history={history} location={location} />
}

class DashboardWrapperImpl extends React.Component {
  render() {
    const { currentUser, fullscreen, history, location } = this.props;
    return (
      <React.Fragment>

        <Menu secondary style={{ background: "white", borderBottom: "1px solid #d5d5d5" }}>
          <Container fluid style={{ padding: "0 3em" }}>
            <Menu.Item  style={{ padding: 0, border: 'none' }}>
              <a href={SITE_URL} >
                <Image src={"/logos/Perl-Logo-Wide-Black-2x.png"} className="navLogo" />
              </a>
            </Menu.Item>
            <Menu.Item position="right">
            { location.pathname === '/projects/spv-mgmt' ? <Button basic style={{color: "#6435C9", "box-shadow": "none", "font-weight": "bold"}} onClick={() => history.push(AUTHENTICATION_PATHS.root)}>Account Home</Button> : null}
            { currentUser ?
                <>
                  <UserDropdownActions currentUser={currentUser} />
                </>
                : null
                // <Menu.Item>
                //   <Button as={Link} to="/login">
                //     Log In
                //   </Button>
                //   {/* <Button as={Link} color={BrandColors.SEMANTIC_COLOR_PRIMARY} style={{ marginLeft: '0.5em' }} to="/signup">
                //     Sign Up
                //   </Button> */}
                // </Menu.Item>
              }
            </Menu.Item>
          </Container> 
        </Menu>
        { !!fullscreen ? (
          this.props.children
        ) : (
          <div className="coloredBGCircle">
            <div style={{ margin: 0, padding: "3em", minHeight: "100vh" }}>
              <Container fluid>
                {this.props.children}
              </Container>
            </div>
          </div>
        ) }

      </React.Fragment>
    );
  }
}

export default DashboardWrapper;