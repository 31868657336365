import React, {useState} from "react";
import {useCompanyId} from "../../../core";
import {PortfolioPerformance} from "./PortfolioPerformance";
import {SiteSelectWrapper} from "../../SiteSelectWrapper";
import {AssetRegister} from "./AssetRegister";
import {PortfolioFinancials} from "./PortfolioFinancials";
import {LineItemsEditor} from "../../line-items-editor";
import {Dropdown, DropdownProps, Menu, Segment} from "semantic-ui-react";
import {Route, useHistory} from "react-router-dom";
import { CompanyLogo } from "./CompanyLogo";
import {CashFlow} from "../demo/CashFlow";
import {LoanManagement} from "./LoanManagement";
import {pQuery} from "../../../ps-models/lineitems-store";
import {MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING} from "../../index";
import {PortfolioPerformanceNRNACNO3} from "./PortfolioPerformanceACNO3";

type Section = { title: string, key: string, path: string, render: () => JSX.Element, getPath: () => string, isHome?: boolean }

function useSections(): Record<string, Section> {
  const [selectedSite, setSelectedSite] = useState<string | undefined>(window.localStorage.getItem("selectedStore") || undefined);
  const companyId = useCompanyId();
  const handleSelectedSite = (site: string) => {
    setSelectedSite(site)
  }

  const BASE_PATH = `/ps/:companyId/assets-management/b`;

  const buildSection = (data: Omit<Section, "getPath">): Section => {
    return {...data, getPath: () => data.path.replace(":companyId", companyId)}
  }

  let siteQuery = pQuery().metadataOnly().withStoreType("document")


  let sections = {
    "portfolio-performance": {
      ...buildSection({
        title: "Portfolio Performance",
        key: "portfolio-performance",
        path: BASE_PATH,
        render: () => <>
          <PortfolioPerformance goToSiteDetailTab={site => handleSelectedSite(site)}/>
        </>
      })
    },
    "portfolio-performance-nrn-ac-no3": {
      ...buildSection({
        title: "Portfolio Performance NRN AC NO3",
        key: "portfolio-performance-nrn-ac-no3",
        path: `${BASE_PATH}/portfolio-performance-nrn-ac-no3`,
        render: () => <>
          <PortfolioPerformanceNRNACNO3 goToSiteDetailTab={site => handleSelectedSite(site)}/>
        </>
      })
    },
    "aggregate-financials": {
      ...buildSection({
        title: "Aggregate Financials",
        key: "aggregate-financials",
        path: `${BASE_PATH}/aggregate-financials`,
        render: () => <>
          <PortfolioFinancials/>
        </>
      })
    },
    "asset-register": {
      ...buildSection({
        title: "Asset Register",
        key: "asset-register",
        path: `${BASE_PATH}/asset-register`,
        render: () => <>
          <SiteSelectWrapper selectedSite={selectedSite} setSelectedSite={setSelectedSite} query={siteQuery} storeSetup={MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["NRN"]}>
            <AssetRegister/>
          </SiteSelectWrapper>
        </>
      })
    },
    "loan-management": buildSection({
        title: "Loan Management",
        key: "loan-management",
        path: `${BASE_PATH}/loan-management`,
        render: () => <><LoanManagement/></>
      })
  };

  return sections;
}

function MainContent() {
  const sections = useSections();

  return <>{Object.values(sections).map(section =>
      <Route exact  path={section.path}  key={section.key} render={section.render} />
    )}
  </>
}

function MainMenu() {
  const sections = useSections();

  const goToSection = (section: string) => () => history.push(sections[section].getPath())
  const history = useHistory();
  return <Menu secondary tabular>
        <Menu.Item className={'nav-header-item'} onClick={goToSection('portfolio-performance')}>Portfolio Performance</Menu.Item>
        <Menu.Item className={'nav-header-item'} onClick={goToSection('portfolio-performance-nrn-ac-no3')}>Portfolio Performance NRN AC NO3</Menu.Item>
        <Menu.Item className={'nav-header-item'} onClick={goToSection("aggregate-financials")}>Portfolio Financials</Menu.Item>

        <Menu.Item className={'nav-header-item'} onClick={goToSection('loan-management')}>Loan Management</Menu.Item>
        <Menu.Item className={'nav-header-item'} onClick={goToSection('asset-register')}>Asset Register</Menu.Item>

  </Menu>
}
const Name = "NRN" as const;
export default  {
  MainContent, MainMenu, CompanyLogo, Name
}