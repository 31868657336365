import { useCompanyId } from "../../core";
import { useEffect, useState } from "react";
import { AmProject, ProjectVersion } from "../../ps-types";
import { getAmProjects } from "./amProject.client";
import { SmallLoading } from "../../ui/Loading";
import { indexBy, prop } from "ramda";
import { Button, Grid } from "semantic-ui-react";
import { ContentPopup } from "../../ui/popup/ContentPopup";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRowParams,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid-pro";

export interface ProjectVersionSelectionProps {
  title?: string;
  projects?: AmProject[];
  selectedProjectVersions?: ProjectVersion[];
  handleChange: (selectedProjectVersions: ProjectVersion[]) => void;
}


export function useDefaultProjectsVersions() {
  const [projects, setProjects] = useState<AmProject[]>([]);
  const [defaultProjectVersions, setDefaultProjectVersions] = useState<ProjectVersion[]>([]);
  const [status, setStatus] = useState("loading");

  let companyId = useCompanyId();

  useEffect(() => {
    async function update() {
      let projects = await getAmProjects(companyId);
      setProjects(projects);

      let defaultProjectVersions = projects
          ?.filter((p) => p.defaultVersion)
        .map((p) => ({projectId: p.id, versionId: p.defaultVersion! })) ?? []

      setDefaultProjectVersions(defaultProjectVersions);
      setStatus("ready");

    }
    update()
  }, []);

  return {status, projects, defaultProjectVersions};
}

/**
 * @param title
 * @param selectedProjectIds
 * @constructor
 */
export function ProjectVersionSelection({
  title = "Select Project Version(s)",
  projects,
  selectedProjectVersions = [],
  handleChange,
}: ProjectVersionSelectionProps) {
  const [state, setState] = useState({
    selectedIds: [] as string[],
    rows: [] as GridRowsProp,
    openModal: false,
    openedRow: [] as string[],
    groupedOptions: [] as any[],
    projectOptionsByValue: {} as Record<string, any>,
  });

  useEffect(() => {
    if (projects && selectedProjectVersions) {
      const projectVersionOptions = buildProjectVersionOptions(projects);
      const projectOptionsByValue = indexBy(
        prop("value"),
        projectVersionOptions
      );
      const selectedProjectOptions = selectedProjectVersions.map(
        (p) => `${p.projectId}-${p.versionId}`
      );

      const groupedOptions = projectVersionOptions.reduce(
        (acc: any[], current: any) => {
          if (current.isHeader) {
            acc.push({ ...current, child: [] });
          } else {
            const lastHeader = acc[acc.length - 1];
            if (lastHeader) {
              lastHeader.child.push(current);
            }
          }
          return acc;
        },
        []
      );

      setState((prevState) => ({
        ...prevState,
        projectOptionsByValue,
        groupedOptions,
        selectedIds: [
          ...new Set(selectedProjectOptions)
        ],
      }));
    }
  }, [projects, selectedProjectVersions]);

  useEffect(() => {
    if (state.groupedOptions.length > 0) {
      const rows = state.groupedOptions.flatMap((group: any) => {
        const headerRow = {
          hierarchy: [group.text],
          projectName: group.text,
          projectVersion: null,
          createdAt: new Date(group.createdAt),
          id: `parent-${group.key}`,
          selected: false,
        };

        const childRows = group.child.map((child: any) => ({
          hierarchy: [group.text, child.text],
          projectName: "",
          projectVersion: child.text,
          createdAt: new Date(child.createdAt),
          id: child.key,
          selected: state.selectedIds.includes(child.value),
        }));

        return [headerRow, ...childRows];
      });

      setState((prevState) => ({ ...prevState, rows }));
    }
  }, [state.groupedOptions, state.selectedIds]);

  if (!projects || !selectedProjectVersions) return <SmallLoading />;

  const onApply = () => {
    handleChange(
      state.selectedIds.map(
        (id) => state.projectOptionsByValue[id].projectVersion
      )
    );
    closePopup();
  };

  const closePopup = () => {
    setState((prevState) => ({
      ...prevState,
      openModal: false,
      openedRow: [],
    }));
  };

  const handleSelectionChange = (newSelection: string[]) => {
    const lastSelectedId = newSelection[newSelection.length - 1];
    const parent = state.groupedOptions.find((group: any) =>
      group.child.some((child: any) => child.value === lastSelectedId)
    );

    setState((prevState) => ({
      ...prevState,
      selectedIds: newSelection,
      openedRow: parent
        ? [...prevState.openedRow, `parent-${parent.value}`]
        : prevState.openedRow,
    }));
  };

  const getRowClassName = (params: GridRowParams) => {
    if (params.row.projectName) {
      return 'parent-row';
    }
    return '';
  };

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) =>
    row.hierarchy;

  const columns: GridColDef[] = [
    { field: "projectName", headerName: "Project Name", width: 200 },
    { field: "projectVersion", headerName: "Project Version", width: 250 },
    { field: "createdAt", headerName: "Created At", type: "date", width: 150 },
  ];

  return (
    <>
      <Button
        size="tiny"
        onClick={() =>
          setState((prevState) => ({ ...prevState, openModal: true }))
        }
      >
        {title}
      </Button>
      {state.openModal && (
        <ContentPopup
          header={
            <Grid columns="two">
              <Grid.Row>
                <Grid.Column>
                  <h3>Select Project Versions</h3>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    color="black"
                    floated="right"
                    size="tiny"
                    onClick={onApply}
                  >
                    Apply
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
          onClose={closePopup}
          size="large"
        >
          <Grid.Row>
            <DataGridPro
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              sx={{
                '& .parent-row > .MuiDataGrid-cellCheckbox': {
                  display: 'none !important',
                },
                '& .MuiDataGrid-columnHeader:first-of-type': {
                  display: 'none !important',
                },
              }}
              treeData
              rows={state.rows}
              columns={columns}
              getTreeDataPath={getTreeDataPath}
              checkboxSelection
              rowSelectionModel={state.selectedIds}
              isRowSelectable={(params) => !params.row.projectName}
              onRowSelectionModelChange={(newSelection) => {
                handleSelectionChange(newSelection as string[]);
              }}
              isGroupExpandedByDefault={(node) =>
                state.openedRow.includes(node.id as string)
              }
              getRowClassName={getRowClassName}
              pagination={true}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                }
              }}
              autoHeight
            />
          </Grid.Row>
        </ContentPopup>
      )}
    </>
  );
}

export function buildProjectVersionOptions(projects: AmProject[]) {
  let projectOptions: {
    key: string;
    value: string;
    text: string;
    isHeader: boolean;
    projectVersion?: ProjectVersion;
    createdAt: number;
  }[] = [];

  projects.filter((p) => p.versions.length > 0 && p.defaultStoreId).forEach((p) => {
      //add header
      projectOptions.push({
        key: p.defaultStoreId!,
        value: p.defaultStoreId!,
        text: p.name,
        isHeader: true,
        createdAt: p.createdAt,
      });

      const sortedVersions = p.versions.sort((a, b) => {
        if (a.versionId === p.defaultVersion) return -1;
        if (b.versionId === p.defaultVersion) return 1;
        return -a.title.localeCompare(b.title);
      });

      //add versions
      sortedVersions.forEach((v) => {
        projectOptions.push({
          key: `${p.id}-${v.versionId}`,
          value: `${p.id}-${v.versionId}`,
        text: `${v.title}${p.defaultVersion === v.versionId ? " (default)" : ""}`,
          isHeader: false,
          projectVersion: { projectId: p.id, versionId: v.versionId },
          createdAt: v.createdAt,
        });
      });
    });

  return projectOptions;
}
