import React, {ChangeEvent} from "react";
import '../LineItemsTableView.css';
import {Form, InputOnChangeData} from 'semantic-ui-react';
import {FinancialValueType, storeValueTypeField, valueAsNumber} from "../../ps-models";
import {LineItemsStore} from "../../ps-models/lineitems-store";
import {buildParameterLineItem, ParameterLineItem, ValueType} from "../../ps-models/line-items";
import {useUpdateContext} from "../../UpdateContext";

const labelSuffix: Record<FinancialValueType, string | null> = {percentage: '(%)',
    dollars: '($)', number: null, string: null, bool: null, kw: '(kw)', mw: '(MW)', kwh: '(kwh)',
    kwp: '(kwp)', zip: '(zip)', percentX100: '(%)',
    multipleOf: '(x)',
    percent: '(%)', dollarX1000: '($)', date: '', utc_mmddyyyy: '', utc_mmyyyy: '', "utc_mmm-yy": '', "utc_dd-mmm-yy": '', "utc_ddmmyy": '',  rupee: '(₹)', australianDollars: '(A$)', dollarthousand: "($K)", dollarmillion: "($M)", "c/kWh": '(c/kWh)', kWac: '(kWac)', "kWh/kWp/yr": '(kWh/kWp/yr)', "$/MW p.a.": "$/MW p.a."}
export const ParamLiInputs = ({
                                  lineItemName,
                                  unit = 'number',
                                  store
                              }: { lineItemName: string, unit?: FinancialValueType, store: LineItemsStore }) => {
    const ctx = useUpdateContext();
    const defaultValue = store.getDataSet().getLineItem(lineItemName) ? (store.getDataSet().getLineItem(lineItemName) as ParameterLineItem).getValue().value : null

    const handleChange = (e: ChangeEvent, {value}: InputOnChangeData) => {
        try {
            let existingLi = store.getDataSet().getLineItem(lineItemName);

            let liValue: ValueType | ValueType[] = value;
            if ((existingLi && existingLi instanceof ParameterLineItem) || (!existingLi && !Array.isArray(liValue))) {
                let newLineItem = existingLi ? buildParameterLineItem(lineItemName, valueAsNumber(liValue), existingLi.fields.clone()) : buildParameterLineItem(lineItemName, valueAsNumber(liValue), storeValueTypeField(unit));
                store.getDataSet().addLineItem(newLineItem);
                store.clearExecutionCache();
                ctx.ready();
            }
        } catch(err){
            console.error('The error while updating the item is', err)
        }
    };

    return <Form.Field>
        <label style={{fontWeight: 510}}>{lineItemName} {labelSuffix[unit]}</label>
        <Form.Input
            name={lineItemName}
            onChange={handleChange}
            type={'number'}
            {...(defaultValue !==null ? {defaultValue} : {})}
            {...(unit === 'percentage' ? {min: 0, max: 100} : {})}
        />
    </Form.Field>
};
