import {useSiteStoreContext} from "../../siteStoreLoader";
import {StoreQuery} from "../../../ps-models/lineitems-store";
import {Header, Segment} from "semantic-ui-react";
import React from "react";
import {LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {formatRows, fromNestedList} from "../../../lineitems-store/TableFormat";
import {fromPairs} from "ramda";

export function SiteP50P90Reports() {
  let store = useSiteStoreContext();


  const items = [
    'p50_projection',

    'p90_projection',
    'actual_production_kwh',

    'p50_variance',
    'p90_variance',
  ];

  let result = store.query(
      StoreQuery
          .byNames(items)
          .yearly()
          .inTimeRange(new Date(2023, 1, 1), new Date(2039, 1, 1))
  );

  let fResult = formatRows(fromNestedList({
    ...fromPairs(items.map((item) => [item, []]))
  }), result);

  return <Segment basic>
    <Header as="h2" color="purple">P50 / P90 Reports</Header>
    <LineItemsTableWithFormulas queryResult={fResult} store={store}/>
  </Segment>
}
