/** Collection of utilities that extend typescript/javascript language constructs
  * They must use no objects from any layer of the application
  */
import {formatDistance} from "date-fns";


export function tryOrUndefined<T, E extends Error>(
  f: () => T,
  exceptionType?: new (...args: any[]) => E
): T | undefined {
  try {
    return f();
  } catch (e) {
    if (exceptionType && !(e instanceof exceptionType)) {
      throw e;
    }
    return undefined;
  }
}

export function entries<K extends string, V>(obj: Record<K, V>): [K, V][] {
    return Object.entries(obj) as [K, V][]
}

export function enumerate<T>(arr: T[]): [number, T][] {
    return arr.map((v, i) => [i, v])
}

export function numericEntries<T>(arr: T[]): [number, T][]
export function numericEntries<K extends number, V>(obj: Record<K, V>): [K, V][]
export function numericEntries(obj: unknown): [unknown, unknown][] {
    if (Array.isArray(obj)) {
        return [...obj.entries()]
    }
    return Object.entries(obj as object).map(([k, v]) => [+k, v])
}

export function round(n: number, decimals: number = 0): number {
    return Math.round(n * Math.pow(10, decimals)) / Math.pow(10, decimals)
}

export const isRealNumber = (n: number) => !isNaN(n) && n !== Infinity && n !== -Infinity

//type BrandedString = `${string & ''}`;
//export type NormalizedString = `${BrandedString & 'NormalizedString'}`;
export type NormalizedString = string;


const NORM_CACHE = new Map<string, NormalizedString>();

export function normalizeString2(str: string): NormalizedString {
  return str.trim().replace(/\s+/gm, '').toLowerCase() as NormalizedString;
}

export function normalizeString(str: string): NormalizedString {

  if(NORM_CACHE.has(str)) {
    return NORM_CACHE.get(str) as NormalizedString;
  }

  let normalized =  str.toLowerCase().trim().replace(/\s/g, '') as NormalizedString;
  NORM_CACHE.set(str, normalized);
  return normalized;
}

export function compareNormalized(a: string, b: string) {
  return normalizeString(a) === normalizeString(b);
}

export function getCaseInsensitiveUniqueElementsFromStringArray(data: string[]) {
    const uniqueValues: Record<string, string> = {};

    data.forEach((item) => {
        if (!uniqueValues[item.toLowerCase()]) {
            uniqueValues[item.toLowerCase()] = item;
        }
    })
    return Object.values(uniqueValues);
}

export function maxElement<T>(col: T[], access: (n: T) => number): T {
    return findMostFit(col, Number.MIN_VALUE, (a, b) => a > b, access)
}

export function minElement<T>(col: T[], access: (n: T) => number): T {
    return findMostFit(col, Number.MAX_VALUE, (a, b) => a < b, access)
}

export function findMostFit<T, U>(col: T[], leastFit: U, compareFitness: (a: U, b: U) => boolean, access: (n: T) => U): T {
    let candidate = leastFit
    let result: T | undefined = undefined
    for (const c of col) {
        const cur = access(c)
        if (compareFitness(cur, candidate)) {
            result = c
            candidate = cur
        }
    }
    return result ?? col?.[0]
}

export function equalFloats(left: number, right: number): boolean {
    return Math.abs(left - right) < 0.001
}

export function normalizeMapKeys(map: Record<string, any>): Record<string, any> {
    return Object.fromEntries(Object.entries(map).map(([k, v]) => [normalizeString(k), v]))
}

export function valueAsNumber(value: any) {
    if(value instanceof Date) {
      return value.getTime();
    }

    if(typeof value === "string") {
        let maybeValue = parseFloat(value.replace(/,/g, ''));
        if(!isNaN(maybeValue)) {
            return maybeValue;
        }
        return 0;
    }

    if(value === null) {
        return 0;
    }

    return value as number
}

export function canBeNumber(value: any) {
    if(typeof value === "string") {
        let regex = /^-?\d+\.?\d*$/;
        return  regex.test(value.replace(/,/g, ''));
    }
    return typeof value === "number";
}

/**
 * Returns a number or a string.
 * If it's a number returns it trivialy
 * If it's a string that contains a number, extracts it and returns it
 * @param value
 */
export function tryExtractNumber(value: any): string | number {
    if(typeof value === "number") {
        return value;
    } else if(canBeNumber(value)) {
      //Regexp to extract the number from the string
      let regex = /-?\d+\.?\d*/;
      let match = value.match(regex);
      if(match) {
        return parseFloat(match[0]);
      }
      return value;
    }
    return value;
}

export function findInArrayFromEnd(arr: any[], callback: (val: any)=>boolean) {
    for (let i = arr.length - 1; i >= 0; i--) {
        if (callback(arr[i])) {
            return arr[i];
        }
    }
    return undefined;
}

export function convertToNumberOrString(value: any): string | number {
  if(typeof value === "number") {
    return value;
  } else if(canBeNumber(value)) {
        let converted = parseFloat(value);
        if(!isNaN(converted)) {
            return converted;
        }
    }

   return value?.toString() || "";
}
export function convertUnixTimeToExcelNumberTime(unixTime: number){
    return (unixTime/(86400*1000))+25569
}

export function convertToNumberStringOrBoolean(value: any) : boolean | string | number {
    if(typeof value === "boolean"){
        return value;
    } else {
        return convertToNumberOrString(value);
    }
}


//Utility type to make a single field of a type optional I.E. Optional<Record, "id">
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export function debounce(func: any, wait: number) {
    let timeout: any;
    return function executedFunction(...args: any) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

export function mapObjectValues<T, U>(obj: Record<string, T>, fn: (value: T) => U): Record<string, U> {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, fn(value)]))
}

export function joinStringsUsingHyphens(...strs: string[]): string{
    return strs.join(' - ');
}

export function parsePositiveNumber(value: string | undefined): number | undefined {
  if (value === undefined) return undefined
  const parsedNumber = parseInt(value)
  if (isNaN(parsedNumber) || parsedNumber <= 0) return undefined
  return parsedNumber
}

export class CancellablePromise<T> implements Promise<T> {
    private _cancelled = false
    constructor(private promise: Promise<T>) {}

    static makeCancellable<T>(promise: Promise<T>): CancellablePromise<T> {
        return promise instanceof CancellablePromise ? promise : new CancellablePromise(promise)
    }

    cancel() { this._cancelled = true }

    get [Symbol.toStringTag](): string { return this.promise[Symbol.toStringTag] }

    then<TResult1 = T, TResult2 = never>(onFulfilled?: ((value: T) => (PromiseLike<TResult1> | TResult1)) | undefined | null, onRejected?: ((reason: any) => (PromiseLike<TResult2> | TResult2)) | undefined | null): Promise<TResult1 | TResult2> {
        return this.promise.then(onFulfilled ? (v: any) => {
            if (this._cancelled) return
            return onFulfilled(v)
        } : undefined, onRejected ? (e: any) => {
            if (this._cancelled) return e
            return onRejected(e)
        } : undefined)
    }

    catch<TResult = never>(onRejected?: ((reason: any) => (PromiseLike<TResult> | TResult)) | undefined | null): Promise<T | TResult> {
        return this.promise.catch(onRejected ? (e: any) => {
            if (this._cancelled) return e
            return onRejected(e)
        } : undefined)
    }

    finally(onFinally?: (() => void) | null | undefined): Promise<T> {
        return this.promise.finally(onFinally ? () => {
            if (this._cancelled) return
            return onFinally()
        } : undefined)
    }
}

export function memoize<R, F extends (...args: any[]) => R>(func: F): F {
  const cache: Map<string, any> = new Map();

  const memoizedFunc = function(...args: any[]) {
    const cacheKey = JSON.stringify(args);

    if (cache.has(cacheKey)) {
      return cache.get(cacheKey);
    } else {
      const result = func(...args);
      cache.set(cacheKey, result);
      return result;
    }
  };

  return memoizedFunc as F;
}

export function timeAgo(value: string) {
    return value ? formatDistance(new Date(value), new Date(), {addSuffix: true}) : "";
}

export class LazyValue<T> {
  private value: T | undefined
  constructor(private readonly factory: () => T) {}

  get(): T {
    if (this.value === undefined) {
      this.value = this.factory()
    }
    return this.value
  }
}

export function isNullOrUndefined<T>(value: T | null | undefined): value is null | undefined {
  return value === null || value === undefined
}

export function firstNonUndefined<T>(...values: T[]): T | undefined {
  return values.find(v => !isNullOrUndefined(v))
}

export function reverseIndex(list: any[]) {
  return Object.fromEntries(list.map((v, i) => [v, i]))
}

export function cleanUpStringForWritingToCSV(value: string){
    if (value.includes(',') || value.includes('"')) {
        // Escape double quotes by doubling them
        value = value.replace(/"/g, '""');
        // Wrap the item in double quotes
        return `"${value}"`;
    }
    return value;
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>


