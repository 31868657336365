import bom1 from "./BOM_Data_File1.json";
import bom2 from "./BOM_Data_File2.json";
import bom3 from "./BOM_Data_File3.json";
import axios from "axios";
import {LineItemsStore, StoreQuery} from "../../../../ps-models/lineitems-store";
import {
  buildDailyTimeDef,
  buildMonthlyTimeDef, buildParameterLineItem, buildTimedCalculatedLineItem,
  buildTimedRawLineItem,
  buildYearlyTimeDef, LineItemsFieldSet
} from "../../../../ps-models/line-items";
import {avg, avgNZ, buildDailyTimeIndex, norminv, stDev, stDevNZ} from "../../../../ps-models";
import {buildLineItemsStorePersistence} from "../../../../lineitems-store/RestLineItemsStore.persistence";
import {loadSiteStore} from "../../../siteStoreLoader";
import {values} from "ramda";
import { StoreSetupType} from "../../../index";

const persistence = buildLineItemsStorePersistence();

export const hardcodedBomDataSetsByCoordinates = {
    [bom1.latitude + '_' + bom1.longitude]: bom1,
    [bom2.latitude + '_' + bom2.longitude]: bom2,
    [bom3.latitude + '_' + bom3.longitude]: bom3,
}
export const DAILY_GLOBAL_SOLAR_EXPOSURE_NAME = 'Solar Exposure (kWh/m2)'
export const DAILY_MEAN_NAME = 'Daily mean'
export const MONTHLY_MEAN_NAME = 'Monthly mean'
const ANNUAL_EXPECTED_PRODUCTION_NAME = 'expected_production_kwh_by_kw'
const ANNUAL_TOTAL_SOLAR_EXPOSURE_NAME = 'annual_total_solar_exposure_kwh_by_m2'

export interface BomData {
    bom: any,
    store: LineItemsStore,
    message: string,
}

export type SiteMeta = {name: string, id: string}

export async function editSiteStore(collection: string,storeSetup: StoreSetupType, site: SiteMeta, from: Date, to: Date): Promise<LineItemsStore> {
    const store = await loadSiteStore(site.id, collection, storeSetup, false)
    if (!store) {
        console.error(`Site ${site.name} (${site.id}) not found`)
        return createSiteStore(from, to)
    }
    store.timeIndex = buildDailyTimeIndex(from, to);
    return store
}

export function createSiteStore(from: Date, to: Date): LineItemsStore {
    const timeIndex = buildDailyTimeIndex(new Date(from), new Date(to))
    return persistence.createLineItemsStore(timeIndex)
}

export function addBomDataToStore(store: LineItemsStore, bom: any) {

    const dailyGlobalSolarExposureLi = buildTimedRawLineItem(DAILY_GLOBAL_SOLAR_EXPOSURE_NAME, buildDailyTimeDef());
    for (const entry of bom['entries']) {
        dailyGlobalSolarExposureLi.add(new Date(entry['day']), entry['daily_global_solar_exposure_kwh_by_m2']);
    }


    const dailyMeanLi = buildTimedCalculatedLineItem(DAILY_MEAN_NAME, buildMonthlyTimeDef('avg'),
        // language=JavaScript
        `"Solar Exposure (kWh/m2)" / daysInMonth()`
    );
    const monthlyTotal = buildTimedCalculatedLineItem("Monthly Total", buildMonthlyTimeDef('sum'),
      // language=JavaScript
      `"Solar Exposure (kWh/m2)"`
    );

    const monthlyMeanLi = buildTimedCalculatedLineItem(MONTHLY_MEAN_NAME, buildYearlyTimeDef('avg'),
      `"Monthly Total" / 12`
      );

    const annualExpectedProductionLi = buildTimedRawLineItem(ANNUAL_EXPECTED_PRODUCTION_NAME, buildYearlyTimeDef())
    const annualTotalSolarExposureLi = buildTimedRawLineItem(ANNUAL_TOTAL_SOLAR_EXPOSURE_NAME, buildYearlyTimeDef())
    for (const entry of bom['summary']) {
        annualExpectedProductionLi.add(new Date(entry['year']), entry['expected_production_kwh_by_kw'])
        annualTotalSolarExposureLi.add(new Date(entry['year']), entry['total'])
    }
    store.getDataSet().addLineItems([
        dailyGlobalSolarExposureLi,
        dailyMeanLi,
        monthlyTotal,
        monthlyMeanLi,
        annualExpectedProductionLi,
        annualTotalSolarExposureLi
    ])
}

export function getP50P90Stats(store: LineItemsStore) {


    store.getDataSet()
      .addLineItems([
            buildTimedCalculatedLineItem(
              'Average',
              buildDailyTimeDef(),
              `"Daily Expected Production (kwh)"`,

            ),
          buildTimedCalculatedLineItem(
            'STD',
            buildDailyTimeDef(),
            `"Daily Expected Production (kwh)"`,
          ),
        ]
      );


  /**
   *   TODO: This kind of code is very cumbersome, The reason for this is that we need to calculate a total Average and STD
   *   but we don't have something like TotalCalculatedLineItem that can work with the whole date
   *   so we have to create aliases of the line items and materialize the aggregate query to achieve that.
   */

    let yearlyStore = store.materializeTimed(StoreQuery.all().withTimeIndex(store.timeIndex.withGranularity("years")));


    let result = yearlyStore.query(StoreQuery.all());

    let yearlyProduction: number[] = [];
    values(result.row("Daily Expected Production (kwh)")!).forEach(cell => {
      if(cell.type === "lineItemValue") {
        yearlyProduction.push(cell.value as number);
      }
    });



    console.info("Yearly Prod", yearlyProduction);

    let average = avg(yearlyProduction);
    let std = stDev(yearlyProduction);
    let p50 = norminv(0.5, average, std);
    let p90 = norminv(1 - 0.9, average, std);

    console.info("AVG", avg(yearlyProduction));
    console.info("stDEV", stDev(yearlyProduction));
    console.info("p50", p50);
    console.info("p90", p90);

    let stats = yearlyStore.materializeTimed(
      StoreQuery.all().aggregateOverTimeRange(yearlyStore.timeIndex.startDate, yearlyStore.timeIndex.endDate)
    )


  stats.getDataSet().addLineItems(
      [
          buildParameterLineItem("Average", average),
          buildParameterLineItem("STD", std),
          buildParameterLineItem("p50", p50),
          buildParameterLineItem("p90", p90),
          buildTimedCalculatedLineItem("p50 Revenue", buildYearlyTimeDef(), '  "p50"  * "PPA Rate" ', {"store_valueType": "dollars"}),
          buildTimedCalculatedLineItem("p90 Revenue",  buildYearlyTimeDef(), '  "p90"  * "PPA Rate" ', {"store_valueType": "dollars"}),
      ]);



    return {yearlyStore, statsStore: stats};
}

export function addP50P90Parameters(store: LineItemsStore, expectedProduction: string, systemSize: number, ppaRate: number) {
    const expectedProductionLi = buildTimedCalculatedLineItem("Expected Production (per KW)",
      buildDailyTimeDef(),
      expectedProduction
    );

    const systemSizeLi = buildParameterLineItem("System Size", systemSize)
    const ppaRateLi = buildParameterLineItem("PPA Rate", ppaRate);


  const dailyExpectedProduction = buildTimedCalculatedLineItem(
    'Daily Expected Production (kwh)',
    buildDailyTimeDef(),
    `"Expected Production (per KW)" * "System Size" `,
  );

  const expectedRevenue = buildTimedCalculatedLineItem(
    'Daily Expected Revenue',
    buildDailyTimeDef(),
    `"Daily Expected Production (kwh)" * "PPA Rate" `,
    {
      store_valueType: 'dollars'
    }
  );

    store.getDataSet().addLineItems([expectedProductionLi, systemSizeLi, ppaRateLi, dailyExpectedProduction, expectedRevenue])
}

const GEOCODING_API_KEY = 'AIzaSyB-Hf-PnYcQrLen1SqoyOUv25eP4h7a48Y'
export type GeocodeResponse = { lng: number, lat: number } | 'empty'
export const getGeocode = async (address: string, signal: AbortSignal): Promise<GeocodeResponse> => {
    const res = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
            address: address,
            key: GEOCODING_API_KEY
        },
        timeout: 1000,
        signal
    });

    if (res.data.status === 'OK') {
        return res.data.results[0].geometry.location;
    } else if (res.data.status === 'ZERO_RESULTS') {
        return 'empty'
    } else {
        throw new Error(res.data.status);
    }
}
