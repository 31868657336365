import {AuthError} from "./index";
import React from "react";
import {Message} from "semantic-ui-react";
import renderCustomizedErrorMessage from "../../utils/renderCustomizedErrorMessage.utils";
function ErrorRenderer({ error }: { error: Error | null}) {
  if (error === null) {
    return <></>
  }
  let code = ''
  let title = 'Error'
  if (error instanceof AuthError) {
    code = error.code
    title = error.title
  }
  return (
      <React.Fragment>
        <Message error>
          <Message.Header>{title}</Message.Header>
          { renderCustomizedErrorMessage(code, error.message) }
        </Message>
      </React.Fragment>
  )
}

export default ErrorRenderer