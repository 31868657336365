import { Button, Grid } from "semantic-ui-react";
import { useDashboardService } from "../builder/DashboardConfigService";
import { useCompanyId } from "../../core";
import { LoadScenarios } from "./LoadScenarios.component";
import { ScenarioContext } from "./ScenarioFilter.component";

interface LoadScenario {
  setSelectedScenario: (string: string) => void;
  compareWith: string;
  setCompareWith: (string: string) => void;
  setCompareWithContext: (context: ScenarioContext) => void;
  compareWithName: string;
  noSelections?: boolean;
  scenarioTitle: string;
  selectedScenario: string;
  update: () => void;
}

export function LoadScenarioFilterSection({
  setSelectedScenario,
  setCompareWith,
  setCompareWithContext,
  scenarioTitle,
  compareWith,
  compareWithName,
  selectedScenario,
  update
}: LoadScenario) {
  const { getService } = useDashboardService();
  const dbService = getService();
  const companyId = useCompanyId();
  const hideCompareWith = dbService.getConfig().isScenarioComparatorExposed();
  return (
    <>
      {dbService.getConfig().isProjectVersionSelectorExposed() && (
        <Grid.Column width={6}>
          <LoadScenarios
            setSelectedScenario={setSelectedScenario}
            companyId={companyId}
            selectedScenario={selectedScenario}
            update={update}
          />
          <span>
            <strong>{scenarioTitle}</strong>
          </span>
        </Grid.Column>
      )}
      <Grid.Column width={3}>
        {compareWith && (
          <Button
            circular
            icon={"close"}
            floated="right"
            style={{ margin: "1px" }}
            size="tiny"
            compact
            onClick={() => {
              setCompareWith("");
              setCompareWithContext({
                storeIds: [],
                paramsMap: undefined,
                filterDetails: undefined,
              });
            }}
          />
        )}
        {hideCompareWith && (
          <LoadScenarios
            selectedScenario=""
            setSelectedScenario={setCompareWith}
            text={compareWithName}
            buttonColor={"orange"}
            styles={{ float: "right" }}
            companyId={companyId}
            update={update}
          />
        )}
      </Grid.Column>
    </>
  );
}
