import React, { useEffect } from 'react';
import {
  Container,
  Header,
  Segment,
} from 'semantic-ui-react';

import BrandColors from '../../utils/brandColors';
import './FAQsPage.styles.css';
import AppContainer from '../../components/AppContainer/AppContainer.component';
import Footer from '../../components/Footer/Footer.component';

const PageHeading = ({ mobile }) => (
  <div className="FAQsPageOverlay">
    <Segment style={{ padding: '0em 0' }} vertical>
      <Container text 
        textAlign="center"
        style={{
          paddingTop: '2em',
          paddingBottom: '1em'
        }}
      >
        <Header
          as='h2'
          content={
          <React.Fragment>
            <span>Frequently Asked Questions<span style={{color: BrandColors.HEX_COLOR_PRIMARY }}></span></span>
          </React.Fragment>}
          style={{
            fontSize: '3em',
            fontWeight: '700',
            marginTop: '0.5em',
            marginBottom: '0em'
          }}
        />
        {/* <Header
          as='h2'
          content={
          <React.Fragment>
            Don't see your question listed below?<br/>Drop us a note at <a href="mailto:team@perlstreet.com">team@perlstreet.com</a>
          </React.Fragment>}
          style={{
            fontSize: '1.7em',
            fontWeight: 'normal',
            marginTop: '1em',
            marginBottom: '0em'
          }}
        /> */}
      </Container>
    </Segment>
  </div>
);

const FAQsPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <AppContainer {...props}>

      <PageHeading {...props} />

      <Segment vertical className="white" style={{ marginBottom: '-0.5em', paddingBottom: '0.5em' }}>
        <Container text>

        <Header as='h3'>
          Why does SPV financing and off-balance sheet structuring matter?
        </Header>
        <p>
          Because many hardware companies have a balance sheet problem. Without disaggregating cash flows and properly structuring the business, they might not be able to lend against it at the scale they need in order to grow - that is if they can get a loan at all. Furthermore, many lenders are familiar with or even require a bankruptcy-remote SPV financing structure.
        </p>

        <Header as='h3'>
          How does my company benefit from the project if it is deployed into an SPV or project company?
        </Header>
        <p>
          Depending on the structure, your company (now the HoldCo) can become essentially asset-light. Profits from the project entities can be swept back (a process known as distributions or dividends) to the HoldCo periodically, for example every 3 months. This frees up your HoldCo to raise equity and build out a team without worrying about profitability and maintaining a strong debt service coverage ratio (DSCR) or other required lender metrics.
        </p>

        <Header as='h3'>
          Is an SPV financing structure always necessary?
        </Header>
        <p>
          No, this ultimately depends on the lender’s requirements. However, SPV structuring brings a number of benefits for both you and for the lender. Reach out to <a href="mailto:team@perlstreet.com">team@perlstreet.com</a> to learn more.
        </p>

        <Header as='h3'>
          What if my company doesn't have physical hardware?
        </Header>
        <p>
          Our solution works for most capital-intensive business models, including even those without hardware. Please reach out to us at <a href="mailto:team@perlstreet.com">team@perlstreet.com</a> to see if you’re a good fit.
        </p>

        <Header as='h3'>
          What if I'm also raising an equity round?
        </Header>
        <p>
          That's great! Raising alternative capital alongside a traditional equity financing round will give you more leverage when it comes to negotiation, provides early evidence of scalability and profitability, and allows for equity funds to be used for higher ROI activities rather than financing inventory, for example - which will result in an increased ROE for current and future equity investors.
        </p>

        <Header as='h3'>
          Why not just use Venture Debt?
        </Header>
        <p>
          Venture debt is generally raised as a percentage of your company’s overall cash reserves given your burn rate, and sometimes includes warrants or restrictions on burn. While it can be useful as a short-term tool to extend your runway - it is not a scalable form of financing, and is generally capped at about 20%-30% of your cash on hand.
        </p>

        <Header as='h3'>
          Is this voodoo magic?
        </Header>
        <p>
          No, this is just an <a href="https://link.medium.com/PweTfxQjK8" target="_blank" rel="noopener noreferrer">application of financial engineering</a> and project finance tools and structures that would normally only be cost-effective or possible for multi-billion dollar projects. Our structures don’t change the underlying business, but they align the incentives between customers, suppliers, lenders and shareholders.
          Our technology platform, stardardized approach, and aggregation tools allow us to bring this methodology to even early stage hardware startups for the first time. 
        </p>

        </Container>
      </Segment>

      <Footer />
    </AppContainer>
  );
}

export default FAQsPage;