import {DashboardBoardView} from "../builder/DashboardView";
import React, {useEffect, useState} from "react";
import {useCompany, useCompanyId} from "../../core";
import {getAmDashboard} from "../builder/Dashboard.client";
import {DashboardConfigService, getSortIndex} from "../builder/DashboardConfigService";
import {
    Button,
    Container, Dimmer, Divider,
    Embed,
    Form,
    Grid, Icon, Input, Loader,
    Segment, Table,
} from "semantic-ui-react";
import {
    generateAmInvoice,
    getAmInvoice, getAmInvoiceDownloadUrl, getAmInvoicePreviewUrl, recordCustomerPayment,
} from "./amInvoice.client";
import {
    AmDashboard, AmInvoice, AmInvoicePaymentEntry,
} from "../../ps-types";
import {QueryResultData} from "../../ps-models/lineitems-store";
import { useMessages } from "../../ui/MessagesProvider";
import { SmallLoading } from "../../ui/Loading";
import {
    getAmProjectConfig,
    OutputMapping,
    ExcelReportInjectionConfig,
    formatDateByTimeUnit,
    isNullOrUndefined
} from "../../ps-models";
import {useForm} from "../../ui/UseForm";
import {clone} from "ramda";

function formatDate(time: number) {
    let date = new Date(time);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

export function InvoiceDetailPage({invoiceId}: {invoiceId: string}) {
    const companyId = useCompanyId();
    const company = useCompany();
    const { namespace } = getAmProjectConfig(company);
    const amProjectId = namespace;
    let {clear, error} = useMessages();
    const [invoice, setInvoice] = useState<AmInvoice>();
    const [dService, setDService] = useState<DashboardConfigService>();
    const [dashboard, setDashboard] = useState<AmDashboard>();
    const [invoicePreviewUrl, setInvoicePreviewUrl] = useState<string>();
    const [touchingInvoice, setTouchingInvoice] = useState<boolean>(false);

    useEffect(() => {
        (async function loadInvoice() {
            setTouchingInvoice(true);
            let invoice = await getAmInvoice(companyId, invoiceId);
            setInvoice(invoice);
            if(invoice.fileUri){
                let previewUrl = await getAmInvoicePreviewUrl(companyId, invoiceId);
                setInvoicePreviewUrl(previewUrl.url);
            } else {
                let dashboard = await getAmDashboard(companyId, invoice.dashboardId);
                setDashboard(dashboard);
            }
            setTouchingInvoice(false);
        })();
    }, []);

    const handleDownloadInvoice = async (invoiceId: string) => {
        if(!invoice) {
            console.error("Invoice not loaded");
            return;
        }
       
        let res = await getAmInvoiceDownloadUrl(companyId, invoiceId, "pdf");
        window.open(res.url, "_blank")
    }
    const triggerInvoiceReload = async () => {
        console.info("trigger Invoice reload");
        clear();
        setTouchingInvoice(true);
        try {
            console.info("Alright 1")
            let latestInvoice = await getAmInvoice(companyId, invoiceId);
            setTouchingInvoice(false);
            setInvoice(latestInvoice);
            console.info("Alright 2")
        } catch (e) {
            console.error("Error getting updated invoice", e);
            error("Error getting updated invoice");
        }
        setTouchingInvoice(false);
        return;
    }

    const handleSendInvoice = async () => {
        clear();
        setTouchingInvoice(true);
        if(!dService) {
            console.error("Dashboard service not initialized");
            setTouchingInvoice(false);
            return;
        }
        if(!invoice) {
            console.error("Invoice not loaded");
            setTouchingInvoice(false);
            return;
        }
        // if(!dashboard) {
        //     console.error("Template Dashboard not loaded");
        //     setTouchingInvoice(false);
        //     return;
        // }

        try {
// @TODO: Actual code for sending invoice goes here
            setTouchingInvoice(false);
            // setInvoice(updatedInvoice);
        } catch (e) {
            console.error("Error sending invoice", e);
            error("Error sending invoice");
        }

        setTouchingInvoice(false);
    }

    const handleGenerateInvoice = async () => {
        clear();
        setTouchingInvoice(true);
        if(!dService) {
            console.error("Dashboard service not initialized");
            setTouchingInvoice(false);
            return;
        }
        if(!invoice) {
            console.error("Invoice not loaded");
            setTouchingInvoice(false);
            return;
        }
        if(!dashboard) {
            console.error("Template Dashboard not loaded");
            setTouchingInvoice(false);
            return;
        }
        const outputMapping: OutputMapping = {};

        const widgets = dService.getAllWidgets()
            .sort((a, b) =>
                getSortIndex(a.config) - getSortIndex(b.config));

        let errorList: string[] = [];
        for(let widget of widgets){
            let widgetOutput = widget.getOutputs();
            if('queryResultData' in widgetOutput){
                outputMapping[widget.machineName] = {queryResultData: clone(widgetOutput['queryResultData']) as QueryResultData};
            } else if('value' in widgetOutput){
                let value = widgetOutput['value'];
                if(widget.config.type === 'string'){
                    if(!value){
                        errorList.push(`${widget.machineName}`);
                    } else {
                        outputMapping[widget.machineName] = {value};
                    }
                } else {
                    value = parseInt(value);
                    if(isNaN(value)) {
                        errorList.push(`${widget.machineName}`);
                    } else {
                        outputMapping[widget.machineName] = {value};
                    }
                }
            }

            if(!widgetOutput) {
                console.warn(`No data found for widget ${widget.machineName}`);
            }
        }

        if(errorList.length > 0) {
            error(`Missing Values: ${errorList.join(", ")}`);
            setTouchingInvoice(false);
            return;
        }
        const injectionConfig: ExcelReportInjectionConfig = dashboard?.injectionTemplateConfig?.injectionConfig ?? buildDefaultInjectionConfig(amProjectId, outputMapping);

        // @TODO: This is done dirtly here.
        // Adding Invoice Date to outputMapping and also to injection config...
        outputMapping["Invoice Date"] = {value: Intl.DateTimeFormat('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            }).format(invoice?.config?.dateRange.from)};
        // We need just one of these injections once we migrate to usage of microsoft graph api for injection. Right now the excel formulas are not re-evaluated since.
        injectionConfig.push({outputOf: "Invoice Date", startCell: "E4", sheetName: "Invoice"});
        injectionConfig.push({outputOf: "Invoice Date", startCell: "B19", sheetName: "Invoice"});
        injectionConfig.push({outputOf: "Invoice Date", startCell: "F25", sheetName: "Invoice"});

        for(let injection of injectionConfig){
            if(!outputMapping[injection.outputOf]){
                console.error(`No data found for ${injection.outputOf}`);
                error(`No data found ${injection.outputOf}`);
                setTouchingInvoice(false);
                return;
            }
        }
        // Core fields reqd for Invoice
        for(let invoicingKey of ["Invoice Number", "Invoice Amount"]) {
            if(!outputMapping[invoicingKey]){
                const eMsg = `${invoicingKey} not configured for template. Please select a valid invoice template`
                console.error(eMsg);
                error(eMsg);
                setTouchingInvoice(false);
                return;
            }
        }

        try {
            const invoiceData: AmInvoice['data'] = {
                number: (outputMapping["Invoice Number"] as any).value,
                dated: Intl.DateTimeFormat('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                }).format(invoice?.config?.dateRange.from),
                amount: (outputMapping["Invoice Amount"] as any).value,
                dueAmount: (outputMapping["Invoice Amount"] as any).value,
            }
            let updatedInvoice = await generateAmInvoice(companyId, invoiceId, outputMapping, invoiceData,{
                ...(dashboard?.injectionTemplateConfig ?? {}),
                injectionConfig,
            });
            setTouchingInvoice(false);
            setInvoice(updatedInvoice);
            if(updatedInvoice.fileUri){
                try {
                    let previewUrl = await getAmInvoicePreviewUrl(companyId, invoiceId);
                    setInvoicePreviewUrl(previewUrl.url);
                } catch(err){
                    console.error("Error fetching preview url for invoice", err);
                    error("Error fetching preview url for invoice");
                }
            }
        } catch (e) {
            console.error("Error generating invoice", e);
            error("Error generating invoice");
        }

        setTouchingInvoice(false);
    }

    return (
        <Container>
            <Segment style={{position:'relative'}}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}><div >
                                    <h2>{invoice?.name}</h2>
                            {invoice?.config && <div>
                                <><strong>For</strong> {formatDateByTimeUnit(invoice.config.dateRange.from, 'months')} <br/></>
                            </div>}

                            {invoice?.createdAt && <div>
                                <strong>Created At</strong> {formatDate(invoice.createdAt)} <br/>
                            </div>}

                            {invoice?.status && <div>
                                <strong>Status</strong> {invoice.status} <br/>
                            </div>}

                            <p>&nbsp;</p>
                            <div style={{position: 'absolute', bottom: "10px", left: "10px", fontSize: "12px"}}>

                            {/*<ButtonGroup>*/}

                                {invoice?.fileUri ?
                                    <Button
                                        // primary
                                        disabled={touchingInvoice}
                                        loading={touchingInvoice}
                                        onClick={handleSendInvoice}>Send Invoice</Button>
                                    : <Button
                            primary
                            disabled={touchingInvoice}
                            loading={touchingInvoice}
                            onClick={handleGenerateInvoice}>Generate Invoice</Button>}
                            </div>
                            <br/>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={8}>
                {invoice?.generatedAt && <Button
                    icon="download"
                    size="mini"
                    label={"Download Invoice"}
                    onClick={() => handleDownloadInvoice(invoice.id)} />}
                        </Grid.Column>
                    </Grid.Row>
               </Grid>
            </Segment>
            {/*{touchingInvoice && <SmallLoading message="Loading Invoice..." />}*/}
            {invoice && invoice?.fileUri && <>
                <div style={{ position: 'relative', height:'800px' }}>
                    {!invoicePreviewUrl && (
                        <Dimmer active inverted>
                            <Loader size="large" active>Loading Invoice Preview...</Loader>
                        </Dimmer>
                    )}
                    <iframe
                        src={invoicePreviewUrl}
                        title={'Invoice Preview'}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                        }}
                    />
                </div>
                <CustomerPayments invoice={invoice} companyId={companyId} reloadInvoice={triggerInvoiceReload} />
            </>}
            {invoice && dashboard && !invoice?.fileUri && <DashboardBoardView
              dashboardId={dashboard.id}
              dashboardData={dashboard}
              onServiceReady={(service) => {
                setDService(service);
              }}
              constrainedViewDateRange={invoice.config.dateRange}
              constrainedStoreIds={invoice.config.storeIds}
            />}
        </Container>
    )
}

function buildDefaultInjectionConfig(amNamespace: string, outputMapping: OutputMapping): ExcelReportInjectionConfig{
     // Default Injection Config, which injects in an empty excel. In sheet named "Report", in a top-down fashion.
        let injectionConfig: ExcelReportInjectionConfig = [];
        let lastRowNumWrittenTo= 0;
        for(let [machineName, output] of Object.entries(outputMapping)){
            injectionConfig.push({outputOf: machineName, startCell: `A${lastRowNumWrittenTo+1}`, sheetName: "Report"})
            if('queryResultData' in output){ // +1 to include the header row
                lastRowNumWrittenTo += output.queryResultData.rows.length+1;
            } else if('value' in output){
                lastRowNumWrittenTo += 1;
            }
        }
        return injectionConfig;
}

function CustomerPayments({invoice, companyId, reloadInvoice}: {invoice: AmInvoice, companyId: string, reloadInvoice:()=>void}){
    const [error, setError] = useState<any>(null)
    const { formData, handleChange, handleSubmit, submitting, error: e1 } = useForm<AmInvoicePaymentEntry>({
        initialState: {
            amount: 0,
            date: new Date().getTime(),
        },
        onSubmit: async (data) => {
            if(isNullOrUndefined(data.date) || isNullOrUndefined(data.amount)){
                throw new Error('Date and Amount are mandatory');
            }
            if(data){
                data.amount = parseFloat(data.amount as unknown as string);
            }
            await recordCustomerPayment(companyId, invoice.id, data);
            return reloadInvoice();
        },
        setError
    })
    const date = new Date(invoice.data.dated);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const paymentAcceptedFromDate = `${year}-${month}-${day}`;

    return <>
        {invoice.status === "Un-paid" && <>
            <h3>Record Payment from Customer</h3>
            <Form size='large' onSubmit={handleSubmit} className="outlineForm">
            <Form.Input
                fluid
                placeholder='Amount'
                type='number'
                name="amount"
                min={0}
                max={invoice.data.dueAmount}
                value={formData.amount}
                onChange={handleChange}
                label={`Amount (Max $${invoice.data.dueAmount})`}
            />
            <Form.Input
                fluid
                placeholder='Date'
                type='date'
                name="date"
                value={formData.date}
                onChange={handleChange}
                label={'Date'}
                min={paymentAcceptedFromDate}
            />

            <Divider hidden />
            <Divider hidden />

            <Button primary type="submit" className="raised" size='huge' loading={submitting} disabled={submitting} >
                Capture
                <Icon name='arrow right' />
            </Button>
        </Form></>}
    <h4>Payment History</h4>
    <Table celled
           selectable
           compact
           size='small'
           style={{cursor: 'pointer'}}
    >
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {invoice?.payments.map((payment, idx) => (
                <Table.Row key={idx}>
                    <Table.Cell>{payment.date}</Table.Cell>
                    <Table.Cell>{payment.amount}</Table.Cell>
                </Table.Row>
            ))}
        </Table.Body>
    </Table>
    </>
}