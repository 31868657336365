import {Button, Container, Form, FormField, Input, Message, Segment, Table} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {useCompanyId} from "../../core";
import {ExcelMapperResult} from "../../ps-models/exa";

import {CodeEditor} from "../../ui/CodeEditor";
import {LineItemsStore, StoreQuery} from "../../ps-models/lineitems-store";
import {LineItemsTableWithFormulas} from "../../lineitems-store/LineItemsTableView";
import {PsFileUploader} from "../../ui/FileUploader.component";
import {checkTemplate} from "./amProject.client";
import {ProjectTemplateConfig} from "../../ps-types";
import {useInstantUpdater} from "../../generic.hooks";
import {CompanyTemplates} from "./CompanyTemplates";
import {saveTemplateConfig} from "../../company/company.client";

export function TemplateConfig() {

  const companyId = useCompanyId();
  const [result, setResult] = useState<ExcelMapperResult>();
  const [code, setCode] = useState<string>("");
  const [file, setFile] = useState<File>();

  const [templateConfigName, setTemplateConfigName] = useState<string>("");

  let [refresh, update] = useInstantUpdater();

  const handleCheck = () => {
    if(!file) return;
    checkTemplate(companyId, file, code, ()=>{})
      .then((res) => {

        setResult(res.data as ExcelMapperResult);
      });
  }

  const handleFileCallback =  (f?: File) => {
    setFile(f);
  }

  const handleLoadTemplate = (templateConfig: ProjectTemplateConfig) => {
      console.info("Loading template config", templateConfig);
      setCode(JSON.stringify(templateConfig.config, null, 2));
  }

  const handleSaveTemplate = () => {
    saveTemplateConfig(companyId, {
      configName: templateConfigName,
      configKey: templateConfigName,
      config: JSON.parse(code)
    }).then(() => {
      update();
    });
  }

  return <div>
    <Container>
    <Segment>
       <Form>

       <PsFileUploader setFile={handleFileCallback} />

        <p> <h3>Template Config</h3>

          <FormField>
            <label>Load Config</label>
            <CompanyTemplates key={refresh} onSelect={handleLoadTemplate}  />
          </FormField>

          <FormField>
            <label>Template Config</label>
          <Input
            value={templateConfigName}
            onChange={(e, {value}) => setTemplateConfigName(value)}
            placeholder='Template Config Name'
          />
          </FormField>
        <CodeEditor code={code} onChanged={setCode} />
        </p>
        <Button primary onClick={handleCheck}>Check</Button>
        <Button primary onClick={handleSaveTemplate}>Save Template</Button>

       </Form>
      </Segment>
      {result && <ExcelMappingResultsView result={result} />}
    </Container>

  </div>
}

export function ExcelMappingResultsView({result}: {result: ExcelMapperResult}) {

  let [store, setStore] = useState<LineItemsStore>();

  useEffect(() => {
    if(result.store) {
      setStore(LineItemsStore.deserialize(result.store));
    }
  }, [result]);

  return <Segment>
    {result && result.errors.length > 0 && result.errors.map((error, index) => <>
      <Message key={index} negative>
        <Message.Header>Error</Message.Header>
        <p>{error}</p>
      </Message>
    </>)}

    {result && result.extractionResults.map(data =><>
      {data.extractedData && data.extractedData.errors.length > 0
        && data.extractedData.errors.map((error, index) => <>
          <h3>Excel Reading Errors</h3>
          <Message key={index} negative>
            <Message.Header>Error</Message.Header>
            <p>{error}</p>
          </Message>

        </>)}

      {data.extractedData && Object.entries(data.extractedData.values).map(([key, value]) => {
        return <div key={key} style={{marginTop: "20px", width: "100%", overflow: "auto"}}>
          <h3>{key}</h3>
          <Table celled size="small">
            <Table.Body>
              {value.value.map((row, index) => {
                return <Table.Row key={index}>
                  {row.map((cell, index) => {
                    return <Table.Cell key={index}>{cell.text}</Table.Cell>
                  })}
                </Table.Row>
              })}
            </Table.Body>
          </Table>
        </div>
      })}

      {data.lineItems && store && <><h3>Line Items</h3>
          <LineItemsTableWithFormulas
              store={store}
              queryResult={store.query(
                StoreQuery.byNames(
                  data.lineItems.map(lineItem => lineItem.name)
                )
              )}
          /></>
      }

    </>)}
  </Segment>
}

