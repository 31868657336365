let redirectionURL: string | null = null

export class LoginStorage {
  static setRedirectionURL(url: string): void {
    redirectionURL = url
  }

  static getRedirectionURL(fallback: string): string {
    if (redirectionURL) {
        const url = redirectionURL
        redirectionURL = null
        return url
    } else {
        return fallback
    }
  }
}