import React, {useState} from "react";
import {Button, Header, Input,Segment, Select, Grid} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import {useCompanyId, usePlatformUrls} from "../../core";
import {PsFileUploader} from "../../ui/FileUploader.component";
import {Loading} from "../../ui/Loading";
import {usePopup} from "../../ui/popup/Popup";
import {useMessages} from "../../ui/MessagesProvider";
import {ErrorPopup} from "../../ui/popup/ErrorPopup";
import {useInstantUpdater} from "../../generic.hooks";
import {saveAmCompanyModel} from "../../company/company.client";
import {AMCompanyModel} from "../../ps-types"

export function AmCompanyNewModelPage() {
  let companyId = useCompanyId();
  let [ title, setTitle ] = useState("");
  let [ description, setDescription ] = useState("");
  const history = useHistory();
  const { amProjects } = usePlatformUrls();
  let {clear, error} = useMessages();
  const [templateType, setTemplateType] = useState<AMCompanyModel['type']>("Project")
  let {openPopup, closePopup}   = usePopup();
  let [uploadProgress, setUploadProgress] = useState<number>(0);
  const [fileUploaderReRenderer, updateFileUploaderReRenderer] = useInstantUpdater()
  const [processing, setProcessing] = useState<boolean>(false);

  const isDescriptionValid = (suppliedDescription: string)=>{
    let isValid = true;
    if(!suppliedDescription){
      error(`Please provide a brief description of the model`);
      setProcessing(false);
      isValid = false;
    }
    return isValid;
  }

    const isTitleValid = (suppliedTitle: string)=>{
        let isValid = true;
        if(!suppliedTitle){
            error(`Please provide a title of the model`);
            setProcessing(false);
            isValid = false;
        }
        return isValid;
    }

  const fileUploadCallback = async (file?: File ) => {
    clear();
    if(!file) return;
    if(!templateType) return;
    if(!isTitleValid(title)) return;
    if(!isDescriptionValid(description)) return;
    setProcessing(true);
    saveAmCompanyModel(companyId, {title, description, type: templateType}, file, (progress) => setUploadProgress(progress)).then((res) => {
      setProcessing(false);
      headToModelsListPage();
    }).catch((err) => {
          setProcessing(false);
          updateFileUploaderReRenderer();
          console.error(err);
          openPopup(<ErrorPopup e={err} header='Failed to register new model' onClose={closePopup} />);
    });
  }
  const headToModelsListPage = ()=>{
    history.push(amProjects("models"));
  }

  const templateTypeOptions = [
      {key: "Project", value: "Project", text: "Project"},
      {key: "SPV", value: "SPV", text: "SPV"},
  ]

  return (<>
        {processing && <Loading  />}
        <Segment style={{padding: "40px"}}>
        <div>
        <div style={{ position: "absolute", top: 10, left: 10 }}>
          <Button
            basic
            labelPosition="left"
            icon="left chevron"
            size={"mini"}
            onClick={() => window.history.back()}
            content="Back"
          />
        </div>
          <Header as="h2">{"Create new model"}</Header>
          <p>
            <Input label="Model Title" placeholder="Model Title"
                   value={title}
                   required
                   onChange={(e, data)=> setTitle(data.value) } />
            <Input label="Model Description" maxLength={100} style={{ width: `${Math.max(30, description.length + 12)}ch` }} placeholder="Model Description" onChange={(e, data)=> setDescription(data.value) } />
          </p>
          <Grid>
            <Grid.Row columns={1} >
              <h3>File</h3>
              <Grid.Column >
                    <Select style={{marginBottom: "10px"}}
                    value={templateType}
                    onChange={(e, data) => setTemplateType(data.value as AMCompanyModel['type'])}
                    options={templateTypeOptions} placeholder='Select Template' />
                    <PsFileUploader
                    uploadProgress={uploadProgress}
                    setFile={fileUploadCallback}
                    updater={fileUploaderReRenderer}
                    acceptableFileTypes={["xls", "xlsm", "xlsx"]}
                    /> 
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        </Segment>
  </>
  );
}