import {StoreQuery} from "../../../ps-models/lineitems-store";
import { LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import React from "react";
import {useSiteStoreContext} from "../../siteStoreLoader";
import {formatRows, fromNestedList} from "../../../lineitems-store/TableFormat";
import {fromPairs} from "ramda";

export function GridExportTimeSeries() {

  let store = useSiteStoreContext();

  let items = [
    'pv_generation_kwh',

    'grid_import_kwh',
    'grid_export_kwh',

    'generation_import_kwh',
    'generation_export_kwh',

    'all_consumption_kwh',
  ];

  let result = store.query(StoreQuery.byNames(items).monthly());

  let fResult = formatRows(fromNestedList({
    ...fromPairs(items.map((item) => [item, []]))
  }), result);


  return <LineItemsTableWithFormulas queryResult={fResult} store={store}/>
}