import psPlatformClient from "../../psPlatformClient";
import {
  CreateWhatIfScenarioRequest,
  ListWhatIfScenariosResponse,
  UpdateWhatIfScenarioRequest, WhatIfScenarioValuesPerProject,
  WhatIfScenarioRecord
} from "../../ps-types";


//Client for whatIfScenario.router.ts
export const createWhatIfScenario = async (companyId: string, data: CreateWhatIfScenarioRequest) => {
  const result = await psPlatformClient<CreateWhatIfScenarioRequest, WhatIfScenarioRecord>(
    {method: 'post', url: `/am/${companyId}/what-if-scenario`,
    data })
  return result.data;
}

export const updateWhatIfScenario = async (companyId: string, scenarioId: string, data: UpdateWhatIfScenarioRequest) => {
  const result = await psPlatformClient<any, any>(
    {method: 'put', url: `/am/${companyId}/what-if-scenario/${scenarioId}`,
    data })
  return result.data;
}

export const deleteWhatIfScenario = async (companyId: string, scenarioId: string) => {
  const result = await psPlatformClient<any, any>(
    {method: 'delete', url: `/am/${companyId}/what-if-scenario/${scenarioId}`})
  return result.data;
}

export const listWhatIfScenarios = async (companyId: string) => {
  const result = await psPlatformClient<any, ListWhatIfScenariosResponse>(
    {method: 'get', url: `/am/${companyId}/what-if-scenario/` })
  return result.data;
}

export const getWhatIfScenario = async (companyId: string, scenarioId: string): Promise<WhatIfScenarioRecord> => {
  const result = await psPlatformClient<{companyId: string, scenarioId: string }, any>(
    {method: 'get', url: `/am/${companyId}/what-if-scenario/${scenarioId}` })
  return result.data;
}

export const runWhatIfScenario = async (companyId: string, scenarioId: string) => {
  await psPlatformClient<{companyId: string, scenarioId: string }, any>(
    {method: 'post', url: `/am/${companyId}/what-if-scenario/${scenarioId}/run` })
}

export const getSalesforceValues = async (companyId: string, configKey: string): Promise<WhatIfScenarioValuesPerProject> => {
  const result = await psPlatformClient<null, WhatIfScenarioValuesPerProject>(
    {method: 'get', url: `/am/${companyId}/integrations/salesforce/${configKey}/values` })
  return result.data;
}

export const getNewProjects = async (companyId: string, configKey: string): Promise<Record<string, {name: string}>> => {
  const result = await psPlatformClient<null, Record<string, {name: string}> >(
    {method: 'get', url: `/am/${companyId}/integrations/salesforce/${configKey}/new-projects` })
  return result.data;
}

export const getSalesforceConfigKeys = async (companyId: string): Promise<string[]> => {
  //TODO: This is hardcoded here, We should get this dynamically from the existing configurations
  return ['portfolio-model-data-report', 'portfolio-data-cleargen', 'srec-by-property', 'program-summary']
}


//get available saleforce fields for configuration
export const getSalesforceFields = async (companyId: string, configKey: string): Promise<string[]> => {
  const result = await psPlatformClient<null, string[]>(
    {method: 'get', url: `/am/${companyId}/integrations/salesforce/${configKey}/fields` })
  return result.data;
}



