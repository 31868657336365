//Get custom imports
import psPlatformClient from "../../psPlatformClient";
import {LineItemsStoreDto} from "../../ps-models/lineitems-store";
import {ProjectCustomImport} from "../../ps-types";

export const getCustomImports = async (companyId: string) => {
  const result = await psPlatformClient<null, ProjectCustomImport[]>
  ({method: 'get', url: `/am/${companyId}/project/custom-import/`});
  return result.data ;
}
export const uploadCustomImport = async (companyId: string, file: File, template: string,  updateFileTransferProgress?: (percentageCompleted: number) => void) => {

  const formData = new FormData();

  formData.append('file', file);
  formData.set('companyId', companyId);
  formData.set('template', template);


  const result = await psPlatformClient<FormData, [LineItemsStoreDto[], { warning: string[]; info: string[] }]>(
    {
      method: 'post', url: `/am/${companyId}/project/custom-import/upload`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        companyId
      },
      ...(updateFileTransferProgress ? {
        onUploadProgress: ((progressEvent) => {
          if (progressEvent?.total) {
            let percentageCompleted = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
            updateFileTransferProgress(percentageCompleted);
          }
        })
      } : {})
    });

  return result.data;
}