import {LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import React from "react";
import {StoreQuery} from "../../../ps-models/lineitems-store";
import {useSiteStoreContext} from "../../siteStoreLoader";
import {fromPairs} from "ramda";
import {formatRows, fromNestedList} from "../../../lineitems-store/TableFormat";
import {Header, Icon, Segment} from "semantic-ui-react";
import {Revenues} from "./Revenues";



export function CashFlow() {
  const store = useSiteStoreContext();

  let revenueLineItems = [
    'BTM PPA Revenue',
    'Grid Services Revenue',
    'Net Export Revenue',
    'Net Revenue',
  ]

  let opexLineItems = [
    'Metering',
    '4gSimConnection',
    'SRPRetainer',
    'VPP',
    'Grid Charging Cost',
    'Net Opex',
  ];


  let cashFlow = store.query(StoreQuery.byNames([...revenueLineItems, ...opexLineItems, "Net Cash Flow"])
    .monthly());


  let formattedRows = formatRows(fromNestedList({
      'Revenues': [],
      ...fromPairs(revenueLineItems
        .filter(item => !!store.getDataSet().getLineItem(item) )
        .map(item => [item, []])),

      'Opex': [],
      ...fromPairs(opexLineItems
        .filter(item => !!store.getDataSet().getLineItem(item) )
        .map(item => [item, []])),
      "Net Cash Flow": []
    }),
    cashFlow, {rowsWithTotals: ["Net Cash Flow"], rowsWithSubTotals: ["Net Revenue", "Net Opex"]});



  return <Segment basic>

      <Header as="h2" color="purple">Cash Flow</Header>
      <LineItemsTableWithFormulas
        withGroups
        queryResult={formattedRows}
        store={store}
      />


  </Segment>
}