// @ts-ignore
import {highlight, languages} from 'prismjs/components/prism-core';
//@ts-ignore
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css';
import Editor from "react-simple-code-editor";
import React, {useState} from "react";
import {Button, Grid, Icon} from "semantic-ui-react";


export function CodeEditor({code, onChanged, language}: {
  code: string,
  onChanged: (code: string) => void,
  language?: string
}) {

  if(!language) {
    language = 'json';
  }

  return <Editor
    value={code}
    onValueChange={onChanged}
    highlight={code => highlight(code, languages[language as string])}
    padding={10}
    style={{
      fontFamily: '"Fira code", "Fira Mono", monospace',
      fontSize: 12,
      backgroundColor: '#f5f5f5'
    }}
  />
}


export function JSONCodeEditor({initialCode, onValidated}: {
    initialCode: string,
    onValidated?: (parsedCode: any) => void,
}) {
    const [code, setCode] = useState<string>(initialCode)
    const [isInvalidCode,setIsInvalidCode] = useState<boolean>(false);
    return <Grid style={{width: '100%'}}>
        <Grid.Row colum={2}>
            <Grid.Column width={15}>
                <Editor
                    value={code}
                    onValueChange={setCode}
                    highlight={code => highlight(code, languages.json)}
                    padding={10}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        backgroundColor: '#f5f5f5'
                    }}
                />
                {isInvalidCode && <strong style={{color:'red'}}>The provided JSON is invalid</strong>}
            </Grid.Column>
            <Grid.Column>
                <Button icon
                        style={{backgroundColor: "white", border: "1px dotted grey"}}
                        onClick={()=>{
                            try {
                                let parsedCode = JSON.parse(code);
                                setIsInvalidCode(false);
                                onValidated?.(parsedCode);
                            }
                            catch(err){
                                console.error(err);
                                setIsInvalidCode(true);
                            }
                        }}>
                    <Icon name='check' color={'green'} />
                </Button>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}