import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios"
require('dotenv').config();

export interface RequestRetryConfig {
    retries: number;
    delay: number;
    failuresToLog: 'all' | 'last' | 'intermediaryOnly' | 'none'
}

const DEFAULT_REQUEST_RETRY_CONFIG: RequestRetryConfig = {
    retries: 4,
    delay: 2000,
    failuresToLog: 'none'
}

const makeRequestViaAxios = async <Req = Record<string, unknown>, Res = Record<string, unknown>>(config: AxiosRequestConfig<Req>, retryConfig: RequestRetryConfig = DEFAULT_REQUEST_RETRY_CONFIG) => {
    //accepts gzip
    config.headers = {
        ...config.headers,
        //'Accept-Encoding': 'gzip, deflate'
    }

   return  retries(async () => axios({
        ...config,
        withCredentials: true
    }) as Promise<AxiosResponse<Res>>, retryConfig)
}

async function retries<T>(fn: () => Promise<T>, retryConfig:RequestRetryConfig): Promise<T> {
    const {retries, delay, failuresToLog} = retryConfig

    let lastError: AxiosError | undefined
    for (let i = 0; i < retries; i++) {
        if (i > 0) {
            await new Promise(resolve => setTimeout(resolve, delay * i))
        }
        try {
            return await fn()
        } catch (e) {
            if (!(e instanceof AxiosError) || e.code !== "ERR_NETWORK") {
                throw e
            }
            lastError = e

            let isLastAttempt = i === retries-1;
            let logSeverity: string = isLastAttempt ? 'error': 'warn'

            if(failuresToLog === 'all'){
                console[logSeverity as 'error' | 'warn'](e);
            }
            if(isLastAttempt && failuresToLog === 'last'){
                console[logSeverity as 'error'](e);
            }
            if(!isLastAttempt && failuresToLog === 'intermediaryOnly'){
                console[logSeverity as 'warn'](e);
            }
        }
    }
    console.error(lastError)
    throw lastError
}

export default makeRequestViaAxios