import {buildEntityDef, EntityCrud, EntityPropDef} from "../../ui/crud/EntityCrud.component";
import {formatDistance} from "date-fns";
import {
  Company, CompanyCloneDto, CompanyCreateDto, CompanyUpdateDto,
  PrimaryIntakeData,
  SUPPORTED_REGIONS
} from "../../ps-types";
import {getCompanies} from "../../company/company.client";
import {INTAKE_OPTIONS} from "../../../components/Intake/intake-constants";
import {Popup} from "semantic-ui-react";
import {cloneCompany, createCompany, updateCompany} from "../admin.client";
import {authStorage} from "../../auth";
import React from "react";
import {buildForm} from "../../ui/crud/common/Field";
import {map} from "ramda";
import {FieldDef} from "../../ui/crud/types";
import {CloneEntity} from "../../ui/crud/CloneEntity.component";
import {getAmProjectConfig} from "../../ps-models";

export function CompanyManagement(){
  const currentUser = authStorage.getUser();
  const getEntities = () => getCompanies();


  const createEntity =  async (data: any) => {
    let companyDataObject: CompanyCreateDto = {...data,
        sortTag: "lead",
        allInvites: [{ email: currentUser.email, role: "admin" }],
    };
    validateCompanyCreationData(companyDataObject);

    if(!companyDataObject.region || companyDataObject.region === 'us'){
      let { region, ...dataWithoutRegion} = companyDataObject;
      companyDataObject = dataWithoutRegion;
    }
    if(!companyDataObject.require2FA){
      let { require2FA, ...dataWithoutRequire2FA} = companyDataObject;
      companyDataObject = dataWithoutRequire2FA;
    }
    if(!companyDataObject.amProjectId){
      let { amProjectId, ...dataWithoutAmProjectId} = companyDataObject;
      companyDataObject = dataWithoutAmProjectId;
    }
    const createdCompany = await createCompany(companyDataObject);
    return createdCompany;
  }

  const deleteEntity = async (entity: any) => {
    // deleteUser(entity.id);
    return;
  }
  const updateEntity = async (entity: any) => {
    let entityToUpdate: CompanyUpdateDto = {
      sortTag: "lead",
      allInvites: [{ email: currentUser.email, role: "admin" }],
      ...entity,
    };
    if(entityToUpdate.amProjectConfig && !entityToUpdate.amProjectConfig.namespace && !entityToUpdate.amProjectConfig.namespace){
      let { amProjectConfig, ...dataWithoutAMPC} = entityToUpdate;
      entityToUpdate = dataWithoutAMPC;
    }

    validateCompanyUpdateData(entityToUpdate as CompanyUpdateDto);
    const updatedCompany = await updateCompany(entityToUpdate);
    return updatedCompany;
  }

  const cloneEntity = async (data: any, originalEntity: any) => {
    const {id, lastModified, ...companyCreationData} = data as any;
    let companyDataObject: CompanyCloneDto = {
      ...companyCreationData,
      sortTag: "lead",
      allInvites: [{ email: currentUser.email, role: "admin" }],
      sourceCompanyAmProjectCollection: getAmProjectConfig(originalEntity as Company).collection,
    };
    validateCompanyCloneData(companyDataObject as CompanyCloneDto);

    if(!companyDataObject.region || companyDataObject.region === 'us'){
      let { region, ...dataWithoutRegion} = companyDataObject;
      companyDataObject = dataWithoutRegion;
    }
    if(!companyDataObject.require2FA){
      let { require2FA, ...dataWithoutRequire2FA} = companyDataObject;
      companyDataObject = dataWithoutRequire2FA;
    }
    if(!companyDataObject.amProjectId){
      let { amProjectId, ...dataWithoutAmProjectId} = companyDataObject;
      companyDataObject = dataWithoutAmProjectId;
    }
    const clonedCompany = await cloneCompany(id, companyDataObject);
    return clonedCompany;
  }


  const entityDefProps: Record<{
    intake: keyof PrimaryIntakeData
  } & keyof Pick<Company, 'name' | 'id' | 'region' | 'require2FA' | 'amProjectId' | 'amProjectConfig'>
      , Partial<EntityPropDef>> = {
    id: {
      table: {
        type: 'text',
      },
      create: {
        type: 'hidden'
      },
      update: {
        label: "Id",
        type: 'text',
        readOnly: true,
      },
    },
    name: {
      table: {
        type: 'text',
        render: (_value: string, entity: any) =>
            <Popup
                trigger={
                  <div style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {entity.name}
                  </div>
                }
                content={entity.name}
                basic
            />
      },
      create: {
        label: "Name",
        type: 'text',
      },
    },
    region: {
      table: {},
      create: {
        label: "Region",
        type: 'select',
        placeHolder: 'Enter Company Region Here (default is US)',
        options: SUPPORTED_REGIONS.map((it)=>{
          let item = it;
          if(item === ''){
            item = 'us';
          }
          return {key: item, value: item, text: item.toUpperCase()}})
      },
    },
    require2FA: {
      table: {
        type: 'text',
      },
      create: {
        label: "2FA Required",
        type: 'toggle',
      },
    },
    amProjectId: {
      table: {
        type: 'text',
      },
      create: {
        label: "AM Project Id",
        type: 'text',
      },
    },
    "amProjectConfig.namespace": {
      table: {
        type: 'text',
      },
      create: {
        type: 'text',
        label: "AM Project Namespace",
      },
    },
    "amProjectConfig.collection": {
      table: {
        type: 'text',
      },
      create: {
        type: 'text',
        label: "AM Project Collection",
      },
    },
    "intake.reasonForInterest": {
      table: {
        type: 'hidden'
      },
      create: {
        label: "Reason For Interest",
        type: 'multi-select',
        options: INTAKE_OPTIONS.reasonForInterestOptions.map((it)=>({key: it, value: it, text: it}))
      },
    },
    "intake.companyStage": {
      table: {
        type: 'hidden'
      },
      create: {
        label: "Company Stage",
        type: 'select',
        options: INTAKE_OPTIONS.companyStageOptions.map((it)=>({key: it, value: it, text: it}))
      },
    },
    "intake.companySector": {
      table: {
        type: 'hidden'
      },
      create: {
        options: INTAKE_OPTIONS.companySectorOptions.map((it)=>({key: it, value: it, text: it})),
        label: "Company Sector",
        type: 'multi-select',
      },
    },
    "intake.geography": {
      table: {
        type: 'hidden'
      },
      create: {
        label: "Geography",
        type: 'multi-select',
        options: INTAKE_OPTIONS.geographyOptions.map((it)=>({key: it, value: it, text: it})),
      },
    },
    "intake.estimatedValueDeploymentToDate": {
      table: {
        type: 'hidden'
      },
      create: {
        label: "Estimated Value Deployment To Date",
        type: 'text',
      },
    },
    "intake.technologyType": {
      table: {
        type: 'hidden'
      },
      create: {
        label: "Technology Type",
        type: 'multi-select',
        options: INTAKE_OPTIONS.technologyTypeOptions.map((it)=>({key: it, value: it, text: it})),
      },
    },
  }

  let def = buildEntityDef(
      {
        entityName: "Company",
        title: "Platform Companies",
        createEntity,
        getEntities,
        // deleteEntity,
        updateEntity,
        table: {
          customEntityFormActions: [
            (props) => (<CloneEntity {...props} {...buildForm(
                // @ts-ignore
                { ...props.entityDef, ...(props.entityDef?.create ?? {}), ...(props.entityDef?.update ?? {})},
                map((p) => ({
                  key: p.key,
                  label: p.title,
                  type: "text",
                  ...p.create,
                  ...p.update,
                } as FieldDef), props.entityDef.props)
            )} originalEntity={props.entity} cloneEntity={cloneEntity} onEntityCloned={props.onActionCompletion.createAction} customIconProps={"clone outline"} options={{modalTitle: 'Clone Company' ,buttonText: 'Clone'}} />)
          ],
        }
      },
      entityDefProps
  );

  return <EntityCrud entityDef={def} />
}


// @TODO: Most of the code below, is simply inherited from intake form for validation
const includeOther = (selections: string[] | undefined) => {
  if (Array.isArray(selections) && selections.includes("Other")) {
    return true;
  }
  return false;
};

function validateCompanyCreationData(companyCreationData: CompanyCreateDto){
  // @TODO: Make these validations stricter
  if(companyCreationData?.amProjectConfig){ // if amProjectConfig is defined then all properties must be defined
    if(!companyCreationData.amProjectConfig.namespace){
      throw new Error("amprojectconfig namespace must be provided");
    }
    if(!companyCreationData.amProjectConfig.collection){
      throw new Error("amprojectconfig collection must be provided");
    }
  }
  if(!companyCreationData.name){
    throw new Error("name must be provided")
  }
  if(!companyCreationData.intake?.companySector || companyCreationData.intake?.companySector.length === 0){
    throw new Error("Company Sector must be provided")
  }

  if (includeOther(companyCreationData.intake?.companySector) && companyCreationData.intake?.companySectorDescription === "") {
    throw new Error("Company Sector Description must be provided")
  }

  if (companyCreationData.intake?.companyDescription === "") {
    throw new Error("Company Description must be provided")
  }

  if (companyCreationData.intake?.companyStage === "") {
    throw new Error("Company Stage must be provided")
  }

  if (companyCreationData.intake?.companyStage === "Other" && companyCreationData.intake?.companyStageDescription === "") {
    throw new Error("Company Stage Description must be provided")
  }

  if (!companyCreationData.intake?.technologyType || companyCreationData.intake.technologyType.length === 0) {
    throw new Error("Technology Type must be provided")
  }

  if (includeOther(companyCreationData.intake?.technologyType) && companyCreationData.intake?.technologyTypeDescription === "") {
    throw new Error("Technology Type Description must be provided")
  }

  if (!companyCreationData.intake?.geography || companyCreationData.intake.geography.length === 0) {
    throw new Error("Geography must be provided")
  }

  if (!companyCreationData.intake?.estimatedValueDeploymentToDate || companyCreationData.intake.estimatedValueDeploymentToDate === "") {
    throw new Error("Estimated Value of Deployment must be provided")
  }

  if (!companyCreationData.intake?.reasonForInterest || companyCreationData.intake.reasonForInterest.length === 0) {
    throw new Error("Reason for Interest must be provided")
  }

  if(includeOther(companyCreationData.intake?.reasonForInterest) && companyCreationData.intake?.reasonForInterestDescription === ""){
    throw new Error("Reason for Interest Description must be provided")
  }
}

function validateCompanyUpdateData(companyUpdatePayload: CompanyUpdateDto){
  const {id, ...companyCreatePayload} = companyUpdatePayload;
  if(!id){
    throw new Error("id must be provided")
  }
  validateCompanyCreationData(companyCreatePayload);
}

function validateCompanyCloneData(companyClonePayload: CompanyCloneDto){
  const {sourceCompanyAmProjectCollection, amProjectConfig, ...companyCreatePayload} = companyClonePayload;
  if(!sourceCompanyAmProjectCollection){
    throw new Error("sourceCompanyAmProjectCollection must be provided")
  }
  validateCompanyCreationData(companyCreatePayload);
}