import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone"

export const INPUT_FIELD_MAX_CHARACTER_LIMITS = {
    companyName: 70,
    companyDescription: 500,
    detailsOfOtherOptionChosen: 300,
    personName: 110,
    designation: 70,
    address: 120,
    phoneEmail: 254,
    listItemInputs: 120,
};

export const phoneOrEmailFieldValidator = (value: string) => {
    if(!value){
        return 'Please enter phone or email'
    }
    if(isEmail(value) || isMobilePhone(value)){
        return null;
    } else {
        return "Please provide a valid email or phone";
    }
}