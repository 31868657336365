import {Button, Radio, Table} from "semantic-ui-react";
import React, {useState} from "react";
import {deleterReportConfigFromLocalStorage, ReportConfig} from "./constants";

export function ReportConfigCrud({selectConfigToLoad}: {selectConfigToLoad: (singleConfigItem: ReportConfig | undefined)=>void}) {
    const persistedConfigs = JSON.parse(window.localStorage.getItem("reportConfigs") ?? "[]")
    const [storage, setStorage] = useState<ReportConfig[]>(()=> {
        return persistedConfigs
    });

    const [selectedId, setSelectedId] = useState<string| null>(null);

    const handleDelete = (id: string) => {
        if(selectedId === id){
            selectConfigToLoad(undefined);
            setSelectedId(null);
        }
        setStorage(storage.filter(item => item.id !== id));
        deleterReportConfigFromLocalStorage(id);
    };

    const handleRadioChange = (id: string) => {
        setSelectedId(id);
        const selectedReportConfig = storage.find(item => item.id === id)
        console.info("the selected report is", selectedReportConfig)
        if(selectedReportConfig){
            selectConfigToLoad(selectedReportConfig);
        }
    };

    return (
        <div
            style={{ overflowY: 'auto', maxHeight: '300px' }}
        >
            {storage.length === 0 && <div style={{textAlign: 'center'}}>No Reports Saved Yet</div>}
            {storage.length > 0 && <Table celled compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Select</Table.HeaderCell>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Delivery Schedule</Table.HeaderCell>
                        <Table.HeaderCell>Created At</Table.HeaderCell>
                        <Table.HeaderCell>Updated At</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {storage.map(item => (
                        <Table.Row key={item.id}>
                            <Table.Cell>
                                <Radio
                                    checked={selectedId === item.id}
                                    onChange={() => handleRadioChange(item.id)}
                                />
                            </Table.Cell>
                            <Table.Cell>{item.reportMeta.title}</Table.Cell>
                            <Table.Cell>{item.reportMeta.description}</Table.Cell>
                            <Table.Cell>{(item?.emailConfig?.frequency && item?.emailConfig?.emailTo) ? `Sent ${item?.emailConfig?.frequency} to ${item?.emailConfig?.emailTo}`: 'Not Configured'}</Table.Cell>
                            <Table.Cell>{`${new Date(item.createdAt).toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric'
                            })}`}</Table.Cell>
                            <Table.Cell>{`${new Date(item.updatedAt).toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric'
                            })}`}</Table.Cell>
                            <Table.Cell>
                                <Button negative onClick={() => handleDelete(item.id)}>
                                    Delete
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>}
        </div>
    );
};



