import {LineItemsStore, pQuery, StoreQuery} from "../../../ps-models/lineitems-store";
import {LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {Header, Segment} from "semantic-ui-react";
import {formatRows, fromNestedList} from "../../../lineitems-store/TableFormat";
import {fromPairs} from "ramda";
import {useLineItemFilter} from "../../../lineitems-store/MetadataFilters";
import {usePersistenceStoreQueryWithContextUpdate} from "../../../lineitems-store/LineItemsStore.hook";
import {GROUPING_PARENT_REFERENCE_KEY, singleAggregator, getAmProjectConfig} from "../../../ps-models";
import {setLabelAndFormatForAggregates} from "./storeFormulas";
import {authStorage} from "../../../auth";

let revenueLineItems = [
  'BTM PPA Revenue',
  'Grid Services Revenue',
  'Net Export Revenue',
  'Net Revenue',
]

let opexLineItems = [
  'Metering',
  '4gSimConnection',
  'SRPRetainer',
  'VPP',
  'Grid Charging Cost',
  'Net Opex',
];

let capexRows = [
  'total_purchase_price__c',
  'discounted_purchase_price__c',
  'current_value_post_dep__c',
  'original_asset_value__c',
  'monthly_depreciated_value__c',
  'Investment Returns IRR',
  'Investment Returns MOIC',
  'Investment Returns Cash Return',
  'Investment Returns Rolling ARR',
]


export function AggregateFinancials() {

  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);

  let sourceMetadata = [
     'Network__c', 'State__c', 'Residential_Business__c'
  ]
  const cashFlowTableItems = [...revenueLineItems, ...opexLineItems, "Net Cash Flow"]
  let aggregatedStore = usePersistenceStoreQueryWithContextUpdate(collection,
    pQuery()
      .selectSourceParams(sourceMetadata)
      .withLineItems([...cashFlowTableItems, ...capexRows])
      .withGranularity('months'),
    (store) => {
          store.getDataSet().addTimedGroupingLineItemsByField("store_sourceLineItemName");
          setLabelAndFormatForAggregates(store)
      });


  let [filterQuery, filtersComponent] = useLineItemFilter({
    'source_Current_Energy_Retailer__c': 'Retailer',
    'source_Network__c': 'Network',
    'source_State__c': 'State',
    'source_Residential_Business__c': 'Residential/Business'
  }, aggregatedStore);


  let storeQuery = StoreQuery.withField('store_groupingName').or(filterQuery);

  return <Segment>
    {filtersComponent}
    {aggregatedStore && <AggregatedCashFlows store={aggregatedStore.view(storeQuery.and(StoreQuery.byNames(cashFlowTableItems, true)))} />}
    {aggregatedStore && <AggregatedCapex store={aggregatedStore.view(storeQuery.and(StoreQuery.byNames(capexRows, true)))} />}
  </Segment>

}



function AggregatedCapex({store}: { store: LineItemsStore}) {

  let result = store.query(StoreQuery.all().monthly(singleAggregator('first')));

  let siteRows = fromPairs(result.rows.filter((row: any) => row[GROUPING_PARENT_REFERENCE_KEY]).map((row: any) => [row.name.value, {}]));

  let capexFormattedRows = formatRows(fromNestedList({
      ...siteRows,
       ...capexRows.reduce((acc, item) => ({...acc, [item]: []}), {})
    }),
    result);

  return <Segment basic>
    <Header as="h2" color="purple">Capex</Header>
    <LineItemsTableWithFormulas queryResult={capexFormattedRows} store={store} withGroups  />
  </Segment>
}



export function AggregatedCashFlows({store}: { store: LineItemsStore}) {

  let result = store.query(StoreQuery.all().monthly());

  let siteRows = fromPairs(result.rows.filter((row: any) => row[GROUPING_PARENT_REFERENCE_KEY]).map((row: any) => [row.name.value, {}]));

  let capexFormattedRows = formatRows(fromNestedList({
      ...siteRows,
      'Revenues': [],
      ...fromPairs(revenueLineItems.map(item => [item, []])),

      'Opex': [],
      ...fromPairs(opexLineItems.map(item => [item, []])),
      "Net Cash Flow": []
    }),
    result, {rowsWithTotals: ["Net Cash Flow"], rowsWithSubTotals: ["Net Revenue", "Net Opex"]});


  return <Segment basic>
    <Header as="h2" color="purple">Cash Flows</Header>
    <LineItemsTableWithFormulas queryResult={capexFormattedRows} store={store} withGroups  />
  </Segment>
}

