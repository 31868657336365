// import { functions } from "../firebase/firebase.utils";
import { getBrowserName } from "./getBrowserName.utils";
import { CircuitBreaker } from '../platform/ps-models/CircuitBreaker';
import { cloudFunctionUrl } from "../firebase/firebase.config";

let circuitBreakerForLogger: CircuitBreaker;

const defaultConsoleTraceMethod = console.trace;
const defaultConsoleDebugMethod = console.debug;
const defaultConsoleLogMethod = console.log;
const defaultConsoleInfoMethod = console.info;
const defaultConsoleWarnMethod = console.warn;
const defaultConsoleErrorMethod = console.error;


const isDevelopmentEnvironment = process.env.NODE_ENV === 'development';

const loggerName = 'frontend';

type LogSeverity = 'trace' | 'debug' | 'log' | 'info' | 'warn' | 'error';

const timeForLoggingFnToRespond = 2000; // 2000ms

class LoggerWrapper {
    // The docs of log4javascript can be accessed -> https://www.log4javascript.org/docs/manual.html
    // @ts-ignore
    public logger = window.log4javascript.getLogger(loggerName);
    private applicationVersion = process.env.APPLICATION_VERSION || '0.0.0';
    private batchSizeToUseWhilePublishingLogsToRemoteServer = 50;

    constructor() {
        // @ts-ignore
        window.log4javascript.addEventListener("error", ((cbargs) => {
            // TODO: this needs to be further refined to preserve the structure, also timestamp needs to be updated
            // functions.httpsCallable('publishApplicationLogs')({data: [{"logger": 'frontend:', message: , timestamp: new Date()}]});
        }))

        this.configureLogsToBePublishedToRemoteServer();
        this.overrideConsoleStatementsDefaultBehaviour();
        this.registerGlobalErrorEventHandlers();
    }

    private configureLogsToBePublishedToRemoteServer() {
        if (!isDevelopmentEnvironment) {
            // @ts-ignore
            const ajaxLogAppender = new window.log4javascript.AjaxAppender(`${cloudFunctionUrl}publishApplicationLogs`);
            // @ts-ignore
            const jsonLayout = new window.log4javascript.JsonLayout(true, false);
            // This patch needs to be present
            jsonLayout.batchHeader = '{"data":' + jsonLayout.batchHeader;
            jsonLayout.batchFooter = jsonLayout.batchFooter + '}';
            jsonLayout.setCustomField('browser', getBrowserName());
            jsonLayout.setCustomField('userAgent', navigator?.userAgent || '');
            jsonLayout.setCustomField('applicationVersion', this.applicationVersion);
            // Just done to set message key as content. Because the cloud function logger reads the js object and uses the same for the message preview
            jsonLayout.setKeys("logger", "timestamp", "level", "content", "exception", "url");
            jsonLayout.setCustomField('message', 'Incoming log from client side');

            ajaxLogAppender.addHeader("Content-Type", "application/json");
            ajaxLogAppender.setLayout(jsonLayout);
            ajaxLogAppender.setBatchSize(this.batchSizeToUseWhilePublishingLogsToRemoteServer);
            ajaxLogAppender.setTimeoutOnRequest(timeForLoggingFnToRespond);
            // TODO: We are loosing some logs here, for now.
            // ajaxLogAppender.setSendAllOnUnload(true);
            ajaxLogAppender.setRequestSentCallback(() => {
                getCircuitBreakerForLogger().requestEvent();
            });
            ajaxLogAppender.setRequestTimedoutCallback(() => {
                getCircuitBreakerForLogger().timeoutEvent();
            })
            ajaxLogAppender.setRequestSuccessCallback((xmlHttp: XMLHttpRequest) => {
                getCircuitBreakerForLogger().successEvent();
            })
            ajaxLogAppender.setFailCallback((message: string) => {
                getCircuitBreakerForLogger().errorEvent();
                try {
                    // TODO: this needs to be further refined to preserve the structure, also timestamp needs to be updated
                    /* Resolve the TODO
                    // functions.httpsCallable('publishApplicationLogs')({
                    //     data: [{
                    //         "logger": loggerName,
                    //         content: `Request made to publish logs from the client side failed with reason : ${message}`,
                    //         timestamp: new Date().getTime(),
                    //         applicationVersion: this.applicationVersion,
                    //         browser: getBrowserName(),
                    //         level: 'ERROR'
                    //     }]
                    // });
                    */
                } catch (err) { /* functions.httpsCallable should also most likely fail, because ajax is also trying to reach the same url. DO NOTHING */ }
            })
            this.logger.addAppender(ajaxLogAppender);
        }
    }

    private overrideConsoleStatementsDefaultBehaviour() {
        // TODO: Add JSDOC for all the methods, to show a warning, that this is not the default implementation of the method, so as to avoid confusions during development.
        console.trace = function () {
            invokeDefaultLogger('trace', ...arguments);
            if (isDevelopmentEnvironment) {
                //@ts-ignore
                return defaultConsoleTraceMethod.apply(this, arguments);
            }
        };
        console.log = function () {
            invokeDefaultLogger('log', ...arguments);
            if (isDevelopmentEnvironment) {
                let errors =  (new Error()).stack?.split("\n");
                let errorInFile = errors && errors.length > 2 && errors[2];
                //@ts-ignore
                return defaultConsoleLogMethod.apply(this, [...arguments, errorInFile]);
            }
        };
        console.info = function () {


            invokeDefaultLogger('info', ...arguments);
            if (isDevelopmentEnvironment) {
                let errors =  (new Error()).stack?.split("\n");
                let errorInFile = errors && errors.length > 2 && errors[2];
                //@ts-ignore
                return defaultConsoleInfoMethod.apply(this, [...arguments, errorInFile]);
            }
        };
        console.debug = function () {
            invokeDefaultLogger('debug', ...arguments);
            if (isDevelopmentEnvironment) {
                let errors =  (new Error()).stack?.split("\n");
                let errorInFile = errors && errors.length > 2 && errors[2];
                //@ts-ignore
                return defaultConsoleDebugMethod.apply(this, [...arguments, errorInFile]);
            }
        };
        console.warn = function () {
            invokeDefaultLogger('warn', ...arguments);
            if (isDevelopmentEnvironment) {
                let errors =  (new Error()).stack?.split("\n");
                let errorInFile = errors && errors.length > 2 && errors[2];
                //@ts-ignore
                return defaultConsoleWarnMethod.apply(this, [...arguments, errorInFile]);
            }

        };
        console.error = function () {
            invokeDefaultLogger('error', ...arguments);
            if (isDevelopmentEnvironment) {
                let errors =  (new Error()).stack?.split("\n");
                let errorInFile = errors && errors.length > 2 && errors[2];
                //@ts-ignore
                return defaultConsoleErrorMethod.apply(this, [...arguments, errorInFile]);
            }
        };
    }

    private registerGlobalErrorEventHandlers() {
        // Source: https://stackoverflow.com/a/49560222
        // TODO: The error stack and lineNo will not be useful in production, due to code obfuscation. Think of a solution to capture more information...
        window.onerror = (msg, url, lineNo, columnNo, error) => {
            if (error && error.stack) {
                console.error(`Caught from the global error handler: ${msg}\n in url ${url}\n${error.stack}`);
            } else {
                console.error(`Caught from the global error handler: ${msg}\n url ${url}\n Line: ${lineNo}`);
            }
        };

        window.addEventListener('unhandledrejection', function (e) {
            console.error(`Caught from the global unhandledrejection handler`, e.reason);
        });
    }
}

let loggerInstance: LoggerWrapper;

export const initialiseLogger = () => {
    if (!loggerInstance) {
        loggerInstance = new LoggerWrapper();
    }
}

const getCircuitBreakerForLogger = (): CircuitBreaker => {
    if (!circuitBreakerForLogger) {
        circuitBreakerForLogger = new CircuitBreaker('frontend-logger', timeForLoggingFnToRespond * 5, 3, 4);
    }
    return circuitBreakerForLogger;
}

export const getLogger = (): LoggerWrapper => {
    if (!loggerInstance) {
        initialiseLogger();
    }
    return loggerInstance;
}

const invokeDefaultLogger = function (logMethod: LogSeverity, ...args: object[]) {
    /*
        TODO: Handle data coming in as args
            Check for circular dependency
            Check for the data type and handle it appropriately. For example, stringify the JSON params.
    * */
    let message = '';
    if (args.length > 0) {
        message += args.join(", ");
    }
    let logLevel = logMethod === 'log' ? 'debug' : logMethod;
    // This will call the actual base logger.
    const currentState = getCircuitBreakerForLogger().getState();
    if (currentState !== 'open') {
        getLogger().logger[logLevel](message);
    }
}
