import React, {useEffect} from "react";
import {Segment} from 'semantic-ui-react';
import {LineItemsStore, StoreQuery} from "../../ps-models/lineitems-store";
import {
    buildMonthlyTimeDef,
    buildTimedRawLineItem,
    TimedRawLineItem,
} from "../../ps-models/line-items";
import {useUpdateContext} from "../../UpdateContext";
import {LineItemsTable} from "../LineItemsTableView";
import {buildDailyTimeIndex, valueAsNumber} from "../../ps-models";
import {formatRows, fromNestedList} from "../TableFormat";
import {fromPairs} from "ramda";
import {useFrequencySelect} from "../FrequencySelect";

export const MonthlyRawLiInputs = ({lineItemNames, store}: { lineItemNames: string[], store: LineItemsStore }) => {
    const ctx = useUpdateContext();
    let [select, frequency] = useFrequencySelect("monthly");
    useEffect(() => {
        for(let lineItemName of lineItemNames){
            let existingLi = store.getDataSet().getLineItem(lineItemName);
            if (!existingLi) {
                let newRawLineItem = buildTimedRawLineItem(lineItemName, buildMonthlyTimeDef());
                store.getDataSet().addLineItem(newRawLineItem)
            }
        }
        store.clearExecutionCache();
        ctx.ready();
    }, [])

    let tableQuery = StoreQuery.byNames(lineItemNames);
    if (frequency === 'monthly') {
        tableQuery = tableQuery.monthly();
    } else if(frequency === 'daily'){
        const storeTimeIndex = store.timeIndex;
        tableQuery = tableQuery.withTimeIndex(buildDailyTimeIndex(storeTimeIndex.startDate, storeTimeIndex.endDate));
    }

    let result = store.query(tableQuery);

    let fResult = formatRows(fromNestedList({
        ...fromPairs(lineItemNames.map((item) => [item, []]))
    }), result);

    const handleSave = (row: any) => {
        let li = store.getDataSet().getLineItem(row.id);
        if (li instanceof TimedRawLineItem) {
            let newRawLineItem = buildTimedRawLineItem(li.name, li.timeDefinition, li.fields.clone());

            store.timeIndex.timeLine.forEach(t => {
                newRawLineItem.add(new Date(t.date), row[t.time] !==undefined ? valueAsNumber(row[t.time]): 0);
            });

            store.getDataSet().addLineItem(newRawLineItem);
            store.clearExecutionCache();
            ctx.ready();
            return;
        }
    }

    return <Segment style={{marginTop: 0}}>
            {select}
            <LineItemsTable
                onRowSelected={(row) => {
                    console.info("Selected ", store.getDataSet().getLineItem(row as string));
                }}
                hideToolbar={true}
                queryResult={fResult}
                readonly={frequency === 'daily'}
                onUpdated={(row) => {
                    handleSave(row);
                    return row;
                }}
            />
        </Segment>
};
