import {pQuery, StoreQuery} from "../ps-models/lineitems-store";
import {useLineItemsStoreMetadata} from "../lineitems-store/LineItemsStore.hook";
import {authStorage} from "../auth";
import {getAmProjectConfig} from "../ps-models";

export type Site = {id: string, label: string, isNew?:boolean}

export function siteDescription(site: Site) {
    return `${site.label} (${site.id})`
}

export function useAllSites(demarcateWhetherSiteIsNew: boolean = false): Site[] | undefined {
  const company = authStorage.getCompany();
  const {collection} = getAmProjectConfig(company);
  const query = pQuery().metadataOnly(demarcateWhetherSiteIsNew ? ['External System Sync Status']: undefined).withStoreType("any")
  const storeMetadata = useLineItemsStoreMetadata(collection, query);

  const result = storeMetadata?.query(StoreQuery.all());

  if(!result || !storeMetadata) {
    return undefined
  }
  return storeMetadata
      .query(StoreQuery.all(), {withMetadata: []})
      .rows
      .map((_, i) => {
          const siteObj: Site = {
              id: result.column(i, 'name')?.value as string,
              label: `${result.column(i, 'name')?.text as string}`
          }
          if(demarcateWhetherSiteIsNew){
              const isNewSite = result.column(i, 'fields.source_externalsystemsyncstatus')?.text === 'pending';
              siteObj.isNew = isNewSite;
          }
        return siteObj;
      });
}
