import {formatDistance} from "date-fns";
import {useCompanyId, usePlatformUrls} from "../../core";
import {NavLink, useHistory} from "react-router-dom";
import {DeleteButton} from "../../ui/crud/DeleteButton.component";
import {buildEntityDef, EntityCrud} from "../../ui/crud/EntityCrud.component";
import {createAmDashboard, deleteAmDashboard, getAmDashboards} from "./Dashboard.client";
import {AmDashboard, AmProject} from "../../ps-types";
import {Tab} from "semantic-ui-react";

function timeAgo(value: string) {
  return value ? formatDistance(new Date(value), new Date(), {addSuffix: true}) : "";
}

function DeleteBtn({entityName, entity, handleDelete}: {entityName: string, entity: any, handleDelete: (entity: any) => void}) {
  const content =`Are you sure you want to delete the ${entityName} '${entity["name"]}'?`;

  return <DeleteButton
    content={content}
    onDelete={() => handleDelete(entity)}
  />
}

export function DashboardEditorLink({dashboard}: {dashboard: AmDashboard}) {
  const { amDashboardEditor } = usePlatformUrls();
  return<NavLink to={() => amDashboardEditor(dashboard.id)}>{dashboard.name}</NavLink>
}
export function Dashboards() {
  const history = useHistory();

  const companyId = useCompanyId();
  const getEntities = () => getAmDashboards(companyId);
  const createEntity =  async (data: any) => {
    return  await createAmDashboard(companyId, data);
  }

  const deleteEntity = (dashboard: any) => deleteAmDashboard(companyId, dashboard.id);

  let def = buildEntityDef(
    {
      entityName: "Dashboard",
      title: "My Dashboards",
      createEntity,
      getEntities,
      deleteEntity,
      table: {
        deleteButton: (props)=> (<DeleteBtn {...props} />)
      }
    },
    {
      name: {
        title: "NAME",
        table: {
          render: (_value: string, ds) =>
            <DashboardEditorLink dashboard={ds as AmDashboard} />
        }
      },
      v2: {
          title: "Use Puck (v2)",
          create: {type: "toggle"},
          table: {
            render: (_value: string, ds) => <>{`${!!ds.v2}`}</>
          }
      },
      createdAt: {
        title: "CREATED",
        create: { type: "hidden" },
        table: { format: timeAgo }
      }
    }
  );

 const panes = [
    {
      menuItem: 'Dashboards',
      render: () => <EntityCrud entityDef={def} />
    }
 ]

  return <div>
    <Tab panes={panes} />
  </div>
}