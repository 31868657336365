import {Grid, Header, Message, Segment, Select, SemanticWIDTHS, Table} from "semantic-ui-react";
import {usePersistenceStoreQueryWithContextUpdate} from "../../../lineitems-store/LineItemsStore.hook";
import {
    CANONICAL_NAME_FIELD, LineItemsStore,
    pQuery, QueryResult,
    StoreQuery, TimeColumn
} from "../../../ps-models/lineitems-store";
import React, {ReactNode, useEffect, useState} from "react";
import {SPVSelection} from "./SPVSelection";
import {formatDateByTimeUnit, getLastDateOfPreviousMonth, TimeUnits} from "../../../ps-models";
import {LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {loadSiteStore} from "../../siteStoreLoader";
import {useLineItemFilter} from "../../../lineitems-store/MetadataFilters";
import {LoadingBlock} from "../../../ui/Loading";
import {values} from "ramda";
import {MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING} from "../../index";
import {addCustomLabelToItemsInStore, setLabelAndFormatForAggregates} from "../nrn/storeFormulas";
import { DemoTimeSeriesChart } from "./DemoTimeSeriesChart";
import {authStorage} from "../../../auth";
import {getAmProjectConfig} from "../../../ps-models";


export function SpvStructure() {

  let [spv, setSpv] = useState<string>();

  let spvContent: Record<string, ReactNode> = {
    'spv-1': <SPV1 />,
    'spv-2': <SPV2 />,
  }

   return  <Segment>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{verticalAlign: 'middle'}}>
                {!spv ? "Please select an SPV" : ""}
            </div>
            <div>
                <SPVSelection onSelect={setSpv} />
            </div>
        </div>
        {spv && spvContent[spv]}

    </Segment>
}


export function SPV1() {
  const borrowers = [
    <>
      <strong>Lender 1:</strong>Solartech Finance Ltd <br/>
      <strong>Total Debt Amount:</strong> $10,000,000 <br/>
    </>,
    <>
      <strong>Lender 2:</strong> Renewable Energy Partners Ltd <br/>
      <strong>Total Debt Amount:</strong> $20,000,000 <br/>
    </>
  ]

  return <SPVCommon storeId={'spv-1'} header={"Solar SPV 1"} info={(result: QueryResult) => <>
        <p><strong>Borrower:</strong> Solar SPV 1 Pty Ltd, a related body corporate of Solar Holdings Pty Ltd  </p>
        <p><strong>Total Debt Amount Drawn till date:</strong> $30,000,000 </p>
        <p><strong>Annual Interest Rate:</strong> 13%    </p>
        <p>
            Interest is accrued & calculated daily against the drawn down balance of the loan less any accumulated Loan Repayments on each calendar day until the last calendar day of each month.
        </p>
        <p>
            <strong>Loan Repayment Schedule:</strong> <br/>
            <ul>
                <li>50% to be amortised and repaid in 24 equal monthly installments due on the monthly anniversary of the drawdown.</li>
                <li> 50% will be repaid in a single repayment on the last calendar day of the 24th month of the loan term for the drawdown.</li>
            </ul>
            <i>Cash Collateral Requirement: 10% of drawn down balance</i> </p>
        <p>
            <i>The SPV has Principal repayment and Interest payment obligations every month.</i>
        </p>
        <hr/><br/>
        <Grid>
            <Grid.Row divided columns={borrowers.length as SemanticWIDTHS}>
              {borrowers.map((b, i) => <Grid.Column key={i} width={8}>{b}</Grid.Column>)}
            </Grid.Row>
        </Grid>
    </>}
  />
}

export function SPV2() {

  let borrowers = [
    <>
      <strong>Lender 1:</strong> Renewable Energy Partners Ltd <br/>
      <strong>Total Debt Amount:</strong> $250,000 <br/>
      <strong>Additional Terms:</strong> Withholding Tax: 10%
    </>
  ]

  return <SPVCommon storeId={'spv-2'} header={"Solar SPV 2"} info={(result: QueryResult) => <>
        <p><strong>Borrower:</strong> Solar SPV 2 Pty Ltd, a related body corporate of Solar Holdings Pty Ltd </p>
        <p><strong>Total Debt Amount Drawn till date:</strong> $10,000,000  </p>
        <p><strong>Annual Interest Rate:</strong> 12%    </p>
        <p>
          Interest is accrued & calculated daily against the drawndown balance of the loan less any accumulated Loan Repayments on each calendar day until the last calendar day of each month.    </p>
        <p>
          <strong>Loan Repayment Schedule:</strong> <br/>
          <ul>
            <li>50% to be amortised and repaid in 24 equal monthly installments due on monthly anniversary of the drawdown.</li>
            <li>50% will be repaid in a single repayment on the last calendar day of the 24th month of the loan term for the drawdown.</li>
          </ul>
          <i>Cash Collateral Requirement: 10% of drawn down balance</i> </p>
        <p>
          <i>The SPV has Principal repayment and Interest payment obligations every month.</i>
        </p>
        <hr/><br/>
        <Grid>
          <Grid.Row divided columns={borrowers.length as SemanticWIDTHS}>
            {borrowers.map((b, i) => <Grid.Column key={i}>{b}</Grid.Column>)}
          </Grid.Row>
        </Grid>
    </>}
  />
}

interface SPVCommonProps {
    storeId: string
    header: string,
    info: (result: QueryResult) => React.JSX.Element
}

function SPVCommon({storeId, header, info}: SPVCommonProps) {
  let [spvStore, setSpvStore] = useState<LineItemsStore>();
  let [granularity, setGranularity] = useState<TimeUnits>("months");

  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);

  let aggregatedStore = usePersistenceStoreQueryWithContextUpdate(collection,
    pQuery()
      .selectSourceParams(['SPV_Fund__c'])
      .havingParameterValues({"SPV_Fund__c": storeId})
      .withLineItems([
 //         'Annual Interest Rate',
          'Net Revenue',
          'Current Value'
      ])
      .withStoreType('document')
      .withGranularity('months')
    , (store) => {

      store.getDataSet().addTimedGroupingLineItemsByField(CANONICAL_NAME_FIELD, {
        defaultGroupingOperation: 'sum'
      });
      setLabelAndFormatForAggregates(store);
    }
  );

  let [filterQuery, filtersComponent] = useLineItemFilter({
    'lender': 'Lender',
  }, spvStore);

  useEffect(() => {
    loadSiteStore(storeId, collection, MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"]).then(store => {
      if (store && aggregatedStore) {
        const materializedAggregatedStore = aggregatedStore.materializeTimed(
            StoreQuery.all(), {}, true
        )
        const netRevenueLis = materializedAggregatedStore.getDataSet()
            .getByCanonicalName('Net Revenue');
        const currentValueLis = materializedAggregatedStore.getDataSet()
            .getByCanonicalName('Current Value');
        const aggregatedLineItems = [...netRevenueLis, ...currentValueLis]
        console.info('aggregatedLineItems', aggregatedLineItems)
        store.getDataSet().addLineItems(aggregatedLineItems)
      }
      setSpvStore(store!)
    })
  }, []);

  if(!spvStore) return <LoadingBlock />;

  addCustomLabelToItemsInStore(spvStore, 'Drawn Balance', 'Funds Under Management', 'lender');
  addCustomLabelToItemsInStore(spvStore,'Current Value', 'Value of Assets')
  const lineItemNamesForTableView = ['Drawn Balance', 'Principal Repayment', 'Interest Payment', 'Withholding Tax', 'Interest Payment After Tax', 'Net Revenue'];
  const allLineItemNames = [...lineItemNamesForTableView, 'Annual Interest Rate', 'Current Value'];
  const spvMaterializedStore = spvStore
    .materializeTimed(StoreQuery.byNames(allLineItemNames)
        .or(filterQuery)
        .withTimeIndex(spvStore.timeIndex.withGranularity(granularity))
    )

  let spvResult = spvMaterializedStore.query(StoreQuery.byNames(allLineItemNames, true));
  let spvResultForTableView = spvMaterializedStore.query(StoreQuery.byNames(lineItemNamesForTableView, true));


  return <div>
    <Header>{header}</Header>

    {filtersComponent}
    {info(spvResult)}

    {spvStore && <IncomingPayments store={spvStore} filter={filterQuery} />}
    {spvResultForTableView && <GranularitySelectorWithGroupedTable granularity={granularity} setGranularity={setGranularity} queryResult={spvResultForTableView} store={spvStore} />}
    {spvResult && <RevenueVsLoanCommitments queryResult={spvResult} />}
    {spvResult && <FUMvsVOAChart queryResult={spvResult} />}
  </div>
}

function GranularitySelectorWithGroupedTable({queryResult, store, granularity, setGranularity}: {queryResult: QueryResult, store: LineItemsStore, granularity: string, setGranularity: (granularity: TimeUnits) => void}) {
    return <>
        <Select
            placeholder='Select granularity'
            value={granularity}
            options={[
                { key: 'months', value: 'months', text: 'Month' },
                { key: 'years', value: 'years', text: 'Year' },
            ]}
            onChange={(e, {value}) => {
                setGranularity(value as TimeUnits);
            }}
        />
        <LineItemsTableWithFormulas queryResult={queryResult} store={store}  withGroups projectionsStartDate={new Date(getLastDateOfPreviousMonth())}/>
    </>
}

function RevenueVsLoanCommitments({queryResult}: { queryResult: QueryResult }) {
    return <DemoTimeSeriesChart title={"Revenue vs Loan Commitments"} result={queryResult}
                            options={{
                                type: 'bar',
                                stackingConfig: {
                                    relativeToLi: "Net Revenue"
                                },
                                includeLineItems: ['Net Revenue', 'Principal Repayment', 'Interest Payment']
                            }}/>
}

function FUMvsVOAChart({queryResult}: { queryResult: QueryResult }){
    return  <DemoTimeSeriesChart title={"Funds Under Management vs Value of Assets"} result={queryResult}
                                                   options={{
                                                       includeLineItems: ['Drawn Balance', 'Current Value']}}/>
}

export function IncomingPayments({store, filter}: {store: LineItemsStore, filter: StoreQuery}) {

  let result = store.query(
      StoreQuery.withField('lender').and(filter)
  );

  let nextPayments: { date: Date, value: string, paymentType: string, lender: string }[] = [];
  for(let row of result.rows) {
    for(let paymentType of ["Interest Payment", "Principal Repayment"]) {
        if (row.name.text.toLowerCase().endsWith(paymentType.toLowerCase())) {
          for (let cell of values(row)) {
            let timeCol = (result.columnById(cell.columnId) as TimeColumn);
            let today = new Date().getTime();
            const onTime = timeCol && timeCol.time && today < timeCol.time
            if (onTime && cell.value !== 0 && row["fields.lender"] && row["fields.lender"].text.trim()) {
              nextPayments.push({
                date: new Date(timeCol.time),
                value: cell.text,
                paymentType,
                lender: toFirstUppercase(row["fields.lender"].text)
              });
              break;
            }
          }
        }
      }
  }

  // sort by date
  nextPayments.sort((a, b) => a.date.getTime() - b.date.getTime());


  return (<div>
    <Message warning style={{marginTop: "15px", marginBottom: "15px"}}>
      <div><strong>Upcoming Loan Payments</strong></div>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Payment Type</Table.HeaderCell>
            <Table.HeaderCell>Next Payment Date</Table.HeaderCell>
            <Table.HeaderCell>Lender</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>

          </Table.Row>
        </Table.Header>
        <Table.Body>
          {nextPayments.map((p, i) => (<Table.Row>
            <Table.Cell>{p.paymentType}</Table.Cell>
            <Table.Cell>{formatDateByTimeUnit(p.date, "days")}</Table.Cell>
            <Table.Cell>{p.lender}</Table.Cell>
            <Table.Cell>{p.value}</Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Message></div>
  );
}

function toFirstUppercase(str: string) {
    if (str.length < 2) return str.toUpperCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
}