import {LocatorMap} from "../../ps-models/exa";
import {WhatIfScenarioConfig, WhatIfScenarioInputValues, WhatIfScenarioMappingConfig} from "../../ps-types";
import {ValueType} from "../../ps-models/line-items";
import {assocPath} from "ramda";
import {Grid, Input} from "semantic-ui-react";
import {convertToNumberStringOrBoolean, entries} from "../../ps-models";
import React, {useEffect, useState} from "react";

export function LocatorsMapInput({mappingConfig, inputValues, onValuesUpdated}: {
  mappingConfig: WhatIfScenarioConfig['mappingConfig'],
  inputValues: WhatIfScenarioInputValues,
  onValuesUpdated: (values: WhatIfScenarioInputValues) => void
}) {

  const [values, setValues] = useState<Record<string, string>>({});

  useEffect(() => {
    let values: Record<string, string> = {};
    entries(inputValues).forEach(([key, value]) => {
      values[key] = value.value[0][0].toString();
    });
    setValues(values);
  }, [inputValues]);

  const handleLocatorChange = (valueKey: string, value: ValueType) => {

    //Update the corresponding value
    let valuesUpdated = assocPath([valueKey],
      ({value: [[ convertToNumberStringOrBoolean(value)]], valueKey: valueKey}),
      inputValues
    );

    onValuesUpdated(valuesUpdated);
  }

  return <>
    <Grid>
      {
        Object.values(mappingConfig).map((conf) => {
          let key = conf.valueKey;
          return <Grid.Row columns={2}>
            <Grid.Column>
              <strong>{conf.label}</strong>
            </Grid.Column>
            <Grid.Column>
              <Input
                      value={values[key] || ""}
                      onChange={(e:any) => {
                        setValues({...values, [key]: e.target.value})
                      }}
                     onBlur={(e:any) => {
                       handleLocatorChange(key, e.target.value)
                     }}
              />
            </Grid.Column>
          </Grid.Row>
        })
      }
    </Grid>
  </>
}