import React from "react";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Input, Form } from "semantic-ui-react";
import { 
  selectCurrentCompanies, selectCompanyById, selectCompaniesStatus, selectCompaniesError 
} from '../../../redux/companies/companies.selectors';
import { startEditCompany } from '../../../redux/companies/companies.actions';

class CompanyEditableAttribute extends React.Component {
  state = {
    isEditing: false
  }

  getCompanyData = () => {
    const companyId = this.props.match.params.id;
    const { companies } = this.props;
    if (!companyId || !companies) return null;
    const companyData = selectCompanyById(companies, companyId);
    if (!companyData) return null;
    return companyData;
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value, isEditing: true });
  }

  handleSubmit = async event => {
    event.preventDefault();

    const { id, 
      // fieldType, name 
    } = this.props;

    // Retrieve company data first
    const companyData = this.getCompanyData();
    if (!companyData) return null;

    // Access new data value
    const dbVal = companyData[id] ? companyData[id] : "";
    const newVal = (this.state[id] !== undefined ) ? this.state[id] : dbVal;

    // Update company data with new value
    const { editCompany } = this.props;
    await editCompany(companyData.id, { [id]: newVal });
    this.setState({ isEditing: false });
  }

  renderInput = () => {
    const { id, fieldType, name } = this.props;
    const { isEditing } = this.state;
    // const currentVal = "";

    // Retrieve company data first
    const companyData = this.getCompanyData();
    if (!companyData) return null;

    // Access new data value
    const dbVal = companyData[id] ? companyData[id] : "";
    const newVal = (this.state[id] !== undefined ) ? this.state[id] : dbVal;
    const currentVal = (newVal !== undefined) ? newVal : dbVal
    switch (fieldType) {
      case "shortText":
        return (
          <Form 
            onSubmit={this.handleSubmit}
          >
            <Input 
              fluid
              action={
                isEditing ? (
                  {
                    color: 'purple',
                    labelPosition: 'left',
                    icon: 'check circle',
                    content: 'Save Changes'
                  }
                ) : null
              }
              label={name}
              name={id}
              // placeholder='placeholder'
              value={currentVal}
              // disabled
              onChange={this.handleChange}
              style={{ marginBottom: '6px' }}
            />
          </Form>
        );
      // case "longText":
      //   return <Form.TextArea placeholder={question.prompt} name={question.id} onChange={this.handleChange} value={currentVal}  />;
      default:
        return <p>Unknown Input Type</p>;
    }
  }

  render() {
    return (
      <>
        {this.renderInput()}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  companies: selectCurrentCompanies,
  companyStatus: selectCompaniesStatus,
  companyError: selectCompaniesError,
});

const mapDispatchToProps = dispatch => ({
  editCompany: (companyId, companyInfo) => dispatch(startEditCompany(companyId, companyInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditableAttribute);