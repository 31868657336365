import {functions} from "../../firebase/firebase.core";
import {PsUser} from "../auth";
import { SignUpUserData} from "../ps-models";
import {MessagePayload, OnboardingData, PrimaryIntakeData} from "../ps-types";
import psPlatformClient from "../psPlatformClient";


export async function sendMessage(payload: MessagePayload) {
    const messagePacket = {
        ...payload,
        emailSubject: payload.emailSubject ?? `Message from ${payload.fromName} (${payload.fromEmail})`,
        emailVerified: payload.emailVerified ?? false,
        replyTo: payload.replyTo ?? payload.fromEmail,
    }
    console.log("Attempting to send messagePacket: ", messagePacket);
    const res = await functions.httpsCallable("sendEmailMessage")(messagePacket)
    console.log("Email send status: ", res);
}

export async function notifyNewSignUp(email: string, user: SignUpUserData): Promise<void> {
    const userProfile = user.profile;
    const fromName = userProfile.displayName || userProfile.firstName + " " + userProfile.lastName;
    if (isTestEmail(email)) {
        return
    }
    for (const admin_email of ADMIN_EMAILS) {
        try {
            await sendMessage({
                fromEmail: email,
                fromName,
                toEmail: admin_email,
                emailSubject: `Initial signup from ${fromName} (${email})`,
                message: `\n${fromName} just signed up for an account using the email ${email}. They have not yet created a company profile. Email is unverified as well.\n`,
            });
        } catch (error) {
            console.error(`Failed to send message about ${email} to ${admin_email}, error: `, error);
        }
    }
}

export async function notifyNewCompany(data: OnboardingData | PrimaryIntakeData, user: PsUser, onboardedVia: 'intake' | 'onboarding'): Promise<void> {
    const profile = user.getProfile()
    const fromName = profile.displayName || profile.firstName + " " + profile.lastName;
    if (isTestEmail(user.email)) {
        return
    }
    let onboardMsg = ''
    if(onboardedVia === 'intake'){
        const notificationData: PrimaryIntakeData = data as PrimaryIntakeData;
        onboardMsg = `
        Email: ${user.email}<br/>
        Company Name: ${notificationData.companyName}<br/>
        Description: ${notificationData.companyDescription}<br/>
        Technology: ${notificationData.technologyType}<br/>
        Reason For Interest: ${notificationData.reasonForInterest}<br/>
     `;
    } else if(onboardedVia ==='onboarding'){
        const notificationData: OnboardingData = data as OnboardingData;
        onboardMsg = `
        Email: ${user.email}<br/>
        Company Name: ${notificationData.companyName}<br/>
        Website: ${notificationData.companyWebsite}<br/>
        Description: ${notificationData.companyDescr}<br/>
        Hardware: ${notificationData.companyHardware}<br/>
        Customers: ${notificationData.customerTypes}<br/>
        Referral Source: ${notificationData.refSource}<br/>
     `;
    }

    for (const admin_email of ADMIN_EMAILS) {
        try {
            await sendMessage( {
              fromEmail: user.email,
              fromName,
              toEmail: admin_email,
              emailSubject: `New Lead for ${data.companyName} (${user.email})`,
              message: onboardMsg,
            });
        } catch (error) {
            console.error(`Failed to send message about ${user.email} to ${admin_email}, error: `, error);
        }
    }
}

const ADMIN_EMAILS = ["arvind@perlstreet.com", "tooraj@perlstreet.com"];

const isTestEmail = (email: string) => {
    return (
        email.endsWith("@cypress-test.perlstreet.com") ||
        (email.startsWith("jose") && email.endsWith("@perlstreet.com")) ||
        (email.startsWith("ricardo") && email.endsWith("@perlstreet.com")) ||
        (email.startsWith("nishant") && email.endsWith("@perlstreet.com")) ||
        (email.startsWith("udyan") && email.endsWith("@perlstreet.com"))
    );
};