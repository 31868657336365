import psPlatformClient from "../../psPlatformClient";
import {LineItemsStoreDto} from "../../ps-models/lineitems-store";
import {AxiosError, AxiosResponse} from "axios";

export const uploadFile = async (companyId: string, file: File, collection: string, overwriteLineItems: boolean, updateFileTransferProgress?: (percentageCompleted: number)=>void, template?: string) => {

    const formData = new FormData();

    formData.append('file', file);
    formData.set('companyId', companyId);
    formData.set('collection', collection);
    formData.set('overwriteLineItems', overwriteLineItems.toString());


    const result = FileUploadResponseWrapper(psPlatformClient<FormData, [LineItemsStoreDto[],{warning: string[]; info: string[]}]>({method: 'post', url: '/integrations/file-upload',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
            companyId
        },
        ...(updateFileTransferProgress ? {
            onUploadProgress: ((progressEvent) => {
                if(progressEvent?.total) {
                    let percentageCompleted = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
                    updateFileTransferProgress(percentageCompleted);
                }
            })
        }: {})
    }))
    return result;
}

const FileUploadResponseWrapper = async (request: Promise<AxiosResponse<any, any>>): Promise<[LineItemsStoreDto[],{warning: string[]; info: string[]}]> => {
    try {
        const result = await request;
        return result.data;
    } catch(err){
        if(err instanceof AxiosError && err.response && err.response.status ==422 && err.response.data && typeof err.response.data === 'object'){
            const errResponseDescription = err.response.data?.description;
            if(errResponseDescription?.reason){
                throw new Error(errResponseDescription?.reason);
            }
        }
        throw err;
    }
}
