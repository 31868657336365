const ActionTypes = {

  // Get scenarios
  GET_SCENARIOS_START: 'getScenarios/start',
  GET_SCENARIOS_SUCCESS: 'getScenarios/success',
  GET_SCENARIOS_FAILURE: 'getScenarios/failure',

  // Assign scenarios
  ASSIGN_SCENARIO_START: 'assignScenario/start',
  ASSIGN_SCENARIO_SUCCESS: 'assignScenario/success',
  ASSIGN_SCENARIO_FAILURE: 'assignScenario/failure',

  // Delete scenario
  DELETE_SCENARIO_START: 'deleteScenario/start',
  DELETE_SCENARIO_SUCCESS: 'deleteScenario/success',
  DELETE_SCENARIO_FAILURE: 'deleteScenario/failure',

};

export default ActionTypes;