import {useState } from "react";
import { Button, Input, Segment } from "semantic-ui-react";
import { useCompanyId } from "../../core";
import { createAmProjectVersionBulk } from "./amProject.client";
import { useMessages } from "../../ui/MessagesProvider";
import MultiFileUploader, { FileUploadStatus } from "../../ui/MultiFileUploader";
const { Grid } = require("semantic-ui-react");

export function MultiProjectUploadTemplate() {
  let companyId = useCompanyId();
  let [title, setTitle] = useState("");
  let [description, setDescription] = useState("");
  let { clear, error } = useMessages();
  let [uploadProgress, setUploadProgress] = useState<number>(0);
  const [files, setFiles] = useState<FileUploadStatus[]>([]);

  const isDescriptionValid = (suppliedDescription: string) => {   
    let isValid = true;
    if (!suppliedDescription) {
      error(`Please provide a brief description of the version`);
      isValid = false;
    }
    return isValid;
  }

  const isAxiosError = (error: any): error is { message: string } => {
    return error && typeof error.message === "string";
  };

  const uploadFile = async (fileStatus: FileUploadStatus) => {
    clear();
    if (!fileStatus.file) return;
    const isValid = isDescriptionValid(description);
    if (!isValid) {
      return;
    }
    try {
      await createAmProjectVersionBulk(
        companyId,
        title,
        description,
        fileStatus.file,
        (progress) => setUploadProgress(progress)
      )
        .then((res) => {
          if (("errors" in res && res.errors.length)) {
            setFileDetails(setFiles, fileStatus.file.name, res);
          } else {
            setFileDetails(setFiles, fileStatus.file.name, res, true);
          }
        })
        .catch((e) => {
          setFileDetails(setFiles, fileStatus.file.name, "", false, [
            e.message,
          ]);
        });
    } catch (error) {
      if (isAxiosError(error)) {
        setFileDetails(setFiles, fileStatus.file.name, "", false, [
          (error as Error)?.message || "",
        ]);
      } else {
        setFileDetails(setFiles, fileStatus.file.name, "", false, [
          "An unknown error occurred",
        ]);
      }
    }
  };

  return (<>
    <Segment style={{ padding: "40px" }}>
      <div>
        <div style={{ position: "absolute", top: 10, left: 10 }}>
          <Button
            basic
            labelPosition="left"
            icon="left chevron"
            size={"mini"}
            onClick={() => window.history.back()}
            content="Back"
          />
        </div>
        <p>
          <Input label="Version Title" placeholder="Version Title"
            value={title}
            required
            onChange={(e, data) => setTitle(data.value)} />
          <Input label="Version Description" maxLength={100} style={{ width: `${Math.max(30, description.length + 12)}ch` }} placeholder="Version Description" onChange={(e, data) => setDescription(data.value)} />
        </p>
        <Grid>
          <Grid.Row columns={1} >
            <h3>Create From File Template</h3>
            <Grid.Column >

              <MultiFileUploader
                uploadFile={uploadFile}
                setFiles={setFiles}
                files={files}
                uploadProgress={uploadProgress}
                fileTypes={["csv", "xls", "xlsx", "xlsm"]}
                successButtonTitle="Project Details"
              />
            </Grid.Column>

          </Grid.Row>
        </Grid>
      </div>
    </Segment>
  </>
  );
}

function setFileDetails(
  setFiles: (update: (prevFiles: any) => any) => void,
  fileName: string,
  res: any,
  success?: boolean | false,
  errors?: string[]
) {
  return setFiles((prevFiles: any) =>
    prevFiles.map((file: any) =>
      file.file.name === fileName
        ? {
          ...file,
          errors: res?.errors || errors,
          warnings: res?.warnings || [],
          projectId: res?.updatedProjectId || "",
          success,
        }
        : file
    )
  );
}
