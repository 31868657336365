import {usePSQuery} from "../../../lineitems-store/LineItemsStore.hook";
import {pQuery, StoreQuery} from "../../../ps-models/lineitems-store";
import {SmallLoading} from "../../../ui/Loading";
import {groupBy, indexBy, values} from "ramda";
import React from "react";
import {buildLineItemsStorePersistence} from "../../../lineitems-store/RestLineItemsStore.persistence";

export interface Loan {
  id: string;
  name: string;
  amount: number;
  borrower: string;
  interestRate: string;
  projectCount: number;
  projectIds: string[];
}


export async function getLoans(companyId: string): Promise<Loan[]> {

  let persistence = buildLineItemsStorePersistence();

  let store = await persistence.query(
    'CGCDemo_LineItems',
    pQuery()
      .withLineItems([
        'Project Name',
        'Input Data-Loan',
        'Input Data-Borrower',
        'Debt assumptions - Debt',
        'Debt assumptions - Interest Rate',
      ])
      .withGranularity('years')
  )



    let results = store.query(
    StoreQuery.all()
  );


  let loans = results.rows.map((row) => {
    let loanName = results.rowColumnValue(row, 'fields.source_inputdata-loan') as string;
    return {
      id: loanName,
      name: loanName,
      amount: results.rowColumnValue(row, 'fields.source_debtassumptions-debt') as number,
      interestRate: results.rowColumnValue(row, 'fields.source_debtassumptions-interestrate') as string,
      borrower: results.rowColumnValue(row, 'fields.source_inputdata-borrower') as string,
      projectName: results.rowColumnValue(row, 'fields.source_projectname') as string
    }
  });

  let groupedLoans = groupBy((loan) => loan.name, loans);

  loans = values(indexBy((loan) => loan.id, loans))

  return loans.map((loan) => {
    let projects = new Set(groupedLoans[loan.id].map(it => it.projectName));

    return {
      ...loan,
      projectCount: projects.size,
      projectIds: Array.from(projects)
    }
  });
}