import React, { useEffect } from "react";
import { Header, Segment, List, Icon, Container, Divider, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";

import { selectCompaniesData, selectUsersData, selectUsersStatus } from "../../../redux/admin/admin.selectors";
import { getUsers, getCompanies } from "../../../redux/admin/admin.actions";

import {lastModifiedCompare, extractSecondsFromDate} from "../../../utils/utilfunctions";
import UserAssign from "./UserAssign";
import UserSubscriptionStatus from './UserSubscriptionStatus';

function UsersList() {
  // const history = useHistory();
  const dispatch = useDispatch();

  const companies = useSelector(selectCompaniesData);
  const users = (useSelector(selectUsersData) || []).sort(lastModifiedCompare);
  const usersStatus = useSelector(selectUsersStatus);

  const loading = usersStatus === "loading";
  const usersIcon = "users";

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getCompanies());
  }, [dispatch]);

  return (
    <Segment basic>
      <Container>
        <Header as='h2' color="purple" size="huge">
          <Icon className="users" size="small" />
          <Header.Content>
            Users
            <Header.Subheader>Manage users and assign them to companies</Header.Subheader>
          </Header.Content>
        </Header>
        <Divider hidden />
        <Divider hidden />
        <Segment.Group raised>
          <Segment attached>
            <Header size="tiny" icon={<Icon className={usersIcon} />} content={`All Users`} />
          </Segment>
          <Segment attached color="purple" loading={loading}>
            <List relaxed animated>
              { (!users.length) && <p>No users found</p> }
              {users.map( u => {
                const assignedCompany = companies.find(c => c.members && c.members.includes(u.id));
                const isAdmin = false // u.email.endsWith("@perlstreet.com");
                let displayName = `${u.id} (${u.email})`;
                if (!!u.displayName) { displayName = `${u.displayName} (${u.email})`; }
                else if (!!u.firstName && u.firstName !== undefined) { displayName = `${u.firstName} ${u.lastName} (${u.email})`; }
                return (
                <List.Item key={u.id}> 
                  { !isAdmin && (
                    <List.Content floated='right'>
                    {/* <Link to={`/users/${u.id}`}><Button size="tiny" compact>Manage</Button></Link> */}
                      { !!assignedCompany && <UserSubscriptionStatus user={u} /> }
                      <UserAssign user={u} companies={companies} text={assignedCompany ? "Reassign" : "Assign"}/>
                    </List.Content>
                  )}
                  <List.Icon className={"user"} color="purple" />
                  <List.Content>
                    <List.Header>
                      {/* <Link to={`/users/${u.id}`}>{`${u.displayName || u.id} (${u.email})`}</Link> */}
                      <Link to={`#`}>{displayName}</Link>
                    </List.Header>
                    <List.Description>
                      {assignedCompany && <Label size="mini" color="purple" icon={<Icon className="city"/>} content={`Assigned to ${assignedCompany.name}`} />}
                      {/* {(u.shareTokens && u.shareTokens.length) ? <Label size="mini" color="purple" icon="share square" content={`${u.shareTokens.length} Tokens`} /> : ""} */}
                    </List.Description>
                    <List.Description>
                      {u.id}
                    </List.Description>
                    <List.Description>
                      Updated on { u.lastModified && moment(extractSecondsFromDate(u.lastModified) * 1000).format('MMMM Do YYYY, h:mm a') }
                    </List.Description>
                  </List.Content>
                </List.Item>
              )})}
            </List>
          </Segment>
        </Segment.Group>
      </Container>
    </Segment>
  );
}

export default UsersList;