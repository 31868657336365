import {
    FormDropdownProps,
    FormInputProps,
    FormTextAreaProps,
} from "semantic-ui-react";

export type FormFieldErrorType = any | null;

interface BaseInputFieldDef  {
    label: string;
    placeholder: string;
    required: boolean;
}

interface TextInputFieldBaseDef extends BaseInputFieldDef{
    customFieldType: 'text';
    customFieldValidator : (value: string) => (string | null);
    maxLength: number;
}

interface TextInputFieldDef extends TextInputFieldBaseDef, Omit<FormInputProps, 'label' | 'required'> {}

interface TextAreaInputFieldBaseDef extends BaseInputFieldDef{
    customFieldType: 'textArea';
    customFieldValidator : (value: string) => (string | null);
    rows: number;
    maxLength: number;
}

interface TextAreaInputFieldDef extends TextAreaInputFieldBaseDef, Omit<FormTextAreaProps, 'rows' | 'required' | 'label'>{}

interface NumberInputFieldBaseDef extends BaseInputFieldDef{
    customFieldType: 'number';
    customFieldValidator : (value: number) => (string | null);
    min: number;
    max: number;
}

interface NumberInputFieldDef extends NumberInputFieldBaseDef, Omit<FormInputProps, 'label'> {}

interface DropdownInputFieldBaseDef extends BaseInputFieldDef {
    customFieldType: 'dropdown';
    customFieldValidator : (value: string | string[]) => (string | null);
    options: FormDropdownProps['options']
}

interface DropdownInputFieldDef extends DropdownInputFieldBaseDef, Omit<FormDropdownProps, 'label'> {}

interface ListItemsInputFieldBaseDef extends BaseInputFieldDef {
    customFieldType: 'listItem';
    maxItems: number;
    customFieldValidator: (value: string[]) => (string | null);
    maxLengthPerItem: number;
}
export interface ListItemsInputFieldDef extends ListItemsInputFieldBaseDef, Omit<FormInputProps, 'label'> {}

export type InputFieldDef = TextInputFieldDef | TextAreaInputFieldDef | NumberInputFieldDef | DropdownInputFieldDef | ListItemsInputFieldDef

export class InputFieldDefinitionBuilder {
    private noValidations(v: any){
        return null;
    }
    public buildTextInput(def: Omit<TextInputFieldBaseDef, 'customFieldType' | 'customFieldValidator'> & FormInputProps & {customFieldValidator?: TextInputFieldDef['customFieldValidator']}): TextInputFieldDef {
        return {
            customFieldValidator: this.noValidations,
            ...def,
            customFieldType: 'text'
        }
    }

    public buildTextAreaInput(def: Omit<TextAreaInputFieldBaseDef, 'customFieldType' | 'customFieldValidator'> & FormTextAreaProps & {customFieldValidator?: TextAreaInputFieldDef['customFieldValidator']}): TextAreaInputFieldDef {
        return {
            customFieldValidator: this.noValidations,
            ...def,
            customFieldType: 'textArea',
        }
    }

    public buildNumberInput(def: Omit<NumberInputFieldBaseDef, 'customFieldType' | 'customFieldValidator'> & FormInputProps & {customFieldValidator?: NumberInputFieldDef['customFieldValidator']}): NumberInputFieldDef {
        return {
            customFieldValidator: this.noValidations,
            ...def,
            customFieldType: 'number'
        }
    }

    public buildDropdownInput(def: Omit<DropdownInputFieldBaseDef, 'customFieldType' | 'customFieldValidator'> & FormDropdownProps & {customFieldValidator?: DropdownInputFieldDef['customFieldValidator']}): DropdownInputFieldDef {
        return {
            customFieldValidator: this.noValidations,
            ...def,
            customFieldType: 'dropdown'
        }
    }

    public buildListItemInput(def: Omit<ListItemsInputFieldBaseDef, 'customFieldType' | 'customFieldValidator'> & FormInputProps & {customFieldValidator?: ListItemsInputFieldDef['customFieldValidator']}): ListItemsInputFieldDef {
        return {
            customFieldValidator: this.noValidations,
            ...def,
            customFieldType: 'listItem'
        }
    }
}

