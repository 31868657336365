import psPlatformClient from "../../psPlatformClient";
import {AmScenario} from "../../ps-types";
import {PartialBy} from "../../ps-models";

export async function getScenarios(companyId: string): Promise<AmScenario[]> {
  //Call scenario backend endpoint
  const response = await
    psPlatformClient<null, AmScenario[]>({method: 'get', url: `/am/${companyId}/scenario` });
  return response.data as AmScenario[];
}

export async function saveScenario(companyId: string, scenario: PartialBy<AmScenario, 'id' | 'createdAt'> ) {
  if(scenario.selectedProjectVersions.length === 0) throw new Error("Scenario selection is required");

  return (await
    psPlatformClient<any, AmScenario>({method: 'post', url: `/am/${companyId}/scenario`,
      data: {
        ...scenario
      }
    })).data
}

export const amDeleteScenario = async (companyId: string, scenarioId: string) => {
  const result = await psPlatformClient<null, null>(
    { method: 'delete', url: `/am/${companyId}/scenario/${scenarioId}` })
  return result.data;
}