import {LineItemsStore, PersistenceQuery} from "../../../ps-models/lineitems-store";
import {StoreQueryFlat} from "../../../ps-models/lineitems-store/StoreQueryFlat";
import {QueryLineItemNamesPicker} from "./QueryLineItemNamesPicker";

export function QueryLineItemNamesSelector<T extends PersistenceQuery | StoreQueryFlat>({query, store, onQueryUpdate}: {query: T,store?: LineItemsStore, onQueryUpdate: (updatedQuery: T) =>void}) {
  return  <QueryLineItemNamesPicker initiallySelectedLineItems={query.serialize().lineItems} sectionTitle={'Select By Names'} store={store} query={query} onQueryUpdate={onQueryUpdate}
                                    onItemSelection={(localQuery, lineItemsToSelect)=>{
                                      localQuery.withLineItems(lineItemsToSelect);
                                    }}
  />
}