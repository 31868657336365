import {normalizeString} from "../line-item-utils/coding.utils";
import {fromPairs, indexBy, prop, values} from "ramda";
import {LineItemsFieldSet} from "./LineItemsFieldSet";
import {LineItemField} from "../../ps-types";


export interface LineItemMetadata {
  isAggregate: boolean,
  account: string,
  //Todo: This should replace categories
  labels: Record<string, LineItemLabel>
  errors: Record<string, LineItemError>
}



export const DEFAULT_CATEGORY = "root";

export const ErrorTypeValues = ['#DIV/0!', '#N/A', '#NUM!', '#NULL!', '#REF!', '#NAME?', '#VALUE!', '#TYPE!', '#EMPTY!', '#CALCULATED_LINE_ITEM!'] as const
// #TYPE! & #EMPTY! & #CALCULATED_LINE_ITEM! are non-standard, the rest are MS Excel errors
export type ErrorType = typeof ErrorTypeValues[number]

export interface LineItemError {
  kind: ErrorType,
  ctx?: any
}


export interface LineItemMetadataField {
  name: string,
  value: string
}
export  interface LineItemLabel {
  key: string //Key is the normalized name

  name: string
  //TODO: Group should be changed to value
  group: string //accountType, report etc
}

export function getAccountType(fields: Record<string, LineItemField>): LineItemField | undefined {
  for (let field of values(fields)) {
    if (field.value === "AccountType") {
      return field
    }
  }
}

export function isCategoryLineItem({fields, name}: { fields: Record<string, LineItemField>, name: string }): boolean {
  return getAccountType(fields)?.key === normalizeString(name)
}

//*** TODO: Deprecate **/
export function LineItemMetadata(category: string, category2: string, isAggregate: boolean = false, account: string = "-"): LineItemsFieldSet {

  return  LineItemsFieldSet.fromMap(
    {
      [normalizeString(category)]: "AccountType",
      [normalizeString(category2)]: "Page"
    }
  )
}

export function LineItemMetadataLegacy(category: string, category2: string, isAggregate: boolean = false, account: string = "-") {
  return {
    isAggregate, account, errors: {}, labels: {
      [normalizeString(category)]: {name: category, key: normalizeString(category), group: "AccountType"},
      [normalizeString(category2)]: {name: category2, key: normalizeString(category2), group: "Page"}
    }
  }
}


export function field(name: string, value: string): LineItemMetadataField {
  return {name, value}
}

export function addFieldsToMetadata(metadata: LineItemMetadata, ...fields: LineItemMetadataField[]): LineItemMetadata {
  return {
    ...metadata,
    labels: {
      ...metadata.labels,
      ...fromPairs(fields.map(f => [normalizeString(f.name), {
          name: f.name,
          key: normalizeString(f.name),
          group: f.value
        }]
      ))
    }
  }
}

export function getFieldValue(metadata: LineItemMetadata, field: string): string | undefined {
  return metadata.labels[normalizeString(field)]?.group
}

export function getFieldMap(metadata: LineItemMetadata): Record<string, string> {
  return fromPairs(values(metadata.labels).map(l => [l.name, l.group]))
}

export function buildMetadata(...labels: { name: string, value: string }[]): LineItemsFieldSet {
  return LineItemsFieldSet.fromList(labels)
}

export function isCalculatedLineItem(metadata: Record<string, LineItemField>): boolean {
  return getAccountType(metadata)?.key === DEFAULT_CATEGORY || !!metadata[normalizeString(DEFAULT_CATEGORY)]
}

export function cloneMetadata(metadata: LineItemMetadata): LineItemMetadata {
  return {...metadata, labels: {...metadata.labels}}
}