import React, {useEffect, useState} from "react";
import {Button, Select} from "semantic-ui-react";
import {PsFileUploader} from "../../ui/FileUploader.component";
import {ProjectCustomImport} from "../../ps-types";
import {getCustomImports, uploadCustomImport} from "./customImports.client";
import {useCompanyId} from "../../core";
import {useMessages} from "../../ui/MessagesProvider";

export function CustomImports() {
  const companyId = useCompanyId();
  const [template, setTemplate] = useState<string>();
  const [file, setFile] = useState<File>();
  const [customImports, setCustomImports] = useState<ProjectCustomImport[]>([]);
  const {error, info, warning} = useMessages();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    getCustomImports(companyId).then((res) => {
      if(res.length === 0) {
        warning("No custom import templates available for your company")
      }
      setCustomImports(res)
    })
  }, []);

  let templateOptions = customImports?.map(ci => ({
    key: ci.name, text: ci.label, value: ci.name
  }))

  const handleUpload = () => {
    if (!template) {
      error("Please select a template")
      return
    }
    if (!file) {
      error("Please select a file")
      return
    }
    setIsUploading(true)
    uploadCustomImport(companyId, file, template)
      .then(() => {
        info(`Successfully imported ${file.name} using ${template} template`)
      }).catch((e) => {
      error(e.message)
    }).finally(() => {
      setIsUploading(false)
    });
  }


  return <>
    <Select options={templateOptions}
            style={{marginBottom: 10}}
            value={template}
            placeholder="Select a template"
            onChange={(e, data) => {
              setTemplate(data.value as string)
            }
            }/>

    <PsFileUploader
      setFile={(file) => setFile(file)}
    />

    <Button
      loading={isUploading}
      disabled={isUploading}
      onClick={handleUpload} primary >Import</Button>
  </>
}