import React, {useEffect, useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const HtmlEditor = ({ defaultValue, onChange, placeholder }: {defaultValue: string, onChange:(val:string)=>void,placeholder: string}) => {
    const [editorHtml, setEditorHtml] = useState('');
    useEffect(()=>
    {
        setEditorHtml(defaultValue);
    }, [defaultValue])

    const handleChange = (html: string) => {
        setEditorHtml(html);
        onChange(html);
    };

    return (
        <ReactQuill
            style={{margin: '10px 0 50px 0'}}
            placeholder={placeholder}
            theme="snow"
            value={editorHtml}
            onChange={handleChange}
        />
    );
};

export default HtmlEditor;