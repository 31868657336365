import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useCompanyId } from "../../core";
import { SmallLoading } from "../../ui/Loading";
import { Button, Grid, Header } from "semantic-ui-react";
import {
  getAllSpvs,
  getAllWorkflowLogsByType,
  getWorkflowLogs,
  importSpvsWorkflowCall,
} from "./amProjectSync.client";
import { useMessages } from "../../ui/MessagesProvider";
import { GridToolbar } from "@mui/x-data-grid";
import { DateRange } from "../../lineitems-store/DateRange";
import { addMonths } from "../../ps-models";
import { SPVSyncLogs } from "./SpvSyncLogs";
import { EntityCrud, buildEntityDef } from "../../ui/crud/EntityCrud.component";
import { timeAgo } from "./ProjectDetail.page";
import { ContentPopup } from "../../ui/popup/ContentPopup";
import { usePopup } from "../../ui/popup/Popup";

export function SyncSpvs() {
  const [spvs, setSpvs] = useState<string[]>([]);
  const [executionId, setExecutionId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const companyId = useCompanyId();
  const [selected, setSelected] = useState<string[]>([]);
  const { info, error } = useMessages();
  const [startDate, setStartDate] = useState<Date>(
    addMonths(new Date("2023-01-01"), -3)
  );
  let { openPopup, closePopup } = usePopup();
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [projects, setProjects] = useState<any[]>([]);

  useEffect(() => {
    getAllSpvs(companyId).then((res) => {
      setSpvs(res!);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (executionId) {
      getWorkflowLogs(companyId, executionId).then((res) => {
        setProjects([
          {
            ...res,
            id: executionId,
          },
        ]);
      });
    }
  }, [executionId]);
  //Projects map to Table for MUIX GRid
  const rows = spvs.map((spv: any) => {
    return {
      id: spv.SPV,
      name: spv.name
    };
  });

  const columns = Object.keys(rows[0] || {}).map((key) => {
    return {
      field: key,
      headerName: key,
      width: 350,
    };
  });

  const handleImportSpvs = () => {
    if (selected.length == 0) {
      error("Please select at least one SPV.");
      return;
    }
    setLoading(true);
    importSpvsWorkflowCall(companyId, selected, "spvs", {
      dateFrom: startDate.getTime(),
      dateTo: endDate.getTime(),
    })
      .then((res) => {
        info("SPVs Synced.");
        setExecutionId(res.executionId);
        setLoading(false);
      })
      .catch((e) => {
        error(`Error syncing SPVs ${e.message}`);
        setLoading(false);
      });
  };

  const getEntities = () => getAllWorkflowLogsByType(companyId, "spv");

  const closePopupFun = () => {
    closePopup();
    setProjects([]);
  }
  const LogsContent = (data: any) => {
    setExecutionId(data.data.executionId);
    let openPopupContent = (
      <ContentPopup header="Logs" onClose={closePopupFun}>
        <Grid.Row>
          <SPVSyncLogs
            executionId={data.data.executionId}
            projects={projects}
            initialJobProgress={{
              logEntries: [],
              total: 0,
            }}
          />
        </Grid.Row>
      </ContentPopup>
    );
    return (
      <p
        style={{ cursor: "pointer", color: "#9b51e0" }}
        onClick={() => openPopup(openPopupContent)}
      >
        {data.data.executionId}
      </p>
    );
  };

  let def = buildEntityDef(
    {
      entityName: "SPV History",
      title: "SPV Trigger History",
      getEntities,
    },
    {
      executionId: {
        title: "Execution Id",
        table: {
          render: (_value: string, spv) => <LogsContent data={spv} />,
        },
      },
      status: {
        create: { type: "hidden" }
      },
      createdAt: {
        title: "Created At",
        table: { format: timeAgo },
        create: { type: "hidden" },
      },
    }
  );

  return (
    <div>
      <div>
        <Header floated="left" as="h3">
          Sync SPVs
        </Header>
        <div style={{ clear: "both" }}></div>
        <div>
          <DateRange
            initialStartDate={startDate}
            initialEndDate={endDate}
            onSelect={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
          <Button content={"SPVs Sync"} onClick={handleImportSpvs} />
        </div>
      </div>

      <div style={{ clear: "both" }}></div>

      {loading && <SmallLoading message="loading projects" />}

      <div style={{ width: "100%", marginTop: "20px" }}>
        <DataGridPro
          density={"compact"}
          rows={rows}
          columns={columns}
          pagination={true}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          autoHeight
          columnBuffer={2}
          columnThreshold={2}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(selection) => {
            console.log(selection);
            setSelected(selection as string[]);
          }}
        />
      </div>
      <div style={{ margin: "20px 0px" }}>
        <EntityCrud entityDef={def} />
      </div>
    </div>
  );
}
