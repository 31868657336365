import {buildLineItemsStorePersistence} from "../../lineitems-store/RestLineItemsStore.persistence";
import {Site, siteDescription} from "../siteHooks";
import {entries, mapObjectValues, NormalizedString, normalizeString, ObjectNotFound} from "../../ps-models";
import {LineItemsStore, pQuery, QueryResult, StoreQuery} from "../../ps-models/lineitems-store";
import {
  LineItemsFieldSet, TimedCalculatedLineItem
} from "../../ps-models/line-items";
import {FieldDifferences} from "./BatchFieldsEditor";
import {PairTableRow} from "../../ui/PairTable";
import {loadSiteStore, saveSiteStore} from "../siteStoreLoader";
import {FormulaDifferences} from "./BatchFormulaEditor";
import {LineItemBatchUpdates} from "./BatchEditor";
import {LineItemField} from "../../ps-types";

export const DIFFERENCES_CONSTANT = '?????????'

const persistence = buildLineItemsStorePersistence()

export interface LineItemBatch {
  lineItemName: string,
  siteLineItems: SiteLineItem[],
  queryResult: QueryResult
  store: LineItemsStore
}

export type SiteLineItem = Site & {liName: string}
export async function queryLineItemOnSites(collection: string, lineItemName: NormalizedString, sites: Site[]): Promise<[LineItemsStore, QueryResult, SiteLineItem[], SiteLineItem[]]> {
  const siteLineItems = sites.map(site => ({...site, liName: `${normalizeString(site.label)}-${lineItemName}`}))
  const store = await persistence.query(
    collection,
    pQuery()
        .withStoreIds(siteLineItems.map(opt => opt.id))
        .withLineItems([lineItemName])
        .withMaterializedCalculatedLineItems(false)
  )
  const queryResult = store.query(StoreQuery.byNames(siteLineItems.map(({liName}) => liName)))
  const missingLineItems = siteLineItems.filter(({liName}) => queryResult.row(liName) === undefined)
  const existingLineItems = siteLineItems.filter(({liName}) => queryResult.row(liName) !== undefined)
  return [store, queryResult, missingLineItems, existingLineItems]
}

export async function updateRemoteStoreFromStoreWithSites(collection: string, storeSetup: (store: LineItemsStore)=>void, lineItemName: NormalizedString, aggregatedStore: LineItemsStore, siteLineItems: SiteLineItem[],
                                                          detailsToUpdate: {
    updateFields: boolean,
    updateFormula: boolean
}
                                                          ): Promise<void> {
  for (const sli of siteLineItems) {
    console.info('Loading store:', sli.id, collection)
    const store = await loadSiteStore(sli.id, collection, storeSetup)
    if (!store) {
      throw new ObjectNotFound(`Store ${sli.id} not found`)
    }
    const lineItemInAggregatedStore = aggregatedStore.getDataSet().getLineItem(sli.liName);
    if(detailsToUpdate.updateFields){
      // @TODO: Clean this code later, and make it quicker
      for (const field of lineItemInAggregatedStore.fields.getFields()) {
        if (field.name === 'store_label' || field.name.startsWith('store_source')) {
          console.info('Skipping field at', lineItemName, ', name:', field.name, ', value:', field.value, ', label:', field.label)
          continue
        }
        const newField = {...field}
        if (newField.value === DIFFERENCES_CONSTANT) {
          newField.value = store.getDataSet().getLineItem(sli.liName).fields.getField(field.name)?.value ?? newField.value
          console.error('@FIXME: This should not happen', sli.id, sli.liName, lineItemName, field.name, newField.value)
        }
        if (newField.label === DIFFERENCES_CONSTANT) {
          newField.label = store.getDataSet().getLineItem(sli.liName).fields.getField(field.name)?.label ?? newField.label
          console.error('@FIXME: This should not happen', sli.id, sli.liName, lineItemName, field.name, newField.label)
        }
        console.info('Adding field at', lineItemName, ', name:', newField.name, ', value:', newField.value, ', label:', newField.label)
        store.getDataSet().addFieldToLineItem(lineItemName, newField)
      }
      for (const field of store.getDataSet().getLineItem(lineItemName).fields.getFields()) {
        if (field.name === 'store_label' || field.name.startsWith('store_source')) {
          console.info('Skipping field at', lineItemName, ', name:', field.name, ', value:', field.value, ', label:', field.label)
          continue
        }
        if (!aggregatedStore.getDataSet().getLineItem(sli.liName).fields.hasField(field.name)) {
          console.info('Removing field at', lineItemName, ', name:', field.name, ', value:', field.value, ', label:', field.label)
          store.getDataSet().removeFieldFromLineItem(lineItemName, field.name)
        }
      }
    }
    if(detailsToUpdate.updateFormula){
      const existingLi = store.getDataSet().getLineItem(lineItemName)
      if(existingLi instanceof TimedCalculatedLineItem){
        const updatedFormulaInAggregatedStore = (lineItemInAggregatedStore as TimedCalculatedLineItem).serialize().fn;
        store.getDataSet().addLineItem(existingLi.withCode(updatedFormulaInAggregatedStore))
      }
    }

    console.info('Saving store:', sli.id, collection)

    await saveSiteStore(collection, store)
  }
}


export function applyUpdateToStoreWithSites(store: LineItemsStore, updates: LineItemBatchUpdates) {
   if(updates?.updateToFields){
     const {siteLineItems, deleted, updatedFields} = updates.updateToFields;
     for (const sli of siteLineItems) {
       for (const field of deleted) {
         store.getDataSet().removeFieldFromLineItem(
             sli.liName,
             field
         );
       }

       for (const field of updatedFields.getFields()) {
         if (field.value === DIFFERENCES_CONSTANT) {
           continue
         }
         store.getDataSet().addFieldToLineItem(
             sli.liName,
             field
         );
       }
     }
   }

    if(updates?.updateToFormula){
      const {siteCalculatedLineItems, formula: updatedFormula} = updates.updateToFormula;
      for (const sli of siteCalculatedLineItems){
        const existingLi = store.getDataSet().getLineItem(sli.liName);
        store.getDataSet().addLineItem((existingLi as TimedCalculatedLineItem).withCode(updatedFormula))
      }
    }
}

export type PartiallyCommonField = { fieldName: string, occurrences: number }

export function calcBatchEditableFields(siteLineItems: SiteLineItem[], store: LineItemsStore): [LineItemsFieldSet | undefined, PartiallyCommonField[], FieldDifferences] {
  const fieldMap: Record<string, Record<string, { site: Site, field: LineItemField }>> = {}
  for (const siteLi of siteLineItems) {
    const li = store.getDataSet().getLineItem(siteLi.liName)
    if (!li.fields) {
      continue
    }
    for (let field of li.fields.getAllKeys()) {
      // Messy ifs because we are using the pquery with aggregated fields
      if (field === 'store_sourceLabel') {
        field = 'store_source'
      } else if (field === 'store_label' || field.startsWith('store_source')) {
        continue
      }
      if (!fieldMap[field]) fieldMap[field] = {}
      fieldMap[field][siteLi.id] = {site: siteLi, field: li.fields.getField(field)!}
    }
  }

  const thereAreCommonFields = Object.values(fieldMap).some((sites) => Object.keys(sites).length === siteLineItems.length)
  if (!thereAreCommonFields) {
    return [undefined, [], {}]
  }

  const differences: FieldDifferences = {}
  const partiallyCommonFields: PartiallyCommonField[] = []
  const fields = new LineItemsFieldSet()

  for (const [fieldName, siteMap] of entries(fieldMap)) {
    if (Object.keys(siteMap).length === siteLineItems.length) {
      const differencesInValues = getDifferencesOnRightSide(mapObjectValues(siteMap, ({site, field}) => ({
        left: siteDescription(site),
        right: field.value.toString()
      })))
      const differencesInLabels = getDifferencesOnRightSide(mapObjectValues(siteMap, ({site, field}) => ({
        left: siteDescription(site),
        right: field.label?.toString() || ''
      })))
      const firstSiteField = Object.values(siteMap)[0]
      const value = Object.keys(differencesInValues).length > 0 ? DIFFERENCES_CONSTANT : firstSiteField.field.value
      const label = Object.keys(differencesInLabels).length > 0 ? DIFFERENCES_CONSTANT : firstSiteField.field.label
      differences[fieldName] = {values: differencesInValues, labels: differencesInLabels}
      fields.addField(fieldName, value, label)
    } else if (Object.keys(siteMap).length > 1) {
      partiallyCommonFields.push({fieldName, occurrences: Object.keys(siteMap).length})
    }
  }

  return [fields, partiallyCommonFields, differences]
}

function getDifferencesOnRightSide(diff: Record<string, PairTableRow>): Record<string, PairTableRow> {
  const values = Object.values(diff)
  return values.some(val => val.right !== values[0].right) ? diff : {}
}

export function calcBatchEditableFormulas(calculatedSiteLineItems: SiteLineItem[], store: LineItemsStore): [string, FormulaDifferences] {
  const formulaMap: Record<string, { site: Site, formula: string }> = {}
  for (const siteLi of calculatedSiteLineItems) {
    const li = store.getDataSet().getLineItem(siteLi.liName)
    formulaMap[siteLi.id] = {site: siteLi, formula: (li as TimedCalculatedLineItem).serialize().fn}
  }

  let differences: FormulaDifferences = {}
  let initialFormula = "";

    const differencesInFormulas = getDifferencesOnRightSide(mapObjectValues(formulaMap, ({site, formula}) => ({
      left: siteDescription(site),
      right: formula
    })))

    const firstSiteFormula = Object.values(formulaMap)?.[0]?.formula ?? "";
    const formulaDepictor = Object.keys(differencesInFormulas).length > 0 ? DIFFERENCES_CONSTANT : firstSiteFormula;
    differences = differencesInFormulas;
    initialFormula = formulaDepictor;

  return [initialFormula, differences]
}