import React, {useEffect} from "react";
import {StoreQuery, QueryResult, LineItemsStore} from "../../../ps-models/lineitems-store";
import {useDateRange} from "../../../lineitems-store/DateRange";
import {Metric} from "../../../statistics/Metric";
import {useSiteStoreContext} from "../../siteStoreLoader";
import {Grid, Header, Segment} from "semantic-ui-react";
import {LineItemsTable, LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {buildStoreWithPxxStatistics} from "./storeFormulas";
import {formatRows, fromNestedList} from "../../../lineitems-store/TableFormat";
import {DemoTimeSeriesChart} from "./DemoTimeSeriesChart";

export function SitePerformance() {
  let store = useSiteStoreContext();
  let [dateRangeComponent, dateRange] = useDateRange(store);

  let result: QueryResult = store.query(StoreQuery.byNames([
    'generation_export_kwh',
    'all_consumption_kwh',
    'generation_import_kwh',
    'pv_generation_kwh',
    'grid_export_kwh',
    'grid_import_kwh',
    'siteunavailable',
    "Battery Unavailable Units",
    "Solar Unavailable Units"
  ]).aggregateOverTimeRange(dateRange.from, dateRange.to));



  return <Segment basic>
    <Header as="h2" color="purple">Site Performance</Header>
    {dateRangeComponent}

    {result &&<div >
        <Segment>
            <Grid solid>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Metric label={ "Total Consumption"}
                                value={ result.firstTimeSlotTextOf('all_consumption_kwh') }
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <Metric label={ "Total PV Generation"}
                                value={ result.firstTimeSlotTextOf('pv_generation_kwh') }
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <Metric label={ "Total Generation Export"}
                                value={ result.firstTimeSlotTextOf('generation_export_kwh') }
                        />
                    </Grid.Column>



                </Grid.Row>

                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Metric label={ "Total Generation Import"}
                                value={ result.firstTimeSlotTextOf('generation_import_kwh') }
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Metric label={ "Total Grid Export"}
                                value={ result.firstTimeSlotTextOf('grid_export_kwh') }
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <Metric label={ "Total Grid Import"}
                                value={ result.firstTimeSlotTextOf('grid_import_kwh') }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
        <Segment>
            <Grid solid>
                <Grid.Row columns={2}>
                    <Grid.Column width={4} divided>
                        <Grid.Row>
                            <Metric label={ "Solar Unavailable Units"}
                                    value={ result.firstTimeSlotTextOf('Solar Unavailable Units') }
                            /><br/><br/>
                        </Grid.Row>
                        <Grid.Row>
                            <Metric label={ "Battery Unavailable Units"}
                                    value={ result.firstTimeSlotTextOf('Battery Unavailable Units') }
                            /><br/><br/>
                        </Grid.Row>
                        <Grid.Row centered>
                            <Metric label={ "Site Unavailable"}
                                    value={ result.firstTimeSlotTextOf('siteunavailable') }
                            />
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column  width={12}>
                        <LineItemsChart />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    </div>

    }

    <PXX />
  </Segment>
}


function PXX() {
  let store = useSiteStoreContext();

  if (!store) return null;


  let {statsResult, yearActualsWithStats} = buildStoreWithPxxStatistics(store);

  let lineItems =  ['p50', 'p90',  'Actual Production',  'Variance from p50', 'Variance from p90']
  let yearActualsWithStatsResult = yearActualsWithStats.query(StoreQuery.byNames(lineItems));

  yearActualsWithStatsResult = formatRows(
      lineItems.map((li) => ({name: li}))
    ,
    yearActualsWithStatsResult
  )

  /**
   * Actual Production per KW
   *
   * P50, P90, Actual Production
   *
   */

  return <div>
    <p></p>
    <Header as="h2" color="purple">P50/P90</Header>

    <LineItemsTableWithFormulas
      queryResult={yearActualsWithStatsResult} store={yearActualsWithStats}

    hideToolbar
    />

    <DemoTimeSeriesChart title={"Actual vs Expected"} result={yearActualsWithStatsResult}
      options={{type: 'bar', includeLineItems: ['Actual Production', 'p50', 'p90']}}
    />

  </div>



}

function LineItemsChart({title}: {title?: string}) {
  let store = useSiteStoreContext();

  if (!store) return null;

  let result = store.query(StoreQuery
    .byNames(['siteunavailable', 'batteryunavailable', 'solarunavailable']));

  return <DemoTimeSeriesChart
    title={title || ""}
    result={result}
  />
}