
import {MonthlyInvoicePreview} from "./MonthlyInvoicePreview";
import {useSiteStoreContext} from "../../siteStoreLoader";
import {LineItemsStore, StoreQuery } from "../../../ps-models/lineitems-store";
import { useDateRange } from "../../../lineitems-store/DateRange";
import {
    buildMonthlyTimeDef,
    buildTimedCalculatedLineItem,
} from "../../../ps-models/line-items";
import {lineItemAggregatorMap, storeValueTypeField, utcDate} from "../../../ps-models";
import {Grid, Header, Segment } from "semantic-ui-react";
import ButtonWithImage from "../../../../components/ButtonWithImage/ButtonWithImage.component";
import {Metric} from "../../../statistics/Metric";
import {LineItemsTable} from "../../../lineitems-store/LineItemsTableView";

const invoiceRelatedItems = [
    'PPA Rate',
    'Discount To PPA Rate',
    'pv_generation_kwh',
    'PPA Revenue',
    'PPA Discount',
    'Net Revenue'
];

const liNameToMetricLabelMapping: Record<string, string> = {
    'PPA Rate': 'PPA RATE ($/kWh)',
    'pv_generation_kwh': 'TOTAL PV GENERATION (kWh)',
    'PPA Revenue':'ORIGINAL AMOUNT TO BE BILLED',
    'PPA Discount': 'DISCOUNT AMOUNT',
    'Net Revenue': 'MONTHLY CHARGES TO BE BILLED'
};

const liNameToMetricPrefixMapping: Record<string, string> = {
    'PPA Rate': '$',
};

const liNameToMetricSuffixMapping: Record<string, string> = {
    'Discount To PPA Rate': '%',
};

const DISABLED_FEATURE_NOTIFIER = 'This feature is not available for demo accounts';

export function MonthlyInvoicing() {
    const store = useSiteStoreContext();

    if (!store) {
        return <div>Loading...</div>
    }
    // Excluding July 2023.
    let materializedStore = store
        .materializeTimed(StoreQuery.all()
            .withTimeIndex(store.timeIndex.withEndDate(new Date(utcDate(2023, 5, 29)))
                .withGranularity('months')
            ));
    return (<>
        {materializedStore && <MonthlyInvoicingWithStore store={materializedStore} storeName={store.getName()} />}
        {!materializedStore && <div>Loading...</div>}
    </>)
}

function MonthlyInvoicingWithStore({store, storeName}: {store: LineItemsStore, storeName: string | undefined}) {
    let [dateRangeComponent, dateRange] = useDateRange(store, "month-old");
    store.getDataSet().addLineItems([
        buildTimedCalculatedLineItem('Invoice #', buildMonthlyTimeDef("first"), `
        let monthNum = dt;
        return 'S' + (115 + monthNum);
        `),
        buildTimedCalculatedLineItem('Amount Billed',
            buildMonthlyTimeDef(),
            //language=JavaScript
            `"Net Revenue"`,
            storeValueTypeField('dollars')
        ),
        buildTimedCalculatedLineItem('Date Invoice Sent', buildMonthlyTimeDef("first"), `
        new Date(new Date().setFullYear(2023, dt+1, 10)).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric'
            })
        `,
            storeValueTypeField('string')
            ),
        buildTimedCalculatedLineItem('Date Invoice Due', buildMonthlyTimeDef("first"), `
        new Date(new Date().setFullYear(2023, dt+1, 24)).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric'
            })
        `,
            storeValueTypeField('string')
            ),
        buildTimedCalculatedLineItem('Date Invoice Paid', buildMonthlyTimeDef("first"), `
            let dateOfMonth = 17;
            if(dt === 1){
                dateOfMonth = 24;
            } else if(dt === 2){
                dateOfMonth = 11;
            } else if(dt === 3){
                dateOfMonth = 19;
            } else if(dt === 4){
                dateOfMonth = 21;
            }

            if(dt > 4){
                return 'Past Due';
            } else {
                return new Date(new Date().setFullYear(2023, dt+1, dateOfMonth)).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric'
                })
            }
        `,
            storeValueTypeField('string')
        ),
    ])
    let materializedStore = store.materializeTimed(StoreQuery.all());

    let invoicingLineItemsForTableView = materializedStore.query(StoreQuery.byNames(['Invoice #', 'Amount Billed', 'Date Invoice Sent', 'Date Invoice Due', 'Date Invoice Paid']));

    let invoiceCoreComponents = materializedStore.query(StoreQuery.byNames([...invoiceRelatedItems]).aggregateOverTimeRange(dateRange.from, dateRange.to,
        lineItemAggregatorMap({
            'PPA Rate': "first",
            "Discount To PPA Rate": "first",
        }, "sum")));

    const invoiceNumberForMonth = materializedStore.timeIndex.endDate.getMonth() < dateRange.from.getMonth() ? 'Loading...': invoicingLineItemsForTableView.columnValue(0, `${dateRange.from.getMonth()}`).toString()

    return <Segment basic>
        {storeName !== 'site-1' ? <>No invoice templates setup by user</> : <><Header as="h2" color="purple">Monthly
            Invoicing</Header>
            {dateRangeComponent}
            <Grid.Row style={{display: 'flex', justifyContent: 'space-between'}}>
                <MonthlyInvoicePreview
                    invoiceData={{
                    number : invoiceNumberForMonth,
                    period: `${dateRange.from.toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                    })} - ${new Date(new Date(dateRange.from).setFullYear(2023, dateRange.from.getMonth()+1, dateRange.from.getDate()-1)).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                    })} ${dateRange.from.getFullYear()}` ,
                    date: new Date(new Date(dateRange.from).setFullYear(2023, dateRange.from.getMonth() + 1, 10)).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                    }),
                    dueAt: new Date(new Date(dateRange.from).setFullYear(2023, dateRange.from.getMonth() + 1, 24)).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                    }),
                    offTakerName: 'XT Solar Inc.',
                    offTakerAddress: '456 Park Avenue, New York, NY 10022',
                    totalPVGeneration: invoiceCoreComponents.firstTimeSlotTextOf('pv_generation_kwh'),
                    PPARate: invoiceCoreComponents.firstTimeSlotTextOf('PPA Rate'),
                    PPARevenue: invoiceCoreComponents.firstTimeSlotTextOf('PPA Revenue'),
                    discountToPPARate: invoiceCoreComponents.firstTimeSlotTextOf('Discount To PPA Rate'),
                    ppaDiscount: invoiceCoreComponents.firstTimeSlotTextOf('PPA Discount'),
                    netRevenue: invoiceCoreComponents.firstTimeSlotTextOf('Net Revenue'),
                }}
                 onDocumentDownload={() => {}} />
                <ButtonWithImage color={'twitter'}
                                 imageAttributes={{src: "/logos/demo/stripe_logo.png", alt: "Stripe Icon"}}
                                 labelPosition={'left'}
                                 content={'Send Invoice via Stripe'}
                                 disabled={true}
                                 disabledButtonPopupContent={DISABLED_FEATURE_NOTIFIER}
                />
                <ButtonWithImage color={'green'}
                                 imageAttributes={{src: "/logos/demo/go_cardless_logo.png", alt: "Go Cardless Icon"}}
                                 labelPosition={'left'}
                                 content={'Send Invoice via GoCardless'}
                                 disabled={true}
                                 disabledButtonPopupContent={DISABLED_FEATURE_NOTIFIER}
                />
            </Grid.Row>
            <Segment>
                <Grid>
                    <Grid.Row>
                        {invoiceRelatedItems.map((lineItemName, colIndex) => (
                            <Grid.Column key={colIndex}
                                         computer={5}
                                         tablet={8}
                                         mobile={16}
                            >
                                <Metric label={liNameToMetricLabelMapping[lineItemName] ?? lineItemName.toUpperCase()}
                                        value={`${liNameToMetricPrefixMapping[lineItemName] ?? ''}${invoiceCoreComponents.firstTimeSlotTextOf(lineItemName)}${liNameToMetricSuffixMapping[lineItemName] ?? ''}`}
                                        displayLabelWithoutUpperCasing={true}
                                />
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                </Grid>
            </Segment>
            <LineItemsTable
                queryResult={invoicingLineItemsForTableView}
                readonly
            />
        </>}
    </Segment>
}