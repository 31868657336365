const ActionTypes = {

  // Add Company
  ADD_COMPANY_STARTED: 'addCompany/started',
  ADD_COMPANY_SUCCESS: 'addCompany/success',
  ADD_COMPANY_FAILED: 'addCompany/failed',

  // Edit Company
  EDIT_COMPANY_STARTED: 'editCompany/started',
  EDIT_COMPANY_SUCCESS: 'editCompany/success',
  EDIT_COMPANY_FAILED: 'editCompany/failed',

  // Delete Company
  DELETE_COMPANY_STARTED: 'deleteCompany/started',
  DELETE_COMPANY_SUCCESS: 'deleteCompany/success',
  DELETE_COMPANY_FAILED: 'deleteCompany/failed',

  // Upload Company File
  UPLOAD_COMPANY_FILE_STARTED: 'uploadCompanyFile/started',
  UPLOAD_COMPANY_FILE_SUCCESS: 'uploadCompanyFile/success',
  UPLOAD_COMPANY_FILE_FAILED: 'uploadCompanyFile/failed',

  // Add Company Model Update
  ADD_COMPANY_MODEL_UPDATE_STARTED: 'addCompanyModelUpdate/started',
  ADD_COMPANY_MODEL_UPDATE_SUCCESS: 'addCompanyModelUpdate/success',
  ADD_COMPANY_MODEL_UPDATE_FAILED: 'addCompanyModelUpdate/failed',

};

export default ActionTypes;