import {LineItemsStore} from "../../ps-models/lineitems-store";
import {useDashboardService} from "./DashboardConfigService";
import {useEffect, useState} from "react";
import {
  buildMonthlyTimeDef, buildParameterLineItem, buildTemplateLineItem,
  buildTimedCalculatedLineItem,
  LineItem,
  LineItemsFieldSet, ParameterLineItem, TemplateLineItem, TimedCalculatedLineItem
} from "../../ps-models/line-items";
import {Button, Icon, Input, Segment, Table} from "semantic-ui-react";
import {LineItemEditor} from "../../lineitems-store/lineItemEditor/LineItemEditor";

export function ExtraLineItems({store, type}: { store: LineItemsStore, type: 'Calculated' | 'Parameter' | 'Template'}) {
  let {getService, ready} = useDashboardService();

  let [newLineItemName, setNewLineItemName] = useState<string>('');

  let [selectedLineItem, setSelectedLineItem] = useState<string>();

  const handleAddLineItem = () => {
    let dashboardService = getService();
    let li: ParameterLineItem | TimedCalculatedLineItem | TemplateLineItem = buildParameterLineItem(newLineItemName, 1, LineItemsFieldSet.fromMap({"store_sourceLineItemName": newLineItemName, "dashboard_extra": true}))
    if(type === 'Calculated'){
      li = buildTimedCalculatedLineItem(
          newLineItemName,
          buildMonthlyTimeDef(),
          "1",
          LineItemsFieldSet.fromMap({"store_sourceLineItemName": newLineItemName, "dashboard_extra": true})
      );
    } else if(type === 'Template'){
      li = buildTemplateLineItem(newLineItemName, buildMonthlyTimeDef(),
          LineItemsFieldSet.fromMap({"store_sourceLineItemName": newLineItemName,
          "store_sourceLabel": newLineItemName,
            "store_label": newLineItemName,
          })
          )
    }

    dashboardService.addExtraLineItem(li);
    setNewLineItemName('');
    setSelectedLineItem(li.name);
    store.clearExecutionCache();
    dashboardService.markStoreAsReady();
    ready();
  }

  let extraLineItems = getService().getConfig().getExtraLineItems({getByTypes:[type]});

  const removeLineItem = (liName: string) => {
    let dashboardService = getService();
    dashboardService.getConfig().removeExtraLineItem(liName);
    store.getDataSet().removeLineItem(liName);
    ready();
  }

  const selectLineItem = (liName: string) => {
    setSelectedLineItem(liName);
  }

  const handleLineItemUpdated = (updatedLineItem: LineItem) => {
    let dashboardService = getService();
    dashboardService.addExtraLineItem(updatedLineItem);
    dashboardService.markStoreAsReady()
    store.clearExecutionCache();
    ready();
  }

  return <>

    <Segment>
      <Input placeholder='Line Item Name' value={newLineItemName}
             onChange={(e, data) => setNewLineItemName(data.value)}/>
      <Button primary onClick={handleAddLineItem}>Add</Button>
    </Segment>

    <Table celled compact size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Line Item</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {extraLineItems.map((li, index) => (
          <Table.Row key={index}>
            <Table.Cell>{li.name}</Table.Cell>
            <Table.Cell>
              <Button icon onClick={() => removeLineItem(li.name)}>
                <Icon name='delete'/>
              </Button>
              <Button icon onClick={() => selectLineItem(li.name)}>
                <Icon name='edit'/>
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>

    {selectedLineItem && <LineItemEditor
        lineItemName={selectedLineItem}
        store={store}
        onLineItemUpdated={handleLineItemUpdated}
    />}
  </>
}