import React, {useState} from "react";
import {UpdateProvider} from "../../UpdateContext";
import {SiteSelectWrapper} from "../SiteSelectWrapper";
import {ClosableSection} from "../../ClosableSection";
import {StoreInfoEditor} from "./StoreInfoEditor";
import {LineItemsEditorTable} from "./LineItemsEditorTable";
import {StoreInfo} from "./StoreInfo";
import {TimeIndexEditor} from "./TimeIndexEditor";
import {Grid, Message, Segment} from "semantic-ui-react";
import {useSiteStoreContext} from "../siteStoreLoader";
import {LineItemAdder} from "./LineItemAdder";
import {StoreActions} from "./StoreActions";
import {pQuery} from "../../ps-models/lineitems-store";
import {MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING, useAssetManagementModule} from "../index";

export function LineItemsEditor() {
  let [selectedSite, setSelectedSite] = useState<string>(window.localStorage.getItem("selectedStore") || "");
  let [message, setMessage] = useState<string>("");
  const module = useAssetManagementModule();
  const storeSetup = MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING[module.Name] || MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"];
  if(!storeSetup){
    console.warn(`The store setup was not found for module ${module.Name}, using the one for DEMO`)
  }

  return   <UpdateProvider>

    <SiteSelectWrapper setSelectedSite={setSelectedSite} selectedSite={selectedSite} query={pQuery().metadataOnly().withStoreType("any")} storeSetup={storeSetup} >
      <Message negative hidden={!message}>{message}</Message>
      <ClosableSection opened={false} level="title-bar" title={"Create data set"}>
        <StoreInfoEditor  setMessage={setMessage} setSelectedSite={setSelectedSite} />
      </ClosableSection>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <StoreInfoAndEdit setMessage={setMessage} setSelectedSite={setSelectedSite} />
          </Grid.Column>
          <Grid.Column width={8}>
            <TimeIndexEditor/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment>
        <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <LineItemAdder setMessage={setMessage}/>
          </Grid.Column>
          <Grid.Column width={4} >
            <StoreActions setMessage={setMessage} storeSetup={storeSetup} />
          </Grid.Column>
        </Grid.Row>
        </Grid>

       </Segment>
        <LineItemsEditorTable />
    </SiteSelectWrapper>

  </UpdateProvider>

}

function StoreInfoAndEdit({setSelectedSite, setMessage}: {setSelectedSite: (s: string) => void, setMessage: (message: string) => void}) {
  const store = useSiteStoreContext();
  return <Segment>
    <StoreInfo/>
    <ClosableSection title={"Edit"} level={"small"} opened={false}>
      <StoreInfoEditor setMessage={setMessage}  setSelectedSite={setSelectedSite} store={store}/>
    </ClosableSection>
  </Segment>
}

