import {PlatformLayout} from "../../PlatformLayout";
import CreateCompanyKey from "./CreateCompanyKey.component";
import {DeleteButton} from "../../ui/crud/DeleteButton.component";
import {format} from "date-fns";
import {PublicKeyHelpComponent} from "./PublicKeyHelp.component";
import { useCompanyKeys, useDeleteCompanyKeys} from "./companyKeys.client";
import { useInstantUpdater } from "../../generic.hooks";
import {AssetsManagementMenu} from "../AssetsManagementMenu";

const {Table} = require("semantic-ui-react");

export default function KeyManagementPage() {
  const [updateId, updateSearch] = useInstantUpdater()

  const companyKeys = useCompanyKeys(updateId)

  const deleteCompanyKey = useDeleteCompanyKeys()

  const handleDelete = (keyId: string) => {
     deleteCompanyKey(keyId).then(updateSearch)
  }

  return <PlatformLayout
    header="Key Management"
    leftFloatedActions={<AssetsManagementMenu />}
    actions={<CreateCompanyKey onCreated={updateSearch} />}
  >
    <div>

      <PublicKeyHelpComponent/>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Key Id
            </Table.HeaderCell>
            <Table.HeaderCell>
              Public Key
            </Table.HeaderCell>
            <Table.HeaderCell>
              Created At
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>

          {companyKeys.map(({keyId, publicKey, createdAt}) =>
            <Table.Row key={keyId}>
              <Table.Cell>
                {keyId}
              </Table.Cell>
              <Table.Cell>
                {publicKey}
              </Table.Cell>
              <Table.Cell>
                {format(new Date(createdAt), "yyyy-MM-dd'T'HH:mm:ss")}
              </Table.Cell>
              <Table.Cell>
                <DeleteButton
                  content={`Are you sure you want to delete the Key '${keyId}'?`}
                  onDelete={() => handleDelete(keyId)}
                />
              </Table.Cell>
            </Table.Row>
          )}

        </Table.Body>
      </Table>
    </div>
  </PlatformLayout>
}