import {LineItemRow} from "../../../ps-models/lineitems-store";
import {ValueType} from "../../../ps-models/line-items";
import {canBeNumber, FinancialValueType, round, VALUE_TYPE_KEY, valueAsNumber} from "../../../ps-models";
import {symbol} from "prop-types";

export function formatSingleCellRow(row: LineItemRow | undefined): string {
  if (row?.["0"]?.value === undefined) {
    return 'N/A'
  }
  return row["0"].text
}

export function formatValueWithValueType(value: ValueType, valueType: FinancialValueType, decimalPlacesInValue?: number): string {

  //Set default value type
  if(!valueType) {
    valueType = canBeNumber(value) ? "number" : "string";
    if(canBeNumber(value)) {
      value = valueAsNumber(value);
    }
  }


  if(valueType === "string") {
    return value?.toString() || "";
  }

  //@ts-ignore
  value = parseFloat( value || 0)

  switch (valueType) {
    case "dollars":
    case "rupee":
    {
      let sign = ""
      if (value < 0) {
        value = -value
        sign = "-"
      }
      const symbol = valueType === "dollars" ? "$" : "₹"

      return `${sign}${symbol}${formatNumber(value, decimalPlacesInValue)}`
    }


    case "percentage":
      return `${formatNumber(value, decimalPlacesInValue)}%`
    case "kwh":
    case "kw":
    case "kwp":
    case "number":
      return formatNumber(value, decimalPlacesInValue).toString()
    default:
      return formatNumber(value, decimalPlacesInValue).toString()
  }
}

function formatNumber(value: any, decimalPlaces?:number) {
  if(decimalPlaces === undefined){
    return addComaToThousands(round(value as number, decimals(value)))
  } else {
    if(decimalPlaces === -1) {
      return addComaToThousands(value as number);
    } else {
      return addComaToThousands(round(value as number, decimalPlaces));
    }
  }
}

function addComaToThousands(num: number) {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

function decimals(value: number): number {
  return Math.abs(value) < 0 ? 3 : 2
}
