import React, {useEffect, useMemo, useRef, useState} from 'react';
import {GoogleMap, Marker, OverlayView,OverlayViewF, useJsApiLoader} from '@react-google-maps/api';
import {QueryResult} from "../ps-models/lineitems-store";
import {normalizeString} from "../ps-models";

interface Location {name: string, billingType: string, id: string, coordinates: {lat: number, lng: number};}

const mapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#d3d3d3' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#a0a0a0' }] // slightly darker than water
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#f0f0f0' }] // lighter stroke to increase legibility
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }, { lightness: -20 }]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#c0c0c0' }]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }]
  },
  // Add more featureType as needed
];

export function MapComponent({latitudeKey, longitudeKey, result, onClickLocation}:{result: QueryResult , latitudeKey?: string, longitudeKey?: string, onClickLocation?: (id: string) => void}){

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB-Hf-PnYcQrLen1SqoyOUv25eP4h7a48Y'
  });

  const mapRef = useRef<google.maps.Map | null>(null);

  const definedLatitudeKey = latitudeKey ?? 'fields.source_Location_latitude';
  const definedLongitudeKey = longitudeKey ?? 'fields.source_Location_longitude';

  const locations = useMemo(() => {
    const calcLocations: Location[] = [];
    if(!result) {
      return calcLocations;
    }

    for (let i = 0; i < result.rows.length; i++) {
      let lat = result.columnValueAsNumber(i, definedLatitudeKey);
      let lng = result.columnValueAsNumber(i, definedLongitudeKey);

      if (!lat || !lng) {
        continue
      }

      //TODO: This fields are too specific, need to add them as parameters
      calcLocations.push({
        name: result.columnValueAsString(i, 'Name'),
        id: result.columnValueAsString(i, 'fields.sourceId'),
        billingType: result.columnValueAsString(i, 'fields.source_Billing_Type__c'),
        coordinates: {
          lat,
          lng
        }
      })
    }
    return calcLocations
  }, [result, definedLatitudeKey, definedLongitudeKey]);

  let avgCenter = locations.reduce((acc, location) => {
    return {
      lat: acc.lat + location!.coordinates.lat,
      lng: acc.lng + location!.coordinates.lng
    }
  }, {lat: 0, lng: 0});

  avgCenter = {
    lat: avgCenter.lat / locations.length,
    lng: avgCenter.lng / locations.length
  }

  useEffect(() => {
    if (mapRef.current && locations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach((location) => {
        bounds.extend(location.coordinates);
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [locations])

  return <div className="MetadataMapComponent">
    {isLoaded && (<GoogleMap
        mapContainerStyle={{ height: "400px", width: "100%" }}
        zoom={6}
        center={avgCenter} // This is an initial center point. Adjust it as needed.
        options={{ styles: mapStyles, mapTypeControl: false, streetViewControl: false, fullscreenControl: false }}
      >
        {locations.map((location, index) => <LocationComponent key={index} location={location} onClickLocation={onClickLocation} />)}
      </GoogleMap>)}
  </div>
}

const getPixelPositionOffset = (width: number, height: number) => ({
  x: -(width / 2),
  y: -(height / 2) - 40,
})

function LocationComponent({location, onClickLocation}: {location: Location, onClickLocation?: (id: string) => void}) {
    const [isOpen, setIsOpen] = useState(false);
    const handleSelectStore = () => {
      onClickLocation?.(location.id);
    }
    return <Marker
        position={location.coordinates}
        onMouseOver={() => setIsOpen(true)}
        onMouseOut={() => setIsOpen(false)}
        onClick={handleSelectStore}
        icon={{
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 7.5, // Change this value to change the size of your circle
          fillColor: colorByLocation(location),
          fillOpacity: 1,
          strokeWeight: 1,
        }}
    >
          {isOpen && <>
              <OverlayViewF
                position={location.coordinates}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={getPixelPositionOffset}
              >
                <div style={{ background: 'white', padding: '10px', border: '1px solid grey'}}>
                  <strong>{location.name}</strong>
                  {location.billingType && <div style={{ marginTop: '5px' }}>Billing Type: <strong>{location.billingType}</strong></div>}
                </div>

              </OverlayViewF>
          </>}
    </Marker>
}

function colorByLocation(location: Location): string {
  return normalizeString(location.billingType).indexOf(normalizeString('Direct Daily Fee')) !== -1 ? '#6a6'
      : normalizeString(location.billingType).indexOf(normalizeString('NRN Retailer')) !== -1 ? '#66a'
      : '#aa6'
}

export default MapComponent;
