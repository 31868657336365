import {
    FormCheckbox,
    FormDropdown,
    FormField,
    FormInput,
    FormTextArea,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {FieldDef, FormDef} from "../types";
import {mapObjIndexed} from "ramda";
import HtmlEditor from "../../HtmlEditor";
import {useGranularUTCDateSelector} from "../../../lineitems-store/DateRange";
import {buildTimeIndex, utcDate} from "../../../ps-models";
import {LineItemsStore} from "../../../ps-models/lineitems-store";
import React, {useEffect} from "react";
import {DateRangeType} from "../../../ps-types";

export function Field({fieldDef, entityName, onChange, value}:{
    fieldDef: FieldDef,
    value: any,
    entityName: string,
    onChange: (value:any) => void
}) {

    if(fieldDef.type === "hidden") {
        return <></>
    }

    if(fieldDef.type === "date") {
        return <FormField key={fieldDef.key}>
            <DatePicker
                selected={value}
                onChange={(newValue) => onChange(newValue)}
                readOnly={fieldDef.readOnly}
            />
        </FormField>
    }
    if(fieldDef.type === "utc-date-range"){
        return <FormField key={fieldDef.key}>
            <GranularUTCDateSelectorField value={value} rangeSelection={true} onChange={(newValue)=> onChange(newValue)}/>
        </FormField>
    }
    if(fieldDef.type === "utc-date"){
        return <FormField key={fieldDef.key}>
            <GranularUTCDateSelectorField value={value} onChange={(newValue)=> onChange(newValue)}/>
        </FormField>
    }
    if(fieldDef.type === "textarea") {
        return <FormTextArea key={fieldDef.key}
                             label={fieldDef.label}
                onChange={(e, {value}) => onChange(value)}
                fluid
                style={{width: '100%'}}
                value={value}
                // primary
                name={entityName}
                defaultValue={fieldDef.defaultValue}
                readOnly={fieldDef.readOnly}
                placeholder={fieldDef.placeHolder || `Enter ${entityName} ${fieldDef.label} here`}
            />
    }

    if(fieldDef.type === "email" || fieldDef.type === "password") {
        return <FormInput
                key={fieldDef.key}
                label={fieldDef.label}
                type={fieldDef.type}
                onChange={(e, {value}) => onChange(value)}
                fluid
                value={value}
                primary
                name={entityName}
                defaultValue={fieldDef.defaultValue}
                readOnly={fieldDef.readOnly}
                placeholder={fieldDef.placeHolder || `Enter ${entityName} ${fieldDef.label} here`} />
    }

    if(fieldDef.type === "multiple-files" || fieldDef.type === "file") {
        return <FormInput
                key={fieldDef.key}
                type={"file"}
                label={fieldDef.label}
                onChange={(e, {value}) => onChange(e.target.files)}
                fluid
                value={value}
                primary
                name={entityName}
                defaultValue={fieldDef.defaultValue ?? null}
                {...(fieldDef.type === "multiple-files" ? {multiple:true} : {})}
                readOnly={fieldDef.readOnly}
                placeholder={fieldDef.placeHolder || `Enter ${entityName} ${fieldDef.label} here`}
            />
    }

    if(fieldDef.type === "html") {
        return <FormField key={fieldDef.key}>
                <label>{fieldDef.label}</label>
                <HtmlEditor
                    defaultValue={fieldDef.defaultValue}
                    onChange={(value: string)=>onChange(value)}
                    placeholder={fieldDef.placeHolder || `Enter ${entityName} ${fieldDef.label} here`}
                 />
        </FormField>
    }
    if(fieldDef.type === 'select' || fieldDef.type === 'multi-select') {
        return <FormDropdown
            label={fieldDef.label}
            selection
            fluid
            options={fieldDef.options}
            key={fieldDef.key}
            onChange={(e, {value})=>onChange(value)}
            value={value}
            name={entityName}
            readOnly={fieldDef.readOnly}
            multiple={fieldDef.type === 'multi-select'}
            placeholder={fieldDef.placeHolder || `Enter ${entityName} ${fieldDef.label} here`}
            />
    }

    if(fieldDef.type === 'toggle'){
        return <FormCheckbox
            label={fieldDef.label}
            toggle
            fluid
            key={fieldDef.key}
            onChange={(e, {checked})=>onChange(checked)}
            checked={value}
            name={entityName}
            readOnly={fieldDef.readOnly}
            />
    }
    return <FormInput
            label={fieldDef.label}
            onChange={(e, {value}) => onChange(value)}
            fluid
            primary
            value={value}
            name={entityName}
            defaultValue={fieldDef.defaultValue}
            readOnly={fieldDef.readOnly}
            placeholder={fieldDef.placeHolder || `Enter ${entityName} ${fieldDef.label} here`} />
}

export function buildForm(
    def: Omit<FormDef, "fields">,
    fields: Record<string, Partial<FieldDef>>): FormDef {
    // @ts-ignore
    let fieldWithValues: { [key: string]: FieldDef } = mapObjIndexed((v, k) => ({
            key: v.key || k,
            type: v.type || "text",
            label: v.label || v.key || k,
            placeHolder: v.placeHolder,
            defaultValue: v.defaultValue ?? null,
            readOnly: v?.readOnly ?? undefined,
            ...(((v.type === 'select' || v.type === 'multi-select') && v.options) ? {options: v.options}:{}),
        })
        , fields);

    return {
        ...def,
        fields: fieldWithValues
    }
}

function GranularUTCDateSelectorField({rangeSelection= false, value, onChange}: {rangeSelection?: boolean,value: DateRangeType, onChange: (dateRange:DateRangeType)=>void}){
    const dummyStore = new LineItemsStore(buildTimeIndex(utcDate(2010, 12, 31, 0, 0, 0, 0), utcDate(2100, 12, 31, 0, 0, 0, 0), "months"))
    let [selectorComponent, dateRange] = useGranularUTCDateSelector(dummyStore, rangeSelection);
    useEffect(()=> {
        if(value?.to === dateRange.to && value.from === dateRange.from){
            return;
        }
        onChange(dateRange);
    }, [onChange , dateRange])
    return <>{selectorComponent}</>
}