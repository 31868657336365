import {Button, Input} from "semantic-ui-react";
import React from "react";
import {BuilderContext, registerWidgetType} from "../WidgetRegistry";

interface ConfigProps {
  text: string,
  style?: string
}

registerWidgetType({
    typeId: 'Text',
    metadata: {
      name: 'Simple Text Widget',
      description: '',
      icon: 'font',
    },
    defaultConfig: {
      text: 'Hello world'
    },
  renderConfig: (config: any, context: BuilderContext, setConfig: (config: any) => void) => {
    return <WidgetConfig
      config={config} context={context} onConfigChange={setConfig}/>
  },
    render: (config: any, context: BuilderContext) => {
      return  <Widget config={config.config} context={context}  />
    }
  }
)

function WidgetConfig({config, context, onConfigChange}:
                                      { config: ConfigProps, context: BuilderContext, onConfigChange: (config: any) => void }) {

  let [localConfig, setLocalConfig] = React.useState(config);


  const handleApply = () => {
    onConfigChange(localConfig);
  }

  return <>

    <div>
      <strong>Text:</strong>
    <Input
      style={{width: '100%'}}
      value={localConfig.text} onChange={(e, data) => {
      setLocalConfig({...localConfig, text: data.value})
    }} />
    </div>
    <div>
    <strong>Style:</strong>
      <Input
        value={localConfig.style}
        onChange={(e, data) => {
          setLocalConfig({...localConfig, style: data.value})
        }}
        />
    </div>
    <Button onClick={handleApply}>Apply</Button>
  </>

}

function Widget({config, context}: { config: ConfigProps, context: BuilderContext }) {

  return <>
    <div
      className={`ps-widget-${config.style}`}
      dangerouslySetInnerHTML={{__html: config.text}}></div>
  </>
}