export const INTAKE_OPTIONS = {
  "companyStageOptions": [
    "Technology Development",
    "Field Prototype",
    "Commercial Pilot",
    "Post Pilot",
    "Growth",
    "Other"
  ],
  "companySectorOptions": [
    "Carbon Mitigation",
    "DER",
    "Smart City",
    "Robotics",
    "IoT",
    "Mobility",
    "Other"
  ],
  "technologyTypeOptions": [
    "Other",
    "Energy Storage",
    "HVAC",
    "Manufacturing",
    "Construction",
    "Hydrogen",
    "Advanced Weathering",
    "Agricultural Commodity",
    "Agricultural Technology",
    "Robotics",
    "Solar",
    "Waste to Value",
    "Transportation",
    "Computer Vision",
    "EV Charging",
    "Telecom",
    "Logistics",
    "Building Efficiency",
    "Real Estate",
    "Direct Air Capture",
    "Other Carbon Mitigation"
  ],
  "geographyOptions": [
    "US",
    "China",
    "Australia",
    "Other Asia & Oceania",
    "Europe",
    "Canada",
    "Latin America",
    "Africa",
    "Middle East",
    "India",
    "Russia"
  ],
  "reasonForInterestOptions": [
    "Interested in Hardware-as-a-Service",
    "Interested in dual-structure organizations",
    "Want to grow sales",
    "Want to scale globally",
    "Interested in the SPV process",
    "Interested in non-dilutive financing",
    "Want to increase profit margins",
    "Want to raise debt",
    "Want to generate more leverage",
    "Evaluating for a portfolio company",
    "Other"
  ],
  "revenueModel": [
    "Hardware Sales",
    "Hardware Lease",
    "Pay-per-use",
    "Technology Licensing",
    "Revenue Share",
    "Other"
  ],
  "customerType": [
    "Governments",
    "Individuals/Consumers",
    "Large Corporates",
    "Small-Medium Enterprises",
    "Utilities",
    "Other"
  ],
  "contractDevelopment": [
    "Customer Revenue Contract",
    "No Contracts Developed",
    "One-Time Sales Contract",
    "Service Contract",
    "Supply Contract",
    "Other"
  ]
}
