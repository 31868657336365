import React from "react";
import { Header, Segment} from "semantic-ui-react";
import {MonthlyRawLiInputs} from "../../../lineitems-store/ParamForm/MonthlyRawLiInputs";
import {
    CashPerformanceSummary
} from "./LoanManagementViews/CashPerformanceSummary";
import {
    addLoanMonitoringCalculatedLineItems,
    LoanMonitoringReport,
    loanMonitoringReportTableItems
} from "./LoanManagementViews/LoanMonitoringReport";
import {ParamLiInputs} from "../../../lineitems-store/ParamForm/ParamLiInputs";
import {usePersistenceStoreQueryWithContextUpdate} from "../../../lineitems-store/LineItemsStore.hook";
import {pQuery} from "../../../ps-models/lineitems-store";
import {addSageIntacctDemoData, setLabelAndFormatForAggregates} from "./storeFormulas";
import {useUpdateContext} from "../../../UpdateContext";
import {ClosableSection} from "../../../ClosableSection";
import {authStorage} from "../../../auth";
import {getAmProjectConfig} from "../../../ps-models";

export function LoanManagement() {
    useUpdateContext();
    const company = authStorage.getCompany();
    let { collection } = getAmProjectConfig(company);
    let aggregatedStore = usePersistenceStoreQueryWithContextUpdate(collection,
        pQuery()
            .withLineItems([...loanMonitoringReportTableItems, 'Original_Asset_Value__c', 'Net Cash Flow', 'Net Opex', 'Annual Interest Rate', 'Advance Ratio'])
            .havingImports(['common'])
            .withGranularity('months'),
        (store) => {
            store.getDataSet().addTimedGroupingLineItemsByField("store_sourceLineItemName");
            setLabelAndFormatForAggregates(store)
            addSageIntacctDemoData(store);
        });

    if(!aggregatedStore) {
        return <div>Loading...</div>;
    }
    addLoanMonitoringCalculatedLineItems(aggregatedStore);

  return <Segment basic>
      <Header as="h2" color="purple">Loan Management</Header>
      <ClosableSection
          opened={true}
          title={<><strong style={{fontSize: "16px"}}>
              Cash Performance Summary
          </strong>
          </>}
       level="title-bar"
        customStyles={{margin: '1em 0'}}
      ><Segment><CashPerformanceSummary store={aggregatedStore} /></Segment> </ClosableSection>
      <ClosableSection
          title={<><strong style={{fontSize: "16px"}}>
              Loan Definition Parameters
          </strong>
          </>}
          level="title-bar"
          customStyles={{margin: '1em 0'}}
      > <Segment>
          <div style={{display: 'flex', columnGap: 30, rowGap: 10, flexWrap: 'wrap', padding: '0 1rem'}}>
              <ParamLiInputs lineItemName={'Facility Size'} unit={'dollars'} store={aggregatedStore}/>
              <ParamLiInputs lineItemName={'Annual Interest Rate'} unit={'percentage'} store={aggregatedStore}/>
              <ParamLiInputs lineItemName={'Advance Ratio'} unit={'percentage'} store={aggregatedStore}/>
              <ParamLiInputs lineItemName={'Minimum Cash Balance'} unit={'dollars'} store={aggregatedStore}/>
              <ParamLiInputs lineItemName={'Minimum DSCR'} unit={'number'} store={aggregatedStore}/>
          </div>
          <h5 style={{marginBottom: 0}}>Amortization Schedule</h5>
          <MonthlyRawLiInputs lineItemNames={['Principal Repayment']}
                              store={aggregatedStore}/>
      </Segment>
      </ClosableSection>
      <ClosableSection
          title={<><strong style={{fontSize: "16px"}}>
              Loan Monitoring Report
          </strong>
          </>}
          level="title-bar"
          customStyles={{margin: '1em 0'}}
      >
          <LoanMonitoringReport store={aggregatedStore} />
      </ClosableSection>
  </Segment>
}