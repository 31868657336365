import {useUpdateContext} from "../../UpdateContext";
import {useSiteStoreContext} from "../siteStoreLoader";
import React, {useEffect, useState} from "react";
import {buildTimeIndex, TimeIndex, TimeUnits} from "../../ps-models";
import {Button, Segment, Select} from "semantic-ui-react";
import {DateRange} from "../../lineitems-store/DateRange";

export function TimeIndexEditor() {
  const ctx = useUpdateContext();
  const store = useSiteStoreContext();

  let timeIndex = store.timeIndex;
  let [builtTimeIndex, setBuiltTimeIndex] = useState<TimeIndex>();

  const handleApply = () => {
    if(builtTimeIndex){
      store.timeIndex = builtTimeIndex;
      store.clearExecutionCache()
      ctx?.ready();
    }
  }

  return <Segment><TimeIndexEditorStandalone initialGranularity={timeIndex.getUnit()} initiallySelectedDates={{startDate: timeIndex.startDate, endDate: timeIndex.endDate}}
  onUpdate={(updatedTimeIndex)=>{
    setBuiltTimeIndex(updatedTimeIndex);
  }}
  />
    <Button onClick={handleApply}>
      Apply
    </Button>
  </Segment>
}


export type TimeIndexEditorStandaloneProps = {initiallySelectedDates: { startDate: Date, endDate: Date }, initialGranularity: TimeUnits, onUpdate: (timeIndex: TimeIndex)=>void, layout?: "horizontal" | "vertical"}
export function TimeIndexEditorStandalone({initiallySelectedDates,
                                            initialGranularity, onUpdate, layout = "horizontal"}:
                                            TimeIndexEditorStandaloneProps) {

  let [dateRange, setDateRange] = useState<{ startDate: Date, endDate: Date }>(
      initiallySelectedDates
  );
  let [granularity, setGranularity] = useState<TimeUnits>(initialGranularity);

  useEffect(()=>{
    onUpdate(buildTimeIndex(dateRange.startDate, dateRange.endDate, granularity))
  }, [dateRange, granularity])

  return <>
    <h5>Time Index</h5>
    <DateRange initialStartDate={dateRange.startDate}
               initialEndDate={dateRange.endDate}
               onSelect={(startDate, endDate) => setDateRange({startDate, endDate})}
               layout={layout}
    />
    <Select style={{...(layout === 'vertical' ? {display: 'flex'}: {})}} options={[
      {text: "Hour", value: "hours"},
      {text: "Day", value: "days"},
      {text: "Week", value: "weeks"},
      {text: "Month", value: "months"},
      {text: "Year", value: "years"},
    ]} value={granularity}
            onChange={(e, {value}) =>
              setGranularity(value as TimeUnits)}
    />
  </>

}