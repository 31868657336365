import React from 'react';
import { createMedia } from "@artsy/fresnel";
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Button,
    Container,
    Divider,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
    Dropdown
} from 'semantic-ui-react';

import BrandColors from '../../utils/brandColors';
import './AppContainer.styles.css';
import {AuthCtxWrapper, authStorage} from "../../platform/auth";
import {SITE_URL} from "../../platform/core";
import {useAuthCtx} from "../../platform/auth/AuthContextProvider";
import { AUTHENTICATION_PATHS } from '../../constants';

const AppMedia = createMedia({
    breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
    }
});

const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

const MobileDropdown = ({handleToggle}) => {
    const currentUser = authStorage.getMaybeUser();
    return (
        <>
            <Menu.Item>
                {currentUser ?
                    <MobileUserDropdownActions />
                    : (
                        <Menu.Item>
                            <Button as={Link} to="/login">
                                Log In
                            </Button>
                            <Button as={Link} color={BrandColors.SEMANTIC_COLOR_PRIMARY} style={{marginLeft: '0.5em'}}
                                    to="/signup">
                                Sign Up
                            </Button>
                        </Menu.Item>
                    )
                }
            </Menu.Item>
            <Menu.Item onClick={handleToggle}>
                <Icon name='sidebar'/>
            </Menu.Item>
        </>
    );
}

// Create sidebar options
const MobileUserDropdownActions = () => {
    return (
        <AuthCtxWrapper>
        <MobileUserDropdownActionsImpl />
        </AuthCtxWrapper>
)};

const MobileUserDropdownActionsImpl = ()=>{
    const {logout} = useAuthCtx();
    const currentUser = authStorage.getMaybeUser();
    return <Dropdown item text={currentUser.getProfile().displayName || currentUser.email}>
        <Dropdown.Menu>
            <Dropdown.Header>Currently Logged In</Dropdown.Header>
            <Dropdown.Item as={Link} to={AUTHENTICATION_PATHS.root}><Icon name="dashboard"/> Dashboard</Dropdown.Item>
            <Dropdown.Item onClick={() => logout()}><Icon name="log out"/> Sign Out</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
}

const HomepageSidebarOptions = () => {
    const currentUser = authStorage.getMaybeUser();
    return (
        currentUser ?
            <React.Fragment>
                <MobileUserDropdownActions/>
            </React.Fragment>
            :
            <React.Fragment>
                <Menu.Item>
                    <Button
                        as={Link}
                        to="/signup"
                        fluid
                        color={BrandColors.SEMANTIC_COLOR_PRIMARY}
                    >
                        Sign Up
                    </Button>
                </Menu.Item>
                <Menu.Item>
                    <Button
                        as={Link} to="/login"
                        fluid
                    >
                        Log In
                    </Button>
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to="/"
                >
                    Home
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to="/how-it-works"
                >
                    How it Works
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to="/haas"
                >
                    Hardware-as-a-Service
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to="/faqs"
                >
                    FAQs
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to="/about"
                >
                    About Us
                </Menu.Item>
            </React.Fragment>
    );
}

class MobileContainer extends React.Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
        <>
            <style>{mediaStyles}</style>
            <MediaContextProvider>
                <Sidebar.Pushable as={Media}
                                 className="mobileWrapper" greaterThanOrEqual={"mobile"}>
                    <Sidebar
                        as={Menu}
                        animation='push'
                        inverted
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={sidebarOpened}
                        className="overflowingSidebar"
                    >

                        <Divider hidden />

                        <Image src={"/logos/Perl-Logo-Wide-White-2x.png"} className="navLogo" centered />

                        <Divider hidden />

                        <HomepageSidebarOptions />

                    </Sidebar>

                    <Sidebar.Pusher dimmed={sidebarOpened} className="overflowingSidebar">
                        <Segment
                            // inverted
                            textAlign='center'
                            style={{ padding: '0em 0em 0em 0em', border: 'none' }}
                            vertical
                        >
                            <div className="coverTopNav">
                                <Container>
                                    <Menu pointing size='large' className="boxMenu invisibleMenu">

                                        <Menu.Item style={{ padding: 0, border: 'none' }}>
                                            <a href={SITE_URL}>
                                                <Image src={"/logos/Perl-Logo-Wide-Black-2x.png"} className="navLogo" />
                                            </a>
                                        </Menu.Item>

                                        <Menu.Menu as={Media} position='right' minWidth={768} greaterThanOrEqual={"tablet"}>
                                            <Menu.Item
                                                as={Link}
                                                to="/how-it-works"
                                            >
                                                <strong>How it Works</strong>
                                            </Menu.Item>

                                            <Menu.Item
                                                as={Link}
                                                to="/haas"
                                            >
                                                <strong>Hardware-as-a-Service</strong>
                                            </Menu.Item>

                                            <Menu.Item
                                                as={Link}
                                                to="/faqs"
                                            >
                                                <strong>FAQs</strong>
                                            </Menu.Item>

                                            <Menu.Item
                                                as={Link}
                                                to="/about"
                                            >
                                                <strong>About Us</strong>
                                            </Menu.Item>

                                            <Menu.Item
                                                as={Link}
                                                to={{ pathname: "https://www.blog.perlstreet.com", target: '_blank' }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    window.location = "https://www.blog.perlstreet.com"
                                                }}
                                            >
                                                <strong>News</strong>
                                            </Menu.Item>

                                            <Menu.Item />
                                            <MobileDropdown handleToggle={this.handleToggle} />

                                        </Menu.Menu>
                                        <Menu.Menu as={Media} position='right' at={"mobile"} maxWidth={767}>
                                            <MobileDropdown handleToggle={this.handleToggle} />
                                        </Menu.Menu>

                                    </Menu>
                                </Container>
                            </div>
                        </Segment>
                        {children}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </MediaContextProvider>
        </>
    )
  }
}

const ResponsiveContainer = ({ children, ...props }) => (
  <div>
    <MobileContainer {...props}>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const AppContainer = ({children, ...props}) => (
  <ResponsiveContainer {...props}>
    {children}
  </ResponsiveContainer>
);

export default AppContainer;