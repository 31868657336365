import {AmProject, WhatIfScenarioRecord} from "../../ps-types";
import {useCompanyId} from "../../core";
import React from "react";
import {getWhatIfScenario} from "./whatIfScenarios.client";
import {JobProgress, JobProgressData} from "../JobProgress";


export function WhatIfScenarioLogs({scenarioId, projects, scenario, updateScenarioStatus}: {
  scenarioId: string,
  projects: AmProject[],
  scenario: WhatIfScenarioRecord,
  updateScenarioStatus: (status: WhatIfScenarioRecord['status']) => void
}) {

  const companyId = useCompanyId();

  const reloadScenario = async () => {
    let scenario1 = await getWhatIfScenario(companyId, scenarioId);
    updateScenarioStatus(scenario1.status);
    return {
      logEntries: scenario1.log || [],
      total: scenario1.inputData.projectVersions.length
    } as JobProgressData
  }

  return  <JobProgress projects={projects}
                       initialJobProgress={{logEntries: scenario.log || [], total: scenario.inputData.projectVersions.length}}
                       refreshLogs={reloadScenario}
  />

}

