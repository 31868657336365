import psPlatformClient from "../../../psPlatformClient";
import {AxiosError} from "axios";
import {SerializedParameterLineItem} from "../../../ps-models/line-items";


//TODO: Move to salesforce integration clients
interface SalesforceObjectDefinitions {
  name: string
  label: string
  isCustom: boolean
}

export const getSalesforceObjects = async (): Promise<SalesforceObjectDefinitions[]> => {
  const response = await psPlatformClient<null, SalesforceObjectDefinitions[]>({method: 'get', url: 'integrations/salesforce/objects'});
  return response.data;
}

export const syncLineItems = async (companyId: string, rootObjectId: string, collection: string): Promise<{warning: string[]; info: string[]}> => {
  const response = await psPlatformClient<{rootObjectId: string, companyId: string, collection: string}, {warning: string[]; info: string[]}>({method: 'post', url: 'integrations/sync-line-items',
    data: {rootObjectId, companyId, collection}});
  return response.data
}

export const syncObjectsOfType = async (companyId: string, objectType: string, collection: string): Promise<SalesforceObjectDefinitions[]> => {
  const response = await psPlatformClient<{objectType: string, companyId: string, collection: string}, SalesforceObjectDefinitions[]>({
    method: 'post', url: 'integrations/salesforce/sync', data: {"objectType": objectType, companyId, collection}}
  );
  return response.data;
}

export const syncSiteDER = async (companyId:string, siteId: string, startDate: Date, endDate: Date, collection: string) => {
  const response = await psPlatformClient({method: 'post', url: '/integrations/der/sync',
    data: {siteId, companyId, startDate: startDate.getTime(), endDate: endDate.getTime(), collection  }});
  return response.data;
}

export const extractLineItemsFromExcel = async (file: File, itemsToLocate: {name: string, type: 'parameter'}[]): Promise<{extractedSiteWiseLineItems: {[siteId: string]: SerializedParameterLineItem[]}[], errors: string[]}> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('itemsToLocate', JSON.stringify(itemsToLocate));
  try {
    const result = await psPlatformClient<FormData, {extractedSiteWiseLineItems: {[siteId: string]: SerializedParameterLineItem[]}[], errors: string[]}>(
        {method: 'post', url: `/am/extract-line-items`, data: formData})
    return result.data;
  } catch(err) {
      if(err instanceof AxiosError && err.response && err.response.status >=400){
        if(err.response.data && typeof err.response.data === 'object'){
          const errResponseDescription = err.response.data?.description;
          if(errResponseDescription?.reason){
            throw new Error(errResponseDescription?.reason);
          }
        }
      }
      throw err;
  }
}