import React, {createContext, useState, useContext, ReactNode} from 'react';

interface PopupContextProps {
    openPopup: (popupComponent: JSX.Element) => void
    closePopup: () => void
}

const PopupContext = createContext<PopupContextProps | undefined>(undefined);

export const usePopup = () => {
    const context = useContext(PopupContext);
    if (!context) {
        throw new Error('usePopup must be used within a PopupProvider');
    }
    return context;
};

export function PopupProvider({children}: { children: ReactNode }): JSX.Element {
    const [currentPopup, setCurrentPopup] = useState<React.ReactNode>(null);

    function openPopup(popupComponent: JSX.Element) {
        setCurrentPopup(popupComponent);
    }

    function closePopup() {
        setCurrentPopup(null);
    }

    return (
        <PopupContext.Provider value={{openPopup, closePopup}}>
            {children}
            {currentPopup}
        </PopupContext.Provider>
    )
}

