import React, {useEffect, useState} from "react";

import {LineItemsStore} from "../../ps-models/lineitems-store";
import {
  isTemplateLineItem,
  isTimedLineItem,
  LineItem,
  LineItemsFieldSet, ParameterLineItem,
  TimedCalculatedLineItem, TimeDefinition,
  TimedLineItem, ValueType
} from "../../ps-models/line-items";
import {Button, Icon, Input, Message, Segment} from "semantic-ui-react";
import {useUpdateContext} from "../../UpdateContext";
import {ClosableSection} from "../../ClosableSection";
import {FieldsEditor} from "./FieldsEditor";
import {LineItemCodeEditor, getCode} from "./LineItemCodeEditor";
import {TimedLineItemEditor} from "./TimedLineItemEditor";
import {convertToNumberStringOrBoolean} from "../../ps-models";

export function LineItemEditorClosable({store, lineItemName}:{store: LineItemsStore, lineItemName: string}) {
  return <ClosableSection level={"small"} opened={false} title={"Line Item Config"}><div style={{padding: "5px", border: "1px solid #ccc"}}>
    <LineItemEditor store={store} lineItemName={lineItemName}/>
  </div></ClosableSection>
}

export function LineItemEditor({store, lineItemName, onLineItemUpdated}:{
  store: LineItemsStore, lineItemName: string,
  onLineItemUpdated?: (lineItem: LineItem) => void
}) {
  let lineItem = store.getDataSet().getLineItem(lineItemName);
  let isCalculated = lineItem instanceof TimedCalculatedLineItem;
  let isTimedLi = isTimedLineItem(lineItem)
  let isTemplateLi = isTemplateLineItem(lineItem)
  let isParameterLi = lineItem instanceof ParameterLineItem;

  let [code, setCode] = useState<string>("");
  let [timeDefinition, setTimeDefinition] = useState<TimeDefinition>();

  let [fields, setFields] = useState<LineItemsFieldSet>();

  let [parameterValue, setParameterValue] = useState<ValueType>();

  let ctx = useUpdateContext();

  let [liName, setLiName] = useState(lineItemName);

  useEffect(() => {

    if(isCalculated) {
      setCode(getCode(lineItem));
    }

    if(isParameterLi) {
      setParameterValue((lineItem as ParameterLineItem).getValue().value);
    }

    if(isTimedLi || isTemplateLi){
      setTimeDefinition((lineItem as (TimedLineItem & LineItem))?.getTimeDefinition());
    }
    setFields(lineItem?.fields || new LineItemsFieldSet());
  }, [lineItemName]);


  if(!lineItem) {
    return <Message error>No Line Item found for {lineItemName}</Message>
  }

  const handleFieldUpdate = (fields: LineItemsFieldSet, deleted: string[]) => {
    setFields(fields);
  }

  const handleRemove = () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm("Are you sure you want to remove this line item?")) {
      store.getDataSet().removeLineItem(lineItem.name);
      ctx.ready();
    }

  }


  const copyLineItem = (name: string) => {
    let copy  = lineItem.serialize()

    let newLineItem = LineItemsStore.deserializeLineItem(copy)!;

    newLineItem.name = name;

    store.getDataSet().addLineItem(newLineItem);
  }

  const handleCopy = (name?: string) => {
    if(!lineItem) return;

    lineItem.clearErrors();

    copyLineItem(name || `${lineItem.name} (copy)`)
    store.lastExecution = undefined
    ctx.ready();
  }

  const handleRename = () => {
    if(!lineItem) return;

    if(!liName || !liName.trim()) return alert("Please enter a name");

    if(store.getDataSet().getLineItem(liName)) {
      // eslint-disable-next-line no-restricted-globals
      if(!confirm("A line item with this name already exists. Do you want to overwrite it?")) {
        return;
      }
    }

    copyLineItem(liName);
    store.getDataSet().removeLineItem(lineItem.name);
    store.lastExecution = undefined
    ctx.ready();
  }

  const handleApply = () => {
    if(!lineItem) return;

    let updatedLineItem = lineItem.clone() as (LineItem);
    updatedLineItem.clearErrors();


    if(isCalculated) {
      updatedLineItem = (updatedLineItem as TimedCalculatedLineItem).withCode(code);
    }

    if(timeDefinition) {
      updatedLineItem = (updatedLineItem as TimedCalculatedLineItem).withDefinition(timeDefinition)
    }

    if(isParameterLi) {
      (updatedLineItem as ParameterLineItem).setValue(
        convertToNumberStringOrBoolean(parameterValue)
      )
    }

    if(fields) {
      updatedLineItem.setFields(fields)
    }

    if(onLineItemUpdated) {
      onLineItemUpdated(updatedLineItem);
    } else {
      store.getDataSet().addLineItem(updatedLineItem);
      store.clearExecutionCache();
      ctx.ready();
    }

  }

  return <div style={{padding: "20px", width: "800px"}}>

    <Segment>
      <div> <strong>Name</strong> {lineItem.name} </div>
      <div> <strong>Name</strong>
      <Input size="mini" value={liName}
             onChange={(e, {value}) => setLiName(value)}/>
        <Button size="mini" onClick={() => handleRename() }> Rename </Button>
      </div>
      <strong>Type</strong> {lineItem.type}
      <Button onClick={()=>handleCopy()} size="mini" floated="right" icon >
        <Icon name="copy" /> Copy
      </Button>

      <Button onClick={handleRemove} size="mini" floated="right" icon>
        <Icon name="trash" /> Remove Line Item
      </Button>
    </Segment>

    {fields && <FieldsEditor
      initialData={fields}
      onUpdate={handleFieldUpdate}
      lineItemName={lineItemName}
      store={store}
    />}

    {timeDefinition && <TimedLineItemEditor
      timeDefinition={timeDefinition}
      onTimeDefinitionUpdated={(updated) => setTimeDefinition(updated)}
    />}

    {isCalculated &&  <LineItemCodeEditor
        code={code}
        lineItem={store.getDataSet().getLineItem(lineItemName)}
        onChanged={(code) => setCode(code)}
    />}
    {isParameterLi &&
      <Input placeholder='Parameter Value' value={parameterValue}
              onChange={(e, data) =>
                 setParameterValue(data.value)}/>
    }

    <Button  onClick={handleApply}>Save</Button>
  </div>
}

