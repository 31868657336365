import {
    DropdownProps,
    Form,
    FormDropdownProps,
    FormInputProps,
    FormTextAreaProps,
    InputOnChangeData, TextArea,
    TextAreaProps,
} from "semantic-ui-react";

import {ChangeEvent, FormEvent, SyntheticEvent} from "react";
import {
    InputFieldDef, ListItemsInputFieldDef,
} from "./PreparedFormInputCommons";
import ListInput from "./ListInput";
import {
    FormInputTypes,
} from "../../platform/ps-types";
import GenericNestedInputWrapper from "./GenericNestedInputWrapper";

function PreparedFormField<T>({
      name,
      fieldDef,
      error,
      value,
      onChange,
      onBlur,
      onDropDownInputChange,
      onListItemInputChange
  }: {
    name: T;
    fieldDef: InputFieldDef;
    error: null | any;
    value: FormInputTypes;
    onChange: (e: ChangeEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>,
               {name, value}: InputOnChangeData | TextAreaProps) => void;
    onBlur: (fieldName: T)=> void;
    onDropDownInputChange: (event: SyntheticEvent<HTMLElement>, data: DropdownProps, fieldName: T)=>void;
    onListItemInputChange: (value: string[], fieldName: T)=>void;
}) {
    const {customFieldType, customFieldValidator, ...fieldProps} = fieldDef;

    switch (customFieldType) {
        case 'textArea':
            return <Form.Field
                error={error}
                style={{width: '100%'}}
            >
                <label>{fieldProps.label}</label>
                <TextArea
                    style={{width: '100%'}}
                    name={name}
                    onInput={onChange}
                    onBlur={() => {
                        onBlur(name)
                    }}
                    value={(value || '') as string}
                    {...fieldProps.input}
                    {...(fieldProps as FormTextAreaProps)}
                />
            </Form.Field>
        case 'text':
            return <Form.Input
                error={error}
                name={name}
                value={value || ''}
                onChange={onChange}
                onBlur={() => {
                    onBlur(name)
                }}
                {...(fieldProps as FormInputProps)}
            />
        case
        'number'
        :
            return <Form.Input
                error={error}
                name={name}
                value={value}
                type={'number'}
                onChange={onChange}
                onBlur={() => {
                    onBlur(name)
                }}
                {...(fieldProps as FormInputProps)}
            />
        case
        'dropdown'
        :
            let {input, ...fieldPropsToPass} = fieldProps
            return <GenericNestedInputWrapper onBlur={()=> {
                onBlur(name)
            }
            }><Form.Dropdown
                error={error}
                name={name}
                selection
                onChange={(e, data)=>{
                onDropDownInputChange(e, data, name)
                }}
                value={value || ((fieldProps as FormDropdownProps).multiple ? [] : '')}
                onBlur={() => {
                    onBlur(name)
                }}
                openOnFocus={true}
                {...(fieldPropsToPass as FormDropdownProps)}
            /></GenericNestedInputWrapper>
        case "listItem":
            return <GenericNestedInputWrapper onBlur={()=> {
                onBlur(name)
            }
            }><ListInput
                initialValue={value as string[] || ['']}
                error={error}
                onValueChange={(value)=>{onListItemInputChange(value, name)}}
                onBlur={() => {
                    onBlur(name)
                }}
                {...(fieldProps as ListItemsInputFieldDef)}
            /></GenericNestedInputWrapper>
    }
}

export default PreparedFormField;