import ActionTypes from './companies.types';
import {
  addCompanyDocument, editCompanyDocument, uploadCompanyFile, addCompanyEvent, deleteCompanyDocument
} from '../../firebase/firebase.utils';
import firebase from 'firebase/app';
import {authStorage} from "../../platform/auth";

const addCompanyStarted = (companyInfo) => ({
  type: ActionTypes.ADD_COMPANY_STARTED,
  payload: companyInfo
});

const addCompanySuccess = (company) => ({
  type: ActionTypes.ADD_COMPANY_SUCCESS,
  payload: company
});

const addCompanyFailed = (error) => ({
  type: ActionTypes.ADD_COMPANY_FAILED,
  payload: error
});

export const startAddCompany = (companyInfo) => {
  return async (dispatch) => {
    dispatch(addCompanyStarted(companyInfo))
    try {
      const companyDoc = await addCompanyDocument(companyInfo);
      const companyData = companyDoc.data;
      dispatch(addCompanySuccess(companyData));
      return companyData;
    } catch (err) {
      dispatch(addCompanyFailed(err.toString()));
    }
  }
}

const editCompanyStarted = (companyId, companyAttributes) => ({
  type: ActionTypes.EDIT_COMPANY_STARTED,
  payload: { companyId, companyAttributes }
});

const editCompanySuccess = (company) => ({
  type: ActionTypes.EDIT_COMPANY_SUCCESS,
  payload: company
});

const editCompanyFailed = (error) => ({
  type: ActionTypes.EDIT_COMPANY_FAILED,
  payload: error
});

export const startEditCompany = (companyId, companyAttributes, forCurrentUser = false) => {
  return async (dispatch) => {
    dispatch(editCompanyStarted(companyId, companyAttributes))
    try {
      const companyDoc = await editCompanyDocument(companyId, companyAttributes);
      const companyData = { ...companyDoc.data, id: companyId };
      if(forCurrentUser){
        authStorage.getUser().setUserCompany(companyData);
      }
      dispatch(editCompanySuccess(companyData))
    } catch (err) {
      dispatch(editCompanyFailed(err.toString()))
    }
  }
}

const uploadCompanyFileStarted = (companyId, fileData) => ({
  type: ActionTypes.UPLOAD_COMPANY_FILE_STARTED,
  payload: { companyId, fileData }
});

const uploadCompanyFileSuccess = (fileData) => ({
  type: ActionTypes.UPLOAD_COMPANY_FILE_SUCCESS,
  payload: fileData
});

const uploadCompanyFileFailed = (error) => ({
  type: ActionTypes.UPLOAD_COMPANY_FILE_FAILED,
  payload: error
});

export const startUploadCompanyFile = (companyId, fileData) => {
  return async (dispatch) => {
    dispatch(uploadCompanyFileStarted(companyId, fileData))
    try {
      const uploadedFileData = await uploadCompanyFile(companyId, fileData);
      dispatch(uploadCompanyFileSuccess(uploadedFileData));
      return uploadedFileData;
    } catch (err) {
      dispatch(uploadCompanyFileFailed(err.toString()));
    }
  }
}

const addCompanyModelUpdateStarted = (companyId, eventData) => ({
  type: ActionTypes.ADD_COMPANY_MODEL_UPDATE_STARTED,
  payload: { companyId, eventData }
});

const addCompanyModelUpdateSuccess = (eventData) => ({
  type: ActionTypes.ADD_COMPANY_MODEL_UPDATE_SUCCESS,
  payload: eventData
});

const addCompanyModelUpdateFailed = (error) => ({
  type: ActionTypes.ADD_COMPANY_MODEL_UPDATE_FAILED,
  payload: error
});

export const startAddCompanyModelUpdate = (companyId, eventData) => {
  return async (dispatch) => {
    dispatch(addCompanyModelUpdateStarted(companyId, eventData))
    try {
      let eventDoc = await addCompanyEvent(companyId, eventData);
      // Convert date to Firebase format just to prep
      eventDoc.updateDate = firebase.firestore.Timestamp.fromDate(eventDoc.updateDate);
      eventDoc.updateDate._seconds = eventDoc.updateDate.seconds;
      dispatch(addCompanyModelUpdateSuccess(eventDoc));
      return eventData;
    } catch (err) {
      dispatch(addCompanyModelUpdateFailed(err.toString()));
    }
  }
}

const deleteCompanyStarted = (companyId) => ({
  type: ActionTypes.DELETE_COMPANY_STARTED,
  payload: companyId
});

const deleteCompanySuccess = (companyId) => ({
  type: ActionTypes.DELETE_COMPANY_SUCCESS,
  payload: companyId
});

const deleteCompanyFailed = (error) => ({
  type: ActionTypes.DELETE_COMPANY_FAILED,
  payload: error
});

export const startDeleteCompany = (companyId) => {
  return async (dispatch) => {
    dispatch(deleteCompanyStarted(companyId));
    try {
      await deleteCompanyDocument(companyId);
      dispatch(deleteCompanySuccess(companyId));
      return companyId;
    } catch (err) {
      dispatch(deleteCompanyFailed(err.toString()));
    }
  }
}