import React, {ReactNode} from "react";
import {ComponentConfig } from "@measured/puck"
import {WidgetWrapperSection} from "./commons";
import {v4 as uuidv4} from "uuid";

export type HeadingProps = {
    config:{
        align: "left" | "center" | "right";
        text?: string;
        level?: _HeadingProps["rank"];
        size: _HeadingProps["size"];
        padding?: string;
    }
    machineName: string;
};

const sizeOptions = [
    { value: "xxxl", label: "XXXL" },
    { value: "xxl", label: "XXL" },
    { value: "xl", label: "XL" },
    { value: "l", label: "L" },
    { value: "m", label: "M" },
    { value: "s", label: "S" },
    { value: "xs", label: "XS" },
];

const levelOptions = [
    { label: "", value: "" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
];

export const HeadingWidget: ComponentConfig<HeadingProps> = {
    fields: {
        machineName: {type: "text", label: "Machine Name"},
        config: {
            type:"object",
                objectFields: {
                text: {
                    type: "textarea",
                },
                size: {
                    type: "select",
                    options: sizeOptions,
                },
                level: {
                    type: "select",
                    options: levelOptions,
                },
                align: {
                    type: "radio",
                    options: [
                        {label: "Left", value: "left"},
                        {label: "Center", value: "center"},
                        {label: "Right", value: "right"},
                    ],
                },
                padding: {type: "text"}
            }
        },
    },
    defaultProps: {
        machineName: `HeadingWidget-${uuidv4().substring(0, 4)}`,
        config: {
            align: "left",
            text: "Heading",
            padding: "24px",
            size: "m"
        }
    },
    render: ({ config, machineName }) => {
        const {align, text, size, level, padding} = config;
        return (
            <WidgetWrapperSection padding={padding}>
                <HeadingWrapper size={size} rank={level as any}>
          <span style={{ display: "block", textAlign: align, width: "100%" }}>
            {text}
          </span>
                </HeadingWrapper>
            </WidgetWrapperSection>
        );
    },
};


type _HeadingProps = {
    children: ReactNode;
    rank?: "1" | "2" | "3" | "4" | "5" | "6";
    size?: "xxxxl" | "xxxl" | "xxl" | "xl" | "l" | "m" | "s" | "xs";
};

const HeadingWrapper = ({ children, rank, size = "m" }: _HeadingProps) => {
    const Tag: any = rank ? `h${rank}` : "span";

    return (
        <Tag className={`widget-heading-wrapper${size ? `--${size}` : ""}`}>
            {children}
        </Tag>
    );
};