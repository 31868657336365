import React, { useRef } from 'react';
import { Button } from 'semantic-ui-react';

export const FileUploadButton = ({ onFileSelect }: {
    onFileSelect: (file: File) => void;
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            onFileSelect(event.target.files[0]);
        }
    };

    return (
        <div>
            <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={handleFileChange}
            />
            <Button primary onClick={handleButtonClick}>
                Upload File
            </Button>
        </div>
    );
};

