import {assignUserToCompany} from "../../platform/company/company.client";
import { functions } from "../../firebase/firebase.core";

export const fsAssignUser = async (userId, companyId) => {
  try {
    const res = await assignUserToCompany(companyId, userId);
    return res.data;
  } catch (error) {
    const eMsg = 'Error assigning user: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

export const fsGetUsers = async () => {
  try {
    const res = await functions.httpsCallable('getUsers')();
    return res.data;
  } catch (error) {
    const eMsg = 'Error retrieving users: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

export const fsGetCompanies = async () => {
  try {
    const res = await functions.httpsCallable('getCompanies')();
    return res.data;
  } catch (error) {
    const eMsg = 'Error retrieving companies: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};