import React from "react";
import {Grid, SemanticWIDTHS} from "semantic-ui-react";

export function GridOfComponents({children, columns, height}: { children: React.ReactNode[], columns?: number, height?: number }) {

  if(!columns) columns = 2;

  return <Grid verticalAlign="middle" container style={ height ?  {height:`${height}px`}:{}} >
    <Grid.Row  stretched columns={columns as SemanticWIDTHS}>
      {children.map((child, index) => <Grid.Column >{child}</Grid.Column>)}
    </Grid.Row>
  </Grid>
}