import {Container, Divider, Header, Image} from "semantic-ui-react";
import React from "react";
import {SITE_URL} from "../core";

import ErrorRenderer from "./ErrorRenderer";

export function LoginLayout({children, error}: {children: any, error: Error | null}) {
  return <Container>
        <a href={SITE_URL}>
          <Header as='h1' inverted>
            <Image src={"/logos/Perl-Logo-Icon-Black-2x.png"} style={{ margin: 0, height: 'auto', width: 'auto', maxWidth: '125px' }} />
          </Header>
        </a>
        <Divider hidden />
        <Container text>
          {children}
          <ErrorRenderer error={error} />
        </Container>
      </Container>
}



