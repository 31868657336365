import {ValueType} from "../../ps-models/line-items";
import {PersistenceQuery, PersistenceQueryDto} from "../../ps-models/lineitems-store";
import {
  useEffect,
  useState
} from "react";
import {Button, Grid, GridColumn, Icon, Input, Menu, MenuItem, MenuItemProps, Segment, Table} from "semantic-ui-react";
import {canBeNumber} from "../../ps-models";

function toStr(obj: ValueType | ValueType[]) {

  if(canBeNumber(obj)){
    return Number(obj);
  } else if(obj === "true" || obj === "false"){
    return obj === "true";
  } else if (Array.isArray(obj)) {
    return obj.join(",");
  } else {
    return obj;
  }
}

function FilterStoresByParamValues({query}: {
  query: PersistenceQueryDto,
}) {
  const [localHavingParamValues, setLocalHavingParamValues] = useState(query._havingParamValues);

  useEffect(() => {
    query._havingParamValues = localHavingParamValues;
  }, [localHavingParamValues]);


  const handleUpdate = (fieldName: string, newName: string, value: ValueType) => {

    let newHavingParams = {...localHavingParamValues, [newName]: value};
    if (newName !== fieldName) {
      delete newHavingParams[fieldName];
    }
    setLocalHavingParamValues(newHavingParams);
  }

  const handleDelete = (fieldName: string) => {
    let newHavingParams = {...localHavingParamValues};
    delete newHavingParams[fieldName];
    setLocalHavingParamValues(newHavingParams);
  }

  const handleAdd = () => {
    let newHavingParams = {...localHavingParamValues, "": ""};
    setLocalHavingParamValues(newHavingParams);
  }

  return <>
    <Button onClick={handleAdd}>Add</Button>

    <Table celled compact size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Param</Table.HeaderCell>
          <Table.HeaderCell>Value</Table.HeaderCell>
          <Table.HeaderCell>Remove</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(localHavingParamValues).map((field, index) => (
          <Table.Row key={index}>
            <Table.Cell>
              <Input
                style={{width: "100%"}}
                size="mini"
                value={field}
                onChange={(e) => handleUpdate(
                  field,
                  e.target.value,
                  toStr(localHavingParamValues[field])
                )}
              />
            </Table.Cell>
            <Table.Cell>
              <Input
                style={{width: "100%"}}
                size="mini"
                value={localHavingParamValues[field]}
                onChange={(e) =>
                  handleUpdate(field, field, toStr(e.target.value))
                }
              />
            </Table.Cell>
            <Table.Cell>
              <Button size="mini" icon onClick={() => handleDelete(field)}>
                <Icon name='trash'/>
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </>
}

interface FilterStoreByParamsProps {
  query: PersistenceQueryDto,
}

type FilterStoresByParamsState  = PersistenceQueryDto["_selectParams"]

const FilterStoresByParams = ({query}:FilterStoreByParamsProps)=>{
  const [locallySelectedParams, setLocallySelectedParams] = useState<FilterStoresByParamsState>([...new Set(query._selectParams)]);

  useEffect(()=>{
    query._selectParams = locallySelectedParams
  }, [locallySelectedParams])

  const handleUpdate = (index: number, newParamName: string) => {
    if(locallySelectedParams?.length > index){
      if(newParamName !== locallySelectedParams[index]){
        const copy = [...locallySelectedParams];
        copy[index] = newParamName;
        setLocallySelectedParams(copy);
      } else {
        console.info("NOT Setting the new value", newParamName, locallySelectedParams[index])
      }
    }
  }

  const handleDelete = (index: number) => {
    setLocallySelectedParams((prev)=>{
      return prev.filter((it,idx)=>idx!==index);
    });
  }

  const handleAdd = () => {
    setLocallySelectedParams((prev)=>{
      return prev.concat([''])
    });
  }

  return <>
    <Button onClick={handleAdd}>Add</Button>
    <Table celled compact size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Param</Table.HeaderCell>
          <Table.HeaderCell>Remove</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {locallySelectedParams.map((paramName, index) => (
            <Table.Row key={paramName}>
              <Table.Cell>
                <Input
                    style={{width: "100%"}}
                    size="mini"
                    value={paramName}
                    onChange={(e) => handleUpdate(
                        index,
                        e.target.value,
                    )}
                />
              </Table.Cell>
              <Table.Cell>
                <Button size="mini" icon onClick={() => handleDelete(index)}>
                  <Icon name='trash'/>
                </Button>
              </Table.Cell>
            </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </>
}

const queryBuilderConfigurators = ["names", "fields", "params", "param values"] as const;
type QueryEditorCapabilities = typeof queryBuilderConfigurators[number];
export function QueryEditorNew({query, onQueryUpdate}: {
  query: PersistenceQuery,
  onQueryUpdate: (query: PersistenceQuery) => void
}){
  const [activeCapability, setActiveCapability] = useState<QueryEditorCapabilities>();
  const [localQuery, setLocalQuery] = useState(query.serialize());

  useEffect(() => {
    setLocalQuery(query.serialize());
  }, [query]);

  const handleApply = () => {
    console.info("The localQuery should have the stuff", localQuery)
    onQueryUpdate(PersistenceQuery.deserialize(localQuery));
  }

  const handleItemClick = (e: any, { name }: MenuItemProps) => setActiveCapability(name as QueryEditorCapabilities)
  return (<>
        <Grid>
          <GridColumn width={2}>
            <Menu fluid vertical tabular>
              <MenuItem
                  name='params'
                  active={activeCapability === 'params'}
                  onClick={handleItemClick}
              />
              <MenuItem
                  name='param values'
                  active={activeCapability === 'param values'}
                  onClick={handleItemClick}
              />
              {/*<MenuItem*/}
              {/*    name='param values'*/}
              {/*    active={activeCapability === 'param values'}*/}
              {/*    onClick={handleItemClick}*/}
              {/*/>*/}
              {/*<MenuItem*/}
              {/*    name='companies'*/}
              {/*    active={activeCapability === 'companies'}*/}
              {/*    onClick={handleItemClick}*/}
              {/*/>*/}
              {/*<MenuItem*/}
              {/*    name='links'*/}
              {/*    active={activeCapability === 'links'}*/}
              {/*    onClick={handleItemClick}*/}
              {/*/>*/}
            </Menu>
          </GridColumn>

          <GridColumn stretched width={12}>
            <Segment>
              {activeCapability === 'params' && <FilterStoresByParams query={localQuery}/>}
              {activeCapability === 'param values' && <FilterStoresByParamValues query={localQuery} />}
            </Segment>
          </GridColumn>
        </Grid>
      <Grid>
        <GridColumn stretched width={12}>
          <Button onClick={() => handleApply()}>
            Apply
          </Button>
        </GridColumn>
      </Grid>
      </>
    )
}