import {
  CANONICAL_NAME_FIELD,
  LineItemsStore,
  pQuery, QueryResult,
  StoreQuery
} from "../../../ps-models/lineitems-store";
import {LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {Header, Segment} from "semantic-ui-react";
import {useLineItemFilter} from "../../../lineitems-store/MetadataFilters";
import {usePersistenceStoreQueryWithContextUpdate} from "../../../lineitems-store/LineItemsStore.hook";
import {itemsNotToBeRoundedOff, setLabelAndFormatForAggregates} from "./storeFormulas";
import {LoadingBlock} from "../../../ui/Loading";
import {
  getLastDateOfPreviousMonth,
  STORE_VALUE_DECIMALS_KEY,
  utcDate
} from "../../../ps-models";
import {
  buildMonthlyTimeDef,
  buildTimedCalculatedLineItem,
  field,
  LineItemsFieldSet
} from "../../../ps-models/line-items";
import {authStorage} from "../../../auth";
import {getAmProjectConfig} from "../../../ps-models";

let revenueLineItems = [
  'Solar Fee',
  'Battery Fee',
  'Daily Fee',
  'Net Revenue'
]

let capexLineItems = [
  // 'Original Asset Value',
  'Original_Asset_Value__c',
  'Projected Current Value',
  'monthly_depreciated_value__c',
  // 'discounted_purchase_price__c',
  // 'Current Value',
  'Customer Buy out Price',
];

export function PortfolioFinancials() {
  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);

  let sourceMetadata = [
    'Current_Energy_Retailer__c', 'Network__c', 'State__c', 'Billing_Type__c', 'Residential_Business__c', 'SPV_Fund__c'
  ]
  const store = usePersistenceStoreQueryWithContextUpdate(collection,
      pQuery()
          .selectSourceParams(sourceMetadata)
          .havingParameterValues({"dsType": "site"})
          .withLineItems([...revenueLineItems, ...capexLineItems])
          .withGranularity('months')
      ,
      (store) => {
        store.timeIndex = store.timeIndex.withStartDate(utcDate(2024, 1, 1))

        store.getDataSet().addTimedGroupingLineItemsByField(CANONICAL_NAME_FIELD);
        setLabelAndFormatForAggregates(store)
      });

  let isLoading = !store;

  return <Segment>
    {store && <AggregatedRevenuesAndCapex store={store} />}
    {isLoading && <LoadingBlock  />}
  </Segment>

}

export function AggregatedRevenuesAndCapex({store}: { store: LineItemsStore }) {

  let [filterQuery, filtersComponent] = useLineItemFilter({
    'source_Current_Energy_Retailer__c': 'Retailer',
    'source_Network__c': 'Network',
    'source_State__c': 'State',
    'source_Billing_Type__c': 'Billing Type',
    'source_Residential_Business__c': 'Residential/Business',
    'store_sourceId': 'Site',
    'source_SPV_Fund__c': 'SPV Fund'
  }, store);

  let queryWithGroupingAndFilters = StoreQuery.withField('store_groupingName').or(filterQuery)
  restrictDecimalPlacesInNonRoundedLineItems({store});
  const capexQueryResult = store.materializeTimed(queryWithGroupingAndFilters, {sourceQuery: store.getSourceQuery()}).query(StoreQuery.byNames(capexLineItems, true).withTimeIndex(store.timeIndex.withGranularity('months')));
  const revenueQueryResult = store.materializeTimed(queryWithGroupingAndFilters).query(StoreQuery.byNames(revenueLineItems, true).withTimeIndex(store.timeIndex.withGranularity('months')));


  return <Segment basic>
    {filtersComponent}
    {capexQueryResult && <AggregatedView sectionHeading={"Capex Summary"} store={store}  queryResult={capexQueryResult} />}
    {revenueQueryResult && <AggregatedView sectionHeading={"Cash Flows"} store={store}  queryResult={revenueQueryResult} />}
  </Segment>
}

function AggregatedView({sectionHeading, store, queryResult}: { sectionHeading: string, store: LineItemsStore, queryResult: QueryResult}) {
  return <Segment basic>

    <Header as="h2" color="purple">{sectionHeading}</Header>
    <LineItemsTableWithFormulas queryResult={queryResult} store={store}
                                withGroups projectionsStartDate={new Date(getLastDateOfPreviousMonth())}  />

  </Segment>
}


const restrictDecimalPlacesInNonRoundedLineItems = ({store, decimals=2}: {store: LineItemsStore, decimals?:number})=>{
  for(let liName of itemsNotToBeRoundedOff){
    store.getDataSet().getByField('store_sourceLineItemName', liName).forEach(li => {
      store.getDataSet().addFieldToLineItem(li.name, field(STORE_VALUE_DECIMALS_KEY, decimals.toString()))
    });

    store.getDataSet().addFieldToLineItem(
        liName,
        field(STORE_VALUE_DECIMALS_KEY, decimals.toString())
    )
  }
};

