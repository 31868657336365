import React, {MutableRefObject, ReactNode, Ref, useState} from "react";
import {Button, Dimmer, Grid, Loader, Modal} from "semantic-ui-react";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";

const FONT_SIZE = '22.5px';

export const PDFPreviewContent = ({pageContents, pagesRefArray, PageHeader, PageFooter, generationDate}: {
    pageContents: JSX.Element[],
    pagesRefArray?: MutableRefObject<HTMLDivElement | null>[],
    PageHeader?: ()=>JSX.Element,
    PageFooter?: ({pageNum, generationDate}: {pageNum: number, generationDate: string})=> JSX.Element,
    generationDate: string,
}) => {
    return <>{pageContents.map((content, index) => {
        return <><div
            id={`pdf-content-div${index}`}
            style={{
                height: '2200px',
                width:'1512px',
                lineHeight: 1.2,
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between',
                border: '1px solid #ddd',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)' /* Slight shadow for a 3D effect */
            }}
            {...(pagesRefArray!==undefined ? { ref: pagesRefArray[index] as unknown as Ref<HTMLDivElement>} : {})}
        >
            <Grid style={{
                fontSize: FONT_SIZE,
                margin: '96px 96px 0px 96px',
            }}>
                {PageHeader && <PageHeader/>}
                <Grid.Row style={{margin: '0 12px'}}>
                    {pageContents[index]}
                </Grid.Row>
            </Grid>
            {PageFooter && <Grid style={{
                fontSize: FONT_SIZE,
                margin: '0px 96px 72px 96px',
            }}>
                <PageFooter pageNum={index+1} generationDate={generationDate} />
            </Grid>
            }
        </div>
            <br/>
        </>
    })}
</>
}


export function PDFPreviewer({
                                 pageContents,
                                 pagesRefArray,
                                 PageHeader,
                                 PageFooter,
                                 downloadedFileBaseName,
                                 onDownloadComplete,
                                 appendGenerationDateToFileName=false,
                                 isPreviewOpen,
                                 setIsPreviewOpen,
                                 downloadDisabled=false,
                                 onInputsSave=undefined
                             }: {
    pageContents: JSX.Element[],
    pagesRefArray: MutableRefObject<HTMLDivElement | null>[],
    PageHeader?: ()=>JSX.Element,
    PageFooter?: ({pageNum, generationDate}: {pageNum: number, generationDate: string})=> JSX.Element,
    downloadedFileBaseName: string,
    onDownloadComplete: () => void,
    appendGenerationDateToFileName?: boolean
    isPreviewOpen: boolean,
    setIsPreviewOpen: (isPreviewOpen: boolean)=> void,
    downloadDisabled?: boolean,
    onInputsSave?: ()=>void
}) {
    const [isDownloading, setIsDownloading] = useState(false);
    const [currentInputIndex, setCurrentInputIndex] = useState<number>(-1);
    const generationDate = new Date().toLocaleString('en-US', {
            year: "numeric",
            month: "2-digit",
            day: "numeric"
        })
    const downloadPDF = async () => {
        const fileNameSuffix = appendGenerationDateToFileName ? `_${generationDate}`: ''
        const preparedFileName = `${downloadedFileBaseName}${fileNameSuffix}`;
        try {
            setIsDownloading(true);
            const doc = new jsPDF('p', 'mm', 'letter');
            for (let [index, _] of pageContents.entries()) {
                if (pagesRefArray[index].current) {
                    let canvas = await html2canvas(pagesRefArray[index].current as HTMLDivElement, {
                        onclone: function (doc) {
                            let targetDiv = doc.getElementById(`pdf-content-div${index}`);
                            let segmentsOnPage = doc.querySelectorAll('.ui.basic.segment');
                            let readOnlySections = doc.querySelectorAll('.pdf-read-only-section')
                            if (targetDiv) {
                                targetDiv.style.border = 'none';
                                targetDiv.style.boxShadow = 'none';
                                targetDiv.style.width = '1512px';
                            }
                            if(segmentsOnPage.length>0){
                                for(let i=0;i<segmentsOnPage.length;i++){
                                    // @ts-ignore
                                    segmentsOnPage[i].style.background = 'white';
                                }
                            }
                            if(readOnlySections.length >0){
                                for(let i=0;i<readOnlySections.length;i++){
                                    // @ts-ignore
                                    readOnlySections[i].style.display = 'none';
                                }
                            }
                        },
                        width: 1512,
                        height: 2200,
                        scale: 1,
                        allowTaint: true,
                        useCORS: true
                    });

                    let imgData = canvas.toDataURL("image/png");
                    const pdfWidth = doc.internal.pageSize.getWidth();
                    const pdfPageHeight = doc.internal.pageSize.getHeight();
                    doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfPageHeight);
                    if(index !== pageContents.length-1){
                        doc.addPage()
                    }
                }
            }
            doc.save(`${preparedFileName}.pdf`)
            onDownloadComplete();
        } catch(err) {
            console.error(`Could not download the PDF ${preparedFileName}`, err)
        } finally {
            setIsDownloading(false);
        }
    }

    const goToNextInput = () => {
        const focusableElements = '[contenteditable]';
        const focusable = Array.from(document.querySelectorAll(focusableElements));
        let indexToFocusOn = (currentInputIndex === focusable.length-1 ? focusable.length-1 : currentInputIndex + 1);
        // @ts-ignore
        focusable[indexToFocusOn].focus();
        setCurrentInputIndex(indexToFocusOn);
    };

    const goToPreviousInput = () => {
        const focusableElements = '[contenteditable]';
        const focusable = Array.from(document.querySelectorAll(focusableElements));
        let indexToFocusOn = (currentInputIndex === 0 ? 0 : currentInputIndex - 1);
        // @ts-ignore
        focusable[indexToFocusOn].focus();
        setCurrentInputIndex(indexToFocusOn);
    };

    return (
        <Modal
                size={'fullscreen'}
                closeIcon
                open={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
                onOpen={() => setIsPreviewOpen(true)}
            >
                <Modal.Header>
                    PDF Preview
                </Modal.Header>
                <Modal.Content scrolling>
                    <Dimmer active={isDownloading} inverted><Loader style={{fontSize:FONT_SIZE}} active={isDownloading} content={`Downloading, Please wait...`}/></Dimmer>
                    <PDFPreviewContent pageContents={pageContents} generationDate={generationDate} pagesRefArray={pagesRefArray} PageFooter={PageFooter} PageHeader={PageHeader}/>
                </Modal.Content>
                <Modal.Actions style={{textAlign: 'right'}}>
                    <><Button
                        floated={'left'}
                        icon={'close'}
                        labelPosition="left"
                        content="Exit Preview" onClick={
                        () => setIsPreviewOpen(false)
                    }/>
                        {onInputsSave && (<>
                                {/*<Loader*/}
                                {/*    inline={true}*/}
                                {/*    style={{float: 'left', color: '#6f6fb6'}}*/}
                                {/*    size={'tiny'}*/}
                                {/*    active={true}*/}
                                {/*    content={'Auto Saving...'}*/}
                                {/*    indeterminate={true}*/}
                                {/*/>*/}
                                <Button
                                    color={'pink'} icon={'long arrow alternate left'} labelPosition="left"
                                    disabled={currentInputIndex < 0}
                                    content="Go To Prev Input" onClick={goToPreviousInput}/>
                                <Button
                                    color={'blue'} icon={'long arrow alternate right'} labelPosition="right"
                                    // disabled={currentIndex === focusableElements.length}
                                    content="Go To Next Input" onClick={goToNextInput}/>
                                <Button
                                    color={'green'}
                                    icon={'save'}
                                    content={'Save Responses'}
                                    onClick={onInputsSave}
                                    />
                        </>
                        )}
                        <Button
                        color={'purple'} icon={'download'} labelPosition="left"
                        disabled={downloadDisabled}
                        content={"Download PDF"} onClick={
                        () => downloadPDF()
                    }/>
                    </>
                </Modal.Actions>
            </Modal>
    );
}

export const ReadOnlyPDFSection = ({children}: {children: ReactNode})=> {
    return <Grid className={'pdf-read-only-section'}>
        {children}
    </Grid>
}