import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Dimmer, Loader, Message, Segment } from "semantic-ui-react";
import { now } from "moment/moment";
import {useInstantUpdater} from "./generic.hooks";
import {CancellablePromise} from "./ps-models";

export type UpdateContextStatus = "loading" | "ready" | "error" | "empty"


export type UpdateAction = {type: string, data?: any}
export interface UpdateContextProps {
  loading: () => void
  error?: Error,
  message?:string,
  action: UpdateAction,
  actionChanged: number,
  empty: () => void
  ready: () => void,
  fireAction: (type:string, data?:any) => void
  reload: (service?: any) => void
  lastUpdate: number
  status: UpdateContextStatus,
  setService:(service: any) => void
}


interface UpdateData<T> extends UpdateContextProps {
  getService(): T
  tryGetService(): T | undefined
}

export const INIT_ACTION = "INIT";
export const REFETCH_ACTION = "REFETCH";

export const UpdateContext = React.createContext<UpdateData<any>>({
    status: "loading",
    lastUpdate: now(),
    action: {type: INIT_ACTION},
    actionChanged: now(),
    loading: () => {
    },
    ready: () => {
    },
    empty:() => {},
    reload: () => {},
    fireAction: () => {},
    setService:(service: any) => {},
    getService(): any {
    },
    tryGetService() {}
  }
);

export function useUpdateContext<T>() {
  return useContext(UpdateContext) as UpdateData<T>;
}
export function Deferred<T>({render, delay = 50}: { render: (service: T) => React.ReactNode | React.ReactNode[], delay? : number}) {
  const ctx = useContext(UpdateContext) as UpdateData<T>;

  let [st, setSt] = useState<{status: "loading" | "ready", lastAccepted?: number}>({
    status: "loading" , lastAccepted: ctx.lastUpdate
  });

  const loading = <Dimmer inverted active><Loader  inverted active={true}>Loading</Loader></Dimmer>;

  let memo = useMemo(()=> ctx.tryGetService() ? render(ctx.getService()) : loading, [st.lastAccepted])

  useEffect(()=> {
      //setSt({status: "loading"})
      setTimeout(()=> setSt({status: "ready", lastAccepted: ctx.lastUpdate}) , delay);
  }, [ctx]);

  return <div  style={{"position": "relative"}}>
    {st.lastAccepted !== ctx.lastUpdate && loading}
    {memo}
    </div>
}



export function UpdateProvider<T>({loadService, children, errorRender, onStatusChanged, emptyMessage}: {
  loadService?: () => Promise<any>,
  errorRender?: (error: Error) => React.ReactNode
  children: React.ReactNode | React.ReactNode[]
  onStatusChanged?: (status: string)=>void,
  emptyMessage?: string
}) {
  const serviceRef = useRef(null);
  const [reloadId, updateReload] = useInstantUpdater()

  if(!loadService) {
    loadService = () => Promise.resolve(undefined);
  }

  const [_loaderService, setLoaderService] = useState<any>(()=>loadService)

  if(!errorRender) {
    errorRender = (error: Error) => <Message negative>{error.name} : {error.message}</Message>
    // @TODO: Refactor this error handling mechanism. (Same in WaitForPromise and SyncResourceContext)
    // We need to reconcile the error handling processing from this component with the one from ErrorHandling.tsx
    // Right now they are overlapping, and we should have a single way of handling unexpected errors
  }

  let [updated, setUpdated] = useState<UpdateData<T>>({
    status: "loading",
    lastUpdate: now(),
    actionChanged: now(),
    action: {type: INIT_ACTION},
    getService() {
      if(serviceRef.current === undefined || serviceRef.current === null) {
        throw Error("Update context's service is null, this should not happen");
      }
      return serviceRef.current
    },
    tryGetService() {
      if(!serviceRef.current) {
        return undefined;
      }
      return serviceRef.current
    },
    loading() {
      setUpdated((updated) => ({...updated, status: "loading", lastUpdate: now()}));
      onStatusChanged && onStatusChanged("loading");
    },
    empty() {
      setUpdated((updated) => ({...updated, status: "empty", lastUpdate: now()}));
      onStatusChanged && onStatusChanged("empty");
    },
    ready() {
      setUpdated((updated) => ({...updated, status: "ready", lastUpdate:
          now()
      }))
      onStatusChanged && onStatusChanged("ready");
    },
    setService(service: any) {
      if(service) {
        if(typeof service === "function") {
          setLoaderService(() => service)
        } else {
          setLoaderService(() => ()=> Promise.resolve(service));
        }
      }
    },
    reload() {
      updateReload();
      setUpdated((updated) => ({...updated, status: "ready", lastUpdate: now()}))
    },
    fireAction(action, data) {
      setUpdated((updated) => ({...updated,
        actionChanged: now(),
        action: {type: action, data}
      }))
    }
  });

  useEffect(()=> {
    setUpdated({...updated, status: "loading", lastUpdate: now()});
    onStatusChanged && onStatusChanged("loading");

    let promise : undefined | CancellablePromise<any>

    const timeoutId = setTimeout(() => {
      promise = CancellablePromise.makeCancellable(_loaderService())
      promise.then(
        (service) =>  {
          serviceRef.current = service;
          setUpdated({...updated, status: "ready", lastUpdate: now()});
          onStatusChanged && onStatusChanged("ready");
        }
      ).catch(e => {
        console.error(e);
        setUpdated({...updated, status: "error", lastUpdate: now(),
          error: e
        })
        onStatusChanged && onStatusChanged("error");
      });
    }, 20)

    return () => {
      clearTimeout(timeoutId)
      promise?.cancel()
    }
  }, [_loaderService, reloadId]);

  return <UpdateContext.Provider value={updated}>
    {(updated.status === "ready" || updated.status === "loading" || updated.status === "empty")  && children}
    {/*{updated.status === "loading" &&  <Loader active={true}>Loading</Loader>}*/}
    {updated.status === "error" && errorRender(updated.error!)}
  </UpdateContext.Provider>
}

export function WaitForIt<T>({children, emptyMessage}: { children:  React.ReactNode | React.ReactNode[], emptyMessage?: string}) {
  let {status, tryGetService} = useUpdateContext<T>();


  let service = tryGetService();

  if (status === "empty"|| !service) {
    return <Segment style={{"position": "relative", "padding": "40px"}}>{emptyMessage || "No results"}</Segment>
  }

  if (status === "loading") {
    return <Segment style={{"position": "relative", "padding": "40px"}}><Loader active={true}/></Segment>
  }

  return <>{children}</>
}

//TODO: Making a V2 while we refactor the other one, it is showing No Result when the service is still loading
export function WaitForItV2<T>({children, emptyMessage}: { children:  React.ReactNode | React.ReactNode[], emptyMessage?: string}) {
  let {status, tryGetService} = useUpdateContext<T>();


  let service = tryGetService();

  if (status === "empty") {
    return <Segment style={{"position": "relative", "padding": "40px"}}>{emptyMessage || "No results"}</Segment>
  }

  if (status === "loading") {
    return <Segment style={{"position": "relative", "padding": "40px"}}><Loader active={true}/></Segment>
  }

  return <>{children}</>
}