import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import companiesReducer from "./companies/companies.reducer";
import projectsReducer from "./projects/projects.reducer";
import scenarioReducer from "./scenario/scenario.reducer";
import adminReducer from "./admin/admin.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
  // whitelist: ['cart'] // user storage is already handled with firebase auth
};

const rootReducer = combineReducers({
  user: userReducer,
  companies: companiesReducer,
  projects: projectsReducer,
  scenario: scenarioReducer,
  admin: adminReducer,
});

export default persistReducer(persistConfig, rootReducer);
