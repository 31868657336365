import {LineItemRow, LineItemsStore, StoreQuery} from "../../../ps-models/lineitems-store";
import React, {useEffect, useMemo} from "react";
import {loadSiteStore, useSiteStoreContext} from "../../siteStoreLoader";
import {Grid, Header, Icon, Segment, SemanticWIDTHS} from "semantic-ui-react";
import {formatSingleCellRow} from "./formatting";


export function SiteDetail() {
  const store = useSiteStoreContext();

  const result = useMemo(() => store.query(StoreQuery.byNames([
      'Site_ID__c',
      'Analytics_Serial_Number__c',
      'Name',
      'State__c',
      'Post_Code__c',
      'Billing_Type__c',
      'Current_Energy_Retailer__c',
      'Solar_Bill_Rate_per_kWp__c',
      'Battery_Bill_Rate_per_kWh__c',
      'Optimisation_Control_Charge_Day__c',
      'Metering_and_Optimiser_Bill_Rate_per_Day__c',
      'kWp_Solar_System__c',
      'Battery_kWh__c',
      'Monthly Lease Rate',
      'PPA Rate',
      'Discount to PPA Rate',
      'Battery KW'
    ])), [store]);

  return <Segment basic>
    <Header as="h2" color="purple"><Icon className="building outline" size="small" />Site Location</Header>
    <Segment>
      <ValueGrid metrics={[
          {lineItemRow: result.row("Site_ID__c"), label: "Site ID"},
          {lineItemRow: result.row("Analytics_Serial_Number__c"), label: "Analytics Serial Number"},
          {lineItemRow: result.row("Name"), label: "Site Name"}
      ]} />
    </Segment>
    <Segment>
      <ValueGrid metrics={[
          {lineItemRow: result.row("State__c"), label: "State"},
          {lineItemRow: result.row("Post_Code__c"), label: "Zip Code"}
      ]} />
    </Segment>
    <Header as="h2" color="purple"><Icon className="dollar" size="small" />Billing Rates and System Size</Header>
    <Segment>
      <ValueGrid metrics={[
          {lineItemRow: result.row("Billing_Type__c"), label: "Billing Type"},
          {lineItemRow: result.row("Current_Energy_Retailer__c"), label: "Retailer"},
      ]} />
    </Segment>

    {/*<Segment>*/}
    {/*  <ValueGrid metrics={[*/}
    {/*      {lineItemRow: result.row("Solar_Bill_Rate_per_kWp__c"), label: "Daily Solar Fee"},*/}
    {/*      {lineItemRow: result.row("Battery_Bill_Rate_per_kWh__c"), label: "Daily Battery Fee"},*/}
    {/*  ]} />*/}
    {/*</Segment>*/}
    <Segment>
      <ValueGrid metrics={[
        {lineItemRow: result.row("PPA Rate"), label: "PPA Rate ($/kWh)"},
        {lineItemRow: result.row("Discount to PPA Rate"), label: "Discount to PPA Rate"}
      ]} />
    </Segment>
    {/*<Segment>*/}
    {/*  <ValueGrid metrics={[*/}
    {/*      {lineItemRow: result.row("Optimisation_Control_Charge_Day__c"), label: "Daily Optimisation Control Fee"},*/}
    {/*      {lineItemRow: result.row("Metering_and_Optimiser_Bill_Rate_per_Day__c"), label: "Daily Metering Data Fee"},*/}
    {/*  ]} />*/}
    {/*</Segment>*/}
    <Segment>
      <ValueGrid metrics={[
          {lineItemRow: result.row("kWp_Solar_System__c"), label: "Solar System kWp"},
          {lineItemRow: result.row("Battery_kWh__c"), label: "Battery kWh"},
        {lineItemRow: result.row("Battery KW"), label: "Battery kW"},
      ]} />
    </Segment>

</Segment>
}

function ValueGrid({metrics}: { metrics: {lineItemRow: LineItemRow | undefined, label: string, decimals?: number}[] }) {
  return <Grid solid>
    <Grid.Row columns={metrics.length as SemanticWIDTHS}>
      {metrics.map(m => <Grid.Column><strong>{m.label}</strong></Grid.Column>)}
    </Grid.Row>
    <Grid.Row columns={metrics.length as SemanticWIDTHS}>
      {metrics.map(m => <Grid.Column>{formatSingleCellRow(m.lineItemRow)}</Grid.Column>)}
    </Grid.Row>
  </Grid>
}
