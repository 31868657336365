import {StoresMetadataSelect} from "../../../lineitems-store/StoresMetadataSelect";
import {pQuery} from "../../../ps-models/lineitems-store";
import {useState} from "react";
import {authStorage} from "../../../auth";
import {getAmProjectConfig} from "../../../ps-models";

export function SPVSelection({onSelect}: { onSelect: (spv: string) => void }) {
  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);

  let [selected, setSelected] = useState<string>();




  return <>
    <StoresMetadataSelect
      value={selected}
      query={pQuery()
        .metadataOnly()
        .havingParameterValues({dsType: 'spv'})
    }
      onSelect={(id, text, li) => {
        setSelected(id);
        if (li.fields.getFieldStr('source_spv_fund__c')) {
            onSelect(li.fields.getFieldStr('source_spv_fund__c'))
        } else {
            onSelect(li.name)
        }
      }}
      collection={collection}/>
  </>

}

