import {AmInvoice, AmInvoicePaymentEntry, InjectionTemplateConfig} from "../../ps-types";
import psPlatformClient from "../../psPlatformClient";
import {buildUTCDate, ExcelReportInjectionConfig, OutputMapping} from "../../ps-models";
import {parseISO} from "date-fns";

export const saveAmInvoice = async (companyId: string, name: string, dashboardId: string, config: AmInvoice['config']) => {
  const result = await psPlatformClient<any, AmInvoice>(
    {method: 'post', url: `/am/${companyId}/invoice`,
      data: {companyId,
        dashboardId,
        name,
        config
    } })

  return {
    ...result.data,
    config: deserializeAmInvoiceConfig(result.data.config)
  }
}

export const getAmInvoices = async (companyId: string): Promise<AmInvoice[]> => {
  const result = await psPlatformClient<{companyId: string}, AmInvoice[]>(
    {method: 'get', url: `/am/${companyId}/invoices`, data: {companyId}})
  return result.data.map((it)=> ({
        ...it,
        config: deserializeAmInvoiceConfig(it.config)
  }));
}

export const getAmInvoice = async (companyId: string, invoiceId: string): Promise<AmInvoice> => {
  const result = await psPlatformClient<null, AmInvoice>(
    {method: 'get', url: `/am/${companyId}/invoice/${invoiceId}`})
  return {
    ...result.data,
    config: deserializeAmInvoiceConfig(result.data.config)
  };
}

export const generateAmInvoice = async (companyId: string,
                                       invoiceId: string,
                                       outputMapping: OutputMapping,
                                       invoiceData: AmInvoice['data'],
                                       templateConfig: Omit<InjectionTemplateConfig, 'injectionConfig' | 'name' | 'type'> & {injectionConfig: ExcelReportInjectionConfig}) => {
  const result = await psPlatformClient<any, AmInvoice>(
      {method: 'put', url: `/am/${companyId}/invoice/${invoiceId}`,
      data: {companyId,
        invoiceId,
        outputMapping: outputMapping,
        invoiceData,
        invoiceTemplateConfig: {...templateConfig, type: "Invoice"},
      } })
  return {
    ...result.data,
    config: deserializeAmInvoiceConfig(result.data.config)
  }
}

export const recordCustomerPayment = async (companyId: string,
                                        invoiceId: string,
                                        paymentEntry: AmInvoicePaymentEntry) =>{
  const result = await psPlatformClient<{paymentEntry: AmInvoicePaymentEntry}, boolean>(
      {method: 'put', url: `/am/${companyId}/invoice/${invoiceId}/record-payment`,
        data: {
        paymentEntry
        } })
    return result.data;
}

export const getAmInvoiceDownloadUrl = async (companyId: string, invoiceId: string, downloadFormat?: "pdf") => {
  const result = await psPlatformClient<{downloadFormat?: "pdf"}, {  url: string }>(
    {method: 'post', url: `/am/${companyId}/invoice/${invoiceId}/getUrl`,
  ...(downloadFormat ? {data: {downloadFormat: "pdf"}}: {})
    })
  return result.data;
}

export const getAmInvoicePreviewUrl = async (companyId: string, invoiceId: string) => {
  const result = await psPlatformClient<null, {  url: string }>(
      {method: 'get', url: `/am/${companyId}/invoice/${invoiceId}/getPreviewUrl`,
      })
  return result.data;
}
function deserializeAmInvoiceConfig(config: AmInvoice["config"]){
  let dateFrom = config.dateRange.from;
  let dateTo = config.dateRange.to;
  return {...config, dateRange: {from: parseISO(dateFrom as any), to: parseISO(dateTo as any)}};
}