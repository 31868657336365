import {useLineItemsStoreMetadata, usePersistenceStoreQueryWithContextUpdate} from "../../../lineitems-store/LineItemsStore.hook";
import {
  CANONICAL_NAME_FIELD,
  DEFAULT_SOURCE_GRID,
  LineItemsStore,
  pQuery,
  QueryResult,
  StoreQuery
} from "../../../ps-models/lineitems-store";
import {Container, Grid, Header, Icon, Segment} from "semantic-ui-react";
import React from "react";

import {setLabelAndFormatForAggregates} from "./storeFormulas";

import {LoadingBlock} from "../../../ui/Loading";
import {LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {utcDate} from "../../../ps-models";
import {CategoryChart} from "../../../lineitems-store/CategoryChart";
import {authStorage} from "../../../auth";
import {getAmProjectConfig} from "../../../ps-models";

export function CapexRollUp({projectStores}: {projectStores: string[]}) {

  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);

  let sourceMetadata = [
   'versionId', 'location', 'property'
  ]
  const store = usePersistenceStoreQueryWithContextUpdate(collection,
    pQuery()
      .selectSourceParams(sourceMetadata)
      .withLineItems(['Project Costs', 'Total Incentives'])
      // .havingParameterValues({"dsType": "site"})
      .selectLineItemsHavingFieldValues({
        'group': ['Project Price', 'Incentives'],
      })
      .withGranularity('years')
      .withStoreIds(projectStores)
    ,
    (store) => {
      store.getDataSet().addTimedGroupingLineItemsByField(CANONICAL_NAME_FIELD);
      setLabelAndFormatForAggregates(store);

    });

  let isLoading = !store;

  return <>
    {store && <CapexView store={store} />}
    {isLoading && <LoadingBlock  />}
  </>
}

export function CapexView({store}: { store: LineItemsStore }) {

  let table = QueryResult.emptyTable();

  let firstYear = utcDate(store.timeIndex.startDate.getUTCFullYear(), 0, 1);

  /*
  This is a workaround because we don't have a good component to show Parameter Line Items
   */
  let timeIndex = store.timeIndex
    .withDates(firstYear, firstYear)
    .withGranularity('years');

  table.addSection(
    store.query(StoreQuery
      .byField("group", "Project Price")
    .withTimeIndex(timeIndex)),"Project Price"
  )


  table.addSection(
    store.query(StoreQuery.byNames(['Project Costs'], true)
      .withTimeIndex(timeIndex)
    )
  )

  let incentivesLi = ['Incentives (Cozy)', 'Estimated ITC', 'Total Incentives']
  table.addSection(store.query(
    StoreQuery.byNames(incentivesLi, true)
      .withTimeIndex(timeIndex)),
    'Incentives'
  );
  table.setOrder(incentivesLi)

  table.setFormatting('Project Costs', 'header')
  table.setFormatting('Total Incentives', 'header')
  table.setFormatting('Total Project Price', 'header')

  return <Container>
    {<AggregatedView
        sectionHeading={"Capex Rollup"}
        store={store}
        queryResult={table} />}

    <CategoryChart
      title="Capex Rollup"
      fieldName={"store_sourcename"}
      result={store.query(
        StoreQuery.byNames(['Project Costs', 'Total Incentives', 'Total Project Price'], true)
      )}
    />
  </Container>
}

function AggregatedView({sectionHeading, store, queryResult}:
                          { sectionHeading: string, store: LineItemsStore, queryResult: QueryResult}) {
  return   <>

    <Header as="h2" color="purple">{sectionHeading}</Header>

    <LineItemsTableWithFormulas
      hideHeaders
      hideToolbar
      queryResult={queryResult}
      leftHeaderColumnSize={500}
      store={store}
      withGroups
    />
  </>
}



