import UserActionTypes from "./user.types";
import CompanyActionTypes from "../companies/companies.types";

const INITIAL_STATE = {
  currentUser: null,
  loading: false,
  error: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CompanyActionTypes.ADD_COMPANY_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          userCompany: {
            id: action.payload.id,
            name: action.payload.name,
            ...action.payload,
          },
        },
      };
    case CompanyActionTypes.EDIT_COMPANY_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          userCompany: {
            ...state.currentUser.userCompany,
            ...action.payload,
          },
        },
      };
    case UserActionTypes.CREATE_INITIAL_USERPROFILE_START:
    case UserActionTypes.CREATE_INITIAL_COMPANY_START:
    case UserActionTypes.COMPLETE_ONBOARDING_STEP_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UserActionTypes.CREATE_INITIAL_COMPANY_FAILURE:
    case UserActionTypes.CREATE_INITIAL_USERPROFILE_FAILURE:
    case UserActionTypes.COMPLETE_ONBOARDING_STEP_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          title: "Oops. Looks like something went wrong.",
          error: action.payload,
        },
      };
    case UserActionTypes.COMPLETE_ONBOARDING_STEP_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
        loading: false,
        error: null,
      };
    case UserActionTypes.CREATE_INITIAL_COMPANY_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          linkedCompany: action.payload,
          onboardingCompleted: true,
        },
        loading: false,
        error: null,
      };
    case UserActionTypes.CREATE_INITIAL_USERPROFILE_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default userReducer;
