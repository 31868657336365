import React, {useEffect, useState} from 'react';
import { FileUploader } from 'react-drag-drop-files';
import {
  Button,
  Checkbox,
  Container,
  Icon,
  Message,
  Popup,
  Progress,
  Segment, Select,
} from "semantic-ui-react";
import {LineItemsStore} from "../../ps-models/lineitems-store";
import {LineItemsEditorTable} from "../line-items-editor/LineItemsEditorTable";
import {loadSiteStore, saveSiteStore} from "../siteStoreLoader";
import {v4 as uuidv4} from "uuid";
import {ClosableSection} from "../../ClosableSection";
import {useCompanyId} from "../../core";
import {uploadFile} from "./integrations.client";
import {StoreSetupType} from "../index";
import {ProcessLogs} from "../../ui/ProcessLogs";
import {authStorage} from "../../auth";
import {getAmProjectConfig} from "../../ps-models";


const acceptedFileTypes = ['xls', 'xlsx'];
const acceptedMaxFileSizeInMB = 50;
export function FileImport({storeSetup}: {storeSetup: StoreSetupType}) {

  const [fileData, setFileData] = useState<File | null>(null);
  const [overwrite, setOverwrite] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [importingFile, setImportingFile] = useState<boolean>(false);
  const [toasts, setToasts] = useState<{id: string; message: string; kind: 'info' | 'error' | 'warning'}[]>([]);
  const [importProcessLogs, setImportProcessLogs] = useState<{warning: string[], info: string[]} | null>(null);

  const [importedStores, setImportedStores] = useState<LineItemsStore[] | null>(null);

    const addToast = (message: string, kind: 'info' | 'error' | 'warning' = 'info') => {
        setToasts((existingToasts)=>[...existingToasts, { id: uuidv4(), message, kind }]);
    };

    const handleToastDismiss = (id: string) => {
        setToasts((prevToasts)=>prevToasts.filter((toast) => toast.id !== id));
    };

  const companyId = useCompanyId();

  let company = authStorage.getCompany();
  const { collection: COLLECTION } = getAmProjectConfig(company);

  const submitFile = async (file: File) => {
    if (!file) return;
    setImportingFile(true);
    uploadFile(companyId, file, COLLECTION, overwrite, setUploadProgress)
        .then((result)=> {
            const [serializedStores, processLogs] = result;
            setImportProcessLogs(processLogs);
            setImportedStores(serializedStores.map((store)=>(LineItemsStore.deserialize(store))))
        })
        .catch((err) => {
            addToast(err.message, "error")
        })
      .finally(()=> {
        setImportingFile(false);
        setFileData(null);
      });
  };

  useEffect(()=> {
      function wasAllTheImpactOnStoreRemovedFromView(){
          if(importedStores === null) {
              setToasts([]);
          }
      }

      wasAllTheImpactOnStoreRemovedFromView();
  }, [importedStores]);



  return <div>
      {!importedStores && <Container>
      <div className="fileUploader">

        <FileUploader handleChange={(file:File) => setFileData(file)} name="file" types={acceptedFileTypes}
                      multiple={false}
                      disabled={importingFile}
                      onSizeError={(err: string)=>{
                          addToast(`File is too large. Please make sure file is of less than ${acceptedMaxFileSizeInMB}MB`, "error")
                      }}
                      maxSize={acceptedMaxFileSizeInMB}
        >
            <Icon name="file alternate outline" />
            <div className="text">
              {fileData ? <div>The following file will be imported: <strong>{fileData.name}</strong></div>: <strong>Upload an Excel with your data</strong>}
              <div style={{marginTop: '10px'}}>
                {importingFile ? <><strong>Importing Data...</strong>
                  <Progress percent={uploadProgress} autoSuccess className="bar" /></> : (<>
                    <u>Select {fileData ? "a different file": "file"}</u> or drag and drop here</>
                )}
              </div>
            </div>
        </FileUploader>
      </div>
      <div className="file-uploader-controls-container">
        <Checkbox label="Overwrite line items" onChange={(e, data)=> setOverwrite(data.checked || false)} />
          {fileData && <Button primary
                disabled={!fileData}
                onClick={()=>submitFile(fileData!)} type="submit">Import Excel</Button>}
      </div>
      </Container>}
      {importedStores && <Container>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <h3>Impacted Sites & Line Items</h3>
              <Button icon={'undo alternate'} color={'red'}
                      onClick={()=> {
                          setImportedStores(null);
                      }} content={'Discard all changes.'} />

          </div>
          {importProcessLogs && <ProcessLogs logs={importProcessLogs} />}
          {importedStores.map((store, index)=>{
              return (<div style={{marginTop: '10px'}} key={store.getId() ?? index}>
                      {store && <ImpactViewerAndEditor impactedStoreView={store} onRemoveStoreChangesFromView={(storeId)=> {
                          setImportedStores((stores)=> {
                              let newSetOfStores = stores?.filter((s)=>s.getId() !== storeId) ?? null
                              return (newSetOfStores?.length ?? 0) > 0 ? newSetOfStores : null;
                          })
                      }}
                      storeSetup={storeSetup}
                      />}
              </div>)
          })}
      </Container>}
      <div style={{ position: 'fixed', bottom: 10, right: 10,
          width: "45%",
          zIndex: 10000000
      }}>
          {toasts.map((toast) => (
              <Popup
                  content={toast.message}
                  flowing={true}
                  inverted={true}
                  disabled={toast.message.length <= 250}
                  position={"left center"}
                  trigger={
                      <Message
                          style={{
                              maxHeight: '10%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                          }}
                      key={toast.id}
                      size={"large"}
                      floating={true}
                  {...(toast.kind === 'info' ? {info: true}: {})}
                  {...(toast.kind === 'warning' ? {warning: true}: {})}
                  {...(toast.kind === 'error' ? {error: true}: {})}
                      onDismiss={() => handleToastDismiss(toast.id)}>
                          {
                              toast.message.length > 250
                              ? toast.message.substring(0, 247) + '...'
                              : toast.message
                          }
                      </Message>
                  }
                  hoverable={true}
                  on="hover"
              />
          ))}
      </div>
  </div>

}

const ImpactViewerAndEditor = ({impactedStoreView, onRemoveStoreChangesFromView, storeSetup}: {impactedStoreView: LineItemsStore, onRemoveStoreChangesFromView: (storeId: string)=> void, storeSetup: StoreSetupType}) => {
    const company = authStorage.getCompany();
    const { collection } = getAmProjectConfig(company);
    const [savingProgressMessage, setSavingProgressMessage] = useState<{kind: 'info' | 'warning' | 'error' | 'success', message: string} | null>(null);
    const storeId = impactedStoreView.getId();
    const [disableInteractionWithLiEditor, setDisableInteractionWithLiEditor] = useState<boolean>(false);
    function saveStore() {
        if(!impactedStoreView) return;
        if(storeId !== undefined){
            setSavingProgressMessage({kind: 'info', message: `Saving changes on site with Id ${storeId}`});
            setDisableInteractionWithLiEditor(true);
            console.info('Loading store', collection);
            loadSiteStore(storeId, collection,storeSetup, false).then((entireStore) => {
                let storeToPersist = impactedStoreView;
                if(entireStore !== null){
                    entireStore.getDataSet().addLineItems(Object.values(impactedStoreView.getDataSet().getLineItems()))
                    console.info('Adding line items to the new store')
                    storeToPersist = entireStore;
                }
                saveSiteStore(collection, storeToPersist).then(() => {
                    setSavingProgressMessage({kind: 'success', message: `Changes on site with Id ${storeId} Saved`});
                    onRemoveStoreChangesFromView(storeId);
                }).catch((err) => {
                    setSavingProgressMessage({kind: 'error', message: "Error Saving Store " + JSON.stringify(err)})
                }).finally(()=> {
                    setDisableInteractionWithLiEditor(false);
                })
            })
            setTimeout(()=> {
                setSavingProgressMessage(null);
            }, 4*1000)
        } else {
            setSavingProgressMessage({kind: 'error', message: "Something went wrong. Please try again."});
        }
    }

    return <>
        <Message
            {...(savingProgressMessage?.kind === 'info' ? {info: true}: {})}
            {...(savingProgressMessage?.kind === 'warning' ? {warning: true}: {})}
            {...(savingProgressMessage?.kind === 'error' ? {error: true}: {})}
            {...(savingProgressMessage?.kind === 'success' ? {success: true}: {})}
            hidden={!savingProgressMessage}>{savingProgressMessage?.message}</Message>
        <Segment disabled={disableInteractionWithLiEditor}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <strong>{impactedStoreView.getId()}</strong>
                <div style={{justifyContent: 'end'}}>
                    <Button icon={'close'} secondary onClick={()=> storeId ? onRemoveStoreChangesFromView(storeId) : null} content={'Reject Changes'} />
                    <Button icon={'check circle outline'} primary onClick={saveStore} content={'Approve Changes'}/>
                </div>
            </div>
            {impactedStoreView && <div style={{marginTop: '10px'}}><LineItemsEditorTable localUnsyncedStore={impactedStoreView}  /></div>}
        </Segment>
    </>

}