import React, {useState} from "react";
import {Segment, Select} from "semantic-ui-react";

type Frequency = "monthly" | "daily"
export function useFrequencySelect(defaultFrequency: Frequency = "daily"): [JSX.Element, Frequency] {
  let [frequency, setFrequency] = useState<Frequency>(defaultFrequency);

  return [
    <div className="frequency-select">
      <strong>Frequency</strong>: &nbsp;&nbsp;
  <Select
      placeholder="Frequency" defaultValue={defaultFrequency}
      options={[{value: 'monthly', text: 'Monthly'}, {value: 'daily', text: 'Daily'}]}
      onChange={(e, {value}) => setFrequency(value as "monthly" | "daily")}
    /></div>,
    frequency
  ]
}