import { CircuitBreaker } from "./CircuitBreaker";

type QueueItemBaseType = {
    timeInSeconds: number,
};

type ProcessorParams<ItemType> = {
    processorCallback: (items: ItemType[]) => void | Promise<void>, batchSize?: number, circuitBreaker?: CircuitBreaker
}

export class Queue<BaseItemType extends Record<string, unknown>, ItemStaticMetaDataType extends Record<string, unknown> = Record<string, unknown>, ItemType=BaseItemType & QueueItemBaseType & ItemStaticMetaDataType> {
    private name: string;
    private items: ItemType[];
    private processorCallback?: Function;
    private itemStaticMetaData = {};
    private batchSize = 20;
    private circuitBreaker: CircuitBreaker | null = null;
    private batchBeingProcessed: boolean = false;
    constructor(name: string, processorParams: ProcessorParams<ItemType>, itemStaticMetaData?: ItemStaticMetaDataType) {
        this.items = [];
        this.name = name;
        if (itemStaticMetaData) {
            this.itemStaticMetaData = itemStaticMetaData;
        }
        if (processorParams?.processorCallback) {
            this.processorCallback = processorParams?.processorCallback;
        }
        if (processorParams?.batchSize) {
            this.batchSize = processorParams?.batchSize
        }
        if (processorParams?.circuitBreaker) {
            this.circuitBreaker = processorParams?.circuitBreaker;
        }
    }

    private clear() {
        this.items = [];
    }

    private canAddItem(item: BaseItemType) {
        // We can use this method, to do any other checks as well.
        // Do not ingest more items into the queue if current size of queue is already 3X of batch size.
        const isFull = this.items.length === (3 * this.batchSize);
        if (isFull) {
            console.warn(`The item could not be added to the ${this.name} queue for processing because the queue was full`, item);
        }
        return !isFull;
    }

    add(item: BaseItemType) {
        if (this.canAddItem(item)) {
            this.items.push({
                ...item,
                timeInSeconds: Date.now() / 1000,
                ...this.itemStaticMetaData
            } as ItemType);
        }
        this.processBatch()
    }

    async processBatch() {
        if (!this.batchBeingProcessed && this.items.length >= this.batchSize && this.canProcessItem()) {
            this.batchBeingProcessed = true;
            const itemsOfBatch = this.items.splice(0, this.batchSize);
            await this.processItems(itemsOfBatch);
            this.batchBeingProcessed = false;
        }
    }

    flush() {
        if (this.items.length > 0 && this.canProcessItem())  {
            this.processItems(this.items);
            this.clear();
        }
    }

    private async processItems(items: ItemType[]) {
        try {
            this.circuitBreaker?.requestEvent();
            await this.processorCallback?.(items);
            this.circuitBreaker?.successEvent();
        }
        catch (err) {
            console.warn(`The items of the ${this.name} queue could not be processed`, err);
            this.circuitBreaker?.errorEvent();
        }
        return;
    }

    private canProcessItem() {
        if (this.circuitBreaker?.getState() !== 'open') {
            return true;
        } else {
            return false;
        }
    }
}