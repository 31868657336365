import {NewLayout} from "../NewLayout";
import React from "react";
import {Button} from "semantic-ui-react";
import {usePlatformUrls} from "../core";
import {useHistory} from "react-router-dom";
import {useAuthCtx} from "../auth/AuthContextProvider";

export function TaskWorkflowsPage({projectId}: {projectId: string}) {
    const history = useHistory();
    const {logout} = useAuthCtx()
    const { projects, fileUpload } = usePlatformUrls();

    return <NewLayout
      header="Task Workflows"
      subHeader="Follow the steps below to complete your project."
      contentWide={true}
      topActions={<>
        <Button onClick={() => history.push(fileUpload(projectId?? ''))}>Back</Button>
        <Button onClick={() => history.push(projects())}>Cancel</Button>
      </>}
    >
      <Button onClick={logout}>Logout</Button>
    </NewLayout>
}

function TaskWorkflowsComponent() {
    return <div>Hello</div>
}

