import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Form, Segment, Header, Button, Message, Icon } from "semantic-ui-react";
import { 
  selectCurrentCompanies, selectCompanyById, selectCompaniesStatus, selectCompaniesError 
} from '../../redux/companies/companies.selectors';
import { startEditCompany } from '../../redux/companies/companies.actions';

const CHECKLIST_QUESTIONS = [

  { 
    index: 0, 
    id: "businessModel",
    prompt: "What does the company do? What is the business model?",
    answerType: "longText",
    category: "Industry",
    qCategory: "Essentials",
  },
  { 
    index: 1, 
    id: "companyStage",
    prompt: "What stage is the business in? (R&D, MVP/Pilot, Revenue) What are the plans/projections?",
    answerType: "longText",
    category: "Timing",
    qCategory: "Essentials",
  },
  { 
    index: 2, 
    id: "companyRevenue",
    prompt: "How much revenue? Type (Transaction or HaaS revenue)? Do they need/want to have HaaS?",
    answerType: "longText",
    category: "Financial",
    qCategory: "Essentials",
  },
  { 
    index: 3, 
    id: "revenueSource",
    prompt: "Where does the revenue come from (or will come from)?(revenue source) Details of buyers and offtakers?",
    answerType: "longText",
    category: "Financial",
    qCategory: "Essentials",
  },
  { 
    index: 4, 
    id: "contractTermination",
    prompt: "Are contracts easily terminable? What is the term of the contract?",
    answerType: "longText",
    category: "Financial",
    qCategory: "Essentials",
  },
  { 
    index: 5, 
    id: "capitalStack",
    prompt: "What are your sources of equity? Any equity raising plans? Do you have any debt?",
    answerType: "longText",
    category: "Financial",
    qCategory: "Essentials",
  },
  { 
    index: 6, 
    id: "companyHardware",
    prompt: "What is the hardware / equipment? Any technical specs (link here)?",
    answerType: "longText",
    category: "Industry",
    qCategory: "Essentials",
  },
  { 
    index: 7, 
    id: "otherNotes",
    prompt: "Any other notes or comments?",
    answerType: "longText",
    category: "Other",
    qCategory: "Essentials",
  },
  
];

class LeadChecklist extends React.Component {
  state = {}

  renderQuestions = () => {
    const companyData = this.getCompanyData();
    if (!companyData) return null;

    return (
      <>
      {CHECKLIST_QUESTIONS.map(question => {
        const inputType = question.answerType;
        const leadChecklist = companyData.leadChecklist;
        const dbVal = leadChecklist ? leadChecklist[question.id] : "";
        const formValue = this.state[question.id];
        const currentVal = (formValue !== undefined) ? formValue : dbVal
        switch (inputType) {
          case "shortText":
            return <Form.Field key={question.index}>
                <label>{question.prompt}</label>
                <Form.Input name={question.id} onChange={this.handleChange} value={currentVal} />
              </Form.Field>;
          case "longText":
            return <Form.Field key={question.index}>
                <label>{question.prompt}</label>
                <Form.TextArea name={question.id} onChange={this.handleChange} value={currentVal}  />
              </Form.Field>;
          default:
            return <p key={question.index}>Unknown Input Type</p>;
        }
      })}
      </>
    );
  }

  getCompanyData = () => {
    const companyId = this.props.match.params.id;
    const { companies } = this.props;
    if (!companyId || !companies) return null;
    const companyData = selectCompanyById(companies, companyId);
    if (!companyData) return null;
    return companyData;
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Retrieve company data first
    const companyData = this.getCompanyData();
    if (!companyData) return null;

    // Append lead form data
    const checklistIds = CHECKLIST_QUESTIONS.map(q => q.id);
    const leadChecklist = companyData.leadChecklist;
    const formValues = {};
    checklistIds.forEach( id => {
      const dbVal = leadChecklist ? leadChecklist[id] : "";
      formValues[id] = (this.state[id] !== undefined ) ? this.state[id] || "" : dbVal || "";
    });

    // Update company data with lead form inputs
    const { editCompany } = this.props;
    await editCompany(companyData.id, { leadChecklist: formValues });
  }
  
  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    const { companyStatus, companyError } = this.props;

    return(
      <>
        <Segment.Group>
          <Segment onClick={() => this.setState({ isOpen: !this.state.isOpen })} style={{ cursor: 'pointer'}} >
            <Header size="tiny" className="tightHeader">
              <Icon name="tasks" />
              Company Profile
            </Header>
            <Icon 
              size="large"
              name="dropdown" 
              style={{ cursor: "pointer", position: "absolute", top: 0, right: 0, margin: ".75rem .5rem 0 0" }} 
              rotated={!this.state.isOpen ? "clockwise" : null}
            />
          </Segment>
          {this.state.isOpen && (
            <>
            <Segment color="purple">

              <Form size="large" className="outlineForm" style={{ marginBottom: '16px' }} onSubmit={null}
                loading={companyStatus === "loading"}
              >
                {this.renderQuestions()}
              </Form>

              {companyStatus === "failed" ? (
                <Message error>
                  <Message.Header>Unable to Save Changes</Message.Header>
                  { companyError }
                </Message>
              ) : null}

            </Segment>
            <Segment secondary>
              <Button size="small" primary icon="save" labelPosition="left" content="Save Changes" 
                onClick={this.handleSubmit} 
                disabled={companyStatus === "loading"} 
                loading={companyStatus === "loading"}
              />
            </Segment>
            </>
          )}
        </Segment.Group>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  companies: selectCurrentCompanies,
  companyStatus: selectCompaniesStatus,
  companyError: selectCompaniesError,
});

const mapDispatchToProps = dispatch => ({
  editCompany: (companyId, companyInfo) => dispatch(startEditCompany(companyId, companyInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadChecklist);