import {Button, Input, Popup, Segment} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {LineItemsStore} from "../ps-models/lineitems-store";
import {TimedCalculatedLineItem} from "../ps-models/line-items";
import Editor from "react-simple-code-editor";
//@ts-ignore
import {highlight, languages} from "prismjs/components/prism-core";
import {InputProps} from "semantic-ui-react/dist/commonjs/elements/Input/Input";

interface InputWithTooltipProps {
    tooltipCondition: boolean;
    tooltipContent: () => JSX.Element;
    valueChanged: boolean
    onRestore: () => void;
}

export function InputWithTooltip(props: InputWithTooltipProps & Omit<InputProps, 'input' | 'action'>) {
    const {tooltipCondition, valueChanged, onRestore, tooltipContent, ...inputProps} = props;

    const inputComponent = (
        <Input
            {...inputProps} input={tooltipCondition ? {style: {"background-color": '#ffefb8', "color": "#7A8697"}} : {}}
            action={valueChanged && {
                content: 'Restore',
                onClick: onRestore
            }}
        />
    );

    if (tooltipCondition) {
        return (
            <Popup
                content={tooltipContent()}
                trigger={inputComponent}
                style={{width: '800px'}}
            />
        );
    }

    return inputComponent;
}

export function CodeEditorWithTooltip(props: InputWithTooltipProps & Omit<InputProps, 'input' | 'action'>) {
    const {tooltipCondition, valueChanged, onRestore, tooltipContent, ...inputProps} = props;

    const inputComponent = (
        <Input
            {...inputProps} input={tooltipCondition ? {style: {"background-color": '#ffefb8', "color": "#7A8697"}} : {}}
            action={valueChanged && {
                content: 'Restore',
                onClick: onRestore
            }}
        />
    );

    if (tooltipCondition) {
        return (
            <Popup
                content={tooltipContent()}
                trigger={inputComponent}
                style={{width: '800px'}}
            />
        );
    }

    return inputComponent;
}

export function CodeEditor({store, lineItemName}:{store: LineItemsStore, lineItemName: string}) {
    let [code, setCode] = useState("");
    let lineItem = store.getDataSet().getLineItem(lineItemName) as TimedCalculatedLineItem;

    useEffect(() => {
        setCode( typeof lineItem.fn === 'string' ? lineItem.fn : "---Native---");
    }, [lineItemName]);

    const handleSaveCode = () => {
        if(!lineItem) return;

        lineItem.clearErrors();

        store.getDataSet().addLineItem(
            (lineItem as TimedCalculatedLineItem).withCode(code)
        );
    }

    return <Segment>
        <Editor
            value={code}
            onValueChange={code => setCode(code)}
            highlight={code => highlight(code, languages.js)}
            padding={10}
            required
            style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                border: "1px solid #ccc",
                backgroundColor: "white",
                margin: "10px 0"
            }}
        />
        <Button size="tiny" type="submit" content="Apply" onClick={handleSaveCode} />
    </Segment>
}