import {Button, Modal} from "semantic-ui-react";
import {entries} from "../ps-models";
import React from "react";

export type ConfirmChanges = Record<string, {header: JSX.Element, messages: JSX.Element[]}>

export function ConfirmSavePopup({changes, onClose, onSave}: {changes: ConfirmChanges | undefined, onClose: () => void, onSave: () => void | Promise<void>}) {
    return <Modal
      open={changes !== undefined}
      onClose={onClose}
      size="tiny"
    >
        <Modal.Header>Confirm Save</Modal.Header>
        <Modal.Content>
        <p>Are you sure you want to save the changes?</p>
        {changes && entries(changes).map(([key, {header, messages}]) => <div key={key}>
            {header}
            <ul>
                {messages.map((message, i) => <li key={i}>{message}</li>)}
            </ul>
        </div>)}
        </Modal.Content>
        <Modal.Actions>
            <Button color="green" onClick={async () => {
              onClose()
              await onSave()
            }}>
              Save
            </Button>
            <Button color="blue" onClick={() => onClose()}>
              Close
            </Button>
        </Modal.Actions>
    </Modal>
}
