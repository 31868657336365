import {LineItemsStore, PersistenceQuery} from "../../ps-models/lineitems-store";
import {StoreQueryFlat} from "../../ps-models/lineitems-store/StoreQueryFlat";
import {QueryFieldFilterEditor} from "./common-components/QueryFieldFilterEditor";
import {QueryLineItemNamesSelector} from "./common-components/QueryLineItemNamesEditor";
import {QueryLineItemNonMaterializedItemsEditor} from "./common-components/QueryLineItemNonMaterializedItemsEditor";

const queryBuilderConfigurators = ["names", "fields", "non-materialized"] as const;
type QueryEditorCapabilities = typeof queryBuilderConfigurators[number];
export function QueryEditor<T extends PersistenceQuery | StoreQueryFlat>({query, onQueryUpdate, capabilities, store}: {query: T, store?: LineItemsStore, onQueryUpdate: (updatedQuery: T) =>void, capabilities?: QueryEditorCapabilities[]}) {
    return <>
      {(capabilities ? capabilities.includes("fields") : true) && <QueryFieldFilterEditor store={store}  query={query} onQueryUpdate={onQueryUpdate}/>}
      {(capabilities ? capabilities.includes("names") : true) && <QueryLineItemNamesSelector store={store} query={query} onQueryUpdate={onQueryUpdate}/>}
      {(capabilities ? capabilities.includes("non-materialized") : true) && <QueryLineItemNonMaterializedItemsEditor store={store} query={query} onQueryUpdate={onQueryUpdate}/>}
    </>
}
