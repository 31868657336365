import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AUTHENTICATION_PATHS } from "../../constants";
import { authStorage } from "../../platform/auth";
import { getCompanyHome } from "../../platform/assets-management/config";

function Dashboard() {
  const history = useHistory();
  const company = authStorage.getCompany();
  const redirectionUrl = getCompanyHome(company.id,
    company.amProjectId ?? 'DEMO')
  
  useEffect(() => {
    // This is reqd. when hitting authentication related urls like login, signup etc. when user is already authenticated
    if (
      Object.values(AUTHENTICATION_PATHS).includes(history.location.pathname)
    ) {
      history.replace(redirectionUrl);
    }
  }, [history.location.pathname]);

  return <></>;
}

export default Dashboard;