import {LineItem, ParameterLineItem, ValueType} from "../line-items";
import {compareNormalized, entries, normalizeString} from "../line-item-utils/coding.utils";
import {
  formatDateByTimeUnit, TimeIndex,
  TimeUnitView
} from "../Time.model";
import {
  AggregatorMethod,
  DEFAULT_AGGREGATOR_MAP,
  runAggregatorMethod,
  runAggregator,
  AggregatorMap
} from "../line-item-utils/aggregators";
import {LineItemDataSet} from "../LineItemDataSet";
import {truncateTime} from "../TimeGranularity";
import {TimeRange, TimeUnits} from "../Time.types";
import {LineItemsStoreExecution} from "./LineItemsStoreExecution.model";
import {DateRangeType} from "../../ps-types";


export interface LineItemValuesGroupItem {
  lineItem: LineItem
  value: ValueType
  time: TimeUnitView
}

export class StoreQuery {

  private constructor(
    public readonly filter: (lineItem: LineItem) => (ds: LineItemDataSet, execution?: LineItemsStoreExecution) => boolean,
    public timeRange?: DateRangeType,
    public timeGroupBy: (t: TimeUnitView) => string = t => t.fDate,
    public timeColumnReducer: (t: TimeUnitView[]) => TimeUnitView = t => t[0],
    public lineItemValuesReducer: (values: LineItemValuesGroupItem[], lineItem: LineItem) => ValueType = values => values[0].value,
    public timeIndex?: TimeIndex
  ) {}


  //TODO: Do not use, this is being deprecated
  aggregateOverTimeRange(from: Date, to: Date, aggregatorMap: AggregatorMap = DEFAULT_AGGREGATOR_MAP) {
      return new StoreQuery(
        this.filter,
        {from, to},
        (t) => "0",
           t => t[0],
             (values, li) => {
                return runAggregator(li, values.map(v => v.value), aggregatorMap)
             },
        this.timeIndex
      )
  }

  inTimeRange(from: Date, to: Date) {
    return new StoreQuery(
      this.filter,
      {from, to},
      this.timeGroupBy,
      this.timeColumnReducer,
      this.lineItemValuesReducer,
      this.timeIndex
    )
  }

  monthly(aggregationMap: AggregatorMap = DEFAULT_AGGREGATOR_MAP) {
    return this.timeGrouping('months', aggregationMap)
  }

  yearly(aggregationMap: AggregatorMap = DEFAULT_AGGREGATOR_MAP) {
    return this.timeGrouping('years', aggregationMap)
  }

  //Todo: Use aggregator functions instead of strings
  private timeGrouping(timeUnit: TimeUnits, aggregationMap: AggregatorMap) {
    return new StoreQuery(
      this.filter, this.timeRange,
      (t) => truncateTime(timeUnit, t.date).toString(),
      timeSlots => ({fDate: formatDateByTimeUnit(timeSlots[0].date, timeUnit), time: timeSlots[0].time, date: timeSlots[0].date}),
      (values, lineItem) => {
        return runAggregator(lineItem, values.map(v => v.value), aggregationMap)
      }
    )
  }

  static all() {
    return new StoreQuery(li => () => true);
  }

  static byName(name: string) {
    return new StoreQuery(li => ()=> compareNormalized(li.name, name));
  }

  static byNames(names: string[], useCanonicalName: boolean = false) {
    return new StoreQuery(li => ()=> names.map(normalizeString).includes(normalizeString(
       useCanonicalName ? li.canonicalName : li.name
    )));
  }

  // static byStoreIds(storeIds: string[]) {
  //   return new StoreQuery(li => () => {
  //     let storeId = li.fields.getFieldStr("store_sourceName")
  //     return storeIds.map(normalizeString).includes(normalizeString(storeId));
  //   });
  // }

  static byField(field: string, value: string) {
    return new StoreQuery(li => (ds, ex)=>
      ex ? ex.hasFieldValue(li.name, field, value) : li.fields.hasFieldValue(field, value)
    );
  }

  static withField(field: string) {
    return new StoreQuery(li => ()=> li.fields.hasField(field));
  }

  or(b: StoreQuery) {
    return new StoreQuery(li => (ds) => this.filter(li)(ds) || b.filter(li)(ds),
      this.timeRange,
      this.timeGroupBy, this.timeColumnReducer, this.lineItemValuesReducer, this.timeIndex)
  }

  and(b: StoreQuery) {
    return new StoreQuery(li => (ds) => this.filter(li)(ds) && b.filter(li)(ds),
        this.timeRange,
        this.timeGroupBy, this.timeColumnReducer, this.lineItemValuesReducer, this.timeIndex)
  }

  not() {
    return new StoreQuery(li => (ds) => !this.filter(li)(ds),
      this.timeRange,
      this.timeGroupBy, this.timeColumnReducer, this.lineItemValuesReducer, this.timeIndex)
  }

  group() {
    return new StoreQuery(this.filter, this.timeRange, this.timeGroupBy, this.timeColumnReducer, this.lineItemValuesReducer, this.timeIndex)
  }

  withTimeIndex(timeIndex: TimeIndex) {
    return new StoreQuery(this.filter, this.timeRange, this.timeGroupBy, this.timeColumnReducer, this.lineItemValuesReducer, timeIndex);
  }

  static byParamValue(name: string, value: string) {
    return new StoreQuery( (li) => (ds ) => {
        let sourceId = li.fields.getFieldStr("store_sourceName");

        if (!sourceId || compareNormalized(li.name, `${sourceId}-${name}`)) {
          return false;
        }

        return compareNormalized(
          (ds.getLineItem( `${sourceId}-${name}`) as ParameterLineItem)?.getValue().value.toString(),
          value
        );
    });
  }
}

