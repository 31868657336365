import {Container, Dimmer, Divider, Header, Image} from "semantic-ui-react";
import {SITE_URL} from "../core";
import {Link, useHistory} from "react-router-dom";
import React, { useEffect } from "react";
import {LoginStorage} from "./LoginStorage";
import {AUTHENTICATION_PATHS} from "../../constants";

export function NotSignedInPage() {
    const history = useHistory();
    useEffect(() => {
        const lastPersistedRedirectionURL = LoginStorage.getRedirectionURL("")
        if(lastPersistedRedirectionURL === AUTHENTICATION_PATHS.logout){
            history.push(AUTHENTICATION_PATHS.login);
        } else {
            LoginStorage.setRedirectionURL(window.location.pathname)
        }
    }, [])
    return <Container textAlign="center">
        <Dimmer active page inverted>
            <a href={SITE_URL}>
                <Header as="h1">
                    <Image
                        src={"/logos/Perl-Logo-Wide-Black-2x.png"}
                        style={{
                            margin: 0,
                            height: "auto",
                            width: "auto",
                            maxWidth: "250px",
                        }}
                    />
                </Header>
            </a>
            <Header as="h3">You aren't currently signed in.</Header>
            <Divider hidden/>
            <Header as="h5">
                <Link to="/signup">Create a new account</Link> or{" "}
                <Link to="/login">Login here</Link>
            </Header>
        </Dimmer>
    </Container>
}