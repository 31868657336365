import ActionTypes from './scenario.types';

const INITIAL_STATE = {
  status: 'idle', // enum: 'idle' | 'loading' | 'success' | 'failed'
  error: null,

  scenarios: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_SCENARIOS_FAILURE:
    case ActionTypes.DELETE_SCENARIO_FAILURE:
      return {
        ...state,
        status: 'failed'
      }
    case ActionTypes.GET_SCENARIOS_START:
    case ActionTypes.DELETE_SCENARIO_START:
      return {
        ...state,
        status: 'loading'
      }
    case ActionTypes.DELETE_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarios: state.scenarios.filter(s => s.id !== action.payload),
        status: 'idle'
      };
    case ActionTypes.GET_SCENARIOS_SUCCESS:
      return {
        ...state,
        scenarios: action.payload,
        status: 'idle'
      };
    default:
      return state;
  }
}

export default reducer;