import {WidgetWrapperSection} from "./commons";
import {ClosableSection} from "../../../../ClosableSection";
import {Header} from "semantic-ui-react";
import {ComponentConfig, DropZone} from "@measured/puck";
import {v4 as uuidv4} from "uuid";

type ColumnComponentConfigProps = {
    config:{
        distribution: "auto" | "manual";
        bordered?:boolean;
        columns: {
            span?: number;
            title?: string;
            collapsible?: boolean;
        }[];
    },
    machineName: string;
};

export const ColumnsWidget: ComponentConfig<ColumnComponentConfigProps> = {
    fields: {
        machineName: {type: "text", label: "Machine Name"},
        config:{
            type:"object",
            objectFields: {
                distribution: {
                    type: "radio",
                    options: [
                        {
                            value: "auto",
                            label: "Auto",
                        },
                        {
                            value: "manual",
                            label: "Manual",
                        },
                    ],
                },
                bordered: {
                    label: "Bordered",
                    type: "radio",
                    options: [{value: true, label: "True"}, {value: false, label: "False"}]
                },
                columns: {
                    type: "array",
                    getItemSummary: (col, id = -1) =>
                        `Column ${id + 1}, span ${
                            col.span ? Math.max(Math.min(col.span, 12), 1) : "auto"
                        }${col.title ? ", titled": ''}${col.collapsible ? ", collapsible": ''}`,
                    arrayFields: {
                        span: {
                            label: "Span (1-12)",
                            type: "number",
                            min: 0,
                            max: 12,
                        },
                        title: {
                            label: "Title",
                            type: "text",
                        },
                        collapsible: {
                            label: "Collapsible",
                            type: "select",
                            options: [{value: false, label: "False"}, {value: true, label: "True"}],
                            // type: "radio",
                            // options: [{value: true, label: "True"}, {value: false, label: "False"}]
                        },
                    },
                }},
            },
    },
    defaultProps: {
        machineName: `ColumnsWidget-${uuidv4().substring(0, 4)}`,
        config: {
            distribution: "auto",
            columns: [{}, {}],
            bordered: false,
        },
    },
    render: (props: ColumnComponentConfigProps) => {
        return <WidgetWrapperSection><div
            className={'widget-columns'}
            style={{
                gridTemplateColumns:
                    props.config.distribution === "manual"
                        ? "repeat(12, 1fr)"
                        : `repeat(${props.config.columns.length}, 1fr)`,
                ...(props.config?.bordered ? {
                    border: "2px solid",
                    margin: '0.1rem 0',
                    borderRadius: "0.28571429rem !important"}: {})
            }}
        >
            {props.config.columns.map(({ span, title, collapsible }, idx) => {
                if(collapsible){
                    return <ClosableSection
                        key={idx}
                        opened={true}
                        title={<><strong style={{fontSize: "16px"}}>
                            {title ?? ""}
                        </strong>
                        </>}
                        level="h3"
                    >
                        <div
                            key={idx}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gridColumn:
                                    span && props.config.distribution === "manual"
                                        ? `span ${Math.max(Math.min(span, 12), 1)}`
                                        : "",
                            }}
                        >
                            <DropZone
                                zone={`column-${idx}`}
                                // disallow={["Hero", "Logos", "Stats"]}
                            />
                        </div>
                    </ClosableSection>
                } else {
                    return <div
                        key={idx}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gridColumn:
                                span && props.config.distribution === "manual"
                                    ? `span ${Math.max(Math.min(span, 12), 1)}`
                                    : "",
                        }}
                    >
                        {title !==undefined && <Header as="h2" color="purple">{title}</Header>}
                        <DropZone
                            zone={`column-${idx}`}
                        />
                    </div>
                }
            })}
        </div></WidgetWrapperSection>
    },
};