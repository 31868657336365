import React from "react";
import { Header, Icon } from 'semantic-ui-react';

function CompaniesHeader() {
  return (
    <Header as='h2' color="purple" size="huge">
      <Icon className="city" size="small" />
      <Header.Content>
        Companies
        <Header.Subheader>Create and manage companies and thier associated data and documents</Header.Subheader>
      </Header.Content>
    </Header>
  );
}

export default CompaniesHeader;