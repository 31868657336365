import React, {useState} from "react";
import {Button, Form, Modal} from "semantic-ui-react";
import {UpdateEntityProps} from "./types";
import {Field} from "./common/Field";
import {assocPath, path} from "ramda"
export function UpdateEntity<F extends UpdateEntityProps>({
                                                         fields,
                                                         entity,
                                                         entityName,
                                                         options,
                                                         onEntityUpdated,
                                                         updateEntity,
                                                         customIconProps,
                                                         onFormDataChange
                                                          }: UpdateEntityProps) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<Record<string, any>>(Object.fromEntries(
      Object.keys(entity).map(key => [key, path(key.split("."), entity)])
  ));
  options = {
    buttonText: `Update`,
    modalTitle: `Edit ${entityName}`,
    buttonDisabled: options?.buttonDisabled || false,
    ...options};

  const handleUpdate = () => {
    updateEntity(formData)
      .then((data: any) => {
        onEntityUpdated && onEntityUpdated(data);
      });

    setOpen(false);
  }

    const onFieldValueChange = (key: string, value: any) => {
        onFormDataChange?.(assocPath(key.split("."), value, formData));
        setFormData( f => assocPath(key.split("."), value, f))
    }

  return <>
    <Button disabled={options.buttonDisabled} floated={"right"} size="small" icon={customIconProps ?? "edit"} onClick={() => setOpen(true)}/>

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header>{options.modalTitle}</Modal.Header>
        <Modal.Content>
          <Form
              onSubmit={e => {
            e.preventDefault();
          }}>
          {Object.values(fields).map(fieldDef =>
            <Field fieldDef={fieldDef} entityName={entityName}
                   key={fieldDef.key}
                   value={path(fieldDef.key.split("."), formData)}
                   onChange={ value => { onFieldValueChange(fieldDef.key, value); }}
            />
          )}</Form>

        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button
            primary
            type='submit'
            content={options.buttonText}
            labelPosition='right'
            icon='checkmark'
            onClick={() => handleUpdate()}
          />

        </Modal.Actions>

      </Modal>
  </>
}