import {useCompanyId} from "../../../core";
import {Image} from "semantic-ui-react";
import React from "react";

export function CompanyLogo() {
  let  src = '/nrnlogo.svg'
  let  style = {height: "60px"};
  return <div>
    <Image style={style} src={src}/>
  </div>
}