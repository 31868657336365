import {DashboardConfigService, useDashboardService} from "./DashboardConfigService";
import {Button, Icon, Modal} from "semantic-ui-react";
import React, { useMemo, useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import type { Identifier, XYCoord } from 'dnd-core'
import {usePopup} from "../../ui/popup/Popup";
import { BuilderContext } from "./WidgetRegistry";
import {useOnConfigChange} from "./widgets/commons";
import {DashboardConfig} from "./DashboardConfig";
import {WidgetConfig} from "./WidgetConfig";


export type BoxItem = {
  widgetId: string
  widgetIndex: number
}

function moveCard(widgetA: string, widgetB: string, ds: DashboardConfigService, upOrDown: number = 1) {
  let widget = ds.getConfig().getByID(widgetA) as WidgetConfig;
  let widget2 = ds.getConfig().getByID(widgetB) as WidgetConfig;

  if (!widget || !widget2) {
    return;
  }

  let index = widget.config?.containerData?.widgetIndex || 0;
  let index2 = widget2.config?.containerData?.widgetIndex || 0;

  if(index === index2) {
    index2 += upOrDown;
  }

  widget.mergeConfig({containerData: {widgetIndex: index2}});
  widget2.mergeConfig({containerData: {widgetIndex: index}});

  (ds.getConfig() as DashboardConfig).addWidget(widget);
  (ds.getConfig() as DashboardConfig).addWidget(widget2);
}

function moveCardOne(widgetId: string, ds: DashboardConfigService, upDown: number) {
  let widget = ds.getConfig().getByID(widgetId) as WidgetConfig;
  if (!widget) {
    return;
  }

  let widgets = (ds.getConfig() as DashboardConfig).getByParentId(widget.parent);

  widgets.sort((a, b) => {
    let indexA = a.config?.containerData?.widgetIndex || 0;
    let indexB = b.config?.containerData?.widgetIndex || 0;
    return indexA - indexB;
  });

  let widgetIndex = widgets.findIndex(w => w.id === widget.id);

  let widgetBefore = widgets[widgetIndex + upDown];

  if(!widgetBefore) {
    return;
  }

  moveCard(widget.id, widgetBefore.id, ds, upDown);
}

function WidgetWrapperWithStore({dsService, ready, widgetId, context, lastUpdate}:{dsService: DashboardConfigService, ready: ()=>void,widgetId: string, context: BuilderContext, lastUpdate: number}) {

  let {config} = useOnConfigChange<any>({...context, id: widgetId});

  let W = useMemo(()=>{
    return dsService.renderWidget.bind(dsService)
  }, [config]);

  let machineName = config?.machineName ?? context.appContext.getEntireWidgetConfig(widgetId)?.machineName;
  let wContext = {
    ...context,
    params: (context && context.widgetParams && context.widgetParams[machineName]) || {}
  }

   if(dsService.readOnly) {
     return <div className="widget">
       <W config={config} key={widgetId} widgetId={widgetId} context={wContext} />
     </div>
   } else {
     return <div className="widget">
       <WidgetEditorWrapper dsService={dsService} widgetId={widgetId} context={wContext}
                            ready={ready}
       lastUpdate={lastUpdate}
       />
     </div>
   }

 }

export function WidgetWrapper({widgetId, context}:{widgetId: string, context: BuilderContext}) {
  let {getService, ready, lastUpdate} = useDashboardService();

  let dsService = getService();
  return <WidgetWrapperWithStore
    key={widgetId}
    widgetId={widgetId} context={context} ready={ready} dsService={dsService} lastUpdate={lastUpdate} />
}

function WidgetEditorWrapper({widgetId, context, dsService, ready, lastUpdate}:{widgetId: string, context: BuilderContext, dsService:DashboardConfigService, ready: ()=>void, lastUpdate: number}) {
  let {openPopup, closePopup} = usePopup()

  const ref = useRef<HTMLDivElement>(null)

  let widget = dsService.getConfig().getByID(widgetId) as WidgetConfig;


  const [{ handlerId }, drop] = useDrop<
    BoxItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'box',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: BoxItem, monitor) {

      if (!ref.current) {
        return
      }

      const dragIndex = item.widgetIndex
      const hoverIndex = widget.config?.containerData?.widgetIndex || 0;

      console.info("Hovering", dragIndex, hoverIndex)
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      console.info("Moving widget", dragIndex, hoverIndex)
      // Time to actually perform the action
      //moveCard(dragIndex, hoverIndex)

      console.info("Moving widget", item.widgetId, widget.id)
      moveCard(item.widgetId, widget.id, dsService);


      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      //item.index = hoverIndex
    },
  })


  const [{isDragging}, drag] = useDrag(() => ({
    type: 'box',
    item: {widgetId: widget.id, widgetIndex: widget.config?.containerData?.widgetIndex || 0},
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<BoxItem>()
      if (item && dropResult && dropResult.widgetId) {
        widget.setParentId(dropResult.widgetId);
        widget.mergeConfig({containerData: dropResult});
        //dsService.getConfig().addWidget(widget);
        ready();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }), [lastUpdate])


  const handleSelectedConfig = (id: string) => {
    dsService.selectWidget(id);
  }

  const handleDelete = (id: string) => {
    (dsService.getConfig() as DashboardConfig).deleteWidget(id);
  }

  const handleDeleteAttempt = (id: string) => {
    openPopup(<Modal open={true}>
      <Modal.Content>
        <p>Are you sure you want to delete this widget?</p>
        <Button onClick={() => {
          handleDelete(id);
          closePopup();
        }}>Yes</Button>
        <Button onClick={() => closePopup()}>No</Button>
      </Modal.Content>
    </Modal>)
  }

  const handleMoveOne = (id: string, upDown: number) => {
    moveCardOne(id, dsService, upDown);
    ready();
  }


  drag(drop(ref));


  return <div className="editable-widget">
    <div className="widget-actions"  >
      <span ref={ref} >
          <Button
              className="widget-action"
              size="mini" icon >
            <Icon name='move'/>
          </Button>
    </span>
      <Button
        className="widget-action"
        size="mini" icon onClick={() => handleMoveOne(widgetId, -1)}>
        <Icon name='arrow up' />
      </Button>
      <Button
        className="widget-action"
        size="mini" icon onClick={() => handleMoveOne(widgetId, +1)}>
        <Icon name='arrow down' />
      </Button>

      <Button  className="widget-action" size="mini" icon onClick={() => handleSelectedConfig(widgetId)}>
        <Icon name='configure' />
      </Button>

      <Button className="widget-action" size="mini" icon onClick={() => handleDeleteAttempt(widgetId)}>
        <Icon name='trash' />
      </Button>
    </div>
    <span style={{opacity: isDragging ? 0.5 : 1}}>
      <CachedWidget widgetId={widgetId} dsService={dsService} context={context} />
    </span>
  </div>

}

//Need a cached widget here because the DnD Container re-renders on loading
function CachedWidget({widgetId, dsService, context}:{widgetId: string, dsService: DashboardConfigService,
  context: BuilderContext
  }) {
  let {config} = useOnConfigChange({...context, id: widgetId});

  let WE = useMemo(()=>{
    return dsService.renderWidget.bind(dsService);
  }, [config]);

  return <>
    <WE widgetId={widgetId} config={config} context={context} />
  </>
}