import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Form, Icon, Modal, Segment, Table} from "semantic-ui-react";
import {
    ChartConfig,
    ConfigurableItemKind,
    ConfigItemLineItemAndAggregationMaps,
    MetricConfig,
    NonTimeSeriesTableConfig,
    POSSIBLE_CHART_TYPES_FOR_DEMO,
    ReportConfigItems, ReportConfig,
} from "./constants";
import {
    AggregatorMethod,
    LineItemAggregations,
    LineItemAggregationsValue,
    NumberAggregators,
    StringAggregators
} from "../../ps-models";
import {v4 as uuidv4} from 'uuid';
import ButtonWithListOfOptions from "../../../components/ButtonWithListOfOptions/ButtonWithListOfOptions.component";

interface ReportConfigBuilder {
    lineItemNames: string[];
    onConfigItemsUpdate: (config: ReportConfig) => void;
    reportConfig: ReportConfig
}

const TimeAggregationTechniques = Array.from(new Set([
    ...Object.keys(NumberAggregators), ...Object.keys(StringAggregators)
]));

export const ReportConfigBuilder = ({lineItemNames, onConfigItemsUpdate, reportConfig}: ReportConfigBuilder) => {
    const [open, setOpen] = useState<boolean>(false);
    const [modalType, setModalType] = useState<ConfigurableItemKind>();

    const handleConfigItemKindToConfigureChange = (configItemKind: ConfigurableItemKind) => {
        setModalType(configItemKind);
        setOpen(true);
    };

    return (
        <div style={{width: '100%'}}>
            <ButtonWithListOfOptions
                options={['Metric', 'Chart', 'NonTimeSeriesTable']}
                handleOptionClick={handleConfigItemKindToConfigureChange}
                icon={'plus'}
                content={'Add item to report'}
                size={'medium'}
            />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                closeIcon={true}
            >
                <Modal.Header>
                    Configure {modalType}
                </Modal.Header>
                <Modal.Content>
                    {modalType &&
                        <ConfigurationForm kind={modalType} lineItemNames={lineItemNames} onSave={(metricConfig) => {
                            onConfigItemsUpdate({...reportConfig, items: [...reportConfig.items, metricConfig]})
                            setOpen(false);
                        }}/>}
                </Modal.Content>
            </Modal>
        </div>
    );
};

const ConfigurationForm = ({
                               kind,
                               onSave,
                               lineItemNames
                           }: { kind: ConfigurableItemKind, onSave: (metricConfig: MetricConfig | ChartConfig | NonTimeSeriesTableConfig) => void, lineItemNames: string[] }) => {
    switch (kind) {
        case "Metric":
            return <MetricConfigurationForm onSave={onSave} lineItemNames={lineItemNames}/>
        case "Chart":
            return <ChartConfigurationForm onSave={onSave} lineItemNames={lineItemNames}/>
        case "NonTimeSeriesTable":
            return <NonTimeSeriesTableConfigurationForm onSave={onSave} lineItemNames={lineItemNames}/>
    }
}

const MetricConfigurationForm = ({
                                     onSave,
                                     lineItemNames
                                 }: { onSave: (metricConfig: MetricConfig) => void, lineItemNames: string[] }) => {
    const [title, setTitle] = useState('');
    const [timeAggregationValue, setTimeAggregationValue] = useState<AggregatorMethod>('sum');
    const [lineItemName, setLineItemName] = useState<string>('');

    const handleSubmit = () => {
        // @TODO: Add Validations and remove logs
        console.log({
            title,
            timeAggregationValue,
            lineItemName,
        });
        onSave({id: uuidv4(), title, kind: 'Metric', lineItemName, aggregationTechnique: timeAggregationValue})
    };

    return <Form onSubmit={handleSubmit}>
        <Form.Field>
            <label>Title</label>
            <input
                placeholder='Title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
        </Form.Field>
        <Form.Field>
            <label>Metric Name *</label>
            <Dropdown
                placeholder={'Select Metric'}
                options={lineItemNames?.map((name) => ({key: name, text: name, value: name}))}
                fluid
                search
                selection
                multiple={false}
                value={lineItemName}
                onChange={(e, {value}) => setLineItemName(value as string)}
            />
        </Form.Field>
        <Form.Field>
            <label>Time Aggregation *</label>
            <Dropdown
                options={(TimeAggregationTechniques).map((k: any) => ({key: k, text: k, value: k}))}
                fluid
                search
                selection
                multiple={false}
                defaultValue={'sum'}
                value={timeAggregationValue}
                onChange={(e, {value}) => setTimeAggregationValue(value as AggregatorMethod)}
            />
        </Form.Field>
        <Button type='submit'
                floated={"right"}
                style={{marginBottom: "10px"}}
        >Create</Button>
    </Form>
}

const ChartConfigurationForm = ({
                                    onSave,
                                    lineItemNames
                                }: { onSave: (chartConfig: ChartConfig) => void, lineItemNames: string[] }) => {
    const [title, setTitle] = useState<ChartConfig["title"]>('');
    const [chartType, setChartType] = useState<ChartConfig["type"]>();
    const [lineItemNamesInChart, setLineItemNamesInChart] = useState<ChartConfig["lineItemNames"]>([]);
    const [timeAggregatorMap, setTimeAggregationMap] = useState<ChartConfig["timeAggregatorMap"]>({});
    const [groupAggregatorMap, setGroupAggregationMap] = useState<ChartConfig["groupAggregatorMap"]>({});

    const handleSubmit = () => {
        // @TODO: Add Validations and remove logs
        console.info('why is this being triggered??')
        console.log({
            title,
            chartType,
            timeAggregatorMap,
            lineItemNamesInChart,
            groupAggregatorMap
        });
        onSave({
            title,
            type: chartType,
            groupAggregatorMap,
            timeAggregatorMap,
            lineItemNames: lineItemNamesInChart,
            kind: 'Chart',
            id: uuidv4()
        })
    };

    return <Form onSubmit={handleSubmit}>
        <Form.Field>
            <label>Title *</label>
            <input
                placeholder='Title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
        </Form.Field>
        <Form.Field>
            <label>Type *</label>
            <Dropdown
                options={POSSIBLE_CHART_TYPES_FOR_DEMO.map((name) => ({key: name, text: name, value: name}))}
                fluid
                search
                selection
                multiple={false}
                onChange={(e, data) => setChartType(data.value as any)}
            />
        </Form.Field>
        <LineItemAndAggregatorsInput lineItemNames={lineItemNames} onUpdate={(items) => {
            // @TODO: Debounce this
            const lineItemsToAdd: ConfigItemLineItemAndAggregationMaps["lineItemNames"] = [];
            const timeAggregatorMap: ConfigItemLineItemAndAggregationMaps["timeAggregatorMap"] = {};
            const groupAggregatorMap: ConfigItemLineItemAndAggregationMaps["groupAggregatorMap"] = {};

            for (let item of items) {
                lineItemsToAdd.push(item.lineItemName);
                timeAggregatorMap[item.lineItemName] = item.timeAggregationTechnique;
                groupAggregatorMap[item.lineItemName] = item.groupAggregationTechnique;
            }
            setLineItemNamesInChart(lineItemsToAdd);
            setTimeAggregationMap(timeAggregatorMap);
            setGroupAggregationMap(groupAggregatorMap);
        }}/>
        <Button type='submit' floated={"right"}
                style={{marginBottom: "10px"}}
        >Create</Button>
    </Form>
}

const NonTimeSeriesTableConfigurationForm = ({
                                                 onSave,
                                                 lineItemNames
                                             }: { onSave: (chartConfig: NonTimeSeriesTableConfig) => void, lineItemNames: string[] }) => {
    const [lineItemNamesInTable, setLineItemNamesInTable] = useState<NonTimeSeriesTableConfig["lineItemNames"]>([]);
    const [timeAggregatorMap, setTimeAggregationMap] = useState<NonTimeSeriesTableConfig["timeAggregatorMap"]>({});
    const [groupAggregatorMap, setGroupAggregationMap] = useState<NonTimeSeriesTableConfig["groupAggregatorMap"]>({});

    const handleSubmit = () => {
        // @TODO: Add Validations and remove logs
        console.info('why is this being triggered??')
        console.log({
            timeAggregatorMap,
            lineItemNamesInTable,
            groupAggregatorMap
        });
        onSave({
            groupAggregatorMap,
            timeAggregatorMap,
            lineItemNames: lineItemNamesInTable,
            kind: 'NonTimeSeriesTable',
            id: uuidv4()
        })
    };

    return <Form onSubmit={handleSubmit}>
        <LineItemAndAggregatorsInput lineItemNames={lineItemNames} onUpdate={(items) => {
            // @TODO: Debounce this
            const lineItemsToAdd: ConfigItemLineItemAndAggregationMaps["lineItemNames"] = [];
            const timeAggregatorMap: ConfigItemLineItemAndAggregationMaps["timeAggregatorMap"] = {};
            const groupAggregatorMap: ConfigItemLineItemAndAggregationMaps["groupAggregatorMap"] = {};

            for (let item of items) {
                lineItemsToAdd.push(item.lineItemName);
                timeAggregatorMap[item.lineItemName] = item.timeAggregationTechnique;
                groupAggregatorMap[item.lineItemName] = item.groupAggregationTechnique;
            }
            setLineItemNamesInTable(lineItemsToAdd);
            setTimeAggregationMap(timeAggregatorMap);
            setGroupAggregationMap(groupAggregatorMap);
        }}/>
        <Button type='submit' floated={"right"}
                style={{marginBottom: "10px"}}
        >Create</Button>
    </Form>
}

interface SingleLineItemAndAggregatorMap {
    id: string,
    lineItemName: string,
    timeAggregationTechnique: AggregatorMethod,
    groupAggregationTechnique: LineItemAggregations
}

const defaultSingleLineItemAndAggregatorMap: SingleLineItemAndAggregatorMap = {
    id: uuidv4(),
    lineItemName: '',
    timeAggregationTechnique: 'sum',
    groupAggregationTechnique: 'sumOver'
};

const MAX_ITEMS_THAT_CAN_BE_SELECTED = 4;
const LineItemAndAggregatorsInput = ({
                                         lineItemNames,
                                         onUpdate
                                     }: { lineItemNames: string[], onUpdate: (rows: SingleLineItemAndAggregatorMap[]) => void }) => {
    const [rows, setRows] = useState<SingleLineItemAndAggregatorMap[]>([defaultSingleLineItemAndAggregatorMap]);

    useEffect(() => {
        onUpdate(rows);
    }, [rows])
    const handleAddRow = () => {
        setRows([...rows, {...defaultSingleLineItemAndAggregatorMap, id: uuidv4()}]);
    };

    const handleDeleteRow = (id: string) => {
        setRows((prev) => prev.filter((r) => r.id !== id))
    }

    const handleInputChange = (rowId: string, type: keyof Omit<SingleLineItemAndAggregatorMap, 'id'>, value: string | AggregatorMethod | LineItemAggregations) => {
        const newRows = [...rows];
        const changedRowIndex = newRows.findIndex((r) => r.id === rowId)
        if (changedRowIndex !== -1) {
            newRows[changedRowIndex] = {...newRows[changedRowIndex], [type]: value};
        } else {
            console.warn('The element should have existed', rowId)
        }
        setRows(newRows);
    };

    return <div style={{width: '100%', margin: "0 0 1em"}}>
        <Segment>
            <span style={{color: '#4e4ea3'}}>{`To get a clear and crisp analytical view, we recommend and limit selection of upto ${MAX_ITEMS_THAT_CAN_BE_SELECTED} items:`}</span>
            <Table basic='very' celled collapsing style={{width: "100%", marginTop: 0}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Metric</Table.HeaderCell>
                        <Table.HeaderCell>Aggregation Technique (Along Time)</Table.HeaderCell>
                        <Table.HeaderCell>Aggregation Technique (Across Group)</Table.HeaderCell>
                        <Table.HeaderCell>
                            <Button icon size="mini" type={"button"} disabled={rows.length ===4} onClick={handleAddRow}>
                                <Icon name='plus'/>
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row) => (
                        <Table.Row key={row.id}>
                            <Table.Cell style={{width: "100%"}}>
                                <Dropdown
                                    options={lineItemNames?.map((name) => ({key: name, text: name, value: name}))}
                                    fluid
                                    search
                                    selection
                                    multiple={false}
                                    onChange={(e, data) => handleInputChange(row.id, 'lineItemName', data.value as any)}
                                />
                            </Table.Cell>
                            <Table.Cell style={{width: "100%"}}>
                                <Dropdown
                                    options={(TimeAggregationTechniques).map((k: any) => ({key: k, text: k, value: k}))}
                                    fluid
                                    search
                                    selection
                                    multiple={false}
                                    defaultValue={'sum'}
                                    onChange={(e, data) => handleInputChange(row.id, 'timeAggregationTechnique', data.value as any)}
                                />
                            </Table.Cell>
                            <Table.Cell style={{width: "100%"}}>
                                <Dropdown
                                    options={(LineItemAggregationsValue).map((k: any) => ({key: k, text: k, value: k}))}
                                    fluid
                                    search
                                    selection
                                    multiple={false}
                                    defaultValue={'sumOver'}
                                    onChange={(e, data) => handleInputChange(row.id, 'groupAggregationTechnique', data.value as any)}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Button size="mini" icon
                                        onClick={() => handleDeleteRow(row.id)}
                                >
                                    <Icon name='trash'/>
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    </div>
}