import {LineItemsStore, PersistenceQuery, pQuery} from "../../../ps-models/lineitems-store";
import {StoreQueryFlat} from "../../../ps-models/lineitems-store/StoreQueryFlat";
import React, {useEffect, useState} from "react";
import {Button, Icon, Segment} from "semantic-ui-react";
import {getAmProjectConfig} from "../../../ps-models";
import {buildLineItemsStorePersistence} from "../../../lineitems-store/RestLineItemsStore.persistence";
import {authStorage} from "../../../auth";
import {LineItemNamesSelector} from "../LineItemNamesSelector";

export function QueryLineItemNamesPicker<T extends PersistenceQuery | StoreQueryFlat>({query, store, onQueryUpdate, sectionTitle, initiallySelectedLineItems, onItemSelection}: {query: T,store?: LineItemsStore, onQueryUpdate: (updatedQuery: T) =>void, sectionTitle: string, initiallySelectedLineItems: string[], onItemSelection:(localQuery:T,items:string[])=>void}) {
    const [localQuery, setLocalQuery] = useState<T>(query);
    let [selectedLineItems, setSelectedLineItems] = useState<string[]>(()=>initiallySelectedLineItems);

    let [storeWithLineItems, setStoreWithLineItems] = useState<LineItemsStore | undefined>(store);

    const company = authStorage.getCompany();
    const { collection } = getAmProjectConfig(company);

    useEffect(()=> {
        if(!storeWithLineItems) {
            let persistence = buildLineItemsStorePersistence();
            persistence.query(
              collection,
              pQuery().lineItemsMetadata()
            ).then((store) => {
                setStoreWithLineItems(store);
            });
        }
    }, [store]);

    useEffect(()=> {
        handleLineItemsChange();
    }, [selectedLineItems])

    const handleLineItemsChange = () => {
        let lineItemsToSelect = selectedLineItems;
        onItemSelection(localQuery, lineItemsToSelect)
        // localQuery[queryMethodToInvokeOnSelection](lineItemsToSelect);
        // localQuery.withNonMaterializedCalculatedLineItems(lineItemsToSelect);
        setLocalQuery(localQuery);
        onQueryUpdate(localQuery);
    }

    const onSelectedLineItemsUpdated = (liNames: string[]) => {
        setSelectedLineItems(liNames);
    }


    return <Segment>
            <h5>{sectionTitle}</h5>
            <LineItemNamesSelector store={storeWithLineItems}
                                   selectedLineItems={selectedLineItems}
                                   onSelected={onSelectedLineItemsUpdated} />
        </Segment>
}