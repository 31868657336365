import React from 'react';
import { Segment, Container, Image, Header, Grid, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {SITE_URL} from "../../platform/core";

const FooterMenu = () => (
  <Segment inverted vertical style={{ padding: '5em 0em' }} className="footer-menu">
    <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={6} textAlign="center">
            <a  href={SITE_URL}>
              <Header as='h4' inverted>
                <Image src={"/logos/Perl-Logo-Wide-White-2x.png"} style={{ margin: 0, width: 'auto', height: 'auto', maxWidth: '150px' }} />
              </Header>
            </a>
            <p>
              © Perl Street {new Date().getFullYear()}
            </p>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header inverted as='h4' content='Our Company' />
            <List link inverted>
              <List.Item as={Link} to="/">Home</List.Item>
              <List.Item as={Link} to="/about">About Us</List.Item>
              <List.Item as={Link} to="/how-it-works">How it Works</List.Item>
              <a role="listitem" className='item' href="https://www.ycombinator.com/companies/perl-street" rel='noopener noreferrer' target="_blank">Careers</a>
            </List>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header inverted as='h4' content='Learn More' />
            <List link inverted>
              <List.Item as={Link} to="/faqs">FAQs</List.Item>
              <List.Item as={Link} to="/about">Contact Us</List.Item>
              <List.Item as={Link} to="/haas">HaaS Launch Program</List.Item>
              <List.Item as={Link} to="/how-it-works">Why Debt for Hardware Matters</List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Segment>
);

export default FooterMenu;