import {
    ChangeEvent,
    Dispatch, FormEvent,
    SetStateAction, SyntheticEvent,
    useEffect, useMemo, useRef,
    useState
} from 'react';
import PreparedFormField from "../PreparedForm/PreparedFormField";
import {Divider, InputOnChangeData, Popup, TextAreaProps} from "semantic-ui-react";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import {FormInputTypes} from "../../platform/ps-types";
import {FormFieldErrorType, InputFieldDef} from "../PreparedForm/PreparedFormInputCommons";

function PDFInputField<T extends string,>(props: {
    name: T;
    fieldDef: InputFieldDef;
    error: null | any;
    value: FormInputTypes;
    setErrors: Dispatch<SetStateAction<Record<T, FormFieldErrorType>>>;
    setInputValues: Dispatch<SetStateAction<Record<T, FormInputTypes>>>;
    onFieldFocus: ()=> void;
}
) {
    const {
        name,
            fieldDef,
            error,
            value,
            setErrors,
            setInputValues,
            onFieldFocus
    } = props;
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const onChange = (e: ChangeEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>, {
        name,
        value
    }: InputOnChangeData | TextAreaProps) => {
        setErrors((prevState) => ({
            ...prevState,
            [name]: null
        }));
        setInputValues((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const onDropDownInputChange = (event: SyntheticEvent<HTMLElement>, data: DropdownProps, fieldName: T) => {
        setErrors((prevState) => ({
            ...prevState,
            [fieldName]: null
        }));
        if (data.multiple) {
            setInputValues((prevState) => ({...(prevState), [fieldName]: data.value as string[]}))
        } else {
            setInputValues((prevState) => ({...(prevState), [fieldName]: data.value as string}))
        }
    }

    const onListItemInputChange = (value: string [], fieldName: T) => {
        setErrors((prevState) => ({
            ...prevState,
            [fieldName]: null
        }));
        setInputValues((prevState) => ({...prevState, [fieldName]: value}))
    }

    const onBlur = (fieldName: T) => {
        switchToReadMode();
        const errorOnField = fieldDef.customFieldValidator(value as never);
        if (errorOnField) {
            setErrors((prevState) => ({
                ...prevState,
                [fieldName]: errorOnField
            }));
        }

    }

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    const switchToEditMode = () => {
        setIsEditing(true);
    };

    const switchToReadMode = () => {
        setIsEditing(false);
    }
    // @TODO: Implement this
    // const handleFieldFocus = (e: React.FocusEvent<HTMLDivElement>)=> {
    //     console.info('weird', e.type)
    //     // onFieldFocus();
    //     switchToEditMode();
    // }

    const {label, placeholder, ...fieldDefToPass} = fieldDef;

    let fieldDoesNotHaveAValue = verifyIfFieldDoesNotHaveAValue(value);
    const currentValueOfField = value;

    const contentToDisplay = useMemo(()=> {
        switch(fieldDef.customFieldType){
            case "listItem":
                if(Array.isArray(currentValueOfField) && currentValueOfField.length>0){
                    return <ul>{
                        currentValueOfField.map((it, index)=>{
                            return <li key={index}>{it}</li>
                        })}</ul>
                } else {
                    return currentValueOfField;
                }
            case "dropdown":
                if(fieldDef.multiple === true && Array.isArray(currentValueOfField)){
                    return currentValueOfField.join(', ')
                } else {
                    return currentValueOfField;
                }
            default:
                return currentValueOfField;
        }
    }, [currentValueOfField, fieldDef])

    const leadingElement = fieldDef.customFieldType === 'listItem' ? <br/> : <>&nbsp;</>
    const trailingElement = fieldDef.customFieldType === 'listItem' ? <></> : <>&nbsp;</>

    return <>
        {leadingElement}
        <Popup
            openOnTriggerFocus={true}
            closeOnTriggerBlur={true}
        trigger={isEditing ? (<PreparedFormField
            error={null}
            fieldDef={{
                ...fieldDefToPass,
                placeholder:'',
                label:"",
                input: {
                    className: 'input_in_pdf',
                    ref: inputRef,
                }
            }}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onDropDownInputChange={onDropDownInputChange}
            onListItemInputChange={onListItemInputChange}
        />): (<div
            data-placeholder={`[${label}]`}
            suppressContentEditableWarning
            className={`pdf_contenteditable ${(fieldDoesNotHaveAValue ? 'placeholder': '')} ${(error ? 'error' : '')}`}
            // onClick={handleFieldClick}
            style={{
                display: "inline-block",
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                wordBreak: 'break-all',
            }}
            onFocus={switchToEditMode}
            onBlur={switchToReadMode}
            contentEditable={true}>{contentToDisplay}</div>)}>
        <Popup.Header>
            {label}
        </Popup.Header>
        <Popup.Content style={(error ? {color: 'red'} : {})}>
            <Divider/>
            {error ? error : placeholder}
        </Popup.Content>
    </Popup>
        {trailingElement}
    </>;
}

export default PDFInputField;


export const verifyIfFieldDoesNotHaveAValue = (value: FormInputTypes) => {
    let fieldDoesNotHaveAValue = false;
    const currentValueOfField = value;
    if (currentValueOfField === null) {
        fieldDoesNotHaveAValue = true;
    } else if (Array.isArray(currentValueOfField) && currentValueOfField.length === 0) {
        fieldDoesNotHaveAValue = true;
    } else if (typeof currentValueOfField === 'string' && currentValueOfField.length === 0) {
        fieldDoesNotHaveAValue = true;
    }
    return fieldDoesNotHaveAValue;
}