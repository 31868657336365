import {LineItemsStore, StoreQuery} from "../../../../ps-models/lineitems-store";
import {formatRows, fromNestedList} from "../../../../lineitems-store/TableFormat";
import {fromPairs} from "ramda";
import {LineItemsTable} from "../../../../lineitems-store/LineItemsTableView";
import {setSourceForDemo} from "../storeFormulas";

export const cashPerformanceSummaryTableItems = [
    'Debt Balance',
    'Cash Balance',
    'Net Cash Flow',
    'Net Asset Cost Basis',
    'Current Value'
];

export function CashPerformanceSummary({store}: {store: LineItemsStore}) {
    setSourceForDemo(store);

    let result = store.query(StoreQuery.byNames(cashPerformanceSummaryTableItems).monthly(), {withMetadata: ['data_source']});

    let formattedReportTableResult = formatRows(fromNestedList({
        ...fromPairs(cashPerformanceSummaryTableItems.map((item) => [item, []]))
    }), result);

    return <LineItemsTable
        showColumns={{
            'fields.data_source': 'Source of Data'
        }}
        queryResult={formattedReportTableResult} />
}