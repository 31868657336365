import {
  LineItem, TimedCalculatedLineItem, TimedLineItem, ValueType
} from "./line-items";
import {normalizeMapKeys, normalizeString} from "./line-item-utils/coding.utils";
import {LineItemsFieldSet} from "./line-items/LineItemsFieldSet";
import {
  buildParameterLineItem,
  buildTimedCalculatedLineItem,
  buildTimeDef,
  field,
  HighOrderLineItem,
  isTimedLineItem,
  ParameterLineItem,
} from "./line-items";
import {CANONICAL_NAME_FIELD, SOURCE_ID_FIELD, StoreQuery} from "./lineitems-store";
import {filter, values} from "ramda";
import {GREATEST_GRANULARITY} from "./TimeGranularity";
import {LineItemField} from "../ps-types";
import {LineItemStoreLogs} from "./LineItemStoreLogs";

const warningLogCache: Record<string, boolean> = {}

function warn(msg: string) {
  // if(!warningLogCache[msg]) {
  //   //console.warn(msg);
  // }
  // warningLogCache[msg] = true
}

export const GROUPING_PARENT_REFERENCE_KEY = "store_parent";
export const GROUPING_LINE_ITEM_KEY = "store_groupingName";

//Todo: Do we need to unify this with the Aggregation operations? They are very similar, but they happen on different dimensions (Horizontally vs Vertically)
interface GroupingLineItemsOptions {
  groupingOperation?: LineItemAggregations,
  lineItemName?: string
}

export const LineItemAggregationsValue = ["sumOver", "minOver", "maxOver" ,  "avg" , "none" , "last" , "first", "sum", "min", "max"] as const
//This is similar to the montly aggregations and so, but its not exactly the same. The ones here have to exist in the CalculatedLineitems binding. We should think a way to unify this
export type LineItemAggregations =  typeof LineItemAggregationsValue[number]

export interface GroupingOptions  {
  defaultGroupingOperation: LineItemAggregations,
  groupOperationMap: Record<string, LineItemAggregations>
  inheritedFields: string[]
  groupingLabel?: string
}
export class LineItemDataSet {

  constructor(private logs: LineItemStoreLogs = new LineItemStoreLogs()) {}

  private lineItemsByCategory: Record<string, Set<string>> = {}
  private lineItemsByFieldAndValue: Record<string, Set<string>> = {}
  private allFields: Record<string, LineItemField> = {}
  private lineItems: Record<string, LineItem> = {}

  private highOrderLineItems: Record<string, HighOrderLineItem> = {}

  getLineItem(lineItemName: string): LineItem {
    if(!lineItemName) {
      lineItemName = "none";
    }
    return this.lineItems[normalizeString(lineItemName)]
  }

  getTypedLineItem<T extends LineItem>(lineItemName: string): T {
    return this.getLineItem(lineItemName) as T;
  }

  getLogs(): LineItemStoreLogs {
    return this.logs;
  }

  getByCanonicalName(lineItemName: string): LineItem[] {
    let li = this.lineItems[normalizeString(lineItemName)];

    let res = [];

    if(li) {
      res.push(li);
    }
    res.push(...this.getByField(CANONICAL_NAME_FIELD, lineItemName).filter(li => li.name !== lineItemName));

    return res;
  }

  deleteLineItem(lineItemName: string) {
    delete this.lineItems[normalizeString(lineItemName)];
  }

  static merge(a: LineItemDataSet, b: LineItemDataSet) {
    let union = new LineItemDataSet();
    Object.values(a.getLineItems()).forEach(li => {
      union.addLineItem(li);
    });
    Object.values(b.getLineItems()).forEach(li => {
      union.addLineItem(li);
    });

    return union
  }

  getLineItems() {
    return this.lineItems;
  }

  getLineItemsByType(type: string): LineItem[] {
    return Object.values(this.lineItems).filter(li => li.type === type);
  }

  private logWarning(msg: string){
    warn(msg);
    this.logs.warn(msg);
  }

  getLineItemsWithField(fieldName: string): LineItem[] {
    if (!this.lineItemsByCategory[normalizeString(fieldName)]) {
      this.logWarning(`Field '${fieldName}' not found`);
      return []
    }
    return Array.from(this.lineItemsByCategory[normalizeString(fieldName)]).map(li => this.lineItems[li])
  }


  getFieldsWithName(fieldName: string): LineItemField[] {
    let result = new Set<LineItemField>();
    for(let li of Object.values(this.lineItems)) {
      let field = li.fields.getField(fieldName);
      if(field) {
        result.add(field);
      }
    }
    return [...result]
  }

  getFieldValues(fieldName: string) {
    return Array.from(
      new Set(this.getFieldsWithName(fieldName).map(f => f.value))
    )
  }

  getByField(fieldName: string, value: string): LineItem[] {
    let cachedValues = this.lineItemsByFieldAndValue[`${normalizeString(fieldName)}:${normalizeString(value)}`];
    if (!cachedValues) {
      return []
    }
    return Array.from(cachedValues).map(li => this.lineItems[li]);
  }


  getLineItemInContext(cName: string, fieldName: string, value: string): LineItem | undefined {
    let lineItems = this.getByField(fieldName, value)
      .filter(li => li.nameMatches(cName));

    if (lineItems.length > 0) {
      this.logWarning(`Multiple line items ${cName}  found in context of ${fieldName} ${value}`);
    }

    return lineItems[0]
  }


  //TODO: Deprecate. The category concept should be dreprecated, use getByFieldName instead
  getByCategory(categoryName: string): LineItem[] {
    if (!this.lineItemsByCategory[normalizeString(categoryName)]) {
      this.logWarning(`Category '${categoryName}' not found`);
      return []
    }
    return Array.from(this.lineItemsByCategory[normalizeString(categoryName)]).map(li => this.lineItems[li])
  }

  //TODO: Deprecate. The category concept should be dreprecated, use getByFieldName instead
  isCategory(categoryName: string): boolean {
    if (!this.lineItemsByCategory[normalizeString(categoryName)]) {
      this.logWarning(`Category '${categoryName}' not found`);
      return false;
    }
    return true;
  }

  addHighOrderLineItem(highOrderLineItem: HighOrderLineItem) {
    this.highOrderLineItems[normalizeString(highOrderLineItem.name)] = highOrderLineItem;
  }

  expand(): LineItemDataSet {
    let expanded = this.clone();

    let fieldsIndex = this.getFieldsIndex();

    Object.values(this.highOrderLineItems).forEach(holi => {
      holi.getLineItems(expanded.lineItems, fieldsIndex).forEach(li => {
        expanded.addLineItem(li);
      })
    });

    return expanded;
  }

  getFieldsIndex() {
    let allFieldsMap: Record<string, (ValueType)[]> = {};
    let allFieldsUniqueSet: Record<string, Set<ValueType>> = {};
    Object.values(this.lineItems).forEach(li => {
      let map = li.fields.getFieldMap();
      Object.keys(map).forEach(key => {
        if (!allFieldsUniqueSet[key])
          allFieldsUniqueSet[key] = new Set<ValueType>();

        allFieldsUniqueSet[key].add(map[key].value)
      })
    });
    for(let key in allFieldsUniqueSet){
      allFieldsMap[key] = [...allFieldsUniqueSet[key]];
    }
    return allFieldsMap;
  }

  getFields() {
    return this.allFields;
  }

  addFieldToLineItem(lineItemName: string, field: Omit<LineItemField, "key">) {

    let lineItem = this.getLineItem(lineItemName);
    if (!lineItem) {
      this.logWarning(`Line Item ${lineItemName} not found`);
      return
    }

    this.deleteIndexes(field.name, lineItem);

    lineItem.fields.addField(field.name, field.value, field.label)
    this.updateIndexes(field.name, lineItem);
  }

  removeFieldFromLineItem(lineItemName: string, fieldName: string) {
    let lineItem = this.getLineItem(lineItemName);
    if (!lineItem) {
      this.logWarning(`Line Item ${lineItemName} not found`)
      return
    }

    lineItem.fields.removeField(fieldName)

    //Update indexes
    this.deleteIndexes(fieldName, lineItem);
  }

  private updateIndexes(fieldKey:string, lineItem: LineItem) {
    let field = lineItem.fields.getField(fieldKey)!;
    if (!this.lineItemsByCategory[fieldKey]) {
      this.lineItemsByCategory[fieldKey] = new Set<string>();
    }
    let value = normalizeString(field!.value.toString());
    if(!this.lineItemsByFieldAndValue[`${fieldKey}:${value}`]) {
      this.lineItemsByFieldAndValue[`${fieldKey}:${value}`] = new Set<string>();
    }
    this.lineItemsByCategory[fieldKey].add(normalizeString(lineItem.name));
    this.lineItemsByFieldAndValue[`${fieldKey}:${value}`].add(normalizeString(lineItem.name));
    this.allFields[fieldKey] = field;
  }

  private deleteIndexes(fieldName:string, lineItem: LineItem) {
    let field = lineItem.fields.getField(fieldName);

    if(!field || !field.value) {
      return;
    }

    if (this.lineItemsByCategory[fieldName]) {
      this.lineItemsByCategory[fieldName].delete(normalizeString(lineItem.name));
    }
    let value = normalizeString(field.value.toString());
    if(this.lineItemsByFieldAndValue[`${fieldName}:${value}`]) {
      this.lineItemsByFieldAndValue[`${fieldName}:${value}`].delete(normalizeString(lineItem.name));
    }
    delete this.allFields[fieldName];
  }

  removeLineItem(lineItemName: string) {
    let lineItem = this.getLineItem(lineItemName);
    if (!lineItem) {
      return
    }

    for (const label of lineItem.fields.getAllKeys()) {
      this.deleteIndexes(label, lineItem);
    }

    delete this.lineItems[normalizeString(lineItemName)];
  }

  addLineItem(lineItem: LineItem) {

      for (const label of lineItem.fields.getAllKeys()) {
        try {
        this.updateIndexes(label, lineItem);
        } catch(e: any) {
          console.log(`Error with label ${label}`, e);
          if(e?.message){
            this.logs.error(`Error with label ${label} ${e?.message}`);
          }
        }
      }

    let existingLineItem = this.lineItems[normalizeString(lineItem.name)];

    if (existingLineItem) {
      this.logWarning(`Line Item ${lineItem.name} is overrided.`)
    }

    this.lineItems[normalizeString(lineItem.name)] = lineItem;

    return this;
  }

  addUniquelyNamedLineItem(lineItem: LineItem) {

    let existingLineItem = this.lineItems[normalizeString(lineItem.name)];

    if (existingLineItem) {
      throw new Error(`Line Item ${lineItem.name} already exists.`)
    }

    return this.addLineItem(lineItem);
  }


  addLineItems(lineItems: LineItem[]) {
    lineItems.forEach(li => this.addLineItem(li));
  }
  // @TODO: AM48 restore
  // addGroupingLineItemsByFieldAndValue(groupingFieldName: string, value: string, groupingOptions?: GroupingLineItemsOptions) {
  //
  //   if(!groupingOptions) {
  //     groupingOptions = {};
  //   }
  //
  //   if(!groupingOptions?.lineItemName) {
  //     groupingOptions.lineItemName = `${value}`;
  //   }
  //
  //   if(!groupingOptions?.groupingOperation) {
  //     groupingOptions.groupingOperation = "sumOver";
  //   }
  //
  //   let applicableLineItems = this.getByField(groupingFieldName, value);
  //   // Picks the timeDefinition of the first applicable li
  //   let firstApplicableLi = applicableLineItems[0];
  //   this.addLineItem(
  //     buildTimedCalculatedLineItem(
  //       groupingOptions.lineItemName,
  //       isTimedLineItem(firstApplicableLi) ? firstApplicableLi.getTimeDefinition(): buildMonthlyTimeDef(),
  //       //language=JavaScript
  //       `${groupingOptions.groupingOperation}(f('${groupingFieldName}', '${value}'))`,
  //       {[GROUPING_LINE_ITEM_KEY]:groupingFieldName}
  //     )
  //   );
  //
  //   applicableLineItems.forEach((lineItem) => {
  //     this.addFieldToLineItem(lineItem.name, field(GROUPING_PARENT_REFERENCE_KEY,   groupingOptions!.lineItemName!));
  //   });
  // }

  // @TODO: AM48 WATTSYNC CHECK
  // addGroupingLineItemsByField(groupingFieldName: string, defaultGroupingOperation: LineItemAggregations = "sumOver", groupOperationMap: Record<string, LineItemAggregations> = {}) {
  //
  //   //normalize the groupOperationMap
  //   groupOperationMap = normalizeMapKeys(groupOperationMap);
  //
  //   let applicableLineItems = this.getLineItemsWithField(groupingFieldName);
  //
  //   applicableLineItems.forEach((lineItem) => {
  //     let parentFieldName = `${lineItem.fields.getFieldStr(groupingFieldName)}`;
  //
  //     let valueType = lineItem.fields.getFieldStr("store_valueType");
  //
  //     if(defaultGroupingOperation !== "none" && !this.getLineItem(parentFieldName)) {
  //       let groupingOperation = groupOperationMap[normalizeString(parentFieldName)] || defaultGroupingOperation;
  //       this.addLineItem(
  //         buildCalculatedLineItem(
  //           parentFieldName,
  //           //language=JavaScript
  //           `${groupingOperation}(f('${groupingFieldName}', '${parentFieldName}'))`,
  //           //convert below to map
  //           { "store_valueType": valueType || "string", [GROUPING_LINE_ITEM_KEY]: groupingFieldName }
  //         )
  //       );
  //     }
  //
  //     this.addFieldToLineItem(lineItem.name, field(GROUPING_PARENT_REFERENCE_KEY,  parentFieldName));
  //   });
  // }

  addTimedGroupingLineItemsByField(groupingFieldName: string,  options: Partial<GroupingOptions> = {}) {

    let ops = {
        defaultGroupingOperation: options.defaultGroupingOperation || "sum",
        groupOperationMap: options.groupOperationMap || {},
        inheritedFields: options.inheritedFields || []
      }

    ops.groupOperationMap = normalizeMapKeys(ops.groupOperationMap)

    let applicableLineItems = this.getLineItemsWithField(groupingFieldName);

    applicableLineItems.forEach((lineItem, index) => {

      let parentFieldName = `${lineItem.fields.getFieldStr(groupingFieldName)}`.trim();

      let valueType = lineItem.fields.getFieldStr("store_valueType");

      let lineItemGroupingOperation =  lineItem.fields.getFieldStr("store_groupingOperation");

      //If it's not a timed line item it's a Parameter Line Item, so we use the bigger time definition by default
      let timeDef =  isTimedLineItem(lineItem) ? lineItem.getTimeDefinition() : buildTimeDef(GREATEST_GRANULARITY);

      if(ops.defaultGroupingOperation !== "none" && !this.getLineItem(parentFieldName)) {

        let fieldsFromChildren = new LineItemsFieldSet();

        //add the inherited fields from children, assume that all children have the same value for the fields
        for(let inheritedField of ops.inheritedFields) {
          let value = lineItem.fields.getField(inheritedField)?.value;
          if(value) {
            fieldsFromChildren.addField(inheritedField, value);
          }
        }

        if(options.groupingLabel) {
          fieldsFromChildren.addField("store_label",
            lineItem.fields.getFieldStr(options.groupingLabel)
          );
        }

        fieldsFromChildren.addField("store_label",
          lineItem.fields.getFieldStr('store_sourceLabel')
        );

        let groupingOperation = ops.groupOperationMap[normalizeString(parentFieldName)]
          || lineItemGroupingOperation || ops.defaultGroupingOperation;

        let groupingLineItem = buildTimedCalculatedLineItem(
          parentFieldName,
          timeDef,
          //language=JavaScript
          `${groupingOperation}(f('${groupingFieldName}', \`${parentFieldName}\`))`,
          fieldsFromChildren
            .addField("store_valueType", valueType || "number")
            .addField(GROUPING_LINE_ITEM_KEY, groupingFieldName)
        );

        this.addLineItem(groupingLineItem);
      }

      this.addFieldToLineItem(lineItem.name, field(GROUPING_PARENT_REFERENCE_KEY,
        parentFieldName
      ));
    });
  }

  createView(query: StoreQuery) {
    let allLineItems = this.getLineItems();
    let filteredLineItems = filter(li => query.filter(li)(this), allLineItems);
    let dataSet = new LineItemDataSet();
    values(filteredLineItems).forEach(li => dataSet.addLineItem(li));
    return dataSet;
  }

  clone() {
    let copy = new LineItemDataSet();

    Object.values(this.lineItems).forEach(li => {
      copy.addLineItem(li);
    });

    return copy;
  }

  /**
   * Gets the list of Stores of an aggregation
   */
  getAggregatedStoreIds() {
    return this.getFieldValues("store_sourceName")
  }
  getAggregatedStoreNames() {
    return this.getFieldValues("store_sourceName")
  }
  /**
   * Returns the line item value or the associated Parameter line item value
   * The Associated single line item value is the lineItem value that belongs to the same store using store.sourceId and store.sourceLineItemName
   */
  getRelatedValue(lineItemName: string, fieldName: string): ValueType | undefined {
    let li = this.getLineItem(lineItemName);
    let value = li.fields.getField(fieldName)?.value;
    if (value) {
      return value;
    }

    let sourceId = li.fields.getField(SOURCE_ID_FIELD)?.value;

    if(sourceId === undefined) {
      return undefined;
    }

    let sli = (this.getLineItem(LineItemDataSet.uniqueName(sourceId.toString(), fieldName)) as ParameterLineItem);

    return sli?.getValue().value;
  }

  static uniqueName(sourceId: string, sourceLineItemName: string) {
    return `${sourceId}-${sourceLineItemName}`;
  }

  getParam(lineItem: string) {
    let li = this.getLineItem(lineItem);

    if(!li ||  !(li instanceof ParameterLineItem)) {
      // @TODO: Check if this should be presented to the user
      this.logWarning(`Invalid parameter line item ${lineItem}`);
      return undefined;
    }

    return li.getValue().value;
  }

  addParam(name: string, value: ValueType, fields?: LineItemsFieldSet) {
    this.addLineItem(buildParameterLineItem(name, value, fields));
  }

  addUniquelyNamedParam(name: string, value: ValueType, fields?: LineItemsFieldSet){
    this.addUniquelyNamedLineItem(buildParameterLineItem(name, value, fields));
  }

  extend(code: string, lineItems: LineItem[]) {

    lineItems.forEach(li => {

      if(li.fields.hasField("store_isExtension")) {
        li = (li as TimedCalculatedLineItem).getChildLineItem()!;
      }

      let timeDef = isTimedLineItem(li) ? li.getTimeDefinition() : buildTimeDef(GREATEST_GRANULARITY);

      let cl = buildTimedCalculatedLineItem(
        li.name,
        timeDef,
        code,
        li.fields.clone()
      )
      cl.fields.addField("store_label", `${li.label}*`);
      cl.fields.addField("store_isExtension", true);
      cl.extend(li);
      this.addLineItem(cl);
    });
  }
}