import {formatDistance} from "date-fns";
import {PS_URLS, useCompanyId} from "../../core";
import {AMCompanyModel} from "../../ps-types";
import {buildEntityDef, EntityCrud} from "../../ui/crud/EntityCrud.component";
import React from "react";
import {Button} from "semantic-ui-react";
import {getAmCompanyModels, getAmCompanyModelDownloadUrl} from "../../company/company.client";
import {useHistory} from "react-router-dom";
import {useMessages} from "../../ui/MessagesProvider";


export function timeAgo(value: string) {
  return value ? formatDistance(new Date(value), new Date(), {addSuffix: true}) : "";
}

export function AmCompanyModelsListPage() {
  const companyId = useCompanyId();
  const history = useHistory();
  let {clear, error} = useMessages();

  const downloadModel = async (companyModelData: AMCompanyModel) => {
    getAmCompanyModelDownloadUrl(companyId, companyModelData.id).then(async (res) => {
      window.open(res.url, "_blank")
    }).catch((err) => {
          console.error(err);
          error("Unable to download the model at the moment. Please try after sometime.")
        });
  }

  const getEntities = () => getAmCompanyModels(companyId);

  let def = buildEntityDef(
      {
        entityName: "Models",
        title: "Models",
        getEntities,
        table: {},
      },
      {
        title: {
          title: "Title"
        },
        description: {
          title: "Description",
        },
        type: {
          title: "Type",
        },
        createdAt: {
          title: "CREATED",
          create: { type: "hidden" },
          table: { format: timeAgo }
        },
        downloadUrl: {
          title: "Download",
          table: {
            render: (value:any, entity) =>
                <Button size="mini" onClick={()=>downloadModel(entity)}> Download </Button>
          }
        }
      }
  );

  return <div>
    <Button primary
            floated={"right"}
            style={{marginBottom: "1em"}}
            size={"small"}
            onClick={() => history.push(PS_URLS(companyId).amCompanyNewModel())}
    >Add new model</Button>
    <EntityCrud entityDef={def} />
  </div>
}