import {StoreQuery} from "../../../ps-models/lineitems-store";
import React from "react";
import {useSiteStoreContext} from "../../siteStoreLoader";
import {Header, Segment} from "semantic-ui-react";
import {GridExportTimeSeries} from "./GridExportTimeSeries";
import {DemoTimeSeriesChart} from "./DemoTimeSeriesChart";

export function AEOvsWAMO() {

  let store = useSiteStoreContext();

  let AEO_vs_WAMO = store.query(StoreQuery.byNames(['pv_generation_kwh', 'wamo']))
  let AEO60_vs_WAMO60 = store.query(StoreQuery.byNames(['AEO60', 'WAMO60']));

  return <Segment basic>
    <Header as="h2" color="purple">AEO vs WAMO</Header>
    <GridExportTimeSeries />
    <DemoTimeSeriesChart title={"AEO vs WAMO"} result={AEO_vs_WAMO}/>
    <DemoTimeSeriesChart title={"AEO60 vs WAMO60"} result={AEO60_vs_WAMO60}/>
  </Segment>
}