import React, {useState} from "react";
import {Icon, Message, Segment} from "semantic-ui-react";
import { normalizeString } from "./ps-models";

export function ClosableSection({
                                  title,
                                  children,
                                  opened, level = 'h3',
                                  customStyles
                                }: { title: React.ReactNode,
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'small' | 'medium' | "title-bar",
  children: React.ReactNode | React.ReactNode[], opened?: boolean, customStyles?:Record<string, any> }) {
  let [open, setOpen] = useState<boolean>(opened === undefined ? true : opened);

  let titleWithIndicator = <>{title}
    <Icon name={`angle ${open ? 'down' : 'up'}`} style={{cursor: "pointer", "padding-left": "5px"}}
          size="small"
          onClick={() => setOpen(!open)}/></>


  if(level === "title-bar") {
    return <Segment.Group piled data-cy={`${ typeof title === "string"  ? normalizeString(title as string): "" }-closable-section`}
        {...(customStyles ? {style: customStyles}: {} )}
    >
      <Segment
        style={{cursor: "pointer"}}
        onClick={() => setOpen(!open)}>{titleWithIndicator}</Segment>
      {open && children}
    </Segment.Group>
  } else {}

  return <div
    data-cy={`${ typeof title === "string"  ? normalizeString(title as string): "" }-closable-section`}>
    {level === 'h1' && <h1>{titleWithIndicator}</h1>}
    {level === 'h2' && <h2>{titleWithIndicator}</h2>}
    {level === 'h3' && <h3>{titleWithIndicator}</h3>}
    {level === 'h4' && <h4>{titleWithIndicator}</h4>}
    {level === 'small' && <div style={{fontSize: "12px", marginTop: "10px", marginBottom: "10px"}}>{titleWithIndicator}</div>}
    {level === 'medium' && <div style={{fontSize: "14px"}}>{titleWithIndicator}</div>}

    {open && children}
  </div>
}