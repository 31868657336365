import React, {useEffect, useRef, useState} from "react";
import {TextAreaProps} from "semantic-ui-react/dist/commonjs/addons/TextArea/TextArea";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";

interface FormConfig<T> {
    initialState?: T;
    onSubmit?: (payload: T) => Promise<unknown>;
    setError?: (e: any) => void;
}

interface FormProps<T> {
    formData: T;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | {target: {name: any, value: any}}, data?: TextAreaProps | InputOnChangeData) => void;
    handleSubmit: (e: React.FormEvent) => void;
    submitting: boolean;
    error: Error | null;
    setError: (e: any) => void;
}

export function useForm<T = undefined>(config: FormConfig<T>): FormProps<T> {
    const [formData, setFormData] = useState<T>(config.initialState as T);
    const [submitting, setSubmitting] = useState(false);
    const [error, setErrorInternal] = useState<Error | null>(null);
    const isMounted = useRef(true)
    const setError = (e: any) => {
        setErrorInternal(e);
        config.setError?.(e);
    }

    useEffect(() => () => {
        isMounted.current = false
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | {target: {name: any, value: any}}, data?: TextAreaProps | InputOnChangeData) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        if (config.onSubmit === undefined) {
            throw new Error('onSubmit is undefined')
        }
        e.preventDefault();
        setSubmitting(true);
        setError(null);
        try {
            await config.onSubmit(formData);
        } catch (e: unknown) {
            if (e instanceof Error) {
                setError(e);
            } else {
                setError(new Error('An error occurred, please try again later. If the problem persists, please contact support.'))
            }
        }
        if (isMounted.current) {
            setSubmitting(false);
        }
    }

    return {formData, handleChange, handleSubmit, submitting, error, setError};
}
