import React, { useEffect, useState } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import { getUserCompanySubscriptions, updateUserSubscriptionManually } from "../../../firebase/firebase.utils";
import {checkPremiumSubscription} from "../../../platform/auth";

function UserSubscriptionStatus({ user }) {
  const [isLoading, setLoading] = useState(true);
  const [isPremium, setPremium] = useState(false);
  const [open, setOpen] = useState(false);

  const userId = user.id;
  const userName = user.displayName || `${user.firstName} ${user.lastName}`;
  const userEmail = user.email;

  const handleConfirm = async () => {
    setLoading(true);
    await updateUserSubscriptionManually(userId, !!isPremium ? "downgrade" : "upgrade");
    setLoading(false);
    resetModal();
  }

  const resetModal = () => {
    setOpen(false);
  }

  const checkUserSubscription = async () => {
    const subscriptions = await getUserCompanySubscriptions(user, false);
    let isPremium = false;
    if (!!subscriptions.length) isPremium = !!(subscriptions.find(s => checkPremiumSubscription(s)));
    if (isPremium) setPremium(isPremium);
    setLoading(false);
  }

  useEffect(() => {
    checkUserSubscription();
  }, [checkUserSubscription]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button size="tiny" compact content={isPremium ? "Premium" : "Free"} color={"purple"} loading={isLoading} basic={!isPremium} />
      }
    >
      <Modal.Header>{isPremium ? `Downgrade User ${userName} (${userEmail}) to Free status?` : `Upgrade User ${userName} (${userEmail}) to Premium status?`}</Modal.Header>
      <Modal.Content> 
        <Modal.Description>
          { isPremium ? 
            "By continuing, you will downgrade this user's permissions to Free status, and they will lose access to all paid app features." : 
            "By continuing, you will upgrade this user's permissions to Premium status, and they will gain access to all paid app features."
          }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          labelPosition='left'
          icon={isPremium ? "arrow circle down" : "arrow circle up"}
          primary
          onClick={handleConfirm}
          color={isPremium ? "red" : "green"}
          content={isPremium ? "Downgrade User to Free" : "Upgrade User to Premium"}
          disabled={isLoading}
          loading={isLoading}
        />
        <Button basic onClick={resetModal} icon labelPosition="left" type="button">
          <Icon name="cancel" />
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default UserSubscriptionStatus;
