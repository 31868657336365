import {Button, Divider, Form, Grid, Header, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "../ui/UseForm";
import {LoginLayout} from "./LoginLayout";
import {LoginData, SSOLoginPayload} from "./auth.client";
import firebase from "firebase";
import {useTimer} from "../ui/UseTimer";
import {TwoFactor, useLogin} from "./LoginHook";
import {OtpFormInput} from "./PhoneFormInputs";
import {AZURE_ENTRA_SAML_AUTH_PROVIDER_ID} from "../../firebase/firebase.core";

export function NewLoginPage() {
    const [error, setError] = useState<any>(null)
    const {twoFactor, onBasicLogin, ssoLogin} = useLogin()
    return <LoginLayout error={error}>
      <Header as="h1" className="hero-title">Welcome to Perlstreet</Header>
        {twoFactor
            ? <TwoFactorAuthLogin key={twoFactor.transactionId} twoFactor={twoFactor} setError={setError} />
            : <BasicLogin onBasicLogin={onBasicLogin} setError={setError}  />
        }
        <SSOLogin onLogin={ssoLogin} setError={setError}/>
    </LoginLayout>
}

function BasicLogin({onBasicLogin, setError}: {onBasicLogin: (data: LoginData) => Promise<void>, setError: (e: any) => void}) {
    const { formData, handleChange, handleSubmit, submitting, error } = useForm<LoginData>({
        initialState: {
            email: '',
            password: '',
        },
        onSubmit: async (data) => {
            await onBasicLogin(data)
        },
        setError
    })

    return <>
      <p>Don't have an account? <Link to="/signup">Click here</Link></p>
      <Form size='large' onSubmit={handleSubmit} className="outlineForm">
        <Form.Input
          fluid
          placeholder='Email address'
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <Form.Input
          fluid
          placeholder='Password'
          type='password'
          name="password"
          value={formData.password}
          onChange={handleChange}
        />

        <Divider hidden />
        <Divider hidden />

        <Button primary type="submit" className="raised" size='huge' loading={submitting} disabled={submitting} >
          Continue
          <Icon name='arrow right' />
        </Button>
    </Form>
    <Divider hidden />
    <p>Forgot your password? <Link to="/reset-password" className="slightLeftMargin">Reset Password</Link></p>
  </>
}

function TwoFactorAuthLogin({twoFactor, setError}: {twoFactor: TwoFactor, setError: (e: any) => void}) {
    const signInButtonRef = useRef(null);
    const [timerExpired, resetTimer] = useTimer(10000)
    const [optSent, setOtpSent] = useState(false)
    const { formData, handleChange, handleSubmit, submitting } = useForm<{otp: string}>({
        initialState: {
            otp: ''
        },
        onSubmit: async (data) => {
            await twoFactor.login(data.otp)
        },
        setError
    })

    useEffect(() => {
        if (!signInButtonRef.current) {
            return;
        }

        const recaptchaVerifier = new firebase.auth.RecaptchaVerifier("sign-in-button", { size: "invisible" })
        twoFactor.initiate(recaptchaVerifier)
            .then(() => {
                resetTimer(10000)
                setOtpSent(true)
            })
            .catch(setError)
    }, []);

    return <>
      <p>Introduce the OTP Code sent to your phone.</p>
      <Form size='large' className="outlineForm">
        <OtpFormInput
          fluid
          placeholder='OTP Code'
          name="otp"
          value={formData.otp}
          onChange={handleChange}
        />

        <Divider hidden />
        <Divider hidden />

        <Grid centered>
          <Grid.Row centered columns={2}>
            <Grid.Column textAlign='center'>
                <Button primary type="submit" id='sign-in-button'
                        ref={signInButtonRef} className="raised" size='huge' loading={submitting || !optSent} disabled={submitting || !optSent}
                        onClick={(e) => {handleSubmit(e)}}
                >
                  Continue
                  <Icon name='arrow right' />
                </Button>
            </Grid.Column>
            <Grid.Column textAlign='center'>
                <Button key={twoFactor.transactionId} primary type="submit"
                        ref={signInButtonRef} className="raised" size='huge' loading={submitting || !optSent || !timerExpired} disabled={submitting || !optSent || !timerExpired}
                        onClick={() => {twoFactor.reset()}}
                >
                    Resend Code
                    <Icon name='repeat' style={{ marginLeft: '8px' }} />
                </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <Divider hidden />
      <p>Need help? <Link to="/reset-password" className="slightLeftMargin">Reset Password</Link></p>
    </>
}

function SSOLogin({onLogin, setError}:{onLogin: (payload:SSOLoginPayload)=>void, setError: (e: any) => void}) {
    const { handleSubmit } = useForm<{}>({
        initialState: {},
        onSubmit: async (data) => {
            await onLogin({providerId: AZURE_ENTRA_SAML_AUTH_PROVIDER_ID})
        },
        setError
    })
    return <>
        <Button color={'blue'} onClick={handleSubmit}>
            <Icon name='microsoft' /> Sign in with Azure Entra
        </Button>
        <Divider hidden />
    </>
}