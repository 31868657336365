import {LineItemColumn, LineItemRow, ResultCell} from "../QueryResult";
import {lineItemAggregatorMap} from "../../line-item-utils/aggregators";
import {TableBuilder} from "./TableBuilder";
import {AggregateOver} from "./QueryResultOptions";

export class AggregateTableBuilder extends TableBuilder {

  public buildTable() {
    let options = this.options;
    let lineItems = this.visibleLineItems;
    let aggregationRange = options.aggregatedTable?.defaultAggregateOver === AggregateOver.CURRENT_RANGE ? (options.aggregatedTable!.currentRange || this.timeIndex.getRange()) : this.timeIndex.getRange();
    let lineItemsAggregationDef = options.aggregatedTable!.lineItemAggregations;
    let rows: LineItemRow[] = [];
    for (let i = 0; i < lineItems.length; i++) {
      let lineItem = lineItems[i];
      let nameCell: ResultCell = this.buildNameCell(lineItem, options);
      let row: any = {
        name: nameCell
      };
      let formatterFunction = this.getFormattingFunction(lineItem);
      let cells: Record<string, ResultCell> = {};
      let value = lineItem.getTotal(aggregationRange, this.execution!, lineItemAggregatorMap(lineItemsAggregationDef?.lineItemMap ?? {}, lineItemsAggregationDef?.defaultAggregator)(lineItem));
      cells["0"] = {
        type: 'lineItemValue',
        columnId: "0",
        text: formatterFunction(value),
        value: value,
      }
      Object.assign(row, this.metadataCells(lineItem), cells);
      rows.push(row);
    }
    let columns: LineItemColumn[] = [];
    columns.push({
      type: 'header',
      columnId: 'name',
      text: 'Line Item'
    });
    columns.push({
      type: 'time',
      columnId: '0',
      text: 'Total'
    });
    return {
      rows: rows,
      columns: columns
    }
  }

}