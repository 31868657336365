import {CompanyConfig, ProjectTemplateConfig} from "../../ps-types";
import {useCompanyId} from "../../core";
import React, {useEffect, useState} from "react";
import {SmallLoading} from "../../ui/Loading";
import {Select} from "semantic-ui-react";
import {getAmCompanyConfig} from "../../company/company.client";

export function CompanyTemplates({onSelect}: { onSelect: (config: ProjectTemplateConfig) => void }) {

  let companyId = useCompanyId();
  let [templateConfigs, setTemplateConfigs] = useState<Record<string, ProjectTemplateConfig>>();


  useEffect(() => {
    getAmCompanyConfig<ProjectTemplateConfig>(companyId, "templateConfig")
      .then((res) => setTemplateConfigs(res.configs));
  }, [companyId]);

  if (!templateConfigs) return <SmallLoading/>

  const handleSelect = (configName: string) => {
    if (configName === "new") {
      onSelect({
        configName: "",
        configKey: "",
        config: {}
      });
      return;
    }
    if (templateConfigs && templateConfigs[configName]) {
      onSelect(templateConfigs[configName]);
    }
  }


  let options = Object.values(templateConfigs).map((config) => {
    return {key: config.configKey, value: config.configKey, text: config.configName}
  });

  //new
  options.unshift({key: "new", value: "new", text: "New"});

  return <div>
    <Select options={options}
            onChange={(_e, {value}) =>
              handleSelect(value as string)}
            placeholder='Select Template'
    />
  </div>

}