import {AmProject, ProjectLogEntry, ProjectVersionExecutionLogEntry, WhatIfScenarioRecord} from "../ps-types";
import React, {useEffect, useState} from "react";
import {format} from "date-fns";
import {indexBy, sort} from "ramda";
import {Tab} from "semantic-ui-react";




export interface JobProgressData {
  logEntries: ProjectLogEntry[],
  total: number
}

export function JobProgress({projects, initialJobProgress, refreshLogs}: {
  projects: AmProject[],
  initialJobProgress: JobProgressData,
  refreshLogs: () => Promise<JobProgressData>
}) {

  const [jobProgress, setJobProgress] = useState<JobProgressData>( initialJobProgress);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshLogs().then(jobProgress => {
        setJobProgress(jobProgress);
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const logs = jobProgress.logEntries;
  const total = jobProgress.total;

  let sortedLogs = sort((a, b) => b.time - a.time, logs);
  const projectById = indexBy(p => p.id, projects);

  const panes = [
    { menuItem: 'Per Project', render: () => <PerProjectLogView logs={sortedLogs} projectById={projectById}/>},
    { menuItem: 'All Logs', render: () => <LogView logs={sortedLogs} projectById={projectById}/> },
  ]


  return <>
    <JobProgressBar total={total} logs={sortedLogs}/>
    <Tab panes={panes} />
  </>
}


export function JobProgressBar({total, logs}: {total: number, logs: ProjectLogEntry[]}) {
  let latestLogs = getLatestLogsByProject(logs);

  let completed = latestLogs.filter(log => log.status === "completed").length;
  let failed = latestLogs.filter(log => log.status === "failed").length;

  let progress = Math.round((completed / total) * 100);

  return <div style={{margin: '16px 0'}}>
    <div style={{marginBottom: '8px'}}>Progress: {progress}%</div>
    <progress value={completed + failed} max={total} style={{width: '100%'}} />
  </div>

}


// Function to get the latest log for each project version
const getLatestLogsByProject = (logs: ProjectLogEntry[]) => {
  const latestLogs: { [key: string]: ProjectVersionExecutionLogEntry } = {};

  logs.forEach(log => {
    if (log.type === 'project-version') {
      const projectLog = log as ProjectVersionExecutionLogEntry;
      const key = `${projectLog.projectId}-${projectLog.versionId}`;

      if (!latestLogs[key] || new Date(projectLog.time) > new Date(latestLogs[key].time)) {
        latestLogs[key] = projectLog;
      }
    }
    if (log.type === 'SPV') {
      const projectLog = log as ProjectVersionExecutionLogEntry;
      const key = `${projectLog.spvId}`;

      if (!latestLogs[key] || new Date(projectLog.time) > new Date(latestLogs[key].time)) {
        latestLogs[key] = projectLog;
      }
    }
  });

  return Object.values(latestLogs);
};


export function PerProjectLogView({logs, projectById}: {
  logs: ProjectLogEntry[],
  projectById: Record<string, AmProject>
}) {
  const latestLogs = getLatestLogsByProject(logs);

  return (
    <div style={{overflowX: 'auto'}}>
      <table style={{width: '100%', borderCollapse: 'collapse'}}>
        <thead>
        <tr>
          <th style={{border: '1px solid #ddd', padding: '8px'}}>Project Name</th>
          <th style={{border: '1px solid #ddd', padding: '8px'}}>Source Version</th>
          <th style={{border: '1px solid #ddd', padding: '8px'}}>Last Status</th>
          <th style={{border: '1px solid #ddd', padding: '8px'}}>Last Message</th>
        </tr>
        </thead>
        <tbody>
        {latestLogs.map((log, index) => {
          const projectName = projectById[log.projectId]?.name || 'Unknown Project';
          return (
            <tr key={index} style={{borderBottom: '1px solid #ddd'}}>
              <td style={{border: '1px solid #ddd', padding: '8px'}}>{projectName}</td>
              <td style={{border: '1px solid #ddd', padding: '8px'}}>{log.versionId}</td>
              <td style={{border: '1px solid #ddd', padding: '8px'}}>{log.status}</td>
              <td style={{border: '1px solid #ddd', padding: '8px'}}>{log.message}</td>
            </tr>
          );
        })}
        </tbody>
      </table>
    </div>
  );
}

export function LogView({logs, projectById}: {
  logs: ProjectLogEntry[],
  projectById: Record<string, AmProject>
}) {

  return <table style={{width: '100%', borderCollapse: 'collapse'}}>
    <tr>
      <th style={{border: '1px solid #ddd', padding: '8px'}}>Time</th>
      <th style={{border: '1px solid #ddd', padding: '8px'}}>Level</th>
      <th style={{border: '1px solid #ddd', padding: '8px'}}>Project Info</th>
      <th style={{border: '1px solid #ddd', padding: '8px'}}>Message</th>
    </tr>
    {logs && logs.map((log, index) => {

      let projectInfo = log.type === "project-version" ? log as ProjectVersionExecutionLogEntry : null;

      //Time to hh:mm:ss dd/mm/yyyy

      let formattedTime = format(log.time, "HH:mm:ss dd/MM/yyyy");

      let backgroundColor = log.level === "error" ? '#fdd' : log.level === "warning" ? '#fffae6' : '#fff';

      return <tr key={index} style={{borderBottom: '1px solid #ddd', backgroundColor}}>
        <td style={{border: '1px solid #ddd', padding: '8px', color: '#888', backgroundColor}}>{formattedTime}</td>
        <td style={{border: '1px solid #ddd', padding: '8px', fontWeight: 'bold'}}>{log.level}</td>

        <td style={{border: '1px solid #ddd', padding: '8px'}}>
          {projectInfo ? `${projectById[projectInfo.projectId]?.name} - ${projectInfo.versionId}, status: ${projectInfo.status}` : ''}
        </td>
        <td style={{border: '1px solid #ddd', padding: '8px'}}>{log.message}</td>
      </tr>
    })}
  </table>
}