import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Container, Segment, Header, List, Button, Image, Divider, Input, Form, Icon, Breadcrumb, Confirm } from 'semantic-ui-react';
import LeadChecklist from '../../LeadChecklist/LeadChecklist.component';
import BackLink from '../../BackLink/BackLink.component';

import { startEditCompany, startDeleteCompany } from '../../../redux/companies/companies.actions';
import { selectCurrentCompanies, selectCompanyById } from '../../../redux/companies/companies.selectors';
import CompanyEditableAttribute from './CompanyEditableAttribute.component';
import {addLinkedDataToCurrentUser, authStorage} from "../../../platform/auth";

const EDITABLE_ATTRIBUTES = [
  { 
    id: "name",
    name: "Company Name",
    fieldType: "shortText"
  },
  { 
    id: "custStatus",
    name: "Status",
    fieldType: "shortText"
  },
  { 
    id: "sortTag",
    name: "Sorting Group",
    fieldType: "shortText"
  },
  { 
    id: "websiteUrl",
    name: "Website URL",
    fieldType: "shortText"
  },
  { 
    id: "logoUrl",
    name: "Logo URL",
    fieldType: "shortText"
  },
  { 
    id: "hubspotId",
    name: "Hubspot Company ID",
    fieldType: "shortText"
  },
  { 
    id: "gDriveLink",
    name: "Google Drive Link",
    fieldType: "shortText"
  },
  { 
    id: "otherNotes",
    name: "Other Notes",
    fieldType: "shortText"
  },
];

class CompanyView extends React.Component {

  state = {
    boxParentFolderId: "",
    deleteOpen: false,
  }

  openDelete = () => this.setState({ deleteOpen: true });
  closeDelete = () => this.setState({ deleteOpen: false });

  deleteCompany = async () => {
    const companyId = this.props.match.params.id;
    const { startDeleteCompany } = this.props;
    await startDeleteCompany(companyId);
    this.closeDelete();
    this.props.history.goBack();
  }

  componentDidMount() {
    // TBD: get updated company data on mount?
    const currentUser = authStorage.getUser();
    if(!currentUser.data.linkedUserData){
      addLinkedDataToCurrentUser(currentUser).catch(e => console.error(e, 'error adding linked data to user'))
    }
  }

  getCompanyData = () => {
    const companyId = this.props.match.params.id;
    const { companies } = this.props;
    if (!companyId || !companies) return null;
    const companyData = selectCompanyById(companies, companyId);
    if (!companyData) return null;
    return companyData;
  }

  handleChange = (event, data) => {
    const { value, name } = data;
    this.setState({ [name]: value });
  }

  updateBoxLink = async (event) => {
    const { boxParentFolderId } = this.state;
    event.preventDefault();
    const companyData = this.getCompanyData();
    if (!companyData) return null;
    const { editCompany } = this.props;
    await editCompany(companyData.id, { boxParentFolderId });
  }

  renderBoxLink = () => {
    const { boxParentFolderId } = this.state;
    const companyData = this.getCompanyData();
    if (!companyData) return null;
    const boxSharedId = companyData.boxParentFolderId;

    return (
      <Form onSubmit={this.updateBoxLink}>
        <Input 
          fluid
            action={{
              color: 'purple',
              labelPosition: 'left',
              icon: 'check circle',
              content: 'Update Shared Box ID'
            }}
          name="boxParentFolderId"
          placeholder='Box shared ID'
          value={boxParentFolderId ?  boxParentFolderId : boxSharedId || ""}
          // disabled
          onChange={this.handleChange}
        />
      </Form>
    );
  }

  renderBoxFolder = () => {
    const companyData = this.getCompanyData();
    if (!companyData) return null;

    // const boxSharedId = "m7qpf7lwkn4qo0oe2hkfp8ndjpedeivz";
    const boxSharedId = companyData.boxParentFolderId;
    if (!boxSharedId) return <p>Undefined Box shared link ID</p>;

    const baseDomain = "perlstreet";
    const boxView = "list" // list or icon
    const sortColumn = "name" // name, date, or size
    const sortDirection = "ASC" // ASC or DSC
    const showParentPath = "false" // true or false
    const width = "100%";
    const height = "500";

    return (
      <iframe
        title="BoxFolder"
        src={`https://${baseDomain}.app.box.com/embed/s/${boxSharedId}?view=${boxView}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&showParentPath=${showParentPath}`}
        width={width}
        height={height}
        frameBorder="0"
        allowFullScreen={true}
        webkitallowfullscreen="true"
        msallowfullscreen="true"
      ></iframe>
    );
  }

  // This was not being used, hence commented, to avoid auth related migration.
  // renderCompanyMembers = (linkedUserDataStatus, companyMembersData, companyInvitesData) => {
  //   return(
  //     <Segment.Group raised>
  //         <Segment attached>
  //           <Header size="tiny" className="tightHeader" icon="users" content={`Company Members`} />
  //         </Segment>
  //         <Segment attached color="purple" loading={linkedUserDataStatus.code !== "success"}>
  //
  //           {(true || !companyMembersData) ? (<p>No assigned members</p>) : (
  //             <List relaxed animated>
  //             {companyMembersData.map((member, member_i) => {
  //
  //               return null;
  //
  //               // return (
  //               //   <List.Item key={member.id || member_i}>
  //               //     <List.Content floated='right'>
  //               //       <Link to={
  //               //         // member.id ? `/users/${member.id}` : "#"
  //               //         "#"
  //               //         }><Button size="tiny" compact>Manage</Button></Link>
  //               //     </List.Content>
  //               //     <List.Icon name="user" />
  //               //     {/* { company.logoUrl ? <Image src={company.logoUrl} avatar /> : <List.Icon name="building" /> } */}
  //               //     <List.Content>
  //               //       <List.Header>
  //               //         <Link to={
  //               //           // member.id ? `/users/${member.id}` : "#"
  //               //           "#"
  //               //           }>
  //               //           {member.displayName || member.email}
  //               //         </Link>
  //               //       </List.Header>
  //               //       <List.Description>
  //               //         {member.email}
  //               //       </List.Description>
  //               //     </List.Content>
  //               //   </List.Item>
  //               // )
  //             })}
  //             </List>
  //           )}
  //
  //           {(!companyInvitesData || companyInvitesData.length === 0) ? null : (
  //
  //             <React.Fragment>
  //             <Header as="h5">Pending Invites ({companyInvitesData.length})</Header>
  //             <List relaxed animated>
  //             {companyInvitesData.map((invite, invite_i) => {
  //               return (
  //                 <List.Item key={invite_i}>
  //                   {/* <List.Content floated='right'>
  //                     <Link to={member.id ? `/users/${member.id}` : "#"}><Button size="tiny" compact>Manage</Button></Link>
  //                   </List.Content> */}
  //                   <List.Icon name="user" />
  //                   {/* { company.logoUrl ? <Image src={company.logoUrl} avatar /> : <List.Icon name="building" /> } */}
  //                   <List.Content>
  //                     <List.Header>
  //                       <Link to={"#"}>{invite.email}</Link>
  //                     </List.Header>
  //                     <List.Description>
  //                       {invite.role}
  //                     </List.Description>
  //                   </List.Content>
  //                 </List.Item>
  //               )
  //             })}
  //             </List>
  //             </React.Fragment>
  //
  //           )}
  //
  //         </Segment>
  //         <Segment secondary>
  //           <Button size="small" primary icon="plus" labelPosition="left" content="New Member" disabled={true || linkedUserDataStatus.code !== "success"} />
  //         </Segment>
  //       </Segment.Group>
  //   );
  // }

  render() {
    const { 
      // legacyLinkedUserData,
    } = this.props;
    // Get linked company data
    const linkedUserData = authStorage.getUser().data?.linkedUserData;

    const companyData = this.getCompanyData();
    // const companyMembersData = legacyLinkedUserData ? legacyLinkedUserData.members : null;
    // const companyInvitesData = companyData ? companyData.invites : null;
    // const linkedProjects = legacyLinkedUserData ? legacyLinkedUserData.projects : [];

    // Only render if admin
    // TBD - make this secure
    const isAdmin = authStorage.getUser().isAdmin();
    if (!isAdmin) return <h1>Access denied. Please contact team@perlstreet.com</h1>;

    return (
      <Segment basic>

        <Container>

          <Breadcrumb>
            <BackLink text="Previous" />
            <Breadcrumb.Divider icon='right chevron' />
            <Breadcrumb.Section>{companyData ? companyData.name : "Loading company ..."}</Breadcrumb.Section>
          </Breadcrumb>

          {companyData ? (
            <Header as='h2' color="purple" size="huge">
              {companyData.logoUrl ? <Image avatar src={companyData.logoUrl} className="square" /> : null}
              {companyData.name}
            </Header>
          ) : null}
          
        </Container>

        <Divider hidden />

        <Container>
          <Segment.Group>
            <Segment>
              <Header>Attributes</Header>
            </Segment>
            <Segment color="purple">
            {EDITABLE_ATTRIBUTES.map((attribute, attr_i) => 
              <CompanyEditableAttribute {...attribute} key={attr_i} match={this.props.match} />
            )}
            </Segment>
          </Segment.Group>
        </Container>

        <Divider hidden />
        <Divider hidden />

        <Container>
          <LeadChecklist match={this.props.match} />
        </Container>

        <Divider hidden />
        
        <Container>
          <Segment.Group>
            <Segment onClick={() => this.setState({ isOpen: !this.state.isOpen })} style={{ cursor: 'pointer'}} attached>
              <Header size="tiny" className="tightHeader">
              <Icon name="folder" />
              Company Folder
              </Header>
              <Icon 
                size="large"
                name="dropdown" 
                style={{position: "absolute", top: 0, right: 0, margin: ".75rem .5rem 0 0" }}
                rotated={!this.state.isOpen ? "clockwise" : null}
              />
            </Segment>
            {this.state.isOpen && (<>
              <Segment attached color="purple" secondary >
                {this.renderBoxLink()}
              </Segment>
              <Segment attached loading={!linkedUserData}>
                {this.renderBoxFolder()}
              </ Segment>
            </>)}
          </Segment.Group>
        </Container>

        <Divider hidden />

        <Container>
          <Button size="large" color='red' icon="trash alternate" labelPosition="left" content="Delete Company" disabled={!linkedUserData} onClick={this.openDelete} />
          <Confirm
            open={this.state.deleteOpen}
            onCancel={this.closeDelete}
            onConfirm={this.deleteCompany}
            content="Are you sure you want to delete this company?"
          />
        </Container>
      </Segment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  companies: selectCurrentCompanies
});

const mapDispatchToProps = dispatch => ({
  editCompany: (companyId, companyInfo) => dispatch(startEditCompany(companyId, companyInfo)),
  startDeleteCompany: (companyId) => dispatch(startDeleteCompany(companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyView);