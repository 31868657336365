import React, {useState} from "react";
import {Button, Confirm} from "semantic-ui-react";

export function DeleteButton({onDelete, content}:
                               {
                                 content: string,
                                 onDelete: () => void | Promise<any>
                               }) {
  let [isOpen, setIsOpen] = useState(false);

  const handleDelete = async () => {
    await onDelete();
    setIsOpen(false);
  }

  return (
    <>
      <Button floated={"right"} size="small" icon="trash" onClick={() => setIsOpen(true)}/>
      <Confirm
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        content={content}
        cancelButton='Cancel'
        confirmButton="Delete"
        onConfirm={handleDelete}
      />
    </>
  )
}