
import {Button, Checkbox, FormField, Input, Label, Select, TextArea} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {BuilderContext, registerWidgetType} from "../WidgetRegistry";
import {convertToNumberStringOrBoolean, FinancialValueType, FinancialValueTypes} from "../../../ps-models";
import {getConfig, getGlobalContext, useOnConfigChange} from "./commons";

interface ConfigProps {
  label: string,
  type: FinancialValueType,
  addToStore?: boolean,
  options: {value: string, text: string}[]
}

registerWidgetType({
    typeId: 'Select',
    metadata: {
      name: 'Simple Select Widget',
      description: '',
      icon: 'list',
    },
    defaultConfig: {
      label: 'Param',
      type: "string",
      addToStore: false,
      options: []
    },
  renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
    return <WidgetConfig
      config={config} context={context} onConfigChange={setConfig}/>
  },
    render: (config: any, context: BuilderContext, setOutput) => {
      return <Widget config={config.config} context={context} setOutput={setOutput}/>
    }
  }
)

const optionListToText = (options: {value: string, text: string}[]) => {
  return options.map((o)=> {
    if(o.text === o.value) {
      return o.value;
    } else {
      return `${o.value}:${o.text}`;
    }
  }).join("\n")
}
const optionsToList = (optionsText: string) => {
  return optionsText.split("\n").map((line) => {
    let [value, text] = line.split(":");
    text = text ?? value;
    return {value: value.trim(), text: text.trim()}
  })
}

function WidgetConfig({config, context, onConfigChange}:
                                      { config: ConfigProps, context: BuilderContext, onConfigChange: (config: any) => void }) {

  let [localConfig, setLocalConfig] = React.useState(config);
  let [optionsText, setOptionsText] = React.useState(
    optionListToText(config.options)
  );

  const handleApply = () => {
    onConfigChange({
      ...localConfig,
      options: optionsToList(optionsText)
    });
  }


  const handleOptionsChange = (value: string) => {
    setOptionsText(value);
  }



  return <>
    Label: <Input value={localConfig.label}

                  onChange={(e, data) => {
      setLocalConfig({...localConfig, label: data.value as string})
    } }/>
  <p>
    Options: <TextArea
    style={{width: '100%', height: '150px'}}
      value={optionsText}
      onChange={(e, data) => {
        handleOptionsChange(data.value as string)
      }}
    />
  </p>
    <p>
    Type: <Select options={FinancialValueTypes.map((vt)=>({value: vt, text: vt, key: vt}))}
                  defaultValue={localConfig.type ?? "number"}
                  onChange={(e, data) => {
                    setLocalConfig({...localConfig, type: data.value as FinancialValueType})
                  } }/>

    </p>
    <p>
    <Checkbox toggle checked={localConfig?.addToStore}
              onChange={(e, data) => {
                if(!!data.checked === false){
                  // @TODO
                // Should remove the li from store...
                }
                setLocalConfig({...localConfig, addToStore: !!data.checked})
              }}
              label={"Add to store"} />
    </p>
    <Button onClick={handleApply}>Apply</Button>
  </>

}

function Widget({config, setOutput, context}: {
  config: ConfigProps,
  context: BuilderContext,
  setOutput: (key: string, value: any) => void
}) {
  const globalContext = getGlobalContext(context);
  let [rawValue, setRawValue] = useState<number | string>(0);

  useEffect(()=>{
    setOutput('value', rawValue);
    if(config.addToStore){
      context.appContext.setParameter(config.label,
        convertToNumberStringOrBoolean(rawValue)
        , config.type);
    }
  }, [rawValue, config])

  useEffect(() => {
    let inputValue = context.params.value;
    setRawValue(inputValue);
  } , [context.params.value]);

  const handleChange = (value: string) => {
    setRawValue(value)
  }

  return <>

    <FormField>
        <Select options={config.options}
                placeholder={`${config.label}`}
                clearable
                defaultValue={rawValue as string}
                onChange={(e, data) =>
                  handleChange(data.value as string)}
        />
    </FormField>

  </>
}