import {Checkbox} from "semantic-ui-react";
import {RowOrderingGrid} from "./RowOrderingGrid";
import {RowOrderingTextArea} from "./RowOrderingTextArea";
import React, {useState} from "react";

export function OrderedArrayInput({items, onSelected}:{items: string[], onSelected: (selectedItems: string[])=>void}){
    let [toggleRowOrdering, setToggleRowOrdering] = useState(false);
    return <>
        <Checkbox toggle checked={toggleRowOrdering}
                  onChange={(e, data) => setToggleRowOrdering(!!data.checked)}
                  label={"Text Area Ordering"}/>

        {!toggleRowOrdering && <RowOrderingGrid data={items} onSelected={onSelected} />}
        {toggleRowOrdering && <RowOrderingTextArea items={items} onSelected={onSelected} />}
    </>
}