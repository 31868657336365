import {DashboardConfigService} from "./DashboardConfigService";
import React, {useState} from "react";
import {
  LineItemCodeEditor
} from "../../lineitems-store/lineItemEditor/LineItemCodeEditor";
import {LineItemsStore, StoreQuery} from "../../ps-models/lineitems-store";
import {buildMonthlyTimeIndex, utcDate} from "../../ps-models";
import {buildDailyTimeDef, buildTimedCalculatedLineItem} from "../../ps-models/line-items";
import {LineItemNamesSelector} from "./LineItemNamesSelector";
import {StoreQueryFlat} from "../../ps-models/lineitems-store/StoreQueryFlat";
import {DashboardLineItemExtensionsConfig} from "./DashboardConfig";
import {Button, Checkbox} from "semantic-ui-react";
import {insert, update} from "ramda";
import {tr} from "date-fns/locale";

export function LineItemsExtensionsEditor({service}: { service: DashboardConfigService }) {

  let [extensions, setExtensions] = useState(service.getConfig().getLineItemExtensions())

  const handleAddExtension = () => {
    setExtensions([...extensions, {
      code: "value*2",
      lineItemNames: [],
      excludeGroupingLineItems: true
    }])
  }
  const handleExtensionUpdated = (index: number, extensionUpdate: DashboardLineItemExtensionsConfig) => {

    setExtensions(update(index, extensionUpdate, extensions));
  }

  return <div>
    <Button onClick={handleAddExtension}>Add Extension</Button>
    {extensions.map((extension, i) => {
      return <LineItemExtension storeWithLineItems={service.getStore()}
                                onExtensionUpdated={(update) => {
                                  handleExtensionUpdated(i, update)
                                }}
                                lineItemExtension={extension} />
    })}

    <Button onClick={() => {
       service.setLineItemExtensions(extensions);
    }}>Apply</Button>
  </div>
}

export function LineItemExtension({
                                    storeWithLineItems,
                                    lineItemExtension,
                                    onExtensionUpdated
                                  }: {
  storeWithLineItems: LineItemsStore,
  lineItemExtension: DashboardLineItemExtensionsConfig,
  onExtensionUpdated: (update: DashboardLineItemExtensionsConfig) => void
}) {


  let wrapperBaseStore = new LineItemsStore(
    buildMonthlyTimeIndex(
      utcDate(2021, 1, 1),
      utcDate(2021, 12, 1)
    )
  );
  let base = buildTimedCalculatedLineItem(
    "base",
    buildDailyTimeDef(),
    `1`
  );
  wrapperBaseStore.getDataSet().addLineItem(base);

  const handleLineItemsChange = (lineItemNames: string[]) => {
    onExtensionUpdated({...lineItemExtension, lineItemNames});
  }

  const handleCodeChange = (code: string) => {
    onExtensionUpdated({...lineItemExtension, code});
  }

  const handleExcludeGroupingLineItemsChange = (excludeGroupingLineItems: boolean) => {
    onExtensionUpdated({...lineItemExtension, excludeGroupingLineItems});
  }

  return <div>
    <LineItemCodeEditor
      lineItem={base}
      code={lineItemExtension.code}
      onChanged={handleCodeChange}
    />

    <LineItemNamesSelector store={storeWithLineItems}
                           selectedLineItems={lineItemExtension.lineItemNames}
                           onSelected={handleLineItemsChange}
    />
    <p>

      <Checkbox toggle checked={lineItemExtension.excludeGroupingLineItems}
                onChange={(e, data) => {
                  handleExcludeGroupingLineItemsChange(!!data.checked)
                }}
                label={"Exclude grouping line items"}/>
      <br/>
    </p>

  </div>
}