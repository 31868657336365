import {FileName} from "../ps-models/file-upload";
import psPlatformClient from "../psPlatformClient";

async function publishTempFileUploadLink({ userId, fileName }: { userId: string; fileName?: FileName | undefined; }): Promise<any> {

}

export const getSignedUrlForFile = async (fileName: string) => {
    const result = await psPlatformClient<{fileName: string }, string>(
        {method: 'post', url: `/file/get-signed-url`,
            data: {fileName } })
    return result.data;
}
