import {useState} from "react";
import {
    isMultiFactorError,
    login,
    LoginData,
    LoginPayload, ssoAuthLogin, SSOLoginPayload,
    twoFactorAuthLogin,
    TwoFactorLoginPayload,
    verifyPhoneNumber
} from "./auth.client";
import firebase from "firebase";

export function useLogin() {
    const [twoFactor, setTwoFactor] = useState<TwoFactor | null>(null)

    const onBasicLogin = async (formData: LoginData) => {
        try {
            await login(new LoginPayload(formData))
        } catch (e: unknown) {
            if (isMultiFactorError(e)) {
                setTwoFactor(new TwoFactor(e.resolver, formData.email, setTwoFactor))
            } else {
                throw e
            }
        }
    }

    const ssoLogin = async (payload: SSOLoginPayload) =>{
        await ssoAuthLogin(payload);
    }

    return {
        twoFactor,
        onBasicLogin,
        ssoLogin
    }
}

export class TwoFactor {
    private _verificationId = ""
    constructor(
        private _resolver: firebase.auth.MultiFactorResolver,
        private _email: string,
        private _reset: (another: TwoFactor) => void,
        readonly transactionId: number = 0
    ) {}

    async initiate(recaptcha: firebase.auth.RecaptchaVerifier): Promise<void> {
        this._verificationId = await verifyPhoneNumber(this._resolver, recaptcha)
    }

    async login(otp: string): Promise<void> {
        await twoFactorAuthLogin(new TwoFactorLoginPayload(this._verificationId, otp, this._resolver), this._email)
    }

    reset(): void {
        this._reset(new TwoFactor(this._resolver, this._email, this._reset, this.transactionId + 1))
    }
}