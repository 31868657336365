import {TimeIndexEditorStandalone} from "../line-items-editor/TimeIndexEditor";
import {addDays, addYears} from "date-fns";
import {convertToNumberOrString, TimeIndex, TimeUnits} from "../../ps-models";
import {LineItemsStore} from "../../ps-models/lineitems-store";
import {useState} from "react";
import {Button, Input} from "semantic-ui-react";


export function ProjectStoreDetails({onApply}:{
  onApply: (store: LineItemsStore)=>void}) {

  let [store, setStore] = useState<LineItemsStore>();


  let initialDates = {
    startDate: new Date(),
    endDate: addYears(new Date(), 1)
  }
  let initialGranularity: TimeUnits = "months";

  const handleApply = () => {
    if(store){
      onApply(store)
    }
  }

  const handleTimeIndexUpdate = (timeIndex: TimeIndex) => {
    let updatedStore = store || new LineItemsStore(timeIndex);

    updatedStore.timeIndex = timeIndex;

    setStore(updatedStore);
  }



  return <>
   <TimeIndexEditorStandalone
     initiallySelectedDates={initialDates}
     initialGranularity={initialGranularity}
     onUpdate={handleTimeIndexUpdate}
   />

    <Button
      onClick={handleApply}
      primary
    > Save </Button>
  </>

}