import { Company } from "../ps-types";
import {AuthError} from "./errors";
import {PsUser} from "./PsUser";

let loggedInUser: PsUser | null = null
let selectedCompany: Company | null = null

export class AuthStorage {

  newLogin(user: PsUser): void {
    loggedInUser = user
    this.setDefaultCompany()
  }

  getMaybeUser(): PsUser | null {
    return loggedInUser
  }

  getUser(): PsUser {
    if (!loggedInUser) {
        throw new AuthError('User is not logged in!', "bad")
    }
    return loggedInUser
  }

  logout(): void {
    if(loggedInUser){
      console.info(`Sign Out Event :: User email ${loggedInUser?.email}`);
    }
    loggedInUser = null
  }

  setCompany(company: Company): void {
    selectedCompany = company
  }

  getMaybeCompany(): Company | null {
    return selectedCompany
  }

  getCompany(): Company {
    if (!selectedCompany) {
        throw new AuthError('Company is not set!', "bad")
    }
    return selectedCompany
  }

  setDefaultCompany() {
    selectedCompany = this.getUser().company
  }
}

export const SKIPPING_AUTH = process.env.REACT_APP_ENV?.trim()?.toLowerCase() === 'local' && process.env.REACT_APP_SKIP_AUTH?.trim()?.toLowerCase() === 'true'

if (SKIPPING_AUTH) {
    console.warn('Warning! Skipping auth')
}

export const NO_AUTH_USER_ID = 'no-auth-user'
export const NO_AUTH_COMPANY_ID = 'no-auth-company'

class NoAuthStorage extends AuthStorage {
  getUser(): PsUser {
    const email = 'dev-test-account@perlstreet.com'
    return new PsUser({} as unknown as firebase.User, NO_AUTH_USER_ID, {
      id: NO_AUTH_USER_ID,
      displayName: null,
      createdAt: new Date(),
      email,
      profile: {
        email,
        firstName: 'No Auth',
        lastName: 'User',
        displayName: 'No Auth User',
      },
      userCompany: {
        id: NO_AUTH_COMPANY_ID,
        name: 'No Auth Company',
        admins: [NO_AUTH_USER_ID],
        members: [NO_AUTH_USER_ID],
        subscriptions: []
      }
    })
  }
}

export const authStorage = SKIPPING_AUTH ? new NoAuthStorage() : new AuthStorage()
