let idleTime = 0;
const idleLimit = 20 * 60 * 1000; // 20 minutes in milliseconds

// Reset idle timer
const resetIdleTimer = () => {
  idleTime = 0;
};

// Increment the idle time counter every second
const incrementIdleTime = () => {
  idleTime += 1000;
  if (idleTime >= idleLimit) {
    // eslint-disable-next-line no-restricted-globals
    location.reload(); // Refresh the page
  }
};

// Event listeners for user activity
const setupActivityListeners = () => {
  window.addEventListener('mousemove', resetIdleTimer, false);
  window.addEventListener('keypress', resetIdleTimer, false);
  window.addEventListener('touchstart', resetIdleTimer, false);
  window.addEventListener('click', resetIdleTimer, false);
};

// Initialize the idle timer and activity listeners
const initIdleTimer = () => {
  setupActivityListeners();
  setInterval(incrementIdleTime, 1000); // Check idle time every second
};

// Start the idle timer
initIdleTimer();