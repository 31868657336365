// @TODO: These events will be deprecated, once Puck is our stable version for managing widgets
const WIDGETS_MANAGER_EVENTS = {
  WIDGET_CONFIG_CHANGED: 'widgetConfigChanged',
  WIDGET_CONFIG_SELECTED: 'widgetConfigSelected',
  WIDGET_ADDED: 'widgetAdded',
  WIDGET_DELETED: 'widgetDeleted',
}

export const EVENTS = {
  GLOBAL_CONTEXT_CHANGED: 'globalContextChanged',
  QUERY_CHANGED: 'queryChanged',
  COMPARED_WITH_STORE_IDS_CHANGED: 'comparedWithStoreIdsChanged',
  STORE_LOADING: 'storeLoading',
  STORE_LOADED: 'storeLoaded', //To be used for store initialization (Add extra line items etc)
  STORE_READY: 'storeReady', //To use after store initialization
  ...WIDGETS_MANAGER_EVENTS
}

export class EventHandler {

  private eventListeners: {name: string,  callback: (event: any)=>void}[] = [];

  addListener(event: string, callback: (event: any)=>void) {

    //only if callback is not there
    let existing = this
      .eventListeners
      .find(e => e.name === event && e.callback === callback);

    if(existing) {
      return;
    }

    this.eventListeners.push({name: event, callback})
  }

  addListeners(event: string[], callback: (event: any)=>void) {
    event.forEach(e => this.addListener(e, callback))
  }

  fireEvent(name: string, data: any) {
    this.eventListeners
      .filter(e => e.name === name)
      .forEach(e => e.callback({name, data}));

  }

  eventTimeouts: Record<string, any> = {};

  fireEventDebounced(name: string) {
    let timeout = this.eventTimeouts[name];

    if(timeout) {
      clearTimeout(timeout);
    }

    this.eventTimeouts[name] = setTimeout(() => {
      this.fireEvent(name, {});
    }, 100);

  }
}