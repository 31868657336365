import {
  useLineItemsStoreMetadata,
  usePSQuery
} from "../../../lineitems-store/LineItemsStore.hook";
import {
  CANONICAL_NAME_FIELD,
  DEFAULT_SOURCE_GRID, LineItemsStore,
  pQuery,
  QueryResult, SOURCE_NAME_FIELD,
  StoreQuery
} from "../../../ps-models/lineitems-store";
import {Grid, Header, Popup, Segment} from "semantic-ui-react";
import React from "react";
import {buildTimedCalculatedLineItem, buildYearlyTimeDef, field} from "../../../ps-models/line-items";
import {Metric, MetricProps} from "../../../statistics/Metric";
import {LineItemsTable, LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {setLabelAndFormatForAggregates, DEFAULT_TIME_AGGREGATIONS} from "./storeFormulas";
import {ClosableSection} from "../../../ClosableSection";
import {useLineItemFilter} from "../../../lineitems-store/MetadataFilters";
import MapComponent from "../../../lineitems-store/MapComponent";
import {GridOfComponents} from "../../../ui/GridOfComponents";
import {LoadingBlock} from "../../../ui/Loading";
import {RevenueVsLoanCommitments} from "./RevenueVsLoanCommitments";
import {
  buildTimeIndex,
  getLastDateOfCurrentMonthInUTC,
  getLastDateOfPreviousMonth,
  utcDate,
  getAmProjectConfig
} from "../../../ps-models";
import {authStorage} from "../../../auth";
import {useUTCDateSelector} from "../../../lineitems-store/DateRange";


    let lineItems = [
      'Customer Buy Out Price',
       'Post_Code__c',
       'Billing_Type__c',
      'Current_Energy_Retailer__c',
      'current_value_post_dep__c',
      'original_asset_value__c',
      'Net Cash Flow',

      'wamo',

      'kWp_Solar_System__c',
      'Current_Energy_Retailer__c',
      'Billing_Type__c',
      'Battery_kWh__c',
      'Net Revenue',
      'Net Cash Flow',

      'Drawn Balance',
      'Principal Repayment',
      'Interest Payment'
    ];

    let parameters = [
      'Current_Energy_Retailer__c', 'Network__c', 'State__c', 'Billing_Type__c', 'Residential_Business__c', 'SPV_Fund__c'
    ]
export function PortfolioPerformance({goToSiteDetailTab}: {goToSiteDetailTab: (id: string) => void}) {
  let company = authStorage.getCompany();
  const { collection: COLLECTION } = getAmProjectConfig(company);
  let store = usePSQuery(COLLECTION,
      pQuery()
        .selectSourceParams(parameters)
        .havingParameterValues({"SPV_Fund__c": ["NRN AC NO1", "NRN AC NO23"]})
        .withLineItems(lineItems)
        .withStoreType('document')
        .import(['aggregate'])
        .withGranularity('months')
        .withQueryName("Portfolio Performance")
      , (store) => {
        store.getDataSet().addTimedGroupingLineItemsByField(CANONICAL_NAME_FIELD, {
          defaultGroupingOperation: "sum"
        });


        store.getDataSet().addLineItem(
          buildTimedCalculatedLineItem('Number of Sites',
            buildYearlyTimeDef("first"),
            //language=JavaScript
            `f('store_sourceLineItemName', 'Post_Code__c').length`
            //Todo: This is a trick to get the number of loaded sites, we should think on a simple way to count stores, even when filtered
          )
        )

          setLabelAndFormatForAggregates(store);
      }
    );

    let storesMetadata = useLineItemsStoreMetadata(COLLECTION, pQuery().metadataOnly([
      'Location_latitude',
      'Location_longitude',
      ...parameters
    ]));
    if (!store || !storesMetadata) {
      return <LoadingBlock/>
    }

  let timeIndexUptoLastDayOfCurrentMonth = buildTimeIndex(utcDate(2024, 1, 1), getLastDateOfCurrentMonthInUTC(), store.timeIndex.getUnit(), store.timeIndex._useRawDatesToBuildIndex)
  let completeTimeIndex = buildTimeIndex(utcDate(2024, 1, 1), store.timeIndex.endDate, store.timeIndex.getUnit(), store.timeIndex._useRawDatesToBuildIndex)
  let storeTillLastDayOfCurrentMonth = store.materializeTimed(StoreQuery.all().withTimeIndex(timeIndexUptoLastDayOfCurrentMonth));
  let completeStore = store.materializeTimed(StoreQuery.all().withTimeIndex(completeTimeIndex));
  return <PortfolioPerformanceView completeStore={completeStore} storeTillLastDayOfCurrentMonth={storeTillLastDayOfCurrentMonth}  storesMetadata={storesMetadata} goToSiteDetailTab={goToSiteDetailTab} />
}

function PortfolioPerformanceView({completeStore, storeTillLastDayOfCurrentMonth, storesMetadata, goToSiteDetailTab}: {completeStore: LineItemsStore, storeTillLastDayOfCurrentMonth: LineItemsStore, storesMetadata: LineItemsStore,goToSiteDetailTab:(id: string) => void}){
  let [filterQuery, filtersComponent] = useLineItemFilter({
    [SOURCE_NAME_FIELD]: 'Site',
    'source_Current_Energy_Retailer__c': 'Retailer',
    'source_Network__c': 'Network',
    'source_State__c': 'State',
    'source_Billing_Type__c': 'Billing Type',
    'source_Residential_Business__c': 'Residential/Business',
  }, storeTillLastDayOfCurrentMonth);

  let [spvFilterQuery, spvFiltersComponent] = useLineItemFilter({
    'source_SPV_Fund__c': 'SPV',
  }, storeTillLastDayOfCurrentMonth);

  let [monthSelectorComponent, dateRange] = useUTCDateSelector('months', storeTillLastDayOfCurrentMonth);
  let [monthRangeSelectorComponent, dateRangeForChart] = useUTCDateSelector('months', completeStore, true);

  let queryWithFiltersAndGropingElements = StoreQuery
      .withField('store_groupingName').or(filterQuery.and(spvFilterQuery))
      .or(StoreQuery.byField('store_importedFrom', 'aggregate'))
      .or(StoreQuery.byNames(['Number of Sites', 'Net Revenue']))

  let spvQueryWithFiltersAndGropingElements = StoreQuery
      .withField('store_groupingName').or(spvFilterQuery)
      .or(StoreQuery.byField('store_importedFrom', 'aggregate'))
      .or(StoreQuery.byNames(['Number of Sites', 'Net Revenue']))

  let metricsMainQuery = queryWithFiltersAndGropingElements
      .aggregateOverTimeRange(dateRange.from, dateRange.to, DEFAULT_TIME_AGGREGATIONS);

  let spvMetricsMainQuery = spvQueryWithFiltersAndGropingElements
      .aggregateOverTimeRange(dateRange.from, dateRange.to, DEFAULT_TIME_AGGREGATIONS);

  let siteList = storesMetadata && storesMetadata.query(queryWithFiltersAndGropingElements);

  const metricsStore = storeTillLastDayOfCurrentMonth.view(StoreQuery.all());

  let metrics = metricsStore.materializeTimed(metricsMainQuery).query(StoreQuery.all());

  let spvMetrics = storeTillLastDayOfCurrentMonth.materializeTimed(spvMetricsMainQuery).query(StoreQuery.all());

  let metricsTable = storeTillLastDayOfCurrentMonth.query(metricsMainQuery.and(
          StoreQuery.byNames(
              [
                'Post_Code__c',
                'Billing_Type__c',
                'Current_Energy_Retailer__c',
                'kWp_Solar_System__c',
                'Battery_kWh__c',
                // 'Original Asset Value',
                'original_asset_value__c',
                // 'Current Value',
                'current_value_post_dep__c',
                'Net Cash Flow',
                'wamo',
                // 'discounted_purchase_price__c'
                'Customer Buy out Price',
              ], true
          )
      )
      , {grid: DEFAULT_SOURCE_GRID });


  let metricsChart = completeStore.materializeTimed(
      queryWithFiltersAndGropingElements.inTimeRange(dateRangeForChart.from, dateRangeForChart.to)).query(StoreQuery.all(),
  );

  if (!metrics || !spvMetrics) {
    return <LoadingBlock />
  }

  return <div className="InvestorDashboard"><Segment>
    <Header as="h2" color="purple">Portfolio Performance</Header>

    <Segment className="aggregate-filters">
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={6}>
            {monthSelectorComponent}
          </Grid.Column>
          <Grid.Column width={10}>
            {filtersComponent}
            {spvFiltersComponent}
          </Grid.Column>
        </Grid.Row>

      </Grid>
    </Segment>


    {metrics && spvMetrics && <Grid solid>
      <Grid.Row columns={2}>
        <Grid.Column>
          {siteList && <MapComponent
              onClickLocation={(site) => goToSiteDetailTab && goToSiteDetailTab(site)}
              result={siteList}/>}
        </Grid.Column>
        <Grid.Column>
          <GridOfComponents height={400}>
            <MetricWithPopup size="small" label="Funds Under Management [SPV]"
                    value={spvMetrics.firstTimeSlotTextOf('Drawn Balance')}
                     popupContent={'Total drawn loan balance as of end of selected date range.'}
            />
            <MetricWithPopup size="small" label={"Income Yield on Assets *"}
                    value={metrics.firstTimeSlotTextOf('Income Yield on Assets')}
                    popupContent={'Annualized Net Revenue divided by Total CAPEX'}
            />
            <MetricWithPopup size="small" label={"Revenue *"}
                    value={metrics.firstTimeSlotTextOf('Net Revenue')}
                    popupContent={"Sum of Daily Solar Fees, Batter Fees, and Site fees for each for each of the sites"}
            />
            <MetricWithPopup size="small" label={"DSCR [SPV] *"}
                    value={spvMetrics.firstTimeSlotTextOf('DSCR')}
                    popupContent={"Annualized Net Revenue divided by Annualized loan obligations"}
            />

            <MetricWithPopup size="small" label={"Original Value of Assets Deployed"}
                    value={metrics.firstTimeSlotTextOf('original_asset_value__c')}
                     popupContent={"Total CAPEX of all sites"}
            />
            <MetricWithPopup size="small" label={"Current Value of Assets Deployed"}
                    value={metrics.firstTimeSlotTextOf('current_value_post_dep__c')}
                    popupContent={"Total CAPEX less depreciation of all sites"}
            />
          </GridOfComponents>
        </Grid.Column>
      </Grid.Row>
    </Grid>}


    <Grid>
      <Grid.Row >
        <GridOfComponents  columns={5}>
          <MetricWithPopup size="small" label={"Customer Buy Out Price *"}
                  value={metrics.firstTimeSlotTextOf('Customer Buy Out Price')}
                           popupContent={"Total current purchase price of the portfolio"}
          />
          <MetricWithPopup size="small" label={"Number of Sites"}
                  value={metrics.firstTimeSlotTextOf("Number of Sites")}
                  popupContent={"Total number of sites in the portfolio"}
          />

          <MetricWithPopup size="small" label={"Total Solar KWP"}
                  value={metrics.firstTimeSlotTextOf('kWp_Solar_System__c')}
                   popupContent={"Total Solar Capacity installed"}
          />
          <MetricWithPopup size="small" label={"Total Battery KWH"}
                  value={metrics.firstTimeSlotTextOf('Battery_kWh__c')}
                  popupContent={"Total Battery Storage Capacity installed"}
          />

        </GridOfComponents>
      </Grid.Row>
        <i style={{position: "relative", top: -15}}>* The metrics above may not be accurate due to missing data points.
          <br/>
          [SPV] Only SPV filter applies to these metrics.
        </i>
      <Grid.Row columns={1}>
        <Grid.Column>
          <>
            {monthRangeSelectorComponent}
            <RevenueVsLoanCommitments queryResult={metricsChart} />
          </>
        </Grid.Column>

      </Grid.Row>
    </Grid>

    <LineItemsTable queryResult={metricsTable} projectionsStartDate={new Date(getLastDateOfPreviousMonth())} />

    <ClosableSection title={"Debug"} level={"small"} opened={false}>
      {storeTillLastDayOfCurrentMonth && metrics && <LineItemsTableWithFormulas store={storeTillLastDayOfCurrentMonth} withGroups
                                                       queryResult={metrics}
                                                       projectionsStartDate={new Date(getLastDateOfPreviousMonth())}
      />}
    </ClosableSection>


    <ClosableSection title={"Debug"} level={"small"} opened={false}>
      {completeStore && metricsChart && <LineItemsTableWithFormulas store={completeStore} withGroups
                                                            queryResult={metricsChart}
                                                            projectionsStartDate={new Date(getLastDateOfPreviousMonth())}
      />}
    </ClosableSection>

  </Segment></div>
}


function MetricWithPopup(props: {popupContent: string} & MetricProps){
  const {popupContent, ...metricProps} = props;
  return <Popup
      trigger={<div><Metric {...metricProps} /></div>}
      content={popupContent}
  />
}

