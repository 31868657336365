import {DefaultModule, registerModule} from "../../builder/DashboardConfigServiceRegistry";
import {SectionService} from "../../Section";
import React, {ReactNode} from "react";
import { Menu } from "semantic-ui-react";
import {NewAuthWrapper} from "../../../auth";
import {Route} from "react-router-dom";
import {Layout} from "../../layout";
import {Loans} from "./Loans";
import {Projects} from "../../projects/Projects.page";
import {LoanProjects} from "./LoanProjects";
import "./Widgets"


registerModule('CGCDemo', new class extends DefaultModule {

  overrideUIStrings() {
    return {
      "Asset Management": "Portfolio Management"
    }
  }

  getRoutes(): React.ReactNode {
      return <>
        <Route exact path='/ps/:companyId/ext/loans'
               render={ () => <NewAuthWrapper>
                 <Layout >
                     <Loans />
                 </Layout>
               </NewAuthWrapper> } />
        <Route exact path='/ps/:companyId/ext/projects/:loanId'
               render={ () => <NewAuthWrapper>
                 <Layout >
                   <LoanProjects />
                 </Layout>
               </NewAuthWrapper> } />
      </>
  }

  overrideMainMenu(path: string, mainMenu:  { key: string, menuItem: ReactNode }[], companyId: string, history: any) {
    //Insert a new menu item before the Projects menu item
    let newMainMenu = [...mainMenu];

    let projectsIndex = newMainMenu.findIndex((item) => {
      return item.key === 'projects';
    });

    newMainMenu.splice(projectsIndex, 0, {
      key: 'loans',
      menuItem: (
        <Menu.Item
          key="loans"
          active={path === 'loans'}
          onClick={() => history.push(`/ps/${companyId}/ext/loans`)}
        >
          Loans
        </Menu.Item>
      )
    });

    return newMainMenu;
  }


})