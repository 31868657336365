import * as React from "react";
import "./Platform.css";
import {ErrorContext, ErrorMessagePopup} from "./ErrorHandling";
import {UserDropdownActions} from "../components/UserDropdownActions/UserDropdownActions"
import {MainMenu} from "./MainMenu";
import {useHistory} from "react-router-dom";
import {Button, SegmentInline} from "semantic-ui-react";
import {FunctionComponent} from "react";
import {authStorage} from "./auth";
import { AUTHENTICATION_PATHS } from "../constants";

const {Container, Grid, Header, Image} =  require("semantic-ui-react");

export type NewLayoutParams = {
  header: JSX.Element | string,
  subHeader?: JSX.Element | string,
  leftFloatedActions?: JSX.Element,
  topContext?: FunctionComponent<{children: React.ReactNode | React.ReactNode[]}>,
  actions?: JSX.Element,
  children: JSX.Element | JSX.Element[],
  topActions?: JSX.Element
  contentWide?: boolean
  disableErrorToast?: boolean
  topId?: string
};


function RenderChildren({children}: {children: JSX.Element | JSX.Element[]}) {
  return <>{children}</>
}

export function NewLayout({header, topId, subHeader, leftFloatedActions, actions, children, contentWide, topActions, topContext, disableErrorToast}: NewLayoutParams) {
  const currentUser = authStorage.getUser();
  const history = useHistory();
  const TopContext = topContext ?? RenderChildren

  return <div id={topId}>
    <ErrorContext>
      <TopContext>
          <Container fluid className={"ps-top"}>
            <Grid colums={2}>
              <Grid.Row columns={2}>
                <Grid.Column>
                    <a href={AUTHENTICATION_PATHS.root}>
                      <Image style={{paddingBottom:'6px'}} src={"/logos/Perl-Logo-Wide-Black-2x.png"} className="left logo" />
                    </a>
                    <MainMenu />
                </Grid.Column>
                <Grid.Column verticalAlign={'top'}>
                  <SegmentInline style={{float:'right'}}>
                    <Button style={{color: "#6435C9"}} onClick={() => history.push(AUTHENTICATION_PATHS.root)}>Account Home</Button>
                    {topActions}
                    {currentUser && <UserDropdownActions currentUser={currentUser} renderCompanySelectorForAdmin={true} />}
                  </SegmentInline>
                </Grid.Column>
              </Grid.Row>
            </Grid>

          </Container>
        <Container fluid className={"ps-header-container"}>
          <Container>
            <Grid className="ps-header">
              <Grid.Row >
                <Grid.Column width={13}>
                  <Header>{header}</Header>
                  <span>{subHeader}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={13}>{leftFloatedActions}</Grid.Column>
                <Grid.Column width={3}>
                  {actions}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Container>
        <Container fluid={contentWide ?? false}>
          {!disableErrorToast && <ErrorMessagePopup header={''} autoDismissIn={15000}/>}
          {children}
        </Container>
      </TopContext>
    </ErrorContext>
  </div>
}

