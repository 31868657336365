import React, {useState} from 'react';
import {
    Button,
    ButtonProps,
    List,
    Segment,
} from 'semantic-ui-react';

// @TODO: Think of a better name for this component
const ButtonWithListOfOptions = <T extends string>(props: {options: T[], handleOptionClick: (option: T)=>void;} & ButtonProps)=> {
    const {options, handleOptionClick, ...btnProps} = props;
    const [displayOptions, setDisplayOptions] = useState(false);

    const ListItems = <Segment raised style={{
        position: 'absolute',
        zIndex: 100,
        marginTop: 0,
    }}>{options.map((it)=> {
            return <List
                key={it}
                relaxed
                selection
                verticalAlign='top'
            >
                <List.Item content={it} onClick={()=>{
                    handleOptionClick(it)
                    setDisplayOptions(false)
                }} />
            </List>
        }
    )}</Segment>;

    return <><Button style={{position: 'relative', display: 'block'}}
                     onClick={()=>setDisplayOptions(prev=>!prev)}
                     {...btnProps}
    />
        {displayOptions === true && <>{ListItems}</>}</>
}

export default ButtonWithListOfOptions;