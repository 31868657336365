import React, {useEffect, useState} from "react";
import {map, mapObjIndexed, path, prop, sortBy} from "ramda";
import {DeleteButton} from "./DeleteButton.component";
import {EntityDef, FieldDef, FormDef} from "./types";
import {UpdateEntity} from "./UpdateEntity.component";
import {buildForm} from "./common/Field";
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid-pro';
import {GridToolbar} from "@mui/x-data-grid";
const {Table} = require("semantic-ui-react");

export interface ColumnDef {
  key: string,
  title: string,
  type: "text" | "hidden",
  format: (value: any, entity: Record<string, any>) => string,
  render: (value: any, entity: any) => JSX.Element
}

export interface TableDef {
  entityName: string,
  columns: {[key: string] : ColumnDef }
  sortBy?: string
  reverse?: boolean
  getEntities:  (() => Promise<any[]>) | any[],
  deleteEntity?:  (entity: any) => Promise<any>,
  updateEntity?: (entity: any) => Promise<any>,
  customEntityFormActions?: React.FC<{entityName: string, entity: any, entityDef: EntityDef, onActionCompletion: {
    createAction: (entity:any) =>void,
    readAction: (entity:any) =>void,
    updateAction: (entity: any)=> void,
    deleteAction: (entity: any)=>void,
    }}>[],
  deleteButton?: React.FC<{entityName: string, entity: any, handleDelete: (entity: any) => void}>
  updateButton?: React.FC<{entityDef: EntityDef, entityName: string, entity: any, handleUpdate: (entity: any) => void}>,
  tableComponent?: 'simple' | 'dataGrid'
  disableColumnFilter?: boolean,
  disableColumnSelector?: boolean,
  disableDensitySelector?: boolean,
  disableToolbarButton?: boolean,
  actionColumnTitle?: string,
  selectedRow?: string,
  actionColumnWidth?: number,
  entityColumnWidth?: number,
  defaultPageSize?: number
}

export type EntityTableParams = {
  tableDef: TableDef
  newest?: any,
  entityDef: EntityDef
};

export function EntityTable({  tableDef, newest, entityDef }: EntityTableParams) {

  const {getEntities} = tableDef;
  let [items, setItems] = useState<Record<string, any>[]>( []);
  let [newestFromWithinTable, setNewestFromWithinTable] = useState<any | undefined>();
  let updateEntityFormDef = tableDef?.updateEntity && toUpdateEntityFormDef(entityDef);

  useEffect(() => {
    if(typeof getEntities == "function") {
      getEntities().then(
        res => {
          setItems(res)
        }
      );
    } else {
      setItems(getEntities)
    }
  }, [getEntities]);

  if(tableDef.sortBy) {
    items = sortBy(prop(tableDef.sortBy), items);
  }

  if(tableDef.reverse) {
    console.info("Its reversing")
    items = items.reverse();
  }

  useEffect(() => {
    if(newest) {
      setItems((items) => [newest].concat(items));
    }
  }, [newest]);

  useEffect(() => {
    if(newestFromWithinTable) {
      setItems((items) => [newestFromWithinTable].concat(items));
    }
  }, [newestFromWithinTable]);

  const handleDelete = (entity: any) => {
    tableDef.deleteEntity && tableDef.deleteEntity(entity);
    setItems((items) => items.filter(it => it["id"] !==entity["id"] ));
  }

  const handleCreate = (entity: any) => {
    setNewestFromWithinTable(entity);
  }

  const handleUpdate = (entity: any) => {
    setItems((items) =>
      items.map(it => {
        if(it["id"] ===entity["id"]){
          return entity;
        } else {
          return it;
        }
      })
    );
  }

  if(!tableDef.tableComponent || tableDef.tableComponent === 'simple') {
    return <SimpleTable
      items={items}
      tableDef={tableDef}
      entityDef={entityDef}
      updateEntityFormDef={updateEntityFormDef}
      handleCreate={handleCreate}
      handleUpdate={handleUpdate}
      handleDelete={handleDelete}
    />
  }

  return <div><CustomDataGrid
    items={items}
    tableDef={tableDef}
    entityDef={entityDef}
    updateEntityFormDef={updateEntityFormDef}
    handleCreate={handleCreate}
    handleUpdate={handleUpdate}
    handleDelete={handleDelete}
  /></div>


}



function SimpleTable({items, tableDef, entityDef,
                       handleCreate, updateEntityFormDef,
                       handleUpdate,
                       handleDelete}:
                       CustomDataGridProps ) {
  return <Table >
    <Table.Header>
      <Table.Row>
        {Object.values(tableDef.columns).map(def=> {
            if(def.type === "hidden") return null;
            return <Table.HeaderCell key={def.key}>
              {def.title.toUpperCase()}
            </Table.HeaderCell>
          }
        )}
      </Table.Row>
    </Table.Header>
    <Table.Body>

      {items.map(item =>
      {
        const isRowSelected = tableDef.selectedRow && item.id === tableDef.selectedRow;
        return <Table.Row key={item.id}>
          {Object.values(tableDef.columns).map(def => {
              if (def.type === "hidden") return null;
              return <Table.Cell key={def.key} >
                <div style={{maxWidth: "100%", overflow: "hidden"}}>
                  {def.render(def.format(path(def.key.split("."), item), item), item)}
                </div>
              </Table.Cell>
            }
          )}
          {(tableDef.deleteEntity || tableDef.updateEntity) &&
              <Table.Cell>
                {(tableDef?.customEntityFormActions?.map((comp, index)=>{
                    return <span key={index}>{comp(
                      {
                        entityDef: entityDef,
                        entity: item, entityName: tableDef.entityName,
                        onActionCompletion: {
                          createAction: handleCreate,
                          readAction: ():void=>{},
                          updateAction: handleUpdate,
                          deleteAction: handleDelete,
                        }
                      })}</span>
                  }
                ))}
                {(updateEntityFormDef && tableDef.updateEntity) && (tableDef.updateButton ? tableDef.updateButton({entityName: tableDef.entityName, entity: item, handleUpdate, entityDef}):
                    (updateEntityFormDef ? <UpdateEntity
                      {...updateEntityFormDef}
                      entity={item}
                      updateEntity={tableDef.updateEntity}
                      onEntityUpdated={handleUpdate}
                    />: <></>)
                )}

              {
                 !isRowSelected && tableDef.deleteEntity && (tableDef.deleteButton ? tableDef.deleteButton({ entityName: tableDef.entityName, entity: item, handleDelete }) :
                    <DeleteButton
                      content={`Are you sure you want to delete the 
                             ${tableDef.entityName} '${item["name"]}'?`}
                      onDelete={() => handleDelete(item)}
                    />
                  )}
              </Table.Cell> }
        </Table.Row>
      }
      )}

    </Table.Body>
  </Table>
}



function DefaultRender({value}: {value: any}) {
  return <>{value}</>;
}

export function buildEntityTableDef(
  def: Partial<TableDef>,
  columns: Record<string, Partial<ColumnDef>>): TableDef {
  let columnsWithValues: {[key: string] : ColumnDef } = mapObjIndexed((v, k) => ({
    type: "text",
    key: k,
    title: k,
    format: (value: any) => value,
    render: (value: any) => <DefaultRender value={value} />,
    ...v
  }), columns);

  return {
    entityName: "",
    getEntities: def.getEntities!,
    ...def,
    columns: columnsWithValues
  }
}


function toUpdateEntityFormDef(def: EntityDef) {
  return buildForm(
      // @ts-ignore
      { ...def, ...(def?.create ?? {}), ...(def?.update ?? {})},
      map((p) => ({
        key: p.key,
        label: p.title,
        type: "text",
        ...p.create,
        ...p.update
      } as FieldDef), def.props)
  );
}

interface CustomDataGridProps {
  items: Record<string, any>[];
  entityDef: EntityDef;
  tableDef: TableDef;
  updateEntityFormDef?: FormDef;
  handleCreate: (item: any) => void;
  handleUpdate: (item: any) => void;
  handleDelete: (item: any) => void;
}

function CustomDataGrid({ items, tableDef, entityDef, handleCreate, handleUpdate, handleDelete }: CustomDataGridProps): JSX.Element {
  // Prepare columns for DataGrid
  const columns: GridColDef[] = Object.values(tableDef.columns).filter(def => def.type !== "hidden").map(def => ({
    field: def.key,
    headerName: def.title.toUpperCase(),
    width: tableDef.entityColumnWidth || 150,
    renderCell: (params: GridRenderCellParams<any>) => (
      <div style={{ maxWidth: "100%", overflow: "hidden" }}>
        {def.render(def.format(path(def.key.split("."), params.row), params.row), params.row)}
      </div>
    ),
    valueGetter: (params) => {
      const formatValue = def.format(path(def.key.split("."), params.row), params.row);
      const renderValue = def.render(def.format(path(def.key.split("."), params.row), params.row), params.row).props.children
      return renderValue ? renderValue : formatValue
    }
  }));

  // Add action column if needed
  if (tableDef.updateEntity || tableDef.deleteEntity) {
    columns.push({
      field: "actions",
      headerName: tableDef.actionColumnTitle || "Actions",
      sortable: false,
      width: tableDef.actionColumnWidth || 150,
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          {tableDef.customEntityFormActions?.map((comp, index) => (
            <span key={index}>{comp({
              entityDef: entityDef,
              entity: params.row,
              entityName: tableDef.entityName,
              onActionCompletion: {
                createAction: handleCreate,
                readAction: () => {},
                updateAction: handleUpdate,
                deleteAction: handleDelete,
              }
            })}</span>
          ))}
          {tableDef.updateEntity && (
            <button onClick={() => handleUpdate(params.row)}>
              Update
            </button>
          )}
          {tableDef.deleteEntity && (
            <>
            {tableDef.selectedRow !== params.row.id && (
              tableDef.deleteButton ? tableDef.deleteButton({ entityName: tableDef.entityName, entity: params.row, handleDelete }) :
              <DeleteButton
              content={`Are you sure you want to delete the 
                ${tableDef.entityName} '${params.row["name"]}'?`}
                onDelete={()=> handleDelete(params.row)}
                /> )}
            </>
          )}
        </>
      )
    });
  }

  // Prepare rows with an id
  const rows = items.map(item => ({ id: item.id, ...item }));

  return (
    <div style={{  width: '100%' }}>
      <DataGridPro
        rows={rows}
        slots={{
        toolbar: GridToolbar
        }}
        disableColumnFilter={ tableDef?.disableColumnFilter ?? false }
        disableColumnSelector={ tableDef?.disableColumnSelector ?? false }
        disableDensitySelector={ tableDef?.disableDensitySelector ?? false }
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: tableDef?.defaultPageSize || 40 } },
          pinnedColumns: { left: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'name', GRID_TREE_DATA_GROUPING_FIELD]
          } }}
        autoHeight
        pagination={rows.length>1}
        slotProps={{

          toolbar: {
            printOptions: { disableToolbarButton: tableDef?.disableToolbarButton ?? false },
            csvOptions: { disableToolbarButton: tableDef?.disableToolbarButton ?? false },
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          }
        }}
      />
    </div>
  );
}