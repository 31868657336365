import React, {useEffect, useState} from "react";
import {LineItemsStore} from "../../ps-models/lineitems-store";
import {buildTimeIndex} from "../../ps-models";
import {addMonths} from "date-fns";
import {Button, Form, Input, Message, Segment} from "semantic-ui-react";
import {REFETCH_ACTION, useUpdateContext} from "../../UpdateContext";

export function StoreInfoEditor({setSelectedSite, store, setMessage}: {
  store?: LineItemsStore,
  setMessage: (message: string) => void,
  setSelectedSite: (site: string) => void }) {

  let [updatedStore, setUpdatedStore] = useState(store || new LineItemsStore(
    buildTimeIndex(
      new Date(),
      addMonths(new Date(), 1),
      "days",
    ),
    {
      name: "",
      id: ""
    }
  ));

  let ctx = useUpdateContext();

  useEffect(() => {
    if(store) {
      setUpdatedStore(store);
    }
  }, [store]);

  let [storeName, setStoreName] = useState<string>(updatedStore.getName() || "");
  let [storeId, setStoreId] = useState<string>(updatedStore.getId() || "");
  let [isTemplate, setIsTemplate] = useState<boolean>(updatedStore.isTemplate || false);
  let [imports, setImports] = useState<string>(updatedStore.getImports().join(", "));

  useEffect(() => {
    if(store) {
      setStoreName(updatedStore.getName() || "");
      setStoreId(updatedStore.getId() || "");
      setIsTemplate(updatedStore.isTemplate || false);
      setImports(updatedStore.getImports().join(", "));
    }
  }, [store]);


  const handleNameChange = (name: string) => {

    let idFromName = storeName.toLowerCase().replace(/ /g, "-");
    if (idFromName === storeId) {
      setStoreId(name.toLowerCase().replace(/ /g, "-"));
    }
    setStoreName(name);
  }


  const handleApplyChanges = () => {
    if (!storeName.trim() || !storeId.trim()) {
      setMessage("Please fill in the name and id");
      return;
    }

    updatedStore.setName(storeName);
    updatedStore.setId(storeId);
    if(!isTemplate) {
      updatedStore.setStoreType("document");
      updatedStore.setImports(
        imports.split(",").map(s => s.trim()).filter(s => s.length > 0)
      );
    } else {
      updatedStore.setStoreType("template");
      updatedStore.setImports([]);
    }


    if(!store) {

      setStoreName("");
      setStoreId("");
      setIsTemplate(false);
      //setSelectedSite(storeId);
      ctx!.setService(updatedStore);
    }
    //ctx!.fireAction(REFETCH_ACTION);
    //ctx!.reload()
  }

  return <div>
    <Form>
      <Input required placeholder="Name" value={storeName} onChange={(e, {value}) => handleNameChange(value)}/>
      <Input required placeholder="Id" value={storeId} onChange={(e, {value}) => setStoreId(value)}/>
      <Form.Checkbox label="Is Template" checked={isTemplate} onChange={(e, {checked}) => setIsTemplate(!!checked)}/>

      {!isTemplate && <Input placeholder="Imports" value={imports}
                             onChange={(e, {value}) => setImports(value)} />}

      <Button onClick={handleApplyChanges}>
        Apply
      </Button>
    </Form>
  </div>

}