import ActionTypes from './admin.types';
import { fsAssignUser } from './admin.firebase';

import {getUsers as getUsersFromPlatform} from "../../platform/user/user.client";
import {getCompanies as getCompaniesFromPlatform} from "../../platform/company/company.client";

// Assign User

export const assignUserStart = () => ({
  type: ActionTypes.ASSIGN_USER_START
});

export const assignUserSuccess = (users) => ({
  type: ActionTypes.ASSIGN_USER_SUCCESS,
  payload: users
});

export const assignUserFailure = (emsg) => ({
  type: ActionTypes.ASSIGN_USER_FAILURE,
  payload: emsg
});

export const assignUser = async (dispatch, userId, companyId) => {
  dispatch(assignUserStart());
  try {
    const res = await fsAssignUser(userId, companyId);
    dispatch(assignUserSuccess({ userId, companyId }));
    return res;
  } catch (err) {
    const emsg = err.toString();
    dispatch(assignUserFailure(emsg));
    return new Error("Assign user failed: ",emsg);
  }
}

// Get Users

export const getUsersStart = () => ({
  type: ActionTypes.GET_USERS_START
});

export const getUsersSuccess = (users) => ({
  type: ActionTypes.GET_USERS_SUCCESS,
  payload: users
});

export const getUsersFailure = (emsg) => ({
  type: ActionTypes.GET_USERS_FAILURE,
  payload: emsg
});

export const getUsers = () => {
  return async (dispatch) => {
    dispatch(getUsersStart());
    try {
      const users = await getUsersFromPlatform();
      dispatch(getUsersSuccess(users));
    } catch (err) {
      const emsg = err.toString();
      console.error("Get users failed: ", emsg);
      dispatch(getUsersFailure(emsg));
      return new Error("Get users failed: ",emsg);
    }
  };
}

// Get Companies

export const getCompaniesStart = () => ({
  type: ActionTypes.GET_COMPANIES_START
});

export const getCompaniesSuccess = (companies) => ({
  type: ActionTypes.GET_COMPANIES_SUCCESS,
  payload: companies
});

export const getCompaniesFailure = (emsg) => ({
  type: ActionTypes.GET_COMPANIES_FAILURE,
  payload: emsg
});

export const getCompanies = () => {
  return async (dispatch) => {
    dispatch(getCompaniesStart());
    try {
      const companies = await getCompaniesFromPlatform();
      dispatch(getCompaniesSuccess(companies));
    } catch (err) {
      const emsg = err.toString();
      console.error("Get companies failed: ",emsg)
      dispatch(getCompaniesFailure(emsg));
      return new Error("Get companies failed: ",emsg);
    }
  };
}