import {LineItemsStore, PersistenceQuery} from "../../../ps-models/lineitems-store";
import {StoreQueryFlat} from "../../../ps-models/lineitems-store/StoreQueryFlat";
import {QueryLineItemNamesPicker} from "./QueryLineItemNamesPicker";

export function QueryLineItemNonMaterializedItemsEditor<T extends PersistenceQuery | StoreQueryFlat>({query, store, onQueryUpdate}: {query: T,store?: LineItemsStore, onQueryUpdate: (updatedQuery: T) =>void}) {
    return <QueryLineItemNamesPicker initiallySelectedLineItems={query._calculatedLineItems ?? []} sectionTitle={'Items not to materialize'} store={store} query={query} onQueryUpdate={onQueryUpdate}
                                  onItemSelection={(localQuery, lineItemsToSelect)=>{
                                      localQuery.withCalculatedLineItems(lineItemsToSelect);
                                  }}
/>
}