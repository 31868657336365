import { useDashboardService } from "../../platform/assets-management/builder/DashboardConfigService";
import { useUpdateOnGlobalContextChange } from "../../platform/assets-management/builder/widgets/commons";
import {
  FilterProps,
  useLineItemFilter,
} from "../../platform/lineitems-store/MetadataFilters";
import {PersistenceQuery, StoreQuery} from "../../platform/ps-models/lineitems-store";
import { buildQueryFromFilters } from "../../platform/assets-management/scenarios/ScenarioFilter.component";
import { useEffect, useMemo, useState } from "react";
import { usePSQuery } from "../../platform/lineitems-store/LineItemsStore.hook";
import { authStorage } from "../../platform/auth";
import {getAmProjectConfig, ZERO_TIME_INDEX} from "../../platform/ps-models";
import {clone} from "ramda";

export function ScenarioFilters({setClearButtonDisabled}: {setClearButtonDisabled: (bool: boolean) => void;}) {
  let { getService } = useDashboardService();
  const dService = getService();
  const [initFilter, setInitFilter] = useState({});
  const store = dService.getStore();
  let query = dService.getConfig().getQuery();
  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);
  let serializedQueryInstance = clone(query.serialize());
  serializedQueryInstance._selectsFirstParamValues = [];

  useUpdateOnGlobalContextChange({
    appContext: dService,
    id: "ctxLoader.ScenarioFilters",
  });

  let filterConfig: Record<string, Partial<FilterProps> | string> = {};

  dService
    .getConfig()
    .getFilters()
    .forEach((filter) => {
      const filterFieldName = (filter.getFromSourceStore || filter.getFromPersistanceStore) ? `source_${filter.fieldName}` : filter.fieldName;
      filterConfig[filterFieldName] = {
        label: filter.fiterLabel,
        multiSelect: !filter.singleSelect,
        formattedOptions: filter?.formattedOptions,
        getFromSourceStore: filter?.getFromSourceStore,
        getFromPersistanceStore: filter?.getFromPersistanceStore,
        required: filter?.required,
      };
    });

  useEffect(() => {
    const serializedHavingParamsValue = serializedQueryInstance._havingParamValues
    if(Object.keys(serializedHavingParamsValue).length){
      for (let key in serializedHavingParamsValue) {
        const filterConfigKey = `source_${key}`;
        if (filterConfigKey in filterConfig && !(filterConfig[filterConfigKey] as FilterProps)?.multiSelect) {
          delete serializedHavingParamsValue[key]
        }
      }
    }
  })

  let metadataStore = usePSQuery(collection, PersistenceQuery.deserialize(serializedQueryInstance).withTimeIndex(ZERO_TIME_INDEX));
  useEffect(() => {
    const init: Record<string, string[]> = {};
    for (let [key, val] of Object.entries(filterConfig)) {
      let filterDef: string | Partial<FilterProps> = val;
      if(typeof filterDef !== "string"){
        if (filterDef.required) {
          const value = store?.getExecution()
            .getFieldValues(key)
          if (value && value.length) {
            init[key as string] = [value[0] as string];
          } else {
            console.warn("No value");
          }
        }
      }
    }
    console.log("Initialized", init)
    setInitFilter(init)

  }, [dService.getGlobalContext()?.filterDetails?.filtersMap])

  const initialSelection = useMemo(() => {
    return {
      ...dService.getGlobalContext()?.filterDetails?.filtersMap,
      ...initFilter
    }
  }, [dService.getGlobalContext()?.filterDetails?.filtersMap, initFilter])

  useEffect(()=>{
    dService.getConfig().setQuery(
      dService.getConfig().getQuery().havingParameterValues({...initFilter, ...dService.getConfig().getQuery().havingParameterValues})
    );
  },[])
  const onFilterChange = (
    query: StoreQuery,
    selectedFilters: Record<string, string[]>,
    key: string
  ) => {
    console.info("On Filter Change", selectedFilters);
    // Global Context holds everything that we want to persist for runtime.
    const filterConfigValue = filterConfig[key];
    if (typeof filterConfigValue === 'object' && filterConfigValue !== null && 'getFromPersistanceStore' in filterConfigValue && filterConfigValue.getFromPersistanceStore) {
      const updatedObj: any = {};
      for (const key in selectedFilters) {
        const newKey = key.replace(/^source_/, '');
        updatedObj[newKey] = selectedFilters[key];
      }
      const firstParamValues =  dService.getConfig().getQuery()._selectsFirstParamValues.filter((value: string)=>{
        return `source_${value}` !== key || value !== key
      } )
      if(filterConfigValue.multiSelect){
        dService.getConfig().setQuery(
          dService.getConfig().getQuery().havingParameterValues(updatedObj).selectsFirstParamValues([])
        );
        setInitFilter(selectedFilters)
      }else{
        dService.getConfig().setQuery(
          dService.getConfig().getQuery().havingParameterValues(updatedObj).selectsFirstParamValues(firstParamValues)
        );
      }
      setClearButtonDisabled(false)
    } else {
      dService.updateGlobalContext({
        filterDetails: {
          filterQuery: buildQueryFromFilters(selectedFilters),
          filtersMap: selectedFilters,
          initialFiltersMap: dService.getGlobalContext()?.filterDetails?.initialFiltersMap,
        },
      });
    }
  };

  let [, filterComponent] = useLineItemFilter(filterConfig, metadataStore,store, {
    onFilterChange,
    initialSelection,
  });

  return <div>{filterComponent}</div>;
}
