import React, {useState} from "react";
import {Button, Form, Input, Modal, TextArea} from "semantic-ui-react";
import {CompanyKey} from "../../ps-types/CompanyKeys";
import {useCreateCompanyKey} from "./companyKeys.client";


export default function CreateCompanyKey({onCreated}: { onCreated: (data: Partial<CompanyKey>) => void}) {
  const createCompanyKey = useCreateCompanyKey()

  const [open, setOpen] = useState(false);
  const [data, setData] = useState<Partial<CompanyKey>>({})

  const handleCreate = () => {
    let keyData = { keyId: data.keyId, publicKey: data.publicKey };
    createCompanyKey(data.keyId!, data.publicKey!)
      .then(res => {
        onCreated(keyData);
        setOpen(false);
      });
  }

  return <div>
    <Button primary onClick={() => setOpen(true)}>Add Public Key</Button>
    <form onSubmit={e => {
      e.preventDefault();
      handleCreate();
    }}>

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header>Key Name</Modal.Header>
        <Modal.Content>
          <Form.Group>
            <Input
              className="form-item"
              onChange={(e, {value}) => setData({...data, keyId: value}) }
              fluid
              placeholder='Use a unique name for your key'/>

            <TextArea placeholder='Public Key'
                      className="form-item"
                      fluid
                      onChange={(e, {value}) => setData({...data, publicKey: value?.toString()}) }
            />
          </Form.Group>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button
            content="Next"
            labelPosition='right'
            icon='checkmark'
            onClick={() => handleCreate()}
            positive
          />

        </Modal.Actions>

      </Modal></form>
  </div>
}