import psPlatformClient from "../../psPlatformClient";
import {AmDashboard, AmDashboardCreate} from "../../ps-types";
import {createNewDashboardConfig} from "./DashboardConfig";


export const getAmDashboard = async (companyId: string, dashboardId: string) => {
  const result = await psPlatformClient<{companyId: string, dashboardId: string}, AmDashboard>(
    {method: 'get', url: `/am/${companyId}/dashboard/${dashboardId}`,
      data: {companyId, dashboardId}})
  return result.data;
}

export const createAmDashboard = async (companyId: string, data:AmDashboardCreate) => {
  const result = await psPlatformClient<AmDashboardCreate, AmDashboard>(
    {method: 'post',
      url: `/am/${companyId}/dashboard`,
      data: {
        ...data,
        data: createNewDashboardConfig(data.v2).serialize()
      }
    })
  return result.data;
}

export const updateAmDashboard = async (companyId: string, dashboard: AmDashboard, reportTemplateExcelFile?: File) => {
    const formData = new FormData();
    if(reportTemplateExcelFile){
        formData.append('reportTemplateExcelFile', reportTemplateExcelFile);
    }
    formData.append('dashboard', JSON.stringify(dashboard));

  const result = await psPlatformClient<FormData, AmDashboard>(
    {method: 'put',
      url: `/am/${companyId}/dashboard/${dashboard.id}`,
      data: formData
    })
  return result.data;
}



export const getAmDashboards = async (companyId: string) => {
  const result = await psPlatformClient<{companyId: string}, AmDashboard[]>(
    {method: 'get', url: `/am/${companyId}/dashboard`,
      data: {companyId}})
  return result.data;
}


export const deleteAmDashboard = async (companyId: string, dashboardId: string) => {
  const result = await psPlatformClient<{companyId: string, dashboardId: string}, any>(
    {method: 'delete', url: `/am/${companyId}/dashboard/${dashboardId}`,
      data: {companyId, dashboardId}})
  return result.data;
}



