import React, {useEffect, useMemo, useState} from 'react';
import {groupBy, values} from "ramda";
import {Button, Icon, Input, Segment, Table} from "semantic-ui-react";
import {compareField, LineItemsFieldSet} from "../../ps-models/line-items";
import {PairTableRow, PairTable} from "../../ui/PairTable";
import {DIFFERENCES_CONSTANT} from "./lineItemBatchOperations";
import {InputWithTooltip} from "../../ui/InputWithTooltip";
import {LineItemField} from "../../ps-types";


type ListOfFields = LineItemField[]

interface Props {
    initialData: LineItemsFieldSet;
    differences: FieldDifferences;
    onStateChanged: (state: FieldEditorState) => void;
}

function required(index: number, fieldKey: string, fields: any[], newMessages: Record<string, any>) {
    if (!fields[index][fieldKey]?.trim()) {
        newMessages[index] = {...newMessages[index], [fieldKey]: "Required"};
    }
}

type GroupId = string
type FieldId = string
export type FieldDifferences = Record<FieldId, {
    values: Record<GroupId, PairTableRow>,
    labels: Record<GroupId, PairTableRow>
}>

export interface FieldEditorState {
    fields: ListOfFields,
    deleted: LineItemField[],
    canSave: boolean,
    modified: boolean
}

export function makeInitialFieldEditorState(): FieldEditorState {
    return {fields: [], deleted: [], canSave: false, modified: false}
}

export function BatchFieldsEditor({initialData, differences, onStateChanged}: Props): JSX.Element {
    const [fields, setFields] = useState<ListOfFields>(() => values(initialData.getFieldMap()).sort(compareField));
    const [deleted, setDeleted] = useState<LineItemField[]>([]);
    const messages = useMemo(() => {
        const newMessages: Record<string, any> = {}
        for (let i = 0; i < fields.length; i++) {
            required(i, "name", fields, newMessages)
            required(i, "value", fields, newMessages)
        }
        return newMessages
    }, [fields])

    const modified = useMemo(() => initialData.getAllKeys().length !== fields.length || fields.some((field, index) => {
        const initialField = initialData.getField(field.name);
        return field.label !== initialField?.label || field.value !== initialField?.value?.toString();
    }), [fields, initialData])

    const canSave = useMemo(() => {
        const enoughFields = fields.length > 0
        const nonEmptyFields = !fields.some(f => f.name === '')
        const uniqueFields = Object.values(
            groupBy(f => f.name, fields)
        ).every(g => g.length === 1)

        const areRequiredFieldsValid = Object.keys(messages).length === 0
        return enoughFields && modified && nonEmptyFields && uniqueFields && areRequiredFieldsValid
    }, [fields, initialData, messages, modified])

    useEffect(() => {
        onStateChanged({fields, deleted, canSave, modified})
    }, [onStateChanged, fields, deleted, canSave, modified])

    function handleChange(key: number, field: keyof LineItemField, value: string) {
        if (value.startsWith(DIFFERENCES_CONSTANT) && value.length > DIFFERENCES_CONSTANT.length) {
            value = value.slice(DIFFERENCES_CONSTANT.length)
        }
        const updatedData = fields.map((item, index) => {
            if (index === key) {
                return {...item, [field]: value};
            }
            return item;
        });

        setFields(updatedData);
    }

    function handleAdd() {
        setFields(prev => ([{name: '', label: '', value: ''}] as ListOfFields).concat(prev));
    }

    function handleDelete(keyToDelete: number) {
        setFields(fields.filter((_, key) => key !== keyToDelete))
        if (initialData.getField(fields[keyToDelete].name) !== undefined) {
            setDeleted(prev => prev.concat(fields[keyToDelete]))
        }
    }

    function handleRevertDeleted() {
        setFields(prev => prev.concat(deleted).sort(compareField));
        setDeleted([]);
    }
    
    return (
        <div>
            <Segment>
                <h5>Fields
                    {deleted.length > 0 && <Button floated="right" size="mini" onClick={handleRevertDeleted}>Restore {deleted.length} deleted Fields</Button>}
                </h5>

                <Table basic='very' celled collapsing style={{width: "100%"}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Label</Table.HeaderCell>
                            <Table.HeaderCell>Value</Table.HeaderCell>
                            <Table.HeaderCell>
                                <Button icon size="mini" onClick={handleAdd}>
                                    <Icon name='plus'/>
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {fields.map((fieldData, key) => (

                            <Table.Row>
                                <Table.Cell>
                                    <Input
                                        style={{width: "100%"}}
                                        error={!!(messages[key]?.name)}
                                        required
                                        disabled={initialData.getField(fieldData.name) !== undefined}
                                        size="mini" onChange={(e) => handleChange(key, "name", e.target.value)}
                                        value={fieldData.name}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <InputWithTooltip
                                        tooltipCondition={differences[fieldData.name] && Object.keys(differences[fieldData.name].labels).length > 0}
                                        tooltipContent={() => <PairTable pairs={differences[fieldData.name].labels} description={"This field has different labels in different sites"} header={{left: "Site", right: "Value"}} />}
                                        style={{width: "100%"}}
                                        size="mini"
                                        valueChanged={initialData.getField(fieldData.name) !== undefined && fieldData.label !== initialData.getField(fieldData.name)?.label}
                                        onChange={(e: any) => handleChange(key, "label", e.target.value)}
                                        onRestore={() => handleChange(key, "label", initialData.getField(fieldData.name)?.label || "")}
                                        value={fieldData.label}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <InputWithTooltip
                                        tooltipCondition={differences[fieldData.name] && Object.keys(differences[fieldData.name].values).length > 0}
                                        tooltipContent={() => <PairTable pairs={differences[fieldData.name].values} description={"This field has different values in different sites"} header={{left: "Site", right: "Value"}} />}
                                        style={{width: "100%"}}
                                        error={!!(messages[key]?.value)}
                                        valueChanged={initialData.getField(fieldData.name) !== undefined && fieldData.value !== initialData.getField(fieldData.name)?.value}
                                        required
                                        size="mini"
                                        onChange={(e: any) => handleChange(key, "value", e.target.value)}
                                        onRestore={() => handleChange(key, "value", initialData.getField(fieldData.name)?.value?.toString() || "")}
                                        value={fieldData.value}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Button size="mini" icon onClick={() => handleDelete(key)}>
                                        <Icon name='trash'/>
                                    </Button>
                                </Table.Cell>
                            </Table.Row>

                        ))}
                    </Table.Body>
                </Table>
            </Segment>


        </div>
    );
}
