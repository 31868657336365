import React, {useEffect, useState} from "react";
import { FileUploader } from "react-drag-drop-files";
import {Button, Icon, Progress} from "semantic-ui-react";
import {useErrorHandling} from "../ErrorHandling";

type ACCEPTED_FILE_TYPES = "csv" | "xls" | "xlsx" | "xlsm";

const ALL_ACCEPTED_FILE_TYPES: ACCEPTED_FILE_TYPES[] = ["csv", "xls", "xlsx", "xlsm"] as ACCEPTED_FILE_TYPES[];

export interface FileUploadProps {
  setFile: (file: File | undefined) => void,
  uploadProgress?: number,
  updater?: number
  acceptableFileTypes?: ACCEPTED_FILE_TYPES[]
}

export function PsFileUploader({ uploadProgress, setFile, updater=0, acceptableFileTypes = ALL_ACCEPTED_FILE_TYPES }: FileUploadProps) {
  const { handleError } = useErrorHandling();
  const [fileStaged, setFileStaged] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");

  useEffect(()=>{
    clearFile()
  }, [updater])

  const handleChange = async (file: File) => {
    try {
      const fileExtension = file.name.split('.').pop();

      if (fileExtension && !acceptableFileTypes.includes(fileExtension as any)) {
        throw new Error(`The uploaded template should have one of the following extension ${acceptableFileTypes.join(', ')}`);
      }

      setFile(file);
      setFileStaged(true);
      setFileName(file.name);
    } catch (err) {
      handleError({err});
      clearFile();
    }
  };


  const clearFile = () => {
    setFileStaged(false);
    setFileName("");
    setFile(undefined);
  }


  return (
    <>
      <div className="psFileUploader" style={{marginBottom: "20px"}}>
        <FileUploader
          handleChange={handleChange} name="file" types={acceptableFileTypes}>
          {fileStaged ? (<>
            <div className="text">

              <div className="text">
               Selected file <strong>{fileName}</strong>
              </div>

              {uploadProgress !== undefined && <Progress percent={uploadProgress} autoSuccess className="bar" />}

              <Button onClick={clearFile}>Cancel</Button>
            </div>
          </>
          ) : (<>
            <Icon name="file alternate outline" /> <u>Select file</u> or drag and drop here
          </>)}

        </FileUploader>
      </div>
    </>

  );
}