import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";

import "./assets/fomantic/dist/semantic.css";

import HomePage from '../src/pages/homepage/HomePage.component';
import HowItWorksPage from '../src/pages/how-it-works/HowItWorksPage.component';
import HaaSPage from './pages/haas/HaaSPage.component';
import FAQsPage from '../src/pages/faqs/FAQsPage.component';
import AboutPage from '../src/pages/about/AboutPage.component';
import WhyDebtPage from '../src/pages/why-debt/WhyDebtPage.component';
import Dashboard from './components/Dashboard/Dashboard.component';
import CompanyView from './components/Admin/Companies/CompanyView.component';
import AdminDashboard from './components/Admin/AdminDashboard.component';

import "./App.css";
import Platform from "./platform";
import userflow from "userflow.js";
import {
  authStorage,
  NewAuthWrapper,
  PsUser
} from "./platform/auth";
import {AUTHENTICATION_PATHS} from "./constants";

type AppComponentProps = {}
type AppComponentState = {currentUser: PsUser | null};

class NewApp extends Component<AppComponentProps> {
  state: AppComponentState = {
    currentUser: null
  }
  componentDidMount() {
    this.state.currentUser = authStorage.getMaybeUser();
  }
  componentDidUpdate(prevProps: Readonly<AppComponentProps>, prevState: Readonly<AppComponentState>, snapshot?: any) {
  if (process.env.REACT_APP_USERFLOW_TOKEN && prevState.currentUser && this.state.currentUser !== prevState.currentUser) {
  const user = this.state.currentUser;
  if(user){
    userflow.identify(user.id, {
      name: user.data.firstName ?? "" + user.data.lastName ?? "",
      email: user.email,
      signed_up_at: new Date(user.data.createdAt.getTime()*1000).toISOString(),
    }).then((res)=> {
      console.info("User session registered with userflow");
    }).catch((err)=>{
      console.error("Unable to register user session with userflow", err);
    });
  }
}
}

render() {
  return (
      <div>
        <Switch>
          <Route exact path="/" render={() => <ProtectedDashboardPage />} />
          <Route exact path="/ds-check"
                 render={
                    (props) =>
                      <div>Faulty Version! 2024.04.19 02:04</div>}

          />
          <Route
              path="/admin"
              render={(props) => (
                  <NewAuthWrapper>
                    <AdminDashboard {...props} />
                  </NewAuthWrapper>
              )}
          />
          <Route exact path='/admin/companies/:id' render={ (props) =>
              <NewAuthWrapper><CompanyView {...props} /></NewAuthWrapper>
          } />
          <Route exact path='/how-it-works' component={HowItWorksPage} />
          <Route exact path='/haas' component={HaaSPage} />
          <Route exact path='/about' component={AboutPage} />
          <Route exact path='/why-debt' component={WhyDebtPage} />
          <Route exact path='/faqs' component={FAQsPage} />
          <Route exact path={AUTHENTICATION_PATHS.login}
                 render={
                   (props) => <ProtectedDashboardPage originallyRequestedUrl={props.match.path} />}
          />
          <Route exact path={AUTHENTICATION_PATHS.signup}
                 render={
                   (props) => <ProtectedDashboardPage originallyRequestedUrl={props.match.path} />}
          />
          <Route exact path={AUTHENTICATION_PATHS.resetPassword}
                 render={
                   (props) => <ProtectedDashboardPage originallyRequestedUrl={props.match.path} />}
          />
          <div id="ps-platform">
            {Platform()}
          </div>

        </Switch>
      </div>
  );
}
}

function ProtectedDashboardPage({originallyRequestedUrl}: {originallyRequestedUrl?: string}){
  return <NewAuthWrapper
                         renderLoginPageIfNotAuthenticated={originallyRequestedUrl === AUTHENTICATION_PATHS.login}
                         renderSignUpPageIfNotAuthenticated={originallyRequestedUrl === AUTHENTICATION_PATHS.signup}
                         renderResetPasswordPageIfNotAuthenticated={originallyRequestedUrl === AUTHENTICATION_PATHS.resetPassword}
  >
    <Dashboard />
  </NewAuthWrapper>
}

export default NewApp;

