import {FormInputProps} from "semantic-ui-react/dist/commonjs/collections/Form/FormInput";
import {Form} from "semantic-ui-react";
import React from "react";

export function OtpFormInput(props: FormInputProps) {
    return <Form.Input
        {...props}
        onChange={(event, data) => {
            const {value} = event.target;
            if (/[^0-9]/.test(value)) {
                return;
            }
            if (value.length > 6) {
                return;
            }
            props.onChange?.(event, data)
        }}
        maxLength="6"
    />
}

export function PhoneFormInput(props: FormInputProps) {
    return <Form.Input
        {...props}
        onChange={(event, data) => {
            const {value} = event.target;
            if (/[^0-9]/.test(value)) {
                return;
            }
            props.onChange?.(event, data)
        }}
    />
}
