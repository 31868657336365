import {ErrorType, LineItemError} from "./LineItemMetadata";
import {LineItemsFieldSet} from "./LineItemsFieldSet";
import {TimeRange, TimeUnit, TimeUnits} from "../Time.types";
import {PartialExecution} from "../lineitems-store/PartialExecution";
import {LineItemValue, ValueType} from "./LineItemValue.model";
import {compareNormalized} from "../line-item-utils/coding.utils";
import {truncateTime} from "../TimeGranularity";
import {AggregatorMethod} from "../line-item-utils/aggregators";


export abstract class LineItem {

  private lastErrors: Record<string, LineItemError> = {}


  abstract get type(): string;

  constructor(public name: string,
              public fields: LineItemsFieldSet = new LineItemsFieldSet()) {}

  abstract getValue(time: number, report: PartialExecution, granularity?: TimeUnits): LineItemValue;

  abstract getTotal({start, end}: TimeRange, report: PartialExecution, aggregator?: AggregatorMethod): ValueType;

  get canonicalName() {
    return this.fields.getFieldStr('cname') || this.name
  }

  nameMatches(name: string) {
    return compareNormalized(this.name,name) || compareNormalized(this.canonicalName, name)
  }

  setFields(fields: LineItemsFieldSet) {
    this.fields = fields
  }

  get errors() {
    return this.lastErrors;
  }

  get label() {
    return this.fields.getFieldStr("store_label") || this.name
  }

  clearErrors() {
    this.lastErrors = {}
  }

  addError(t: number, kind: ErrorType, ctx: Error) {
    this.lastErrors[t.toString()] = {kind, ctx}
  }

  clone(): LineItem {
     throw new Error("Not implemented");
  }


  abstract serialize(): any
}