import {LineItemsStore, StoreQuery} from "../ps-models/lineitems-store";
import React, {useEffect, useMemo, useState} from "react";
import {entries} from "../ps-models";
import {MetadataFieldFilter} from "./MetadataFieldFilter";

export type FilterProps = {
  label: string,
  multiSelect: boolean,
  lineItemName: string,
  formattedOptions?: boolean,
  getFromSourceStore?: boolean,
  getFromPersistanceStore?: boolean,
  required?: boolean
}

let buildFilterProps = (allFilters: Record<string, Partial<FilterProps> | string>): Record<string, FilterProps> => {
  let result: Record<string, FilterProps> = {};
  for (let [key, value] of entries(allFilters)) {
    if (typeof value === "string") {
      result[key] = {label: value, multiSelect: false, lineItemName: key, formattedOptions: false};
    } else {
      result[key] = value as FilterProps;
    }
  }
  return result;
}




export function useLineItemFilter(allFilters: Record<string, Partial<FilterProps> | string>,
                                  store?: LineItemsStore,
                                  defaultStore?: LineItemsStore,
                                  options?: {
        onFilterChange?:(query: StoreQuery, selectedFilters: Record<string, string[]>, key: string)=>void;
        initialSelection?: Record<string, string[]>
}
): [StoreQuery, React.JSX.Element, Record<string, string[]>] {
  const [filterState, setFilterState] = useState<{filters: Record<string, string[]>, query: StoreQuery}>({filters:{}, query: StoreQuery.all() })
  const filterProps = buildFilterProps(allFilters);

  const onChange = (value: string[], key: string)=>{
    setFilterState((prev)=>{
      const newFilters = {...prev.filters, [key]: value}
      let newState = {filters: newFilters, query: StoreQuery.all()};
      options?.onFilterChange?.(newState.query, newState.filters, key);
      return newState;
    });
  }

  // useEffect(()=>{
  //   if(Object.keys(filterState.filters).length ===0) return;
  //   options?.onFilterChange?.(filterState.query, filterState.filters);
  // }, [filterState])

  useEffect(() => {
    console.info("Setting initial selection", options?.initialSelection)
    if(options?.initialSelection) {
      setFilterState({filters: options.initialSelection, query: StoreQuery.all()});
    } else {
      setFilterState({filters: {}, query: StoreQuery.all()});
    }
  }, [options?.initialSelection, store]);

  let component = useMemo(() => {
    if(!store || !defaultStore) {
      return <>Loading</>;
    }
    let filters = entries(filterProps);
    return <>
      {filters.map(([key, value], index) => {
        return <MetadataFieldFilter
          onChange={(value) => onChange(value, key)}
          placeholder={value.label}
          fieldName={key}
          store={store}
          defaultStore={defaultStore}
          direction={"right"}
          initialSelections={filterState.filters[key]}
          multiple={value.multiSelect}
          formattedOptions={value.formattedOptions}
          required={value.required}
          getFromPersistanceStore={value.getFromPersistanceStore || false}
        />
      })}
    </>
  }, [filterProps, filterState.filters, store, options?.initialSelection]);

  if (!store) {
    return [filterState.query, <></>, filterState.filters];
  }

  return [filterState.query, <div style={{display: 'flex', width: "100%", justifyContent: 'flex-start', flexWrap: 'wrap',  alignItems: 'center', gap: "4px"}}>{component}</div>, filterState.filters]}