import {normalizeString} from "../line-item-utils/coding.utils";

export const NumericFinancialValueTypes= [
    "number",
    "kwh",
    "percentage",
    "percent",
    "percentX100",
    "kWh/kWp/yr",
    "kWac",
    "c/kWh",
    "kwp",
    "kw",
    "mw",
    "bool",
    "rupee",
    "australianDollars",
    "multipleOf",
    "dollarX1000",
    "dollarthousand",
    "dollarmillion",
    "dollars",
    "$/MW p.a."
] as const;

const StringFinancialValueTypes = [
    "string",
    "zip",
] as const;

export const DateFinancialValueTypes = [
    "date",
    "utc_mmddyyyy",
    "utc_mmyyyy",
     "utc_ddmmyy",
    "utc_mmm-yy",
    "utc_dd-mmm-yy"
] as const;

export const FinancialValueTypes = [...NumericFinancialValueTypes, ...StringFinancialValueTypes, ...DateFinancialValueTypes];

export type FinancialValueType = typeof NumericFinancialValueTypes[number] | typeof StringFinancialValueTypes[number] | typeof DateFinancialValueTypes[number];
export const VALUE_TYPE_KEY = normalizeString("store_valueType")

export function storeValueTypeField(ty: FinancialValueType) {
    return  { [VALUE_TYPE_KEY]: ty}
}

