import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';
import {firebaseConfig} from './firebase.config';
import {initialiseAnalytics} from "../platform/analytics/AnalyticsFacade";


// Init firebase
firebase.initializeApp(firebaseConfig);

// Init analytics
initialiseAnalytics()

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();

export const AZURE_ENTRA_SAML_AUTH_PROVIDER_ID = 'saml.azure-entra-v2'

export default firebase;
