import { createSelector } from 'reselect';

export const selectAdminState = state => state.admin;

// Users

export const selectUsers = createSelector(
  [selectAdminState],
  (admin) => admin.users
);

export const selectUsersData = createSelector(
  [selectUsers],
  (users) => users.data
);

export const selectUsersStatus = createSelector(
  [selectUsers],
  (users) => users.status
);

// Companies

export const selectCompanies = createSelector(
  [selectAdminState],
  (admin) => admin.companies
);

export const selectCompaniesData = createSelector(
  [selectCompanies],
  (companies) => companies.data
);

export const selectCompaniesStatus = createSelector(
  [selectCompanies],
  (companies) => companies.status
);