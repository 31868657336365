import {useUpdateContext} from "../../UpdateContext";
import {useSiteStoreContext} from "../siteStoreLoader";
import {LineItemsStore, StoreQuery} from "../../ps-models/lineitems-store";
import React, { useState } from "react";
import {
  buildParameterLineItem,
  buildTimedRawLineItem,
  ParameterLineItem,
  TimedRawLineItem
} from "../../ps-models/line-items";
import {LineItemsTableWithDatePagination} from "../../lineitems-store/LineItemsTableView";
import {LineItemEditor} from "../../lineitems-store/lineItemEditor/LineItemEditor";
import {Select} from "semantic-ui-react";
import {TimeUnits} from "../../ps-models";

export function LineItemsEditorTable({localUnsyncedStore}: {localUnsyncedStore?: LineItemsStore}) {
  const ctx = useUpdateContext();
  let store = useSiteStoreContext();

  if (localUnsyncedStore !==undefined){
    store = localUnsyncedStore;
  }

  let [granularity, setGranularity] = useState<TimeUnits>("days");

  let result = store.query(
    StoreQuery.all().withTimeIndex(store.timeIndex
      .withGranularity(granularity)),
    {withLineItemNameColumn: true, showGranularity: true, withMetadata: []});

  let handleSave = (row: any) => {
    let li = store.getDataSet().getLineItem(row.id);

    if (li instanceof ParameterLineItem) {
      let sli = li as ParameterLineItem
      let newRawLineItem = buildParameterLineItem(li.name, 0, li.fields.clone());


      //There is no simple way to get just the cell that changed,
      // so we have to go thru the whole row to check which one is different :(
      for(let t of store.timeIndex.timeLine) {
        if (sli.getValue().value !== row[t.time]) {
          newRawLineItem.add(0, row[t.time]);
          break;
        }
      }

      store.getDataSet().addLineItem(newRawLineItem);

      store.clearExecutionCache()
      if(!localUnsyncedStore){
        ctx.ready();
      }
      return row;
    }

    if (li instanceof TimedRawLineItem) {
      let newRawLineItem = buildTimedRawLineItem(li.name, li.timeDefinition, li.fields.clone());

      store.timeIndex.timeLine.forEach(t => {
        newRawLineItem.add(t.time, row[t.time]);
      });

      store.getDataSet().addLineItem(newRawLineItem);
      store.clearExecutionCache()
      if(!localUnsyncedStore){
        ctx.ready();
      }
      return row;
    }

  }

  return <div>
    <Select
      placeholder='Select granularity'
      value={granularity}
      options={[
        { key: 'days', value: 'days', text: 'Day' },
        { key: 'months', value: 'months', text: 'Month' },
        { key: 'years', value: 'years', text: 'Year' },
      ]}
      onChange={(e, {value}) => {
        setGranularity(value as TimeUnits);
      }}
    />

    <LineItemsTableWithDatePagination
      onRowSelected={(row) => {
        console.info("Selected ", store.getDataSet().getLineItem(row as string));
      }}
      queryResult={result}
      onUpdated={(row) => {
        return handleSave(row);
      }}
      readonly={false}
      lineItemDetailComponent={row => <div>{row.id && <LineItemEditor lineItemName={row.id} store={store}/>}</div>}
    />
  </div>
}

