import {InvalidArgument} from "../ps-models";

export class AuthError extends InvalidArgument {
    constructor(readonly title: string, readonly message: string, readonly code: string = '') {
        super(message)
    }
}

export class OtpError extends AuthError {
    constructor(message: string) {
        super('Code is wrong', message)
    }
}

export class PhoneError extends AuthError {
    constructor(message: string) {
        super('Phone number is wrong', message)
    }
}

export class LoginError extends AuthError {
    constructor(message: string) {
        super('User could not be logged in.', message)
    }
}

export class MissingUserEmailError extends AuthError {
    constructor(message: string) {
        super('Email address has not been verified.', message)
    }
}

export class RegistrationError extends AuthError {
    constructor(message: string, code: string = '') {
        super('Could not create account.', message, code)
    }
}

export class ResetPasswordError extends AuthError {
    constructor(message: string) {
        super('Could not reset password.', message)
    }
}
