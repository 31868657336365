import {StoreQuery} from "../../../ps-models/lineitems-store";
import {LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {TimeSeriesChart} from "../../../lineitems-store/TimeSeriesChart";
import {useSiteStoreContext} from "../../siteStoreLoader";
import {useFrequencySelect} from "../../../lineitems-store/FrequencySelect";
import {Header, Segment} from "semantic-ui-react";
import {formatRows, fromNestedList} from "../../../lineitems-store/TableFormat";
import {fromPairs} from "ramda";
import {DemoTimeSeriesChart} from "./DemoTimeSeriesChart";

export function CustomerSaving() {

  let store = useSiteStoreContext();
  let [select, frequency] = useFrequencySelect();

  // Billing Type, Current Billing - Daily Rate, DMO, Btm Consumption, Grid Consumption, All Consumption, AUC Charged, Customer Bill Pre NRN, Customer Bill Post NRN, Customer Savings.
  let lineItems = ["Billing_Type__c", "Current Billing - Daily Rate", "DMO - Single Tariff",
    "Btm Consumption", "Grid Consumption", "All Consumption", "AUC Charged", "Customer Bill Before Savings", "Customer Bill Post Savings", "Customer Savings"];

  let tableQuery = StoreQuery.byNames(lineItems);
  let chartQuery = StoreQuery.byNames(["Customer Bill Post Savings", "Customer Bill Before Savings", 'Customer Savings']);

  if (frequency === 'monthly') {
    tableQuery = tableQuery.monthly();
    chartQuery = chartQuery.monthly();
  }

  let table = store.query(tableQuery);

  let formatted = formatRows(fromNestedList(
    { ...fromPairs(lineItems.map(li => [li, {}])) }
  ), table, {rowsWithTotals: ['Customer Savings']} )

  let chart = store.query(chartQuery);

  return <Segment basic>
    <Header as="h2" color="purple">Customer Savings</Header>

    <Segment>{select}</Segment>
     <LineItemsTableWithFormulas queryResult={formatted} store={store} />
     <DemoTimeSeriesChart
       title="" result={chart}
       options={{type: 'bar'}}
     />
  </Segment>
}