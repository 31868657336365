import {TimeUnit} from "../Time.types";

export type ValueType = number | string | boolean;

export class LineItemValue {
  constructor(public value: ValueType, public error?: "no-data" | "calculation-error") {
  }
}

export function valueTypeDefault(value: ValueType): ValueType {
  switch (typeof value) {
    case "number":
      return 0;
    case "string":
      return "";
    case "boolean":
      return false;
    default:
      return value;
  }
}

class NoDataValue extends LineItemValue {
  constructor() {
    super(0, "no-data")
  }
}
export const NO_DATA = new LineItemValue(0, "no-data");
export const DATA_ERROR = new LineItemValue(0, "calculation-error");


export function filterNoDataValues(values: LineItemValue[]): LineItemValue[] {
  return values.filter(v => !!v.error)
}

export interface LineItemChanges {
  changes: LineItemChange[]
}

interface LineItemChange {
  name: string
  time: TimeUnit
  value: ValueType
}
