import {LineItemsStore} from "../../../ps-models/lineitems-store";
import {
  buildDailyTimeDef, buildMonthlyTimeDef,
  buildTimedCalculatedLineItem,
  field, LineItemsFieldSet
} from "../../../ps-models/line-items";
import {normalizeMapKeys, storeValueTypeField} from "../../../ps-models";




export function setSourceForDemo(store: LineItemsStore) {
  let sourceMap: Record<string, string> = normalizeMapKeys({
    'Debt Balance': 'Sage Intacct',
    'Cash Balance': 'Sage Intacct',
    'Net Cash Flow': 'Financial Analytics Module',
    'Net Asset Cost Basis': 'Financial Analytics Module',
    'Current Value': 'Financial Analytics Module'
  });

  let dataSet = store.getDataSet();

  for(let [key, value] of Object.entries(sourceMap)) {
    dataSet.getByField('store_sourceLineItemName', key).forEach(li => {
      dataSet.addFieldToLineItem(li.name, field('data_source', value))
    });
    dataSet.addFieldToLineItem(
        key,
        field('data_source', value)
    )
  }
}

export function setLabelAndFormatForAggregates(store: LineItemsStore) {

  let labelsMap: Record<string, string> = {
    'grid_import_kwh': 'Grid Import',
    'generation_export_kwh': 'Generation Export',
    'all_consumption_kwh': 'Total Consumption',
    'generation_import_kwh': 'Generation Import',
    'pv_generation_kwh': 'PV Generation',
    'grid_export_kwh': 'Grid Export',
    'siteunavailable': 'Site Unavailable',
    'batteryunavailable': 'Battery Unavailable',
    'solarunavailable': 'Solar Unavailable',
    'total_purchase_price__c': 'Total Purchase Price',
    'discounted_purchase_price__c': 'Discounted Purchase Price',
    'current_value_post_dep__c': 'Current Value Post Depreciation',
    'original_asset_value__c': 'Original Asset Value',
    'months_active__c': 'Months Active',
    'monthly_depreciated_value__c': 'Monthly Depreciated Value',
    'billing_type__c': 'Billing Type',
    'kWp_Solar_System__c': 'kWp Solar System',
    'Battery_kWh__c': 'Battery kWh',
    'Current_Energy_Retailer__c': 'Current Energy Retailer',
    'Original_Asset_Value__c': 'Original Asset Value',
    'State__c': 'State',
    'Post_Code__c': 'Postcode',
    'City__c': 'City',
    'Investment Returns IRR': 'IRR'
  }


  let formatMap: Record<string, string> = normalizeMapKeys({
    'Original_Asset_Value__c': 'dollars',
    'Current_Value_Post_Dep__c': 'dollars',
    'BTM PPA Revenue': 'dollars',
    'Net Export Revenue': 'dollars',
    'Grid Services Revenue': 'dollars',
    'Grid Charging Cost': 'dollars',
    'Retail Import Cost': 'dollars',
    'Grid Battery Charge (kWh)': 'dollars'
  });

  for (let [key, value] of Object.entries(formatMap)) {

    store.getDataSet().getByField('store_sourceLineItemName', key).forEach(li => {
      store.getDataSet().addFieldToLineItem(li.name, field('store_valuetype', value))
    });

    store.getDataSet().addFieldToLineItem(
      key,
      field('store_valuetype', value)
    )
  }


  for(let [key, value] of Object.entries(labelsMap)) {
    store.getDataSet().getByField('store_sourceLineItemName', key).forEach(li => {
      let site = store.getDataSet().getLineItem(li.name).fields.getFieldStr('store_sourceName');
      store.getDataSet().addFieldToLineItem(li.name, field('store_label', `${site} - ${value}`))
    });
    store.getDataSet().addFieldToLineItem(
      key,
      field('store_label', value)
    )
  }

  store.getDataSet().addFieldToLineItem(
    "Investment Returns IRR",
    field("store_label", "Portfolio IRR")
  )

}

export function addAlertLineItems(store: LineItemsStore) {

  store.getDataSet().addLineItems([
    buildTimedCalculatedLineItem(
      "Investment Returns IRR - alert",
      buildDailyTimeDef(),
      //language=JavaScript
      `"Investment Returns IRR" < 13`,
      {
        "alertOf": "Investment Returns IRR"
      }
    ),
    buildTimedCalculatedLineItem(
      "Net Cash Flow - alert",
      buildDailyTimeDef(),
      //language=JavaScript
      `"Net Cash Flow" < 500000`,
      {
        "alertOf": "Net Cash Flow",
      }
    ),
    buildTimedCalculatedLineItem(
      "Net Cash Flow - children-alert",
        buildDailyTimeDef(),
      //language=JavaScript
      ` sumOver(f('rootAlert', 'NetCashflow - alert')) > 1 `,
      {
        "alertOf": "Net Cash Flow",
      }
    ),
    buildTimedCalculatedLineItem(
      "Investment Returns IRR - children-alert",
        buildDailyTimeDef(),
      //language=JavaScript
      ` sumOver(f('rootAlert', 'Investment Returns IRR - alert')) > 1 `,
      {
        "alertOf": "Investment Returns IRR",
      })
  ]);


  //Adds alerts per site
  let sites = store.getDataSet().getAggregatedStoreIds()

  //add alerts per site
  sites.forEach((site) => {
    store.getDataSet().addLineItems([
      buildTimedCalculatedLineItem(
        `${site} - Investment Returns IRR - alert`,
          buildDailyTimeDef(),
        //language=JavaScript
        `"${site}-Investment Returns IRR" < 13`,
        {
          "rootAlert": 'Investment Returns IRR - alert',
          "alertOf": `${site}-Investment Returns IRR`,
        }
      ),
      buildTimedCalculatedLineItem(
        `${site} - NetCashflow - alert`,
          buildDailyTimeDef(),
        //language=JavaScript
        `"${site}-Net Cashflow" < 50000`,
        {
          "rootAlert": 'Net Cash Flow - alert',
          "alertOf": `${site}-Net Cash Flow`,
        }
      )
    ]);
  });
}

export function addSageIntacctDemoData(store: LineItemsStore){
  // @TODO Remove:: just for demoing
  store.getDataSet().addLineItems([buildTimedCalculatedLineItem('Debt Balance', buildMonthlyTimeDef(),`
        let baseValue = 240000;
        if(dt === 0){
            return baseValue;
        } else if(dt ===1){
            return baseValue - (5500)
        } else if(dt === 2){
            return baseValue - (5500) - (6500)
        } else {
            return baseValue - (5500) - (6500) - (5050)
        }
        ` ,LineItemsFieldSet.fromMap(storeValueTypeField('dollars')))
    ,
    buildTimedCalculatedLineItem('Cash Balance', buildMonthlyTimeDef(),`
        let baseValue = 240000;
        if(dt === 0){
            return baseValue*2.5;
        } else if(dt ===1){
            return (baseValue + (9000))*2.5;
        } else if(dt === 2){
            return (baseValue + (2*8000))*2.5;
        } else {
            return (baseValue + (3*5000))*2.5;
        }
    ` ,LineItemsFieldSet.fromMap(storeValueTypeField('dollars')))
  ])
}