import Chart from "react-apexcharts";
import * as React from "react";
import {ApexOptions} from "apexcharts";
import {QueryResult, TimeColumn} from "../ps-models/lineitems-store";
import {Segment} from "semantic-ui-react";
import {compareNormalized, FinancialValueType, normalizeString, valueAsNumber} from "../ps-models";
import { formatValueWithValueType } from "../ps-models/formatting";

import {ValueType} from "../ps-models/line-items";

export type CategoryChartOptions = {
  type?: ApexChart['type'];
}

export interface CategoryChartProps {
  title: string,
  result: QueryResult,
  fieldName: string,
  options?: CategoryChartOptions
}

export function   CategoryChart({title, result, options, fieldName}:CategoryChartProps) {

  let series: any = [{
    name: title,
    data: []}];

  let seriesByLineItem = new Map<string, any>();


  result.rows.forEach((row) => {

    let lineItemName = result.rowColumn(row, 'fields.cname')?.text;
    let field = result.rowColumn(row, fieldName)?.text;
    let value = result.firstTimeSlotValueOf(row.name.text);

    if(field === undefined || value === undefined || lineItemName === undefined){
      return;
    }

    if(!seriesByLineItem.has(field)){
      seriesByLineItem.set(field, {
        name: field,
        data: []
      })
    }

    seriesByLineItem.get(field).data.push({
      x: lineItemName,
      y: valueAsNumber(value)
    })

  });

    series = Array.from(seriesByLineItem.values());
console.info("series", series)

 //
 // let series =  [{
 //   data: [{
 //     x: 'Apple',
 //     y: 54
 //   }, {
 //     x: 'Orange',
 //     y: 66
 //   }],
 // }]

  let defaultOptions: ApexOptions =  {
   //Dollar format for y axis.
    yaxis: {
        labels: {
            formatter: function(val: number) {
              //number 2 fixed points
            return formatValueWithValueType(val, "dollars", 2);
            }
        }
        },
    xaxis: {
      type: 'category'
    },
    dataLabels: {
      formatter(val: string | number | number[], opts?: any) {
        return formatValueWithValueType(val as any, "dollars", 2);
      }
    }
  };


  return <Segment ><Chart
    options={defaultOptions}
    series={series}
    type='bar'
    width="100%"
    height="300"
  /></Segment>
}

function getRelativePercentageText(relativeLiName: string, val: number, opts: any): string{
  const relatedLiSeriesIndex = opts.w.globals.seriesNames.findIndex((s:string)=>s === relativeLiName);
  if(opts.seriesIndex === relatedLiSeriesIndex || relatedLiSeriesIndex === -1){
    return "";
  }
  const relatedDataPoint = opts.w.globals.series[relatedLiSeriesIndex][opts.dataPointIndex];
  if(relatedDataPoint === 0){
    return "- %";
  }
  return (val / relatedDataPoint * 100).toFixed(2) + '%';
}
