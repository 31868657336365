import {Container, Tab} from "semantic-ui-react";
import {WhatIfScenariosConfig} from "./WhatIfScenariosConfig";
import {SalesforceConfig} from "./SalesforceConfig";
import {SalesforceConfigV2} from "./SalesforceConfigV2";


export function CompanyLevelConfig() {

  let tabs = [
    {menuItem: 'What If Scenarios', render: () => <WhatIfScenariosConfig />},
    {menuItem: 'Salesforce', render: () => <SalesforceConfig />},
    {menuItem: 'Salesforce 2', render: () => <SalesforceConfigV2 />},
  ]

  return <Container>
    <h1>Company Level Config</h1>
        <Tab panes={tabs} />
    </Container>
}