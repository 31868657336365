const ActionTypes = {

  // Assign User
  ASSIGN_USER_START: 'admin/asignUser/start',
  ASSIGN_USER_SUCCESS: 'admin/asignUser/success',
  ASSIGN_USER_FAILURE: 'admin/asignUser/failure',

  // Get Users
  GET_USERS_START: 'admin/getUsers/start',
  GET_USERS_SUCCESS: 'admin/getUsers/success',
  GET_USERS_FAILURE: 'admin/getUsers/failure',

  // Get Companies
  GET_COMPANIES_START: 'admin/getCompanies/start',
  GET_COMPANIES_SUCCESS: 'admin/getCompanies/success',
  GET_COMPANIES_FAILURE: 'admin/getCompanies/failure',

};

export default ActionTypes;