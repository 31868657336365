import {isTimedLineItem, LineItem, TimeDefinition, TimedLineItem} from "../../ps-models/line-items";
import {LineItemsStore} from "../../ps-models/lineitems-store";
import React, {useEffect, useState} from "react";
import {useUpdateContext} from "../../UpdateContext";
import {Button, Input, Segment} from "semantic-ui-react";
import {AggregatorMethod, TimeUnits} from "../../ps-models";


export function TimedLineItemEditor({onTimeDefinitionUpdated, timeDefinition}: {
  timeDefinition: TimeDefinition,
  onTimeDefinitionUpdated: (timeDefinition: TimeDefinition) => void
}) {

  const handleGranularityChange = (value: string) => {
    onTimeDefinitionUpdated(
      new TimeDefinition(timeDefinition.aggregator, timeDefinition.spread, value as TimeUnits)
    );
  }

  const handleAggregatorChange = (value: string) => {
    onTimeDefinitionUpdated(
      new TimeDefinition(value as AggregatorMethod, timeDefinition.spread, timeDefinition.granularity)
    );
  }

  return <>
    <div>
      Granularity: <Input size="mini" value={timeDefinition.granularity}
                          onChange={(e, {value}) =>
                            handleGranularityChange(value as TimeUnits)
                          }/>
    </div>
    <div>
      Aggregator: <Input size="mini" value={timeDefinition.aggregator}
                         onChange={(e, {value}) =>
                            handleAggregatorChange(value as AggregatorMethod)
                          }/>
    </div>
  </>
}