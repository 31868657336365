import {Container} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {getSignedUrlForFile} from "../file-upload/file-upload.client";
import {LoadingBlock} from "./Loading";

export const StaticPDFPreview = ({ fileName }: {fileName: string}) => {
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
        getSignedUrlForFile(fileName)
            .then(data => setFileUrl(data))
            .catch(error => console.error('Error fetching signed URL:', error));
    }, []);

    return (
        <Container textAlign="center">
            {!fileUrl && <LoadingBlock />}
            {fileUrl && (
                <iframe
                    src={fileUrl}
                    style={{ width: '100%',
                        height: '1080px',
                        border: 'none' }}
                    title="PDF Preview"
                />
            )}
        </Container>
    );
};
