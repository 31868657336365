import {useEffect, useState} from "react";
import {
  ConfigType,
  SalesforceConfig,
  SalesforceReportCellMapping,
  WhatIfScenarioConfig,
  WhatIfScenarioMappingConfig
} from "../ps-types";
import {useCompanyId} from "../core";
import {useMessages} from "../ui/MessagesProvider";
import {getAmCompanyConfig, saveAmCompanyConfig} from "./company.client";
import {Button, Input, Select} from "semantic-ui-react";
import {indexBy, values} from "ramda";
import {getSalesforceFields} from "../assets-management/whatif-scenarios/whatIfScenarios.client";


export function SalesforceConfigV2() {

  const emptyConfig: SalesforceConfig = {
    configName: '',
    configKey: '',
    projectIdColumn: '',
    versionDataProjectId: '',
    mappingConfig: {},
    salesforceApi: 'report',
  }

  const companyId = useCompanyId();
  const {error, info} = useMessages();
  const [configs, setConfigs] = useState<Record<string, SalesforceConfig>>();
  const [scenarioConfigs, setScenarioConfigs] = useState<Record<string, WhatIfScenarioMappingConfig>>();
  const [selectedConfig, setSelectedConfig] = useState<string>('');
  const [formData, setFormData] = useState<SalesforceConfig>(emptyConfig);

  const configType = ConfigType.Salesforce;


  function load() {
    getAmCompanyConfig<SalesforceConfig>(companyId, configType)
      .then((config) => {
        console.info("Loaded", config)
        setConfigs(config.configs);
        const key = Object.keys(config.configs)[0];

        if(config.configs[key]) {
          setFormData(config.configs[key])
        }
      });

    getAmCompanyConfig<WhatIfScenarioConfig>(companyId, ConfigType.WhatIfScenario)
      .then((config) => {
        console.info("Loaded", config);
        const conf = config.configs['project-version-assumptions'];
        if(!conf) {
          error("No project-version-assumptions config found");
          return;
        }
        setScenarioConfigs(conf.mappingConfig);
      });


  }

  useEffect(() => {
    load();
  }, []);

  if (!configs) return <div>Loading...</div>

  let options = Object.keys(configs).map((key) => ({key, value: key, text: key}));
  options.push({key: 'new', value: 'new', text: 'New'});

  const handleSelectedConfigChange = (configName: string) => {
    setSelectedConfig(configName);

    if (configName === 'new') {
      setFormData(emptyConfig);
      return;
    }

    setFormData(configs[configName]);
  }


  const handleSave = async () => {
    console.info("Saving", formData)
    if (!formData) return;
    // save config

    console.info("Saving", formData)
    if (!formData) return;
    // save config
    await saveAmCompanyConfig(companyId, configType, {
      companyId,
      configType,
      configs: {
        ...configs,
        [formData.configKey]: formData,
      }
    }).then(() => {
      info("Saved");
    }).catch((e) => {
      error(e.message);
    });

    load();
  }


  return <div>
    Select Config:
    <Select
      options={options}
      value={selectedConfig}
      onChange={(e, {value}) => handleSelectedConfigChange(value as string)}
    />

    {scenarioConfigs && <SalesforceMappingConfig
      salesforceConfig={formData}
      whatIfScenarioConfigs={scenarioConfigs}
      onChange={(updatedConfig) => setFormData({...formData, ...updatedConfig})}
    />}

    <Button onClick={handleSave}>Save</Button>
  </div>

}

export function SalesforceMappingConfig({salesforceConfig, onChange, whatIfScenarioConfigs}: {
  salesforceConfig: Partial<SalesforceConfig>,
  whatIfScenarioConfigs: Record<string, WhatIfScenarioMappingConfig>,
  onChange: (mappingConfig: Partial<SalesforceConfig>) => void
}) {

  const {error} = useMessages();
  const companyId = useCompanyId();
  const data = salesforceConfig;
  const setData = onChange;

  const [salesforceFields, setSalesforceFields] = useState<string[]>([]);

  const [mappingConfigList, setMappingConfigList] = useState<SalesforceReportCellMapping[]>(
    values(data.mappingConfig || {})
  );

  useEffect(() => {
    setMappingConfigList(values(data.mappingConfig || {}));
  }, [data.mappingConfig]);


  const updateKey = (currentKey: string, mapping: SalesforceReportCellMapping) => {
    setMappingConfigList(mappingConfigList.map((obj) => obj.valueKey === currentKey ? mapping : obj));
  }

  const updateObject = (mapping: SalesforceReportCellMapping) => {
    setMappingConfigList(mappingConfigList.map((obj) => obj.valueKey === mapping.valueKey ? mapping : obj));
  }

  const deleteKey = (key: string) => {
    setMappingConfigList(mappingConfigList.filter((obj) => obj.valueKey !== key));
  }

  const valueKeyOptions = values(whatIfScenarioConfigs)
      .map((config) => ({
        key: config.valueKey,
        value: config.valueKey,
        text: `${config.valueKey} - ${config.label} - ${config.locator.label}`
      }));

  useEffect(() => {
    loadSalesforceFields();
  }, []);

  const loadSalesforceFields = async () => {

    if(!data.configKey) {
      error("Config Key is required");
      return;
    }

    if(!data.salesforceReportId) {
      error("Salesforce Report ID is required to load the report");
      return;
    }

    getSalesforceFields(companyId, data.configKey!)
      .then((fields) => {
        setSalesforceFields(fields);
      })
      .catch((e) => {
        error(e.message);
      });

  }

  const addMapping = () => {
    setMappingConfigList([...mappingConfigList, {valueKey: '', valueColumn: ''  }]);
  }

  const apply = () => {
      setData({...data, mappingConfig: indexBy(c => c.valueKey , mappingConfigList) });
  }

 const salesforceFieldsOptions = salesforceFields.map((field) => ({
   key: field,
   value: field,
   text: field
  }));

  return <div>
    <h2>Salesforce Config</h2>
    <Input
      label={"Config Name"}
      value={data.configName}
      onChange={(e) => setData({...data, configName: e.target.value})}
    />

    <Input
      label={"Config Key"}
      value={data.configKey}
      onChange={(e) => setData({...data, configKey: e.target.value})}
    />

    <Input
      label={"Project Id Column"}
      value={data.projectIdColumn}
      onChange={(e) => setData({...data, projectIdColumn: e.target.value})}
    />

    <Input
      label={"Version Data Project ID"}
      value={data.versionDataProjectId}
      onChange={(e) => setData({...data, versionDataProjectId: e.target.value})}
    />

    <Input
      label={"Salesforce Report ID"}
      value={data.salesforceReportId}
      onChange={(e) => setData({...data, salesforceReportId: e.target.value})}
    />

    <div>
    <Button primary onClick={loadSalesforceFields}>Load Report Fields</Button>
    </div>

    <h3>Mapping Config</h3>
    <Button
      onClick={() => {addMapping()}}>Add Mapping</Button>
    <table style={{ borderCollapse:'collapse' }}>
      <tr>
        <th>Salesforce Field</th>
        <th>Mapping Key</th>
        <th>Actions</th>
      </tr>

      {mappingConfigList.map((obj) => {
      return <tr key={obj.valueKey}>
        <td>
          <Select
            options={salesforceFieldsOptions}
            value={obj.valueColumn}
            style={{width: '400px'}}
            onChange={(e, data) => updateObject({...obj, valueColumn: data.value as string})}
          />
        </td>
        <td>
          <Select
            options={valueKeyOptions.concat({key: obj.valueKey, value: obj.valueKey, text: `${obj.valueKey} (!)`})}
            value={obj.valueKey}
            search
            style={{width: '400px'}}
            onChange={(e, data) => updateKey(obj.valueKey, {...obj, valueKey: data.value as string})}
          /></td>

        <td>
          <Button onClick={() => deleteKey(obj.valueKey)}>Delete</Button>
        </td>
      </tr>
      }
    )}
    </table>
    <Button floated="right" onClick={apply}>Apply</Button>
  </div>

}