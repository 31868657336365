import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {Message, Transition} from "semantic-ui-react";

/**
 * How to use the Error Handling interfaces
 *
 *  - <ErrorContext> needs to be used first. All other utilities can be used only within the scope of ErrorContext children.
 *  - Put <ErrorMessagePopup> where you'd wish to display your errors.
 *  - Use `useErrorHandling` hook to get `handleError` function. Use `handleError` to forward any errors you face on event code.
 *  - Errors produced during rendering are automatically caught if they happened within the scope of ErrorContext.
 */

export interface ErrorReport { err: unknown }

export function useErrorHandling(): {handleError: (errorReport: ErrorReport) => void, errorReport?: ErrorReport} {
  const [handleError, errorReport] = React.useContext(InternalErrorContext)
  return {handleError, errorReport}
}

export const ErrorContext = ({children}: {children: ReactNode}) => {
    const [errorReport, setErrorReport] = useState<ErrorReport>();
    return <>
      <InternalErrorContext.Provider value={[setErrorReport, errorReport]}>
          {children}
      </InternalErrorContext.Provider>
    </>
}

export interface ErrorMessagePopupProps {
  header: string,
  autoDismissIn?: number
}

export function ErrorMessagePopup(props: ErrorMessagePopupProps) {
  return <>
    <InternalErrorContext.Consumer>
      {([setErrorReport, errorReport]) =>
          (errorReport && <ErrorMessagePopupImpl
              errorReport={errorReport}
              resetError={() => setErrorReport(undefined)}
              {...props}
          />)
      }
    </InternalErrorContext.Consumer>
  </>;
}

const InternalErrorContext = React.createContext<
    [(error: ErrorReport | undefined) => void, ErrorReport | undefined]
    >([(report: ErrorReport | undefined) => report && console.error(report.err), undefined]);

interface ErrorRendererProps {
  errorReport: ErrorReport,
  resetError: () => void
}

function ErrorMessagePopupImpl({ errorReport, header, autoDismissIn, resetError }: ErrorRendererProps & ErrorMessagePopupProps) {
  const [open, setOpen] = useState<boolean>(true);

  const dismissMessage = useCallback(() => {
      setOpen(false)
      resetError()
  }, [resetError])

  useEffect(() => {
    if(autoDismissIn) {
      setTimeout(()=> {
        dismissMessage();
      }, autoDismissIn)
    }
  }, [errorReport, autoDismissIn, dismissMessage])

  useEffect(() => {
    const {err} = errorReport
    console.error(err)
  }, [errorReport]);

  let wrappedResponseMessage =  (errorReport.err as any).response?.data;

  //Handing generic error messages
  if (wrappedResponseMessage && typeof wrappedResponseMessage === 'string') {
    wrappedResponseMessage = wrappedResponseMessage.split('[DEBUG]')[0];
  }

    return <>
      {open && <Message floating error className="toast-center" onDismiss={dismissMessage} data-cy='error-toast-container'>
      <Message.Header>{header}</Message.Header>
        <Message.List>
          <Message.Item>{errorReport.err instanceof Error  && ( wrappedResponseMessage || errorReport.err.message || errorReport.err.name || "Unknown error!")}</Message.Item>
        </Message.List>
      </Message>}
    </>
}