import {LineItemsStore} from "../../ps-models/lineitems-store";
import {useUpdateContext} from "../../UpdateContext";
import React, {useEffect, useState} from "react";
import {LineItem, TimedCalculatedLineItem} from "../../ps-models/line-items";
import {values} from "ramda";
import {Button, Message, Segment} from "semantic-ui-react";
import Editor from "react-simple-code-editor";
// @ts-ignore
import {highlight, languages} from 'prismjs/components/prism-core';
//@ts-ignore
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';

export function getCode(lineItem: LineItem) {
  let cl = lineItem as TimedCalculatedLineItem;
  return typeof cl.fn === 'string' ? cl.fn : "---Native---";
}

export function LineItemCodeEditorStandalone({store, lineItemName, onLineItemUpdated}: {
  store: LineItemsStore, lineItemName: string,
  onLineItemUpdated?: (lineItem: LineItem) => void
}) {

  let ctx = useUpdateContext();

  let [code, setCode] = useState<string>(
    getCode(store.getDataSet().getLineItem(lineItemName))
  );

  useEffect(() => {
    setCode(getCode(store.getDataSet().getLineItem(lineItemName)));
  }, [lineItemName]);

  const handleApply = () => {
    let lineItem = store.getDataSet().getLineItem(lineItemName) as TimedCalculatedLineItem;
    lineItem.fn = code;
     store.getDataSet().addLineItem(lineItem);
    onLineItemUpdated && onLineItemUpdated(lineItem);
    ctx.ready();
  }

  const handleCodeChange = (code: string) => {
    setCode(code);
  }


  return <Segment><LineItemCodeEditor
    lineItem={store.getDataSet().getLineItem(lineItemName)}
    code={code}
    onChanged={(code) => {handleCodeChange(code)}} />
    <Button onClick={handleApply}>Apply</Button>
  </Segment>

}

export function LineItemCodeEditor({lineItem, onChanged, code}: {
  lineItem: LineItem,
  code: string,
  onChanged?: (code: string) => void
  }) {
  let errors = [];


  errors = Array.from(new Set(values(lineItem.errors).map(e => e.ctx?.message)));

  const handleCodeChange = (code: string) => {
    onChanged && onChanged(code);
  }

  return <>
    <div style={{fontSize: 11, color: "#666", position: 'relative', top: '6px'}}>Code:</div>
    <Editor
      value={code}
      onValueChange={code => handleCodeChange(code)}
      highlight={code => highlight(code, languages.js)}
      padding={10}
      required
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 12,
        border: "1px solid #ccc",
        backgroundColor: "white",
        margin: "10px 0"
      }}
    />


    {errors.length > 0 && <Message
        warning
        list={errors}
    />}
  </>
}